import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Popover, PopoverContent, PopoverTrigger, cssVar } from 'arc';
import { ElasticVehicle, EntityTypeEnum, Vehicle } from '@mark43/rms-api';
import { map } from 'lodash';
import styled from 'styled-components';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { cadVehicleProfilesSelector } from '~/client-common/core/domain/vehicles/state/data';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatVehicleMakeByIdSelector } from '~/client-common/core/domain/vehicle-makes/state/ui';
import { formatVehicleModelByIdSelector } from '~/client-common/core/domain/vehicle-models/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import { vehicleViewModelByIdSelector } from '~/client-common/core/domain/vehicles/state/ui';

import { VEHICLE_SEARCH_TO_ADD_FORM } from '../../../../search/search-to-add/state/forms/vehicleSearchToAddForm';
import { useFormGetter } from '../../../../core/forms/hooks/useFormGetter';
import { Button } from '../../../../core/components/Button';
import { createDelimitedString } from '../../helpers/entityPrefillHelpers';
import {
    openItemSidePanel,
    setFormWithSearchResult,
    storeItemSidePanelMasterItemId,
} from '../../state/ui';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

import { SuggestionListItemProps, SuggestionSearchList } from './SuggestionSearchList';
import VehicleSearchForm, { SearchResults } from './VehicleSearchForm';

const Container = styled.div`
    padding: 8px;
    padding-bottom: 0;
    width: 375px;
    margin-bottom: ${cssVar('arc.space.2')};
`;

export const VehicleSuggestionPopover = ({
    buttonLabel,
}: {
    buttonLabel: string;
    onClick: () => void;
}) => {
    const dispatch = useDispatch();
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const formatVehicleMakeById = useSelector(formatVehicleMakeByIdSelector);
    const formatVehicleModelById = useSelector(formatVehicleModelByIdSelector);
    const cadVehicles = useSelector(cadVehicleProfilesSelector);
    const currentReport = useSelector(currentReportSelector);
    const vehicleViewModelById = useSelector(vehicleViewModelByIdSelector);
    const { getForm } = useFormGetter();
    const form = getForm(VEHICLE_SEARCH_TO_ADD_FORM);
    const strings = componentStrings.reports.EntityPrefillPopover;
    const [searchResults, setSearchResults] = useState<SearchResults | undefined>(undefined);
    const [rmsResults, setRmsResults] = useState<SuggestionListItemProps[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // cadVehicles are Vehicles, while rmsResults are ElasticVehicle
    const getVehicleText = useCallback(
        (
            vehicle: Vehicle | ElasticVehicle,
            makeId?: number,
            modelId?: number,
            makeOther?: string,
            modelOther?: string
        ) => {
            const {
                primaryColorAttrId,
                tag,
                vehicleMakeAttrId,
                vehicleModelAttrId,
                vinNumber,
                yearOfManufacture,
                id,
            } = vehicle;

            // default/fallback values
            let vehicleMake = formatAttributeById(vehicleMakeAttrId);
            let vehicleModel = formatAttributeById(vehicleModelAttrId);

            const makeAltDescription = makeOther?.length ? makeOther : vehicleMake;
            const modelAltDescription = modelOther?.length ? modelOther : vehicleModel;

            if (makeId) {
                vehicleMake = makeId === -1 ? makeAltDescription : formatVehicleMakeById(makeId);
            }
            if (modelId) {
                vehicleModel =
                    modelId === -1 ? modelAltDescription : formatVehicleModelById(modelId);
            }

            const vehicleColor = formatAttributeById(primaryColorAttrId);

            return {
                avatarImgPath: '',
                entityType: EntityTypeEnum.VEHICLE.name,
                name: createDelimitedString([yearOfManufacture, vehicleMake, vehicleModel], ' '),
                nameDescriptor: vehicleColor,
                infoText: createDelimitedString([vinNumber, tag]),
                id,
            };
        },
        [formatAttributeById, formatVehicleMakeById, formatVehicleModelById]
    );

    const suggestedProfiles: SuggestionListItemProps[] = cadVehicles.map((vehicle: Vehicle) =>
        getVehicleText(
            vehicle,
            vehicle.vehicleMakeId,
            vehicle.vehicleModelId,
            vehicle.itemMake,
            vehicle.itemModel
        )
    );

    const [quickSearchQuery, setQuickSearchQuery] = useState<string>('');

    useEffect(() => {
        const newResults = map(searchResults?.vehicles, (vehicle: ElasticVehicle) =>
            getVehicleText(
                vehicle,
                vehicle.vehicle.vehicleMakeId,
                vehicle.vehicle.vehicleModelId,
                vehicle.vehicle.vehicleMakeOther,
                vehicle.vehicle.vehicleModelOther
            )
        );
        setRmsResults(newResults);
    }, [searchResults, getVehicleText]);

    const clearSearch = () => {
        setRmsResults(undefined);
        form?.resetModel();
        form?.resetUi();
    };

    const openAddVehicleSidePanel = () => {
        dispatch(
            openItemSidePanel({
                isVehicle: true,
                itemTypeAttrId: globalAttributes.itemType.vehicle,
                ownerType: EntityTypeEnum.REPORT.name,
                ownerId: currentReport?.id,
                reportingEventNumber: currentReport?.reportingEventNumber,
                operation: itemSidePanelOperationEnum.CREATE,
                isFormHidden: false,
            })
        );
    };

    const openEditVehicleSidePanel = (itemProfileId: number) => {
        dispatch(
            openItemSidePanel({
                itemProfileId,
                itemTypeAttrId: globalAttributes.itemType.vehicle,
                operation: itemSidePanelOperationEnum.CREATE,
                isVehicle: true,
                ownerType: EntityTypeEnum.REPORT.name,
                ownerId: currentReport?.id,
                reportingEventNumber: currentReport?.reportingEventNumber,
                isFormHidden: false,
            })
        );
        dispatch(storeItemSidePanelMasterItemId(itemProfileId));
        dispatch(setFormWithSearchResult(itemProfileId, true));
    };

    const openSearchVehicleSidePanel = (itemProfileId: number) => {
        const itemProfile = vehicleViewModelById(itemProfileId);

        dispatch(
            openItemSidePanel({
                isVehicle: true,
                itemTypeAttrId: globalAttributes.itemType.vehicle,
                ownerType: EntityTypeEnum.REPORT.name,
                ownerId: currentReport?.id,
                reportingEventNumber: currentReport?.reportingEventNumber,
                operation: itemSidePanelOperationEnum.CREATE,
                isFormHidden: true,
                isAutosearch: true,
                // @ts-expect-error client-common to client RND-7529
                query: itemProfile?.tag ?? itemProfile?.vinNumber,
                itemProfileId,
            })
        );
    };

    const onViewAllClick = () => {
        dispatch(
            openItemSidePanel({
                isVehicle: true,
                itemTypeAttrId: globalAttributes.itemType.vehicle,
                ownerType: EntityTypeEnum.REPORT.name,
                ownerId: currentReport?.id,
                reportingEventNumber: currentReport?.reportingEventNumber,
                isFormHidden: true,
                operation: itemSidePanelOperationEnum.CREATE,
                query: quickSearchQuery,
                isAutosearch: true,
            })
        );
    };

    return (
        <Popover hasArrow={false}>
            <PopoverTrigger asChild>
                <Button leadingVisual="Add" style={{ textTransform: 'none' }}>
                    {buttonLabel}
                </Button>
            </PopoverTrigger>
            <PopoverContent
                align="start"
                hasPadding={false}
                side="bottom"
                onOpenAutoFocus={clearSearch}
            >
                <Container>
                    <VehicleSearchForm
                        setSearchResults={setSearchResults}
                        setQuickSearchQuery={setQuickSearchQuery}
                        setIsLoading={setIsLoading}
                    />
                </Container>
                <SuggestionSearchList
                    rmsResults={rmsResults}
                    suggestedProfiles={suggestedProfiles}
                    isLoading={isLoading}
                    onRmsResultClick={openEditVehicleSidePanel}
                    onSuggestionClick={openSearchVehicleSidePanel}
                    onViewAllClick={onViewAllClick}
                />
                <Divider style={{ width: '100%' }} />
                <Button
                    variant="ghost"
                    style={{
                        textTransform: 'none',
                        margin: 8,
                    }}
                    onClick={openAddVehicleSidePanel}
                >
                    {strings.createNewProfile}
                </Button>
            </PopoverContent>
        </Popover>
    );
};
