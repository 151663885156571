import { FormEnum } from '@mark43/rms-api';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { createStructuredSelector } from 'reselect';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import stringsConfig from '~/client-common/core/strings';

import { createModalSelector } from '../../../../modules/core/box/state/ui';
import { renSearchUiSelector } from '../../../selectors/renSearchModalSelectors';
import { createReport } from '../../../actions/renSearchModalActions';
import RenSearchModal from '../../../../modules/core/box/components/RenSearchModal';
import CreateReportModalResults from './CreateReportModalResults';

const strings = stringsConfig.components.reports.CreateReportModal;

const context = { name: boxEnum.CREATE_REPORT_MODAL };
const modalContextSelector = createModalSelector(context)
const formInitialStateSelector = createModalSelector(context, 'formInitialState');
const showCadAgencyEventNumberFieldSelector = createModalSelector(
    context,
    'showCadAgencyEventNumberField'
);

function LegacyCreateReportModal({
    renSearchUi,
    createReport,
    modalContext,
    formatFieldByName,
    formInitialState = {},
    showCadAgencyEventNumberField,
}) {
    return (
        <RenSearchModal
            title={strings.title}
            modalContext={context}
            validationContext={{ form: FormEnum.CREATE_REPORT.name }}
            formInitialState={formInitialState}
            formName="createReportRenSearch"
            showCadAgencyEventNumberField={showCadAgencyEventNumberField}
        >
            <CreateReportModalResults
                createReport={createReport}
                reportDefinitionIds={modalContext.reportDefinitionIds}
                ui={renSearchUi}
                renDisplayName={formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)}
            />
        </RenSearchModal>
    );
}

const selectors = createStructuredSelector({
    renSearchUi: renSearchUiSelector,
    formatFieldByName: formatFieldByNameSelector,
    formInitialState: formInitialStateSelector,
    modalContext: modalContextSelector,
    showCadAgencyEventNumberField: showCadAgencyEventNumberFieldSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    createReport: (id) => dispatch(createReport(id, router)),
});

export default compose(withRouter, connect(selectors, mapDispatchToProps))(LegacyCreateReportModal);
