import React, { useCallback } from 'react';
import { createStructuredSelector } from 'reselect';
import { filter, isEmpty, map } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import savedSearchSectionEnum from '~/client-common/core/enums/client/savedSearchSectionEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { useOverlayResourceOnOpen } from '../../../core/overlays/hooks/useOverlayResource';
import {
    CopySavedSearchConfirmationModal,
    DeleteSavedSearchConfirmationModal,
} from './SavedSearchConfirmationModals';
import SavedSearch from './SavedSearch';
import SavedSearchErrorMessage from './SavedSearchErrorMessage';

const strings = componentStrings.search.savedSearch.SavedSearchContent;

const StyledSection = styled('section')`
    margin-bottom: 20px;
`;

const StyledSectionHeader = styled('div')`
    font-size: var(--arc-fontSizes-md);
    line-height: 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.mediumGrey};
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding-bottom: 4px;
`;

const SavedSection = ({
    header,
    section,
    savedSearches,
    onSavedSearchExecuteSuccess,
    hideSubscribeOption,
    searchModule,
}) => {
    return !isEmpty(savedSearches) ? (
        <StyledSection>
            <StyledSectionHeader>{header}</StyledSectionHeader>
            {map(savedSearches, (savedSearch, i) => (
                <SavedSearch
                    key={savedSearch.id}
                    savedSearch={savedSearch}
                    includeSeparator={i < savedSearches.length - 1}
                    searchModule={searchModule}
                    onSavedSearchExecuteSuccess={() => {
                        if (onSavedSearchExecuteSuccess) {
                            onSavedSearchExecuteSuccess(savedSearch);
                        }
                    }}
                    savedSearches={savedSearches}
                    hideSubscribeOption={hideSubscribeOption}
                    savedSearchSection={section}
                />
            ))}
        </StyledSection>
    ) : null;
};

const SavedSearchContent = ({
    savedSearches,
    searchModule,
    onSavedSearchExecuteSuccess,
    loadSavedSearchesError,
    hideSubscribeOption,
}) => {
    const dispatch = useDispatch();
    const resource = useCallback(
        () => dispatch(searchModule.actionCreators.loadSavedSearches()),
        [dispatch, searchModule]
    );

    const sharedSavedSearches = filter(savedSearches, ({ isShared }) => isShared);
    const mySavedSearches = filter(savedSearches, ({ isShared }) => !isShared);
    const favoriteSearches = filter(savedSearches, ({ isFavorite }) => isFavorite);

    useOverlayResourceOnOpen({ id: overlayIdEnum.SAVED_SEARCH_MODAL, resource });

    if (loadSavedSearchesError) {
        return (
            <div>
                <SavedSearchErrorMessage>{loadSavedSearchesError}</SavedSearchErrorMessage>
            </div>
        );
    }

    return (
        <div>
            {!isEmpty(savedSearches) ? (
                <>
                    <SavedSection
                        header={strings.labels.favoriteSearches}
                        section={savedSearchSectionEnum.FAVORITE_SEARCHES}
                        savedSearches={favoriteSearches}
                        onSavedSearchExecuteSuccess={onSavedSearchExecuteSuccess}
                        hideSubscribeOption={hideSubscribeOption}
                        searchModule={searchModule}
                    />
                    <SavedSection
                        header={strings.labels.mySavedSearches}
                        section={savedSearchSectionEnum.MY_SAVED_SEARCHES}
                        savedSearches={mySavedSearches}
                        onSavedSearchExecuteSuccess={onSavedSearchExecuteSuccess}
                        hideSubscribeOption={hideSubscribeOption}
                        searchModule={searchModule}
                    />
                    <SavedSection
                        header={strings.labels.sharedWithDepartment}
                        section={savedSearchSectionEnum.SHARED_WITH_DEPARTMENT}
                        savedSearches={sharedSavedSearches}
                        onSavedSearchExecuteSuccess={onSavedSearchExecuteSuccess}
                        hideSubscribeOption={hideSubscribeOption}
                        searchModule={searchModule}
                    />
                    <CopySavedSearchConfirmationModal searchModule={searchModule} />
                    <DeleteSavedSearchConfirmationModal searchModule={searchModule} />
                </>
            ) : (
                <div>{strings.labels.noResults}</div>
            )}
        </div>
    );
};

/**
 * @type React.FC<{
 *     hideSubscribeOption?: boolean;
 *     onSavedSearchExecuteSuccess?: (result: SavedSearchResult) => void;
 *     searchModule: object;
 * }>
 */
const SavedSearchContentComponent = connect((state, { searchModule }) => {
    return createStructuredSelector({
        savedSearches: searchModule.selectors.savedSearchesViewModelsSelector,
        loadSavedSearchesError: searchModule.selectors.loadSavedSearchErrorMessageSelector,
    });
})(SavedSearchContent);

export default SavedSearchContentComponent;
