import { EntityTypeEnum, RefContextEnum } from '@mark43/rms-api';
import React, { useCallback } from 'react';
import { compact, get, map } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withState } from 'recompose';
import styled from 'styled-components';
import { withRegistry } from 'markformythree';
import pluralize from 'pluralize';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { canManage } from '~/client-common/core/domain/entity-permissions/state/ui';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import {
    DISPLAY_CASE_ASSIGNEE,
    DISPLAY_ONLY_CASE,
} from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { abilitiesEnum } from '../../../core/abilities';
import HelpText from '../../../../legacy-redux/components/core/HelpText';
import {
    assigneeCaseRoleLinkSelector,
    supervisorCaseRoleLinksSelector,
    reportsResultsViewModelsSelector,
    openCaseDetailsModalForCase,
    currentCaseViewSelector,
} from '../state/ui';
import {
    saveEntityPermissionsForm,
    loadPermissionsForModal,
    getFormNameForEntityType,
} from '../../../core/permissions/state/ui';
import { SecondarySectionHeader } from '../../../core/components/typography';
import Modal from '../../../core/overlays/components/Modal';

import {
    currentUserDepartmentIdSelector,
    currentUserHasAbilitySelector,
} from '../../../core/current-user/state/ui';
import RecordPermissionsForm from '../../../core/permissions/components/RecordPermissionsForm';

const strings = componentStrings.core.permissions.casePermissionsModal;

const ColumnA = styled.div`
    width: 300px;
    display: inline-block;
`;

const Section = styled.div`
    margin-bottom: 30px;
`;

function CasePermissionsModal({ caseId, closeFocusRef }) {
    const dispatch = useDispatch();
    const handleSave = useCallback(
        () => dispatch(saveEntityPermissionsForm(EntityTypeEnum.CASE.name, caseId)),
        [caseId, dispatch]
    );
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    return (
        <Modal
            cancelFocusRef={closeFocusRef}
            id={overlayIdEnum.CASE_PERMISSIONS_OVERLAY}
            okText={strings.saveText}
            onSave={handleSave}
            saveFocusRef={closeFocusRef}
            title={strings.title(caseDisplayName)}
        >
            <CasePermissionsModalContent
                caseId={caseId}
                overlayId={overlayIdEnum.CASE_PERMISSIONS_OVERLAY}
            />
        </Modal>
    );
}

class _CasePermissionsModalContent extends React.Component {
    componentDidMount() {
        this.props.fetchData();
    }

    componentWillUnmount() {
        const form = this.props.registry.get(RefContextEnum.FORM_ENTITY_PERMISSION.name);
        if (form) {
            this.props.registry.unregister(form);
        }
    }

    render() {
        const {
            assigneeCaseRoleLink,
            supervisorCaseRoleLinks,
            openCaseDetailsModalForCase,
            currentCaseView,
            fieldDisplayNames,
            currentUserHasAbility,
            applicationSettings,
        } = this.props;

        const caseIsEditable = currentCaseView ? canManage(currentCaseView.permissionSet) : false;
        const isDepartmentRoleEditable = !!(
            currentUserHasAbility(abilitiesEnum.ADMIN.DELETE_DEPARTMENT_PERMISSION) &&
            applicationSettings.RMS_REMOVE_DEPARTMENT_PERMISSIONS_ENABLED
        );

        const assigneeName = {
            name: get(getViewModelProperties(assigneeCaseRoleLink), 'names.fullName'),
            level: get(getViewModelProperties(assigneeCaseRoleLink), 'operationType'),
        };

        const supervisorNames = map(supervisorCaseRoleLinks, (supervisorCaseRoleLink) => ({
            name: getViewModelProperties(supervisorCaseRoleLink).names.fullName,
            level: getViewModelProperties(supervisorCaseRoleLink).operationType,
        }));

        const disabledCaseRoleLinks = compact(supervisorCaseRoleLinks.concat(assigneeCaseRoleLink));
        const disabledRoleIds = map(disabledCaseRoleLinks, 'roleId');

        return (
            <div>
                <SecondarySectionHeader onEditClick={caseIsEditable && openCaseDetailsModalForCase}>
                    {strings.assigneesTitle(pluralize(fieldDisplayNames.DISPLAY_CASE_ASSIGNEE))}
                    <HelpText
                        collisionBoundary={document.querySelector('.mark43-react-modal')}
                        content={strings.helpText(
                            fieldDisplayNames.DISPLAY_CASE_ASSIGNEE,
                            fieldDisplayNames.DISPLAY_ONLY_CASE
                        )}
                    />
                </SecondarySectionHeader>
                <Section>
                    {assigneeName.name && (
                        <div>
                            <ColumnA>
                                {strings.assigneeName(
                                    assigneeName.name,
                                    fieldDisplayNames.DISPLAY_CASE_ASSIGNEE
                                )}
                            </ColumnA>
                            {assigneeName.level}
                        </div>
                    )}
                    {map(supervisorNames, ({ name, level }) => {
                        return (
                            <div key={name}>
                                <ColumnA>{strings.supervisorName(name)}</ColumnA>
                                {level}
                            </div>
                        );
                    })}
                    {!assigneeName.name && !supervisorNames.length
                        ? strings.noUserAssignedToCase(fieldDisplayNames.DISPLAY_ONLY_CASE)
                        : null}
                </Section>
                <RecordPermissionsForm
                    formName={getFormNameForEntityType(EntityTypeEnum.CASE.name)}
                    disabledRoleIds={disabledRoleIds}
                    editable={caseIsEditable}
                    isDepartmentRoleEditable={isDepartmentRoleEditable}
                />
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    currentCaseView: currentCaseViewSelector,
    assigneeCaseRoleLink: assigneeCaseRoleLinkSelector,
    supervisorCaseRoleLinks: supervisorCaseRoleLinksSelector,
    reportsResultsViewModels: reportsResultsViewModelsSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    applicationSettings: applicationSettingsSelector,
});

const CasePermissionsModalContent = compose(
    withRegistry(),
    withState('collapsed', 'setCollapsed', ({ collapsed = true }) => collapsed),
    connect(mapStateToProps, (dispatch, ownProps) => ({
        fetchData: () =>
            dispatch(
                loadPermissionsForModal(
                    ownProps.overlayId,
                    EntityTypeEnum.CASE.name,
                    ownProps.caseId
                )
            ),
        openCaseDetailsModalForCase: () => dispatch(openCaseDetailsModalForCase()),
    })),
    withFields([DISPLAY_CASE_ASSIGNEE, DISPLAY_ONLY_CASE])
)(_CasePermissionsModalContent);

export default CasePermissionsModal;
