import { some } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EntityTypeEnum, PersonProfile } from '@mark43/rms-api';
import { lifecycleOptions, _Form, MFTFormConfiguration } from 'markformythree';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import type { Editor } from 'tinymce';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { reportsSelector } from '~/client-common/core/domain/reports/state/data';
import { courtOrderSelector } from '~/client-common/core/domain/court-orders/state/data';
import { personProfilesForReportIdSelector } from '~/client-common/core/domain/reports/state/ui/names';
import {
    narrativeAutosavesSelector,
    updateNarrativeAutosaveForReportId,
} from '~/client-common/core/domain/narrative-autosave/state/data';
import { AUTOSAVE_INTERVAL } from '~/client-common/core/domain/reports/state/ui/narratives';
import { loadNarrativeGuidesWithoutCatch } from '~/client-common/core/domain/narrative-guides/state/data';

import { RmsDispatch } from '../../../../../core/typings/redux';
import SealedStamp from '../../../../core/components/SealedStamp';
import { useIsE2ETestEnvironment } from '../../../../core/context/E2ETestingContext';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { BodyMediumText } from '../../../../core/components/typography/Typography';
import { sanitizeTinyMCEValue } from '../../../../core/editor/utils/tinyEditor';

import { narrativeFormName } from '../../state/forms/narrativeForm';
import {
    NarrativeDisplayContainer,
    CardSectionWithEqualPadding,
    TinyStyledArbiterMFTNarrativeCardEditor,
} from '../NarrativeStyledComponents';
import { useFetchMentionsConfigurationsOnNarrativeCard } from '../../../../admin/mentions-configuration/state/data';
import { useDateTimeFormatter } from '../../../../core/current-user/hooks/dateTimeFormats';
import { useAbilitySelector } from '../../../../core/current-user/hooks/useAbilitySelector';
import errorToMessage from '../../../../core/errors/utils/errorToMessage';

const strings = componentStrings.reports.core.NarrativeCardContent;

const NarrativeAutosaveContainer = styled.div`
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.brightYellow};
    background-color: ${(props) => props.theme.colors.lightYellow};
    color: ${(props) => props.theme.colors.darkGrey};
    padding: 10px;
`;

const NarrativeNondisclosureContainer = styled.div`
    color: ${(props) => props.theme.colors.darkGrey};
    text-transform: uppercase;
`;

const NarrativeSealingContainer = styled.div`
    display: flex;
    margin-top: 12px;
    margin-bottom: 2px;
    align-items: center;
`;

const NarrativeSealingText = styled(BodyMediumText)`
    flex: 1;
`;

const SealedStampWrapper = styled.div`
    margin: var(--arc-space-4);
`;

const PartiallySealedStamp = styled(SealedStamp)`
    width: fit-content;
    padding: var(--arc-space-2);
`;

const SealedCardSection: React.FC<{ sealedNarrativeContactUserId?: number }> = ({
    sealedNarrativeContactUserId,
}) => {
    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);

    return (
        <CardSectionWithEqualPadding>
            <NarrativeSealingContainer>
                <NarrativeSealingText>
                    {sealedNarrativeContactUserId
                        ? strings.sealingNotice(formatMiniUserById(sealedNarrativeContactUserId))
                        : undefined}
                </NarrativeSealingText>
                <SealedStamp />
            </NarrativeSealingContainer>
        </CardSectionWithEqualPadding>
    );
};

const useAutosave = ({
    onStartAutosave,
    autosaveInterval,
}: {
    onStartAutosave: () => Promise<void>;
    autosaveInterval: number;
}) => {
    const autosaveTimeoutId = useRef<number>();

    const stopAutosave = useCallback(() => {
        window.clearInterval(autosaveTimeoutId.current);
        autosaveTimeoutId.current = undefined;
    }, []);

    const startAutosave = useCallback(() => {
        autosaveTimeoutId.current = window.setInterval(() => {
            onStartAutosave();
        }, autosaveInterval);
    }, [autosaveInterval, onStartAutosave]);

    return {
        startAutosave,
        stopAutosave,
    };
};

export const TinyNarrativeCardContent = ({
    id,
    setError,
    form,
    summaryMode,
    getSummaryMode,
    setEditor,
    editorRef,
    currentReportId,
    isSticky,
    isExpandedFullScreen,
    onToggleComments,
    onFullScreenToggle,
    offset,
    onValueChange,
    onKeyDown,
}: {
    id: string;
    setError: (error: string) => void;
    form: _Form<MFTFormConfiguration>;
    summaryMode: boolean;
    getSummaryMode: () => boolean;
    setEditor: (editor: Editor) => void;
    editorRef: React.MutableRefObject<Editor | null>;
    currentReportId: number;
    isSticky: boolean;
    isExpandedFullScreen: boolean;
    onToggleComments?: (commentIsSelected: boolean, selectedCommentId?: number) => void;
    onFullScreenToggle: () => void;
    offset: number;
    onValueChange: () => void;
    onKeyDown: () => void;
}) => {
    const dispatch = useDispatch<RmsDispatch>();
    useFetchMentionsConfigurationsOnNarrativeCard(summaryMode);
    const [autosaveNotificationMessage, setAutosaveNotificationMessage] = useState('');

    const mentionsEnabled = useSelector(applicationSettingsSelector)
        .RMS_MENTIONS_IN_NARRATIVE_ENABLED;

    const narrativeAutosave = useSelector(narrativeAutosavesSelector)[currentReportId];

    const initialValue = form.get('narrative');
    const [currentAutosaveValue, setCurrentAutosaveValue] = useState(initialValue);

    const dateTimeFormatter = useDateTimeFormatter();

    const onStartAutosave = useCallback(() => {
        const editor = editorRef.current;
        if (!editor) {
            return Promise.resolve();
        }

        const narrativeHtml = sanitizeTinyMCEValue(editor.getContent() || '');

        if (!narrativeHtml || narrativeHtml === currentAutosaveValue) {
            return Promise.resolve();
        }

        return dispatch(
            updateNarrativeAutosaveForReportId({
                reportId: currentReportId,
                narrativeHtml,
            })
        )
            .then(() => {
                setCurrentAutosaveValue(narrativeHtml);
            })
            .catch((e) => setError(`${strings.autosaveFailed} ${errorToMessage(e)}`));
    }, [
        editorRef,
        currentAutosaveValue,
        setError,
        setCurrentAutosaveValue,
        currentReportId,
        dispatch,
    ]);

    const { startAutosave, stopAutosave } = useAutosave({
        autosaveInterval: AUTOSAVE_INTERVAL,
        onStartAutosave,
    });

    // When summary mode changes,
    // start and stop autosave
    useEffect(() => {
        if (!summaryMode) {
            startAutosave();
            return stopAutosave;
        } else {
            stopAutosave();
            return;
        }
    }, [summaryMode, startAutosave, stopAutosave]);

    const loadNarrativeGuides = loadNarrativeGuidesWithoutCatch;
    useEffect(() => {
        if (!summaryMode) {
            try {
                dispatch(loadNarrativeGuides());
            } catch (e) {
                setError(`${strings.loadNarrativeGuidesFailed} ${errorToMessage(e)}`);
            }
        }
    }, [summaryMode, loadNarrativeGuides, setError, dispatch]);

    useEffect(() => {
        if (
            narrativeAutosave &&
            narrativeAutosave.narrativeHtml &&
            narrativeAutosave.updatedDateUtc
        ) {
            // this message should only ever be set when this component first mounts, to let the user know that the last
            // autosave has been recovered
            setAutosaveNotificationMessage(
                strings.autosaveNotificationMessage(
                    dateTimeFormatter.formatSummaryMonthDayYearTimeSec(
                        narrativeAutosave.updatedDateUtc
                    )
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            !narrativeAutosave ||
            !narrativeAutosave.narrativeHtml ||
            !narrativeAutosave.updatedDateUtc
        ) {
            // when the autosave gets removed, we remove the message
            // this typically happens when the user manually saves the narrative
            setAutosaveNotificationMessage('');
        }
    }, [narrativeAutosave]);

    // data for sealing
    const reports = useSelector(reportsSelector);
    const report = reports?.[currentReportId];
    const courtOrders = useSelector(courtOrderSelector);
    const courtOrdersByReport = courtOrders?.[currentReportId];

    // data for non-disclosure
    const personProfilesForReportId: (reportId: number) => PersonProfile[] = useSelector(
        personProfilesForReportIdSelector
    );
    const personProfiles = personProfilesForReportId(currentReportId);
    const reportHasNonDisclosures = some(personProfiles, {
        isNonDisclosureRequest: true,
    });

    const isE2EContext = useIsE2ETestEnvironment();
    const hasAddImageNarrativeAbility = useAbilitySelector(
        abilitiesEnum.REPORTING.ADD_IMAGE_NARRATIVE
    );
    const tinyNarrativeArbiterForm = (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={narrativeFormName}
            index={currentReportId}
            context={narrativeFormName}
            render={() => (
                <TinyStyledArbiterMFTNarrativeCardEditor
                    id={id}
                    mentionsEnabled={mentionsEnabled}
                    inlineCommentsEnabled={true}
                    form={form}
                    summaryMode={summaryMode}
                    getSummaryMode={getSummaryMode}
                    path="narrative"
                    setCurrentAutosaveValue={setCurrentAutosaveValue}
                    setError={setError}
                    onToggleComments={onToggleComments}
                    onFullScreenToggle={onFullScreenToggle}
                    setEditor={setEditor}
                    editorRef={editorRef}
                    isSticky={!isE2EContext && isSticky}
                    offset={offset}
                    onValueChange={onValueChange}
                    onKeyDown={onKeyDown}
                    entityId={currentReportId}
                    entityType={EntityTypeEnum.REPORT.name}
                    imagesEnabled={hasAddImageNarrativeAbility}
                    tablesEnabled
                />
            )}
        />
    );

    const isNarrativePartiallySealed = useMemo(() => {
        return /data-sealed-id="\d+"/.test(report?.narrative || '') && courtOrdersByReport?.length > 0;
      }, [report?.narrative, courtOrdersByReport]);

    return report?.isNarrativeSealed ? (
        <SealedCardSection sealedNarrativeContactUserId={report.sealedNarrativeContactUserId} />
    ) : (
        <>
            {(autosaveNotificationMessage || !!reportHasNonDisclosures) && (
                <CardSectionWithEqualPadding>
                    {!!reportHasNonDisclosures && (
                        <NarrativeNondisclosureContainer>
                            <BodyMediumText isItalic>{strings.nonDisclosureMessage}</BodyMediumText>
                        </NarrativeNondisclosureContainer>
                    )}
                    {autosaveNotificationMessage && (
                        <NarrativeAutosaveContainer>
                            <BodyMediumText color="tertiary">
                                {autosaveNotificationMessage}
                            </BodyMediumText>
                        </NarrativeAutosaveContainer>
                    )}
                </CardSectionWithEqualPadding>
            )}
            {isNarrativePartiallySealed && <SealedStampWrapper>
                <PartiallySealedStamp>
                    {strings.partiallySealed}
                </PartiallySealedStamp>
            </SealedStampWrapper>}
            <NarrativeDisplayContainer isExpandedFullScreen={isExpandedFullScreen}>
                {tinyNarrativeArbiterForm}
            </NarrativeDisplayContainer>
        </>
    );
};
