import React from 'react';
import { FilterMenuRenderProps, Flex, Divider, FilterOptionT } from 'arc';
import {
    Form,
    lifecycleOptions,
    createNItems,
    createFormConfiguration,
    MFTFieldConfigurationDiscriminator,
    InferFormDataShape,
    NItems,
} from 'markformythree';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { MFTNItems } from '../../../forms/components/NItems';
import { Button } from '../../../components/Button';

const ContentWrapper = styled.div`
    min-height: 0;
    flex-direction: column;
    display: flex;

    /* Can't style the Form directly */
    form {
        min-height: 0;
        display: flex;
        flex: 1;
        flex-direction: column;
    }
`;

const strings = componentStrings.evidence.dashboard.filterList;
type NItemsDefaultValueT = Record<string, unknown>;

export type NItemsFormFilterT<T extends NItemsDefaultValueT> = FilterMenuRenderProps<T> & {
    fields: Record<keyof T, MFTFieldConfigurationDiscriminator>;
    appliedOptions?: FilterOptionT<T>[];
    render: React.ComponentProps<typeof NItems>['render'];
    name: string;
    /** When false, will display a count instead of a value when there's only one option applied.
     * Note, could change this into a render function that provides `item` and allows the consumer to format the label that is displayed.
     */
    showValue?: boolean;
    addText: string;
};

function getNItemsInitialState<T extends NItemsDefaultValueT>(appliedOptions: FilterOptionT<T>[]) {
    const initialOptions = appliedOptions.map((item) => item.value);
    return { nItems: initialOptions };
}

const generateCreateFormConfiguration = <T extends NItemsDefaultValueT>(
    fields: NItemsFormFilterT<T>['fields']
) =>
    createFormConfiguration({
        nItems: createNItems({
            fields,
        }),
    });

type FormConfigurationT<T extends NItemsDefaultValueT> = ReturnType<
    (fields: NItemsFormFilterT<T>) => ReturnType<typeof generateCreateFormConfiguration<T>>
>;

type FormDataT<T extends NItemsDefaultValueT> = InferFormDataShape<FormConfigurationT<T>>;

const convertFormDataToFilter = <T extends NItemsDefaultValueT, F extends FormDataT<T>>(
    formData: F,
    showValue: boolean
) => {
    const appliedFilters: FilterOptionT<T>[] = [];

    if (!formData['nItems']) {
        return [];
    }
    formData['nItems'].map((item, index) => {
        const itemHasValues = Object.values(item).some(
            (value) => value !== undefined && value !== ''
        );
        // Don't add empty fields to the filter
        if (!itemHasValues) {
            return;
        }

        // Work around for complex items like Involved Persons Filter where we don't have a prettified value.
        // Shows a count of 1 if the value is hidden
        const itemLabel = showValue ? String(Object.values(item)[0]) : 1;

        const newFilter: FilterOptionT<T> = {
            id: index,
            label: itemLabel,
            value: item as T,
        };
        return appliedFilters.push(newFilter);
    });
    return appliedFilters;
};

export const NItemsFormFilter = <T extends NItemsDefaultValueT>({
    setAppliedFilters,
    closePopover,
    appliedOptions,
    addText,
    render,
    name,
    showValue = true,
    fields,
}: NItemsFormFilterT<T>) => {
    const formConfiguration = generateCreateFormConfiguration<T>(fields);

    const handleUpdateFilters = (formData: FormDataT<T>) => {
        const updatedFormData = convertFormDataToFilter<T, FormDataT<T>>(formData, showValue);
        setAppliedFilters(updatedFormData);
    };

    return (
        <ContentWrapper>
            <Form
                configuration={formConfiguration}
                lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                name={name}
                initialState={
                    appliedOptions
                        ? (getNItemsInitialState<T>(appliedOptions) as FormDataT<T>)
                        : undefined
                }
                render={(form) => {
                    return (
                        <Flex flexDirection="column" minHeight={0}>
                            <Flex flex={1} overflowY="auto" p={2} minHeight={0}>
                                <MFTNItems
                                    path="nItems"
                                    addText={addText}
                                    addItemOnEmpty
                                    render={render}
                                    renderRowContainer={({ itemElement, removeButtonElement }) => {
                                        return (
                                            <Flex align="center" gap={2}>
                                                {itemElement}
                                                {removeButtonElement}
                                            </Flex>
                                        );
                                    }}
                                    renderAddButton={({ addText, addItem }) => (
                                        <Button
                                            leadingVisual="Add"
                                            variant="ghost"
                                            isTextTransformNone
                                            onClick={() => {
                                                addItem('');
                                            }}
                                        >
                                            {addText}
                                        </Button>
                                    )}
                                />
                            </Flex>
                            <Divider />
                            <Flex p={2}>
                                <Button
                                    isTextTransformNone
                                    onClick={() => {
                                        const formData = form.get();
                                        handleUpdateFilters(formData);
                                        closePopover();
                                    }}
                                >
                                    {strings.applyFilter}
                                </Button>
                            </Flex>
                        </Flex>
                    );
                }}
            />
        </ContentWrapper>
    );
};
