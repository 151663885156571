import {
    EntityTypeEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    UsageSourceModuleEnum,
} from '@mark43/evidence-api';
import { DataTable, Tr, useToast } from 'arc';
import { map } from 'lodash';
import invariant from 'invariant';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sqlSortKeyEnum from '~/client-common/core/enums/universal/sqlSortKeyEnum';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';

import { createUsageLog } from '../../../../admin/usage-logs/state/data';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import { columnDefinitions } from '../../configuration/data-tables/inventoriesDataTable';
import inventoryExportResource from '../../resources/inventoriesExportResource';
import { inventoriesDashboardSearch } from '../../state/ui';
import { InventoriesDashboardResult, InventoriesViewModel } from '../../types';

const DEFAULT_PAGE_SIZE = 50;

const BaseInventoriesDashboardDataTable: React.FC<RouteComponentProps<never, never>> = ({
    router,
}) => {
    const inventoriesDashboardSearchResults: InventoriesViewModel[] = useSelector(
        inventoriesDashboardSearch.selectors.currentResultsViewModelsSelector
    );

    const toast = useToast();

    const totalCount = useSelector(inventoriesDashboardSearch.selectors.totalCountSelector);
    // @ts-expect-error Need to Type the createSearchModule abstraction
    const { from: queryFrom, size: querySize } = useSelector(
        inventoriesDashboardSearch.selectors.currentQuerySelector
    );

    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);

    const dispatch = useDispatch();

    const pageSize = querySize ?? DEFAULT_PAGE_SIZE;
    const currentPageNumber = fromSizeToPage(queryFrom + 1, pageSize);

    const onPageSizeChange = useCallback(
        (event) => {
            const newPageSize = event.currentTarget.value;
            /** Calculate which page contains the first result from the current visible page. */
            const newPageNumber = Math.ceil(queryFrom / newPageSize);

            dispatch(
                inventoriesDashboardSearch.actionCreators.search({
                    from: newPageNumber * newPageSize - newPageSize,
                    size: newPageSize,
                })
            );
        },
        [queryFrom, dispatch]
    );

    const onNavigateToPage = useCallback(
        (pageNumber: number) => {
            dispatch(
                inventoriesDashboardSearch.actionCreators.search(
                    {
                        from: pageNumber * pageSize - pageSize,
                        size: pageSize,
                    },
                    { cacheBust: true }
                )
            );
        },
        [dispatch, pageSize]
    );

    const onPageIndexChange = useCallback(
        (e) => {
            const pageNumber = Number(e.target.value) + 1;
            onNavigateToPage(pageNumber);
        },
        [onNavigateToPage]
    );

    const onNavigateToInventoryPage = useCallback(
        (inventoryId: number) => {
            router.push(`/evidence/inventories/${inventoryId}`);
        },
        [router]
    );

    // Note: Column Sorting in Arc can only be done via one column at a time.
    // This is in parralel with how the existing Inventories & Audits DataTable also behaves.
    // We need to map the sortingOptions back to the correct elastic sorting key/value before running
    // the search. We also don't want to fetch it every time so we won't apply a force refresh (via cacheBust)
    const handleColumnSorting = useCallback(
        (sorting) => {
            let sortKey;
            let sortType;
            const sortOption = sorting[0];
            if (sortOption) {
                const { id, desc } = sortOption;

                if (id === 'completedDateUtc') {
                    sortKey = sqlSortKeyEnum.INVENTORY_COMPLETED_DATE_UTC;
                    sortType = desc
                        ? sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT
                        : sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT;
                } else if (id === 'missingItemsCount') {
                    sortKey = sqlSortKeyEnum.INVENTORY_MISSING_ITEMS_COUNT;
                    sortType = desc
                        ? sortTypeEnum.NUMBER_HIGHEST_TO_LOWEST
                        : sortTypeEnum.NUMBER_LOWEST_TO_HIGHEST;
                }

                if (sortKey && sortType) {
                    dispatch(
                        inventoriesDashboardSearch.actionCreators.search({
                            sortKey,
                            sortType,
                        })
                    );
                }
            }
        },
        [dispatch]
    );

    const onExportClick = useCallback(
        (selectedRows: InventoriesDashboardResult[] | 'ALL_SELECTED') => {
            let convertLegacySelectedRowsToIndexIds: number[] = [];
            if (selectedRows !== 'ALL_SELECTED') {
                convertLegacySelectedRowsToIndexIds = selectedRows.map((row) => {
                    const rowId = row.id;
                    return inventoriesDashboardSearchResults.findIndex(({ id }) => id === rowId);
                });
                invariant(
                    convertLegacySelectedRowsToIndexIds.length === selectedRows.length,
                    'Failed to get all the selected rows from the current search results.'
                );
            } else {
                dispatch(inventoriesDashboardSearch.actionCreators.selectAllResults());
            }

            inventoryExportResource
                .getInventoryPrintables()
                .then((printables) => {
                    if (printables?.length > 0) {
                        dispatch(
                            inventoriesDashboardSearch.actionCreators.exportResults(
                                convertLegacySelectedRowsToIndexIds,
                                selectedRows === 'ALL_SELECTED',
                                map(printables, 'printingTemplateId')
                            )
                        );
                        dispatch(
                            createUsageLog({
                                primaryEntityType: EntityTypeEnum.EVIDENCE_INVENTORY.name,
                                primaryEntityTitle: `Export ${selectedRows.length} Inventories`,
                                primaryEntityDepartmentId: currentUserDepartmentId,
                                sourceModule: UsageSourceModuleEnum.EVIDENCE.name,
                                action: UsageActionEnum.EXPORTED_INVENTORIES.name,
                                completion: UsageCompletionEnum.SUCCEEDED.name,
                            })
                        );
                    } else {
                        dispatch(
                            createUsageLog({
                                primaryEntityType: EntityTypeEnum.EVIDENCE_INVENTORY.name,
                                primaryEntityTitle: `Export ${selectedRows.length} Inventories`,
                                primaryEntityDepartmentId: currentUserDepartmentId,
                                sourceModule: UsageSourceModuleEnum.EVIDENCE.name,
                                action: UsageActionEnum.EXPORTED_INVENTORIES.name,
                                completion: UsageCompletionEnum.SERVER_ERROR.name,
                            })
                        );
                        throw new Error('Failed to export inventory, please reload and try again');
                    }
                })
                .catch((err) => {
                    toast({
                        status: 'error',
                        description: err.message,
                    });
                });
        },
        [dispatch, inventoriesDashboardSearchResults, currentUserDepartmentId, toast]
    );

    return (
        <DataTable<InventoriesViewModel>
            columns={columnDefinitions}
            data={inventoriesDashboardSearchResults}
            hasColumnOrdering
            hasColumnVisibility
            hasColumnResizing
            hasColumnSorting
            hasRowSelection
            hasStickyHeaders
            onColumnSortChange={handleColumnSorting}
            tableProps={{ variant: 'striped' }}
            renderRow={({ row, renderedCells }) => {
                const { id: inventoryId } = row.original;
                return (
                    <Tr isInteractive onClick={() => onNavigateToInventoryPage(inventoryId)}>
                        {renderedCells}
                    </Tr>
                );
            }}
            bulkActions={[
                {
                    type: 'button',
                    props: {
                        title: 'Export',
                        trailingVisual: 'Export',
                        onClick: (selectedRows, e) => {
                            e.preventDefault();
                            onExportClick(selectedRows);
                        },
                    },
                },
            ]}
            paginationProps={{
                pageIndex: currentPageNumber - 1,
                pageSize,
                totalSize: Number(totalCount),
                pageSizeOptions: [25, 50, 75, 100],
                onNextPage: () => onNavigateToPage(currentPageNumber + 1),
                onPrevPage: () => onNavigateToPage(currentPageNumber - 1),
                onPageIndexChange,
                onPageSizeChange,
            }}
        />
    );
};

export const InventoriesDashboardDataTable = withRouter(BaseInventoriesDashboardDataTable);
