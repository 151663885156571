import { useSelector } from 'react-redux';
import React, { PropsWithChildren, ReactNode } from 'react';
import { applicationSettingsSelector } from '../state/data';
import settingsConfig from '../core/configuration';

type FeatureFlaggedProps = {
    fallback?: ReactNode;
    flag: keyof typeof settingsConfig;
};

const FeatureFlagged = ({
    children,
    fallback,
    flag,
}: PropsWithChildren<FeatureFlaggedProps>): JSX.Element | null => {
    const featureFlags = useSelector(applicationSettingsSelector);
    const isFeatureFlagTurnedOn = featureFlags[flag];

    if (!isFeatureFlagTurnedOn && !fallback) {
        return null;
    }

    return <>{isFeatureFlagTurnedOn ? children : fallback}</>;
};

export default FeatureFlagged;
