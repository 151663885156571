import { EFileNameSearchFilter, EFileNameSearchRequest } from '@mark43/rms-api';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sqlSortKeyEnum from '~/client-common/core/enums/universal/sqlSortKeyEnum';
import { EFileSearchRequest, InvolvedProfileLinkType } from '../../../../types';

const INVOLVED_PROFILES_PAGE_SIZE = 50;
const INVOLVED_PROFILES_DEFAULT_SORT = {
    sortKey: sqlSortKeyEnum.E_FILE_NAME_LINK_NAME,
    sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
};

export const useProfilesQuery = (
    linkType: InvolvedProfileLinkType,
    filters: EFileNameSearchFilter | undefined
): EFileSearchRequest<EFileNameSearchRequest> => ({
    from: 0,
    size: INVOLVED_PROFILES_PAGE_SIZE,
    query: {
        ...(filters ? { filters }: {}),
        linkTypes: [linkType],
    },
    sorts: [INVOLVED_PROFILES_DEFAULT_SORT],
});
