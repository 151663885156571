import {
    createField,
    createFormConfiguration,
    createNItems,
    InferFormDataShape,
} from 'markformythree';
import { AttributeTypeEnum, EFileView } from '@mark43/rms-api';
import { compact, find, groupBy, map, uniqWith } from 'lodash';
import {
    E_FILE_ATTRIBUTE_ATTRIBUTE_TYPE_COURT_UNIT_ATTRIBUTE_ID,
    E_FILE_ATTRIBUTE_ATTRIBUTE_TYPE_E_FILE_CLASSIFICATION_ATTRIBUTE_ID,
    E_FILE_ATTRIBUTE_TYPE_E_FILE_STATUS_STATUS_ATTR_ID,
    E_FILE_ROLE_LINK_ATTRIBUTE_TYPE_E_FILE_ROLE_INVOLVEMENT_TYPE_INVOLVEMENT_TYPE_ATTR_ID,
    E_FILE_ROLE_LINK_N_ITEM_WRAPPER,
    E_FILE_ROLE_LINK_ROLE_ID,
    E_FILE_OFFICER_USER_ID,
    E_FILE_OWNER_USER_ID,
    E_FILE_TITLE,
} from '~/client-common/core/enums/universal/fields';
import {
    EFileDetailsModalPropsT,
    EFileCreationRequestT,
    EFileUpdateRequestT,
} from '../../../types';

export const detailsEFileFormConfiguration = createFormConfiguration({
    title: createField<string>({ fieldName: E_FILE_TITLE }),
    caseClassificationAttrIds: createField<number[]>({
        fieldName: E_FILE_ATTRIBUTE_ATTRIBUTE_TYPE_E_FILE_CLASSIFICATION_ATTRIBUTE_ID,
    }),
    officerId: createField<number>({ fieldName: E_FILE_OFFICER_USER_ID }),
    caseOwnerId: createField<number>({ fieldName: E_FILE_OWNER_USER_ID }),
    courtUnitId: createField<number>({
        fieldName: E_FILE_ATTRIBUTE_ATTRIBUTE_TYPE_COURT_UNIT_ATTRIBUTE_ID,
    }),
    involvedPersonnels: createNItems({
        fieldName: E_FILE_ROLE_LINK_N_ITEM_WRAPPER,
        fields: {
            involvedPersonnelId: createField<number>({ fieldName: E_FILE_ROLE_LINK_ROLE_ID }),
            involvedPersonnelRoleId: createField<number>({
                fieldName:
                    E_FILE_ROLE_LINK_ATTRIBUTE_TYPE_E_FILE_ROLE_INVOLVEMENT_TYPE_INVOLVEMENT_TYPE_ATTR_ID,
            }),
        },
    }),
    caseStatusAttrId: createField<number>({
        fieldName: E_FILE_ATTRIBUTE_TYPE_E_FILE_STATUS_STATUS_ATTR_ID,
    }),
});

export type DetailsEFileFormConfigurationT = typeof detailsEFileFormConfiguration;
export type DetailsEFileFormDataShapeT = InferFormDataShape<DetailsEFileFormConfigurationT>;

export function convertFromFormModelToEFileCreation(
    formModel: DetailsEFileFormDataShapeT,
    entityData: EFileDetailsModalPropsT
): EFileCreationRequestT | undefined {
    const model = convertFromFormModel(formModel);

    const {
        officerId: officerUserId,
        caseOwnerId: ownerUserId,
        caseStatusAttrId: statusAttrId,
        title,
        courtUnitId,
        caseClassificationAttrIds = [],
        involvedPersonnels = [],
    } = model;

    const efile: EFileCreationRequestT['efile'] = {
        officerUserId,
        ownerUserId,
        statusAttrId,
        title,
    };

    const efileAttributes: EFileCreationRequestT['efileAttributes'] = compact([
        courtUnitId
            ? {
                  attributeId: courtUnitId,
                  attributeType: AttributeTypeEnum.COURT_UNIT.name,
              }
            : null,
        ...map(caseClassificationAttrIds, (attrId) => ({
            attributeId: attrId,
            attributeType: AttributeTypeEnum.E_FILE_CLASSIFICATION.name,
        })),
    ]);

    const involvedRoles: EFileCreationRequestT['involvedRoles'] = compact(
        map(involvedPersonnels, ({ involvedPersonnelId, involvedPersonnelRoleId }) => {
            if (involvedPersonnelId && involvedPersonnelRoleId) {
                return {
                    involvementTypeAttrId: involvedPersonnelRoleId,
                    roleId: involvedPersonnelId,
                };
            }
            return undefined;
        })
    );

    return {
        efile,
        efileAttributes,
        involvedRoles,
        parentEntityId: entityData.entityId,
        parentEntityType: entityData.entityType,
    };
}

export function convertFromFormModelToEFileUpsert(
    formModel: DetailsEFileFormDataShapeT,
    eFileView: EFileView
): EFileUpdateRequestT {
    const model = convertFromFormModel(formModel);

    const {
        officerId: officerUserId,
        caseOwnerId: ownerUserId,
        caseStatusAttrId: statusAttrId,
        title,
        courtUnitId,
        caseClassificationAttrIds = [],
        involvedPersonnels = [],
    } = model;

    const {
        efile: { localId, id },
        efileAttributes: attributes,
        involvedRoles: roles,
    } = eFileView;

    const efile: EFileUpdateRequestT['efile'] = {
        id,
        localId: localId || '',
        officerUserId,
        ownerUserId,
        statusAttrId,
        title,
    };

    const eFileAttrs = groupBy(attributes, 'attributeType');

    const courtUnitToKeep = find(eFileAttrs[AttributeTypeEnum.COURT_UNIT.name], [
        'attributeId',
        courtUnitId,
    ]);

    const efileAttributes: EFileUpdateRequestT['efileAttributes'] = compact([
        courtUnitId
            ? {
                  id: courtUnitToKeep && courtUnitToKeep.id,
                  attributeId: courtUnitToKeep ? courtUnitToKeep.attributeId : courtUnitId,
                  attributeType: AttributeTypeEnum.COURT_UNIT.name,
              }
            : null,
        ...map(caseClassificationAttrIds, (attrId) => {
            const exisitingClassification = find(
                eFileAttrs[AttributeTypeEnum.E_FILE_CLASSIFICATION.name],
                ['attributeId', attrId]
            );

            // if attribute exist then update by ID
            return {
                id: exisitingClassification && exisitingClassification.id,
                attributeId: attrId,
                attributeType: AttributeTypeEnum.E_FILE_CLASSIFICATION.name,
            };
        }),
    ]);

    const involvedRoles: EFileUpdateRequestT['involvedRoles'] = compact(
        map(involvedPersonnels, ({ involvedPersonnelId, involvedPersonnelRoleId }) => {
            if (involvedPersonnelId && involvedPersonnelRoleId) {
                const existingPersonnel = find(roles, ['roleId', involvedPersonnelId]);

                // if attribute exist then update by ID
                return {
                    id: existingPersonnel && existingPersonnel.id,
                    involvementTypeAttrId: involvedPersonnelRoleId,
                    roleId: involvedPersonnelId,
                };
            }
            return undefined;
        })
    );

    return {
        efile,
        efileAttributes,
        involvedRoles,
    };
}

export function convertToFormModel(viewModel: EFileView): DetailsEFileFormDataShapeT {
    const { efile, efileAttributes, involvedRoles } = viewModel;

    const attributes = groupBy(efileAttributes, 'attributeType');

    return {
        title: efile.title,
        officerId: efile.officerUserId,
        caseOwnerId: efile.ownerUserId,
        caseStatusAttrId: efile.statusAttrId,
        courtUnitId: attributes[AttributeTypeEnum.COURT_UNIT.name]?.[0]?.attributeId,
        caseClassificationAttrIds: map(
            attributes[AttributeTypeEnum.E_FILE_CLASSIFICATION.name],
            'attributeId'
        ),
        involvedPersonnels: map(involvedRoles, ({ involvementTypeAttrId, roleId }) => ({
            involvedPersonnelRoleId: involvementTypeAttrId,
            involvedPersonnelId: roleId,
        })),
    };
}

function convertFromFormModel(formModel: DetailsEFileFormDataShapeT): DetailsEFileFormDataShapeT {
    const {
        officerId,
        caseOwnerId,
        caseStatusAttrId,
        title,
        courtUnitId,
        caseClassificationAttrIds = [],
        involvedPersonnels = [],
    } = formModel;

    return {
        officerId,
        caseOwnerId,
        caseStatusAttrId,
        title,
        courtUnitId,
        caseClassificationAttrIds,
        involvedPersonnels: uniqWith(
            involvedPersonnels,
            (arrVal, othVal) =>
                arrVal.involvedPersonnelId === othVal.involvedPersonnelId &&
                arrVal.involvedPersonnelRoleId === othVal.involvedPersonnelRoleId
        ),
    };
}
