import React from 'react';
import { Flex } from 'arc';
import SavedSearchModal from '../../../../search/saved-search/components/SavedSearchModal';
import { evidenceDashboardSearch } from '../../state/ui';
import { SearchModuleType } from '../../../../../core/typings/search-module';
import { useManageEvidenceFilterContext } from './ManageEvidenceDashboardFilters';
import { ManageEvidenceSavedSearchButton } from './ManageEvidenceSavedSearchButton';

export const ManageEvidenceSavedSearch = () => {
    const { setApplyingSavedSearch } = useManageEvidenceFilterContext();

    return (
        <Flex>
            <SavedSearchModal
                searchModule={evidenceDashboardSearch as SearchModuleType}
                onSavedSearchExecuteSuccess={() => setApplyingSavedSearch(true)}
            />
            <ManageEvidenceSavedSearchButton />
        </Flex>
    );
};
