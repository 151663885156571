import _, {
    partialRight,
    isEmpty,
    times,
    reject,
    map,
    merge,
    mapValues,
    filter,
    noop,
    assign,
    isMatch,
    isObject,
    identity,
    includes,
} from 'lodash';
import invariant from 'invariant';
import { createSelector } from 'reselect';

import { makeResettable } from '../../../../../helpers/reducerHelpers';
import { buildHashKeyForSearchQuery } from '../../../../../helpers/searchHelpers';
import {
    convertSavedSearchQueryToElasticQuery,
    buildElasticQueryDisplayString,
    sortSavedSearches,
    getSavedSearchById,
} from '../../../../../helpers/advancedSearchHelpers';
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';

import { queryParamDefaults } from '../../../../../configs/advancedSearchConfig';

import sortKeyEnum from '../../../../../core/enums/universal/sortKeyEnum';
import sortTypeEnum from '../../../../../core/enums/universal/sortTypeEnum';
import { formatFieldValueRRF as formatFieldValue } from '../../../../../helpers/formHelpers';
import { dateTimeDefaultFormatter } from '../../../../../core/dates/utils/dateHelpers';
import { formatFieldByNameSelector } from '../../../../../core/fields/state/config';

const SCROLL_TO_RESULTS_DURATION = 300;

/**
 * Creates a packaged module of functions and objects that can be used to easily
 *   shape, alter and represent (via ui) state for a given type of search in
 *   Redux. Corresponding additions to `uiReducer` will be necessary for your
 *   module's state to appear in the Redux store.
 *   If you add a parameter make sure to make it noop unless it has been added
 *   to CAD AND RMS
 * @param  {string}   elasticSearchType         A search type from
 *   `elasticSearchTypeEnum`.
 * @param  {Object}   [form]                    Form module.
 * @param  {function} baseUiSelector            A selector which will select the
 *   root of your module/s ui state from the Redux store. Should correspond to
 *   an entry made to `uiReducer`.
 * @param  {Object}   [defaultTableState]       Default state for a table which
 *   represents search results. This would most commonly be used to define
 *   initial 'activeColumnKeys' for any OptionsTableColumns in your table.
 * @param  {function} elasticQueryToFilterGroups Helper to convert an elastic
 *   query to an array of filter group view models.
 * @param  {function} [searchResultToRoutePath] Helper to compute the route path
 *   of a search result.
 * @param  {function} [resourceMethod]          A method which will be called to
 *   perform search requests for the necessary type of search. This should most
 *   likely be a member of ElasticSearchResource.
 * @param  {function} [exportResourceMethod]    A method which will be called to
 *   perform an export.
 * @param  {function} [loadSearchExportPrintablesResourceMethod]  A method which will be called
 *   to gather layouts before performing an export.
 * @param  {string}   resultsContainerClassName A class name which can be used
 *   to dictate scroll actions which occur after successful or failed searches
 * @return {any} A search module (for use with Redux).
 */
export default function createSearchModule({
    /**
     *  IF YOU ADD A PARAMETER MAKE SURE TO MAKE IT "NOOP" UNLESS IT HAS
     *  BEEN ADDED TO CAD AND RMS.
     */
    applicationSettingsSelector = noop, // evidence
    elasticSearchType,
    form,
    baseUiSelector,
    defaultTableState,
    elasticQueryToFilterGroups,
    searchResultToRoutePath,
    resourceMethod,
    exportResourceMethod,
    loadSearchExportPrintablesResourceMethod,
    resultsContainerClassName,
    createResultsViewModelsSelector = noop,
    formatDispatchAreaByIdSelector = noop, // used by CAD only
    formatRadioChannelByIdSelector = noop,
    formatAbilityByIdSelector = noop, // CAD does not pass this, thus we need a default value
    formatAttributeByIdSelector = noop,
    formatLinkTypeByIdSelector = noop,
    formatNibrsCodeByCodeSelector = noop,
    formatNibrsOffenseCodeByIdSelector = noop,
    formatOffenseCodeByIdSelector = noop,
    formatRoleNameByRoleIdSelector = noop,
    formatSubdivisionsByIdsSelector = noop,
    formatCaseDefinitionByIdSelector = noop,
    formatReportDefinitionByIdSelector = noop,
    formatUcrCodeByCodeSelector = noop,
    formatUserByIdSelector = noop,
    formatUnitByIdSelector = noop,
    formatCallForServiceByIdSelector = noop,
    formatVehicleMakeByIdSelector = noop,
    formatVehicleModelByIdSelector = noop,
    formatAtfManufacturerByIdSelector = noop,
    formatChainEventTypeByIdSelector = noop, // evidence
    formatElasticStorageLocationByIdSelector = noop, // evidence
    formatFacilityByIdSelector = noop, // evidence
    buildSearchActionCreator = noop,
    buildExportResultsActionCreator = noop,
    buildLoadSearchExportPrintablesActionCreator = noop,
    buildLoadSavedSearchesActionCreator = noop,
    buildLoadAndExecuteLatestAutoSavedSearch = noop,
    buildExecuteSavedSearchActionCreator = noop,
    buildUpdateSavedSearchActionCreator = noop,
    buildDeleteSavedSearchActionCreator = noop,
    buildSaveSearchActionCreator = noop,
    buildRenameSavedSearchActionCreator = noop,
    buildOpenSearchResultActionCreator = noop,
    buildSubscribeSavedSearchActionCreator = noop,
    buildShareSavedSearchActionCreator = noop,
    buildSetFavoriteSavedSearchActionCreator = noop,
    scrollToElement = noop,
    selectorToBind = noop,
    formatUnitStateByIdSelector = noop,
    formatAgencyProfileByIdSelector = noop,
    formatDepartmentByIdSelector = noop,
    stationNamesByIdSelector = noop,
    transformElasticQueryBeforeSearchSelector = () => identity,
    updateActiveColumnKeysBySortKey = identity,
    currentDepartmentDateFormatterSelector = () => dateTimeDefaultFormatter,
    /**
     *  IF YOU ADD A PARAMETER MAKE SURE TO MAKE IT "NOOP" UNLESS IT HAS
     *  BEEN ADDED TO CAD AND RMS.
     */
}) {
    const actionTypes = actionTypesForElasticSearchType(elasticSearchType);
    const actionCreators = actionCreatorsForActionTypes(
        actionTypes,
        resultsContainerClassName,
        scrollToElement
    );

    // there is always a ui reducer
    const uiReducer = uiReducerForActionTypes(actionTypes, defaultTableState, {
        updateActiveColumnKeysBySortKey,
    });

    const selectors = selectorsForElasticSearchType(
        baseUiSelector,
        elasticQueryToFilterGroups,
        createResultsViewModelsSelector,
        formatDispatchAreaByIdSelector,
        formatRadioChannelByIdSelector,
        formatAbilityByIdSelector,
        formatAttributeByIdSelector,
        formatLinkTypeByIdSelector,
        formatNibrsCodeByCodeSelector,
        formatNibrsOffenseCodeByIdSelector,
        formatCaseDefinitionByIdSelector,
        formatRoleNameByRoleIdSelector,
        formatOffenseCodeByIdSelector,
        formatReportDefinitionByIdSelector,
        formatUcrCodeByCodeSelector,
        formatUserByIdSelector,
        formatUnitByIdSelector,
        formatCallForServiceByIdSelector,
        formatVehicleMakeByIdSelector,
        formatVehicleModelByIdSelector,
        formatAtfManufacturerByIdSelector,
        formatChainEventTypeByIdSelector, // evidence
        formatElasticStorageLocationByIdSelector, // evidence
        formatFacilityByIdSelector, // evidence
        selectorToBind,
        formatUnitStateByIdSelector,
        formatAgencyProfileByIdSelector,
        formatDepartmentByIdSelector,
        stationNamesByIdSelector,
        applicationSettingsSelector,
        transformElasticQueryBeforeSearchSelector,
        formatSubdivisionsByIdsSelector,
        currentDepartmentDateFormatterSelector
    );

    // we only have a search action creator if we're given the required helper
    const searchActionCreator = resourceMethod
        ? buildSearchActionCreator(
              actionCreators,
              selectors,
              form,
              resourceMethod,
              transformElasticQueryBeforeSearchSelector,
              elasticSearchType
          )
        : undefined;

    const exportResultsActionCreator = exportResourceMethod
        ? buildExportResultsActionCreator(
              actionCreators,
              selectors,
              exportResourceMethod,
              transformElasticQueryBeforeSearchSelector
          )
        : undefined;

    const loadSearchExportPrintablesActionCreator = loadSearchExportPrintablesResourceMethod
        ? buildLoadSearchExportPrintablesActionCreator(
              actionCreators,
              loadSearchExportPrintablesResourceMethod
          )
        : undefined;

    const loadSavedSearchesActionCreator = buildLoadSavedSearchesActionCreator(
        actionCreators,
        elasticSearchType
    );

    const loadAndExecuteLatestAutoSavedSearch = buildLoadAndExecuteLatestAutoSavedSearch(
        actionCreators,
        elasticSearchType
    );

    const executeSavedSearchActionCreator = buildExecuteSavedSearchActionCreator(
        actionCreators,
        selectors,
        form,
        transformElasticQueryBeforeSearchSelector
    );

    const updateSavedSearchActionCreator = buildUpdateSavedSearchActionCreator(
        actionCreators,
        selectors,
        elasticSearchType,
        transformElasticQueryBeforeSearchSelector
    );

    const deleteSavedSearchActionCreator = buildDeleteSavedSearchActionCreator(actionCreators);

    const saveSearchActionCreator = buildSaveSearchActionCreator(
        actionCreators,
        elasticSearchType,
        transformElasticQueryBeforeSearchSelector
    );

    const renameSavedSearchActionCreator = buildRenameSavedSearchActionCreator(
        actionCreators,
        selectors
    );

    const openSearchResultActionCreator = searchResultToRoutePath
        ? buildOpenSearchResultActionCreator(actionCreators, searchResultToRoutePath)
        : undefined;

    const subscribeSavedSearchActionCreator =
        buildSubscribeSavedSearchActionCreator(actionCreators);

    const shareSavedSearchActionCreator = buildShareSavedSearchActionCreator(actionCreators);

    const setFavoriteSavedSearchActionCreator =
        buildSetFavoriteSavedSearchActionCreator(actionCreators);

    // merge built action creators into `actionCreators` object so they can
    // be referenced within the built functions
    merge(actionCreators, {
        search: searchActionCreator,
        exportResults: exportResultsActionCreator,
        loadSearchExportPrintables: loadSearchExportPrintablesActionCreator,
        openSearchResult: openSearchResultActionCreator,
        // Saved Search specific actions.
        loadSavedSearches: loadSavedSearchesActionCreator,
        loadAndExecuteLatestAutoSavedSearch,
        executeSavedSearch: executeSavedSearchActionCreator,
        deleteSavedSearch: deleteSavedSearchActionCreator,
        saveSearch: saveSearchActionCreator,
        updateSavedSearch: updateSavedSearchActionCreator,
        renameSavedSearch: renameSavedSearchActionCreator,
        subscribeSavedSearch: subscribeSavedSearchActionCreator,
        shareSavedSearch: shareSavedSearchActionCreator,
        setFavoriteSavedSearch: setFavoriteSavedSearchActionCreator,
    });
    return {
        form,
        resultsContainerClassName,
        reducers: {
            uiReducer,
        },
        selectors,
        actionCreators,
        actionTypes,
        elasticSearchType,
        scrollToElement: () =>
            scrollToElement(`.${resultsContainerClassName}`, SCROLL_TO_RESULTS_DURATION),
    };
}

function actionTypesForElasticSearchType(elasticSearchType) {
    return {
        // perform search
        searchStart: `ADVANCED_SEARCH_${elasticSearchType}_START`,
        searchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_SUCCESS`,
        searchFailure: `ADVANCED_SEARCH_${elasticSearchType}_FAILURE`,
        // remove results
        removeResult: `ADVANCED_SEARCH_${elasticSearchType}_REMOVE_RESULT`,
        // patch results conditionally
        patchResultsWhere: `ADVANCED_SEARCH_${elasticSearchType}_PATCH_RESULTS_WHERE`,
        // row selection
        selectRows: `ADVANCED_SEARCH_${elasticSearchType}_SELECT_ROWS`,
        selectAllResults: `ADVANCED_SEARCH_${elasticSearchType}_SELECT_ALL_RESULTS`,
        // row highlight
        highlightRows: `ADVANCED_SEARCH_${elasticSearchType}_HIGHLIGHT_ROWS`,
        // csv exports
        exportResultsStart: `ADVANCED_SEARCH_${elasticSearchType}_EXPORT_RESULTS_START`,
        exportResultsSuccess: `ADVANCED_SEARCH_${elasticSearchType}_EXPORT_RESULTS_SUCCESS`,
        exportResultsFailure: `ADVANCED_SEARCH_${elasticSearchType}_EXPORT_RESULTS_FAILURE`,
        loadSearchExportPrintablesStart: `ADVANCED_SEARCH_${elasticSearchType}_LOAD_SEARCH_EXPORT_LAYOUTS_START`,
        loadSearchExportPrintablesSuccess: `ADVANCED_SEARCH_${elasticSearchType}_LOAD_SEARCH_EXPORT_LAYOUTS_SUCCESS`,
        loadSearchExportPrintablesFailure: `ADVANCED_SEARCH_${elasticSearchType}_LOAD_SEARCH_EXPORT_LAYOUTS_FAILURE`,
        // general helpers
        resetState: `ADVANCED_SEARCH_${elasticSearchType}_RESET_STATE`,
        resetSearchState: `ADVANCED_SEARCH_${elasticSearchType}_RESET_SEARCH_STATE`, // to not reset ui
        // table state
        setActiveColumnKey: `ADVANCED_SEARCH_${elasticSearchType}_SET_ACTIVE_COLUMN_KEY`,
        // saved searches
        loadSavedSearchesStart: `ADVANCED_SEARCH_${elasticSearchType}_LOAD_SAVED_SEARCHES_START`,
        loadSavedSearchesSuccess: `ADVANCED_SEARCH_${elasticSearchType}_LOAD_SAVED_SEARCHES_SUCCESS`,
        loadSavedSearchesFailure: `ADVANCED_SEARCH_${elasticSearchType}_LOAD_SAVED_SEARCHES_FAILURE`,
        loadLatestAutoSavedSearchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_LOAD_LATEST_AUTO_SAVED_SEARCH_SUCCESS`,
        executeSavedSearchStart: `ADVANCED_SEARCH_${elasticSearchType}_EXECUTE_SAVED_SEARCH_START`,
        executeSavedSearchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_EXECUTE_SAVED_SEARCH_SUCCESS`,
        executeSavedSearchFailure: `ADVANCED_SEARCH_${elasticSearchType}_EXECUTE_SAVED_SEARCH_FAILURE`,
        deleteSavedSearchStart: `ADVANCED_SEARCH_${elasticSearchType}_DELETE_SAVED_SEARCH_START`,
        deleteSavedSearchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_DELETE_SAVED_SEARCH_SUCCESS`,
        deleteSavedSearchFailure: `ADVANCED_SEARCH_${elasticSearchType}_DELETE_SAVED_SEARCH_FAILURE`,
        saveSearchStart: `ADVANCED_SEARCH_${elasticSearchType}_SAVE_SEARCH_START`,
        saveSearchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_SAVE_SEARCH_SUCCESS`,
        saveSearchFailure: `ADVANCED_SEARCH_${elasticSearchType}_SAVE_SEARCH_FAILURE`,
        openSavedSearchRenameForm: `ADVANCED_SEARCH_${elasticSearchType}_SAVED_SEARCH_RENAME_FORM_OPEN`,
        closeSavedSearchRenameForm: `ADVANCED_SEARCH_${elasticSearchType}_SAVED_SEARCH_RENAME_FORM_CLOSE`,
        renameSavedSearchStart: `ADVANCED_SEARCH_${elasticSearchType}_RENAME_SAVED_SEARCH_START`,
        renameSavedSearchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_RENAME_SAVED_SEARCH_SUCCESS`,
        closeSavedSearchesUi: `ADVANCED_SEARCH_${elasticSearchType}_CLOSE_SAVED_SEARCHES_UI`,
        subscribeSavedSearchStart: `ADVANCED_SEARCH_${elasticSearchType}_SUBSCRIBE_SAVED_SEARCH_START`,
        subscribeSavedSearchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_SUBSCRIBE_SAVED_SEARCH_SUCCESS`,
        shareSavedSearchStart: `ADVANCED_SEARCH_${elasticSearchType}_SHARE_SAVED_SEARCH_START`,
        shareSavedSearchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_SHARE_SAVED_SEARCH_SUCCESS`,
        setFavoriteSavedSearchStart: `ADVANCED_SEARCH_${elasticSearchType}_SET_FAVORITE_SAVED_SEARCH_START`,
        setFavoriteSavedSearchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_SET_FAVORITE_SAVED_SEARCH_SUCCESS`,
        updateSavedSearchStart: `ADVANCED_SEARCH_${elasticSearchType}_UPDATE_SAVED_SEARCH_START`,
        updateSavedSearchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_UPDATE_SAVED_SEARCH_SUCCESS`,
        copySavedSearchSuccess: `ADVANCED_SEARCH_${elasticSearchType}_COPY_SAVED_SEARCH_SUCCESS`,
        setRenameSavedSearchSection: `ADVANCED_SEARCH_${elasticSearchType}_SET_RENAME_SAVED_SEARCH_SECTION`,
        setIsSavedSearchUpdatable: `ADVANCED_SEARCH_${elasticSearchType}_SET_IS_SAVED_SEARCH_UPDATABLE`,
        setExecutedSavedSearchToUpdate: `ADVANCED_SEARCH_${elasticSearchType}_SET_EXECUTED_SAVED_SEARCH_TO_UPDATE`,
        // Error for rename, subscribe or share
        savedSearchFailure: `ADVANCED_SEARCH_${elasticSearchType}_SAVED_SEARCH_FAILURE`,
        // scroll ui
        setIsInitialSearch: `ADVANCED_SEARCH_${elasticSearchType}_SET_IS_INITIAL_SEARCH`,
        setScrollPosition: `ADVANCED_SEARCH_${elasticSearchType}_SET_SCROLL_POSITION`,
        setTriggerScroll: `ADVANCED_SEARCH_${elasticSearchType}_SET_TRIGGER_SCROLL`,
    };
}

function actionCreatorsForActionTypes(actionTypes, resultsContainerClassName, scrollToElement) {
    return {
        searchStart: () => ({ type: actionTypes.searchStart }),
        setActiveColumnKey: (key, value) => ({
            type: actionTypes.setActiveColumnKey,
            payload: {
                key,
                value,
            },
        }),
        searchSuccess: (searchResult, scroll = true) => {
            if (scroll && resultsContainerClassName) {
                scrollToElement(`.${resultsContainerClassName}`, SCROLL_TO_RESULTS_DURATION);
            }
            return {
                type: actionTypes.searchSuccess,
                payload: searchResult,
            };
        },
        searchFailure: (readOnlyText, actionText = '', scroll = true) => {
            if (scroll && resultsContainerClassName) {
                scrollToElement(`.${resultsContainerClassName}`, SCROLL_TO_RESULTS_DURATION);
            }
            return {
                type: actionTypes.searchFailure,
                payload: {
                    readOnlyText,
                    actionText,
                },
            };
        },
        removeResult: (value, key = 'id') => ({
            type: actionTypes.removeResult,
            payload: {
                value,
                key,
            },
        }),
        patchResultsWhere: (patch, predicate) => {
            invariant(isObject(patch), 'Patch must be an object');
            invariant(isObject(predicate), 'Predicate must be an object');
            return {
                type: actionTypes.patchResultsWhere,
                payload: {
                    patch,
                    predicate,
                },
            };
        },
        selectRows: (rows) => ({
            type: actionTypes.selectRows,
            payload: rows,
        }),
        selectAllResults: () => ({
            type: actionTypes.selectAllResults,
        }),
        highlightRows: (rows) => ({
            type: actionTypes.highlightRows,
            payload: rows,
        }),
        exportResultsStart: () => ({
            type: actionTypes.exportResultsStart,
        }),
        exportResultsSuccess: (resultExports) => ({
            type: actionTypes.exportResultsSuccess,
            payload: resultExports,
        }),
        exportResultsFailure: (errorMessage) => ({
            type: actionTypes.exportResultsFailure,
            payload: errorMessage,
        }),
        loadSearchExportPrintablesStart: () => ({
            type: actionTypes.loadSearchExportPrintablesStart,
        }),
        loadSearchExportPrintablesSuccess: (layouts) => ({
            type: actionTypes.loadSearchExportPrintablesSuccess,
            payload: layouts,
        }),
        loadSearchExportPrintablesFailure: (errorMessage) => ({
            type: actionTypes.loadSearchExportPrintablesFailure,
            payload: errorMessage,
        }),
        resetState: () => ({
            type: actionTypes.resetState,
        }),
        resetSearchState: () => ({
            type: actionTypes.resetSearchState,
        }),
        // Saved Searches action creators
        loadSavedSearchesStart: () => ({
            type: actionTypes.loadSavedSearchesStart,
        }),
        loadSavedSearchesSuccess: (savedSearchesResult) => ({
            type: actionTypes.loadSavedSearchesSuccess,
            payload: savedSearchesResult,
        }),
        loadSavedSearchesFailure: (loadSavedSearchesErrorMessage) => ({
            type: actionTypes.loadSavedSearchesFailure,
            payload: loadSavedSearchesErrorMessage,
        }),
        loadLatestAutoSavedSearchSuccess: (autoSavedSearchResult) => ({
            type: actionTypes.loadLatestAutoSavedSearchSuccess,
            payload: autoSavedSearchResult,
        }),
        executeSavedSearchStart: () => ({
            type: actionTypes.executeSavedSearchStart,
        }),
        executeSavedSearchSuccess: (savedSearch) => ({
            type: actionTypes.executeSavedSearchSuccess,
            payload: savedSearch,
        }),
        executeSavedSearchFailure: (executeSavedSearchErrorMessage) => ({
            type: actionTypes.executeSavedSearchFailure,
            payload: executeSavedSearchErrorMessage,
        }),
        deleteSavedSearchStart: () => ({
            type: actionTypes.deleteSavedSearchStart,
        }),
        deleteSavedSearchSuccess: (savedSearchId) => ({
            type: actionTypes.deleteSavedSearchSuccess,
            payload: savedSearchId,
        }),
        deleteSavedSearchFailure: (deleteSavedSearchErrorMessage) => ({
            type: actionTypes.deleteSavedSearchFailure,
            payload: deleteSavedSearchErrorMessage,
        }),
        saveSearchStart: () => ({
            type: actionTypes.saveSearchStart,
        }),
        saveSearchSuccess: (savedSearch) => ({
            type: actionTypes.saveSearchSuccess,
            payload: savedSearch,
        }),
        saveSearchFailure: (saveErrorMessage) => ({
            type: actionTypes.saveSearchFailure,
            payload: saveErrorMessage,
        }),
        openSavedSearchRenameForm: (savedSearchId) => ({
            type: actionTypes.openSavedSearchRenameForm,
            payload: savedSearchId,
        }),
        closeSavedSearchRenameForm: () => ({
            type: actionTypes.closeSavedSearchRenameForm,
        }),
        renameSavedSearchStart: () => ({
            type: actionTypes.renameSavedSearchStart,
        }),
        renameSavedSearchSuccess: (savedSearch) => ({
            type: actionTypes.renameSavedSearchSuccess,
            payload: savedSearch,
        }),

        closeSavedSearchesUi: () => ({
            type: actionTypes.closeSavedSearchesUi,
        }),
        subscribeSavedSearchStart: () => ({
            type: actionTypes.subscribeSavedSearchStart,
        }),
        subscribeSavedSearchSuccess: (savedSearch) => ({
            type: actionTypes.subscribeSavedSearchSuccess,
            payload: savedSearch,
        }),
        shareSavedSearchStart: () => ({
            type: actionTypes.shareSavedSearchStart,
        }),
        shareSavedSearchSuccess: (savedSearch) => ({
            type: actionTypes.shareSavedSearchSuccess,
            payload: savedSearch,
        }),
        setFavoriteSavedSearchStart: () => ({
            type: actionTypes.setFavoriteSavedSearchStart,
        }),
        setFavoriteSavedSearchSuccess: (savedSearch) => ({
            type: actionTypes.setFavoriteSavedSearchSuccess,
            payload: savedSearch,
        }),
        updateSavedSearchStart: () => ({
            type: actionTypes.updateSavedSearchStart,
        }),
        updateSavedSearchSuccess: (savedSearch) => ({
            type: actionTypes.updateSavedSearchSuccess,
            payload: savedSearch,
        }),
        savedSearchFailure: (savedSearchErrorForId) => ({
            type: actionTypes.savedSearchFailure,
            payload: savedSearchErrorForId,
        }),
        setIsSavedSearchUpdatable: (isSavedSearchUpdatable) => ({
            type: actionTypes.setIsSavedSearchUpdatable,
            payload: isSavedSearchUpdatable,
        }),
        setExecutedSavedSearchToUpdate: (executedSavedSearchToUpdate) => ({
            type: actionTypes.setExecutedSavedSearchToUpdate,
            payload: executedSavedSearchToUpdate,
        }),
        setIsInitialSearch: (isInitialSearch) => ({
            type: actionTypes.setIsInitialSearch,
            payload: isInitialSearch,
        }),
        setScrollPosition: (scrollPosition) => ({
            type: actionTypes.setScrollPosition,
            payload: scrollPosition,
        }),
        setTriggerScroll: (triggerScroll) => ({
            type: actionTypes.setTriggerScroll,
            payload: triggerScroll,
        }),
        copySavedSearchSuccess: (savedSearch) => ({
            type: actionTypes.copySavedSearchSuccess,
            payload: savedSearch,
        }),
        setRenameSavedSearchSection: (savedSearchSection) => ({
            type: actionTypes.setRenameSavedSearchSection,
            payload: savedSearchSection,
        }),
    };
}

function uiReducerForActionTypes(
    actionTypes,
    { from, size, sortKey, sortType, activeColumnKeys = {} } = { activeColumnKeys: {} },
    { updateActiveColumnKeysBySortKey = identity }
) {
    const baseState = {
        savedSearches: [],
        savedSearchesLoading: false,
        loadSavedSearchesErrorMessage: '',
        savedSearchIdCurrentlyRenaming: null,
        savedSearchSectionCurrentlyRenaming: null,
        savedSearchErrorForId: {
            savedSearchId: null,
            message: '',
        },
        isCurrentQueryStale: false,
        tableLoading: false,
        errorMessage: {
            readOnlyText: '',
            actionText: '',
        },
        currentQuery: {
            savedSearchId: null,
            isAutoSave: false,
            from: from || queryParamDefaults.FROM,
            size: size || queryParamDefaults.SIZE,
            sortKey: sortKey || sortKeyEnum.RELEVANCE,
            sortType: sortType || sortTypeEnum.RELEVANCE,
        },
        successfulSearchHasBeenPerformed: false,
        totalCount: 0,
        results: {},
        selectedRows: [],
        highlightedRows: [],
        allResultsSelected: false,
        exportDisabled: false,
        activeColumnKeys,
        layouts: [],
        isInitialSearch: false,
        scrollPosition: 0,
        triggerScroll: true,
        executedSavedSearchToUpdate: null,
        isSavedSearchUpdatable: false,
    };
    return makeResettable(
        actionTypes.resetState,
        // eslint-disable-next-line prefer-arrow-callback
        function advancedSearchReducer(state = baseState, action) {
            switch (action.type) {
                case actionTypes.selectRows:
                    return {
                        ...state,
                        selectedRows: [...action.payload],
                        allResultsSelected: false,
                        exportDisabled: false,
                    };
                case actionTypes.selectAllResults:
                    const currentResultSize =
                        state.results[buildHashKeyForSearchQuery(state.currentQuery)].length;

                    return {
                        ...state,
                        selectedRows: times(currentResultSize),
                        allResultsSelected: true,
                        exportDisabled: false,
                    };
                case actionTypes.highlightRows:
                    return {
                        ...state,
                        highlightedRows: action.payload,
                    };
                case actionTypes.resetSearchState:
                    return {
                        ...state,
                        results: baseState.results,
                        scrollPosition: state.scrollPosition,
                    };
                case actionTypes.searchStart:
                case actionTypes.exportResultsStart:
                    return {
                        ...state,
                        tableLoading: true,
                        errorMessage: {
                            readOnlyText: '',
                            actionText: '',
                        },
                        savedSearchesLoading: false,
                        loadSavedSearchesErrorMessage: '',
                        isCurrentQueryStale: false,
                        currentQuery: {
                            ...state.currentQuery,
                            savedSearchId: null,
                            isAutoSave: false,
                        },
                        exportDisabled: true,
                    };
                case actionTypes.searchFailure:
                case actionTypes.loadSearchExportPrintablesFailure:
                case actionTypes.exportResultsFailure:
                    return {
                        ...state,
                        tableLoading: false,
                        errorMessage: {
                            readOnlyText: action.payload.readOnlyText,
                            actionText: action.payload.actionText,
                        },
                        exportDisabled: false,
                        triggerScroll: true,
                        executedSavedSearchToUpdate: null,
                    };
                case actionTypes.searchSuccess:
                    const { newResultIds = [], items } = action.payload;
                    return {
                        ...state,
                        tableLoading: false,
                        errorMessage: {
                            readOnlyText: '',
                            actionText: '',
                        },
                        totalCount: action.payload.totalCount,
                        currentQuery: {
                            ...action.payload.query,
                        },
                        successfulSearchHasBeenPerformed: true,
                        results: {
                            ...state.results,
                            [buildHashKeyForSearchQuery({ ...action.payload.query })]: map(
                                items,
                                (item) => {
                                    return {
                                        ...item,
                                        isNewResult: includes(newResultIds, item.id),
                                    };
                                }
                            ),
                        },
                        selectedRows: [],
                        highlightedRows: [],
                        exportDisabled: false,
                        isInitialSearch: false,
                        scrollPosition: state.isInitialSearch ? state.scrollPosition : 0,
                        triggerScroll: true,
                        activeColumnKeys: updateActiveColumnKeysBySortKey(
                            state.activeColumnKeys, // activeColumnKeys has to be first argument so it will get returned by _.identity
                            action.payload.query.sortKey
                        ),
                    };
                case actionTypes.removeResult:
                    return {
                        ...state,
                        results: mapValues(state.results, (resultList) =>
                            filter(
                                resultList,
                                (result) => result[action.payload.key] !== action.payload.value
                            )
                        ),
                    };
                case actionTypes.patchResultsWhere:
                    return {
                        ...state,
                        results: mapValues(state.results, (resultList) =>
                            map(resultList, (result) =>
                                isMatch(result, action.payload.predicate)
                                    ? assign(result, action.payload.patch)
                                    : result
                            )
                        ),
                    };
                case actionTypes.exportResultsSuccess:
                    return {
                        ...state,
                        tableLoading: false,
                        exportDisabled: false,
                    };
                case actionTypes.loadSearchExportPrintablesSuccess:
                    return {
                        ...state,
                        layouts: action.payload,
                    };
                case actionTypes.setActiveColumnKey:
                    return {
                        ...state,
                        activeColumnKeys: {
                            ...state.activeColumnKeys,
                            [action.payload.key]: action.payload.value,
                        },
                        exportDisabled: false,
                    };
                // Saved Searches action types
                case actionTypes.loadSavedSearchesStart:
                    return {
                        ...state,
                        savedSearchesLoading: true,
                        savedSearches: [],
                        loadSavedSearchesErrorMessage: '',
                        savedSearchErrorForId: {
                            savedSearchId: null,
                            message: '',
                        },
                    };
                case actionTypes.loadSavedSearchesSuccess:
                case actionTypes.loadLatestAutoSavedSearchSuccess:
                    return {
                        ...state,
                        savedSearchesLoading: false,
                        savedSearches: action.payload,
                        loadSavedSearchesErrorMessage: '',
                    };
                case actionTypes.loadSavedSearchesFailure:
                    return {
                        ...state,
                        savedSearchesLoading: false,
                        savedSearches: [],
                        loadSavedSearchesErrorMessage: action.payload,
                        executedSavedSearchToUpdate: null,
                        isSavedSearchUpdatable: false,
                    };
                case actionTypes.executeSavedSearchStart:
                    return {
                        ...state,
                        savedSearchesLoading: true,
                        loadSavedSearchesErrorMessage: '',
                        isCurrentQueryStale: false,
                        currentQuery: {
                            ...state.currentQuery,
                            savedSearchId: null,
                            isAutoSave: false,
                        },
                        savedSearchErrorForId: {
                            savedSearchId: null,
                            message: '',
                        },
                    };
                case actionTypes.executeSavedSearchSuccess:
                    return {
                        ...state,
                        savedSearchesLoading: false,
                        loadSavedSearchesErrorMessage: '',
                        currentQuery: {
                            ...state.currentQuery,
                            savedSearchId: action.payload.id,
                            isAutoSave: action.payload.isAutoSave,
                        },
                        isCurrentQueryStale: action.payload.isQueryStale,
                        executedSavedSearchToUpdate: !action.payload.isAutoSave
                            ? action.payload
                            : null,
                        isSavedSearchUpdatable: !action.payload.isAutoSave,
                    };
                case actionTypes.executeSavedSearchFailure:
                    return {
                        ...state,
                        savedSearchesLoading: false,
                        loadSavedSearchesErrorMessage: action.payload,
                        executedSavedSearchToUpdate: null,
                        isSavedSearchUpdatable: false,
                    };
                case actionTypes.deleteSavedSearchStart:
                    return {
                        ...state,
                        savedSearchesLoading: true,
                        loadSavedSearchesErrorMessage: '',
                        savedSearchErrorForId: {
                            savedSearchId: null,
                            message: '',
                        },
                    };
                case actionTypes.deleteSavedSearchSuccess:
                    const savedSearchesAfterDelete = reject(state.savedSearches, {
                        id: action.payload,
                    });

                    return {
                        ...state,
                        savedSearchesLoading: false,
                        loadSavedSearchesErrorMessage: '',
                        savedSearches: savedSearchesAfterDelete,
                        currentQuery: {
                            ...state.currentQuery,
                            isAutoSave: false,
                            savedSearchId:
                                state.currentQuery.savedSearchId === action.payload
                                    ? null
                                    : state.currentQuery.savedSearchId,
                        },
                        executedSavedSearchToUpdate: null,
                        isSavedSearchUpdatable: false,
                    };
                case actionTypes.deleteSavedSearchFailure:
                    return {
                        ...state,
                        savedSearchesLoading: false,
                        loadSavedSearchesErrorMessage: action.payload,
                    };
                case actionTypes.saveSearchStart:
                    return {
                        ...state,
                        savedSearchesLoading: true,
                        loadSavedSearchesErrorMessage: '',
                        savedSearchErrorForId: {
                            savedSearchId: null,
                            message: '',
                        },
                    };
                case actionTypes.saveSearchSuccess:
                    return {
                        ...state,
                        savedSearchesLoading: false,
                        loadSavedSearchesErrorMessage: '',
                        currentQuery: {
                            ...state.currentQuery,
                            savedSearchId: action.payload.id,
                            isAutoSave: false,
                        },
                        savedSearches: [action.payload, ...state.savedSearches],
                        executedSavedSearchToUpdate: action.payload,
                        isSavedSearchUpdatable: true,
                    };
                case actionTypes.saveSearchFailure:
                    return {
                        ...state,
                        savedSearchesLoading: false,
                        loadSavedSearchesErrorMessage: action.payload,
                        executedSavedSearchToUpdate: null,
                        isSavedSearchUpdatable: false,
                    };
                case actionTypes.openSavedSearchRenameForm:
                    return {
                        ...state,
                        savedSearchIdCurrentlyRenaming: action.payload,
                        savedSearchErrorForId: {
                            savedSearchId: null,
                            message: '',
                        },
                    };
                case actionTypes.closeSavedSearchRenameForm:
                    return {
                        ...state,
                        savedSearchIdCurrentlyRenaming: null,
                    };
                case actionTypes.renameSavedSearchStart:
                    return {
                        ...state,
                        savedSearchesLoading: true,
                        savedSearchIdCurrentlyRenaming: null,
                        savedSearchErrorForId: {
                            savedSearchId: null,
                            message: '',
                        },
                    };
                case actionTypes.renameSavedSearchSuccess:
                    // For renaming a saved search -- upon successful rename, the API will return the new `Saved Search` object.
                    // To be verbose, we will "temporarily" delete the `Saved Search` that was updated, then re-insert the updated
                    // `Saved Search` object returned by the API.
                    const savedSearchesAfterTempDelete = reject(state.savedSearches, {
                        id: action.payload.id,
                    });
                    const newSavedSearches = sortSavedSearches(
                        _(savedSearchesAfterTempDelete).concat(action.payload).value()
                    );

                    return {
                        ...state,
                        savedSearchesLoading: false,
                        savedSearches: newSavedSearches,
                    };

                case actionTypes.savedSearchFailure:
                    return {
                        ...state,
                        savedSearchesLoading: false,
                        savedSearchErrorForId: {
                            savedSearchId: action.payload.savedSearchId,
                            message: action.payload.message,
                        },
                    };
                case actionTypes.closeSavedSearchesUi:
                    return {
                        ...state,
                        savedSearchesLoading: false,
                        savedSearches: [],
                        savedSearchIdCurrentlyRenaming: null,
                        loadSavedSearchesErrorMessage: '',
                        savedSearchErrorForId: {
                            savedSearchId: null,
                            message: '',
                        },
                    };
                case actionTypes.shareSavedSearchStart:
                case actionTypes.setFavoriteSavedSearchStart:
                case actionTypes.subscribeSavedSearchStart:
                case actionTypes.updateSavedSearchStart:
                    return {
                        ...state,
                        savedSearchesLoading: true,
                        savedSearchErrorForId: {
                            savedSearchId: null,
                            message: '',
                        },
                    };
                case actionTypes.shareSavedSearchSuccess:
                case actionTypes.setFavoriteSavedSearchSuccess:
                case actionTypes.subscribeSavedSearchSuccess:
                case actionTypes.updateSavedSearchSuccess:
                    const updatedSavedSearches = map(state.savedSearches, (savedSearch) => {
                        if (savedSearch.id === action.payload.id) {
                            return action.payload;
                        }
                        return savedSearch;
                    });
                    return {
                        ...state,
                        savedSearchesLoading: false,
                        savedSearches: updatedSavedSearches,
                    };
                case actionTypes.setIsSavedSearchUpdatable:
                    return {
                        ...state,
                        isSavedSearchUpdatable: action.payload,
                    };
                case actionTypes.setExecutedSavedSearchToUpdate:
                    return {
                        ...state,
                        executedSavedSearchToUpdate: action.payload,
                    };
                case actionTypes.copySavedSearchSuccess:
                    return {
                        ...state,
                        savedSearches: [...state.savedSearches, action.payload],
                    };
                case actionTypes.setIsInitialSearch:
                    return {
                        ...state,
                        isInitialSearch: action.payload,
                    };
                case actionTypes.setScrollPosition:
                    return {
                        ...state,
                        scrollPosition: action.payload,
                    };
                case actionTypes.setTriggerScroll:
                    return {
                        ...state,
                        triggerScroll: action.payload,
                    };
                case actionTypes.setRenameSavedSearchSection:
                    return {
                        ...state,
                        savedSearchSectionCurrentlyRenaming: action.payload,
                    };
                default:
                    return state;
            }
        },
        baseState,
        (baseState, state) => ({
            ...baseState,
            executedSavedSearchToUpdate: state.executedSavedSearchToUpdate,
            isSavedSearchUpdatable: state.isSavedSearchUpdatable,
            savedSearches: state.savedSearches,
            activeColumnKeys: state.activeColumnKeys,
        })
    );
}

function selectorsForElasticSearchType(
    uiStateSelector,
    elasticQueryToFilterGroups,
    createResultsViewModelsSelector,
    formatDispatchAreaByIdSelector,
    formatRadioChannelByIdSelector,
    formatAbilityByIdSelector,
    formatAttributeByIdSelector,
    formatLinkTypeByIdSelector,
    formatNibrsCodeByCodeSelector,
    formatNibrsOffenseCodeByIdSelector,
    formatCaseDefinitionByIdSelector,
    formatRoleNameByRoleIdSelector,
    formatOffenseCodeByIdSelector,
    formatReportDefinitionByIdSelector,
    formatUcrCodeByCodeSelector,
    formatUserByIdSelector,
    formatUnitByIdSelector,
    formatCallForServiceByIdSelector,
    formatVehicleMakeByIdSelector,
    formatVehicleModelByIdSelector,
    formatAtfManufacturerByIdSelector,
    formatChainEventTypeByIdSelector, // evidence
    formatElasticStorageLocationByIdSelector, // evidence
    formatFacilityByIdSelector, // evidence
    selectorToBind,
    formatUnitStateByIdSelector,
    formatAgencyProfileByIdSelector,
    formatDepartmentByIdSelector,
    stationNamesByIdSelector,
    applicationSettingsSelector,
    transformElasticQueryBeforeSearchSelector,
    formatSubdivisionsByIdsSelector,
    currentDepartmentDateFormatterSelector
) {
    const currentQuerySelector = createSelector(
        uiStateSelector,
        ({ currentQuery }) => currentQuery
    );

    const currentResultsSelector = createSelector(
        uiStateSelector,
        currentQuerySelector,
        ({ results }, currentQuery) => results[buildHashKeyForSearchQuery(currentQuery)]
    );

    const savedSearchesSelector = createSelector(
        uiStateSelector,
        ({ savedSearches }) => savedSearches
    );

    const savedSearchSectionCurrentlyRenamingSelector = createSelector(
        uiStateSelector,
        ({ savedSearchSectionCurrentlyRenaming }) => savedSearchSectionCurrentlyRenaming
    );

    const formatElasticQuerySelector = createSelector(
        formatDispatchAreaByIdSelector,
        formatRadioChannelByIdSelector,
        formatAbilityByIdSelector,
        formatAttributeByIdSelector,
        formatLinkTypeByIdSelector,
        formatNibrsCodeByCodeSelector,
        formatNibrsOffenseCodeByIdSelector,
        formatCaseDefinitionByIdSelector,
        formatRoleNameByRoleIdSelector,
        formatReportDefinitionByIdSelector,
        formatOffenseCodeByIdSelector,
        formatUcrCodeByCodeSelector,
        formatUserByIdSelector,
        formatUnitByIdSelector,
        formatCallForServiceByIdSelector,
        formatVehicleMakeByIdSelector,
        formatVehicleModelByIdSelector,
        formatAtfManufacturerByIdSelector,
        formatChainEventTypeByIdSelector, // evidence
        formatElasticStorageLocationByIdSelector, // evidence
        formatFacilityByIdSelector, // evidence
        formatFieldByNameSelector,
        selectorToBind || noop,
        formatUnitStateByIdSelector,
        formatAgencyProfileByIdSelector,
        formatDepartmentByIdSelector,
        stationNamesByIdSelector,
        applicationSettingsSelector,
        formatSubdivisionsByIdsSelector,
        currentDepartmentDateFormatterSelector,
        (
            formatDispatchAreaById,
            formatRadioChannelById,
            formatAbilityById,
            formatAttributeById,
            formatLinkTypeById,
            formatNibrsCodeByCode,
            formatNibrsOffenseCodeById,
            formatCaseDefinitionById,
            formatRoleNameByRoleId,
            formatReportDefinitionById,
            formatOffenseCodeById,
            formatUcrCodeByCode,
            formatUserById,
            formatUnitById,
            formatCallForServiceById,
            formatVehicleMakeById,
            formatVehicleModelById,
            formatAtfManufacturerById,
            formatChainEventTypeById, // evidence
            formatElasticStorageLocationById, // evidence
            formatFacilityById, // evidence
            formatFieldByName,
            boundSelector,
            formatUnitStateById,
            formatAgencyProfileById,
            formatDepartmentById,
            stationNamesById,
            applicationSettings,
            formatSubdivisionsByIds,
            dateTimeFormatter
        ) =>
            (elasticQuery) =>
                elasticQueryToFilterGroups(
                    elasticQuery,
                    // this function allows the helpers a way to format display strings
                    // that require state, like attributes
                    partialRight(formatFieldValue, {
                        applicationSettings,
                        formatDispatchAreaById,
                        formatRadioChannelById,
                        formatAbilityById,
                        formatAttributeById,
                        formatLinkTypeById,
                        formatNibrsCodeByCode,
                        formatNibrsOffenseCodeById,
                        formatCaseDefinitionById,
                        formatRoleNameByRoleId,
                        formatOffenseCodeById,
                        formatReportDefinitionById,
                        formatUcrCodeByCode,
                        formatUserById,
                        formatUnitById,
                        formatCallForServiceById,
                        formatVehicleMakeById,
                        formatVehicleModelById,
                        formatAtfManufacturerById,
                        formatChainEventTypeById, // evidence
                        formatElasticStorageLocationById, // evidence
                        formatFacilityById, // evidence
                        formatFieldByName,
                        formatUnitStateById,
                        formatAgencyProfileById,
                        formatDepartmentById,
                        stationNamesById,
                        formatSubdivisionsByIds,
                        dateTimeFormatter,
                    }),
                    boundSelector
                )
    );

    const savedSearchIdCurrentlyRenamingSelector = createSelector(
        uiStateSelector,
        ({ savedSearchIdCurrentlyRenaming }) => savedSearchIdCurrentlyRenaming
    );

    const savedSearchErrorForIdSelector = createSelector(
        uiStateSelector,
        ({ savedSearchErrorForId }) => savedSearchErrorForId
    );

    return {
        // Query / Results Selectors
        currentQuerySelector,
        tableLoadingSelector: createSelector(uiStateSelector, ({ tableLoading }) => tableLoading),
        totalCountSelector: createSelector(uiStateSelector, ({ totalCount }) => totalCount),
        errorMessageSelector: createSelector(uiStateSelector, ({ errorMessage }) => errorMessage),
        selectedRowsSelector: createSelector(uiStateSelector, ({ selectedRows }) => selectedRows),
        initialSearchSelector: createSelector(
            uiStateSelector,
            ({ isInitialSearch }) => isInitialSearch
        ),
        highlightedRowsSelector: createSelector(
            uiStateSelector,
            ({ highlightedRows }) => highlightedRows
        ),
        allResultsSelectedSelector: createSelector(
            uiStateSelector,
            ({ allResultsSelected }) => allResultsSelected
        ),
        exportDisabledSelector: createSelector(
            uiStateSelector,
            ({ exportDisabled }) => exportDisabled
        ),
        activeColumnKeysSelector: createSelector(
            uiStateSelector,
            ({ activeColumnKeys }) => activeColumnKeys
        ),
        successfulSearchHasBeenPerformedSelector: createSelector(
            uiStateSelector,
            ({ successfulSearchHasBeenPerformed }) => successfulSearchHasBeenPerformed
        ),
        hasResultsSelector: createSelector(uiStateSelector, ({ results }) => !isEmpty(results)),
        printablesSelector: createSelector(uiStateSelector, ({ layouts }) => layouts),
        currentResultsSelector,
        currentResultsViewModelsSelector: createResultsViewModelsSelector(currentResultsSelector),
        cachedResultsForQuerySelector: createSelector(
            uiStateSelector,
            ({ results }) =>
                (query) =>
                    results[buildHashKeyForSearchQuery(query)]
        ),
        // only provide the filters selector if we're given the required helper
        ...(elasticQueryToFilterGroups
            ? {
                  filtersSelector: createSelector(
                      currentQuerySelector,
                      formatElasticQuerySelector,
                      ({ elasticQuery }, formatElasticQuery) => formatElasticQuery(elasticQuery)
                  ),
              }
            : undefined),
        formatElasticQuerySelector,
        // Saved Search related selectors
        loadSavedSearchErrorMessageSelector: createSelector(
            uiStateSelector,
            ({ loadSavedSearchesErrorMessage }) => loadSavedSearchesErrorMessage
        ),
        savedSearchesLoadingSelector: createSelector(
            uiStateSelector,
            ({ savedSearchesLoading }) => savedSearchesLoading
        ),
        savedSearchesSelector,
        savedSearchesViewModelsSelector: createSelector(
            savedSearchesSelector,
            formatElasticQuerySelector,
            savedSearchIdCurrentlyRenamingSelector,
            savedSearchErrorForIdSelector,
            savedSearchSectionCurrentlyRenamingSelector,
            (
                savedSearches,
                formatElasticQuery,
                savedSearchIdCurrentlyRenaming,
                savedSearchErrorForId,
                savedSearchCurrentlyRenaming
            ) => {
                return map(
                    savedSearches,
                    buildViewModel({
                        recursive: false,
                        mappers: [
                            (savedSearch) => {
                                const elasticQueryDisplay = buildElasticQueryDisplayString(
                                    formatElasticQuery(
                                        convertSavedSearchQueryToElasticQuery(savedSearch.query)
                                    )
                                );

                                return {
                                    ...savedSearch,
                                    elasticQueryDisplay,
                                    renaming: savedSearchIdCurrentlyRenaming === savedSearch.id,
                                    renamingSection: savedSearchCurrentlyRenaming,
                                    errorMessage:
                                        savedSearchErrorForId.savedSearchId === savedSearch.id
                                            ? savedSearchErrorForId.message
                                            : '',
                                };
                            },
                        ],
                    })
                );
            }
        ),
        savedSearchIdCurrentlyRenamingSelector,
        savedSearchErrorForIdSelector,
        isCurrentQueryStaleSelector: createSelector(
            uiStateSelector,
            ({ isCurrentQueryStale }) => isCurrentQueryStale
        ),
        transformElasticQueryBeforeSearchSelector,
        scrollPositionSelector: createSelector(
            uiStateSelector,
            ({ scrollPosition }) => scrollPosition
        ),
        triggerScrollSelector: createSelector(
            uiStateSelector,
            ({ triggerScroll }) => triggerScroll
        ),
        executedSavedSearchToUpdateSelector: createSelector(
            uiStateSelector,
            ({ executedSavedSearchToUpdate }) => executedSavedSearchToUpdate
        ),
        currentSavedSearchSelector: createSelector(
            uiStateSelector,
            ({ executedSavedSearchToUpdate, savedSearches }) =>
                !!executedSavedSearchToUpdate
                    ? getSavedSearchById(savedSearches, executedSavedSearchToUpdate.id)
                    : undefined
        ),
        isSavedSearchUpdatableSelector: createSelector(
            uiStateSelector,
            ({ isSavedSearchUpdatable }) => isSavedSearchUpdatable
        ),
    };
}
