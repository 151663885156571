import React, { createContext, useContext, useCallback, useMemo } from 'react';
import {
    DiagramAssetViewModel,
    DiagramCategoryEnumType,
    DiagramTypeEnumType,
    DiagramCategoryEnum,
    DiagramTypeEnum,
} from '@mark43/rms-api';
import { useToast } from 'arc';
import { useSelector } from 'react-redux';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Widget, CrashDiagramPosition, WidgetAction } from '../types';
import { convertDiagramSvgStringToCrashDiagramState } from '../serializer';
import { diagramResource } from '../../../../../../diagram/resources/diagramResource';
import { convertDiagramAssetViewModelToAssets } from '../../../../../../admin/diagram-assets/helpers';
import { getDefaultAsset, getNextExternalAssetId, JPEG_DATA_ELEMENT } from '../helpers';
import {
    addWidgetToDiagram,
    removeWidgetFromDiagram,
    moveWidgetOnDiagram,
    setSelectedWidget,
    clearSelectedWidget,
    clearDiagram,
    resetDiagram,
    setDiagramBackgroundImage,
    updateWidgetValue,
    duplicateWidgetOnDiagram,
    createGhostWidgetsOnDiagram,
    applyWidgetActionOnDiagram,
    setCrashDiagramState,
    setWidgetOpacityOnDiagram,
    setDiagramAssets,
    addExternalDiagramAsset,
} from './reducer/diagram/actions';
import { RootCrashDiagramActions } from './reducer';
import { DiagramAssetsMap, CrashDiagramState } from './reducer/diagram';

const diagramModalErrorMessageStrings =
    componentStrings.dragon.crashDiagram.CrashDiagramModal.errorMessages;

type SetWidgetOpacityArgs = {
    widgetId: string;
    opacity: number;
};

type TransformWidgetArgs = {
    widgetId: string;
    position: CrashDiagramPosition;
};

type AddWidgetArgs = {
    widget: Widget;
};

type DeleteWidgetArgs = {
    widgetId: string;
};

type GhostWidgetArgs = {
    widgetId: string;
    numberOfDuplicates: number;
};

export type ApplyWidgetActionArgs = {
    widgetId: string;
    action: WidgetAction;
};

type UpdateWidgetValueArgs = {
    widgetId: string;
    value: Widget['value'];
    commit: boolean;
};

type CrashDiagramContextType = {
    widgets: Widget[];
    selectedWidget?: Widget;
    backgroundImage?: string;
    assets: DiagramAssetsMap;
    addWidget: (args: AddWidgetArgs) => void;
    fetchAssets: (
        diagramType: DiagramTypeEnumType,
        diagramCategory: DiagramCategoryEnumType
    ) => void;
    clear: () => void;
    reset: () => void;
    deleteWidget: (args: DeleteWidgetArgs) => void;
    duplicateWidget: (args: DeleteWidgetArgs) => void;
    addGhostWidgets: (args: GhostWidgetArgs) => void;
    applyWidgetAction: (args: ApplyWidgetActionArgs) => void;
    transformWidget: (args: TransformWidgetArgs) => void;
    setWidgetOpacity: (args: SetWidgetOpacityArgs) => void;
    selectWidget: (widget: Widget) => void;
    clearSelection: () => void;
    setBackground: (backgroundImage: string) => void;
    updateValue: (args: UpdateWidgetValueArgs) => void;
    setCrashDiagramFromSvg: (svgString: string) => void;
    addExternalAsset: (
        diagramType: DiagramTypeEnumType,
        diagramCategory: DiagramCategoryEnumType,
        dataUrl: string
    ) => void;
    isLoadingAssets: boolean;
    addMapBackgroundModalEnabled: boolean;
};

const CrashDiagramContext = createContext<CrashDiagramContextType | undefined>(undefined);

export function useCrashDiagram(): CrashDiagramContextType {
    const context = useContext(CrashDiagramContext);

    if (context === undefined) {
        throw new Error('Crash Diagram Context must be inside a CrashDiagramProvider with a value');
    }
    return context;
}

type CrashDiagramProviderProps = {
    state: CrashDiagramState;
    dispatch: React.Dispatch<RootCrashDiagramActions>;
};

export const CrashDiagramProvider: React.FC<CrashDiagramProviderProps> = ({
    state,
    dispatch,
    children,
}) => {
    const [isLoadingAssets, setIsLoadingAssets] = React.useState(false);
    const toast = useToast();

    const applicationSettings = useSelector(applicationSettingsSelector);
    const addMapBackgroundModalEnabled = !!(
        applicationSettings.LOCATIONS_LOCATION_SELECTION_ON_MAP_ENABLED &&
        applicationSettings.RMS_ESRI_MAP_IN_CRASH_DIAGRAM_ENABLED
    );

    const addWidget = useCallback(
        ({ widget }: AddWidgetArgs) => {
            dispatch(addWidgetToDiagram(widget));
        },
        [dispatch]
    );

    const clear = useCallback(() => {
        dispatch(clearDiagram());
    }, [dispatch]);

    const reset = useCallback(() => {
        dispatch(resetDiagram());
    }, [dispatch]);

    const duplicateWidget = useCallback(
        ({ widgetId }: DeleteWidgetArgs) => {
            dispatch(duplicateWidgetOnDiagram(widgetId));
        },
        [dispatch]
    );

    const addGhostWidgets = useCallback(
        ({ widgetId, numberOfDuplicates }: GhostWidgetArgs) => {
            dispatch(createGhostWidgetsOnDiagram(widgetId, numberOfDuplicates));
        },
        [dispatch]
    );

    const applyWidgetAction = useCallback(
        ({ widgetId, action }: ApplyWidgetActionArgs) => {
            dispatch(applyWidgetActionOnDiagram(widgetId, action));
        },
        [dispatch]
    );

    const setWidgetOpacity = useCallback(
        ({ widgetId, opacity }: SetWidgetOpacityArgs) => {
            dispatch(setWidgetOpacityOnDiagram(widgetId, opacity));
        },
        [dispatch]
    );

    const deleteWidget = useCallback(
        ({ widgetId }: DeleteWidgetArgs) => {
            dispatch(removeWidgetFromDiagram(widgetId));
        },
        [dispatch]
    );

    const transformWidget = useCallback(
        ({ widgetId, position }: TransformWidgetArgs) => {
            dispatch(moveWidgetOnDiagram(widgetId, position));
        },
        [dispatch]
    );

    const clearSelection = useCallback(() => {
        if (!state.selectedWidget) {
            return;
        }
        dispatch(clearSelectedWidget());
    }, [dispatch, state.selectedWidget]);

    const selectWidget = useCallback(
        (widget: Widget) => {
            clearSelection();
            dispatch(setSelectedWidget(widget));
        },
        [dispatch, clearSelection]
    );

    const setBackground = useCallback(
        (image: string) => {
            dispatch(setDiagramBackgroundImage(image));
        },
        [dispatch]
    );

    const updateValue = useCallback(
        ({ widgetId, value, commit }: UpdateWidgetValueArgs) => {
            dispatch(updateWidgetValue(widgetId, value, commit));
        },
        [dispatch]
    );

    const handleFetchSuccess = useCallback(
        (
            diagramAssetViewModel: DiagramAssetViewModel,
            diagramType: DiagramTypeEnumType,
            diagramCategory: DiagramCategoryEnumType
        ) => {
            const newAssets = convertDiagramAssetViewModelToAssets(diagramAssetViewModel);
            dispatch(setDiagramAssets(diagramType, diagramCategory, newAssets));
            setIsLoadingAssets(false);

            if (
                diagramCategory === DiagramCategoryEnum.CRASH_BACKGROUND.name &&
                !state.backgroundImage
            ) {
                const defaultAsset = getDefaultAsset(newAssets);
                if (!!defaultAsset) {
                    setBackground(defaultAsset.file.fileWebServerPath);
                }
            }
        },
        [dispatch, setBackground, state.backgroundImage]
    );

    const handleFetchFailure = useCallback(() => {
        toast({
            status: 'error',
            description: diagramModalErrorMessageStrings.unhandledAssetFetchException,
        });
        setIsLoadingAssets(false);
    }, [toast]);

    const fetchAssets = useCallback(
        (diagramType: DiagramTypeEnumType, diagramCategory: DiagramCategoryEnumType) => {
            setIsLoadingAssets(true);
            const categoryId = DiagramCategoryEnum[diagramCategory].value;
            diagramResource
                .getAssetsByCategory(categoryId)
                .then((diagramAssetViewModel: DiagramAssetViewModel) =>
                    handleFetchSuccess(diagramAssetViewModel, diagramType, diagramCategory)
                )
                .catch(handleFetchFailure);
        },
        [handleFetchFailure, handleFetchSuccess]
    );

    const addExternalAsset = useCallback(
        (
            diagramType: DiagramTypeEnumType,
            diagramCategory: DiagramCategoryEnumType,
            dataUrl: string
        ) => {
            if (!addMapBackgroundModalEnabled) {
                return;
            }

            const newAssetId = getNextExternalAssetId(state.assets, diagramType, diagramCategory);
            const newAsset = {
                id: newAssetId,
                name: `Map ${newAssetId}`,
                diagramCategoryId: DiagramCategoryEnum.CRASH_BACKGROUND.value,
                base64String: dataUrl,
            };
            dispatch(addExternalDiagramAsset(diagramType, diagramCategory, newAsset));
        },
        [addMapBackgroundModalEnabled, dispatch, state.assets]
    );

    const setCrashDiagramFromSvg = useCallback(
        (svgString: string) => {
            const crashDiagramState = convertDiagramSvgStringToCrashDiagramState(svgString);
            dispatch(setCrashDiagramState(crashDiagramState));

            const backgroundImage = crashDiagramState.backgroundImage;
            if (backgroundImage && backgroundImage.startsWith(JPEG_DATA_ELEMENT)) {
                addExternalAsset(
                    DiagramTypeEnum.CRASH.name,
                    DiagramCategoryEnum.CRASH_BACKGROUND.name,
                    backgroundImage
                );
            }
        },
        [addExternalAsset, dispatch]
    );

    const value = useMemo(() => {
        return {
            clear,
            fetchAssets,
            reset,
            addWidget,
            duplicateWidget,
            addGhostWidgets,
            applyWidgetAction,
            deleteWidget,
            transformWidget,
            selectWidget,
            clearSelection,
            setBackground,
            setCrashDiagramFromSvg,
            updateValue,
            setWidgetOpacity,
            addExternalAsset,
            widgets: state.widgets,
            selectedWidget: state.selectedWidget,
            backgroundImage: state.backgroundImage,
            assets: state.assets,
            isLoadingAssets,
            addMapBackgroundModalEnabled,
        };
    }, [
        addWidget,
        fetchAssets,
        clear,
        reset,
        duplicateWidget,
        addGhostWidgets,
        applyWidgetAction,
        deleteWidget,
        transformWidget,
        selectWidget,
        clearSelection,
        setBackground,
        updateValue,
        setCrashDiagramFromSvg,
        setWidgetOpacity,
        addExternalAsset,
        state.widgets,
        state.selectedWidget,
        state.backgroundImage,
        state.assets,
        isLoadingAssets,
        addMapBackgroundModalEnabled,
    ]);

    return <CrashDiagramContext.Provider value={value}>{children}</CrashDiagramContext.Provider>;
};
