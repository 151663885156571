import React, { FC, useCallback, useRef } from 'react';
import { InlineBanner } from 'arc';
import styled from 'styled-components';
import { EFileNameSearchFilter } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { DataTableWrapper } from '../../../../core/components/DataTableWrapper';
import { EFileInvolvedProfileGridRowT, InvolvedProfileLinkType } from '../../../types';
import { useProfileColumnsVisibility, useProfilesColumns } from './hooks/useProfilesColumns';
import { useProfilesSearch } from './hooks/useProfilesSearch';
import { useProfilesQuery } from './hooks/useProfilesQuery';
import { EFileProfilesGridRow } from './EFileProfilesGridRow';

const { names: profileNameStrings, grid: gridStrings } = componentStrings.eFiles.profiles;

const ProfilesDataTableWrapper = DataTableWrapper<EFileInvolvedProfileGridRowT>;

const StyledProfilesDataTableWrapper = styled(ProfilesDataTableWrapper)`
    height: 0;
    flex-grow: 1;

    & .arc-table_container {
        flex: 0 1 auto;
    }

    & .arc-table_body {
        vertical-align: top;
    }
`;

type EFileProfilesGridProps = {
    eFileId: number;
    linkType: InvolvedProfileLinkType;
    filters?: EFileNameSearchFilter;
};

export const EFileProfilesGrid: FC<EFileProfilesGridProps> = ({ eFileId, linkType, filters }) => {
    const actionsRef = useRef<HTMLDivElement>(null);
    const columns = useProfilesColumns(eFileId, linkType, actionsRef);
    const columnsVisibility = useProfileColumnsVisibility(linkType);
    const initialQuery = useProfilesQuery(linkType, filters);
    const profileName = profileNameStrings[linkType];

    const { results, loading, initialLoading, pagination, errorMessage, sort, onColumnSortChange } =
        useProfilesSearch(initialQuery, eFileId);

    const renderRow = useCallback(
        ({ row, renderedCells }) => (
            <EFileProfilesGridRow
                item={row.original}
                eFileId={eFileId}
                linkType={linkType}
                rowId={row.id}
                actionsRef={actionsRef}
            >
                {renderedCells}
            </EFileProfilesGridRow>
        ),
        [eFileId, linkType]
    );

    if (errorMessage) {
        return (
            <InlineBanner
                description={errorMessage}
                status="error"
                title={gridStrings.error(profileName)}
            />
        );
    }

    return (
        <StyledProfilesDataTableWrapper
            data={results}
            columns={columns}
            hasStickyHeaders={true}
            paginationProps={pagination}
            onColumnSortChange={onColumnSortChange}
            config={{ sort, visibility: columnsVisibility }}
            noDataText={gridStrings.noDataText(profileName)}
            loading={loading}
            initialLoading={initialLoading}
            renderRow={renderRow}
            hasRowSelection={true}
        />
    );
};
