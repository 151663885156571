import _, { chain, includes, indexOf } from 'lodash';
import {
    NameReportLink,
    LinkTypesEnumType,
    Attribute,
    LinkTypesEnum,
    NameItemLink,
} from '@mark43/rms-api';
import { FormatFieldByName } from '../core/fields/state/config';
import { formatAttributeValue } from '../core/domain/attributes/utils/attributesHelpers';
import { ModuleShape } from '../core/utils/createNormalizedModule';
import { formatNameReportLinkTypeId } from './linkTypesHelpers';
import { joinTruthyValues } from './stringHelpers';

// Priority as dictated by RMS-2053
export function nameReportLinkTypePriority(linkType: LinkTypesEnumType): number {
    const nameReportLinkTypePriorities = [
        LinkTypesEnum.MISSING_PERSON_IN_REPORT,
        LinkTypesEnum.DEFENDANT_IN_ARREST,
        LinkTypesEnum.VICTIM_IN_OFFENSE,
        LinkTypesEnum.VICTIM_IN_REPORT,
        LinkTypesEnum.SUSPECT_IN_OFFENSE,
        LinkTypesEnum.SUSPECT_IN_REPORT,
        LinkTypesEnum.OFFENDER_IN_OFFENSE,
        LinkTypesEnum.SUBJECT_IN_OFFENSE,
        LinkTypesEnum.SUBJECT_IN_USE_OF_FORCE,
        LinkTypesEnum.SUBJECT_IN_TRAFFIC_CRASH,
        LinkTypesEnum.SUBJECT_IN_STOP,
        LinkTypesEnum.SUBJECT_IN_FIELD_CONTACT,
        LinkTypesEnum.SUBJECT_IN_COMMUNITY_INFORMATION,
        LinkTypesEnum.SUBJECT_IN_BEHAVIORAL_CRISIS,
        LinkTypesEnum.SUBJECT_IN_CITATION,
        LinkTypesEnum.CO_DEFENDANT,
        LinkTypesEnum.ORGANIZATION_IN_FIELD_CONTACT,
        LinkTypesEnum.REPORTING_PARTY_IN_REPORT,
        LinkTypesEnum.COMPLAINANT_IN_REPORT,
        LinkTypesEnum.WITNESS_IN_OFFENSE,
        LinkTypesEnum.WITNESS_IN_REPORT,
        LinkTypesEnum.TOW_VEHICLE_RELEASE_TO,
        LinkTypesEnum.TOW_VEHICLE_RELEASE_AUTHORIZED_BY,
        LinkTypesEnum.INVOLVED_PERSON_IN_REPORT,
        LinkTypesEnum.INVOLVED_ORG_IN_REPORT,
        LinkTypesEnum.OTHER_NAME_IN_OFFENSE,
        LinkTypesEnum.OTHER_NAME_IN_REPORT,
        LinkTypesEnum.OTHER_NAME_IN_BEHAVIORAL_CRISIS,
        LinkTypesEnum.OTHER_NAME_IN_STOP,
        LinkTypesEnum.OTHER_NAME_IN_FIELD_CONTACT,
        LinkTypesEnum.BUSINESS_ADDRESS,
        LinkTypesEnum.BUSINESS_LOCATION,
        LinkTypesEnum.HANGS_OUT_AT,
        LinkTypesEnum.CONFIGURED_ENTITY_NAME_IN_REPORT,
    ];
    const index = indexOf(nameReportLinkTypePriorities, linkType);
    return index < 0 ? 999 : index;
}

/**
 * Filter the given relationships to those that involve the given persons/orgs (and report if given).
 *   Any duplicates in the result get removed as well.
 */
export function filterNameReportLinks(
    nameReportLinks: NameReportLink[],
    filterNameIds: number[]
): NameReportLink[] {
    return (
        _(nameReportLinks)
            .filter(({ nameId }) => {
                return includes(filterNameIds, nameId);
            })
            // remove duplicates
            .uniqBy(({ linkType, nameId, entityType, reportId, contextId }) => {
                return `${linkType}-${nameId}-${entityType}-${reportId}-${contextId}`;
            })
            .value()
    );
}

function formatNameReportLinkTypes(
    nameReportLinkTypes: LinkTypesEnumType[],
    formatFieldByName: FormatFieldByName
): string {
    return _(nameReportLinkTypes)
        .uniq()
        .sortBy((linkType) => nameReportLinkTypePriority(linkType))
        .map((linkType) => formatNameReportLinkTypeId(linkType, formatFieldByName))
        .join(', ');
}

/**
 * Formats linkTypes by using prioritization & display in functions above and formats
 * `sortedSubjectTypeAttrIds` by resolving the attr ids display value.  Comma-separates all resolved
 * strings and prioritizes involvement type strings to appear first.
 * @param {number[]} sortedSubjectTypeAttrIds  Sorted attr ids.  The sort order is variable;
 *      `NameReportLink`s will sort these attrs by `id` while `ElasticInvolvedPerson` and
 *      `ElasticInvolvedOrganization` will sort by their `nameReportLinkId` prop.
 */
export function formatInvolvement(
    linkTypes: LinkTypesEnumType[],
    sortedSubjectTypeAttrIds: number[],
    indexedAttributes: ModuleShape<Attribute>,
    formatFieldByName: FormatFieldByName
): string {
    const formattedLinkTypes = formatNameReportLinkTypes(linkTypes, formatFieldByName);
    const formattedSubjectTypes = joinTruthyValues(
        chain(sortedSubjectTypeAttrIds)
            .uniq()
            .map((subjectTypeAttrId) => formatAttributeValue(indexedAttributes[subjectTypeAttrId]))
            .value()
    );

    return joinTruthyValues([formattedSubjectTypes, formattedLinkTypes]);
}

/**
 * Get a comma-separated, ordered list of a name's report involvement.
 * Uses subjectTypeAttrId if it exists, otherwise linkType.
 */
export function getInvolvement(
    nameReportLinks: NameReportLink[],
    reportId: number,
    nameIds: number[],
    indexedAttributes: ModuleShape<Attribute>,
    formatFieldByName: FormatFieldByName,
    nameItemLinks: NameItemLink[]
) {
    const relevantNameReportLinks = chain(nameReportLinks)
        .filter({ reportId })
        .filter((nameReportLink) => includes(nameIds, nameReportLink.nameId))
        .value();

    const relevantItemLinks = nameItemLinks?.filter?.((itemLink) => {
        return relevantNameReportLinks?.find?.(
            (nameReportLink) => nameReportLink?.nameId === itemLink?.nameId
        );
    });
    const nameItemReportInvolvements = getNameItemReportInvolvement(relevantItemLinks, indexedAttributes);
    const nameReportInvolvements = getNameReportInvolvement(relevantNameReportLinks, indexedAttributes, formatFieldByName);
    
    return joinTruthyValues([nameReportInvolvements, nameItemReportInvolvements]);

}

function getNameItemReportInvolvement(
    nameItemLinks: NameItemLink[],
    indexedAttributes: ModuleShape<Attribute>
): string {
    const releveantAssociationAttrIds = chain(nameItemLinks)
        .map('nameItemAssociationAttrId')
        .value();

    const formattedItems = releveantAssociationAttrIds?.
        filter((itemId) => itemId)?.
        map((itemId) => {
            const relatedProperty = indexedAttributes?.[itemId as keyof typeof indexedAttributes];
            return relatedProperty ? `Item - ${relatedProperty?.displayValue}` : '';
        });

        return joinTruthyValues(formattedItems);
}

/**
 * Get a comma-separated, ordered list of name report involvements.
 * Uses subjectTypeAttrId if it exists, otherwise linkType.
 */
function getNameReportInvolvement(
    nameReportLinks: NameReportLink[],
    indexedAttributes: ModuleShape<Attribute>,
    formatFieldByName: FormatFieldByName
): string {
    const relevantLinkTypes = chain(nameReportLinks)
        .filter(({ subjectTypeAttrId }) => !subjectTypeAttrId)
        .map('linkType')
        .value();
    const relevantSubjectTypeAttrIds = chain(nameReportLinks)
        .sortBy('id')
        .map('subjectTypeAttrId')
        .compact()
        .value();

    return formatInvolvement(
        relevantLinkTypes,
        relevantSubjectTypeAttrIds,
        indexedAttributes,
        formatFieldByName
    );
}
