import { UniqueValueRenderer, SimpleFillSymbol, UniqueValueInfo } from '../constructors';

import type { SelectedShapefileT } from '../types';
import { stringToColor } from './mapHelpers';

const outline = {
    color: 'white',
};

export const getRendererForJson = (
    geoJSON: SelectedShapefileT
): UniqueValueRenderer | undefined => {
    if (!geoJSON.propertyName || !geoJSON.properties) {
        return undefined;
    }

    const renderer = new UniqueValueRenderer({
        field: geoJSON.propertyName,
        defaultSymbol: new SimpleFillSymbol({
            color: 'orange',
            outline,
        }),
        uniqueValueInfos:
            geoJSON.properties[geoJSON.propertyName]?.map(
                (property) =>
                    new UniqueValueInfo({
                        value: property,
                        symbol: new SimpleFillSymbol({
                            color: stringToColor(property.toString(), 0.6),
                            style: 'solid',
                            outline,
                        }),
                    })
            ) ?? [],
    });

    return renderer;
};
