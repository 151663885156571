import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { loadExportPresets } from '~/client-common/core/domain/export-presets/state/data';
import { reportDefinitionRestrictViewReportOwnersSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { useResource } from '~/client-common/core/hooks/useResource';
import {
    exportDefaultGroupTitleSelector,
    exportPageReleaseTrackingConfigSelector,
} from '../../../../legacy-redux/selectors/exportsSelectors';

import FormExports from '../../../../legacy-redux/components/exports/FormExports';
import { ExportPageWithColumns } from '../../../../legacy-redux/components/exports/LayoutComponents';

import { ReleaseTracking } from '../../../core/pdf-exports/release-tracking/components/ReleaseTracking';
import { currentReportSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import { loadReportExports } from '../state/ui';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import { useIsReportOwnerRestricted } from '../../../core/hooks/useIsReportOwnerRestricted';
import NoDataBlock from '../../../core/components/NoDataBlock';
import { RmsDispatch } from '../../../../core/typings/redux';
import {
    ReportFormExportsOptions,
    ReportFormExportsOptionsProps,
} from './ReportFormExportsOptions';

const strings = componentStrings.reports.core.ReportExports;

const Box = styled.div`
    width: 100%;
    margin: 1rem;
    text-align: center;
`;

const useLoadExportResources = (reportId?: number) => {
    const dispatch = useDispatch<RmsDispatch>();

    const loadReportExportsHandler = useCallback(
        () => dispatch(loadReportExports(reportId)),
        [dispatch, reportId]
    );
    const loadExportPresetsHandler = useCallback(() => dispatch(loadExportPresets()), [dispatch]);

    useResource(loadReportExportsHandler);
    useResource(loadExportPresetsHandler);
};

type ReportExportsProps = WithRouterProps & {
    closeCallback: () => void;
};

const ReportExports = ({ closeCallback, router }: ReportExportsProps) => {
    const exportDefaultGroupTitle = useSelector(exportDefaultGroupTitleSelector);
    const exportPageReleaseTrackingConfig = useSelector(exportPageReleaseTrackingConfigSelector);
    const currentReport = useSelector(currentReportSelector);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const reportDefinitionRestrictViewReportOwners = useSelector(
        reportDefinitionRestrictViewReportOwnersSelector
    );

    const reportId = currentReport?.id;

    useLoadExportResources(reportId);
    const checkIsReportOwnerRestricted = useIsReportOwnerRestricted();

    const onRedact = useCallback(() => {
        if (!!reportId) {
            router.push(`/reports/${reportId}/export/redact`);
        }
    }, [reportId, router]);

    if (!reportId) {
        return null;
    }

    const { showReleaseTracking, isCpsReport } = exportPageReleaseTrackingConfig(
        EntityTypeEnum.REPORT.name,
        reportId
    );
    const currentUserHasRedactReportExportsAbility = currentUserHasAbility(
        abilitiesEnum.CORE.REDACT_REPORT_EXPORTS
    );

    const restrictViewReportOwners = reportDefinitionRestrictViewReportOwners(
        currentReport.reportDefinitionId
    );
    const isReportOwnerRestricted = checkIsReportOwnerRestricted(
        restrictViewReportOwners,
        currentReport.permissionSet
    );

    if (isReportOwnerRestricted) {
        return (
            <Box>
                <NoDataBlock>{strings.cannotBeDisplayed}</NoDataBlock>
            </Box>
        );
    }

    return (
        <ExportPageWithColumns
            leftColumn={
                <FormExports
                    renderFormExportsOptions={({
                        packets,
                        packetOptions,
                        globalExportOptions,
                        handlePacketsChange,
                        handleExportOptionsChange,
                        selectedExportPresetId,
                        setExportPresetId,
                    }: ReportFormExportsOptionsProps) => (
                        <ReportFormExportsOptions
                            entityId={reportId}
                            packets={packets}
                            packetOptions={packetOptions}
                            globalExportOptions={globalExportOptions}
                            handlePacketsChange={handlePacketsChange}
                            handleExportOptionsChange={handleExportOptionsChange}
                            selectedExportPresetId={selectedExportPresetId}
                            setExportPresetId={setExportPresetId}
                        />
                    )}
                    closeCallback={closeCallback}
                    showFieldRedaction={false}
                    useExportRouteToggle={true}
                    includeAttachments={true}
                    showIncludeHistoryEvents={true}
                    showOnlyIncludeFieldsWithData={true}
                    showIncludeNameAddendums={true}
                    entityId={reportId}
                    entityType={EntityTypeEnum.REPORT.name}
                    internalExportAttachmentLinkType={LinkTypesEnum.REPORT_ATTACHMENT}
                    defaultFilename={strings.defaultTitle}
                    defaultGroupTitle={exportDefaultGroupTitle}
                    trackReleases={!isCpsReport}
                    showCancel={false}
                    submitButtonText={strings.export}
                    emailsEnabled={true}
                    onRedact={currentUserHasRedactReportExportsAbility && onRedact}
                />
            }
            rightColumn={
                showReleaseTracking && !isCpsReport ? (
                    <ReleaseTracking entityType={EntityTypeEnum.REPORT.name} entityId={reportId} />
                ) : undefined
            }
        />
    );
};

export default withRouter(ReportExports);
