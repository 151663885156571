import { OperationTypeEnumType } from '@mark43/rms-api';
import React, { PropsWithChildren } from 'react';
import { useIsReportOwnerRestricted } from '../hooks/useIsReportOwnerRestricted';
import { RestrictVisibilityWithDash } from './RestrictVisibilityWithDash';

export const ReportOwnerRestricted = ({
    restrictViewReportOwners,
    permissionSet,
    children,
}: PropsWithChildren<{
    restrictViewReportOwners: boolean;
    permissionSet: OperationTypeEnumType[];
}>) => {
    const checkIsReportOwnerRestricted = useIsReportOwnerRestricted();

    return (
        <RestrictVisibilityWithDash
            restricted={checkIsReportOwnerRestricted(restrictViewReportOwners, permissionSet)}
        >
            {children}
        </RestrictVisibilityWithDash>
    );
};
