import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import reportSealingForm from '../state/forms/reportSealingForm';
import courtOrderEditForm from '../../court-orders/state/forms/courtOrderEditForm';
import { chargesForArrestReportIdSelector } from '../state/ui';
import Select from '../../../core/forms/components/selects/Select';

import type { RootState } from '../../../../legacy-redux/reducers/rootReducer';

type SealingChargesSectionProps = {
    reportId: number;
    edit?: boolean;
};

const SealingChargesDropdown = (props: SealingChargesSectionProps) => {
    const { reportId, edit } = props;
    const dispatch = useDispatch();
    const chargesForArrestReportId = useSelector(chargesForArrestReportIdSelector);
    const formModelByPath = useSelector<RootState, (path: string) => number[]>(
        edit
            ?
              courtOrderEditForm.selectors.formModelByPathSelector
            :
              reportSealingForm.selectors.formModelByPathSelector
    );
    const formatOffenseCodeById = useSelector(formatOffenseCodeByIdSelector);

    const chargeIdsToSealFormModel = formModelByPath('chargeIdsToSeal');

    const createSealClickHandler = (chargeIdsToSeal: number[]) => {
            dispatch(
                edit
                    ?
                      courtOrderEditForm.actionCreators.changePath(
                          'chargeIdsToSeal',
                          chargeIdsToSeal
                      )
                    :
                      reportSealingForm.actionCreators.changePath(
                          'chargeIdsToSeal',
                          chargeIdsToSeal
                      )
            );
    };

    const arrestCharges = chargesForArrestReportId(reportId);
    const showChargesSection = !!arrestCharges.length;

    if (!showChargesSection) {
        return null;
    }
    
    const mappedCharges = arrestCharges.map(charge => ({
        value: charge.id,
        label: formatOffenseCodeById({
            id: charge.chargeOffenseCodeId,
        }),
    }));
    return <Select
            options={mappedCharges}
            onChange={chargesIds => {
                createSealClickHandler(chargesIds);
            }}
            isRequired={true}
            value={chargeIdsToSealFormModel || []}
            label={componentStrings.recordPrivacy.sealing.SealingChargesDropdown.sectionTitle}
            multiple
            clearable
        />;
};

export default SealingChargesDropdown;
