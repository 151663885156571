import React, { useCallback } from 'react';
import { InvolvedProfilesForImport, LinkTypesEnum } from '@mark43/rms-api';
import { Avatar, Checkbox, Tile } from 'arc';
import { Text } from '@arc/typography';
import { map, filter, isEqual, find, xorWith, every, invert, unionWith, isEmpty } from 'lodash';
import { VStack } from '@arc/layout';
import { Divider } from '@arc/divider';
import styled from 'styled-components';
import { useResource } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    EFileLinkTypesT,
    ImportableInvolvedProfileViewModelT,
    SelectedNameEntityT,
} from '../../../../types';
import { ENTITY_TO_ICON_MAP } from '../../../../constants';
import { eFileResource } from '../../../../resources';
import { useEFileContext } from '../../../../hooks';
import { SkeletonList } from '../../../../../core/components/Skeleton';
import NoDataBlock from '../../../../../core/components/NoDataBlock';
import testIds from '../../../../../../core/testIds';

const strings = componentStrings.eFiles.core.sidePanel;
const invertedLinkTypesEnum = invert(LinkTypesEnum);

export const ImportableInvolvedProfilesContent = ({
    eFileId,
    linkType,
}: {
    eFileId: number;
    linkType: EFileLinkTypesT;
}) => {
    const { actions, getters, handlers } = useEFileContext();

    const getInvolvedProfilesToImportSuccess = useCallback(
        (involvedProfilesToImportResult?: InvolvedProfilesForImport) => {
            if (involvedProfilesToImportResult) {
                actions.sidePanel.setEFileInvolvedProfilesToImport(involvedProfilesToImportResult);
            }
        },
        [actions.sidePanel]
    );

    const getInvolvedProfilesToImport = useCallback(() => {
        return eFileResource.getInvolvedProfilesForImport({
            eFileId,
            // @ts-expect-error TODO: Configure LinkTypes with name
            linkType: invertedLinkTypesEnum[linkType],
        });
    }, [eFileId, linkType]);

    const { isLoading } = useResource(
        getInvolvedProfilesToImport,
        getInvolvedProfilesToImportSuccess,
        handlers.onFetchError
    );
    if (isLoading) {
        return <SkeletonList />;
    }

    const sectionText =
        linkType === LinkTypesEnum.DEFENDANT_IN_EFILE
            ? strings.section.suspects
            : strings.section.victims;

    const involvedProfilesToImport = getters.sidePanel.getEFileInvolvedProfilesToImport();
    return (
        <>
            <InvolvedProfileSection
                headerText={sectionText}
                involvedProfiles={filter(involvedProfilesToImport, { isOther: false })}
            />
            <InvolvedProfileSection
                headerText={strings.section.other}
                involvedProfiles={filter(involvedProfilesToImport, { isOther: true })}
            />
        </>
    );
};

const InvolvedProfilesDivider = styled(Divider)`
    border-bottom-width: 6px;
    border-color: var(--arc-colors-text-tertiary);
`;

const InvolvedProfileVStack = styled(VStack)`
    margin-top: 20px;
`;

const InvolvedProfileTile = styled(Tile)`
    margin-top: var(--arc-space-3);
`;

const InvolvedProfileSection = ({
    headerText,
    involvedProfiles,
}: {
    headerText: string;
    involvedProfiles: ImportableInvolvedProfileViewModelT[];
}) => {
    const { actions, getters } = useEFileContext();

    const selectedProfiles = getters.sidePanel.getEFileSelectedInvolvedProfilesToImport();

    const checkBoxOnChange = useCallback(
        (event) => {
            const checked = event.target.checked;
            const transformedInvolvedProfiles: SelectedNameEntityT[] = map(
                involvedProfiles,
                (selectedProfile) => {
                    return {
                        entityId: selectedProfile.viewModel.id,
                        entityType: selectedProfile.entityType,
                    };
                }
            );
            const selected = checked
                ? unionWith(selectedProfiles, transformedInvolvedProfiles, isEqual)
                : [];

            actions.sidePanel.setEFileSelectedInvolvedProfilesToImport(selected);
        },
        [actions.sidePanel, selectedProfiles, involvedProfiles]
    );

    const tileOnClick = useCallback(
        (transformedInvolvedProfile) => {
            const alreadySelected = getters.sidePanel.getEFileSelectedInvolvedProfilesToImport();

            actions.sidePanel.setEFileSelectedInvolvedProfilesToImport(
                xorWith(alreadySelected, [transformedInvolvedProfile], isEqual)
            );
        },
        [actions.sidePanel, getters.sidePanel]
    );

    const isChecked =
        !isEmpty(involvedProfiles) &&
        every(involvedProfiles, (profile) => {
            const alreadySelected = getters.sidePanel.getEFileSelectedInvolvedProfilesToImport();
            return !!find(alreadySelected, {
                entityId: profile.viewModel.id,
                entityType: profile.entityType,
            });
        });

    return (
        <InvolvedProfileVStack align="left" height="auto">
            <Text casing="uppercase" variant="headingXs">
                {headerText}
            </Text>
            <InvolvedProfilesDivider />
            <Checkbox
                isDisabled={isEmpty(involvedProfiles)}
                isChecked={isChecked}
                onChange={checkBoxOnChange}
            >
                {strings.selectAll}
            </Checkbox>
            {!isEmpty(involvedProfiles) ? (
                map(involvedProfiles, (involvedProfile) => {
                    const transformedInvolvedProfile = {
                        entityId: involvedProfile.viewModel.id,
                        entityType: involvedProfile.entityType,
                    };

                    return (
                        <InvolvedProfileTile
                            key={`${involvedProfile.entityType}~${involvedProfile.viewModel.id}`}
                            isSelected={!!find(selectedProfiles, transformedInvolvedProfile)}
                            title={involvedProfile.viewModel.title}
                            description={involvedProfile.viewModel.description}
                            data-test-id={testIds.E_FILE_PROFILE_TILE}
                            media={
                                <Avatar
                                    src={involvedProfile.viewModel.imageUrl}
                                    icon={ENTITY_TO_ICON_MAP[involvedProfile.entityType]}
                                    size="md"
                                />
                            }
                            onClick={() => {
                                tileOnClick(transformedInvolvedProfile);
                            }}
                            isMultiple={true}
                        />
                    );
                })
            ) : (
                <NoDataBlock>{strings.noResultsFound}</NoDataBlock>
            )}
        </InvolvedProfileVStack>
    );
};
