import classNames from 'classnames';
import { ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { filter, noop, omit, size, uniqBy } from 'lodash';
import Promise from 'bluebird';
import { cssVar } from 'arc';

import {
    NEXUS_STATE_PROP as ELASTIC_ATTRIBUTE_DETAILS_NEXUS_STATE_PROP,
    STORE_ELASTIC_ATTRIBUTE_DETAILS,
} from '~/client-common/core/domain/elastic-attribute-details/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import OverlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import CoreButton from '../../../legacy-redux/components/core/Button';
import testIds from '../../../core/testIds';
import elasticSearchResource from '../../../legacy-redux/resources/elasticSearchResource';
import { bookingsResource } from '../../search/core/resources/bookingsResource';
import VehicleSearchResults from '../../reports/core/components/items/VehicleSearchResults';
import getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults from '../../search/core/utils/getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults';
import { arbiterMFTInput } from '../arbiter';
import { Button as _Button } from '../components/Button';
import _AsyncText from '../forms/components/AsyncText';
import Text from '../forms/components/Text';
import Modal from '../overlays/components/Modal';
import { OverlayBaseHelper } from '../components/OverlayBaseHelper';
import { useOverlayStore } from '../overlays/hooks/useOverlayStore';
import { buttonTypes } from '../overlays/components/OverlayButton';
import EntitySearchResults from './EntitySearchResults';

const strings = componentStrings.core.entitySearch;

const SIZE_INTERVAL = 5;
const ENTITY_TYPE = {
    [ElasticSearchTypeEnum.REPORT.name]: 'reports',
    [ElasticSearchTypeEnum.VEHICLE.name]: 'vehicles',
    [ElasticSearchTypeEnum.PROPERTY.name]: 'property',
    [ElasticSearchTypeEnum.CASE.name]: 'cases',
};

const AsyncText = styled(_AsyncText)`
    && {
        margin-bottom: 0;
        ${(props) => props.isOpen && `border-radius: 4px 4px 0 0;`};
        background-color: ${cssVar('arc.colors.surface.foreground')};
    }
`;

const ResultsContainer = styled.div`
    position: relative;
    z-index: 50;
    float: left;
    width: 100%;
`;

const INITIAL_SEARCH_STATE = {
    from: 0,
    size: SIZE_INTERVAL,
    elasticQuery: {},
    totalCount: 0,
    items: [],
    allSearchItems: [],
    isLoading: false,
    error: null,
};

const INITIAL_BOOKING_SEARCH_STATE = {
    offset: 0,
    limit: SIZE_INTERVAL,
    query: '',
    totalCount: 0,
    items: [],
    allSearchItems: [],
    isLoading: false,
    error: null,
};

const Button = styled(_Button)`
    float: right;
`;

const _EntitySearch = (props) => {
    const [selectedEntityIds, setSelectedEntityIds] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [state, setState] = useState(INITIAL_SEARCH_STATE);
    const [bookingState, setBookingState] = useState(INITIAL_BOOKING_SEARCH_STATE);
    const searchTextRef = useRef(null);
    const containerRef = useRef(null);
    const {
        value,
        onChange,
        className,
        minSearchCharacter,
        entityType,
        filterResults,
        addButtonText,
        renderHeader,
        renderFooter,
        showCheckbox,
        hideCloseButton,
        hideAddIcon,
        setSearchValue,
        onFocus,
        storeElasticAttributeDetails,
        onResultClick,
        onAddClick,
        excludeExternalResults,
        clearSearchOnDefocus,
        isVehicle = false,
        query,
        itemSidePanelIsAutosearch,
        storeItemSidePanelIsAutosearch,
        showBooking,
        dexData,
        isFirearm = false,
    } = props;

    const overlayStore = useOverlayStore();

    useEffect(() => {
        return () => {
            if (setSelectedEntityIds) {
                setSelectedEntityIds([]);
            }
        };
    }, [setSelectedEntityIds]);

    useEffect(() => {
        const outsideClickHander = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setShowResults(false);
                if (clearSearchOnDefocus) {
                    onChange('');
                }
            }
        };
        // add timeout because side panel opens before click event finished, so outsideClickHander calls on panel mount
        setTimeout(() => {
            window.addEventListener('click', outsideClickHander);
        }, 0);

        return () => {
            window.removeEventListener('click', outsideClickHander);
        };
    }, [clearSearchOnDefocus, onChange]);

    const loadMore = () => {
        const from = state.from + state.size;
        fetchSearchResults({
            query: value,
            from,
            size: state.size,
            reset: false,
        });
    };

    const fetchSearchResults = useCallback(
        ({ from, size, query, reset }) => {
            if (reset) {
                setState({
                    ...INITIAL_SEARCH_STATE,
                    isLoading: true,
                    error: null,
                    allSearchItems: state.allSearchItems,
                });
            }

            return (entityType === EntityTypeEnum.CASE.name
                ? elasticSearchResource.quickSearchCases({
                      from,
                      query,
                      size,
                      excludeExternalResults,
                  })
                : elasticSearchResource.searchAll({
                      from,
                      query,
                      searchTypes: [entityType],
                      size,
                  })
            )
                .then((result) => {
                    if (result) {
                        let newItems;
                        if (entityType === EntityTypeEnum.CASE.name) {
                            const { caseViewModels } = result;
                            newItems = filterResults
                                ? filter(uniqBy(caseViewModels, 'caseId'), filterResults)
                                : uniqBy(caseViewModels, 'caseId');
                            const items = reset ? newItems : [...state.items, ...newItems];
                            const allSearchItems = [...state.allSearchItems, ...newItems];

                            setState({
                                ...INITIAL_SEARCH_STATE,
                                from,
                                elasticQuery: query,
                                totalCount: caseViewModels.length,
                                items,
                                allSearchItems,
                                isLoading: false,
                            });
                        } else {
                            const {
                                elasticAttributeDetails,
                            } = getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults(
                                result
                            );
                            if (elasticAttributeDetails.length) {
                                storeElasticAttributeDetails(elasticAttributeDetails);
                            }
                            const resultKey = ENTITY_TYPE[entityType];
                            newItems = filterResults
                                ? filter(uniqBy(result[resultKey].items, 'id'), filterResults)
                                : uniqBy(result[resultKey].items, 'id');
                            const items = reset ? newItems : [...state.items, ...newItems];
                            const allSearchItems = [...state.allSearchItems, ...newItems];

                            setState({
                                ...INITIAL_SEARCH_STATE,
                                from,
                                elasticQuery: result[resultKey].query,
                                totalCount: result[resultKey].totalCount,
                                items,
                                allSearchItems,
                                isLoading: false,
                            });
                        }
                    }
                })
                .catch((error) => {
                    setState({
                        ...INITIAL_SEARCH_STATE,
                        isLoading: false,
                        error,
                    });
                });
        },
        [
            entityType,
            excludeExternalResults,
            filterResults,
            state.allSearchItems,
            state.items,
            storeElasticAttributeDetails,
        ]
    );

    const loadMoreBookings = () => {
        const offset = bookingState.offset + bookingState.limit;
        fetchBookings({
            query: value,
            limit: bookingState.limit,
            offset,
            reset: false,
        });
    };

    const openOverrideEntityModal = useCallback(
        ({ entity, dexData }) => {
            overlayStore.open(OverlayIdEnum.OVERRIDE_PROFILE_MODAL, {
                entity,
                dexData,
            });
        },
        [overlayStore]
    );

    const fetchBookings = useCallback(
        ({ query, limit, offset, reset }) => {
            if (!showBooking) {
                return Promise.resolve();
            }

            if (reset) {
                setBookingState({
                    ...INITIAL_BOOKING_SEARCH_STATE,
                    isLoading: true,
                    error: null,
                    allSearchItems: bookingState.allSearchItems,
                });
            }

            return bookingsResource
                .searchBookings({ query, limit, offset })
                .then((result) => {
                    if (result) {
                        const newItems = result.items;
                        const items = reset ? newItems : [...bookingState.items, ...newItems];
                        const allSearchItems = [...bookingState.allSearchItems, ...newItems];

                        setBookingState({
                            ...INITIAL_BOOKING_SEARCH_STATE,
                            items,
                            allSearchItems,
                            offset: result.offset,
                            totalCount: result.totalCount,
                        });
                    }
                })
                .catch((error) => {
                    setBookingState({
                        ...INITIAL_SEARCH_STATE,
                        isLoading: false,
                        error,
                    });
                });
        },
        [showBooking, bookingState.allSearchItems, bookingState.items]
    );

    const triggerSearchQuery = useCallback(
        (searchValue) => {
            setShowResults(true);
            setState({
                ...INITIAL_SEARCH_STATE,
                from: 0,
                size: SIZE_INTERVAL,
                allSearchItems: state.allSearchItems,
            });
            setBookingState({
                ...INITIAL_BOOKING_SEARCH_STATE,
                offset: 0,
                limit: SIZE_INTERVAL,
                allSearchItems: bookingState.allSearchItems,
            });
            onChange(searchValue);
            if (minSearchCharacter !== undefined && size(searchValue) < minSearchCharacter) {
                return;
            }

            return Promise.all([
                fetchSearchResults({
                    query: searchValue,
                    from: 0,
                    size: SIZE_INTERVAL,
                    reset: true,
                }),
                fetchBookings({
                    query: searchValue,
                    limit: SIZE_INTERVAL,
                    offset: 0,
                    reset: true,
                }),
            ]);
        },
        [
            fetchSearchResults,
            minSearchCharacter,
            onChange,
            state.allSearchItems,
            fetchBookings,
            bookingState.allSearchItems,
        ]
    );

    useEffect(() => {
        if (itemSidePanelIsAutosearch) {
            setSearchValue(query);
            triggerSearchQuery(query);
            storeItemSidePanelIsAutosearch(false);
            setShowResults(true);
        }
    }, [
        itemSidePanelIsAutosearch,
        setSearchValue,
        query,
        value,
        triggerSearchQuery,
        storeItemSidePanelIsAutosearch,
    ]);

    const onFocusHandler = (event) => {
        setShowResults(true);
        onFocus(event);
    };

    const onOverrideModel = ({ entity, dexData }) => {
        onResultClick(entity, dexData);
    };

    const onCancelOverride = ({ entity }) => {
        onResultClick(entity);
    };

    const onResultClickHandler = (entity) => {
        setShowResults(false);
        if (!onResultClick) {
            return;
        }
        if ((isVehicle || isFirearm) && dexData) {
            openOverrideEntityModal({ entity, dexData });
        } else {
            onResultClick(entity);
        }
    };

    const onAddClickHandler = (options) => {
        setShowResults(false);
        onAddClick?.(options);
    };

    const onCloseHandler = () => {
        setShowResults(false);
        if (clearSearchOnDefocus) {
            onChange('');
        }

        if (searchTextRef.current && searchTextRef.current.blur) {
            searchTextRef.current.blur();
        }
    };

    return (
        <>
            <FeatureFlagged
                flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                fallback={
                    <div className={classNames(className)} ref={containerRef}>
                        <div data-test-id={testIds.ENTITY_SEARCH_BAR}>
                            <AsyncText
                                isOpen={showResults && state.query}
                                onChange={onChange}
                                onFocus={onFocusHandler}
                                value={value}
                                asyncAction={triggerSearchQuery}
                                ref={searchTextRef}
                                {...omit(props, ['onFocus', 'onBlur'])}
                            />
                        </div>
                        {showResults && (
                            <ResultsContainer>
                                <EntitySearchResults
                                    results={state.results}
                                    onResultClick={onResultClickHandler}
                                    isLoading={state.isLoading}
                                    searchType={entityType}
                                    loadMore={loadMore}
                                    loadMoreBookings={loadMoreBookings}
                                    query={value}
                                    elasticQuery={state.elasticQuery}
                                    items={state.items}
                                    allSearchItems={state.allSearchItems}
                                    totalCount={state.totalCount}
                                    error={state.error}
                                    bookingState={bookingState}
                                    onAddClick={onAddClickHandler}
                                    addButtonText={addButtonText}
                                    onClose={onCloseHandler}
                                    renderHeader={renderHeader}
                                    renderFooter={renderFooter}
                                    showCheckbox={showCheckbox}
                                    hideAddIcon={hideAddIcon}
                                    hideCloseButton={hideCloseButton}
                                    selectedEntityIds={selectedEntityIds}
                                    setSelectedEntityIds={setSelectedEntityIds}
                                />
                            </ResultsContainer>
                        )}
                    </div>
                }
            >
                <div className={classNames(className)} ref={isVehicle ? null : containerRef}>
                    <div data-test-id={testIds.ENTITY_SEARCH_BAR}>
                        {!isVehicle ? (
                            <AsyncText
                                isOpen={showResults && state.query}
                                onChange={onChange}
                                onFocus={onFocusHandler}
                                value={value}
                                asyncAction={triggerSearchQuery}
                                ref={searchTextRef}
                                {...omit(props, ['onFocus', 'onBlur'])}
                            />
                        ) : (
                            <div>
                                <Text
                                    value={value}
                                    onChange={onChange}
                                    onFocus={onFocusHandler}
                                    ref={searchTextRef}
                                    onPressEnter={() => triggerSearchQuery(value)}
                                />
                                <Button variant="solid" onClick={() => triggerSearchQuery(value)}>
                                    {strings.searchButton}
                                </Button>
                            </div>
                        )}
                    </div>
                    {showResults && !isVehicle && (
                        <ResultsContainer>
                            <EntitySearchResults
                                results={state.results}
                                onResultClick={onResultClickHandler}
                                isLoading={state.isLoading}
                                searchType={entityType}
                                loadMore={loadMore}
                                loadMoreBookings={loadMoreBookings}
                                query={value}
                                elasticQuery={state.elasticQuery}
                                items={state.items}
                                allSearchItems={state.allSearchItems}
                                totalCount={state.totalCount}
                                error={state.error}
                                bookingState={bookingState}
                                onAddClick={onAddClickHandler}
                                addButtonText={addButtonText}
                                onClose={onCloseHandler}
                                renderHeader={renderHeader}
                                renderFooter={renderFooter}
                                showCheckbox={showCheckbox}
                                hideAddIcon={hideAddIcon}
                                hideCloseButton={hideCloseButton}
                                selectedEntityIds={selectedEntityIds}
                                setSelectedEntityIds={setSelectedEntityIds}
                            />
                        </ResultsContainer>
                    )}
                    {isVehicle && (
                        <div>
                            <VehicleSearchResults
                                onResultClick={onResultClickHandler}
                                searchType={entityType}
                                query={value}
                                elasticQuery={state.elasticQuery}
                                items={state.items}
                                error={state.error}
                            />
                        </div>
                    )}
                </div>
            </FeatureFlagged>
            {(isVehicle || isFirearm) && (
                <OverlayBaseHelper id={OverlayIdEnum.OVERRIDE_PROFILE_MODAL}>
                    {({ overlayBase }) => {
                        const { entity } = overlayBase.overlayState.customProperties;
                        const { close } = overlayBase;

                        return (
                            <Modal
                                id={OverlayIdEnum.OVERRIDE_PROFILE_MODAL}
                                title={strings.overrideModalTitle(isVehicle ? 'vehicle' : 'firearm')}
                                okText={strings.overrideBtnLabel}
                                onSave={onOverrideModel}
                                cancelText={null}
                                additionalFooterContent={
                                    <CoreButton
                                        className={buttonTypes.SECONDARY}
                                        onClick={() => {
                                            close();
                                            onCancelOverride({ entity });
                                        }}
                                    >
                                        {strings.overrideCancelBtnLabel}
                                    </CoreButton>
                                }
                            >
                                {strings.overrideModalBody(isVehicle ? 'vehicle' : 'firearm')}
                            </Modal>
                        );
                    }}
                </OverlayBaseHelper>
            )}
        </>
    );
};

_EntitySearch.defaultProps = {
    onBlur: noop,
    onFocus: noop,
    clearSearchOnDefocus: false,
};

const mapDispatchToProps = (dispatch) => ({
    storeElasticAttributeDetails: (elasticAttributeDetails) =>
        dispatch(
            withEntityItems(
                {
                    [ELASTIC_ATTRIBUTE_DETAILS_NEXUS_STATE_PROP]: elasticAttributeDetails,
                },
                { type: STORE_ELASTIC_ATTRIBUTE_DETAILS }
            )
        ),
});

const EntitySearch = connect(undefined, mapDispatchToProps)(_EntitySearch);

export const ArbiterMFTEntitySearch = arbiterMFTInput(EntitySearch);

export default EntitySearch;
