import { isFinite } from 'lodash';
import { Point, Polyline, Polygon } from '../constructors';
import { SPATIAL_REFERENCE } from '../constants';

import { GeoPointT } from '../types';

export type GeometryType = Point | Polyline | Polygon;
export function createGeometry(
    type: 'POINT' | 'POLYLINE' | 'POLYGON',
    geoPoints: GeoPointT[]
): GeometryType | undefined {
    if (!geoPoints.length) {
        return undefined;
    }
    let geometry;
    try {
        switch (type) {
            case 'POINT':
                geometry = new Point({
                    latitude: geoPoints[0].latitude,
                    longitude: geoPoints[0].longitude,
                });
                break;
            case 'POLYLINE':
                geometry = new Polyline({
                    paths: [[...geoPoints.map((point) => [point.longitude, point.latitude])]],
                    spatialReference: SPATIAL_REFERENCE,
                });
                break;
            case 'POLYGON':
                geometry = new Polygon({
                    rings: [
                        [
                            ...geoPoints.map((point) => [point.longitude, point.latitude]),
                            [geoPoints[0].longitude, geoPoints[0].latitude],
                        ],
                    ],
                    spatialReference: SPATIAL_REFERENCE,
                });
                break;
            default:
                return undefined;
        }
        return geometry;
    } catch (e) {
        return undefined;
    }
}

export function convertToEsriPolyline(polyline: { lat?: number; lng?: number }[]) {
    return {
        path: [
            { lat: polyline?.[0]?.lat || 0, lng: polyline?.[0]?.lng || 0 },
            { lat: polyline?.[1]?.lat || 0, lng: polyline?.[1]?.lng || 0 },
        ],
    };
}

export function convertToEsriPolygon(
    center: { lat: number; lng: number },
    vertices: { lat: number; lng: number }[],
    id = 0,
    type = 'POLYGON',
    radius = 1000,
    radisUnit = 'METERS'
) {
    if (type === 'POLYGON') {
        return {
            rings: (vertices || [])
                .filter((each) => isFinite(each?.lat) && isFinite(each?.lng))
                .map((each) => [each?.lng, each?.lat]),
            id,
            type,
        };
    } else if (type === 'CIRCLE') {
        return {
            center: [center.lng, center.lat],
            id,
            type,
            radius,
            radisUnit,
        };
    }
    return {};
}

/**
 * Converts a string to a hex color. For use with the geoJSON layer for marking regions
 * with different colors.
 */
export const stringToColor = (str: string, alpha = 1) => {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + (hash << 6) + (hash << 16) - hash;
    }

    const rgba = [];

    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xfff;
        const hexValue = (value * 0xfffff * 1000000).toString(16);
        rgba.push(parseInt(`00${hexValue}`.substring(hexValue.length), 16));
    }

    rgba.push(alpha);

    return rgba;
};


export const handleMapViewGotoCatch = () => {
    // do nothing
};

/**
 * Converts any string into a unique 32-bit integer. SDBM Is a non-cryptographic
 * hash function that provides reasonable security against collisions. We use this
 * to create unique IDs based on a string input.
 * @param str The string to get the hash for.
 * @returns The numerical hash.
 */
export const sdbmHash = (str: string): number => {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + (hash << 6) + (hash << 16) - hash;
    }

    return hash >>> 0;
};