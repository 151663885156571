import { compact } from 'lodash';
import { DiagramAsset, DiagramAssetViewModel } from '@mark43/rms-api';

export const convertDiagramAssetViewModelToAssets = <T extends DiagramAssetViewModel>(
    diagramAssetViewModel: T
) => {
    const { diagramAssets, diagramAssetsFileMap } = diagramAssetViewModel;
    return compact(
        diagramAssets.map((asset) => {
            return convertDiagramAssetViewModelToAsset(asset, diagramAssetsFileMap);
        })
    );
};

export const convertDiagramAssetViewModelToAsset = (
    asset: DiagramAsset,
    diagramAssetsFileMap: DiagramAssetViewModel['diagramAssetsFileMap']
) => {
    const assetFile = diagramAssetsFileMap[asset.fileId];
    if (!assetFile) {
        return undefined;
    }

    return {
        id: asset.id,
        name: asset.name,
        diagramCategoryId: asset.diagramCategoryId,
        file: assetFile,
        departmentId: asset.departmentId,
        isDefault: asset.isDefault,
    };
};
