import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { map, noop } from 'lodash';

import { Icon, Text as ArcText, HStack, Spacer } from 'arc';
import { CommandStatusEnum, NibrsGeneratedSegmentErrorsView, NibrsSegmentsErrorDescription } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_NIBRS_ADMIN_NOT_REPORTABLE_SUBTITLE } from '~/client-common/core/enums/universal/fields';
import { FormattedDate } from '~/client-common/core/dates/components';

import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { SecondarySectionHeader } from '../../../../core/components/typography';
import { GeneratedSegmentsSidePanel } from '../../../../core/generated-segments/components/GeneratedSegmentsSidePanel';
import { NibrsErrorState } from '../../../../core/generated-segments/constants';
import { useGenerateNibrsSegment } from '../../../../core/generated-segments/hooks';
import testIds from '../../../../../core/testIds';
import { Button as ArcButton } from '../../../../core/components/Button';
import ButtonCopyToClipboard from '../../../../core/components/ButtonCopyToClipboard';
import { OverlayRenderProps } from '../../../../core/components/OverlayBaseHelper';

const strings = componentStrings.reports.core.NibrsAdminSection;

type RenderSidePanelButtonsProps = Record<string, unknown>;

const NibrsAdminSectionWrapper = styled(CardSection)`
    padding: 20px;
`;

const NibrsAdminSectionContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const NotReportableReasonsList = styled.ul`
    margin: 8px 0 0 12px;
`;

const ButtonWrapper = styled.div`
    padding-top: var(--arc-space-3);
    display: flex;
    gap: var(--arc-space-3);
    flex-wrap: wrap;
`;

interface StatusMessageProps {
    nibrsErrorState?: NibrsErrorState;
    shouldDisableApprovalButton?: boolean;
    isSingleReview?: boolean;
}

const StatusMessage = ({ nibrsErrorState, shouldDisableApprovalButton, isSingleReview }: StatusMessageProps) => {
    const nibrsAdminNotReportableSubtitle = useFields(
        DISPLAY_ONLY_NIBRS_ADMIN_NOT_REPORTABLE_SUBTITLE
    )[DISPLAY_ONLY_NIBRS_ADMIN_NOT_REPORTABLE_SUBTITLE];
    const errorMessage = isSingleReview ? strings.message.restrictReportCompletionSingleReview : strings.message.restrictReportCompletionSecondaryReview;

    switch (nibrsErrorState) {
        case NibrsErrorState.NoErrors:
            return <ArcText>{strings.message.noErrors}</ArcText>;
        case NibrsErrorState.HasFatalErrors:
            return (
                <div>
                    {shouldDisableApprovalButton && <ArcText fontWeight="bold">{errorMessage}</ArcText>}
                    <ArcText>{strings.message.hasFatalErrors}</ArcText>
                </div>
            );
        case NibrsErrorState.HasNibrsErrors:
            return (
                <div>
                    {shouldDisableApprovalButton && <ArcText fontWeight="bold">{errorMessage}</ArcText>}
                    <ArcText>{strings.message.hasNibrsErrors}</ArcText>
                </div>
            );
        case NibrsErrorState.NotReportable:
            return <ArcText>{nibrsAdminNotReportableSubtitle}</ArcText>;
        case NibrsErrorState.Running:
            return (
                <div>
                    {shouldDisableApprovalButton && <ArcText fontWeight="bold">{errorMessage}</ArcText>}
                    <ArcText>{strings.message.running}</ArcText>
                </div>
            );
        case NibrsErrorState.Failed:
            return (
                <div>
                    {shouldDisableApprovalButton && <ArcText fontWeight="bold">{errorMessage}</ArcText>}
                    <ArcText>{strings.message.failed}</ArcText>
                </div>
            );
        default:
            return <ArcText>{strings.message.unChecked}</ArcText>;
    }
};

const StatusIcon = ({ nibrsErrorState }: { nibrsErrorState?: NibrsErrorState }) => {
    const {
        NoErrors,
        HasNibrsErrors,
        HasFatalErrors,
        NotReportable,
        Running,
        Failed,
    } = NibrsErrorState;

    switch (nibrsErrorState) {
        case NoErrors:
            return <Icon icon="Check" color="positive.default" size="md" />;
        case HasNibrsErrors:
        case HasFatalErrors:
            return <Icon icon="Alert" color="negative.default" size="md" />;
        case NotReportable:
            return <Icon icon="Information" color="info.default" size="md" />;
        case Running:
            return <Icon icon="Sync" color="info.default" size="md" />;
        case Failed:
            return <Icon icon="CloseX" color="negative.default" size="md" />;
        default:
            return <Icon icon="ExclamationPoint" color="icon.default" size="md" />;
    }
};

export type NibrsErrors = {
    callGetNibrsErrorsResource: (reportId: number) => Promise<void>;
    getNibrsErrorsErrorMessage?: string;
    nibrsErrors?: NibrsSegmentsErrorDescription[] | undefined;
    fatalErrors?: NibrsSegmentsErrorDescription[];
    getNibrsErrorsIsLoading: boolean;
    nibrsErrorState: NibrsErrorState;
    errorsView?: NibrsGeneratedSegmentErrorsView;
    setErrorsView: Dispatch<SetStateAction<NibrsGeneratedSegmentErrorsView | undefined>>;
    disableRunErrorCheck: boolean;
    disableViewNibrsDetails: boolean;
};

const NibrsAdminSection: React.FC<{ shouldDisableApprovalButton?: boolean; nibrsErrorsData?: NibrsErrors; isSingleReview?: boolean}> = ({ shouldDisableApprovalButton, nibrsErrorsData, isSingleReview }) => {
    const currentReportId = useSelector(currentReportIdSelector);
    const {
        callGetNibrsErrorsResource,
        nibrsErrors,
        fatalErrors,
        nibrsErrorState,
        errorsView,
        setErrorsView,
        disableRunErrorCheck,
        disableViewNibrsDetails,
    } = nibrsErrorsData ?? { nibrsErrorState: NibrsErrorState.NoErrors, setErrorsView: (value: NibrsGeneratedSegmentErrorsView) => {return value} };
    const { callResource: callGenerateNibrsSegmentResource } = useGenerateNibrsSegment(
        setErrorsView
    );

    const handleRunErrorCheckClick = useCallback(() => {
        if (!currentReportId) {
            return noop;
        }
        setErrorsView?.({
            currentStatus: CommandStatusEnum.RUNNING.name,
            hasGeneratedSegments: true,
            reportId: currentReportId,
            hasFatalErrors: false,
            errorDescriptions: [],
            notGeneratedReasons: [],
            serializedSegments: '',
            updatedDateUtc: '',
        });
        return callGenerateNibrsSegmentResource(currentReportId);
    }, [callGenerateNibrsSegmentResource, currentReportId, setErrorsView]);

    const RenderSidePanelButtons = ({
        overlayBase,
        setCancelFocusRef,
    }: OverlayRenderProps<RenderSidePanelButtonsProps>) => {
        const { open } = overlayBase;
        return (
            <ButtonWrapper>
                <ArcButton
                    isTextTransformNone
                    testId={testIds.REPORT_STATUS_COMMENTS_CARD_NIBRS_VIEW_NIBRS_DETAILS_BUTTON}
                    onClick={() => {
                        setCancelFocusRef(document.activeElement);
                        open();
                    }}
                    disabled={disableViewNibrsDetails}
                >
                    {strings.button.viewNibrsDetails}
                </ArcButton>
                <ArcButton
                    isTextTransformNone
                    testId={testIds.REPORT_STATUS_COMMENTS_CARD_NIBRS_RUN_ERROR_CHECK_BUTTON}
                    onClick={handleRunErrorCheckClick}
                    disabled={disableRunErrorCheck}
                >
                    {strings.button.runErrorCheck}
                </ArcButton>
            </ButtonWrapper>
        );
    };

    useEffect(() => {
        if (!currentReportId) {
            return;
        }
        // the callResource in this takes care getting errors / state
        // the onSuccess will start polling if segment generation is running
        if (!errorsView) {
            callGetNibrsErrorsResource?.(currentReportId);
        }
    }, [callGetNibrsErrorsResource, currentReportId, errorsView, setErrorsView]);
    return (
        <NibrsAdminSectionWrapper>
            <SecondarySectionHeader>{strings.header}</SecondarySectionHeader>
            <NibrsAdminSectionContent>
                <HStack align="start">
                    <StatusIcon nibrsErrorState={nibrsErrorState} />
                    <StatusMessage 
                        nibrsErrorState={nibrsErrorState} 
                        shouldDisableApprovalButton={shouldDisableApprovalButton}
                        isSingleReview={isSingleReview}
                    />
                </HStack>
                {errorsView?.serializedSegments && errorsView.serializedSegments !== '-' && (
                    <HStack>
                        <Spacer maxWidth="6" />
                        <FormattedDate
                            date={errorsView.updatedDateUtc}
                            format={FormattedDate.FORMATS.DATE_TIME_IN_SENTENCE}
                        >
                            {(formattedDate) => (
                                <div>{`Segment Generated on ${formattedDate}`}</div>
                            )}
                        </FormattedDate>
                        <ButtonCopyToClipboard
                            message="IBRS Segment"
                            dataToCopy={errorsView.serializedSegments}
                        />
                    </HStack>
                )}
                {nibrsErrorState === NibrsErrorState.NotReportable && (
                    <NotReportableReasonsList>
                        {map(errorsView?.notGeneratedReasons, (reason, index) => (
                            <li key={index}>
                                <ArcText>{reason}</ArcText>
                            </li>
                        ))}
                    </NotReportableReasonsList>
                )}
                <GeneratedSegmentsSidePanel
                    disableRunErrorCheck={!!disableRunErrorCheck}
                    handleRunErrorCheckClick={handleRunErrorCheckClick}
                    nibrsErrors={nibrsErrors}
                    nibrsErrorState={nibrsErrorState}
                    notGeneratedReasons={errorsView?.notGeneratedReasons}
                    fatalErrors={fatalErrors}
                    renderButton={RenderSidePanelButtons}
                />
            </NibrsAdminSectionContent>
        </NibrsAdminSectionWrapper>
    );
};

export default NibrsAdminSection;
