import * as React from 'react';
import {
    DragonUiConfigurationRenderer,
    FormValue,
    IndexedFormValues,
    DragonMftConfigurationValuesContextProvider,
    prepareDragonDataStructures,
    createFormReferenceValue,
    UiRenderCallback,
    isSelectFieldConfiguration,
    UiConfigurationMetadataMapEntry,
    UiConfigurationPathShape,
} from 'dragon-react';
import {
    InputTypeEnum,
    MetaInstanceView,
    DragonSearchOptionsResource,
    UiConfigurationOptionView,
    UiTypeEnum,
} from '@mark43/rms-api';
import { keyBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { ClientFormView, RMSDragonConfigurationExtensions } from '../../rms-types';
import { DragonConfigDataContextProvider } from '../../context/dragon-config-data';
import {
    DragonFormValuesContextProvider,
    DragonSummaryFormValuesContextProvider,
} from '../../context/dragon-form-values';
import { DragonCurrentFormContextProvider } from '../../context/dragon-current-form';
import { DragonInlineFormConfigurationProvider } from '../../context/dragon-inline-form-configuration';
import { DragonReportCoreEntityOptionsDataLoaderContextProvider } from '../../context/dragon-report-core-entity-data-loaders';
import { subscribe as dragonEventSubscribe } from '../../events/broadcast';
import {
    dragonRemoveFormValueByPathAction,
    dragonUpdateSelectInstanceSummaryFormValueByPathAction,
} from '../../dragonRedux';

import { retry } from '../../../core/utils/promiseHelpers';
import { req } from '../../../../lib/ajax';
import { uiConfigurationIdToNumber } from '../../utils/ui-configuration-id-to-number';
import { Field, fieldset } from './renderers/form';
import { FieldSummary, fieldsetSummary } from './renderers/summary';
import { IsNewReportContextProvider } from './dragon-card';
import { FormReferenceWrapper } from './form-reference-wrapper';
import { NameReportLink, NameReportLinkSummary } from './renderers/name-report-link';
import { NItems, NItemsSummary } from './renderers/n-items';
import { getDisplayStatesForConfiguredFormId } from './state/card-display-mode-tracker';
import { LocationEntityLinkSummary, LocationEntityLink } from './renderers/location-entity-link';
import { CrashDiagramContextProviders } from './renderers/crash-diagram/context/CrashDiagramContextProviders';

const renderDragonUi: UiRenderCallback<RMSDragonConfigurationExtensions> = (options) => {
    const isFormRender = options.mode === 'FORM';
    switch (options.uiType) {
        case 'FIELD':
            return isFormRender ? (
                <Field key={options.props.key} {...options} />
            ) : (
                <FieldSummary key={options.props.key} {...options} />
            );
        case 'FIELDSET':
            return isFormRender ? fieldset(options) : fieldsetSummary(options);
        case 'N_ITEMS':
            return isFormRender ? (
                <NItems key={options.props.key} {...options} />
            ) : (
                <NItemsSummary key={options.props.key} {...options} />
            );
        case 'NAME_REPORT_LINK':
            return isFormRender ? (
                <NameReportLink key={options.props.key} {...options} />
            ) : (
                <NameReportLinkSummary key={options.props.key} {...options} />
            );
        case 'LOCATION_ENTITY_LINK': {
            return isFormRender ? (
                <LocationEntityLink key={options.props.key} {...options} />
            ) : (
                <LocationEntityLinkSummary key={options.props.key} {...options} />
            );
        }
        case 'FORM_REFERENCE':
            return <FormReferenceWrapper key={options.props.key} options={options} />;

        default: {
            throw new Error(
                // @ts-expect-error Covering the case where our types don't match all existing UI types
                `Encountered unsupported ui type while rendering dragon ui: "${options.uiType}"`
            );
        }
    }
};

function indexUiConfigurationOptionsByUiConfigurationId(
    data: UiConfigurationOptionView[]
): Record<string, Record<string, unknown>[]> {
    return data.reduce<Record<string, Record<string, unknown>[]>>((acc, view) => {
        for (const id of view.uiConfigurationIds) {
            const arr = acc[id] || (acc[id] = []);
            arr.push(...view.resolvedConfiguredEntityPropertyInstanceValues);
        }
        return acc;
    }, {});
}

function convertUiConfigurationPathsForDragonAction(
    data: {
        configuration: UiConfigurationMetadataMapEntry;
        path: readonly UiConfigurationPathShape[];
    }[]
): {
    configuredFormId: string;
    primaryKeyPropertyId: number;
    uiConfigurationId: string;
    path: string;
}[] {
    return data.flatMap(({ path, configuration }) =>
        path.map((p) => {
            if (
                configuration.configuration.uiType !== UiTypeEnum.FIELD.name ||
                !isSelectFieldConfiguration(configuration.configuration) ||
                !configuration.configuration.ui.selectConfiguredEntityValuePropertyId
            ) {
                throw new Error(
                    'Unexpectedly did not receive select configuration while trying to convert ui configuration paths'
                );
            }
            return {
                // `formName` is actually the id of the parent form this field resides on. We should probably rename this property
                // to make it very clear, or add a separate `configuredFormId` property to the path descriptor
                configuredFormId: p.formName,
                primaryKeyPropertyId:
                    configuration.configuration.ui.selectConfiguredEntityValuePropertyId,
                uiConfigurationId: configuration.configuration.id,
                path: p.path,
            };
        })
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractTypeGuardType<T> = T extends (input: any) => input is infer R ? R : never;

export const RMSDragonForm: React.FC<{
    formData: ClientFormView;
    summaryData: ClientFormView;
    isNewReport: boolean;
    reportId: number;
}> = ({ formData, summaryData, isNewReport, reportId }) => {
    const { rootFormReference, formValues } = formData;
    const { instanceId: rootFormReferenceInstanceId } = rootFormReference;
    if (!rootFormReferenceInstanceId) {
        throw new Error('Unexpectedly did not find root form reference instance id');
    }

    // TODO we need to be able to add values to these indexed values when we are creating backing instances, e.g. when adding to an nitems.
    // How can we facilitate this in a good way? Just introduce an api which requires a function call so retrieve values?
    // That way we can add to the object without re-renders from other components. Do we need observability here to trigger re-renders if an existing value changed?
    const indexedFormValues = React.useMemo(
        (): IndexedFormValues =>
            formValues.reduce<{ [index: string]: FormValue & { __meta?: MetaInstanceView } }>(
                (acc, value) => {
                    acc[`${value.formConfigurationId}~${value.instanceId}`] = value;
                    return acc;
                },
                {
                    // We fake this form value so that we can reuse the same lookup mechanism for root
                    // form references and other form references.
                    'root~root': {
                        values: {
                            [rootFormReference.id]: createFormReferenceValue(
                                rootFormReferenceInstanceId
                            ),
                        },
                    },
                }
            ),
        [formValues, rootFormReference.id, rootFormReferenceInstanceId]
    );

    const indexedSummaryFormValues = React.useMemo(
        (): IndexedFormValues =>
            summaryData.formValues.reduce<{
                [index: string]: FormValue & { __meta?: MetaInstanceView };
            }>(
                (acc, value) => {
                    acc[`${value.formConfigurationId}~${value.instanceId}`] = value;
                    return acc;
                },
                {
                    'root~root': {
                        values: {
                            // we use the root form reference of our form data because
                            // it is identical between form and summary data
                            [rootFormReference.id]: createFormReferenceValue(
                                rootFormReferenceInstanceId
                            ),
                        },
                    },
                }
            ),
        [summaryData.formValues, rootFormReference.id, rootFormReferenceInstanceId]
    );

    const dragonDataStructures = React.useMemo(
        () => prepareDragonDataStructures(formData.forms, [formData.rootFormReference]),
        [formData.forms, formData.rootFormReference]
    );

    const { vehicleSelectPaths, firearmSelectPaths, itemProfileSelectPaths } = React.useMemo(() => {
        const coreEntitySelectPaths = Object.values(
            dragonDataStructures.uiConfigurationMetadataMap
        ).filter(
            (
                pathMapEntry
            ): pathMapEntry is Omit<NonNullable<typeof pathMapEntry>, 'configuration'> & {
                configuration: ExtractTypeGuardType<typeof isSelectFieldConfiguration>;
            } => {
                if (
                    !pathMapEntry ||
                    pathMapEntry.configuration.uiType !== UiTypeEnum.FIELD.name ||
                    !isSelectFieldConfiguration(pathMapEntry.configuration)
                ) {
                    return false;
                }
                const { inputType } = pathMapEntry.configuration.ui;
                return (
                    inputType === InputTypeEnum.VEHICLE_SELECT.name ||
                    inputType === InputTypeEnum.FIREARM_SELECT.name ||
                    inputType === InputTypeEnum.ITEM_PROFILE_SELECT.name
                );
            }
        );

        type CoreEntitySelectPath = {
            configuration: UiConfigurationMetadataMapEntry;
            path: Readonly<UiConfigurationPathShape>[];
        };
        return coreEntitySelectPaths.reduce<{
            vehicleSelectPaths: CoreEntitySelectPath[];
            firearmSelectPaths: CoreEntitySelectPath[];
            itemProfileSelectPaths: CoreEntitySelectPath[];
        }>(
            (acc, configuration) => {
                const pathConfig = {
                    configuration,
                    path: dragonDataStructures.uiConfigurationPathMap[
                        configuration.configuration.id
                    ],
                };
                const { inputType } = configuration.configuration.ui;
                if (inputType === InputTypeEnum.VEHICLE_SELECT.name) {
                    acc.vehicleSelectPaths.push(pathConfig);
                } else if (inputType === InputTypeEnum.ITEM_PROFILE_SELECT.name) {
                    acc.itemProfileSelectPaths.push(pathConfig);
                } else if (inputType === InputTypeEnum.FIREARM_SELECT.name) {
                    acc.firearmSelectPaths.push(pathConfig);
                }
                return acc;
            },
            {
                vehicleSelectPaths: [],
                firearmSelectPaths: [],
                itemProfileSelectPaths: [],
            }
        );
    }, [
        dragonDataStructures.uiConfigurationMetadataMap,
        dragonDataStructures.uiConfigurationPathMap,
    ]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        return dragonEventSubscribe((event) => {
            const { itemType } = event.payload;
            const coreEntitySelectPaths =
                itemType === 'FIREARM'
                    ? firearmSelectPaths
                    : itemType === 'ITEM_PROFILE'
                      ? itemProfileSelectPaths
                      : itemType === 'VEHICLE'
                        ? vehicleSelectPaths
                        : undefined;

            if (!coreEntitySelectPaths?.length) {
                return;
            }

            switch (event.type) {
                case 'ITEMS_MODIFIED': {
                    retry(() =>
                        req<DragonSearchOptionsResource.GetUiConfigurationOptionViewsForUiConfigurationInstanceRequest>(
                            {
                                method: 'POST',
                                url: 'dragon/search/options/instances',
                                data: {
                                    instanceIds: event.payload.itemIds,
                                    uiConfigurationIds: coreEntitySelectPaths.map(
                                        ({ configuration }) =>
                                            uiConfigurationIdToNumber(
                                                configuration.configuration.id
                                            )
                                    ),
                                },
                            }
                        )
                    )
                        .then((data) => {
                            dispatch(
                                dragonUpdateSelectInstanceSummaryFormValueByPathAction({
                                    updatedInstanceValues:
                                        indexUiConfigurationOptionsByUiConfigurationId(data),
                                    paths: convertUiConfigurationPathsForDragonAction(
                                        coreEntitySelectPaths
                                    ),
                                    reportId,
                                })
                            );
                        })
                        .catch(() => {
                            // This error is intentionally swallowed. We are trying to sync our summary state with a best-effort approach. If we're failing to load
                            // updated instances after all retries we will simply silently fail and expect users to refresh their page at some point on their own.
                            // Updating the summary view itself only matters for vehicles is parts of a vehicle's title are updated. This is a rare edge case,
                            // so simply failing gracefully without interrupting the user should be deemed acceptable.
                        });
                    break;
                }
                case 'ITEM_REMOVED': {
                    const paths = convertUiConfigurationPathsForDragonAction(coreEntitySelectPaths);
                    const configuredFormDisplayStates = Object.fromEntries(
                        [...new Set(paths.map((path) => path.configuredFormId))].map(
                            (configuredFormId) => {
                                const displayStates =
                                    getDisplayStatesForConfiguredFormId(configuredFormId);
                                if (!displayStates) {
                                    throw new Error(
                                        `Unexpectedly did not find display staes for configured form with id "${configuredFormId}"`
                                    );
                                }
                                return [configuredFormId, displayStates];
                            }
                        )
                    );
                    dispatch(
                        dragonRemoveFormValueByPathAction({
                            paths,
                            primaryKeyValue: event.payload.itemId,
                            reportId,
                            configuredFormDisplayStates,
                        })
                    );
                    break;
                }
                default: {
                    // noop
                }
            }
        });
    }, [vehicleSelectPaths, firearmSelectPaths, itemProfileSelectPaths, dispatch, reportId]);

    return (
        <DragonInlineFormConfigurationProvider
            value={keyBy(formData.inlineForms, (form) => form.id)}
        >
            <DragonMftConfigurationValuesContextProvider
                value={dragonDataStructures.mftConfigurations.indexedFormConfigurations}
            >
                <DragonSummaryFormValuesContextProvider value={indexedSummaryFormValues}>
                    <DragonFormValuesContextProvider value={indexedFormValues}>
                        <DragonConfigDataContextProvider
                            formView={formData}
                            configData={dragonDataStructures}
                            reportId={reportId}
                        >
                            <DragonCurrentFormContextProvider
                                configuredFormId="root"
                                instanceId="root"
                                referencingUiConfigurationId="root"
                            >
                                <DragonReportCoreEntityOptionsDataLoaderContextProvider
                                    reportId={reportId}
                                >
                                    <IsNewReportContextProvider value={isNewReport}>
                                        <CrashDiagramContextProviders>
                                            {/*
                                                    This should be wrapped in an error boundary to prevent it from blowing up the whole app when misconfigured.
                                                    This boundary should present useful information to the consumer indicating
                                                    - what broke
                                                    - where it broke
                                                    - how it can be fixed (at least some assumptions)
                                                */}
                                            <DragonUiConfigurationRenderer<RMSDragonConfigurationExtensions>
                                                formReferences={formData.forms}
                                                configurations={rootFormReference}
                                                render={renderDragonUi}
                                                mode="FORM"
                                            />
                                        </CrashDiagramContextProviders>
                                    </IsNewReportContextProvider>
                                </DragonReportCoreEntityOptionsDataLoaderContextProvider>
                            </DragonCurrentFormContextProvider>
                        </DragonConfigDataContextProvider>
                    </DragonFormValuesContextProvider>
                </DragonSummaryFormValuesContextProvider>
            </DragonMftConfigurationValuesContextProvider>
        </DragonInlineFormConfigurationProvider>
    );
};
