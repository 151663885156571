import { useEffect, useRef, useState } from 'react';
import { WebMap, GeoJSONLayer } from '../constructors';
import { logError } from '../../../../core/logging';
import { SelectedShapefileT } from '../types';
import { getRendererForJson } from '../helpers/rendererHelpers';

/**
 * This custom hook creates a new layer to display geoJSON on.
 * @example
 * const { geoJSONLayer } = useGeoJSONLayer({
 *     url: 'https://.../file.geojson',
 * }, webMap);
 * @param geoJSON The URL or geoJSON object to display on the map.
 * @param map The webmap to add the layer to.
 * @returns
 */
export const useGeoJSONLayer = (geoJSON: SelectedShapefileT | undefined, map?: WebMap) => {
    const [geoJSONLayer, setGeoJSONLayer] = useState<GeoJSONLayer>();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const layerRef = useRef<GeoJSONLayer>();

    useEffect(() => {
        if (!map) {
            return;
        }

        if (!!layerRef.current) {
            map.remove(layerRef.current);
        }

        if (geoJSON === undefined) {
            // Setting the geoJSON file to undefined will just remove the layer.
            setGeoJSONLayer(undefined);
            layerRef.current = undefined;
            return;
        }

        let objectUrl: string;

        const fetchShapefileByUrl = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(geoJSON.url);
                const json = await response.json();
                setIsError(false);

                const geojson = {
                    // need to strip `crs` field
                    type: json.type,
                    features: json.features,
                };
                const blob = new Blob([JSON.stringify(geojson)], { type: 'application/json' });
                objectUrl = URL.createObjectURL(blob);

                const newGeoJSONLayer = new GeoJSONLayer({
                    url: objectUrl,
                    renderer: getRendererForJson(geoJSON),
                });

                setGeoJSONLayer(newGeoJSONLayer);

                map.add(newGeoJSONLayer);
                layerRef.current = newGeoJSONLayer;

                setIsLoading(false);
            } catch (error) {
                logError('Error while fetching map geojson features', { error, url: geoJSON.url });
                setIsError(true);
                setIsLoading(false);
            }
        };

        fetchShapefileByUrl();

        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }
        };
    }, [geoJSON, map]);

    return { geoJSONLayer, isLoading, isError };
};
