import keyMirror from 'keymirror';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    compose,
    defaultProps,
    pure,
    setDisplayName,
    setPropTypes,
    withHandlers,
    withPropsOnChange,
} from 'recompose';
import { filter } from 'lodash';
import moment from 'moment';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { searchStorageLocations } from '~/client-common/core/domain/elastic-storage-locations/state/data';
import { elasticStorageLocationOptionsSelector } from '~/client-common/core/domain/elastic-storage-locations/state/ui';
import { facilityOptionsSelector } from '~/client-common/core/domain/facilities/state/ui';
import { currentUserDepartmentIdSelector } from '../../../current-user/state/ui';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import evidenceDashboardSearchForm from '../../../../evidence/dashboard/state/forms/evidenceDashboardSearchForm';
import { multiFieldSelectFactory } from './MultiFieldSelect';
import AsyncSelect from './AsyncSelect';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const FacilityStorageLocationSelect = compose(
    setDisplayName('FacilityStorageLocationSelect'),
    pure,
    connect(
        createStructuredSelector({
            facilityOptions: facilityOptionsSelector,
            elasticStorageLocationOptions: elasticStorageLocationOptionsSelector,
            departmentId: currentUserDepartmentIdSelector,
            applicationSettings: applicationSettingsSelector,
            formModel: evidenceDashboardSearchForm.selectors.formModelSelector,
        }),
        (dispatch) => ({
            searchStorageLocations: (...args) =>
                dispatch(
                    searchStorageLocations(elasticSearchResource.searchStorageLocations, ...args)
                ),
        })
    ),
    withHandlers({
        asyncAction({
            searchStorageLocations,
            facilityTypeAttrIds,
            facilityIdFilter,
            requiredPermissions,
            applicationSettings,
        }) {
            return (query) => {
                const queryObj = {
                    query,
                    facilityTypeAttrIds,
                    facilityIds: !!facilityIdFilter ? [facilityIdFilter] : [],
                    isFacility: false,
                };

                const useEvdPerms = applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED;

                if (useEvdPerms && requiredPermissions) {
                    queryObj.storageLocationPermissionsQuery = [
                        {
                            operationTypes: requiredPermissions,
                        },
                    ];
                }
                return searchStorageLocations(
                    queryObj,
                    0,
                    100,
                    undefined,
                    undefined,
                    undefined,
                    true
                );
            };
        },
    }),
    withPropsOnChange(
        [
            'fields',
            'facilityOptions',
            'elasticStorageLocationOptions',
            'facilityIdFilter',
            'departmentId',
            'requiredPermissions',
            'formModel',
            'excludeExpired',
        ],
        ({
            fields: { facilityId, storageLocationId },
            facilityOptions,
            elasticStorageLocationOptions,
            facilityIdFilter,
            departmentId,
            requiredPermissions,
            formModel,
            excludeExpired,
        }) => {
            const shouldExcludeExpiredStorageLocations =
                formModel?.excludeExpiredStorageLocations || excludeExpired;

            const filteredFacilityOptions = shouldExcludeExpiredStorageLocations
                ? filter(
                      facilityOptions(),
                      (facility) =>
                          moment(facility.expiredDateUtc).isAfter(moment()) ||
                          facility.expiredDateUtc == null
                  )
                : facilityOptions();

            const filteredStorageLocationOptions = shouldExcludeExpiredStorageLocations
                ? filter(
                      elasticStorageLocationOptions({ departmentId }),
                      (location) =>
                          moment(location.expiredDateUtc).isAfter(moment()) ||
                          !location.expiredDateUtc
                  )
                : elasticStorageLocationOptions({ departmentId });

            return {
                fields: {
                    facilityId: {
                        options: !!facilityIdFilter
                            ? filter(filteredFacilityOptions, { value: facilityIdFilter })
                            : filteredFacilityOptions,
                        ...facilityId,
                    },
                    storageLocationId: {
                        options: filteredStorageLocationOptions,
                        ...storageLocationId,
                    },
                },
                // always sort
                sortOptions: true,
                requiredPermissions,
            };
        }
    ),
    multiFieldSelectFactory
)(AsyncSelect);

/**
 * Typeahead for searching both facilities and storage locations. All facilities
 *   and all previously cached storage locations will display as options
 *   immediately. To search for storage locations only, use
 *   <StorageLocationSelect> instead.
 *
 * Delete this after STORAGE_LOCATION_SEARCH_IMPROVEMENTS_ENABLED is globally enabled in prod.
 * @param {Object} props Same props as `Select`.
 * @param {Object} props.fields
 */

export const DeprecatedFacilityStorageLocationSelect = FacilityStorageLocationSelect;

export const DeprecatedRRFFacilityStorageLocationSelect = compose(
    setDisplayName('RRFFacilityStorageLocationSelect'),
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            storageLocationId: null,
            facilityId: null,
        }),
    }),
    connectRRFMultiFieldInput
)(FacilityStorageLocationSelect);
