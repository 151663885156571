import { v4 as uuidv4 } from 'uuid';
import Konva from 'konva';
import { sortBy } from 'lodash';
import {
    DiagramCategoryEnum,
    DiagramCategoryEnumType,
    DiagramTypeEnum,
    DiagramTypeEnumType,
} from '@mark43/rms-api';
import invariant from 'invariant';
import { OverlayIdEnumType } from '~/client-common/core/enums/universal/overlayIdEnum';
import { logWarning } from '../../../../../../../core/logging';
import { btoaUnicode } from '../../../../../../../lib/base64';
import { retry } from '../../../../../../core/utils/promiseHelpers';
import {
    ArrangeAction,
    CrashDiagramPosition,
    FlipAction,
    ImageDimensions,
    KonvaWidget,
    Widget,
    WidgetAction,
    widgetActions,
    widgetTypes,
} from '../types';
import { GLOBAL_DEPARTMENT_ID } from '../../../../../../admin/diagram-assets/forms/diagramAssetsAdminForm';
import { Asset, M43Asset, isExternalAsset, isM43Asset } from '../../../../../../diagram/types';
import { DiagramAssetsMap } from '../context/reducer/diagram';

export const getCrashDiagramModalOverlayId = (key: string): OverlayIdEnumType => {
    return `CRASH_DIAGRAM_MODAL_${key}`;
};

export const getWidgetActionType = (action: WidgetAction) => {
    switch (action) {
        case widgetActions.FLIP_ITEM_HORIZONTAL:
        case widgetActions.FLIP_ITEM_VERTICAL:
            return 'FLIP_WIDGET_ON_DIAGRAM';
        case widgetActions.BRING_ITEM_TO_FRONT:
        case widgetActions.SEND_ITEM_TO_BACK:
            return 'ARRANGE_WIDGET_ON_DIAGRAM';
        default:
            return;
    }
};

const flipWidgetActions = [widgetActions.FLIP_ITEM_HORIZONTAL, widgetActions.FLIP_ITEM_VERTICAL];
const arrangeWidgetActions = [widgetActions.BRING_ITEM_TO_FRONT, widgetActions.SEND_ITEM_TO_BACK];
export const isFlipAction = (action: WidgetAction): action is FlipAction => {
    return flipWidgetActions.includes(action as FlipAction);
};

export const isArrangeAction = (action: WidgetAction): action is ArrangeAction => {
    return arrangeWidgetActions.includes(action as ArrangeAction);
};

export const getImageDimensions = (base64String: string): Promise<ImageDimensions> => {
    return new Promise((resolve, reject) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(atob(base64String.split(',')[1]), 'image/svg+xml');
        const svgElement = svgDoc.documentElement;

        const viewBox = svgElement.getAttribute('viewBox');

        if (viewBox) {
            const [, , width, height] = viewBox.split(' ').map(Number);
            resolve({ width, height });
        } else {
            reject(new Error('Failed to get image dimensions from SVG.'));
        }
    });
};

export const calculateConstrainedPosition = (
    position: Required<
        Pick<
            CrashDiagramPosition,
            'rotation' | 'width' | 'height' | 'scaleX' | 'scaleY' | 'x' | 'y'
        >
    >,
    stageWidth: number,
    stageHeight: number,
    isDuplicateWidget?: boolean
) => {
    const { x, y, rotation, width, height, scaleX, scaleY } = position;

    // Convert rotation from degrees to radians
    const rotationRad = (rotation * Math.PI) / 180;

    const cosRad = Math.cos(rotationRad);
    const sinRad = Math.sin(rotationRad);

    const scaledWidth = width * scaleX;
    const scaledHeight = height * scaleY;

    // Calculate the dimensions of the rotated bounding box
    const rotatedWidth = Math.abs(scaledWidth * cosRad) + Math.abs(scaledHeight * sinRad);
    const rotatedHeight = Math.abs(scaledWidth * sinRad) + Math.abs(scaledHeight * cosRad);

    const xSpacing = isDuplicateWidget ? rotatedWidth : 0;
    const ySpacing = isDuplicateWidget ? rotatedHeight / 2 : 0;

    return {
        x: Math.min(Math.max(x + xSpacing, rotatedWidth / 2), stageWidth - rotatedWidth / 2),
        y: Math.min(Math.max(y + ySpacing, rotatedHeight / 2), stageHeight - rotatedHeight / 2),
    };
};

export const formatFileName = (filePath: string) => {
    const fileNameWithExtension = filePath.split('/').pop();

    if (!fileNameWithExtension) {
        return '';
    }

    const fileName = fileNameWithExtension.replace('.svg', '');

    // Replace hyphens with spaces and capitalize the first letter of each word
    const formattedFileName = fileName
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return formattedFileName;
};

export const getKonvaShapePosition = (konvaShape: KonvaWidget): CrashDiagramPosition => {
    const { x, y } = konvaShape.getPosition();
    const { offsetY, offsetX, rotation, scaleX, scaleY, skewX, skewY, width, height } =
        konvaShape.getAttrs();
    return {
        x,
        y,
        offsetY,
        offsetX,
        rotation,
        scaleX,
        scaleY,
        skewX,
        skewY,
        width: !!width ? Math.round(width) : undefined,
        height: !!height ? Math.round(height) : undefined,
    };
};

const SVG_DATA_ELEMENT = 'data:image/svg+xml;base64,';
export const JPEG_DATA_ELEMENT = 'data:image/jpeg;base64';

export const fetchSVGImage = async (svgImageUrl: string) => {
    try {
        const svgContents = await retry(
            () => {
                return fetch(svgImageUrl).then((response) => response.text());
            },
            { retries: 3 }
        );

        return {
            svgContents,
            svgDataUrl: `${SVG_DATA_ELEMENT}${btoaUnicode(svgContents)}`,
        };
    } catch {
        logWarning(`Failed to fetch and convert SVG to Base 64 for image: ${svgImageUrl}`);
        return undefined;
    }
};

export const isBase64DataUrl = (value: string) => {
    return value.startsWith(SVG_DATA_ELEMENT) || value.startsWith(JPEG_DATA_ELEMENT);
};

export const createWidget = (params: Omit<Widget, 'id' | 'konvaInstance'>): Widget => {
    if (params.type === widgetTypes.TEXT) {
        const { value, position, opacity } = params;
        const FONT_SIZE = 18;

        const textNode = new Konva.Text({
            text: value,
            fontSize: FONT_SIZE,
            position,
            opacity,
        });
        return {
            ...params,
            id: uuidv4(),
            konvaInstance: textNode,
            type: widgetTypes.TEXT,
        };
    } else {
        return {
            ...params,
            id: uuidv4(),
            konvaInstance: undefined,
            type: params.type,
        };
    }
};

export const getDefaultAsset = (assets: M43Asset[]): M43Asset | undefined => {
    const defaultAssets = assets.filter((asset) => asset.isDefault);

    if (defaultAssets.length === 0) {
        return undefined;
    }

    return (
        defaultAssets.find((asset) => asset.departmentId !== GLOBAL_DEPARTMENT_ID) ||
        defaultAssets[0]
    );
};

export const sortDiagramAssets = (assets: Asset[]) => {
    return sortBy(assets, (asset) => {
        if (isExternalAsset(asset)) {
            return 0;
        }
        if (asset.isDefault) {
            return asset.departmentId !== GLOBAL_DEPARTMENT_ID ? 1 : 2;
        }
        return 3;
    });
};

export const createDiagramAssetsMapInitialState = () => {
    const assetsMap: DiagramAssetsMap = Object.create(null);
    Object.values(DiagramTypeEnum).forEach((diagramType) => {
        const diagramTypeName = diagramType.name;
        assetsMap[diagramTypeName] = Object.create(null);

        const categoriesForDiagramType = Object.values(DiagramCategoryEnum).filter(
            (category) => category.diagramTypeId === diagramType.value
        );
        Object.values(categoriesForDiagramType).forEach((category) => {
            assetsMap[diagramTypeName][category.name] = {
                assets: [],
                isFetched: false,
            };
        });
    });

    return assetsMap;
};

export const getAssetUrl = (asset: Asset) => {
    if (isExternalAsset(asset)) {
        return asset.base64String;
    }

    if (isM43Asset(asset)) {
        return asset.file.fileWebServerPath;
    }

    invariant(false, 'Asset type is not supported');
};

export const getNextExternalAssetId = (
    assets: DiagramAssetsMap,
    diagramType: DiagramTypeEnumType,
    diagramCategory: DiagramCategoryEnumType
): number => {
    const categoryAssetsState = assets[diagramType][diagramCategory];
    if (!Array.isArray(categoryAssetsState.assets)) {
        return 1;
    }

    return categoryAssetsState.assets.filter((asset) => isExternalAsset(asset)).length + 1;
};
