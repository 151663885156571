import { ElasticSearchTypeEnum, RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState, withHandlers, withProps } from 'recompose';

import { createStructuredSelector } from 'reselect';
import componentStrings from '~/client-common/core/strings/componentStrings';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import formsRegistry from '../../../../../core/formsRegistry';
import EntitySearch from '../../../../core/entity-search/EntitySearch';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import {
    setFormWithSearchResult,
    storeItemSidePanelMasterItemId,
    itemSidePanelOperationSelector,
    storeItemSidePanelIsFormHidden,
    itemSidePanelIsAutosearchSelector,
    storeItemSidePanelIsAutosearch,
    itemSidePanelQuerySelector,
    generateSidePanelFormDataFromRmsVehicle,
} from '../../state/ui/itemSidePanel';

import testIds from '../../../../../core/testIds';

const strings = componentStrings.reports.core.VehicleInlineSearch;

const mapStateToProps = createStructuredSelector({
    itemSidePanelOperation: itemSidePanelOperationSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    itemSidePanelIsAutosearch: itemSidePanelIsAutosearchSelector,
    query: itemSidePanelQuerySelector,
});
const mapDispatchToProps = {
    setFormWithSearchResult,
    storeItemSidePanelMasterItemId,
    storeItemSidePanelIsFormHidden,
    storeItemSidePanelIsAutosearch,
};

const VehicleInlineSearch = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withState('value', 'setSearchValue'),
    withHandlers({
        onAddClick({ storeItemSidePanelIsFormHidden, dexData }) {
            return () => {
                if (dexData) {
                    formsRegistry.maybeDeferredOperation(
                        RefContextEnum.FORM_HYDRATED_ITEM.name,
                        undefined,
                        (form) => {
                            Object.entries(
                                generateSidePanelFormDataFromRmsVehicle(dexData)
                            ).forEach(([key, value]) => {
                                form.set(key, value);
                            });
                        }
                    );
                }
                storeItemSidePanelIsFormHidden(false);
            };
        },
        onResultClick({
            currentUserDepartmentId,
            storeItemSidePanelMasterItemId,
            itemSidePanelOperation,
            storeItemSidePanelIsFormHidden,
            setFormWithSearchResult,
        }) {
            return ({ item: { departmentId, id } }, dexData) => {
                const itemInCurrentUserDepartment = currentUserDepartmentId === departmentId;

                if (itemSidePanelOperation === itemSidePanelOperationEnum.CREATE) {
                    if (itemInCurrentUserDepartment) {
                        storeItemSidePanelMasterItemId(id);
                    }
                    storeItemSidePanelIsFormHidden(false);
                    setFormWithSearchResult(
                        id,
                        itemInCurrentUserDepartment,
                        dexData ? { vehicle: dexData } : null
                    );
                }
            };
        },
        onChange({ setSearchValue }) {
            return (value) => setSearchValue(value);
        },
    }),
    withProps(({ width }) => ({
        ...strings,
        width: isUndefinedOrNull(width) ? '100%' : `${width}px`,
        entityType: ElasticSearchTypeEnum.VEHICLE.name,
    }))
)((props) => (
    <div data-test-id={testIds.VEHICLE_INLINE_SEARCH} style={{ marginBottom: '13px' }}>
        <EntitySearch {...props} isVehicle={true} />
    </div>
));

export default VehicleInlineSearch;
