import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose, withPropsOnChange, withProps } from 'recompose';
import { compact, flatMap, reduce, map, values, first } from 'lodash';
import styled, { css } from 'styled-components';
import { Observer, Fields } from 'markformythree';
import { cssVar, FormControl, Input } from 'arc';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config/';
import FormElement from '../../forms/components/FormElement';
import { clearfix } from '../../styles/mixins';
import {
    DeprecatedArbiterMFTText,
    DeprecatedMFTText,
    DeprecatedInput,
} from '../../forms/components/Text';
import { AllFieldsAreHidden } from '../../components/SomeFieldsHaveValue';

/**
 *
 * NOTE: IF you update this file, then also please update: client/src/scripts/modules/core/forms/components/RRFMultiTextInput.js
 *
 */

const SuffixText = styled.span`
    color: ${(props) => props.theme.colors.placeholder};
    margin-left: 6px;
`;

const InputWithSuffixWrapper = styled.div`
    ${clearfix};
    display: flex;
    align-items: center;
`;

const StyledControl = styled(FormControl)`
    // cannot style <Input as="div"> directly, because classname applied to internal wrapper component.
    div.chakra-input {
        display: flex;
        justify-content: ${(props) => props.justifyContent || 'flex-end'};
        align-items: center;
        // matches margin-left in SuffixText
        column-gap: 6px;

        // extra flair to highlight selected multi text
        &:focus-within {
            z-index: 1;
            border-color: ${cssVar('arc.colors.brand.default')};
            border-radius: ${cssVar('arc.radii.md')};
            box-shadow: 0 0 0 1px ${cssVar('arc.colors.brand.default')};
        }
    }
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || 'flex-end'};
`;

const legacyUnstyledInputCss = css`
    text-align: right;
    box-shadow: none;
    border: 0;
    background-color: transparent;
    padding: 0;

    && {
        margin: 0;
    }
`;

const ArbiterMFTLegacyUnstyledInput = styled(DeprecatedArbiterMFTText)`
    ${legacyUnstyledInputCss}
`;

const MFTTextLegacyUnstyledInput = styled(DeprecatedMFTText)`
    ${legacyUnstyledInputCss}
`;

const unstyledInputCss = css`
    line-height: 1;

    input {
        text-align: right;
        box-shadow: none;
        border: 0;
        padding: 0;
        height: auto;
        min-height: ${cssVar('arc.space.6')};
        height: ${cssVar('arc.space.6')};
    }

    // Inner inputs should not show invalid borders
    && input[aria-invalid]:not(:focus) {
        box-shadow: unset;
    }

    && {
        margin: 0;
    }
`;

const ArbiterMFTUnstyledInput = styled(DeprecatedArbiterMFTText)`
    ${unstyledInputCss}
`;

const MFTTextUnstyledInput = styled(DeprecatedMFTText)`
    ${unstyledInputCss}
`;

const pathConfigWithFieldName = ({ rawPathConfig, getFieldNameFromPath }) =>
    reduce(
        rawPathConfig,
        (acc, { path, ...resConf }) => {
            acc.push({
                path,
                ...resConf,
                fieldName: getFieldNameFromPath(path),
            });
            return acc;
        },
        []
    );

// All this really does is pull the proper label for the
// field, if a fieldName was given.
const MultiTextInput = compose(
    connect(
        createStructuredSelector({
            formatFieldByName: formatFieldByNameSelector,
            applicationSettings: applicationSettingsSelector,
        })
    ),
    withPropsOnChange(
        ['formatFieldByName', 'label', 'fieldName'],
        ({ formatFieldByName, label, fieldName }) => ({
            label: label || (fieldName ? formatFieldByName(fieldName) || '' : ''),
        })
    )
)(
    ({
        pathConfig,
        width,
        disabled,
        justifyContent,
        label,
        applicationSettings,
        form,
        UnstyledInput,
        LegacyUnstyledInput,
        ...restProps
    }) => {
        const getFieldName = (path) => form.getConfigurationForPath(path).fieldName;

        return (
            <AllFieldsAreHidden
                formName={form.name}
                pathConfig={pathConfigWithFieldName({
                    rawPathConfig: pathConfig,
                    getFieldNameFromPath: getFieldName,
                })}
            >
                {(allFieldsAreHidden) =>
                    !allFieldsAreHidden && (
                        <Observer
                            subscriptions={reduce(
                                pathConfig,
                                (acc, { path }) => {
                                    acc[path] = [path, Fields.ERRORS];
                                    return acc;
                                },
                                {}
                            )}
                            render={(pathToErrorMap) => {
                                const error = first(
                                    compact(flatMap(values(pathToErrorMap), values))
                                );

                                let className = '';

                                // Note: We can delete the `className` variable
                                // when `ARC_RELEASE_CYCLE_THREE_COMPONENTS` is globally enabled
                                if (!applicationSettings.ARC_RELEASE_CYCLE_THREE_COMPONENTS) {
                                    if (disabled) {
                                        className = 'disabled';
                                    } else if (!!error) {
                                        className = 'error';
                                    }
                                }

                                return (
                                    <div className="no-label-margin">
                                        <FormElement
                                            label={label}
                                            error={error}
                                            forceShowError={true}
                                            width={width}
                                            {...restProps}
                                        >
                                            <FeatureFlagged
                                                flag="ARC_RELEASE_CYCLE_THREE_COMPONENTS"
                                                fallback={
                                                    <DeprecatedInput
                                                        as="div"
                                                        error={error}
                                                        disabled={disabled}
                                                        className={className}
                                                    >
                                                        <InputWrapper
                                                            justifyContent={justifyContent}
                                                        >
                                                            {map(
                                                                pathConfig,
                                                                ({ suffix, ...config }) => {
                                                                    const value = disabled
                                                                        ? { value: undefined }
                                                                        : {};
                                                                    return (
                                                                        <InputWithSuffixWrapper>
                                                                            <LegacyUnstyledInput
                                                                                className={
                                                                                    undefined
                                                                                }
                                                                                label={undefined}
                                                                                error={undefined}
                                                                                disabled={disabled}
                                                                                key={config.path}
                                                                                {...config}
                                                                                {...value}
                                                                            />
                                                                            <SuffixText>
                                                                                {suffix}
                                                                            </SuffixText>
                                                                        </InputWithSuffixWrapper>
                                                                    );
                                                                }
                                                            )}
                                                        </InputWrapper>
                                                    </DeprecatedInput>
                                                }
                                            >
                                                <StyledControl
                                                    isInvalid={!!error}
                                                    isDisabled={disabled}
                                                    justifyContent={justifyContent}
                                                >
                                                    <Input as="div">
                                                        {map(
                                                            pathConfig,
                                                            ({ suffix, ...config }) => {
                                                                const value = disabled
                                                                    ? { value: undefined }
                                                                    : {};
                                                                return (
                                                                    <InputWithSuffixWrapper>
                                                                        <UnstyledInput
                                                                            className={undefined}
                                                                            label={undefined}
                                                                            error={undefined}
                                                                            disabled={disabled}
                                                                            key={config.path}
                                                                            {...config}
                                                                            {...value}
                                                                            textAlign="right"
                                                                        />
                                                                        <SuffixText>
                                                                            {suffix}
                                                                        </SuffixText>
                                                                    </InputWithSuffixWrapper>
                                                                );
                                                            }
                                                        )}
                                                    </Input>
                                                </StyledControl>
                                            </FeatureFlagged>
                                        </FormElement>
                                    </div>
                                );
                            }}
                        />
                    )
                }
            </AllFieldsAreHidden>
        );
    }
);

export const ArbiterMFTMultiTextInput = compose(
    withProps(() => ({
        UnstyledInput: ArbiterMFTUnstyledInput,
        LegacyUnstyledInput: ArbiterMFTLegacyUnstyledInput,
    }))
)(MultiTextInput);

export const MFTMultiTextInput = compose(
    withProps(() => ({
        UnstyledInput: MFTTextUnstyledInput,
        LegacyUnstyledInput: MFTTextLegacyUnstyledInput,
    }))
)(MultiTextInput);
