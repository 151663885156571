import { AttributeTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';

import { Fieldset } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';

import FormRow from '../../forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import {
    TWO_CHAR_TEXT_INPUT_WIDTH,
    THREE_CHAR_TEXT_INPUT_WIDTH,
} from '../../names/config/formWidths';
import testIds from '../../../../core/testIds';
import { ExpandableFormLabel, LabelExpander } from '../../components/ExpandableFormLabel';
import { SomeFieldsHaveTruthyValue } from '../../components/SomeFieldsHaveValue';
import { PersonProfileFormAttributeFieldset } from './PersonProfileFormAttributeFieldset';
import { ArbiterMFTMultiTextInput } from './MultiTextInput';

const strings = componentStrings.core.PersonProfileForm.PersonProfileFormBodyRelatedFields;

const heightPathConfig = [
    {
        path: 'heightFeet',
        suffix: strings.ft,
        width: TWO_CHAR_TEXT_INPUT_WIDTH,
        size: 'sm',
    },
    {
        path: 'heightInches',
        suffix: strings.in,
        width: TWO_CHAR_TEXT_INPUT_WIDTH,
        size: 'sm',
    },
];

const heightMinPathConfig = [
    {
        path: 'heightMinFeet',
        suffix: strings.ft,
        width: TWO_CHAR_TEXT_INPUT_WIDTH,
        size: 'sm',
    },
    {
        path: 'heightMinInches',
        suffix: strings.in,
        width: TWO_CHAR_TEXT_INPUT_WIDTH,
        size: 'sm',
    },
];

const heightMaxPathConfig = [
    {
        path: 'heightMaxFeet',
        suffix: strings.ft,
        width: TWO_CHAR_TEXT_INPUT_WIDTH,
        size: 'sm',
    },
    {
        path: 'heightMaxInches',
        suffix: strings.in,
        width: TWO_CHAR_TEXT_INPUT_WIDTH,
        size: 'sm',
    },
];

const weightPathConfig = [
    {
        path: 'weight',
        suffix: strings.lbs,
        width: THREE_CHAR_TEXT_INPUT_WIDTH,
        size: 'sm',
    },
];

const weightMinPathConfig = [
    {
        path: 'weightRangeMin',
        suffix: strings.lbs,
        width: THREE_CHAR_TEXT_INPUT_WIDTH,
        size: 'sm',
    },
];

const weightMaxPathConfig = [
    {
        path: 'weightRangeMax',
        suffix: strings.lbs,
        width: THREE_CHAR_TEXT_INPUT_WIDTH,
        size: 'sm',
    },
];

const showAdditionalHeightFields = 'showAdditionalHeightFields';
const showAdditionalHeightFieldsArray = [showAdditionalHeightFields];

const showAdditionalWeightFields = 'showAdditionalWeightFields';
const showAdditionalWeightFieldsArray = [showAdditionalWeightFields];

const physicalDescriptorFieldNames = [
    'hairStyleAttrId',
    'hairLengthAttrId',
    'buildAttrId',
    'skinToneAttrId',
    'facialHairTypeAttrId',
    'visionAttrId',
];

const FlexRow = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Flex = styled.div`
    display: flex;
`;

const StyledArbiterMFTMultiTextInput = styled(ArbiterMFTMultiTextInput)`
    flex: 1;
    max-width: 6rem;
`;
const FieldsetWithMargin = styled(Fieldset)`
    margin-bottom: var(--arc-space-4);
`;
export function PersonProfileFormBodyRelatedFields({ form, linkType }) {
    const isVictim =
        linkType === LinkTypesEnum.VICTIM_IN_OFFENSE || linkType === LinkTypesEnum.VICTIM_IN_REPORT;

    return (
        <div>
            <FlexRow>
                <SomeFieldsHaveTruthyValue formPathsToObserve={showAdditionalHeightFieldsArray}>
                    {(someFieldsHaveTruthyValue) => (
                        <StyledArbiterMFTMultiTextInput
                            form={form}
                            disabled={someFieldsHaveTruthyValue}
                            pathConfig={heightPathConfig}
                            fieldName={form.getConfigurationForPath('heightLabel').fieldName}
                        />
                    )}
                </SomeFieldsHaveTruthyValue>
                <SomeFieldsHaveTruthyValue formPathsToObserve={showAdditionalHeightFieldsArray}>
                    {(someFieldsHaveTruthyValue) =>
                        !someFieldsHaveTruthyValue && (
                            <LabelExpander
                                onClick={() => {
                                    form.transaction(() => {
                                        form.set('height', undefined);
                                        form.set('heightFeet', undefined);
                                        form.set('heightInches', undefined);
                                        form.set(showAdditionalHeightFields, true);
                                    });
                                }}
                                data-test-id={testIds.PERSON_SIDE_PANEL_ADD_HEIGHT_RANGE}
                            >
                                {strings.addHeightRange}
                            </LabelExpander>
                        )
                    }
                </SomeFieldsHaveTruthyValue>
                <Flex>
                    <StyledArbiterMFTMultiTextInput
                        form={form}
                        pathConfig={heightMinPathConfig}
                        fieldName={form.getConfigurationForPath('heightMinLabel').fieldName}
                    />
                    <StyledArbiterMFTMultiTextInput
                        form={form}
                        pathConfig={heightMaxPathConfig}
                        fieldName={form.getConfigurationForPath('heightMaxLabel').fieldName}
                    />
                </Flex>
                <SomeFieldsHaveTruthyValue formPathsToObserve={showAdditionalHeightFieldsArray}>
                    {(someFieldsHaveTruthyValue) =>
                        someFieldsHaveTruthyValue && (
                            <LabelExpander
                                onClick={() => {
                                    form.transaction(() => {
                                        form.set('heightMaxFeet', undefined);
                                        form.set('heightMaxInches', undefined);
                                        form.set('heightMinFeet', undefined);
                                        form.set('heightMinInches', undefined);
                                        form.set(showAdditionalHeightFields, false);
                                    });
                                }}
                                data-test-id={testIds.PERSON_SIDE_PANEL_REMOVE_HEIGHT_RANGE}
                            >
                                {strings.removeRange}
                            </LabelExpander>
                        )
                    }
                </SomeFieldsHaveTruthyValue>
            </FlexRow>
            <FlexRow>
                <SomeFieldsHaveTruthyValue formPathsToObserve={showAdditionalWeightFieldsArray}>
                    {(someFieldsHaveTruthyValue) => (
                        <StyledArbiterMFTMultiTextInput
                            form={form}
                            disabled={someFieldsHaveTruthyValue}
                            pathConfig={weightPathConfig}
                            fieldName={form.getConfigurationForPath('weight').fieldName}
                        />
                    )}
                </SomeFieldsHaveTruthyValue>
                <SomeFieldsHaveTruthyValue formPathsToObserve={showAdditionalWeightFieldsArray}>
                    {(someFieldsHaveTruthyValue) =>
                        !someFieldsHaveTruthyValue && (
                            <LabelExpander
                                onClick={() => {
                                    form.transaction(() => {
                                        form.set('weight', undefined);
                                        form.set(showAdditionalWeightFields, true);
                                    });
                                }}
                                data-test-id={testIds.PERSON_SIDE_PANEL_ADD_WEIGHT_RANGE}
                            >
                                {strings.addWeightRange}
                            </LabelExpander>
                        )
                    }
                </SomeFieldsHaveTruthyValue>
                <Flex>
                    <StyledArbiterMFTMultiTextInput
                        form={form}
                        pathConfig={weightMinPathConfig}
                        fieldName={form.getConfigurationForPath('weightRangeMin').fieldName}
                    />
                    <StyledArbiterMFTMultiTextInput
                        form={form}
                        pathConfig={weightMaxPathConfig}
                        fieldName={form.getConfigurationForPath('weightRangeMax').fieldName}
                    />
                </Flex>
                <SomeFieldsHaveTruthyValue formPathsToObserve={showAdditionalWeightFieldsArray}>
                    {(someFieldsHaveTruthyValue) =>
                        !!someFieldsHaveTruthyValue && (
                            <LabelExpander
                                onClick={() => {
                                    form.transaction(() => {
                                        form.set('weightRangeMin', undefined);
                                        form.set('weightRangeMax', undefined);
                                        form.set(showAdditionalWeightFields, false);
                                    });
                                }}
                                data-test-id={testIds.PERSON_SIDE_PANEL_REMOVE_WEIGHT_RANGE}
                            >
                                {strings.removeRange}
                            </LabelExpander>
                        )
                    }
                </SomeFieldsHaveTruthyValue>
            </FlexRow>
            <Flex>
                <ArbiterMFTAttributeSelect
                    path="eyeColorAttrId"
                    attributeType={AttributeTypeEnum.EYE_COLOR.name}
                />
                <ArbiterMFTAttributeSelect
                    path="hairColorAttrId"
                    attributeType={AttributeTypeEnum.HAIR_COLOR.name}
                />
            </Flex>
            <FieldsetWithMargin path="attributes">
                <PersonProfileFormAttributeFieldset
                    attributeType={AttributeTypeEnum.SUBJECT_DATA_DISABILITY.name}
                    path={AttributeTypeEnum.SUBJECT_DATA_DISABILITY.name}
                    grouped={false}
                />
            </FieldsetWithMargin>
            <ExpandableFormLabel
                forceOpen={isVictim}
                formPathsToObserve={physicalDescriptorFieldNames}
                expanderContent={(showContent) => (
                    <LabelExpander
                        position="bottom"
                        onClick={showContent}
                        data-test-id={testIds.PERSON_SIDE_PANEL_ADD_PHYSICAL_DESCRIPTORS}
                    >
                        {strings.addPhysicalDescriptors}
                    </LabelExpander>
                )}
            >
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        path="hairStyleAttrId"
                        attributeType={AttributeTypeEnum.HAIR_STYLE.name}
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        path="hairLengthAttrId"
                        attributeType={AttributeTypeEnum.HAIR_LENGTH.name}
                        length="md"
                    />
                </FormRow>
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        path="buildAttrId"
                        attributeType={AttributeTypeEnum.BUILD.name}
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        path="skinToneAttrId"
                        attributeType={AttributeTypeEnum.SKIN_TONE.name}
                        length="md"
                    />
                </FormRow>
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        path="facialHairTypeAttrId"
                        attributeType={AttributeTypeEnum.FACIAL_HAIR_TYPE.name}
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        path="visionAttrId"
                        attributeType={AttributeTypeEnum.VISION.name}
                        length="md"
                    />
                </FormRow>
                <Fieldset path="attributes">
                    <PersonProfileFormAttributeFieldset
                        attributeType={AttributeTypeEnum.PHYSICAL_CHARACTERISTIC.name}
                        path={AttributeTypeEnum.PHYSICAL_CHARACTERISTIC.name}
                        grouped={true}
                    />
                </Fieldset>
            </ExpandableFormLabel>
        </div>
    );
}
