import { VisibleFilter } from 'arc';
import { convertDateRangeOptionValueToFieldValues } from '~/client-common/core/dates/utils/dateRangeHelpers';
import dateRangeTypeEnum from '~/client-common/core/enums/client/dateRangeTypeEnum';

export const INVENTORY_AND_AUDIT_FILTER_IDS = {
    COMPLETION_DATE_TIME_RANGE_FILTER_ID: 1,
    INVENTORY_TYPE_FILTER_ID: 2,
    RESPONSIBLE_PERSONNEL_FILTER_ID: 3,
    STORAGE_LOCATION_FILTER_ID: 4,
} as const;

const convertDateTimeRangeToSearchQuery = (
    range: string,
    startDateUtc?: string,
    endDateUtc?: string
) => {
    if (range === dateRangeTypeEnum.CUSTOM_RANGE) {
        return {
            completedEndDateUtc: endDateUtc,
            completedStartDateUtc: startDateUtc,
        };
    }
    if (range === dateRangeTypeEnum.NO_FILTER) {
        return undefined;
    }

    const result = convertDateRangeOptionValueToFieldValues(
        range,
        startDateUtc,
        endDateUtc,
        undefined,
        undefined
    );

    if (range === 'WITHIN_LAST_PERIOD_P1Y' || range === 'WITHIN_LAST_PERIOD_P1M') {
        return {
            completedToDatePeriod: result.toDatePeriod ?? result.withinLastPeriod,
            completedWithinLastPeriod: undefined,
        };
    }
    return {
        completedWithinLastPeriod: result.withinLastPeriod,
    };
};

const getAppliedFiltersByFilterId = (filters: VisibleFilter[], filterId: number) => {
    return filters.find((filter) => filter.id === filterId);
};

export const convertInventoryDashboardArcFiltersToSearchFormData = (
    filters: VisibleFilter[],
    departmentId: number,
    startDateUtc?: string,
    endDateUtc?: string
) => {
    const completionDateTimeRangeFilter = getAppliedFiltersByFilterId(
        filters,
        INVENTORY_AND_AUDIT_FILTER_IDS.COMPLETION_DATE_TIME_RANGE_FILTER_ID
    );
    const completionDateTimeRange = completionDateTimeRangeFilter?.appliedOptions[0].value;

    // We can't filter on a custom range without having a start and end date set.
    if (
        completionDateTimeRange === dateRangeTypeEnum.CUSTOM_RANGE &&
        !startDateUtc &&
        !endDateUtc
    ) {
        return undefined;
    }

    const inventoryTypeFilter = getAppliedFiltersByFilterId(
        filters,
        INVENTORY_AND_AUDIT_FILTER_IDS.INVENTORY_TYPE_FILTER_ID
    );

    const responsibleOwners = getAppliedFiltersByFilterId(
        filters,
        INVENTORY_AND_AUDIT_FILTER_IDS.RESPONSIBLE_PERSONNEL_FILTER_ID
    );

    const ownerUserIds = responsibleOwners
        ? responsibleOwners.appliedOptions.map((item) => Number(item.value))
        : [];

    const storageLocationFilter = getAppliedFiltersByFilterId(
        filters,
        INVENTORY_AND_AUDIT_FILTER_IDS.STORAGE_LOCATION_FILTER_ID
    );

    const storageLocationIds = storageLocationFilter
        ? storageLocationFilter.appliedOptions.map((item) => Number(item.value))
        : [];

    return {
        departmentIds: [departmentId],
        inventoryType: inventoryTypeFilter?.appliedOptions[0].value,
        ...(completionDateTimeRange
            ? convertDateTimeRangeToSearchQuery(completionDateTimeRange, startDateUtc, endDateUtc)
            : undefined),
        ownerUserIds,
        storageLocationIds,
    };
};
