import { joinTruthyValues } from '../../../../helpers/stringHelpers';

export default function formatRenSequenceNumber(
    reportingEventNumber: string,
    sequenceNumber: number,
    { bothRequired = false }: { bothRequired?: boolean } = {}
): string {
    if (bothRequired) {
        return reportingEventNumber && sequenceNumber
            ? `${reportingEventNumber}-${sequenceNumber}`
            : '';
    } else {
        return joinTruthyValues([reportingEventNumber, sequenceNumber], '-');
    }
}
