import { Registry } from 'markformythree';
import { RefContextEnum } from '@mark43/rms-api';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { PhotoLineupFilterFormConfiguration } from '../modules/cases/case-photo-lineups/state/forms/photoLineupFilterForm';
import { ManualLineupFormConfiguration } from '../modules/cases/case-photo-lineups/state/forms/manualUploadLineupForm';
import { FormSequenceIncreaseFormConfiguration } from '../modules/support/id-generator/state/forms/idSequenceModalForm';
import { FormDeleteEvidenceItemFormConfiguration } from '../modules/reports/core/components/items/DeleteEvidenceItemForm';
import { IdFormatConfigurationFormConfiguration } from '../modules/support/id-generator/state/forms/idFormatConfigurationForm';
import { FormComplianceExportFormConfiguration } from '../modules/compliance/components/ComplianceExportForm';
import { FormComplianceExportAuthenticationFormConfiguration } from '../modules/compliance/components/ComplianceExportAuthenticationForm';
import { TestHandlebarsMFTFormConfiguration } from '../modules/admin/view-handlebars/components/ViewHandlebarsForm';
import { RapidSosAdminFormConfiguration } from '../modules/admin/cad-rapid-sos/state/forms/rapidSosAdminForm';
import {
    GEO_CAUTION_FORM_NAME,
    GeoCautionAdminFormConfiguration,
} from '../modules/admin/geo-cautions/forms/geoCautionsAdminForm';
import { ReasonForRelationFormConfiguration } from '../modules/cases/core/state/form/reasonForRelationForm';
import { RecordPermissionsFormConfiguration } from '../modules/core/permissions/components/RecordPermissionsForm';
import { FormTaskConfiguration } from '../modules/tasks/core/forms/taskForm';
import { UpdateDepartmentSubdomainAdminMFTFormConfiguration } from '../modules/admin/department-update-subdomain/components/DepartmentSubdomainUpdateForm';
import { StopFormConfiguration } from '../modules/reports/core/state/forms/stopForm';
import { DetailsEFileFormConfigurationT } from '../modules/e-files/core/state/forms/eFileDetailsForm';
import { NewPdfTemplateFormConfigurationT } from '../modules/admin/pdf/state/forms';
import { ReportModulesAdminFormConfiguration } from '../modules/admin/report-modules/state/forms/reportModulesAdminForm';
import { ReportCardLinksFormConfiguration } from '../modules/admin/report-types/state/forms/reportCardLinksForm';
import { BriefingRoutingFormConfiguration } from '../modules/briefings/state/forms/routingOptionsForm';
import { SendEmailFormConfiguration } from '../modules/core/emails/state/forms/sendEmailForm';
import { ReportCaseStatusesFormConfiguration } from '../modules/reports/core/state/forms/reportCaseStatusesForm';
import { CaseLinkedProfilesFormConfiguration } from '../modules/cases/linked-profiles/state/forms/caseLinkedProfilesForm';
import { DetentionDispositionFormConfiguration } from '../modules/reports/detention-disposition/state/forms';
import { PersonSearchToAddFormConfiguration } from '../modules/search/search-to-add/state/forms/personSearchToAddForm';
import { SearchBookingsFormConfiguration } from '../modules/search/core/state/forms/searchBookingsForm';
import { RuleConditionFormConfiguration } from '../modules/admin/rules/state/forms/ruleConditionAdminForm';
import { CrashEventInfoFormConfiguration } from '../modules/reports/core/state/forms/crashEventInfoForm';
import { SecurityClassificationsFormConfiguration } from '../modules/security-classification/state/forms/securityClassificationForm';
import { DisclaimerMessageFormConfiguration } from '../modules/admin/disclaimer-messages/forms/disclaimerMessageForm';
import { EFileProfileRemoveDeactivateFormConfiguration } from '../modules/e-files/core/state/forms/eFileProfileRemoveDeactivateForm';
import { MdmAdminFormConfiguration } from '../modules/admin/mobile-device-management/state/forms/mdmAdminForm';
import {
    EFileProfilesVictimWitnessSearchFormConfig,
} from '../modules/e-files/core/state/forms/eFileProfilesVictimWitnessSearchForm';
import {
    EFileProfilesDefendantsSearchFormConfig,
} from '../modules/e-files/core/state/forms/eFileProfilesDefendantsSearchForm';

/**
 * The type of all markformythree forms. These types flow through to usages of form.get(), useFormGetter(), and other
 * functions that return form state.
 *
 * If you are creating a new markformythree form in TypeScript, add it here.
 * Forms without types (written in JavaScript) cannot be added here.
 */
type FormsRegistryShape = {
    [formClientEnum.ADMIN_TEST_HANDLEBARS_FORM]: TestHandlebarsMFTFormConfiguration;
    [formClientEnum.CAD_RAPID_SOS_ADMIN_FORM]: RapidSosAdminFormConfiguration;
    [formClientEnum.CASE_PERMISSIONS_FORM]: RecordPermissionsFormConfiguration;
    [formClientEnum.COMPLIANCE_EXPORT]: FormComplianceExportFormConfiguration;
    [formClientEnum.COMPLIANCE_RESUBMISSION]: FormComplianceExportFormConfiguration;
    [formClientEnum.COMPLIANCE_EXPORT_AUTHENTICATION_FORM]: FormComplianceExportAuthenticationFormConfiguration;
    [formClientEnum.ENTITY_PROFILE_PERMISSIONS_FORM]: RecordPermissionsFormConfiguration;
    [formClientEnum.FORM_SEQUENCE_INCREASE_MODAL]: FormSequenceIncreaseFormConfiguration;
    [RefContextEnum.FORM_DELETE_EVIDENCE_ITEM.name]: FormDeleteEvidenceItemFormConfiguration;
    [RefContextEnum.FORM_ID_FORMAT_CONFIGURATION.name]: IdFormatConfigurationFormConfiguration;
    [formClientEnum.PHOTO_LINEUP_FILTER_FORM]: PhotoLineupFilterFormConfiguration;
    [RefContextEnum.FORM_PHOTO_LINEUPS_PHOTO_UPLOAD.name]: ManualLineupFormConfiguration;
    [formClientEnum.REPORT_PERMISSIONS_FORM]: RecordPermissionsFormConfiguration;
    [formClientEnum.WARRANT_PERMISSIONS_FORM]: RecordPermissionsFormConfiguration;
    [formClientEnum.SAVED_SEARCH_PERMISSIONS_FORM]: RecordPermissionsFormConfiguration;
    [formClientEnum.EVIDENCE_LOCATION_PERMISSIONS_FORM]: RecordPermissionsFormConfiguration;
    [formClientEnum.E_FILE_PERMISSIONS_FORM]: RecordPermissionsFormConfiguration;
    [RefContextEnum.FORM_TASK.name]: FormTaskConfiguration;
    [RefContextEnum.FORM_WARRANT_ID_FORMAT_CONFIGURATION
        .name]: IdFormatConfigurationFormConfiguration;
    [formClientEnum.DEPARTMENT_SUBDOMAIN_UPDATE]: UpdateDepartmentSubdomainAdminMFTFormConfiguration;
    [GEO_CAUTION_FORM_NAME]: GeoCautionAdminFormConfiguration;
    [RefContextEnum.FORM_STOP.name]: StopFormConfiguration;
    [RefContextEnum.FORM_REASON_FOR_RELATION_SIDE_PANEL.name]: ReasonForRelationFormConfiguration;
    [RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name]: ReasonForRelationFormConfiguration;
    [RefContextEnum.FORM_E_FILE_DETAILS_MODAL.name]: DetailsEFileFormConfigurationT;
    [formClientEnum.ADMIN_NEW_PDF_TEMPLATE_FORM]: NewPdfTemplateFormConfigurationT;
    [formClientEnum.REPORT_MODULES_ADMIN]: ReportModulesAdminFormConfiguration;
    [formClientEnum.REPORT_CARD_LINKS]: ReportCardLinksFormConfiguration;
    [RefContextEnum.FORM_BRIEFING_ROUTING_MODAL.name]: BriefingRoutingFormConfiguration;
    [RefContextEnum.FORM_REPORT_CASE_STATUSES_SIDE_PANEL.name]: ReportCaseStatusesFormConfiguration;
    [RefContextEnum.FORM_CASE_PROFILE_LINK_SIDE_PANEL.name]: CaseLinkedProfilesFormConfiguration;
    [formClientEnum.SEND_EMAIL_FORM]: SendEmailFormConfiguration;
    [formClientEnum.DETENTION_DISPOSITION_FORM]: DetentionDispositionFormConfiguration;
    [formClientEnum.PERSON_SEARCH_TO_ADD_FORM]: PersonSearchToAddFormConfiguration;
    [formClientEnum.SEARCH_BOOKINGS_FORM]: SearchBookingsFormConfiguration;
    [formClientEnum.RULE_CONDITION_ADMIN_FORM]: RuleConditionFormConfiguration;
    [RefContextEnum.FORM_CRASH_EVENT_INFO.name]: CrashEventInfoFormConfiguration;
    [RefContextEnum.FORM_SECURITY_CLASSIFICATION_MODAL
        .name]: SecurityClassificationsFormConfiguration;
    [RefContextEnum.FORM_ADMIN_DISCLAIMER_MESSAGES.name]: DisclaimerMessageFormConfiguration;
    [RefContextEnum.FORM_E_FILE_REMOVE_DEACTIVATE_ENTITY.name]: EFileProfileRemoveDeactivateFormConfiguration;
    [formClientEnum.MDM_ADMIN]: MdmAdminFormConfiguration;
    [formClientEnum.E_FILES_SEARCH_PROFILES_VICTIM_WITNESS_FORM]: EFileProfilesVictimWitnessSearchFormConfig;
    [formClientEnum.E_FILES_SEARCH_PROFILES_DEFENDANTS_FORM]: EFileProfilesDefendantsSearchFormConfig;
};

export type MFTFormsRegistry = Registry<FormsRegistryShape>;

const formsRegistry: MFTFormsRegistry = new Registry();

/**
 * The global markformythree registry. All MFT forms across the RMS are registered in this registry.
 *
 * If you need to register a form, it is not necessary to import this object to call formsRegistry.register(). It is
 * simpler to mount the Form component for the form to be automatically registered.
 */
export default formsRegistry;
