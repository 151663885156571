import React from 'react';
import { EntityTypeEnum, PersonProfile } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { FieldUiOptions } from 'dragon-react';

import { personProfilesWhereSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { RMSDragonConfigurationExtensions } from '../../../../rms-types';
import { currentReportIdSelector } from '../../../../../../legacy-redux/selectors/reportSelectors';
import { MFTSelect } from '../../../../../core/forms/components/selects/Select';

const getPersonProfileDisplayValue = ({ firstName, lastName }: PersonProfile) => {
    return `${firstName} ${lastName}`;
};

export const PersonProfileSelectSummary = ({ value }: { value: number }) => {
    const currentReportId = useSelector(currentReportIdSelector);
    const personProfilesWhere = useSelector(personProfilesWhereSelector);

    if (!currentReportId) {
        return null;
    }

    const personProfiles = personProfilesWhere({
        ownerId: currentReportId,
        ownerType: EntityTypeEnum.REPORT.name,
    });

    const personProfile = personProfiles.find((profile) => profile.id === value);

    if (!personProfile) {
        return null;
    }

    return <>{getPersonProfileDisplayValue(personProfile)}</>;
};

export const PersonProfileSelect = (
    props: FieldUiOptions<RMSDragonConfigurationExtensions>['props']
): JSX.Element | null => {
    const currentReportId = useSelector(currentReportIdSelector);
    const personProfilesWhere = useSelector(personProfilesWhereSelector);

    if (!currentReportId) {
        return null;
    }
    const personProfiles = personProfilesWhere({
        ownerId: currentReportId,
        ownerType: EntityTypeEnum.REPORT.name,
    });

    const options = personProfiles.map((personProfile) => {
        return {
            label: getPersonProfileDisplayValue(personProfile),
            value: personProfile.id,
        };
    });

    return <MFTSelect {...props} options={options} length="lg" />;
};
