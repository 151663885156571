import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL } from '~/client-common/core/enums/universal/fields';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { getMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { FormattedUser } from '~/client-common/core/users/components';
import { reportDefinitionRestrictViewReportOwnersSelector } from '~/client-common/core/domain/report-definitions/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import Modal from '../../../../../legacy-redux/components/core/Modal';
import Row from '../../../../core/components/Row';
import { RestrictVisibilityWithDash } from '../../../../core/components/RestrictVisibilityWithDash';
import ReactReduxForm from '../../../../core/forms/components/ReactReduxForm';
import { RRFUserSelect } from '../../../../core/forms/components/selects/UserSelect';
import {
    closeBox,
    saveBoxFailure,
    saveBoxHalt,
} from '../../../../../legacy-redux/actions/boxActions';
import {
    currentReportSelector,
    reportOwnerIdSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import reportChangeOwnerForm from '../../state/forms/reportChangeOwnerForm';
import { createModalSelector } from '../../../../core/box/state/ui';
import { refreshReportApprovalStatusView } from '../../state/ui/submissions';
import { useIsReportOwnerRestricted } from '../../../../core/hooks/useIsReportOwnerRestricted';

const context = {
    name: boxEnum.REPORT_CHANGE_OWNER_MODAL,
};

const strings = componentStrings.reports.core.ReportChangeOwnerModal;

function ReportChangeOwnerModal({
    reportOwnerId,
    closeBox,
    getMiniUserById,
    submitForm,
    currentReport,
    callback,
    reportDefinitionRestrictViewReportOwners,
}) {
    const reportOwnerDisplayValue = useFields(DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL)[
        DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL
    ];
    const checkIsReportOwnerRestricted = useIsReportOwnerRestricted();

    if (!currentReport) {
        return null;
    }

    const restrictViewReportOwners = reportDefinitionRestrictViewReportOwners(
        currentReport.reportDefinitionId
    );

    const formattedUser = reportOwnerId ? getMiniUserById(reportOwnerId) : undefined;

    return (
        <Modal
            context={context}
            title={strings.title(reportOwnerDisplayValue)}
            onClose={closeBox}
            onSave={() => submitForm(currentReport.id, callback)}
            okText={strings.okText}
            contentStyle={{ height: 360 }}
        >
            <p>
                {strings.currentOwner(reportOwnerDisplayValue)}{' '}
                <FeatureFlagged
                    flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                    fallback={
                        <FormattedUser
                            user={formattedUser}
                            format={FormattedUser.FORMATS.FULL_NAME}
                        >
                            {(user) => <b>{user}</b>}
                        </FormattedUser>
                    }
                >
                    <RestrictVisibilityWithDash
                        restricted={checkIsReportOwnerRestricted(
                            restrictViewReportOwners,
                            currentReport.permissionSet
                        )}
                    >
                        <FormattedUser
                            user={formattedUser}
                            format={FormattedUser.FORMATS.FULL_NAME}
                        >
                            {(user) => <b>{user}</b>}
                        </FormattedUser>
                    </RestrictVisibilityWithDash>
                </FeatureFlagged>
            </p>
            <ReactReduxForm {...reportChangeOwnerForm}>
                <Row>
                    <RRFUserSelect
                        length="md"
                        label={strings.newOwnerLabel(reportOwnerDisplayValue)}
                        path="ownerId"
                    />
                </Row>
            </ReactReduxForm>
        </Modal>
    );
}

const mapStateToProps = createStructuredSelector({
    reportOwnerId: reportOwnerIdSelector,
    getMiniUserById: getMiniUserByIdSelector,
    currentReport: currentReportSelector,
    reportDefinitionRestrictViewReportOwners: reportDefinitionRestrictViewReportOwnersSelector,
    callback: createModalSelector(context, 'callback'),
});

const mapDispatchToProps = (dispatch) => ({
    closeBox: () => {
        dispatch(reportChangeOwnerForm.actionCreators.reset());
        dispatch(closeBox(context));
    },
    submitForm: (currentReportId, callback) => {
        dispatch(
            reportChangeOwnerForm.actionCreators.submit((formModel) => {
                const resource = getReportResource();
                resource
                    .reassignSubmissionOwner(currentReportId, formModel.ownerId)
                    .then(() => {
                        dispatch(refreshReportApprovalStatusView(currentReportId));
                        // we have to do a knockout callback for legacy reports
                        if (callback) {
                            callback();
                        }
                        dispatch(reportChangeOwnerForm.actionCreators.reset());
                        dispatch(closeBox(context));
                    })
                    .catch((err) => {
                        dispatch(saveBoxFailure(context, err.message));
                    });
            })
        ).catch(() => {
            dispatch(saveBoxHalt(context));
        });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportChangeOwnerModal);
