import { DragonFormsResource } from '@mark43/rms-api';
import { createResource } from '../../../lib/resources/Resource';
import { req } from '../../../lib/ajax';
import { ParentFormPath } from '../rms-types';
import { uiConfigurationIdToNumber } from '../utils/ui-configuration-id-to-number';

export default createResource({
    name: 'Dragon Forms Resource',
    methods: {
        createFormReference({
            configuredFormId,
            parentReferencingUiConfigurationId,
            parentFormPathInstance,
            hideLoadingBar,
        }: {
            configuredFormId: number;
            parentReferencingUiConfigurationId: string;
            parentFormPathInstance: ParentFormPath;
            hideLoadingBar: boolean;
        }) {
            return req<DragonFormsResource.CreateInlineFormReferenceInstance>({
                method: 'POST',
                url: 'dragon/forms/inline/create',
                hideLoadingBar,
                data: {
                    formConfigurationId: configuredFormId,
                    parentReferencingUiConfigurationId: uiConfigurationIdToNumber(
                        parentReferencingUiConfigurationId
                    ),
                    parentFormPathInstance,
                },
            });
        },
    },
});
