// Core dependencies
import { FormEnum, RefContextEnum } from '@mark43/rms-api';

import _ from 'lodash';
import { combineReducers } from 'redux';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

// form modules
import advancedSearchCadTicketsForm from '../../modules/search/cad-tickets/state/forms/advancedSearchCadTicketsForm';
import advancedSearchPersonsForm from '../../modules/search/persons/state/forms/advancedSearchPersonsForm';
import advancedSearchReportsForm from '../../modules/search/reports/state/forms/advancedSearchReportsForm';
import advancedSearchOrganizationsForm from '../../modules/search/organizations/state/forms/advancedSearchOrganizationsForm';
import advancedSearchVehiclesForm from '../../modules/search/vehicles/state/forms/advancedSearchVehiclesForm';
import advancedSearchPropertyForm from '../../modules/search/property/state/forms/advancedSearchPropertyForm';
import advancedSearchAttachmentsForm from '../../modules/search/attachments/state/forms/advancedSearchAttachmentsForm';
import advancedSearchExportForm from '../../modules/search/core/state/forms/advancedSearchExportForm';
import advancedSearchCourtOrdersForm from '../../modules/search/court-orders/state/forms/advancedSearchCourtOrdersForm';
import itemPopoverForm from '../../modules/reports/core/state/forms/itemPopoverForm';
import allCasesSearchForm from '../../modules/cases/all-cases/state/forms/allCasesSearchForm';
import myCasesSearchForm from '../../modules/cases/my-cases/state/forms/myCasesSearchForm';
import unassignedReportsSearchForm from '../../modules/cases/unassigned-reports/state/forms/unassignedReportsSearchForm';
import locationAliasesAdminForm from '../../modules/forms/locationAliasesAdminForm';
import departmentSetupForm from '../../modules/admin/department-setup/state/forms/departmentSetupForm';
import caseDefinitionsAdminForm from '../../modules/admin/case-definitions/state/forms/caseDefinitionsAdminForm';
import warrantPermissionsAdminForm from '../../modules/admin/warrant-configuration/state/forms/warrantPermissionsAdminForm';
import masterEntityAdminForm from '../../modules/admin/master-entities/state/forms/masterEntityAdminForm';
import agencyProfileAdminForm from '../../modules/admin/agency-profiles/state/forms/agencyProfileAdminForm';
import societyProfileAdminForm from '../../modules/admin/society-profiles/state/forms/societyProfileAdminForm';
import reportSealingForm from '../../modules/record-privacy/sealing/state/forms/reportSealingForm';
import reportUnsealingForm from '../../modules/record-privacy/unsealing/state/forms/reportUnsealingForm';
import courtOrderEditForm from '../../modules/record-privacy/court-orders/state/forms/courtOrderEditForm';
import chargeVacatingForm from '../../modules/record-privacy/vacating/state/forms/chargeVacatingForm';
import fieldConfigurationsAdminForm from '../../modules/admin/field-configurations/state/forms/fieldConfigurationsAdminForm';
import rulesAdminForm from '../../modules/admin/rules/state/forms/rulesAdminForm';
import commandLineArgumentForm from '../../modules/admin/command-line/state/forms/commandLineArgumentForm';
import dispatchAreasForm from '../../modules/admin/dispatch-areas/state/forms/dispatchAreasForm';
import duplicateEventSearchForm from '../../modules/admin/duplicate-event-search/state/forms/duplicateEventSearchForm';
import cadUnitsAdminForm from '../../modules/admin/cad-units/state/forms/cadUnitsAdminForm';
import cadDesktopConfigsAdminForm from '../../modules/admin/cad-desktop-configs/state/forms/cadDesktopConfigsAdminForm';
import cadCallTakerStationsAdminForm from '../../modules/admin/cad-call-taker-stations/state/forms/cadCallTakerStationsAdminForm';
import cadMessagingGroupsAdminForm from '../../modules/admin/cad-messaging-groups/state/forms/cadMessagingGroupsAdminForm';
import cadMessagingAuditAdminForm from '../../modules/admin/cad-messaging-audit/state/forms/cadMessagingAuditAdminForm';
import cadBoloAuditAdminForm from '../../modules/admin/cad-bolo-audit/state/forms/cadBoloAuditAdminForm';
import cadServiceRotationAuditAdminForm from '../../modules/admin/cad-service-rotation-audit/state/forms/cadServiceRotationAuditAdminForm';
import cadServiceTypesAdminForm from '../../modules/admin/cad-service-rotation-types/state/forms/AdminForm';
import cadServiceProvidersAdminForm from '../../modules/admin/cad-service-rotation-providers/state/forms/AdminForm';
import cadRotationListsAdminForm from '../../modules/admin/cad-service-rotation-lists/state/forms/cadRotationListsAdminForm';
import cadRotationListEntryForm from '../../modules/admin/cad-service-rotation-lists/state/forms/cadRotationListEntryForm';
import locationCautionsAdminForm from '../../modules/admin/location-cautions/state/forms/locationCautionsAdminForm';
import locationCautionsSubPremiseForm from '../../modules/admin/location-cautions/state/forms/locationCautionsSubPremiseForm';
import caseDetailsForm from '../../modules/cases/core/state/form/caseDetailsForm';
import caseReviewForm from '../../modules/cases/case-review/state/form/caseReviewForm';
import editCaseRenForm from '../../modules/cases/core/state/form/editCaseRenForm';
import createCaseForm from '../../modules/cases/core/state/form/createCaseForm';
import labelPrintingForm from '../../modules/evidence/label-printing/state/forms/labelPrintingForm';
import documentExportingForm from '../../modules/reports/custodial-property-summary/state/forms/documentExportingForm';
import staffRemarkForm from '../../modules/evidence/core/state/forms/staffRemarkForm';
import evidenceDashboardSearchForm from '../../modules/evidence/dashboard/state/forms/evidenceDashboardSearchForm';
import inventoriesDashboardSearchFrom from '../../modules/evidence/inventories/state/forms/inventoriesDashboardSearchForm';
import createChainEventsForm from '../../modules/evidence/item-queue/state/forms/createChainEventsForm';
import evidenceItemSplitForm from '../../modules/evidence/item-split/state/forms/evidenceItemSplitForm';
import facilityAdminForm from '../../modules/admin/evidence-facilities/state/forms/facilityAdminForm';
import storageLocationAdminForm from '../../modules/admin/evidence-facilities/state/forms/storageLocationAdminForm';
import evidenceConfigAdminForm from '../../modules/admin/evidence-config/state/forms/evidenceConfigAdminForm';
import retentionPolicyAdminForm from '../../modules/admin/evidence-retention-policies/state/forms/retentionPolicyAdminForm';
import lookerDashboardForm from '../../modules/analysis/dashboard/state/forms/lookerDashboardForm';
import taskDetailsForm from '../../modules/cases/task-list/state/forms/taskDetailsForm';
import printingTemplatesAdminForm from '../../modules/admin/printing-templates/state/forms/printingTemplatesAdminForm';
import reportPrintingTemplatesAdminForm from '../../modules/admin/report-printing-templates/state/forms/reportPrintingTemplatesAdminForm';
import casePrintingTemplatesAdminForm from '../../modules/admin/case-printing-templates/state/forms/casePrintingTemplatesAdminForm';
import syncEntityForm from '../../modules/support/search-sync/state/forms/syncEntityForm';
import editRoleFilterUsersForm from '../../modules/admin/roles/state/forms/editRoleFilterUsersForm';
import editRoleFilterAbilitiesForm from '../../modules/admin/roles/state/forms/editRoleFilterAbilitiesForm';
import editRoleUsersManageStatusForm from '../../modules/admin/roles/state/forms/editRoleUsersManageStatusForm';
import editRoleAbilitiesModulesForm from '../../modules/admin/roles/state/forms/editRoleAbilitiesModulesForm';
import editRoleAbilitiesEnabledForm from '../../modules/admin/roles/state/forms/editRoleAbilitiesEnabledForm';
import editRoleApprovalsForm from '../../modules/admin/roles/state/forms/editRoleApprovalsForm';
import editRoleNameForm from '../../modules/admin/roles/state/forms/editRoleNameForm';
import notificationSettingsForm from '../../modules/notifications/settings/state/forms/notificationSettingsForm';
import towVehicleImpoundForm from '../../modules/reports/core/state/forms/towVehicleImpoundForm';
import impoundForm from '../../modules/reports/core/state/forms/impoundForm';
import responsibleOfficerForm from '../../modules/reports/custodial-property-summary/state/forms/responsibleOfficerForm';
import streetAliasesForm from '../../modules/admin/street-aliases/state/forms/streetAliasesForm';
import streetSegmentsForm from '../../modules/admin/street-segments/state/forms/streetSegmentsForm';
import adminAttributeTypeCodeTypeLinksForm from '../../modules/admin/attribute-type-code-type-links/state/forms/adminAttributeTypeCodeTypeLinks';
import arrestUcrClassificationForm from '../../modules/reports/ucr-classification/state/forms/arrestUcrClassificationForm';
import ucrClassificationForm from '../../modules/reports/ucr-classification/state/forms/ucrClassificationForm';
import usageLogsSearchForm from '../../modules/admin/usage-logs/state/forms/usageLogsSearchForm';
import warrantDetailsForm from '../../modules/warrants/warrant/state/forms/warrantDetailsForm';
import createWarrantForm from '../../modules/warrants/warrant/state/forms/createWarrantForm';
import warrantsDashboardSearchForm from '../../modules/warrants/dashboard/state/forms/warrantsDashboardSearchForm';
import changeWarrantNumberForm from '../../modules/warrants/warrant/state/forms/changeWarrantNumberForm';
import connectWarrantsSidePanelForm from '../../modules/warrants/core/state/forms/connectWarrantsSidePanelForm';
import searchSyncTypeForm from '../../modules/support/search-sync/state/forms/syncTypeForm';
import searchSyncMultiForm from '../../modules/support/search-sync/state/forms/syncMultiForm';
import cadUnitStatusTransitionsForm from '../../modules/support/cad-unit-status-transitions/state/forms/cadUnitStatusTransitionsForm';
import involvedProfilesForm from '../../modules/reports/core/state/forms/involvedProfilesForm';
import createExportReleaseForm from '../../modules/core/pdf-exports/release-tracking/state/forms/createReleaseForm';
import reportsDashboardSearchForm from '../../modules/reports/dashboard/state/forms/reportsDashboardSearchForm';
import reportChangeOwnerForm from '../../modules/reports/core/state/forms/reportChangeOwnerForm';
import reportRejectionForm from '../../modules/reports/core/state/forms/reportRejectionForm';
import idGeneratorForm from '../../modules/support/id-generator/state/forms/idGeneratorForm';
import departmentResetForm from '../../modules/admin/department-reset/state/forms/departmentResetForm';
import exportReleaseAdminForm from '../../modules/admin/export-release-settings/state/forms/exportReleaseSettingForm';
import evidencePrintingTemplateAdminForm from '../../modules/admin/evidence-printing-templates/state/forms/evidencePrintingTemplateAdminForm';
import shapefilesUploadForm from '../../modules/admin/subdivisions/state/forms/shapefilesUploadForm';
import subdivisionsAdminForm from '../../modules/admin/subdivisions/state/forms/subdivisionsAdminForm';
import shortcutKeyForm from '../../modules/admin/shortcut-keys/state/forms/shortcutKeyForm';
import callForServiceForm from '../../modules/admin/call-for-services/state/forms/callForServiceForm';
import addNewRoleForm from '../../modules/admin/roles/state/forms/addNewRoleForm';
import assignUserRoleForm from '../../modules/admin/roles/state/forms/assignUserRoleForm';
import usersDashboardSearchForm from '../../modules/admin/users/state/forms/usersDashboardSearchForm';
import createWarrantStubPanelForm from '../../modules/warrants/core/state/forms/createWarrantStubPanelForm';
import evidenceDashboardExportForm from '../../modules/evidence/dashboard/state/forms/evidenceDashboardExportForm';
import entityProfileReportsFilterForm from '../../modules/entity-profiles/core/state/forms/entityProfileReportsFilterForm';
import offenseCodeChangeReasonForm from '../../modules/reports/core/state/forms/offenseCodeChangeReasonForm';
import eFileProfilesDefendantsSearchForm from '../../modules/e-files/core/state/forms/eFileProfilesDefendantsSearchForm';
import eFileProfilesVictimWitnessSearchForm
    from '../../modules/e-files/core/state/forms/eFileProfilesVictimWitnessSearchForm';

/**
 * All form modules in one object, keyed by form enum value.
 * @type {Object}
 */
const formModules = {
    // advanced search forms
    [formClientEnum.ADVANCED_SEARCH_CAD_TICKETS]: advancedSearchCadTicketsForm,
    [formClientEnum.ADVANCED_SEARCH_PERSONS]: advancedSearchPersonsForm,
    [formClientEnum.ADVANCED_SEARCH_REPORTS]: advancedSearchReportsForm,
    [formClientEnum.ADVANCED_SEARCH_ORGANIZATIONS]: advancedSearchOrganizationsForm,
    [formClientEnum.ADVANCED_SEARCH_VEHICLES]: advancedSearchVehiclesForm,
    [formClientEnum.ADVANCED_SEARCH_PROPERTY]: advancedSearchPropertyForm,
    [formClientEnum.ADVANCED_SEARCH_ATTACHMENTS]: advancedSearchAttachmentsForm,
    [formClientEnum.ADVANCED_SEARCH_EXPORT]: advancedSearchExportForm,
    [formClientEnum.ADVANCED_SEARCH_COURT_ORDERS]: advancedSearchCourtOrdersForm,

    // reports forms
    [RefContextEnum.FORM_INVOLVED_PROFILES.name]: involvedProfilesForm,
    [formClientEnum.ITEM_POPOVER]: itemPopoverForm,
    [formClientEnum.RESPONSIBLE_OFFICER]: responsibleOfficerForm,
    [RefContextEnum.FORM_TOW_VEHICLE_IMPOUND.name]: towVehicleImpoundForm,
    [RefContextEnum.IMPOUND.name]: impoundForm,
    [formClientEnum.ARREST_UCR]: arrestUcrClassificationForm,
    [FormEnum.UCR.name]: ucrClassificationForm,
    [formClientEnum.REPORT_CHANGE_OWNER]: reportChangeOwnerForm,
    [formClientEnum.REPORT_REJECTION]: reportRejectionForm,
    [formClientEnum.REPORTS_DASHBOARD_SEARCH]: reportsDashboardSearchForm,
    [formClientEnum.OFFENSE_CODE_CHANGE_REASON]: offenseCodeChangeReasonForm,

    // record privacy forms
    [RefContextEnum.FORM_REPORT_SEALING.name]: reportSealingForm,
    [formClientEnum.REPORT_UNSEALING]: reportUnsealingForm,
    [RefContextEnum.FORM_COURT_ORDER_EDIT.name]: courtOrderEditForm,
    [RefContextEnum.FORM_CHARGE_VACATING.name]: chargeVacatingForm,

    // cases forms
    [formClientEnum.ALL_CASES_SEARCH]: allCasesSearchForm,
    [formClientEnum.MY_CASES_SEARCH]: myCasesSearchForm,
    [formClientEnum.UNASSIGNED_REPORTS_SEARCH]: unassignedReportsSearchForm,
    [formClientEnum.CASE_DETAILS]: caseDetailsForm,
    [FormEnum.CASE_REVIEW.name]: caseReviewForm,
    [formClientEnum.TASK_DETAILS]: taskDetailsForm,
    [RefContextEnum.FORM_CASE_CHANGE_REN.name]: editCaseRenForm,
    [RefContextEnum.FORM_CASE_CREATE_CASE.name]: createCaseForm,

    // evidence forms
    [formClientEnum.LABEL_PRINTING]: labelPrintingForm,
    [formClientEnum.EVIDENCE_DOCUMENT_EXPORTING]: documentExportingForm,
    [formClientEnum.STAFF_REMARK]: staffRemarkForm,
    [formClientEnum.EVIDENCE_DASHBOARD_SEARCH]: evidenceDashboardSearchForm,
    [formClientEnum.EVIDENCE_INVENTORIES_DASHBOARD_SEARCH]: inventoriesDashboardSearchFrom,
    [FormEnum.EVIDENCE_CREATE_CHAIN_EVENTS.name]: createChainEventsForm,
    [formClientEnum.FACILITY_ADMIN]: facilityAdminForm,
    [formClientEnum.STORAGE_ADMIN]: storageLocationAdminForm,
    [FormEnum.EVIDENCE_RETENTION_POLICY_ADMIN.name]: retentionPolicyAdminForm,
    [FormEnum.EVIDENCE_CONFIG_ADMIN.name]: evidenceConfigAdminForm,
    [FormEnum.EVIDENCE_ITEM_SPLIT.name]: evidenceItemSplitForm,
    [FormEnum.EVIDENCE_PRINTING_TEMPLATE_ADMIN.name]: evidencePrintingTemplateAdminForm,
    [formClientEnum.EVIDENCE_DASHBOARD_EXPORT]: evidenceDashboardExportForm,

    // admin forms
    [FormEnum.LOCATION_ALIAS_ADMIN.name]: locationAliasesAdminForm,
    [formClientEnum.DEPARTMENT_SETUP]: departmentSetupForm,
    [formClientEnum.AGENCY_PROFILE_ADMIN]: agencyProfileAdminForm,
    [formClientEnum.SOCIETY_PROFILE_ADMIN]: societyProfileAdminForm,
    [formClientEnum.FIELD_CONFIGURATION_ADMIN]: fieldConfigurationsAdminForm,
    [formClientEnum.COMMAND_LINE_ARGUMENT]: commandLineArgumentForm,
    [formClientEnum.DISPATCH_AREAS_ADMIN]: dispatchAreasForm,
    [formClientEnum.DUPLICATE_EVENT_SEARCH_ADMIN]: duplicateEventSearchForm,
    [formClientEnum.RULES_ADMIN]: rulesAdminForm,
    [formClientEnum.CASE_DEFINITIONS_ADMIN]: caseDefinitionsAdminForm,
    [formClientEnum.WARRANT_PERMISSIONS]: warrantPermissionsAdminForm,
    [formClientEnum.MASTER_ENTITIES]: masterEntityAdminForm,
    [formClientEnum.CAD_UNITS_ADMIN]: cadUnitsAdminForm,
    [formClientEnum.CAD_DESKTOP_CONFIGS_ADMIN]: cadDesktopConfigsAdminForm,
    [formClientEnum.CAD_CALL_TAKER_STATIONS_ADMIN]: cadCallTakerStationsAdminForm,
    [formClientEnum.CAD_BOLO_AUDIT_ADMIN]: cadBoloAuditAdminForm,
    [formClientEnum.CAD_MESSAGING_AUDIT_ADMIN]: cadMessagingAuditAdminForm,
    [formClientEnum.CAD_SERVICE_TYPES_ADMIN]: cadServiceTypesAdminForm,
    [formClientEnum.CAD_SERVICE_ROTATION_LISTS_ADMIN]: cadRotationListsAdminForm,
    [formClientEnum.CAD_SERVICE_ROTATION_LISTS_ENTRY_ADMIN]: cadRotationListEntryForm,
    [formClientEnum.CAD_MESSAGING_GROUPS]: cadMessagingGroupsAdminForm,
    [formClientEnum.CAD_MESSAGING_GROUPS_ADMIN]: cadMessagingGroupsAdminForm,
    [formClientEnum.CAD_SERVICE_ROTATION_AUDIT_ADMIN]: cadServiceRotationAuditAdminForm,
    [formClientEnum.CAD_SERVICE_PROVIDERS_ADMIN]: cadServiceProvidersAdminForm,
    [formClientEnum.DEPARTMENT_RESET]: departmentResetForm,
    [formClientEnum.LOCATION_CAUTIONS_ADMIN]: locationCautionsAdminForm,
    [formClientEnum.LOCATION_CAUTIONS_SUBPREMISE_ADMIN]: locationCautionsSubPremiseForm,
    [formClientEnum.PRINTING_TEMPLATES_ADMIN]: printingTemplatesAdminForm,
    [formClientEnum.REPORT_PRINTING_TEMPLATES_ADMIN]: reportPrintingTemplatesAdminForm,
    [formClientEnum.CASE_PRINTING_TEMPLATES_ADMIN]: casePrintingTemplatesAdminForm,
    [formClientEnum.EDIT_ROLE_FILTER_USERS]: editRoleFilterUsersForm,
    [formClientEnum.EDIT_ROLE_FILTER_ABILITIES]: editRoleFilterAbilitiesForm,
    [formClientEnum.EDIT_ROLE_USERS_MANAGE_STATUS]: editRoleUsersManageStatusForm,
    [formClientEnum.EDIT_ROLE_ABILITIES_MODULES]: editRoleAbilitiesModulesForm,
    [formClientEnum.EDIT_ROLE_ABILITIES_ENABLED]: editRoleAbilitiesEnabledForm,
    [FormEnum.ADMIN_ROLE_APPROVAL.name]: editRoleApprovalsForm,
    [formClientEnum.EDIT_ROLE_NAME]: editRoleNameForm,
    [formClientEnum.STREET_ALIASES_ADMIN]: streetAliasesForm,
    [formClientEnum.STREET_SEGMENTS_ADMIN]: streetSegmentsForm,
    [formClientEnum.ATTRIBUTE_TYPE_CODE_TYPE_LINKS]: adminAttributeTypeCodeTypeLinksForm,
    [formClientEnum.USAGE_LOGS]: usageLogsSearchForm,
    [formClientEnum.SUBDIVISIONS_SHAPEFILE_UPLOAD]: shapefilesUploadForm,
    [formClientEnum.SUBDIVISIONS_ADMIN]: subdivisionsAdminForm,
    [formClientEnum.SHORTCUT_KEYS]: shortcutKeyForm,
    [formClientEnum.CALL_FOR_SERVICE_ADMIN]: callForServiceForm,
    [formClientEnum.NEW_ROLE_ADMIN]: addNewRoleForm,
    [formClientEnum.ASSIGN_USER_ROLE]: assignUserRoleForm,
    [formClientEnum.USERS_DASHBOARD_ADMIN]: usersDashboardSearchForm,

    // support forms
    [formClientEnum.SUPPORT_SEARCH_SYNC_SYNC_ENTITY]: syncEntityForm,
    [formClientEnum.SUPPORT_SEARCH_SYNC_TYPE]: searchSyncTypeForm,
    [formClientEnum.SUPPORT_SEARCH_SYNC_MULTI]: searchSyncMultiForm,
    [formClientEnum.CAD_UNIT_STATUS_TRANSITIONS]: cadUnitStatusTransitionsForm,
    [formClientEnum.SUPPORT_ID_GENERATOR]: idGeneratorForm,

    // analysis forms
    [formClientEnum.LOOKER_DASHBOARD]: lookerDashboardForm,

    // notifications forms
    [formClientEnum.NOTIFICATION_SETTINGS_FORM]: notificationSettingsForm,

    // warrants
    [formClientEnum.WARRANT_DETAILS]: warrantDetailsForm,
    [formClientEnum.WARRANTS_DASHBOARD_SEARCH]: warrantsDashboardSearchForm,
    [FormEnum.CREATE_WARRANT.name]: createWarrantForm,
    [FormEnum.CHANGE_WARRANT_NUMBER.name]: changeWarrantNumberForm,
    [formClientEnum.CONNECT_WARRANTS_SIDE_PANEL]: connectWarrantsSidePanelForm,
    [RefContextEnum.FORM_CREATE_WARRANT_STUB_SIDE_PANEL.name]: createWarrantStubPanelForm,

    // exports
    [FormEnum.CREATE_EXPORT_RELEASE.name]: createExportReleaseForm,
    [formClientEnum.EXPORT_RELEASE_SETTINGS_ADMIN]: exportReleaseAdminForm,

    // entity profile
    [formClientEnum.ENTITY_PROFILE_REPORTS_FILTER]: entityProfileReportsFilterForm,

    // e-files forms
    [formClientEnum.E_FILES_SEARCH_PROFILES_DEFENDANTS_FORM]: eFileProfilesDefendantsSearchForm,
    [formClientEnum.E_FILES_SEARCH_PROFILES_VICTIM_WITNESS_FORM]: eFileProfilesVictimWitnessSearchForm,
};

export const formModelsReducer = combineReducers(
    _(formModules).mapKeys('path').mapValues('reducers.formModelReducer').value()
);

export const formUiReducer = combineReducers(
    _(formModules).mapKeys('path').mapValues('reducers.formUiReducer').value()
);
