import React, { useCallback } from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import Modal from '../../../core/overlays/components/Modal';
import { getErrorMessagesFromErrors } from '../../../reports/core/helpers/validationHelpers';
import { useEFileContext, useEFileDisplayName, useEFileTitle } from '../../hooks';
import { useEFileSubmitForm } from '../../hooks/useEFileSubmitForm';
import EFileDetailsForm from './EFileDetailsForm';

const strings = componentStrings.eFiles.core.eFileDetailsModal;

export const EFileUpdateModal = () => {
    const { eFileDisplayName } = useEFileDisplayName();

    const {
        actions,
        getters: { efile },
    } = useEFileContext();

    const eFileToUpdate = efile.getEFile();
    const eFileTitle = useEFileTitle(false);
    const submitEFileForm = useEFileSubmitForm();

    const onSave = useCallback(async () => {
        try {
            const eFileView = await submitEFileForm(eFileToUpdate);
            actions.efile.setEfile(eFileView);
            return eFileView;
        } catch (e) {
            throw getErrorMessagesFromErrors(e);
        }
    }, [actions.efile, eFileToUpdate, submitEFileForm]);

    return (
        <Modal
            id={overlayIdEnum.UPSERT_E_FILE_MODAL}
            title={strings.manageEFile(eFileDisplayName, eFileTitle)}
            okText={strings.saveText}
            autoClose={false}
            onSave={onSave}
        >
            <EFileDetailsForm eFile={eFileToUpdate} />
        </Modal>
    );
};
