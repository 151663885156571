import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Radio, RadioGroup } from 'arc';
import styled from 'styled-components';
import { PersonProfile } from '@mark43/rms-api';

import strings from '~/client-common/core/strings/componentStrings';
import { NarrativeContainer } from '../../core/components/RecordPrivacyContainers';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import PreviouslySealedNote from '../../core/components/PreviouslySealedNote';
import {
    REPORT_SEALING_PARTIAL_NARRATIVE_BEGIN,
    REPORT_SEALING_PARTIAL_NARRATIVE_CANCEL,
} from '../state/ui';
import PartialSealingNarrativeEditor from './PartialSealingNarrativeEditor';

const Container = styled.div`
    margin-top: var(--arc-space-4);
    margin-bottom: var(--arc-space-8);
`;

const narrativeStrings = strings.recordPrivacy.sealing.NarrativeSection;

interface Report {
    isNarrativeSealed: boolean;
    narrative: string;
    [key: string]: string | number | boolean | object;
}

interface PartialSealingNarrativeSectionProps {
    report: Report;
    formModelByPath: (key: string) => string | number | boolean;
    onNarrativeSealClick: (sealingNarrative: boolean) => void;
    involvedPersons?: PersonProfile[];
}

const PartialSealingNarrativeSection = (props: PartialSealingNarrativeSectionProps) => {
    const dispatch = useDispatch();
    const { report, onNarrativeSealClick } = props;
    const [selection, setSelection] = useState<string>('');

    const handleSelectRadio = (value: string) => {
        setSelection(value);
        if (value === 'full') {
            onNarrativeSealClick(true);
            dispatch({ type: REPORT_SEALING_PARTIAL_NARRATIVE_CANCEL });
        } else {
            onNarrativeSealClick(false);
            dispatch({ type: REPORT_SEALING_PARTIAL_NARRATIVE_BEGIN });
        }
    };

    return (
        <Container>
            <RadioGroup onChange={handleSelectRadio}>
                <Radio value="full">{narrativeStrings.fullRadio}</Radio>
                <Radio value="partial">{narrativeStrings.partialRadio}</Radio>
            </RadioGroup>
            <br />
            {selection === 'full' && !report.isNarrativeSealed && (
                <>
                    <RRFUserSelect path="userToContactId" />
                    <NarrativeContainer
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: report.narrative }}
                    />
                </>
            )}
            {selection === 'partial' && !report.isNarrativeSealed && (
                <PartialSealingNarrativeEditor
                    narrative={report.narrative}
                    involvedPersons={props.involvedPersons || []}
                />
            )}
            {report.isNarrativeSealed && <PreviouslySealedNote />}
        </Container>
    );
};

export default PartialSealingNarrativeSection;
