import { map, partition, orderBy, keys, forEach, concat, filter, find, some } from 'lodash';
import {
    EntityTypeEnum,
    EntityTypeEnumType,
    EntityPermission,
    EntityPermissionView,
    OperationTypeEnum,
    OperationTypeEnumType,
    RoleTypeEnum,
} from '@mark43/rms-api';
import { MFTFormConfiguration, _Form } from 'markformythree';
import { createSelector } from 'reselect';

import { NEXUS_STATE_PROP as ROLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/roles/state/data';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import {
    NEXUS_STATE_PROP as ENTITY_PERMISSIONS_NEXUS_STATE_PROP,
    entityPermissionsSelector,
} from '~/client-common/core/domain/entity-permissions/state/data';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    openConfirmationModal,
    closeConfirmationModal,
} from '../../../../../legacy-redux/actions/boxActions';
import { RmsAction, RmsDispatch } from '../../../../../core/typings/redux';
import { currentUserDepartmentIdSelector } from '../../../current-user/state/ui';
import entityPermissionsResource from '../../resources/entityPermissionsResource';
import { ModalCancelError } from '../../../overlays/components/ModalCancelError';

type OverlayIdEnumType = keyof typeof overlayIdEnum;

const STORE_ROLES_AND_PERMISSIONS = 'STORE_ROLES_AND_PERMISSIONS';
const strings = componentStrings.core.permissions.departmentPermissionsModal;

export function getFormNameForEntityType(entityType: EntityTypeEnumType) {
    switch (entityType) {
        case EntityTypeEnum.CASE.name:
            return formClientEnum.CASE_PERMISSIONS_FORM;
        case EntityTypeEnum.REPORT.name:
            return formClientEnum.REPORT_PERMISSIONS_FORM;
        case EntityTypeEnum.WARRANT.name:
            return formClientEnum.WARRANT_PERMISSIONS_FORM;
        case EntityTypeEnum.SAVED_SEARCH.name:
            return formClientEnum.SAVED_SEARCH_PERMISSIONS_FORM;
        case EntityTypeEnum.EVIDENCE_STORAGE_LOCATION.name:
            return formClientEnum.EVIDENCE_LOCATION_PERMISSIONS_FORM;
        case EntityTypeEnum.E_FILE.name:
            return formClientEnum.E_FILE_PERMISSIONS_FORM;
        default:
            return formClientEnum.ENTITY_PROFILE_PERMISSIONS_FORM;
    }
}

export type RecordPermissionsFormName = ReturnType<typeof getFormNameForEntityType>;

export function updatePermissions(
    entityType: EntityTypeEnumType,
    entityId: number,
    permissions: EntityPermissionView[]
): RmsAction<void> {
    return (dispatch, getState, { nexus }) =>
        dispatch(
            nexus.withEntityItems(
                {
                    [ENTITY_PERMISSIONS_NEXUS_STATE_PROP]: permissions,
                    [ROLES_NEXUS_STATE_PROP]: map(permissions, 'role'),
                },
                nexus.withRemove(
                    ENTITY_PERMISSIONS_NEXUS_STATE_PROP,
                    { entityType, entityId },
                    { type: STORE_ROLES_AND_PERMISSIONS }
                )
            )
        );
}

export function loadPermissions(
    entityType: EntityTypeEnumType,
    entityId: number
): RmsAction<Promise<EntityPermissionView[]>> {
    return function (dispatch) {
        return entityPermissionsResource
            .getPermissions(entityType, entityId, false)
            .then((permissions: EntityPermissionView[]) => {
                dispatch(updatePermissions(entityType, entityId, permissions));
                return permissions;
            });
    };
}

export function loadPermissionsForModal(
    overlayId: OverlayIdEnumType,
    entityType: EntityTypeEnumType,
    entityId: number
): RmsAction<Promise<EntityPermissionView[]>> {
    return function (dispatch, getState, { overlayStore }) {
        overlayStore.setLoading(overlayId, true);

        return dispatch(loadPermissions(entityType, entityId)).then((permissions) => {
            const state = getState();
            const hasExternal = consortiumDepartmentLinksAvailableSelector(state);
            dispatch(
                fillEntityPermissionsForm(
                    permissions,
                    currentUserDepartmentIdSelector(state),
                    hasExternal,
                    entityType
                )
            );
            overlayStore.setLoading(overlayId, false);
            return permissions;
        });
    };
}

export function rankOperationType(operationType: OperationTypeEnumType) {
    switch (operationType) {
        case OperationTypeEnum.MANAGE.name:
            return 5;
        case OperationTypeEnum.DELETE.name:
            return 4;
        case OperationTypeEnum.WRITE.name:
            return 3;
        case OperationTypeEnum.READ.name:
            return 2;
        case OperationTypeEnum.SEARCH.name:
            return 1;
        default:
            return 0;
    }
}

function fillEntityPermissionsForm(
    permissions: EntityPermissionView[],
    currentUserDepartmentId: number | undefined,
    hasExternal: boolean,
    entityType: EntityTypeEnumType
): RmsAction<void> {
    return (dispatch, getState, { formsRegistry }) => {
        formsRegistry.maybeDeferredOperation(getFormNameForEntityType(entityType), 0, (form) => {
            const sortedPermissions = orderBy(
                permissions,

                ({ operationType }) => rankOperationType(operationType),
                ['desc']
            );
            const [userSettings, roleSettings] = partition(
                sortedPermissions,
                ({ role }) => role.roleType === RoleTypeEnum.USER.name
            );
            const [internalRoleSettings, externalRoleSettings] = partition(
                roleSettings,
                ({ role }) => role.departmentId === currentUserDepartmentId
            );

            form.transaction(() => {
                form.resetModel();
                form.resetUi();

                form.set('roleSettings', internalRoleSettings);
                form.set('userSettings', userSettings);

                if (hasExternal) {
                    form.set('externalAgencySettings', externalRoleSettings);
                }
            });
        });
    };
}

export function wereAllPermissionsDeleted(
    entityType: EntityTypeEnumType,
    entityId: number
): RmsAction<boolean> {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(getFormNameForEntityType(entityType));

        if (!form) {
            return false;
        }

        const existingPerms = dispatch(getCurrentPermissions(entityType, entityId));

        const incomingPerms = getIncomingPerms(form);

        return existingPerms.length !== 0 && incomingPerms.length === 0;
    };
}

export function werePermissionsEdited(
    entityType: EntityTypeEnumType,
    entityId: number
): RmsAction<boolean> {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(getFormNameForEntityType(entityType));

        if (!form) {
            return false;
        }

        const existingPerms = dispatch(getCurrentPermissions(entityType, entityId));

        const incomingPerms = getIncomingPerms(form);

        if (existingPerms.length !== incomingPerms.length) {
            return true;
        } else {
            // lengths are equal so check if existing perms matches incoming perms
            return !!find(existingPerms, (perm) => !doesPermExist(perm, incomingPerms));
        }
    };
}

function getCurrentPermissions(
    entityType: EntityTypeEnumType,
    entityId: number
): RmsAction<EntityPermissionView[]> {
    return (dispatch, getState) => {
        return entityPermissionsByIdSelector(getState())(entityType, entityId);
    };
}

export const entityPermissionsByIdSelector = createSelector(
    entityPermissionsSelector,
    (entityPermissions) => (entityType: EntityTypeEnumType, entityId: number) =>
        entityPermissions.filter((perm) => {
            return perm.entityId === entityId && perm.entityType === entityType;
        })
);

export function saveEntityPermissionsForm(
    entityType: EntityTypeEnumType,
    entityId: number
): RmsAction<Promise<void>> {
    return (dispatch: RmsDispatch, getState, { formsRegistry }) => {
        const state = getState();
        let resolve: () => void;
        let reject: (err: Error) => void;
        const modalPromise = new Promise<undefined>((res, rej) => {
            resolve = res;
            reject = rej;
        });
        const form = formsRegistry.get(getFormNameForEntityType(entityType));

        if (!form) {
            return Promise.reject(
                new Error(`Form not found: ${getFormNameForEntityType(entityType)}`)
            );
        }

        removeEmptyFormRows(form, 'roleSettings');
        removeEmptyFormRows(form, 'userSettings');
        removeEmptyFormRows(form, 'externalAgencySettings');

        const existingPerms = dispatch(getCurrentPermissions(entityType, entityId));
        const incomingPerms = getIncomingPerms(form);
        const currentDepartmentId = currentUserDepartmentIdSelector(state);

        const saveCallback = () => {
            dispatch(closeConfirmationModal());
            return form.submit().then((result) => {
                const model = result.form.getState().model;
                const permissions = map(
                    [
                        ...(model?.roleSettings || []),
                        ...(model?.userSettings || []),
                        ...(model?.externalAgencySettings || []),
                    ],
                    (permission) => ({
                        ...permission,
                        entityType,
                        entityId,
                    })
                );

                return entityPermissionsResource
                    .updatePermissions(entityType, entityId, permissions)
                    .then((savedPermissions: EntityPermissionView[]) => {
                        dispatch(updatePermissions(entityType, entityId, savedPermissions));
                        resolve();
                    })
                    .catch((error: ModalCancelError) => {
                        reject(error);
                    });
            });
        };

        const cancelCallback = () => {
            const modalCancelError: ModalCancelError = new ModalCancelError();
            dispatch(closeConfirmationModal());
            return reject(modalCancelError);
        };

        return form.submit().then((result) => {
            const model = result.form.getState().model;
            const permissions = map(
                [
                    ...(model?.roleSettings || []),
                    ...(model?.userSettings || []),
                    ...(model?.externalAgencySettings || []),
                ],
                (permission) => ({
                    ...permission,
                    entityType,
                    entityId,
                })
            );

            if (
                internalDepartmentPermissionsDeleted(
                    existingPerms,
                    incomingPerms,
                    currentDepartmentId
                )
            ) {
                dispatch(
                    openConfirmationModal({
                        title: strings.title,
                        message: strings.message(entityType),
                        saveCallback,
                        cancelCallback,
                        okText: 'Confirm',
                    })
                );
            } else {
                return entityPermissionsResource
                    .updatePermissions(entityType, entityId, permissions)
                    .then((savedPermissions: EntityPermissionView[]) => {
                        dispatch(updatePermissions(entityType, entityId, savedPermissions));
                        return resolve();
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            }
            return modalPromise;
        });
    };
}

function internalDepartmentPermissionsDeleted(
    existingPerms: EntityPermissionView[],
    incomingPerms: EntityPermission[],
    currentDepartmentId: number | undefined
): boolean {
    const existingDeptPerms = filter(existingPerms, [
        'role.roleType',
        RoleTypeEnum.DEPARTMENT.name,
    ]);

    return some(existingDeptPerms, (deptPerm: EntityPermissionView) => {
        // check if the existing perm is an external
        const isExternalAgency: boolean = deptPerm?.role?.departmentId !== currentDepartmentId;

        // check if it exist in the incoming ie. not deleted
        const permExistInIncoming: EntityPermission | undefined = find(incomingPerms, [
            'roleId',
            deptPerm?.roleId,
        ]);

        // if a dept perm is deleted and isnt an external agency then an internal dept permission is deleted
        return !isExternalAgency && !permExistInIncoming;
    });
}

function doesPermExist(perm: EntityPermission, permsSet: EntityPermission[]): boolean {
    return some(permsSet, (permInSet) => {
        return (
            permInSet.roleId === perm.roleId &&
            permInSet.entityId === perm.entityId &&
            permInSet.entityType === perm.entityType &&
            permInSet.operationType === perm.operationType &&
            permInSet.departmentId === perm.departmentId
        );
    });
}

function getIncomingPerms<TFormConfiguration extends MFTFormConfiguration>(
    form: _Form<TFormConfiguration>
): EntityPermission[] {
    removeEmptyFormRows(form, 'roleSettings');
    removeEmptyFormRows(form, 'userSettings');
    removeEmptyFormRows(form, 'externalAgencySettings');

    const roleSettings = form.get('roleSettings');
    const userSettings = form.get('userSettings');
    const externalAgencySettings = form.get('externalAgencySettings');

    const incomingPerms = concat(
        roleSettings,
        userSettings,
        externalAgencySettings
    ) as EntityPermission[];

    return incomingPerms;
}

function removeEmptyFormRows<TFormConfiguration extends MFTFormConfiguration>(
    form: _Form<TFormConfiguration>,
    path: keyof TFormConfiguration
) {
    // @ts-expect-error the return value of getConfigurationForPath is not typed in markformythree
    const config = form.getConfigurationForPath(path);
    // @ts-expect-error remove this after typing getConfigurationForPath
    const fieldKeys = keys(config.fields);
    // @ts-expect-error path typed as generic string
    const rows = form.get(path);

    if (!Array.isArray(rows) || rows.length === 0) {
        return;
    }

    let deletedCount = 0;
    forEach(rows, (row, index) => {
        let isEmpty = true;
        forEach(fieldKeys, (fieldKey) => {
            if (!!row[fieldKey]) {
                isEmpty = false;
                return false;
            }

            return;
        });
        // Keep track of the number of rows deleted so far to calculate the new index
        // in the form of the empty item we're trying to delete
        if (isEmpty) {
            // @ts-expect-error path typed as generic string
            form.remove(path, index - deletedCount);
            deletedCount++;
        }
    });
}
