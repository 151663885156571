import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { mediaQueries } from 'arc';
import {
    some,
    filter,
    isFunction,
    partition,
    isEmpty,
    groupBy,
    map,
    mapValues,
    uniqBy,
} from 'lodash';

import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { loadAttachmentsByEntityIdsAndTypes } from '~/client-common/core/domain/attachments/state/data';
import { SecondarySectionHeader } from '../../../modules/core/components/typography';

import testIds from '../../../core/testIds';
import Button, { buttonTypes } from '../core/Button';
import { OnlyWithAbility, abilitiesEnum } from '../../../modules/core/abilities';
import { ExportRedactionSidePanel } from '../../../modules/exports/core/components/ExportRedactionSidePanel';
import { ConditionalTooltip, Tooltip } from '../../../modules/core/components/tooltip';
import { ActionBarBase } from '../../../modules/core/components/ActionBar';
import { useOverlayStore } from '../../../modules/core/overlays/hooks/useOverlayStore';

import { approvalStatusSelector } from '../../selectors/reportSelectors';
import { useAbilitySelector } from '../../../modules/core/current-user/hooks/useAbilitySelector';
import { exportAttachmentsSelector } from '../../../modules/exports/core/state/data';
import { groupAttachmentsByEntityIdSelector } from '../../../modules/exports/core/state/ui';
import SendEmailModal from '../../../modules/core/emails/components/SendEmailModal';
import { getEmailButtonState } from '../../../modules/core/emails/helpers';

import { FILLABLE_PDF_TEMPLATE_TYPES } from '../../configs/exportConfig';
import AttachmentOption, { computeSelectedAttachmentsSize } from './AttachmentOption';
import { FormOptionWrapper, ExportOption, LinkButton } from './FormOptionComponents';

const strings = componentStrings.exports.FormExportsOptions;
const optionStrings = componentStrings.exports.options;
const emailStrings = componentStrings.exports.emails;

const ActionBarContent = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 4px 20px;

    padding-left: ${(props) => (props.isMainNavEnabled === true ? '7rem' : '')};

    @media (min-width: ${mediaQueries.xl}) {
        padding-left: ${(props) => (props.isMainNavEnabled === true ? '15rem' : '')};
    }
`;

const EmailButton = styled(Button)`
    margin-right: 0;
`;

export const shouldShowAddToReportButton = (
    entityType,
    onAddExportToEntity,
    maEaccEnabled,
    hasMasterAttachmentEditAbility,
    approvalStatus
) => {
    return (
        entityType === EntityTypeEnum.REPORT.name &&
        isFunction(onAddExportToEntity) &&
        (maEaccEnabled ||
            hasMasterAttachmentEditAbility ||
            (!maEaccEnabled && approvalStatus === approvalStatusClientEnum.DRAFT))
    );
};

export default function GlobalExportsOptions({
    type,
    includeFiles,
    includeHistoryEvents,
    onlyIncludeFieldsWithData,
    includeWarrantActivities,
    selectedAttachmentsToInclude,
    includeConfidentialInformation,
    includeNameAddendums,
    combinedPDF,
    includeAttachmentForBulkExport,
    includeZipFilesForBulkExport,
    redactedFields,
    redactFields,
    handleSubmit,
    packetOptions,
    showIncludeWarrantActivities,
    includeAttachments,
    showFieldRedaction,
    showOnlyIncludeFieldsWithData,
    showIncludeNameAddendums,
    showCombinedPDF,
    showIncludeHistoryEvents,
    showRedactionForBulkExport,
    showAttachmentForBulkExport,
    showZipFilesForBulkExport,
    excludeAttachmentLinkTypes,
    numOfSelectedPackets,
    submitButtonText,
    onCancel,
    onRedact,
    onEmail,
    mergeAttachments,
    clearExportPreset,
    entityType,
    onAddExportToEntity,
    emailsEnabled = false,
    selectedPacketValues = [],
}) {
    const showConfidentialOptions = some(packetOptions, (option) => !!option.privacyDisplay);
    const packetEntities = filter(
        packetOptions,
        // it is important to check for `entityId` here because packets
        // can also be nested options (e.g. "include narrative"), which
        // we must not pass to redaction or attachments side panels
        (option) => option.entityId
    );
    const packetsSelected = !!packetEntities.length;
    const showRedaction = packetOptions
        ? showFieldRedaction && packetsSelected
        : showRedactionForBulkExport;

    const dispatch = useDispatch();
    const overlayStore = useOverlayStore();

    // load all attachments linked to the entities that may potentially be exported
    const loadAttachments = React.useCallback(() => {
        const getEntityType = (entity) => entity.entityType;
        const entityIdsByType = mapValues(
            groupBy(filter(packetOptions, getEntityType), getEntityType),
            (entities) => map(entities, (entity) => entity.entityId)
        );
        return dispatch(
            loadAttachmentsByEntityIdsAndTypes(entityIdsByType, { excludeAttachmentLinkTypes })
        );
    }, [dispatch, packetOptions, excludeAttachmentLinkTypes]);

    const handleEmail = () => {
        overlayStore.open(overlayIdEnum.SEND_EMAIL_MODAL, { onSave: onEmail });
    };

    /**
     * We don't allow redaction for fillable PDF's at this time so
     * if the user has only selected fillable PDF's, disable the redact button
     */
    const [fillablePdfSelectedPackets, nonFillablePdfSelectedPackets] = partition(
        packetEntities,
        (packet) => FILLABLE_PDF_TEMPLATE_TYPES.includes(packet.templateType)
    );
    const isRedactionDisabled = isEmpty(nonFillablePdfSelectedPackets);
    const containsFillablePdf = !isEmpty(fillablePdfSelectedPackets);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isMainNavEnabled = applicationSettings.RMS_ARC_NAVIGATION_ENABLED;
    const maEaccEnabled = applicationSettings.RMS_MA_EACC_ENABLED;
    const hasMasterAttachmentEditAbility = useAbilitySelector(
        abilitiesEnum.REPORTING.MASTER_ATTACHMENT_EDIT
    );
    const approvalStatus = useSelector(approvalStatusSelector);
    const getExportAttachments = useSelector(exportAttachmentsSelector);
    const groupAttachmentsByEntityId = useSelector(groupAttachmentsByEntityIdSelector);

    const attachmentsByEntityId = groupAttachmentsByEntityId({
        attachments: getExportAttachments(excludeAttachmentLinkTypes),
        entities: packetEntities,
    });
    const uniqSelectedPackets = uniqBy(packetEntities, 'entityId');
    const attachmentsSize = computeSelectedAttachmentsSize(
        uniqSelectedPackets,
        selectedAttachmentsToInclude?.value,
        attachmentsByEntityId
    );
    const { isDisabled: isEmailButtonDisabled, tooltip: emailButtonTooltip } = getEmailButtonState(
        numOfSelectedPackets,
        includeAttachments && includeFiles.value,
        attachmentsSize
    );

    const showAddToReportButton = shouldShowAddToReportButton(
        entityType,
        onAddExportToEntity,
        maEaccEnabled,
        hasMasterAttachmentEditAbility,
        approvalStatus
    );

    const redactAndExportButton = (
        <Button
            className={buttonTypes.SECONDARY}
            onClick={onRedact}
            disabled={isRedactionDisabled}
            testId={testIds.REDACT_AND_EXPORT_BUTTON}
        >
            {strings.redactAndExport}
        </Button>
    );

    const redactAndExportButtonWithTooltip = containsFillablePdf ? (
        <Tooltip side="right" content={strings.fillablePdfTooltip}>
            {redactAndExportButton}
        </Tooltip>
    ) : (
        redactAndExportButton
    );

    // Tooltip doesn't work for legacy Buttons without intermediate element. Known issue.
    const emailButton = (
        <ConditionalTooltip
            condition={isEmailButtonDisabled}
            content={emailButtonTooltip}
            hasButtonOffset
            side="top"
        >
            <span>
                <EmailButton
                    className={buttonTypes.SECONDARY}
                    disabled={isEmailButtonDisabled}
                    testId={testIds.EMAIL_BUTTON}
                    onClick={handleEmail}
                >
                    {emailStrings.email}
                </EmailButton>
            </span>
        </ConditionalTooltip>
    );

    const exportButtons = (
        <>
            <Button
                className={buttonTypes.PRIMARY}
                disabled={numOfSelectedPackets < 1}
                onClick={handleSubmit}
                testId={testIds.EXPORTS_DOWNLOAD_BUTTON}
            >
                {submitButtonText || strings.download}
            </Button>
            <FeatureFlagged flag="RMS_ADD_EXPORTS_TO_CURRENT_REPORT_ENABLED">
                {showAddToReportButton && (
                    <Button
                        className={buttonTypes.SECONDARY}
                        disabled={numOfSelectedPackets < 1}
                        testId={testIds.EXPORT_AND_ADD_TO_CURRENT_REPORT}
                        onClick={onAddExportToEntity}
                    >
                        {strings.addToReport}
                    </Button>
                )}
            </FeatureFlagged>

            {isFunction(onCancel) && (
                <Button
                    className={buttonTypes.SECONDARY}
                    onClick={onCancel}
                    testId={testIds.EXPORTS_CANCEL_BUTTON}
                >
                    {strings.cancel}
                </Button>
            )}
            {isFunction(onRedact) && redactAndExportButtonWithTooltip}
            <FeatureFlagged flag="RMS_DIRECT_EMAILS_ENABLED">
                {emailsEnabled && emailButton}
            </FeatureFlagged>
        </>
    );

    return (
        <>
            <SecondarySectionHeader>{strings.exportOptions}</SecondarySectionHeader>
            <div data-test-id={testIds.EXPORTS_EXPORT_OPTION_CHECKBOX}>
                {showOnlyIncludeFieldsWithData && (
                    <ExportOption
                        title={optionStrings.onlyIncludeFieldsWithData}
                        field={onlyIncludeFieldsWithData}
                        clearExportPreset={clearExportPreset}
                    />
                )}
                {showIncludeNameAddendums && (
                    <ExportOption
                        title={optionStrings.includeNameAddendums}
                        field={includeNameAddendums}
                        clearExportPreset={clearExportPreset}
                    />
                )}
                {showCombinedPDF && (
                    <ExportOption
                        title={optionStrings.combinedPDF}
                        field={combinedPDF}
                        clearExportPreset={clearExportPreset}
                    />
                )}
                {showIncludeHistoryEvents && (
                    <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_HISTORY}>
                        <ExportOption
                            title={optionStrings.includeHistoryEvents}
                            field={includeHistoryEvents}
                            clearExportPreset={clearExportPreset}
                        />
                    </OnlyWithAbility>
                )}
                {showConfidentialOptions && (
                    <ExportOption
                        title={optionStrings.includeConfidentialInformation}
                        field={includeConfidentialInformation}
                        clearExportPreset={clearExportPreset}
                    />
                )}
                {showAttachmentForBulkExport ? (
                    <ExportOption
                        title={optionStrings.includeAttachmentForBulkExport}
                        field={includeAttachmentForBulkExport}
                        clearExportPreset={clearExportPreset}
                    />
                ) : (
                    <AttachmentOption
                        includeFiles={includeFiles}
                        excludeAttachmentLinkTypes={excludeAttachmentLinkTypes}
                        selectedAttachmentsToInclude={selectedAttachmentsToInclude}
                        mergeAttachments={mergeAttachments}
                        includeAttachments={includeAttachments}
                        packetsSelected={packetsSelected}
                        packetEntities={packetEntities}
                        clearExportPreset={clearExportPreset}
                        loadAttachments={loadAttachments}
                        selectedPacketValues={selectedPacketValues}
                    />
                )}
                {showRedaction && (
                    <FormOptionWrapper>
                        <ExportOption
                            title={optionStrings.redactFields}
                            field={redactFields}
                            noMargin={true}
                            clearExportPreset={clearExportPreset}
                        />
                        {!!redactFields.value && (
                            <ExportRedactionSidePanel
                                id={overlayIdEnum.EXPORTS_REDACTION_SIDEPANEL}
                                onPanelSave={(selectedRedactedFields) =>
                                    redactedFields.onChange(selectedRedactedFields)
                                }
                                initialRedactedFields={redactedFields.value}
                                renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => (
                                    <LinkButton as="button" onClick={open} ref={setCloseFocusRefs}>
                                        {strings.selectRedactableFields}
                                    </LinkButton>
                                )}
                            />
                        )}
                    </FormOptionWrapper>
                )}

                {showIncludeWarrantActivities && (
                    <ExportOption
                        title={optionStrings.includeWarrantActivities}
                        field={includeWarrantActivities}
                        clearExportPreset={clearExportPreset}
                    />
                )}

                {showZipFilesForBulkExport && (
                    <ExportOption
                        title={optionStrings.includeZipFilesForBulkExport(type)}
                        field={includeZipFilesForBulkExport}
                        clearExportPreset={clearExportPreset}
                    />
                )}
            </div>
            {/* TODO: Eventually figure a way to not have this have to be fixed. */}
            <ActionBarBase position="fixed" style={{ left: '0' }} visible={true}>
                <ActionBarContent isMainNavEnabled={isMainNavEnabled}>
                    {exportButtons}
                </ActionBarContent>
            </ActionBarBase>
            <FeatureFlagged flag="RMS_DIRECT_EMAILS_ENABLED">
                {emailsEnabled && <SendEmailModal />}
            </FeatureFlagged>
        </>
    );
}
