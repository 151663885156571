import { Editor, EditorEvent } from 'tinymce';

export const handleKeyPress = (editor: Editor) => (e: EditorEvent<KeyboardEvent>) => {
    const node = editor.selection.getNode();

    if (node.innerHTML.trim() === '') {
        e.preventDefault();
        const textNode = document.createTextNode(String.fromCharCode(e.charCode));
        node.appendChild(textNode);
        editor.selection.setCursorLocation(node, node.childNodes.length);
    }
};
