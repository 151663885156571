import React, { useRef } from 'react';
import styled from 'styled-components';

import { ButtonGroup } from 'arc';

import { LinkTypesEnum } from '@mark43/rms-api';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import testIds from '../../../../../../core/testIds';
import { SCREENS } from '../../../../../core/locations/state/ui';
import EntitySummary from '../EntitySummary';
import EntitySummaryHeader from '../EntitySummaryHeader';
import { iconTypes } from '../../../../../core/components/Icon';
import { LocationQuickAddContext } from '../../../../../core/context/LocationQuickAddContext';
import { SummaryExpandCollapseButton } from '../buttons/SummaryExpandCollapseButton';
import { SummaryRemoveButton } from '../buttons/SummaryRemoveButton';
import { SummaryEditButton } from '../buttons/SummaryEditButton';
import { LocationSidePanel } from '../../../../../core/locations/components/LocationSidePanel';
import { LocationEntityLinkSummary } from './LocationEntityLinkSummary';
import { LocationTitleSummary } from './LocationTitleSummary';

const withQuickAddCallback = (Component) =>
    React.forwardRef(({ onClick, ...props }, ref) => {
        const { setIsLocationStale } = LocationQuickAddContext.useContainer();
        const quickAddCallback = () => setIsLocationStale(true);
        // Every time we successfully remove a location, we need to mark
        // the results as stale (so that they get refetched)
        const handleClick = (e, locationEntityLink) =>
            onClick(e, locationEntityLink, quickAddCallback);
        return <Component onClick={handleClick} ref={ref} {...props} />;
    });

const SummaryRemoveButtonWithQuickAddCallback = withQuickAddCallback(SummaryRemoveButton);

const AdditionalContentWrapper = styled.div`
    margin-top: 12px;
`;
/**
 * @param {*} saveRef
 * @param {Function} onRemove
 * @param {Boolean} showExpandCollapseButton
 * @param {String} customTitle
 * @param {Boolean} showLongitudeAndLatitudeFields
 * @param {Function} onLocationAdd
 */
export const LocationSummaryView = ({
    locationBundle,
    index,
    summaryMode,
    locationEntityLinkSummaryVariant,
    hideQuickAdd,
    hideCountry,
    additionalLocationContent,
    saveRef,
    onRemove,
    showExpandCollapseButton = true,
    customTitle,
    showLongitudeAndLatitudeFields = false,
    onLocationAdd,
}) => {
    const locationSidePanelSaveRef = useRef(null);

    return (
        <LocationQuickAddContext.Provider>
            <EntitySummary
                collapsible={true}
                renderActionButtons={({ setCollapsed, collapsed }) => {
                    const showRemoveButton = !summaryMode && onRemove;
                    return (
                        <ButtonGroup>
                            {showExpandCollapseButton && (
                                <SummaryExpandCollapseButton
                                    collapsed={collapsed}
                                    setCollapsed={setCollapsed}
                                />
                            )}
                            {!summaryMode && (
                                <LocationSidePanel
                                    overlayId={overlayIdEnum.LOCATION_OVERLAY_EDIT_OFFENSE_LOCATION}
                                    renderButton={({ overlayBase: { open }, screenManagerApi }) =>(
                                        <SummaryEditButton
                                            onClick={() => {
                                                open?.();
                                                const fullLocation = {...locationBundle?.location, ...locationBundle?.locationEntityLink};
                                                screenManagerApi.goToNextScreen(SCREENS.PROFILE_EDIT, {
                                                    currentLocation: fullLocation,
                                                    initialFormState: {...fullLocation, entityLink: locationBundle?.locationEntityLink, entityLinks: [locationBundle?.locationEntityLink]},
                                                });
                                            }}
                                        />
                                    )}
                                    saveRef={locationSidePanelSaveRef}
                                    skipStoreLocallyOnLocationSelection={true}
                                    onSaveSuccess={(location, modelEntityLink) => {
                                        onLocationAdd?.(location, modelEntityLink);
                                    }}
                                    linkType={LinkTypesEnum.OFFENSE_LOCATION}
                                />
                            )}
                            {showRemoveButton && (
                                <SummaryRemoveButtonWithQuickAddCallback
                                    onClick={(e) => onRemove(e, locationBundle.locationEntityLink)}
                                    ref={saveRef}
                                    testId={testIds.LOCATION_SUMMARY_VIEW_REMOVE_LOCATION}
                                    hideQuickAdd={hideQuickAdd}
                                />
                            )}
                        </ButtonGroup>
                    );
                }}
                renderTitle={() => (
                    <EntitySummaryHeader
                        iconType={iconTypes.LOCATION}
                        preTitle={customTitle}
                        name={
                            <LocationTitleSummary
                                locationBundle={locationBundle}
                                boldAddress={true}
                                showCountry={!hideCountry}
                                showLongitudeAndLatitudeFields={showLongitudeAndLatitudeFields}
                            />
                        }
                    />
                )}
                key={index}
            >
                <LocationEntityLinkSummary
                    contentWidth={360}
                    labelWidth={150}
                    locationEntityLink={locationBundle.locationEntityLink}
                    variant={locationEntityLinkSummaryVariant}
                />
                {additionalLocationContent && (
                    // This is not ideal, but in almost
                    // all cases, we want there to be margin
                    // between the location and additional content
                    // so lets bake it into here for ease of use
                    <AdditionalContentWrapper>
                        {additionalLocationContent(locationBundle, index)}
                    </AdditionalContentWrapper>
                )}
            </EntitySummary>
        </LocationQuickAddContext.Provider>
    );
};
