import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReportStatusView } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import reportApprovalLevelClientEnum from '~/client-common/core/enums/client/reportApprovalLevelClientEnum';
import { DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW } from '~/client-common/core/enums/universal/fields';
import { reportDefinitionRestrictViewReportOwnersSelector } from '~/client-common/core/domain/report-definitions/state/data';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { Button as ArcButton, ButtonGroup } from '../../../../core/components/Button';
import testIds from '../../../../../core/testIds';
import { Tooltip } from '../../../../core/components/tooltip';
import { useFocusOnUnmountCallback } from '../../../../core/utils/eventHelpers';
import { approveReport } from '../../state/ui/submissions';
import { currentReportSealingSelector } from '../../../../record-privacy/sealing/state/ui';
import { useIsReportOwnerRestricted } from '../../../../core/hooks/useIsReportOwnerRestricted';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { RmsDispatch } from '../../../../../core/typings/redux';
import CardWithApprovalStatusIcon from './CardWithApprovalStatusIcon';
import SubmissionErrorModal from './SubmissionErrorModal';
import { NibrsErrors } from './NibrsAdminSection';

const strings = componentStrings.reports.core.ReportStatusCommentsCard;

interface SubmittedCardOuterPropsT {
    reportStatusView: ReportStatusView;
    className?: string;
    rejectReport: (callback: () => void) => void;
    disabled?: boolean;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
    nibrsErrorsData?: NibrsErrors;
    shouldDisableApprovalButton?: boolean;
    currentReportApprovalLevel?: string;
}

/**
 * Approval status card in the "submitted" state. This is a yellow card that is await approval
 */
function SubmittedCard({
    reportStatusView,
    className,
    rejectReport,
    disabled,
    reportSealingInfo,
    nibrsErrorsData,
    shouldDisableApprovalButton,
    currentReportApprovalLevel,
}: SubmittedCardOuterPropsT) {
    const approveRef = useRef(null);
    const dispatch = useDispatch<RmsDispatch>();
    const currentReport = useSelector(currentReportSelector);
    const reportDefinitionRestrictViewReportOwners = useSelector(
        reportDefinitionRestrictViewReportOwnersSelector
    );
    const fieldDisplayNames = useFields([
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
    ]);
    const rejectReportCallback = useFocusOnUnmountCallback(rejectReport, approveRef);
    const checkIsReportOwnerRestricted = useIsReportOwnerRestricted();
    const isSingleReview = currentReportApprovalLevel === reportApprovalLevelClientEnum.ONE;

    if (!currentReport) {
        return null;
    }

    const restrictViewReportOwners = reportDefinitionRestrictViewReportOwners(
        currentReport.reportDefinitionId
    );
    const isReportOwnerRestricted = checkIsReportOwnerRestricted(
        restrictViewReportOwners,
        currentReport.permissionSet
    );
    const latestHistoryText = (createdBy: string, formattedDate: string | null) => {
        return strings.submitted.by(isReportOwnerRestricted ? '—' : createdBy, formattedDate);
    };

    const handleApprove = () => dispatch(approveReport());

    return (
        <CardWithApprovalStatusIcon
            testId={testIds.REPORT_STATUS_COMMENTS_SUBMITTED_CARD}
            approvalStatus={approvalStatusClientEnum.SUBMITTED}
            reportSealingInfo={reportSealingInfo}
            title={fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW}
            className={className}
            customSummaryModeColor={'lightYellow'}
            customSummaryModeBorderColor={'brightYellow'}
            latestHistoryText={latestHistoryText}
            shouldDisableApprovalButton={shouldDisableApprovalButton && isSingleReview}
            nibrsErrorsData={nibrsErrorsData}
            isSingleReview={isSingleReview}
        >
            <p>
                {reportStatusView.reviewableErrors.map((error, i) => {
                    return <div key={i}>{error}</div>;
                })}
            </p>
            {(reportStatusView.canReview || reportStatusView.canReject) && !disabled && (
                <ButtonGroup style={{ marginTop: 'var(--arc-space-4)' }}>
                    <Tooltip
                        side="bottom"
                        content={
                            shouldDisableApprovalButton && isSingleReview
                                ? componentStrings.reports.core.NibrsAdminSection.message
                                      .restrictReportTooltip
                                : ''
                        }
                    >
                        <ArcButton
                            isTextTransformNone
                            leadingVisual="Check"
                            variant="solid"
                            testId={
                                testIds.REPORT_STATUS_COMMENTS_SUBMITTED_CARD_APPROVE_REPORT_BUTTON
                            }
                            isDisabled={
                                (shouldDisableApprovalButton && isSingleReview) ||
                                !reportStatusView.canReview
                            }
                            onClick={handleApprove}
                            ref={approveRef}
                        >
                            {strings.core.approve}
                        </ArcButton>
                    </Tooltip>
                    <SubmissionErrorModal />
                    <ArcButton
                        leadingVisual="CloseX"
                        isTextTransformNone
                        testId={testIds.REPORT_STATUS_COMMENTS_SUBMITTED_CARD_REJECT_REPORT_BUTTON}
                        isDisabled={!reportStatusView.canReject}
                        variant="outline"
                        onClick={rejectReportCallback}
                    >
                        {strings.core.reject}
                    </ArcButton>
                </ButtonGroup>
            )}
        </CardWithApprovalStatusIcon>
    );
}

export default SubmittedCard;
