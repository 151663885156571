import * as React from 'react';
import { InputTypeEnum } from '@mark43/rms-api';
import { FormReferenceUiOptions, FormReferenceSummaryUiOptions } from 'dragon-react';
import { DragonCurrentFormContextProvider } from '../../context/dragon-current-form';
import { useReferencedFormInstanceIds } from '../../hooks/use-referenced-form-instance-id';
import { DragonConfiguredPropertyAliasContextProvider } from '../../context/dragon-configured-property-alias';
import { useDragonFormValuesContext } from '../../context/dragon-form-values';
import { RMSDragonConfigurationExtensions } from '../../rms-types';
import { resolveAliasValues } from '../../utils/resolve-alias-values';
import { formReferenceSummary } from './renderers/summary';
import { MFTFormWrapper } from './mft-form-wrapper';
import { CreateFormReferenceButton } from './renderers/create-form-reference-button';

export function FormReferenceWrapper({
    options,
}: {
    options:
        | FormReferenceUiOptions<RMSDragonConfigurationExtensions>
        | FormReferenceSummaryUiOptions<RMSDragonConfigurationExtensions>;
}): JSX.Element | null {
    const referencedFormInstanceIds = useReferencedFormInstanceIds(options.fullyQualifiedPath);
    const formValuesMap = useDragonFormValuesContext();

    // we bail if we cannot find an instance id to prevent old reports from crashing

    if (!referencedFormInstanceIds || referencedFormInstanceIds.length === 0) {
        return null;
    }

    const instanceIdsMissingFormValuesEntry = referencedFormInstanceIds.filter((instanceId) => {
        const formValueKey = `${options.configuration.formConfigurationId}~${instanceId}`;
        const formValue = formValuesMap[formValueKey];
        return !formValue;
    });

    const parentInstanceId = referencedFormInstanceIds[0];
    const formValueKey = `${options.configuration.formConfigurationId}~${parentInstanceId}`;
    const formValue = formValuesMap[formValueKey];

    if (instanceIdsMissingFormValuesEntry.length > 0 || !formValue) {
        throw new Error(
            `Unexpectedly did not find a form value for key "${instanceIdsMissingFormValuesEntry.join(
                ', '
            )}`
        );
    }

    // dragon forms are composed together using query configurations. These configurations
    // can look up ancestor values via aliases, crossing multiple forms in the process.
    // We have to replicate the same alias resolving tree that the backend uses
    // when it is loading data, in order to ensure that values are consistent when
    // being read for consumption by components, e.g. `NameSummaryViewWrapper`.
    const { configuredFormPropertyAliasViews } = options.form;
    const configuredEntityPropertyValues = formValue.__meta?.configuredEntityPropertyValues;

    return (
        <DragonConfiguredPropertyAliasContextProvider
            value={resolveAliasValues({
                configuredEntityPropertyValues,
                configuredFormPropertyAliasViews,
            })}
        >
            {referencedFormInstanceIds.map((instanceId, index) => {
                return (
                    <DragonCurrentFormContextProvider
                        key={instanceId}
                        instanceId={instanceId}
                        configuredFormId={options.configuration.formConfigurationId}
                        referencingUiConfigurationId={options.configuration.id}
                    >
                        {options.mode === 'FORM' ? (
                            <MFTFormWrapper {...options} instanceId={instanceId} />
                        ) : (
                            // given how `DragonCard` renders its summary mode, we will most likely
                            // never hit this case in the RMS with the latest `dragon-react` changes.
                            // `dragon-react@v6` will not explicitly re-render the current form reference again in summary
                            // mode but only render its children to prevent an issue with tracking the currently
                            // active form context
                            formReferenceSummary(options)
                        )}
                        {index === referencedFormInstanceIds.length - 1 &&
                            options.configuration.ui.inputType === InputTypeEnum.N_CARD.name && (
                                <CreateFormReferenceButton
                                    uiConfigurationPath={options.fullyQualifiedPath}
                                    label={options.form.displayName}
                                    configuration={options.configuration}
                                />
                            )}
                    </DragonCurrentFormContextProvider>
                );
            })}
        </DragonConfiguredPropertyAliasContextProvider>
    );
}
