import { Icon as ArcIcon, Button, cssVar } from 'arc';
import React from 'react';
import styled from 'styled-components';
import { Icon, iconTypes } from 'components-mark43';
import {
    SimpleZoomControlsButtonStyleT,
    SimpleZoomControlsPositionT,
} from '../esri-hooks/useSimpleZoomControls';

const WHITE = '#ffffff';

type ZoomControlsWrapperProps = {
    zoomControlPosition: SimpleZoomControlsPositionT;
};

const ZoomControlsWrapper = styled.div<ZoomControlsWrapperProps>`
    width: 37px;
    right: 12px;
    z-index: 1; /* needed to get above map */
    ${({ zoomControlPosition }) => {
        switch (zoomControlPosition) {
            case 'middle':
                return `
                    top: 50%;
                    transform: translateY(-50%);`;
            case 'top':
            default:
                return `top: 19px;`;
        }
    }}

    & button:first-child {
        border-radius: 3px 3px 0px 0px;
    }

    & button:last-child {
        border-radius: 0px 0px 3px 3px;
    }

    position: absolute;
`;

type ControlButtonProps = {
    compactZoomControls?: boolean;
};

const ControlButton = styled(Button)<ControlButtonProps>`
    float: left;
    clear: both;
    text-align: center;
    cursor: pointer;
    margin-bottom: ${({ compactZoomControls }) => (!!compactZoomControls ? '5px' : '0')};
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
`;

const ButtonIcon = styled(Icon)`
    margin-top: -2px;
`;

type SimpleZoomControlsProps = {
    compactZoomControls?: boolean;
    zoomIn: () => void;
    zoomOut: () => void;
    zoomControlPosition: SimpleZoomControlsPositionT;
    zoomButtonStyle?: SimpleZoomControlsButtonStyleT;
};

const SimpleZoomControls = ({
    compactZoomControls = false,
    zoomIn,
    zoomOut,
    zoomControlPosition,
    zoomButtonStyle,
}: SimpleZoomControlsProps): JSX.Element => {
    const isArcButtonStyle = zoomButtonStyle === 'arc';
    return (
        <ZoomControlsWrapper zoomControlPosition={zoomControlPosition}>
            <ControlButton
                variant="solid"
                compactZoomControls={compactZoomControls}
                onClick={zoomIn}
            >
                {isArcButtonStyle ? (
                    <ArcIcon icon="Add" color={cssVar('arc.colors.brand.content')} />
                ) : (
                    <ButtonIcon type={iconTypes.ADD} color={WHITE} />
                )}
            </ControlButton>
            <ControlButton variant="solid" onClick={zoomOut}>
                {isArcButtonStyle ? (
                    <ArcIcon icon="Close" color={cssVar('arc.colors.brand.content')} />
                ) : (
                    <ButtonIcon type={iconTypes.CLOSE} color={WHITE} />
                )}
            </ControlButton>
        </ZoomControlsWrapper>
    );
};

export default SimpleZoomControls;
