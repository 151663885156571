import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { map, filter } from 'lodash';
import { VStack, Divider, Flex, cssVar } from 'arc';
import { RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';
import { isPlural, plural } from 'pluralize';

import { PhotoLineupView, OperationTypeEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResource } from '~/client-common/core/hooks/useResource';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import OnlyWithEntityPermission from '~/client-common/core/domain/entity-permissions/components/OnlyWithEntityPermission';
import { mugshotAttachmentsForPersonIdSelector } from '~/client-common/core/domain/attachments/state/ui';
import testIds from '../../../../core/testIds';

import PaddedNoDataBlock from '../../../core/components/PaddedNoDataBlock';
import { horizCenter } from '../../../core/styles/mixins';
import casePhotoLineupResource from '../../core/resources/casePhotoLineupResource';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { currentCaseSelector } from '../../core/state/ui';
import { useLineupContext } from '../state/ui';
import { mugShotsExist } from '../state/data/createLineupModal';
import { usePhotoLineupFieldName } from '../hooks/usePhotoLineupFieldName';
import { Button as _Button } from '../../../core/components/Button';
import { usePhotoLineupPersonProfiles } from '../hooks/usePhotoLineupPersonProfiles';
import { ConditionalTooltip } from '../../../core/components/tooltip';
import { LineupSummaryRow } from './LineupPhotoRow';
import DeleteLineupConfirmationModal from './DeleteLineupConfirmationModal';
import CreateLineupModal from './CreateLineupModal';

const strings = componentStrings.cases.casePhotoLineups.CasePhotoLineups;

const NoResults = styled(PaddedNoDataBlock)`
    margin-top: 20px;
    width: 250px;
    ${horizCenter};
    position: relative;
`;

const Button = styled(_Button)`
    text-transform: none;
`;

type Params = {
    caseId: string;
};

const CasePhotoLineups: React.FC<RouteComponentProps<Params, Record<string, unknown>>> = ({
    params,
}) => {
    const caseId = parseInt(params.caseId);
    const [lineups, setLineups] = useState<PhotoLineupView[]>([]);
    const { setNumPhotoLineups } = useLineupContext();
    const lineupFieldName = usePhotoLineupFieldName();
    const { offenseSuspects, otherProfiles } = usePhotoLineupPersonProfiles();
    const allProfiles = [...offenseSuspects, ...otherProfiles];
    const pluralLineupName = isPlural(lineupFieldName) ? lineupFieldName : plural(lineupFieldName);

    const loadLineups = useCallback(() => casePhotoLineupResource.getPhotoLineupsForCase(caseId), [
        caseId,
    ]);

    const onResourceSuccess = useCallback(
        (photoLineupViews: PhotoLineupView[]) => {
            setLineups(photoLineupViews);
            setNumPhotoLineups(photoLineupViews.length); // needs to be moved to create line up modal when the save button gets pressed
        },
        [setNumPhotoLineups]
    );

    const { isLoading } = useResource(loadLineups, onResourceSuccess);

    const currentCase = useSelector(currentCaseSelector);
    const { permissionSet } = currentCase || {};

    const mugshotAttachmentsForPersonId = useSelector(mugshotAttachmentsForPersonIdSelector);

    const disableCreateLineup = !mugShotsExist(allProfiles, mugshotAttachmentsForPersonId);
    const overlayStore = useOverlayStore();

    const openCreateLineupModal = () => {
        overlayStore.open(overlayIdEnum.CREATE_LINEUP_MODAL);
    };

    const openDeleteLineupModal = () => {
        overlayStore.open(overlayIdEnum.DELETE_LINEUP_MODAL);
    };

    const updateLineupsAfterDeletion = (lineupId: number) => {
        setLineups((oldLineupViews) =>
            filter(oldLineupViews, (lineupView) => lineupView.photoLineup.id !== lineupId)
        );
        setNumPhotoLineups((num) => num - 1);
    };

    if (isLoading) {
        return null;
    }

    return (
        <div>
            <CreateLineupModal caseId={caseId} />
            <Flex
                justify="flex-end"
                marginTop={cssVar('arc.space.2')}
                marginBottom={cssVar('arc.space.6')}
            >
                <OnlyWithEntityPermission
                    permissionSet={permissionSet}
                    has={OperationTypeEnum.WRITE.name}
                >
                    <ConditionalTooltip
                        side="left"
                        content={strings.disabledCreateLineupButtonTooltip}
                        condition={disableCreateLineup}
                    >
                        <Button
                            leftIcon="Add"
                            variant="solid"
                            isDisabled={disableCreateLineup}
                            onClick={openCreateLineupModal}
                            testId={testIds.PHOTO_LINEUP_CREATE_LINEUP_BUTTON}
                        >
                            {strings.createLineupButton(lineupFieldName)}
                        </Button>
                    </ConditionalTooltip>
                </OnlyWithEntityPermission>
            </Flex>
            {lineups.length > 0 ? (
                <VStack divider={<Divider />}>
                    {map(lineups, (lineupView, index) => (
                        <React.Fragment key={lineupView.photoLineup.id}>
                            <LineupSummaryRow
                                lineupView={lineupView}
                                key={index}
                                lineupFieldName={lineupFieldName}
                                caseId={caseId}
                                openDeleteLineupModal={openDeleteLineupModal}
                                lineupDetailsURL={`/cases/${caseId}/lineups/${lineupView.photoLineup.id}`}
                            />
                        </React.Fragment>
                    ))}
                    <DeleteLineupConfirmationModal
                        lineupFieldName={lineupFieldName}
                        onSuccess={updateLineupsAfterDeletion}
                    />
                </VStack>
            ) : (
                <NoResults>{strings.noPhotoLineups(pluralLineupName)}</NoResults>
            )}
        </div>
    );
};
export default CasePhotoLineups;
