import React, { useCallback, useState } from 'react';
import partialRight from 'lodash/partialRight';
import {
    Box,
    DateRange,
    DateRangeProps,
    DateValue,
    FilterMenuList,
    FilterMenuRenderProps,
    FilterOptionT,
    Flex,
    parseISODate,
    RangeValue,
} from 'arc';
import styled from 'styled-components';
import { DateRangeQuery } from '@mark43/rms-api';
import {
    convertDateRangeFieldValuesToOptionValue,
    convertDateRangeOptionValueToFieldValues,
    getDateRangeOptions,
} from '~/client-common/core/dates/utils/dateRangeHelpers';
import DateRangeTypeEnum, {
    ToDatePeriodOption,
    WithinLastPeriodOption,
} from '~/client-common/core/enums/client/dateRangeTypeEnum';
import {
    dateTimeFormats,
    formatISODate,
    nowUtc,
} from '~/client-common/core/dates/utils/dateHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useDateTimeFormatter } from '../../current-user/hooks/dateTimeFormats';

const strings = componentStrings.forms.DateRangePicker;

const customRangeFilterOption = {
    display: strings.customRange,
    value: DateRangeTypeEnum.CUSTOM_RANGE,
};

const FilterMenu = styled(FilterMenuList)`
    width: 100%;
`;

type ConvertDateRangeOption =
    | {
          id: `${typeof DateRangeTypeEnum.WITHIN_LAST_PERIOD}_${string}`;
          value: Required<Pick<DateRangeQuery, 'withinLastPeriod'>>;
      }
    | {
          id: `${typeof DateRangeTypeEnum.TO_DATE_PERIOD}_${string}`;
          value: Required<Pick<DateRangeQuery, 'toDatePeriod'>>;
      }
    | {
          id: typeof DateRangeTypeEnum.CUSTOM_RANGE;
          value: Required<Pick<DateRangeQuery, 'startDateUtc' | 'endDateUtc'>>;
      };
export const convertDateRangeToAppliedOption = (
    item: ConvertDateRangeOption & { label: string }
) => {
    return {
        ...item,
        display: item.label,
    };
};

type CustomRangeValue = Required<Pick<DateRangeQuery, 'startDateUtc' | 'endDateUtc'>>;
const isCustomRangeValue = (value?: DateRangeQuery): value is CustomRangeValue => {
    return value !== undefined && 'startDateUtc' in value && 'endDateUtc' in value;
};

const formatIsoDateTime = partialRight(formatISODate, dateTimeFormats.isoDateTime);
const formatDateRange = (start: string, end: string, trimToDays = false) => {
    const startDateTime = parseISODate(formatIsoDateTime(start), 'datetime');
    const endDateTime = parseISODate(formatIsoDateTime(end), 'datetime');
    return {
        start: !trimToDays ? startDateTime : startDateTime.set({ hour: 0, minute: 0, second: 0 }),
        end: !trimToDays ? endDateTime : endDateTime.set({ hour: 0, minute: 0, second: 0 }),
    };
};

const getDateRange = (appliedOptions: FilterOptionT<DateRangeQuery>[]) => () => {
    if (!appliedOptions.length) {
        return formatDateRange(nowUtc(), nowUtc(), true);
    }
    const value = appliedOptions[0].value;
    if (!isCustomRangeValue(value)) {
        return formatDateRange(nowUtc(), nowUtc(), true);
    }

    return formatDateRange(value.startDateUtc, value.endDateUtc);
};

type IncludeTime =
    | {
          includeTime: true;
          granularity: Extract<DateRangeProps['granularity'], 'minute' | 'second'>;
      }
    | {
          includeTime?: false;
          granularity?: Exclude<DateRangeProps['granularity'], 'minute' | 'second'>;
      };

export const DateRangeFilter: React.FC<
    FilterMenuRenderProps<DateRangeQuery> &
        IncludeTime & {
            withinLastPeriodOptions: WithinLastPeriodOption[];
            toDatePeriodOptions: ToDatePeriodOption[];
            includeNoFilterOption?: boolean;
            includeCustomFilterOption?: boolean;
        }
> = ({
    setAppliedFilters,
    appliedOptions,
    withinLastPeriodOptions,
    toDatePeriodOptions,
    includeNoFilterOption = false,
    includeCustomFilterOption = true,
    includeTime,
    granularity,
    closePopover,
}) => {
    const [range, setRange] = useState<RangeValue<DateValue>>(getDateRange(appliedOptions));
    const [isCustomDateRange, setIsCustomDateRange] = useState(() =>
        Boolean(appliedOptions.length && appliedOptions[0].id === DateRangeTypeEnum.CUSTOM_RANGE)
    );

    const dateTimeFormatter = useDateTimeFormatter();

    const options = getDateRangeOptions({
        withinLastPeriodOptions,
        toDatePeriodOptions,
        includeNoFilterOption,
        includeCustomFilterOption,
    }).map((item) => {
        return {
            ...item,
            label: item.display,
            id: item.value,
        };
    });

    const preparedAppliedOptions = appliedOptions.map((item) => ({
        ...item,
        value: convertDateRangeFieldValuesToOptionValue(item.value),
    }));

    const onSelect = useCallback(
        (item) => {
            if (item.value === DateRangeTypeEnum.CUSTOM_RANGE) {
                setIsCustomDateRange(true);
                return;
            }

            const startDateUtc = range.start.toDate('UTC');
            const endDateUtc = range.end.toDate('UTC');
            const value = convertDateRangeOptionValueToFieldValues(
                item.value,
                startDateUtc,
                endDateUtc,
                startDateUtc,
                endDateUtc
            );

            setAppliedFilters({ ...item, value });
            closePopover();
        },
        [closePopover, setAppliedFilters, range]
    );

    const handleCustomDate = (range: RangeValue<DateValue> | null) => {
        if (!range) {
            return;
        }
        setRange(range);

        const formatDate = includeTime
            ? granularity === 'minute'
                ? dateTimeFormatter.formatDateTime
                : dateTimeFormatter.formatDateTimeSec
            : dateTimeFormatter.formatDate;

        const label = `${formatDate(range.start.toString())} - ${formatDate(range.end.toString())}`;

        setAppliedFilters({
            ...customRangeFilterOption,
            id: DateRangeTypeEnum.CUSTOM_RANGE,
            label,
            value: { startDateUtc: range.start.toString(), endDateUtc: range.end.toString() },
        });
    };

    return (
        <Flex>
            <FilterMenu
                options={options}
                appliedOptions={preparedAppliedOptions}
                onSelect={onSelect}
                selectionType="single"
            />
            {isCustomDateRange && (
                <Box p={2}>
                    <DateRange
                        startFormControlProps={{
                            label: includeTime
                                ? strings.labels.startDateUtc
                                : strings.labels.startDate,
                        }}
                        endFormControlProps={{
                            label: includeTime ? strings.labels.endDateUtc : strings.labels.endDate,
                        }}
                        popoverContentProps={{
                            onCloseAutoFocus: closePopover,
                        }}
                        value={range}
                        onChange={handleCustomDate}
                        granularity={granularity}
                        hasCalendar
                    />
                </Box>
            )}
        </Flex>
    );
};
