import React from 'react';
import { EntityTypeEnumType } from '@mark43/rms-api';

import OverlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { useFieldName } from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CAUTION_LABEL } from '~/client-common/core/enums/universal/fields';

import testIds from '../../../../core/testIds';
import { Button } from '../../components/Button';
import { OverlayRenderProps } from '../../components/OverlayBaseHelper';
import { PersonSidePanel } from '../../persons/components/PersonSidePanel';
import { PERSON_PROFILE_FORM_ADD_CAUTION_BUTTON_ID } from '../configuration';

interface AddPersonCautionButtonProps {
    entityId: number;
    ownerId: number;
    ownerType: EntityTypeEnumType;
    isEditingMasterProfile?: boolean;
}

export const AddPersonCautionButton: React.FC<AddPersonCautionButtonProps> = ({
    entityId,
    ownerId,
    ownerType,
    isEditingMasterProfile,
}) => {
    const { singular: addButtonLabel } = useFieldName(DISPLAY_ONLY_CAUTION_LABEL);
    return (
        <PersonSidePanel
            entityId={entityId}
            ownerId={ownerId}
            ownerType={ownerType}
            isEditingMasterProfile={isEditingMasterProfile}
            overlayId={OverlayIdEnum.PERSON_OVERLAY_CAUTIONS}
            elementToFocusId={PERSON_PROFILE_FORM_ADD_CAUTION_BUTTON_ID}
            renderButton={({ overlayBase: { open } }: OverlayRenderProps<object>) => (
                <Button
                    isTextTransformNone
                    leadingVisual="Add"
                    variant="ghost"
                    testId={testIds.ENHANCED_CAUTIONS_ADD_PERSON_CAUTION_BUTTON}
                    onClick={open}
                >
                    {addButtonLabel}
                </Button>
            )}
        />
    );
};
