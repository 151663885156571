import { useCallback, useEffect, useMemo, useState } from 'react';
import { FeatureLayer, Graphic } from '../constructors';
import { FeatureLayerT, LocationCoordinates, WebMapT } from '../types';
import addressIconSvgString from '../images/address';
import { SPATIAL_REFERENCE } from '../constants';
import { useStaticLayerControl } from './useStaticFeatureLayerControl';

type SelectedStaticLocationFeatureLayerProps = {
    selectedLocation?: LocationCoordinates;
    webmap?: WebMapT;
};

// build selectedLocation graphic
export const createSelectedLocationGraphic = (selectedLocation: LocationCoordinates) => {
    return new Graphic({
        geometry: {
            // @ts-expect-error esri types seem to be wrong https://developers.arcgis.com/javascript/latest/api-reference/esri-Graphic.html#geometry
            type: 'point',
            longitude: selectedLocation.longitude,
            latitude: selectedLocation.latitude,
        },
    });
};

export const useSelectedStaticLocationFeatureLayer = ({
    selectedLocation,
    webmap,
}: SelectedStaticLocationFeatureLayerProps) => {
    const selectedLocationArray = useMemo(() => (selectedLocation ? [selectedLocation] : []), [
        selectedLocation,
    ]);
    const [selectedLocationLayer, setSelectedLocationLayer] = useState<FeatureLayerT>();

    const createSelectedLocationGraphics = useCallback(
        (selectedLocations: LocationCoordinates[]) => {
            return selectedLocations.map((selectedLocation) => {
                return createSelectedLocationGraphic(selectedLocation);
            });
        },
        []
    );

    // handles initializing empty feature layer
    useEffect(() => {
        // need modules to load
        if (webmap) {
            const selectedLocationFeatureLayer = createSelectedLocationLayer();
            setSelectedLocationLayer(selectedLocationFeatureLayer);
            webmap.layers.add(selectedLocationFeatureLayer);
        }
    }, [webmap]);

    useStaticLayerControl({
        layer: selectedLocationLayer,
        currItems: selectedLocationArray,
        createGraphics: createSelectedLocationGraphics,
        replaceExisting: true,
    });

    return {
        layer: selectedLocationLayer,
    };
};

const addressIconSvgStringEncoded = btoa(addressIconSvgString);

const selectedLocationIconRenderer = {
    type: 'unique-value', // autocasts as new UniqueValueRenderer()
    field: ' ',
    defaultSymbol: {
        // used when item field dont match anything defined in uniqueValueInfos
        type: 'picture-marker',
        url: `data:image/svg+xml;base64,${addressIconSvgStringEncoded}`,
        width: '22px',
        height: '32px',
    },
    authoringInfo: undefined,
};

const createSelectedLocationLayer = () => {
    const selectedLocationFeatureLayer = new FeatureLayer({
        title: 'Selected Location',
        source: [], // initialize with an empty collection
        renderer: selectedLocationIconRenderer,
        objectIdField: 'objectId', // This must be defined when creating a layer from `Graphic` objects
        outFields: ['*'],
        geometryType: 'point',
        spatialReference: SPATIAL_REFERENCE,
    });

    return selectedLocationFeatureLayer;
};
