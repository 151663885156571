import React from 'react';
import _, { map, assign, split, compact } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import styled from 'styled-components';
import { Report, ReportComment, ReportCommentTypeEnum, ReportStatusHistory } from '@mark43/rms-api';

import { timeAgo } from '~/client-common/core/dates/utils/dateHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportInlineCommentsViewSelector } from '~/client-common/core/domain/inline-report-comments/state/data';
import withFields from '~/client-common/core/fields/components/withFields';
import {
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
} from '~/client-common/core/enums/universal/fields';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { formatStatusHistoryWithFields } from '~/client-common/core/domain/report-status-histories/state/ui';
import { reportStatusHistoriesByReportIdSelector } from '~/client-common/core/domain/report-status-histories/state/data';
import {
    reportCommentsByReportIdSelector,
    deleteReportComment,
} from '~/client-common/core/domain/report-comments/state/data';
import { Field } from '~/client-common/core/fields/state/config';
import { ClientCommonAction } from '~/client-common/redux/types';
import { reportDefinitionRestrictViewReportOwnersSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { RmsDispatch } from '../../../../../core/typings/redux';
import { currentUserIdSelector } from '../../../../core/current-user/state/ui';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import {
    sortInlineCommentsForDisplay,
    InlineReportComment,
} from '../../utils/inlineReportComments';

import { OnlyWithAbility, abilitiesEnum } from '../../../../core/abilities';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { SecondarySectionHeader as _SecondarySectionHeader } from '../../../../core/components/typography';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import { DashedDivider as _DashedDivider } from '../../../../../legacy-redux/components/core/Divider';
import { useDateTimeFormatter } from '../../../../core/current-user/hooks/dateTimeFormats';
import { useIsReportOwnerRestricted } from '../../../../core/hooks/useIsReportOwnerRestricted';
import ReportCommentForm from './ReportCommentForm';
import ReportInlineComment from './ReportInlineComment';

const strings = componentStrings.reports.core.ReportCommentsAndHistory;

const reportCommentTypes = {
    COMMENT: 'reportComment',
    INLINE_COMMENT: 'reportInlineComment',
    HISTORY: 'reportStatusHistory',
};

const ReportCommentsAndHistoryCardSection = styled(CardSection)`
    padding: 10px 20px 10px 20px;
    float: none;
`;

const SecondarySectionHeader = styled(_SecondarySectionHeader)`
    margin-bottom: 20px;
`;

const DashedDivider = styled(_DashedDivider)`
    margin-top: 0;
    margin-bottom: 10px;
`;

const ReportHistoryItemWrapper = styled.div`
    display: flex;
    position: relative;
`;

const ReportHistoryItems = styled.div`
    & ${/* sc-selector */ ReportHistoryItemWrapper} {
        margin-bottom: 10px;
    }

    & ${/* sc-selector */ ReportHistoryItemWrapper}:last-child {
        margin-bottom: 0;
    }
`;

const _NoteIcon = (props: Record<string, never>) => <Icon type={iconTypes.NOTE} {...props} />;

const NoteIcon = styled(_NoteIcon)`
    margin-right: 8px;
`;

const ReportHistoryItemCommentTitle = styled.div`
    height: 16px;
    line-height: 16px;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.darkGrey};
    margin-bottom: 10px;
`;

const ReportHistoryItemTime = styled.span`
    font-style: italic;
    color: ${(props) => props.theme.colors.mediumGrey};
`;

const ReportHistoryItemCommentBody = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.darkGrey};
    white-space: pre-wrap;
    word-break: break-word;
`;

const _ReportHistoryItemCommentDeleteButton = ({
    className,
    onClick,
}: {
    className?: string;
    onClick: () => void;
}) => {
    return (
        <Button onClick={onClick} className={classNames(className, buttonTypes.ICON_LINK)}>
            <Icon type={iconTypes.TRASH_CAN} />
        </Button>
    );
};

const ReportHistoryItemCommentDeleteButton = styled(_ReportHistoryItemCommentDeleteButton)`
    display: none;
    position: absolute;
    top: 0;
    right: 0;

    ${/* sc-selector */ ReportHistoryItemWrapper}:hover & {
        display: block;
    }
`;

const ReportHistoryItemStatus = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.mediumGrey};
`;

type CombinedReportHistoryItemType =
    | (ReportStatusHistory & { key: string })
    | (InlineReportComment & { key: string })
    | (ReportComment & { key: string });

interface ReportCommentsAndHistoryOuterPropsT {
    className?: string;
}
interface ReportCommentsAndHistoryInnerPropsT extends ReportCommentsAndHistoryOuterPropsT {
    currentUserId: number | undefined;
    currentReport: Report | undefined;
    deleteReportComment: (id: number) => ClientCommonAction<Promise<void>>;
    fieldDisplayNames: Record<Field, string>;
    formatMiniUserById: ReturnType<typeof formatMiniUserByIdSelector>;
    reportCommentsByReportId: ReturnType<typeof reportCommentsByReportIdSelector>;
    reportInlineCommentsView: ReturnType<typeof reportInlineCommentsViewSelector>;
    reportStatusHistoriesByReportId: ReturnType<typeof reportStatusHistoriesByReportIdSelector>;
    reportDefinitionRestrictViewReportOwners: ReturnType<
        typeof reportDefinitionRestrictViewReportOwnersSelector
    >;
}
const ReportCommentsAndHistory = ({
    className,
    currentUserId,
    currentReport,
    formatMiniUserById,
    reportCommentsByReportId,
    reportStatusHistoriesByReportId,
    deleteReportComment,
    fieldDisplayNames,
    reportInlineCommentsView,
    reportDefinitionRestrictViewReportOwners,
}: ReportCommentsAndHistoryInnerPropsT) => {
    const dateTimeFormatter = useDateTimeFormatter();
    const checkIsReportOwnerRestricted = useIsReportOwnerRestricted();

    if (!currentReport?.id) {
        return null;
    }
    const reportComments = compact(
        map(reportCommentsByReportId(currentReport.id), (o) => {
            if (o.type === ReportCommentTypeEnum.INLINE.name) {
                return null;
            }
            return assign({}, o, { key: `${reportCommentTypes.COMMENT}~${o.id}` });
        })
        // type casting to avoid type conflicts in the concat later in this function
    ) as CombinedReportHistoryItemType[];
    const reportStatusHistories = map(reportStatusHistoriesByReportId(currentReport.id), (o) =>
        assign({}, o, { key: `${reportCommentTypes.HISTORY}~${o.id}` })
    ) as CombinedReportHistoryItemType[];
    const reportInlineComments = map(
        sortInlineCommentsForDisplay(reportInlineCommentsView, {
            reportId: currentReport.id,
        }),
        (o) => assign({}, o, { key: `${reportCommentTypes.INLINE_COMMENT}~${o.id}` })
    ) as CombinedReportHistoryItemType[];

    const combinedReportHistoryItems: CombinedReportHistoryItemType[] = _(reportComments)
        .concat(reportStatusHistories)
        .concat(reportInlineComments)
        .sortBy(['createdDateUtc', 'rmsEventId'])
        .reverse()
        .value();
    if (combinedReportHistoryItems.length === 0) {
        return null;
    }

    const showCommentForm = reportStatusHistories.length > 0;

    let lastReportHistoryItemType: string | null = null;

    const restrictViewReportOwners = reportDefinitionRestrictViewReportOwners(
        currentReport.reportDefinitionId
    );

    const isReportOwnerRestricted = checkIsReportOwnerRestricted(
        restrictViewReportOwners,
        currentReport.permissionSet
    );

    return (
        <ReportCommentsAndHistoryCardSection className={className}>
            <SecondarySectionHeader>{strings.header}</SecondarySectionHeader>
            <OnlyWithAbility has={abilitiesEnum.REPORTING.ADD_DELETE_REPORT_COMMENTS}>
                {showCommentForm && <ReportCommentForm reportId={currentReport.id} />}
            </OnlyWithAbility>

            <ReportHistoryItems>
                {combinedReportHistoryItems.map((reportHistoryItem) => {
                    const reportHistoryItemType = split(reportHistoryItem.key, '~')[0];
                    const showDivider =
                        lastReportHistoryItemType &&
                        lastReportHistoryItemType !== reportHistoryItemType;
                    const formattedCreatedByUser = `${formatMiniUserById(
                        reportHistoryItem.createdBy,
                        {
                            firstNameAsInitial: true,
                        }
                    )}
                        ${
                            (reportHistoryItemType === reportCommentTypes.COMMENT ||
                                reportHistoryItemType === reportCommentTypes.INLINE_COMMENT) &&
                            reportHistoryItem.createdBy === currentUserId
                                ? ` ${strings.sameUserText}`
                                : ''
                        }`;
                    const formattedCreatedDateUtc = `${timeAgo(
                        reportHistoryItem.createdDateUtc
                    )} ${dateTimeFormatter.formatSummaryDate(reportHistoryItem.createdDateUtc)}
                    `;
                    lastReportHistoryItemType = reportHistoryItemType;

                    const historyItem = (
                        <ReportHistoryItemWrapper>
                            <ReportHistoryItemStatus>
                                <span>{`${formatStatusHistoryWithFields(
                                    reportHistoryItem,
                                    formattedCreatedByUser,
                                    fieldDisplayNames
                                )} `}</span>
                                <ReportHistoryItemTime>
                                    {formattedCreatedDateUtc}
                                </ReportHistoryItemTime>
                            </ReportHistoryItemStatus>
                        </ReportHistoryItemWrapper>
                    );

                    return (
                        <React.Fragment key={`${reportHistoryItem.key}`}>
                            {showDivider && <DashedDivider />}

                            <FeatureFlagged flag="RMS_INLINE_NARRATIVE_COMMENTS_ENABLED">
                                {reportHistoryItemType === reportCommentTypes.INLINE_COMMENT && (
                                    <ReportInlineComment
                                        reportId={currentReport.id}
                                        inlineComment={reportHistoryItem as InlineReportComment}
                                        formattedCreatedDateUtc={formattedCreatedDateUtc}
                                        formattedCreatedByUser={formattedCreatedByUser}
                                    />
                                )}
                            </FeatureFlagged>

                            {reportHistoryItemType === reportCommentTypes.COMMENT && (
                                <ReportHistoryItemWrapper>
                                    <NoteIcon />
                                    <div>
                                        <ReportHistoryItemCommentTitle>
                                            <span>{`${formattedCreatedByUser} `}</span>
                                            <ReportHistoryItemTime>
                                                {formattedCreatedDateUtc}
                                            </ReportHistoryItemTime>
                                            <OnlyWithAbility
                                                has={
                                                    abilitiesEnum.REPORTING
                                                        .ADD_DELETE_REPORT_COMMENTS
                                                }
                                            >
                                                <ReportHistoryItemCommentDeleteButton
                                                    onClick={() =>
                                                        deleteReportComment(reportHistoryItem.id)
                                                    }
                                                />
                                            </OnlyWithAbility>
                                        </ReportHistoryItemCommentTitle>
                                        <ReportHistoryItemCommentBody>
                                            {'comment' in reportHistoryItem &&
                                                reportHistoryItem.comment}
                                        </ReportHistoryItemCommentBody>
                                    </div>
                                </ReportHistoryItemWrapper>
                            )}
                            {reportHistoryItemType === reportCommentTypes.HISTORY && (
                                <FeatureFlagged
                                    flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                                    fallback={historyItem}
                                >
                                    {!isReportOwnerRestricted && historyItem}
                                </FeatureFlagged>
                            )}
                        </React.Fragment>
                    );
                })}
            </ReportHistoryItems>
        </ReportCommentsAndHistoryCardSection>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUserId: currentUserIdSelector,
    currentReport: currentReportSelector,
    formatMiniUserById: formatMiniUserByIdSelector,
    reportCommentsByReportId: reportCommentsByReportIdSelector,
    reportInlineCommentsView: reportInlineCommentsViewSelector,
    reportStatusHistoriesByReportId: reportStatusHistoriesByReportIdSelector,
    reportDefinitionRestrictViewReportOwners: reportDefinitionRestrictViewReportOwnersSelector,
});

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    deleteReportComment: (id: number) => dispatch(deleteReportComment(id)),
});

export default compose<ReportCommentsAndHistoryInnerPropsT, ReportCommentsAndHistoryOuterPropsT>(
    withFields([
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
    ]),
    connect(mapStateToProps, mapDispatchToProps)
)(ReportCommentsAndHistory);
