import { cssVar, InlineBanner } from 'arc';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { historyEventViewModelsByEntityIdSelector } from '~/client-common/core/domain/history-events/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import History from '../../../../core/histories/components/History';
import { SkeletonTable } from '../../../../core/components/Skeleton';
import { HistoryType } from '../../../../core/histories/config';
import { useEFileHistoryResource } from '../../../hooks/useEFileHistoryResource';

const strings = componentStrings.eFiles.core.history;

const StyledHistory = styled(History)`
    width: 100%;
    margin-top: 0;
    padding: ${cssVar('arc.space.4')} ${cssVar('arc.space.6')};
`;

const StyledSkeletonTable = styled(SkeletonTable)`
    width: 100%;
`;

const _EFileHistoryPage: FC<WithRouterProps> = ({ params }) => {
    const eFileId = parseInt(params.eFileId);
    const historyEventViewModelsByEntityId = useSelector(historyEventViewModelsByEntityIdSelector);
    const historyEventViewModels = historyEventViewModelsByEntityId(eFileId);

    const { isLoading, errorMessage } = useEFileHistoryResource(eFileId);

    if (isLoading) {
        return <StyledSkeletonTable />;
    }

    if (errorMessage) {
        return (
            <InlineBanner description={errorMessage} status="error" title={strings.failedToLoad} />
        );
    }

    return (
        <StyledHistory
            showFilter={true}
            historyEventViewModels={historyEventViewModels}
            historyType={HistoryType.E_FILE}
        />
    );
};

export const EFileHistoryPage = withRouter(_EFileHistoryPage);
