import React, { PropsWithChildren } from 'react';
import { PropertyStatus } from '@mark43/rms-api';
import invariant from 'invariant';
import ConnectedFormattedAttribute from '../../attributes/components/ConnectedFormattedAttribute';
import { getChildTypes } from '../../../../helpers/reactHelpers';

const NODE_ENV = process.env.NODE_ENV;

type FormattedPropertyStatusQuantityProps = {
    propertyStatus: PropertyStatus;
    className?: string;
};

/**
 * Use this component to format property status quantity for a given property status.
 *   Includes measurementUnitsAttrId if exists.
 */
export default function FormattedPropertyStatusQuantity({
    propertyStatus,
    children,
    className,
}: PropsWithChildren<FormattedPropertyStatusQuantityProps>): JSX.Element | null {
    if (!propertyStatus || !propertyStatus.quantity) {
        return null;
    }

    const { quantity, measurementUnitsAttrId } = propertyStatus;
    const childIsFunction = typeof children === 'function';

    if (NODE_ENV !== 'production') {
        const types = children ? getChildTypes(children).join(', ') : '';
        invariant(
            children === undefined || childIsFunction,
            `FormattedPropertyStatusQuantity only accepts a function as child, but you have nested "${types}".`
        );
    }

    const renderQuantity = (
        <span className={className}>
            {quantity}
            {measurementUnitsAttrId && (
                <span>
                    {' '}
                    <ConnectedFormattedAttribute attributeId={measurementUnitsAttrId} />
                </span>
            )}
        </span>
    );

    if (childIsFunction) {
        return children(renderQuantity);
    }

    return renderQuantity;
}
