import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { compose, withState, withHandlers } from 'recompose';
import styled from 'styled-components';
import { mediaQueries } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    ARREST_LOCAL_ID,
    CITATION_CITATION_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import ToggleButton from '../../../../legacy-redux/components/core/ToggleButton';
import Icon, { iconTypes, iconSizes } from '../../../core/components/Icon';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { openBox } from '../../../../legacy-redux/actions/boxActions';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { useViewport } from '../../../core/utils/useViewport';

import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import Subheader from '../../../core/components/Subheader';
import { responsiveStack } from '../../../core/styles/mixins';
import Page from '../../../core/components/Page';
import { Button } from '../../../core/components/Button';
import { RRFAsyncText } from '../../../core/forms/components/AsyncText';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import {
    reportsDashboardSearch,
    submitReportsDashboardSearch,
    searchTabCountsAfterExecutingSavedSearch,
} from '../state/ui';

import testIds from '../../../../core/testIds';
import SavedSearchModal from '../../../search/saved-search/components/SavedSearchModal';
import { useDashboardScroll } from '../../../search/core/hooks/useDashboardScroll';
import { openCreateReportModal } from '../../core/state/ui/createReportModal';
import ReportsDashboardSearchResults from './ReportsDashboardSearchResults';
import ReportsDashboardSearchFilters from './ReportsDashboardSearchFilters';
import ReportsDashboardSearchForm from './ReportsDashboardSearchForm';

const strings = componentStrings.reports.dashboard;

export const FilterButton = styled(ToggleButton)`
    margin-top: 0;
    padding: 1px 15px;
    line-height: 26px;
    text-align: left;
`;

export const SearchAndFilterBar = styled.div`
    ${responsiveStack()};
    flex-direction: column-reverse;
    width: 100%;
    align-items: stretch;

    @media (min-width: ${mediaQueries.md}) {
        justify-content: space-between;
        width: auto;
        margin-top: 19px;
        align-items: flex-start;
    }
`;

export const ToggleIcon = styled(Icon)`
    display: inline-block;
    padding: 4px 6px 4px 8px;
    vertical-align: -3px;
    flex-shrink: 0;
`;

export const SearchBox = styled(ReactReduxForm)`
    flex: 1;
`;

export const SearchWrapper = styled.div`
    display: flex;
    flex: 1;
    gap: var(--arc-space-3);
`;

const CreateReportButton = (props) => (
    <Button
        isTextTransformNone
        variant="solid"
        leadingVisual="Add"
        testId={testIds.NEW_REPORT_BUTTON}
        {...props}
    >
        {strings.ReportsDashboardContainer.newReport}
    </Button>
);
const ReportsDashboardContainer = ({
    searchIsOpen,
    onSearchToggleClick,
    onSearchChange,
    openLegacyCreateReportModal,
    openCreateReportModal,
    formatFieldByName,
    searchTabCountsAfterExecutingSavedSearch,
    router,
}) => {
    const [scrollToElement, setScrollToElement] = useState(true);
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: reportsDashboardSearch,
        isScrollToElement: scrollToElement,
    });
    useViewport();

    return (
        <Page>
            <Subheader title={strings.ReportsDashboardContainer.title}>
                <OnlyWithAbility has={abilitiesEnum.REPORTING.CREATE_REPORT_FROM_DASHBOARD}>
                    <FeatureFlagged
                        flag={'RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED'}
                        fallback={<CreateReportButton onClick={openLegacyCreateReportModal} />}
                    >
                        <CreateReportButton onClick={openCreateReportModal} />
                    </FeatureFlagged>
                </OnlyWithAbility>
            </Subheader>
            <ScrollableUnderSubheader>
                <SearchAndFilterBar>
                    <SearchWrapper>
                        <SearchBox {...reportsDashboardSearch.form}>
                            <RRFAsyncText
                                path="quickSearchQuery"
                                textInputOnly={true}
                                placeholder={`${formatFieldByName(
                                    REPORT_REPORTING_EVENT_NUMBER
                                )}, ${formatFieldByName(ARREST_LOCAL_ID)}, ${formatFieldByName(
                                    CITATION_CITATION_NUMBER
                                )}`}
                                asyncAction={onSearchChange}
                            />
                        </SearchBox>
                        <FilterButton
                            className={buttonTypes.SECONDARY}
                            active={searchIsOpen}
                            inactiveIcon={
                                <ToggleIcon size={iconSizes.MEDIUM} type={iconTypes.FILTER} />
                            }
                            onClick={() => {
                                onSearchToggleClick();
                                setScrollToElement(false);
                            }}
                            testId={testIds.REPORTS_DASHBOARD_FILTERS_TOGGLE_BUTTON}
                        >
                            Filters
                        </FilterButton>
                    </SearchWrapper>
                    <SavedSearchModal
                        searchModule={reportsDashboardSearch}
                        onSavedSearchExecuteSuccess={searchTabCountsAfterExecutingSavedSearch}
                    />
                </SearchAndFilterBar>
                {searchIsOpen && <ReportsDashboardSearchForm />}
                <ReportsDashboardSearchFilters />
                <ReportsDashboardSearchResults
                    getCurrentScrollPosition={getCurrentScrollPosition}
                    setScrollToElement={setScrollToElement}
                />
            </ScrollableUnderSubheader>
        </Page>
    );
};

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSearchChange: () => dispatch(submitReportsDashboardSearch()),
    openLegacyCreateReportModal: () => dispatch(openBox({ name: boxEnum.CREATE_REPORT_MODAL }, { reportDefinitionIds: undefined })),
    openCreateReportModal: () => dispatch(openCreateReportModal()),
    searchTabCountsAfterExecutingSavedSearch: (result) =>
        dispatch(searchTabCountsAfterExecutingSavedSearch(result)),
    setScrollPosition: (value) =>
        dispatch(reportsDashboardSearch.actionCreators.setScrollPosition(value)),
    setTriggerScroll: (value) =>
        dispatch(reportsDashboardSearch.actionCreators.setTriggerScroll(value)),
});

export default compose(
    withState('searchIsOpen', 'setSearchIsOpen', false),
    withState('saveSearchIsOpen', 'setSaveSearchIsOpen', false),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onSearchToggleClick({ setScrollPosition, setSearchIsOpen, setTriggerScroll }) {
            setScrollPosition(0);
            setTriggerScroll(true);
            return () => setSearchIsOpen((isOpen) => !isOpen);
        },
    })
)(ReportsDashboardContainer);
