import React, { useState } from 'react';
import { cssVar, HStack, IconButton, Text, VStack } from 'arc';
import styled from 'styled-components';
import { AttributeTypeEnum, EFileNameSearchFilter } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { usePreloadAttributes } from '~/client-common/core/hooks/usePreloadAttributes';
import { ImportInvolvedProfilesSidePanel } from '../sidepanel/ImportInvolvedProfilesSidePanel';
import { InvolvedProfileLinkType } from '../../../types';
import { EFileProfilesGrid } from './EFileProfilesGrid';
import { EFileProfileRemoveDeactivateModal } from './EFileProfileRemoveDeactivateModal';
import { EFileProfilesSearchForm } from './EFileProfilesSearchForm';

const strings = componentStrings.eFiles.profiles;

interface HeaderProps {
    linkType: InvolvedProfileLinkType;
    isFilterOpen: boolean;
    setIsFilterOpen: (isFilterOpen: boolean) => void;
}

const Header = ({ linkType, isFilterOpen, setIsFilterOpen }: HeaderProps) => {
    const toggleFilterPanelVisibility = () => {
        setIsFilterOpen(!isFilterOpen);
    };
    return (
        <HStack justify="space-between" align="start" width="100%">
            <Text variant="headingMd">{strings.names[linkType]}</Text>
            <HStack>
                <IconButton
                    aria-label="Filter"
                    icon="Filter"
                    size="md"
                    variant="outline"
                    onClick={toggleFilterPanelVisibility}
                />
                <ImportInvolvedProfilesSidePanel linkType={linkType} />
            </HStack>
        </HStack>
    );
};

const FormVisibilityWrapper = styled.div`
    display: ${({ visibility }: { visibility: boolean }) => (visibility ? 'block' : 'none')};
`;

export const EFileProfilesPage = ({
    eFileId,
    linkType,
}: {
    eFileId: number;
    linkType: InvolvedProfileLinkType;
}) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filters, setFilters] = useState<EFileNameSearchFilter>();
    usePreloadAttributes([AttributeTypeEnum.ORGANIZATION_TYPE.name]);

    return (
        <VStack height="100%" gap={cssVar('arc.space.3')}>
            <Header
                linkType={linkType}
                isFilterOpen={isFilterOpen}
                setIsFilterOpen={setIsFilterOpen}
            />
            <FormVisibilityWrapper visibility={isFilterOpen}>
                <EFileProfilesSearchForm linkType={linkType} setFilters={setFilters} />
            </FormVisibilityWrapper>
            <EFileProfilesGrid eFileId={eFileId} linkType={linkType} filters={filters} />
            <EFileProfileRemoveDeactivateModal />
        </VStack>
    );
};
