import { useSelector } from 'react-redux';
import { includes } from 'lodash';
import { ProductModuleEnumType } from '@mark43/rms-api';
import React, { PropsWithChildren } from 'react';
import { productModulesSelector } from '../state/data';

type ProductModuledProps = {
    fallback?: JSX.Element;
    productModule: ProductModuleEnumType;
    testId?: string;
};

function ProductModuled({
    children,
    fallback,
    productModule,
    testId,
}: PropsWithChildren<ProductModuledProps>): JSX.Element | null {
    const productModules = useSelector(productModulesSelector);
    const isModuleIncluded = includes(productModules, productModule);

    if (!isModuleIncluded && !fallback) {
        return null;
    }

    return <span data-testid={testId}>{isModuleIncluded ? children : fallback}</span>;
}

export default ProductModuled;
