import React, { createRef } from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { map, sortBy, size, isFunction } from 'lodash';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import {
    sortedLocationBundlesForLocationEntityLinksWhereSelector,
    locationBundlesForUnsavedLocationEntityLinksSelector,
} from '~/client-common/core/domain/locations/state/ui';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { removeLocationEntityLink } from '~/client-common/core/domain/location-entity-links/state/data';
import { DISPLAY_ONLY_LOCATION_LABEL } from '~/client-common/core/enums/universal/fields';
import testIds from '../../../../../../core/testIds';
import Button, { buttonTypes } from '../../../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../../../../core/components/Icon';
import { LocationSidePanel } from '../../../../../core/locations/components/LocationSidePanel';
import { openErrorModal } from '../../../../../../legacy-redux/actions/boxActions';
import LocationQuickAdd from '../../../../../core/locations/components/LocationQuickAdd';
import { Button as ArcButton } from '../../../../../core/components/Button';
import { LocationSummaryView } from './LocationSummaryView';

const strings = componentStrings.reports.core.LocationSummaryViewWrapper;

const AddButton = styled(Button)`
    padding: 8px 5px;
`;

const LocationSummaryViewWrapperLabel = styled.div`
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: var(--arc-fontSizes-sm);
    margin-bottom: 10px;
`;

/**
 * NOTE: Migration steps:
 *      - See if it makes sense to replace the existing `LocationSummary` component with this one
 *
 * @param {Boolean}     summaryMode                         Whether or not to show the expand/collapse accordion
 * @param {String}      title                               TODO: Deprecating this - please do not use
 * @param {String}      label                               Header to explain what the locations are for (e.g. Tow Vehicle Locations)
 * @param {String}      entityType                          Used to be `parentEntityType`.  Renamed to match
 *                                                              `locationEntityLink` model. This param determines the entity to which
 *                                                              the location will be added
 * @param {String}      entityId                            Used to be `parentEntityId`.  Renamed to match
 *                                                              `locationEntityLink` model. This param determines the id of the entity to which
 *                                                              the location will be added
 * @param {Number}      linkType                            The `linkType` in which we are saving the location to
 * @param {String}      overlayIdSuffix                     If we can have two of the same `LocationSummaryViewWrapper`
                                                                on the page, we need to give each of them a unique identifier,
                                                                or else both will always open
 * @param {Boolean}     allowMultiple                       Whether or not we allow users to add multiple locations
 * @param {Boolean}     dontSave                            TODO: I'm pretty sure we can deprecate this, so please don't use it!
 *                                                              When this is `true`, we just don't persist the entity link.
 *                                                              Instead of using this flag, let's just use `entityId` / `entityType`
 * @param {Function}    onLocationAdd                       Additional function to get called after the location has been saved
 * @param {Function}    onLocationRemove                    Additional function to get called after the location has been removed.
 *                                                          The first argument to this function is the removed location entity link.
 *                                                          The second, optional argument is the list of remaining links.
 * @param {Boolean}     allowNoFixed                        This determines whether or not we can add a `No Fixed` location
 *                                                              TODO: This is only supported for persons right now
 *                                                              so we should build it out when we update persons to use this component
 * @param {Boolean}     locationEntityLinkSummaryVariant    Customize the way the `locationEntitySummaryLink` component renders
 * @param {Function}    additionalLocationContent           Content to be rendered after each location
 * @param {Object}      additionalLocationEntityLinks       If we are working with a new entity, the `locationEntityLink` will not
 *                                                              yet exist in state, so we need to manually render it
 * @param {Boolean}     hideQuickAdd                        Whether to hide the location quick add component
 * @param {Boolean}     showLongitudeAndLatitudeFields      Whether or not to show the location entity links longitude and latitude coordinates
 */
export const LocationSummaryViewWrapper = compose(
    connect(
        createStructuredSelector({
            sortedLocationBundlesForLocationEntityLinksWhere:
                sortedLocationBundlesForLocationEntityLinksWhereSelector,
            locationBundlesForUnsavedLocationEntityLinks:
                locationBundlesForUnsavedLocationEntityLinksSelector,
            formatFieldByName: formatFieldByNameSelector,
        }),
        (dispatch, ownProps) => ({
            removeLocationEntityLink: (locationEntityLinkToRemove, successCallback) =>
                dispatch(
                    removeLocationEntityLink({
                        onSuccess: (locationEntityLink, remainingLocationEntityLinks) => {
                            ownProps.onLocationRemove?.(
                                locationEntityLink,
                                remainingLocationEntityLinks
                            );
                            successCallback();
                        },
                        onError: (err) =>
                            dispatch(
                                openErrorModal({
                                    title: strings.removeErrorModalTitle,
                                    paragraphs: [err.message],
                                })
                            ),
                        locationEntityLinkToRemove,
                    })
                ),
        })
    ),
    withPropsOnChange(
        [
            'sortedLocationBundlesForLocationEntityLinksWhere',
            'locationBundlesForUnsavedLocationEntityLinks',
            'additionalLocationEntityLinks',
            'linkType',
            'entityType',
            'entityId',
        ],
        ({
            sortedLocationBundlesForLocationEntityLinksWhere,
            locationBundlesForUnsavedLocationEntityLinks,
            formatFieldByName,
            additionalLocationEntityLinks,
            linkType,
            entityType,
            entityId,
        }) => ({
            locationBundles: sortBy(
                [
                    ...locationBundlesForUnsavedLocationEntityLinks(additionalLocationEntityLinks),
                    ...sortedLocationBundlesForLocationEntityLinksWhere({
                        linkType,
                        entityType,
                        entityId,
                    }),
                ],
                'locationEntityLink.createdDateUtc'
            ),
            addText: formatFieldByName(DISPLAY_ONLY_LOCATION_LABEL),
        })
    )
)(
    class LocationSummaryViewWrapper extends React.Component {
        constructor(props) {
            super(props);
        }

        saveRef = createRef();

        setAddButton = (ref) => (this.addButton = ref);

        focusAddButton = () => {
            if (this.addButton) {
                if (this.addButton.button) {
                    // Remove this when ARC_RELEASE_CYCLE_ONE_COMPONENTS is globally enabled
                    this.addButton.button.focus();
                } else {
                    this.addButton.focus();
                }
            }
        };

        // If we are missing both and `entityId` and an `entityType`
        // then don't create an entityLink
        persistEntityLink() {
            return this.props.entityId && this.props.entityType;
        }

        getLocationOverlayId() {
            const { linkType, entityId, overlayIdSuffix } = this.props;
            return `${overlayIdEnum.LOCATION_SUMMARY_VIEW}.${linkType}.${entityId || 0}${
                overlayIdSuffix ? `.${overlayIdSuffix}` : ''
            }`;
        }

        shouldShowButtons() {
            const { summaryMode, allowMultiple, locationBundles } = this.props;
            return (
                // If summary mode, don't show buttons
                !summaryMode &&
                // If we support multiple locations, show button
                // If no locations have been added yet, show button
                (allowMultiple || !locationBundles.length)
            );
        }

        renderButtons(open, setCancelFocusRef) {
            const { addText, hideQuickAdd, entityId, entityType, linkType, onLocationAdd } =
                this.props;
            const shouldShowButtons = this.shouldShowButtons();
            const locationOverlayId = this.getLocationOverlayId();

            return (
                <div>
                    {shouldShowButtons && (
                        <FeatureFlagged
                            flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
                            fallback={
                                <AddButton
                                    testId={testIds.LOCATION_SUMMARY_VIEW_ADD_LOCATION}
                                    className={buttonTypes.ICON_LINK}
                                    onClick={() => {
                                        open();
                                        setCancelFocusRef(this.addButton);
                                    }}
                                    iconLeft={iconTypes.ADD}
                                    ref={(ref) => {
                                        this.setAddButton(ref);
                                    }}
                                >
                                    {addText}
                                </AddButton>
                            }
                        >
                            <ArcButton
                                testId={testIds.LOCATION_SUMMARY_VIEW_ADD_LOCATION}
                                onClick={() => {
                                    open();
                                    setCancelFocusRef(this.addButton);
                                }}
                                leftIcon="Add"
                                variant="ghost"
                                style={{ paddingLeft: 0 }}
                                ref={(ref) => {
                                    this.setAddButton(ref);
                                }}
                            >
                                {addText}
                            </ArcButton>
                        </FeatureFlagged>
                    )}
                    {shouldShowButtons && !hideQuickAdd && (
                        <LocationQuickAdd
                            ownerType={entityType}
                            ownerId={entityId}
                            linkType={linkType}
                            onSaveSuccess={(location, modelEntityLink) => {
                                onLocationAdd?.(location, modelEntityLink);
                            }}
                            locationOverlayId={locationOverlayId}
                            setCancelFocusRef={setCancelFocusRef}
                        />
                    )}
                </div>
            );
        }

        handleRemove = (e, locationEntityLink, onSuccess) => {
            const { onLocationRemove, removeLocationEntityLink } = this.props;
            if (this.persistEntityLink()) {
                return removeLocationEntityLink(locationEntityLink, () => {
                    this.focusAddButton();
                    if (isFunction(onSuccess)) {
                        onSuccess();
                    }
                });
            } else if (onLocationRemove) {
                const retValue = onLocationRemove(locationEntityLink);
                if (isFunction(onSuccess)) {
                    onSuccess();
                }
                return Promise.resolve(retValue);
            }
        };

        render() {
            const {
                locationBundles,
                summaryMode,
                linkType,
                entityType,
                entityId,
                label,
                additionalLocationContent,
                locationEntityLinkSummaryVariant,
                hideCountry,
                hideQuickAdd,
                showLongitudeAndLatitudeFields,
            } = this.props;

            return (
                <div data-test-id={testIds.LOCATION_SUMMARY_VIEW}>
                    {!!label && (!!size(locationBundles) || !summaryMode) && (
                        <LocationSummaryViewWrapperLabel>{label}</LocationSummaryViewWrapperLabel>
                    )}
                    {map(locationBundles, (locationBundle, index) => (
                        <LocationSummaryView
                            saveRef={this.saveRef}
                            onRemove={this.handleRemove}
                            key={index}
                            locationBundle={locationBundle}
                            index={index}
                            summaryMode={summaryMode}
                            locationEntityLinkSummaryVariant={locationEntityLinkSummaryVariant}
                            hideQuickAdd={hideQuickAdd}
                            hideCountry={hideCountry}
                            additionalLocationContent={additionalLocationContent}
                            showLongitudeAndLatitudeFields={showLongitudeAndLatitudeFields}
                            onLocationAdd={this.props.onLocationAdd}
                        />
                    ))}
                    <LocationSidePanel
                        linkType={linkType}
                        entityType={entityType}
                        entityId={entityId}
                        onSaveSuccess={(location, modelEntityLink) => {
                            // Rely on BE response entityLink over form state
                            const entityLink =
                                (location.entityLinks ?? []).find(
                                    (entityLink) =>
                                        entityLink.linkType === linkType &&
                                        entityLink.entityId === entityId &&
                                        entityLink.entityType === entityType
                                ) ?? modelEntityLink;
                            this.props.onLocationAdd?.(location, entityLink);
                        }}
                        overlayId={this.getLocationOverlayId()}
                        renderButton={({ overlayBase: { open }, setCancelFocusRef }) =>
                            this.renderButtons(open, setCancelFocusRef)
                        }
                        saveRef={this.saveRef}
                        hideRecentLocations={!hideQuickAdd}
                    />
                </div>
            );
        }
    }
);
