import React from 'react';
import { useSelector } from 'react-redux';

import { AttributeTypeEnum } from '@mark43/rms-api';
import { dateTypeOptions } from '~/client-common/core/dates/utils/dateHelpers';
import { OFFENSE_TYPE_OFFENSE_CODE_FLAGS } from '~/client-common/core/domain/offense-codes/constants';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import useFields, { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import {
    combinedSubdivisionsLabelSelector,
    globalSequenceNumberLabelSelector,
} from '~/client-common/core/fields/state/config';
import {
    DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL,
    DISPLAY_ONLY_CASE_TITLE_LABEL,
} from '~/client-common/core/enums/universal/fields';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import Row from '../../../core/components/Row';
import { RRFApprovalStatusForCaseSelect } from '../../../core/forms/components/selects/ApprovalStatusForCaseSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFCaseDefinitionSelect } from '../../../core/forms/components/selects/CaseDefinitionSelect';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFOffenseCodeSelect } from '../../../core/forms/components/selects/OffenseCodeSelect';
import { RRFSelect } from '../../../core/forms/components/selects/Select';
import { RRFText } from '../../../core/forms/components/Text';
import { SubdivisionsSearch } from '../../../search/core/components/SubdivisionsSearch';

import fieldsetViewModel from '../state/forms/myCasesSearchCaseInformationFieldset';

/**
 * Fieldset for case information in the All Cases search form.
 */
const MyCasesSearchCaseInformationFieldset = (): JSX.Element => {
    const combinedSubdivisionsLabel = useSelector(combinedSubdivisionsLabelSelector);
    const globalSequenceNumberLabel = useSelector(globalSequenceNumberLabelSelector);

    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();
    const caseEnhancementsEnabled = useSelector(applicationSettingsSelector)
        .RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE;
    const fieldDisplayNames = useFields([
        DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL,
        DISPLAY_ONLY_CASE_TITLE_LABEL,
    ]);

    const caseInfoRowWidth = !!caseEnhancementsEnabled ? 144 : 196;

    return (
        <RRFFieldset path="caseInformation" title={fieldsetViewModel.title(caseFieldName)}>
            <Row>
                <RRFSelect
                    path="dateType"
                    options={dateTypeOptions}
                    width={254}
                    clearable={false}
                />
                <RRFDateRangePicker
                    label={fieldDisplayNames.DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL}
                    width={254}
                    includeTime={true}
                    withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                    toDatePeriodOptions={['P1M', 'P1Y']}
                />
                <RRFBooleanSelect path="isOverdue" width={80} />
            </Row>
            <Row>
                <RRFCaseDefinitionSelect
                    width={caseInfoRowWidth}
                    path="caseDefinitionId"
                    includeExpired={true}
                />
                <RRFApprovalStatusForCaseSelect
                    width={caseInfoRowWidth}
                    path="approvalStatuses"
                    multiple={true}
                />
                <RRFAttributeSelect
                    width={caseInfoRowWidth}
                    path="currentStatusAttrIds"
                    attributeType="CASE_STATUS"
                    multiple={true}
                    includeExpired={true}
                />
                <div>
                    <RRFText
                        path="caseTitle"
                        label={fieldDisplayNames.DISPLAY_ONLY_CASE_TITLE_LABEL}
                        width={caseInfoRowWidth}
                    />
                </div>
                <FeatureFlagged flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE">
                    <RRFText path="caseNumber" width={caseInfoRowWidth} />
                </FeatureFlagged>
            </Row>
            <Row>
                <FeatureFlagged
                    flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE"
                    fallback={<RRFText path="reportingEventNumber" width={196} />}
                >
                    <RRFText
                        path="reportIdentifier"
                        label={globalSequenceNumberLabel}
                        width={196}
                    />
                </FeatureFlagged>
                <RRFOffenseCodeSelect
                    multiple={true}
                    flags={OFFENSE_TYPE_OFFENSE_CODE_FLAGS}
                    includeExpired={true}
                    width={196}
                    path="offenseCodeIds"
                />
                <FeatureFlagged
                    flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED"
                    fallback={
                        <RRFAttributeSelect
                            attributeType={[
                                'SUBDIVISION_DEPTH_1',
                                'SUBDIVISION_DEPTH_2',
                                'SUBDIVISION_DEPTH_3',
                                'SUBDIVISION_DEPTH_4',
                                'SUBDIVISION_DEPTH_5',
                            ]}
                            multiple={true}
                            includeExpired={true}
                            width={196}
                            label={combinedSubdivisionsLabel}
                            path="subdivisionAttrIds"
                        />
                    }
                />
                <RRFAttributeSelect
                    attributeType={AttributeTypeEnum.SECURITY_CLASSIFICATION.name}
                    length="md"
                    path="securityClassificationAttrId"
                    includeExpired
                />
                <SubdivisionsSearch width={196} />
            </Row>
        </RRFFieldset>
    );
};

export default MyCasesSearchCaseInformationFieldset;
