import { createSelector } from 'reselect';

import { nameItemLinksSelector } from '../../../name-item-links/state/data';
import { formatFieldByNameSelector } from '../../../../fields/state/config';
import { formatNameReportLinkTypeId } from '../../../../../helpers/linkTypesHelpers';
import { formatInvolvement, getInvolvement } from '../../../../../helpers/nameReportLinksHelpers';

export const formatNameReportLinkTypeIdSelector = createSelector(
    formatFieldByNameSelector,
    (formatFieldByName) => (linkTypeId) => {
        return formatNameReportLinkTypeId(linkTypeId, formatFieldByName);
    }
);

export const formatInvolvementSelector = createSelector(
    formatFieldByNameSelector,
    (formatFieldByName) => (linkTypes, sortedSubjectTypeAttrIds, indexedAttributes) => {
        return formatInvolvement(
            linkTypes,
            sortedSubjectTypeAttrIds,
            indexedAttributes,
            formatFieldByName
        );
    }
);

export const getInvolvementSelector = createSelector(
    formatFieldByNameSelector,
    nameItemLinksSelector,
    (formatFieldByName, nameItemLinks) => (nameReportLinks, reportId, nameIds, indexedAttributes) => {
        return getInvolvement(
            nameReportLinks,
            reportId,
            nameIds,
            indexedAttributes,
            formatFieldByName,
            nameItemLinks
        );
    }
);
