import React from 'react';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { isUserRoleFormat } from '~/client-common/core/domain/roles/state/data';
import { TableCellComponentProps } from '../tableTypes';
import { CaseTaskViewModel } from '../../../../../modules/cases/task-list/state/ui/types';
import { isColumnKeyPropObject } from '../tableHelpers';

type TaskAssignedToCellProps = TableCellComponentProps<CaseTaskViewModel>;

function TaskAssignedToCell(props: TaskAssignedToCellProps): JSX.Element | null {
    if (isColumnKeyPropObject(props)) {
        return null;
    }
    const { assignee } = getViewModelProperties(props);

    return <div>{isUserRoleFormat(assignee) ? assignee.fullName : assignee.default}</div>;
}

export default TaskAssignedToCell;
