import { Text } from 'arc';
import React from 'react';
import { ItemProfile } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { formatRenSequenceNumberSelector } from '../../item-reporting-event-links/state/ui';
import { chainOfCustodyViewModelsForMasterItemIdSelector } from '../../chain-of-custodies/state/ui';

type ItemIDProps = {
    itemProfile: ItemProfile;
};

export const ItemID = ({ itemProfile }: ItemIDProps): JSX.Element | null => {
    const reportId = itemProfile.ownerId;
    const formatRenSequenceNumber = useSelector(formatRenSequenceNumberSelector);
    const chainOfCustodyViewModelsForMasterItemId = useSelector(
        chainOfCustodyViewModelsForMasterItemIdSelector
    );
    const cpsItems = chainOfCustodyViewModelsForMasterItemId(itemProfile.masterItemId);

    if (cpsItems.length === 0) {
        return null;
    }
    const itemId = formatRenSequenceNumber(itemProfile.masterItemId, reportId);

    return <Text variant={'bodyMd'}>{itemId}</Text>;
};
