import { HistoryEvent } from '@mark43/rms-api';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NEXUS_STATE_PROP as HISTORY_EVENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/history-events/state/data';
import { augmentHistoryEvents } from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import { useResource } from '~/client-common/core/hooks/useResource';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import { eFileResource } from '../resources';

export const useEFileHistoryResource = (eFileId: number) => {
    const dispatch = useDispatch();

    const loadHistory = useCallback(() => eFileResource.getEFileHistory(eFileId), [eFileId]);

    const onLoadHistorySuccess = useCallback(
        (historyEvents: HistoryEvent[]) => {
            dispatch(
                withEntityItems(
                    {
                        [HISTORY_EVENTS_NEXUS_STATE_PROP]: augmentHistoryEvents(
                            historyEvents,
                            eFileId
                        ),
                    },
                    { type: 'LOAD_E_FILE_HISTORY_SUCCESS' }
                )
            );
        },
        [dispatch, eFileId]
    );

    return useResource(loadHistory, onLoadHistorySuccess);
};
