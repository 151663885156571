import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { useEFileContext } from './useEFileContext';

export const useEFileTitle = (showTitle = true) => {
    const {
        getters: { efile },
    } = useEFileContext();

    const eFileView = efile.getEFile();

    if (!eFileView) {
        return '';
    }

    const { localId, title } = eFileView.efile;

    return joinTruthyValues([localId ? `#${localId}` : '', showTitle ? title : ''], ' - ');
};
