import { useSelector } from 'react-redux';
import { BriefingSimpleView } from '@mark43/rms-api';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { briefingsDashboardSearch } from '../state/ui';
import { BriefingSearchSqlQueryWithFilters } from '../types';

const {
    currentQuerySelector,
    currentResultsSelector,
    totalCountSelector,
    tableLoadingSelector,
    errorMessageSelector,
    savedSearchesLoadingSelector,
} = briefingsDashboardSearch.selectors;

type ErrorMessage = {
    readOnlyText: string;
    actionText: string;
};

export const useBriefingsDashboardResults = () => {
    const query = useSelector<RootState, BriefingSearchSqlQueryWithFilters>(currentQuerySelector);
    const results = useSelector<RootState, BriefingSimpleView[] | undefined>(
        currentResultsSelector
    );
    const totalSize = useSelector<RootState, number>(totalCountSelector);

    const isTableLoading = useSelector<RootState, boolean>(tableLoadingSelector);
    const errorMessage = useSelector<RootState, ErrorMessage>(errorMessageSelector);

    const savedSearchesLoading = useSelector<RootState, boolean>(savedSearchesLoadingSelector);

    return {
        query,
        results,
        totalSize,
        isLoading: isTableLoading || savedSearchesLoading,
        errorMessage,
    };
};
