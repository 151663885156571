import React, { FC, Ref, useMemo } from 'react';
import { ReactTable } from '@arc/data-table';
import { Box, BoxProps, cssVar } from 'arc';
import keyMirror from 'keymirror';
import { LinkTypesEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';

import testIds from '../../../../../../core/testIds';
import { EFileInvolvedProfileGridRowT, EFileLinkTypesT } from '../../../../types';
import { EFileProfileActionsCell } from '../cells/EFileProfileActionsCell';
import { EFileProfileDataListCell } from '../cells/EFileProfileDataListCell';
import { EFileProfileNameCell } from '../cells/EFileProfileNameCell';

const strings = componentStrings.eFiles.profiles.grid;

export const columnKeys = keyMirror({
    nameData: null,
    identifiers: null,
    locationData: null,
    offenses: null,
    decision: null,
    sentToCPS: null,
    actions: null,
});

const CellWrap: FC<BoxProps> = ({ children, ...props }) => (
    <Box
        whiteSpace="normal"
        lineHeight={cssVar('arc.lineHeights.base')}
        data-test-id={testIds.TABLE_CELL}
        {...props}
    >
        {children}
    </Box>
);

const columnHelper = ReactTable.createColumnHelper<EFileInvolvedProfileGridRowT>();

export const useProfilesColumns = (
    eFileId: number,
    linkType: EFileLinkTypesT,
    actionsRef: Ref<HTMLDivElement>
) => {
    const offenseDisplayName = useOffenseFieldName();

    return useMemo(
        () => [
            columnHelper.accessor(columnKeys.nameData, {
                id: columnKeys.nameData,
                header: strings.columns.name,
                cell: ({ getValue }) => (
                    <CellWrap>
                        <EFileProfileNameCell nameData={getValue()} />
                    </CellWrap>
                ),
                enableSorting: true,
                minSize: 220,
                maxSize: 230,
            }),
            columnHelper.accessor(columnKeys.identifiers, {
                id: columnKeys.identifiers,
                header: strings.columns.identifiers,
                cell: ({ getValue }) => (
                    <CellWrap>
                        <EFileProfileDataListCell items={getValue()} />
                    </CellWrap>
                ),
                enableSorting: false,
                maxSize: 145,
                minSize: 130,
            }),
            columnHelper.accessor(columnKeys.locationData, {
                id: columnKeys.locationData,
                header: strings.columns.locationData,
                cell: ({ getValue }) => (
                    <CellWrap>
                        <EFileProfileDataListCell variant="stacked" items={getValue()} />
                    </CellWrap>
                ),
                enableSorting: false,
                minSize: 150,
                maxSize: 150,
            }),
            columnHelper.display({
                id: columnKeys.offenses,
                header: strings.columns.offenses(offenseDisplayName),
                cell: () => <CellWrap />,
                enableSorting: false,
                maxSize: 112,
                minSize: 112,
            }),
            columnHelper.display({
                id: columnKeys.decision,
                header: strings.columns.decision,
                cell: () => <CellWrap />,
                enableSorting: false,
                maxSize: 96,
                minSize: 96,
            }),
            columnHelper.display({
                id: columnKeys.sentToCPS,
                header: strings.columns.sentToCPS,
                cell: () => <CellWrap />,
                enableSorting: false,
                maxSize: 96,
                minSize: 96,
            }),
            columnHelper.display({
                id: columnKeys.actions,
                cell: ({ row }) => (
                    <CellWrap>
                        <EFileProfileActionsCell
                            ref={actionsRef}
                            item={row.original}
                            linkType={linkType}
                            eFileId={eFileId}
                        />
                    </CellWrap>
                ),
                enableSorting: false,
                maxSize: 30,
                minSize: 30,
            }),
        ],
        [actionsRef, eFileId, linkType, offenseDisplayName]
    );
};

export const useProfileColumnsVisibility = (linkType: EFileLinkTypesT) => ({
    [columnKeys.offenses]: linkType === LinkTypesEnum.DEFENDANT_IN_EFILE,
});
