import _, {
    first,
    forEach,
    get,
    identity,
    isNil,
    isString,
    last,
    noop,
    size,
    sortBy,
} from 'lodash';
import clientCommonCreateSearchModule from '~/client-common/redux/modules/search/core/utils/createSearchModule';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import {
    genericAdvancedSearchErrorReadOnlyMessage,
    genericAdvancedSearchErrorActionTextMessage,
    requestTimeoutAdvancedSearchErrorReadOnlyMessage,
    requestTimeoutAdvancedSearchErrorActionTextMessage,
    savedSearchErrorMessages,
} from '~/client-common/configs/advancedSearchConfig';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    sortSavedSearches,
    getSavedSearchById,
    buildNewSavedSearchModel,
    convertSavedSearchQueryToElasticQueryObject,
    convertElasticQueryToSavedSearchQuery,
    buildSavedSearchModelToUpdate,
} from '~/client-common/helpers/advancedSearchHelpers';
import { storeConfigForRelatedRecordsAndEntities } from '~/client-common/helpers/multiAgencyHelpers';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import {
    STORE_ELASTIC_ATTRIBUTE_DETAILS,
    NEXUS_STATE_PROP as ELASTIC_ATTRIBUTE_DETAILS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/elastic-attribute-details/state/data';
import {
    offenseCodesSelector,
    NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/offense-codes/state/data';
import { formatCaseDefinitionByIdSelector } from '~/client-common/core/domain/case-definitions/state/data';
import { formatRoleNameByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import { formatVehicleMakeByIdSelector } from '~/client-common/core/domain/vehicle-makes/state/ui';
import { formatVehicleModelByIdSelector } from '~/client-common/core/domain/vehicle-models/state/ui';
import { formatAtfManufacturerByIdSelector } from '~/client-common/core/domain/etrace-atf/state/ui';
import { formatChainEventTypeByIdSelector } from '~/client-common/core/domain/chain-event-types/state/ui';
import { formatFacilityByIdSelector } from '~/client-common/core/domain/facilities/state/ui';
import { formatElasticStorageLocationByIdSelector } from '~/client-common/core/domain/elastic-storage-locations/state/ui';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import { formatAgencyProfileByIdSelector } from '~/client-common/core/domain/agency-profiles/state/ui';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { formatUcrSummaryOffenseCodeByCodeSelector } from '~/client-common/core/domain/ucr-summary-offense-codes/state/ui';
import {
    formatNibrsOffenseCodeByCodeSelector,
    formatNibrsOffenseCodeByIdSelector,
} from '~/client-common/core/domain/nibrs-offense-codes/state/ui';
import { formatReportDefinitionByIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { formatAbilityByIdSelector } from '~/client-common/core/domain/abilities/state/data';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import { formatLinkTypeByIdSelector } from '../../../../legacy-redux/selectors/linkTypesSelectors';
import { expandMyExports, pollForExports } from '../../../../legacy-redux/actions/exportsActions';
import { openBox, closeBox } from '../../../../legacy-redux/actions/boxActions';
import { scrollToElement } from '../../../../legacy-redux/helpers/navigationHelpers';
import { headerHeight } from '../../../../legacy-redux/configs/globalConfig';
import errors from '../../../../lib/errors';
import { formatUserByIdSelector } from '../../../../legacy-redux/selectors/userSelectors';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../state/ui';
import elasticSearchResource from '../../../../legacy-redux/resources/elasticSearchResource';
import { openConfirmationBar } from '../../../core/confirmation-bar/state/ui';
import getRmsSavedSearchResource from '../resources/rmsSavedSearchResource';
import { recursivelyConvertElasticInvolvedLocationsToInvolvedLocations } from './recursivelyConvertElasticLocationQueries';
import {
    recursivelyConvertQueryDetailObjectsToIds,
    convertAttributeAndOffenseCodeIdsSingularToPlural,
} from './recursivelyConvertQueryDetailObjectsToIds';
import storeRmsSavedSearchesView from './storeRmsSavedSearchesView';
import getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults from './getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults';

const strings = componentStrings.search;
const boxContext = {
    search: {
        name: boxEnum.ADVANCED_SEARCH_LOADING_MODAL,
    },
};

const storeAttributesAndOffenseCodesFromSearchResults = (
    dispatch,
    dependencies,
    state,
    searchResults
) => {
    // search results contain attributes within each result document
    // and not at the top level, which is why we cannot solely rely
    // on `storeConfigForRelatedRecordsAndEntities`
    const { elasticAttributeDetails, offenseCodeViews } =
        getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults(
            // passing in an object because the helper expects to operate on
            // an object which has results keyed by type. The actual key is irrelevant.
            { searchResults },
            { offenseCodes: offenseCodesSelector(state) }
        );

    let action = { type: STORE_ELASTIC_ATTRIBUTE_DETAILS };
    if (elasticAttributeDetails.length) {
        action = dependencies.nexus.withEntityItems(
            {
                [ELASTIC_ATTRIBUTE_DETAILS_NEXUS_STATE_PROP]: elasticAttributeDetails,
            },
            action
        );
    }
    if (offenseCodeViews.length) {
        action = dependencies.nexus.withEntityItems(
            {
                [OFFENSE_CODES_NEXUS_STATE_PROP]: offenseCodeViews,
            },
            action
        );
    }

    // only dispatch action when we meta data for nexus set
    if (action.meta) {
        dispatch(action);
    }
};

/**
 * This functions exists to undo all transformations we apply to our
 * queries when they are executed. This is required because our
 * frontend relies on the legacy shape and converting every usage
 * of this shape isn't feasible right now
 */
const convertSavedSearchQueryShapeToLegacyQueryShape = (savedSearchQuery) =>
    JSON.stringify(
        // see `convertAttributeAndOffenseCodeIdsSingularToPlural` for an explanation
        // why this is necessary
        convertAttributeAndOffenseCodeIdsSingularToPlural(
            // we have to undo all conversions we are doing when saving/executing
            // a query
            recursivelyConvertElasticInvolvedLocationsToInvolvedLocations(
                recursivelyConvertQueryDetailObjectsToIds(JSON.parse(savedSearchQuery), identity),
                {
                    newQueryProp: 'involvedLocations',
                    legacyQueryProp: 'involvedElasticLocations',
                }
            ),
            identity
        )
    );

export const convertSavedSearchQueryShapesToLegacyQueryShape = (savedSearches) =>
    forEach(
        savedSearches,
        (savedSearch) =>
            (savedSearch.query = convertSavedSearchQueryShapeToLegacyQueryShape(savedSearch.query))
    );

function buildSearchActionCreator(
    actionCreators,
    selectors,
    form,
    resourceMethod,
    transformElasticQueryBeforeSearchSelector,
    elasticSearchType
) {
    return (
        { formData, additionalDataForConvertFromFormModel, ...query } = {},
        { cacheBust = false, scroll = true, showLoadingModal = true, autoSave = true } = {}
    ) => {
        return (dispatch, getState, dependencies) => {
            // we use whether or not cacheBust is provided to determine whether
            // or not to show the full screen loading modal, unless manually
            // set
            if (showLoadingModal && cacheBust) {
                dispatch(openBox(boxContext.search));
            }
            dispatch(actionCreators.searchStart());

            const state = getState();

            const elasticQuery =
                formData && form
                    ? // convert form shape to an elastic query which can be sent to the server
                      form.convertFromFormModel(formData, additionalDataForConvertFromFormModel)
                    : formData && !form
                      ? // merge the raw form data passed to us with the existing form state
                        {
                            ...selectors.currentQuerySelector(state).elasticQuery,
                            ...formData,
                        }
                      : // grab the form data from state if it wasn't passed to us explicitly
                        selectors.currentQuerySelector(state).elasticQuery;

            // merge our new query on to the existing current query
            // from state
            const mergedQuery = {
                ...selectors.currentQuerySelector(state),
                ...query,
            };

            // look in our state to determine if we already have results for
            // the provided query
            // if cacheBust was passed we know not to use cachedResults
            const cachedResults =
                !cacheBust && selectors.cachedResultsForQuerySelector(state)(mergedQuery);

            if (cachedResults) {
                return dispatch(
                    actionCreators.searchSuccess(
                        {
                            items: cachedResults,
                            totalCount: selectors.totalCountSelector(state),
                            query: mergedQuery,
                        },
                        scroll
                    )
                );
            }

            const transformedElasticQuery = transformElasticQueryBeforeSearchSelector(state)(
                elasticQuery || {}
            );

            const promise = resourceMethod(
                transformedElasticQuery,
                mergedQuery.from,
                mergedQuery.size,
                mergedQuery.sortKey,
                mergedQuery.sortType,
                dispatch, // TODO tech debt
                false, // hide loading bar
                autoSave ? elasticSearchType : undefined
            )
                .then(storeConfigForRelatedRecordsAndEntities(dispatch))
                .then((searchResults) => {
                    if (cacheBust) {
                        dispatch(closeBox(boxContext.search));
                        // we dispatch a cacheBust on success so that we dont have
                        // an intermittent state where there are no search results
                        // in the ui - we favour a disabled table state instead
                        dispatch(actionCreators.resetState());
                    }

                    storeAttributesAndOffenseCodesFromSearchResults(
                        dispatch,
                        dependencies,
                        getState(),
                        searchResults
                    );

                    return dispatch(
                        actionCreators.searchSuccess(
                            {
                                // Instead of relying on the returned elastic query,
                                // we reuse the one we generated on the client before
                                // it got transformed.
                                // This frees us from having to map between display names and attribute ids
                                // as the submitted query shape differs from the shape the client uses
                                // internally and the http result mirrors the executed query.
                                ...searchResults,
                                query: {
                                    ...searchResults.query,
                                    elasticQuery,
                                },
                            },
                            scroll
                        )
                    );
                })
                .catch(
                    // special case for a timeout error
                    errors.RequestTimeoutError,
                    () => {
                        if (cacheBust) {
                            dispatch(closeBox(boxContext.search));
                        }
                        return dispatch(
                            actionCreators.searchFailure(
                                requestTimeoutAdvancedSearchErrorReadOnlyMessage,
                                requestTimeoutAdvancedSearchErrorActionTextMessage,
                                scroll
                            )
                        );
                    }
                )
                .catch(
                    // this callback determines whether or not we should
                    // catch the error - our spec for the time being is
                    // to catch all 4xx or 5xx errors
                    (err) => {
                        // get the first digit of the error code :(
                        const codeLevel = Number(first(String(get(err, 'code'))));

                        return codeLevel === 4 || codeLevel === 5;
                    },
                    // catch NetworkError as well
                    errors.NetworkError,
                    // handle an actual error we've decided to catch
                    (error) => {
                        if (cacheBust) {
                            dispatch(closeBox(boxContext.search));
                        }
                        let errorMessage = genericAdvancedSearchErrorReadOnlyMessage;
                        if (error && error.message) {
                            errorMessage = error.message.endsWith('.')
                                ? error.message
                                : error.message.concat('. ');
                        }
                        return dispatch(
                            actionCreators.searchFailure(
                                errorMessage,
                                genericAdvancedSearchErrorActionTextMessage,
                                scroll
                            )
                        );
                    }
                );

            // done is necessary so that any errors which reach this point will
            // be raised to the window
            promise.done();

            return promise;
        };
    };
}

/**
 * Export selected search results. If all results are selected, make a
 *   server request with the original elastic query. If only some specific
 *   results are selected, make a server request with an elastic query that
 *   contains only the selected `ids`. In both cases, the `from` and `size`
 *   properties are not included. When the request succeeds, open the My
 *   Exports panel and poll for the exports.
 * @param {number[]} selectedRows Indexes on the current page.
 * @param {boolean}  allResultsSelected
 * @param {number[]} optional list of layout IDs to export
 */
function buildExportResultsActionCreator(
    actionCreators,
    selectors,
    exportResourceMethod,
    transformElasticQueryBeforeSearchSelector
) {
    return (selectedRows, allResultsSelected, printingTemplateIds) => {
        return (dispatch, getState) => {
            dispatch(actionCreators.exportResultsStart());

            const state = getState();
            // build the elastic query depending on the selected results
            const elasticQuery = allResultsSelected
                ? selectors.currentQuerySelector(state).elasticQuery
                : {
                      ids: _(selectors.currentResultsSelector(state))
                          .pick(selectedRows)
                          .map('id')
                          .value(),
                  };
            const transformedElasticQuery =
                transformElasticQueryBeforeSearchSelector(state)(elasticQuery);
            const promise = exportResourceMethod(transformedElasticQuery, printingTemplateIds || [])
                .then((resultExports) => {
                    dispatch(actionCreators.exportResultsSuccess(resultExports));
                    // open My Exports and start polling
                    dispatch(expandMyExports());
                    dispatch(pollForExports());
                })
                .catch(() => {
                    dispatch(
                        actionCreators.exportResultsFailure(strings.SearchExportBar.serverError)
                    );
                });

            // done is necessary so that any errors which reach this point
            // will be raised to the window
            promise.done();

            return promise;
        };
    };
}

function buildLoadSearchExportPrintablesActionCreator(
    actionCreators,
    loadSearchExportPrintablesResourceMethod
) {
    return (ids) => {
        return (dispatch) => {
            dispatch(actionCreators.loadSearchExportPrintablesStart());

            const promise = loadSearchExportPrintablesResourceMethod(ids)
                .then((printables) => {
                    dispatch(actionCreators.loadSearchExportPrintablesSuccess(printables));
                })
                .catch(() => {
                    dispatch(
                        actionCreators.loadSearchExportPrintablesFailure(
                            strings.SearchExportBar.serverError
                        )
                    );
                });

            promise.done();

            return promise;
        };
    };
}

function buildLoadAndExecuteLatestAutoSavedSearch(actionCreators, elasticSearchType) {
    return () => (dispatch) => {
        return getRmsSavedSearchResource()
            .getAutoSavedRmsSavedSearchesViewForElasticSearchType(elasticSearchType)
            .then((rmsSavedSearchesView) => {
                const { savedSearches } = rmsSavedSearchesView;

                if (size(savedSearches) > 0) {
                    const savedSearch = last(sortBy(savedSearches, 'id'));
                    savedSearch.query = convertSavedSearchQueryShapeToLegacyQueryShape(
                        savedSearch.query
                    );

                    // eslint-disable-next-line no-restricted-syntax
                    dispatch(
                        storeRmsSavedSearchesView({
                            rmsSavedSearchesView,
                            action: actionCreators.loadLatestAutoSavedSearchSuccess([savedSearch]),
                        })
                    );
                    return dispatch(actionCreators.executeSavedSearch(savedSearch.id));
                }
            })
            .catch(noop);
    };
}

/**
 * Handle initializing the `SAVED_SEARCH` view, for the specified `elasticSearchType`.  This function will perform a resource
 * `GET` request, to `GET` an array of `SavedSearch` objects from the database, that have the specified `elasticSearchType`.
 * @param {Object[]}  actionCreators     An object array of action creators for this `elasticSearchType`.
 * @param {string}    elasticSearchType  The elastic search type of the `SavedSearch`es to `GET`.
 */
function buildLoadSavedSearchesActionCreator(actionCreators, elasticSearchType) {
    return () => {
        return (dispatch) => {
            dispatch(actionCreators.loadSavedSearchesStart());

            return getRmsSavedSearchResource()
                .getNonAutoSavedRmsSavedSearchesViewForElasticSearchType(elasticSearchType)
                .then((rmsSavedSearchesView) => {
                    const { savedSearches } = rmsSavedSearchesView;

                    // we transform saved searches which could contain query detail objects back to ids,
                    // so that our form pre-filling works, since the FE always expects ids and not query detail objects
                    convertSavedSearchQueryShapesToLegacyQueryShape(savedSearches);

                    // eslint-disable-next-line no-restricted-syntax
                    dispatch(
                        storeRmsSavedSearchesView({
                            rmsSavedSearchesView,
                            action: actionCreators.loadSavedSearchesSuccess(
                                sortSavedSearches(savedSearches)
                            ),
                        })
                    );
                })
                .catch((error) => {
                    const errorMessage =
                        (error && error.message) ||
                        savedSearchErrorMessages.onGetSavedSearchesErrorMessage;
                    dispatch(actionCreators.loadSavedSearchesFailure(errorMessage));
                });
        };
    };
}

/**
 * Perform a resource `executeSavedSearch` `PUT` request.
 * Handle:
 *   1) Get the saved search that was selected for execution, by id.
 *   2) Execute the selected saved search.
 *   3) Update the `savedSearches` ui state array, with the returned result set.
 *   4) Examine the saved search that was selected for execution...
 *      a) If the saved search is stale, then force-reset the Advanced Search form.
 *      b) Else, force-reset the Advanced Search form with the saved search's query properties.
 * @param {Object[]}  actionCreators  An object array of action creators for this `elasticSearchType`.
 * @param {Object[]}  selectors       An object array of action creators for this `elasticSearchType`.
 * @param {int}       savedSearchId   The id of the selected saved search to be actioned on.
 */
function buildExecuteSavedSearchActionCreator(
    actionCreators,
    selectors,
    form,
    transformElasticQueryBeforeSearchSelector
) {
    return (savedSearchId, scroll = true, showNew = false) => {
        return (dispatch, getState, dependencies) => {
            dispatch(actionCreators.executeSavedSearchStart());
            const state = getState();
            const savedSearch = getSavedSearchById(
                selectors.savedSearchesSelector(state),
                savedSearchId
            );
            const { query } = savedSearch;
            const parsedQuery = convertSavedSearchQueryToElasticQueryObject(query);
            const parsedElasticQuery = parsedQuery.elasticQuery;
            const transformedSavedSearch = {
                ...savedSearch,
                // `elasticQuery` is stored as a string. In order to make sure that
                // we send down the correct data (in case the transform depends on state),
                // we have to deserialize, transform and serialize it again
                query: query
                    ? convertElasticQueryToSavedSearchQuery({
                          // parameters like `size`, `from` and `sorts`
                          // are not nested under `elasticQuery`, so we
                          // spread in the original parsed query and just
                          // override the `elasticQuery` property
                          ...parsedQuery,
                          elasticQuery:
                              transformElasticQueryBeforeSearchSelector(state)(parsedElasticQuery),
                      })
                    : query,
            };

            return elasticSearchResource
                .executeSavedSearch(transformedSavedSearch, showNew)
                .then(storeConfigForRelatedRecordsAndEntities(dispatch))
                .then((savedSearchExecuteResult) => {
                    // fill in the form with the saved search fields
                    // This should be done before the success action so that
                    // we instantly get the correct form state when re-rendering
                    // based on a success. This is required for cases where
                    // we want to pre-fill certain form fields when the form
                    // mounts, but only when a saved search hasn't been executd
                    dispatch(
                        form.actionCreators.change(
                            !savedSearchExecuteResult.isQueryStale
                                ? // we use the parsed query here, because the
                                  // query returned from the server does not conform
                                  // with the shape the client expects
                                  parsedElasticQuery
                                : undefined,
                            state
                        )
                    );

                    storeAttributesAndOffenseCodesFromSearchResults(
                        dispatch,
                        dependencies,
                        getState(),
                        savedSearchExecuteResult
                    );

                    dispatch(actionCreators.resetSearchState()); // will reset all except the scroll
                    // Instead of relying on the returned elastic query,
                    // we reuse the parsed saved search.
                    // This frees us from having to map between display names and attribute ids
                    // as the submitted query shape differs from the shape the client uses
                    // internally and the http result mirrors the executed query.
                    dispatch(
                        actionCreators.searchSuccess(
                            {
                                ...savedSearchExecuteResult,
                                query: {
                                    // in case the search does not return a query, we
                                    // still need to have active sorts etc set.
                                    // In order to prevent our code from bailing here,
                                    // we first spread in the original parsed query and
                                    // then override with the results query. While
                                    // we might not have results, the client will
                                    // still function correctly
                                    ...parsedQuery,
                                    ...savedSearchExecuteResult.query,
                                    elasticQuery: parsedElasticQuery,
                                },
                            },
                            scroll
                        )
                    );
                    dispatch(actionCreators.executeSavedSearchSuccess(savedSearch));

                    return savedSearchExecuteResult;
                })
                .catch((error) => {
                    const errorMessage =
                        (error && error.message) ||
                        savedSearchErrorMessages.onExecuteSavedSearchErrorMessage;
                    dispatch(actionCreators.executeSavedSearchFailure(errorMessage));
                });
        };
    };
}

/**
 * Perform a resource `renameSavedSearch` `PUT` request.
 * Note:
 *   1) Ensure that the user actually renamed the save search; if the renamed saved search's name === current saved searches name, then we `noop`.
 * @param {Object[]}  actionCreators              An object array of action creators for this `elasticSearchType`.
 * @param {Object[]}  selectors                   An object array of action creators for this `elasticSearchType`.
 * @param {int}       savedSearchId               The id of the selected saved search to be actioned on.
 * @param {string}    newSavedSearchName          The proposed new saved search name that the user would like to rename the saved search to.
 */
function buildRenameSavedSearchActionCreator(actionCreators, selectors) {
    return (savedSearchId, newSavedSearchName) => {
        return (dispatch, getState) => {
            const state = getState();
            const savedSearch = getSavedSearchById(
                selectors.savedSearchesSelector(state),
                savedSearchId
            );

            if (savedSearch.name !== newSavedSearchName) {
                dispatch(actionCreators.renameSavedSearchStart());

                return getRmsSavedSearchResource()
                    .renameRmsSavedSearch(savedSearch, newSavedSearchName)
                    .then((rmsSavedSearchesView) => {
                        const { savedSearches } = rmsSavedSearchesView;
                        // we transform saved searches which could contain query detail objects back to ids,
                        // so that our form pre-filling works, since the FE always expects ids and not query detail objects
                        const savedSearch = first(
                            convertSavedSearchQueryShapesToLegacyQueryShape(savedSearches)
                        );
                        dispatch(actionCreators.renameSavedSearchSuccess(savedSearch));
                    })
                    .catch((error) => {
                        const errorMessage =
                            (error && error.message) ||
                            savedSearchErrorMessages.onRenameSavedSearchErrorMessage;
                        dispatch(
                            actionCreators.savedSearchFailure({
                                savedSearchId: savedSearch.id,
                                message: errorMessage,
                            })
                        );
                    });
            } else {
                dispatch(actionCreators.closeSavedSearchRenameForm());
            }
        };
    };
}

/**
 * Perform a resource `deleteSavedSearch` `DELETE` request.
 * Function will delete the saved search from the ui state's `savedSearches` array.
 * @param {Object[]}  actionCreators  An object array of action creators for this `elasticSearchType`.
 * @param {Object[]}  selectors       An object array of action creators for this `elasticSearchType`.
 * @param {int}       savedSearchId   The id of the selected saved search to be actioned on.
 */
function buildDeleteSavedSearchActionCreator(actionCreators) {
    return (savedSearchId) => {
        return (dispatch) => {
            dispatch(actionCreators.deleteSavedSearchStart());

            return elasticSearchResource
                .deleteSavedSearch(savedSearchId)
                .then((savedSearchDeleteResult) => {
                    if (savedSearchDeleteResult) {
                        dispatch(actionCreators.deleteSavedSearchSuccess(savedSearchId));
                    } else {
                        dispatch(
                            actionCreators.deleteSavedSearchFailure(
                                savedSearchErrorMessages.onDeleteSavedSearchErrorMessage
                            )
                        );
                    }
                })
                .catch((error) => {
                    const errorMessage =
                        (error && error.message) ||
                        savedSearchErrorMessages.onDeleteSavedSearchErrorMessage;
                    dispatch(actionCreators.deleteSavedSearchFailure(errorMessage));
                });
        };
    };
}

function buildShareSavedSearchActionCreator(actionCreators) {
    return (savedSearch) => {
        return (dispatch) => {
            dispatch(actionCreators.shareSavedSearchStart());

            const sharedSavedSearchReq = { ...savedSearch, isShared: !savedSearch.isShared };

            return elasticSearchResource
                .updateSearch(sharedSavedSearchReq)
                .then((sharedSavedSearchResult) => {
                    const sharedSavedSearchRes = _.head(sharedSavedSearchResult);
                    const message = sharedSavedSearchRes.isShared
                        ? strings.savedSearch.SavedSearch.shareSuccessMessage
                        : strings.savedSearch.SavedSearch.unshareSuccessMessage;

                    if (sharedSavedSearchRes) {
                        dispatch(actionCreators.shareSavedSearchSuccess(sharedSavedSearchRes));
                        dispatch(openConfirmationBar({ message }));
                        return sharedSavedSearchRes;
                    } else {
                        dispatch(
                            actionCreators.saveSearchFailure(
                                savedSearchErrorMessages.onSaveSavedSearchErrorMessage
                            )
                        );
                    }
                })
                .catch((error) => {
                    const errorMessage =
                        (error && error.message) ||
                        savedSearchErrorMessages.onSaveSavedSearchErrorMessage;
                    dispatch(actionCreators.saveSearchFailure(errorMessage));
                });
        };
    };
}

function buildSubscribeSavedSearchActionCreator(actionCreators) {
    return (savedSearch, isSubscribed) => {
        return (dispatch) => {
            dispatch(actionCreators.subscribeSavedSearchStart());

            return getRmsSavedSearchResource()
                .subscribeRmsSavedSearch(savedSearch, isSubscribed)
                .then((rmsSavedSearchesView) => {
                    const { savedSearches } = rmsSavedSearchesView;
                    // we transform saved searches which could contain query detail objects back to ids,
                    // so that our form pre-filling works, since the FE always expects ids and not query detail objects
                    const subscribedSavedSearch = first(
                        convertSavedSearchQueryShapesToLegacyQueryShape(savedSearches)
                    );

                    dispatch(actionCreators.subscribeSavedSearchSuccess(subscribedSavedSearch));
                    return subscribedSavedSearch;
                })
                .catch((error) => {
                    const { message } = error;
                    dispatch(
                        actionCreators.savedSearchFailure({
                            savedSearchId: savedSearch.id,
                            message,
                        })
                    );
                });
        };
    };
}

function buildSetFavoriteSavedSearchActionCreator(actionCreators) {
    return (savedSearchId, favorite) => {
        return (dispatch) => {
            dispatch(actionCreators.setFavoriteSavedSearchStart());
            return getRmsSavedSearchResource()
                .favoriteRmsSavedSearch(savedSearchId, favorite)
                .then((rmsSavedSearchesView) => {
                    const { savedSearches } = rmsSavedSearchesView;
                    const favoritedSavedSearch = first(
                        convertSavedSearchQueryShapesToLegacyQueryShape(savedSearches)
                    );
                    dispatch(actionCreators.setFavoriteSavedSearchSuccess(favoritedSavedSearch));
                    return favoritedSavedSearch;
                })
                .catch((error) => {
                    const { message } = error;
                    dispatch(
                        actionCreators.savedSearchFailure({
                            savedSearchId,
                            message,
                        })
                    );
                });
        };
    };
}

/**
 * Perform a resource `saveSearch` `POST` request.
 * Function will save the saved search to the ui state's `savedSearches` array.
 * @param {Object[]}  actionCreators  An object array of action creators for this `elasticSearchType`.
 */
function buildSaveSearchActionCreator(
    actionCreators,
    elasticSearchType,
    transformElasticQueryBeforeSearchSelector
) {
    return (elasticQuery, args) => {
        const { name, isShared, entityPermissions, savedSearchSharingEnabled, onSaveSuccess } =
            args || {};
        const successMessage = savedSearchSharingEnabled
            ? strings.savedSearch.SavedSearch.successModalMessage
            : strings.savedSearch.SavedSearch.successMessage;

        return (dispatch, getState) => {
            dispatch(actionCreators.saveSearchStart());
            const newSavedSearch = buildNewSavedSearchModel(
                transformElasticQueryBeforeSearchSelector(getState())(elasticQuery),
                elasticSearchType,
                { name, isShared, entityPermissions }
            );

            return elasticSearchResource
                .saveSearch(newSavedSearch)
                .then((newSavedSearchResult) => {
                    const savedSearch = _.head(newSavedSearchResult);

                    if (savedSearch) {
                        dispatch(actionCreators.saveSearchSuccess(savedSearch));
                        dispatch(
                            openConfirmationBar({
                                message: successMessage,
                            })
                        );
                        if (onSaveSuccess) {
                            onSaveSuccess(savedSearch.id);
                        }
                        return savedSearch;
                    } else {
                        dispatch(
                            actionCreators.saveSearchFailure(
                                savedSearchErrorMessages.onSaveSavedSearchErrorMessage
                            )
                        );
                    }
                })
                .catch((error) => {
                    const errMessage =
                        (error && error.message) ||
                        savedSearchErrorMessages.onSaveSavedSearchErrorMessage;
                    dispatch(actionCreators.saveSearchFailure(errMessage));
                    // TODO: replace with ARC error banner when it is supported
                    dispatch(
                        openConfirmationBar({
                            message: `Error: ${errMessage}`,
                        })
                    );
                });
        };
    };
}

function buildUpdateSavedSearchActionCreator(
    actionCreators,
    selectors,
    elasticSearchType,
    transformElasticQueryBeforeSearchSelector
) {
    return (elasticQuery, args = {}) => {
        return (dispatch, getState) => {
            dispatch(actionCreators.updateSavedSearchStart());
            const savedSearches = selectors.savedSearchesSelector(getState());
            const executedSaveSearchToUpdateId =
                args.savedSearchId || selectors.executedSavedSearchToUpdateSelector(getState()).id;

            const elasticQueryObj = isString(elasticQuery)
                ? convertSavedSearchQueryToElasticQueryObject(elasticQuery)
                : elasticQuery;

            const searchToUpdate = buildSavedSearchModelToUpdate(
                savedSearches,
                executedSaveSearchToUpdateId,
                transformElasticQueryBeforeSearchSelector(getState())(elasticQueryObj),
                elasticSearchType,
                args
            );

            return elasticSearchResource
                .updateSearch(searchToUpdate)
                .then((updatedSearchResult) => {
                    const savedSearch = _.head(updatedSearchResult);

                    if (savedSearch) {
                        dispatch(actionCreators.updateSavedSearchSuccess(savedSearch));
                        dispatch(
                            openConfirmationBar({
                                message: strings.savedSearch.SavedSearch.updateSuccessMessage,
                            })
                        );
                        if (args.onUpdateSuccess) {
                            args.onUpdateSuccess(savedSearch.id);
                        }
                        return savedSearch;
                    } else {
                        dispatch(
                            actionCreators.saveSearchFailure(
                                savedSearchErrorMessages.onUpdateSavedSearchErrorMessage
                            )
                        );
                    }
                })
                .catch((err) => {
                    dispatch(actionCreators.saveSearchFailure(err.message));
                    // TODO: replace with ARC error banner when it is supported
                    dispatch(
                        openConfirmationBar({
                            message: `Error: ${err.message}`,
                        })
                    );
                });
        };
    };
}

/**
 * Performs any necessary navigation when click a search result and fires
 *   an action to higlight the row which was clicked
 * @param  {Object[]} actionCreators An object array of action creators for this `elasticSearchType`
 * @param  {function} a function which accepts a search result and returns a path (string) which can
 *   be used to navigate to the appropriate page in cobalt
 */
function buildOpenSearchResultActionCreator(actionCreators, searchResultToRoutePath) {
    return (searchResult, rowIndex, router, scrollPosition) => {
        return (dispatch) => {
            if (!isNil(scrollPosition)) {
                dispatch(actionCreators.setScrollPosition(scrollPosition));
            }

            if (searchResult) {
                router.push(searchResultToRoutePath(searchResult));
            }

            return dispatch(actionCreators.highlightRows([rowIndex]));
        };
    };
}

export default function createSearchModule(args) {
    return clientCommonCreateSearchModule({
        formatAbilityByIdSelector,
        formatAttributeByIdSelector,
        formatLinkTypeByIdSelector,
        formatNibrsCodeByCodeSelector: formatNibrsOffenseCodeByCodeSelector,
        formatNibrsOffenseCodeByIdSelector,
        formatOffenseCodeByIdSelector,
        formatRoleNameByRoleIdSelector,
        formatCaseDefinitionByIdSelector,
        formatReportDefinitionByIdSelector,
        formatUcrCodeByCodeSelector: formatUcrSummaryOffenseCodeByCodeSelector,
        formatUserByIdSelector,
        formatAgencyProfileByIdSelector,
        // department selects only appear in a multi-agency setup,
        // so using the consortium department links as the source for the selected
        // department's name is fine
        formatDepartmentByIdSelector: departmentNameFromConsortiumLinksByDepartmentIdSelector,
        buildSearchActionCreator,
        buildExportResultsActionCreator,
        buildLoadSearchExportPrintablesActionCreator,
        buildLoadSavedSearchesActionCreator,
        buildLoadAndExecuteLatestAutoSavedSearch,
        buildExecuteSavedSearchActionCreator,
        buildDeleteSavedSearchActionCreator,
        buildSaveSearchActionCreator,
        buildUpdateSavedSearchActionCreator,
        buildRenameSavedSearchActionCreator,
        buildOpenSearchResultActionCreator,
        buildSubscribeSavedSearchActionCreator,
        buildSetFavoriteSavedSearchActionCreator,
        buildShareSavedSearchActionCreator,
        formatVehicleMakeByIdSelector,
        formatVehicleModelByIdSelector,
        formatAtfManufacturerByIdSelector,
        formatChainEventTypeByIdSelector, // evidence
        formatElasticStorageLocationByIdSelector, // evidence
        formatFacilityByIdSelector, // evidence
        formatFieldByNameSelector,
        scrollToElement: (selector, scrollDuration) =>
            // by default, when scrolling to the search results, offset by the
            // header height with a few extra pixels (5) for leeway (otherwise
            // the scroll triggers clippable and jitters indefinitely)
            scrollToElement({ selector, scrollDuration, offset: headerHeight + 5 }),
        transformElasticQueryBeforeSearchSelector: transformElasticQueryIdsToDisplayValuesSelector,
        currentDepartmentDateFormatterSelector,
        ...args,
    });
}
