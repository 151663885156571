import React from 'react';
import { ItemProfile } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { propertyTitleForItemProfileSelector } from '../state/ui';

type PropertyTitleProps = {
    itemProfile: ItemProfile;
    className?: string;
};

/**
 * Use this component to format any item profile title.
 *   Mirrors logic in formatTitleForPropertyRecord. If dealing with raw item profile models,
 *   try to use this component. If dealing with item profile view models,
 *   can use formatTitleForPropertyRecord.
 *   Using formatAttributeById instead of FormattedAttribute because of complexity around
 *   conditional string manipulation.
 *   Server mirrors logic to fill the ucr property's additional notes.
 *   mark43/cobalt-rms/reporting/reporting-services/src/main/java/mark43/reporting/services/ucr/UcrPrefillService.java.
 */
function PropertyTitle({ itemProfile, className }: PropertyTitleProps): JSX.Element {
    const propertyTitleForItemProfile = useSelector(propertyTitleForItemProfileSelector);
    const propertyTitle = propertyTitleForItemProfile(itemProfile);

    return <span className={className}>{propertyTitle}</span>;
}

export default PropertyTitle;
