import React from 'react';
import styled from 'styled-components';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { useFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { BodyMediumText } from '../../components/typography';

const strings = componentStrings.core.InvolvedLocations;

const BodyMediumLinkText = styled(BodyMediumText)`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const InvolvedLocationsPill = ({ ren, onClick }: { ren: string; onClick: () => void }) => {
    const { singular: renDisplayName } = useFieldName(REPORT_REPORTING_EVENT_NUMBER);

    return (
        <BodyMediumLinkText onClick={onClick} color="brand">
            {strings.labelWithRen(ren, renDisplayName)}
        </BodyMediumLinkText>
    );
};
