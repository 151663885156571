import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SavedSearch } from '@mark43/rms-api';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { SearchName } from '../../../search/core/components/SearchName';
import Column from '../../../core/components/Column';
import CasesSearchForm from '../../core/components/CasesSearchForm';
import { myCasesSearch, resetMyCasesSearchForm, submitMyCasesSearch } from '../state/ui';
import TargetProfileSearchFieldset from '../../target-profile/components/TargetProfileSearchFieldset';
import MyCasesSearchPersonnelFieldset from './MyCasesSearchPersonnelFieldset';
import MyCasesSearchCaseInformationFieldset from './MyCasesSearchCaseInformationFieldset';

const MyCasesSearchForm = (): JSX.Element => {
    const currentSavedSearch: SavedSearch | null = useSelector(
        myCasesSearch.selectors.currentSavedSearchSelector
    );
    const query = useSelector(myCasesSearch.selectors.currentQuerySelector);

    const dispatch = useDispatch();

    const onSubmit = useCallback(() => dispatch(submitMyCasesSearch({ autoSave: true })), [
        dispatch,
    ]);

    const onReset = useCallback(() => {
        dispatch(resetMyCasesSearchForm());
        dispatch(myCasesSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(myCasesSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
    }, [dispatch]);

    return (
        <CasesSearchForm
            {...myCasesSearch.form}
            onSubmit={onSubmit}
            onReset={onReset}
            query={query}
            searchModule={myCasesSearch}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={false} />
            <Column width={270}>
                <MyCasesSearchPersonnelFieldset />
            </Column>
            <Column width={620} marginRight={0}>
                <MyCasesSearchCaseInformationFieldset />
            </Column>
            <FeatureFlagged flag="RMS_TARGET_PROFILES_ENABLED">
                <Column width={620} marginRight={0}>
                    <TargetProfileSearchFieldset />
                </Column>
            </FeatureFlagged>
        </CasesSearchForm>
    );
};

/**
 * Search/filter form on the My Cases page. In the UI, this form appears
 *   when the filter button is clicked.
 */
export default MyCasesSearchForm;
