import React from 'react';
import { useSelector } from 'react-redux';
import { reject } from 'lodash';
import { AttributeTypeEnum } from '@mark43/rms-api';

import { vehiclesEnabledSelector } from '~/client-common/core/domain/evidence-department-config/state/data';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import {
    AttributeSelectFilterT,
    AttributeSelectFilter,
    AttributeSelectFilterOption,
} from '../../../core/filters/components';

export const PropertyCategoryFilter: React.FC<Omit<AttributeSelectFilterT, 'attributeType'>> = ({
    includeExpired,
    ...props
}) => {
    const vehiclesEnabled = useSelector(vehiclesEnabledSelector);
    const parentAttributeIdByAttributeId = useSelector(parentAttributeIdByAttributeIdSelector);

    const getOptions = (options: AttributeSelectFilterOption[]) => {
        if (vehiclesEnabled) {
            return options;
        }

        // check both parent and grandparent attribute, because some ITEM_CATEGORY attributes can
        // have child ITEM_CATEGORY attributes, see CHD-2850
        const filteredOptions = reject(options, ({ value }) => {
            const parentAttributeId = parentAttributeIdByAttributeId(Number(value));
            if (parentAttributeId) {
                const parentParentAttributeId = parentAttributeIdByAttributeId(parentAttributeId);
                return (
                    parentAttributeId === globalAttributes.itemType.vehicle ||
                    parentParentAttributeId === globalAttributes.itemType.vehicle
                );
            }
            return parentAttributeId === globalAttributes.itemType.vehicle;
        });
        return filteredOptions;
    };
    return (
        <AttributeSelectFilter
            attributeType={AttributeTypeEnum.ITEM_CATEGORY.name}
            includeExpired={includeExpired}
            filterOptions={(options) => getOptions(options)}
            {...props}
        />
    );
};
