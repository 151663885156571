import React from 'react';
import { noop } from 'lodash';
import { OperationTypeEnum } from '@mark43/rms-api';
import { FilterMenuRenderProps, Flex } from 'arc';
import styled from 'styled-components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import {
    DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_TEMPORARY_LOCATIONS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_LOCATIONS_LABEL,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { selectInputContainerMaxContent } from '../../../core/styles/mixins';
import { DeprecatedFacilityStorageLocationSelect } from '../../../core/forms/components/selects/DeprecatedFacilityStorageLocationSelect';
import FacilityStorageLocationSelect, {
    LocationsValue,
} from '../../../core/forms/components/selects/FacilityStorageLocationSelect';
import Checkbox from '../../../core/forms/components/checkboxes/Checkbox';
import { convertLocationsFormDataToFilterOption } from '../../dashboard/components/filters/manageEvidenceFilterHelpers';

const StyledFacilityStorageLocationSelect = styled(FacilityStorageLocationSelect)`
    ${selectInputContainerMaxContent}
`;

const StyledDeprecatedFacilityStorageLocationSelect = styled(
    DeprecatedFacilityStorageLocationSelect
)`
    ${selectInputContainerMaxContent}
`;

export type FacilityStorageLocationsValue = LocationsValue & {
    excludeTemporaryStorageLocations?: boolean;
    excludeExpiredStorageLocations?: boolean;
};

export const FacilityStorageLocationsFilter: React.FC<
    FilterMenuRenderProps<FacilityStorageLocationsValue>
> = ({ appliedOptions, setAppliedFilters }) => {
    const fields = useFields([
        DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_TEMPORARY_LOCATIONS_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_LOCATIONS_LABEL,
    ]);

    const [formData, setFormData] = React.useState<FacilityStorageLocationsValue>(
        appliedOptions[0]?.value ?? {}
    );

    const handleChange = (value: FacilityStorageLocationsValue) => {
        setFormData((prev) => {
            const updatedFormData = {
                ...prev,
                ...value,
            };

            const newFilterOption = convertLocationsFormDataToFilterOption(updatedFormData);
            setAppliedFilters(newFilterOption ? [newFilterOption] : []);
            return newFilterOption?.value ?? {};
        });
    };

    return (
        <Flex p={2} flexDirection="column" maxWidth="25rem">
            <FeatureFlagged
                flag="STORAGE_LOCATION_SEARCH_IMPROVEMENTS_ENABLED"
                fallback={
                    <>
                        <StyledDeprecatedFacilityStorageLocationSelect
                            label={fields.DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL}
                            multiple={true}
                            excludeExpired={formData.excludeExpiredStorageLocations}
                            fields={{
                                facilityId: {
                                    value: formData.facilityId,
                                    onChange: noop,
                                },
                                storageLocationId: {
                                    value: formData.storageLocationId,
                                    onChange: noop,
                                },
                            }}
                            onChange={(value: LocationsValue) => {
                                handleChange({
                                    facilityId: value.facilityId,
                                    storageLocationId: value.storageLocationId,
                                });
                            }}
                            useStorageLocationIdsInstead={true}
                            requiredPermissions={[
                                OperationTypeEnum.READ.name,
                                OperationTypeEnum.MANAGE.name,
                            ]}
                            menuPortalTarget={document.body}
                        />
                    </>
                }
            >
                <StyledFacilityStorageLocationSelect
                    label={fields.DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL}
                    multiple={true}
                    // TODO: Existing bug, if select an expired location, then select "Exclude Expired Locations" it will visually hide the expired options but it will still be included in the value.
                    excludeExpired={formData.excludeExpiredStorageLocations}
                    fields={{
                        facilityId: {
                            value: formData.facilityId,
                        },
                        storageLocationId: {
                            value: formData.storageLocationId,
                        },
                    }}
                    onChange={(value: LocationsValue) => {
                        handleChange({
                            facilityId: value.facilityId,
                            storageLocationId: value.storageLocationId,
                        });
                    }}
                    maxMenuHeight={350}
                    menuPortalTarget={document.body}
                />
            </FeatureFlagged>

            <Checkbox
                value={formData.excludeExpiredStorageLocations}
                onChange={(value) => {
                    handleChange({ excludeExpiredStorageLocations: !!value });
                }}
                label={fields.DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_LOCATIONS_LABEL}
            />
            <Checkbox
                value={formData.excludeTemporaryStorageLocations}
                onChange={(value) => {
                    handleChange({ excludeTemporaryStorageLocations: !!value });
                }}
                label={fields.DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_TEMPORARY_LOCATIONS_LABEL}
            />
        </Flex>
    );
};
