import { EFileView, EntityTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { useDispatch, useSelector } from 'react-redux';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_E_FILE } from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';

import { useFormGetter } from '../../core/forms/hooks/useFormGetter';
import { loadPermissions } from '../../core/permissions/state/ui';
import { eFileResource } from '../resources/eFileResource';
import {
    DetailsEFileFormDataShapeT,
    convertFromFormModelToEFileUpsert,
} from '../core/state/forms/eFileDetailsForm';
import { useEFileUpdatePermissions } from './useEFileUpdatePermissions';

const strings = componentStrings.eFiles.core.eFileDetailsModal;

const STORE_ATTRIBUTES = 'STORE_E_FILE_ATTRIBUTES';

export const useEFileSubmitForm = () => {
    const dispatch = useDispatch();
    const { getForm } = useFormGetter();
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const updateEFilePermissions = useEFileUpdatePermissions();

    return async (eFileToUpdate: EFileView | undefined) => {
        const form = getForm(RefContextEnum.FORM_E_FILE_DETAILS_MODAL.name);
        const errorMessage = strings.failedToSave(formatFieldByName(DISPLAY_ONLY_E_FILE));

        if (!form || !eFileToUpdate) {
            throw new Error(errorMessage);
        }

        const result = await form.submit();
        const formModel: DetailsEFileFormDataShapeT = result.form.getState().model;

        const eFileUpsertRequest = convertFromFormModelToEFileUpsert(formModel, eFileToUpdate);

        if (!eFileUpsertRequest) {
            throw new Error(errorMessage);
        }

        const eFileView = await eFileResource.updateEFileDetails(eFileUpsertRequest);

        dispatch(
            withEntityItems(
                {
                    [ATTRIBUTES_NEXUS_STATE_PROP]: eFileView.attributes.map(
                        convertAttributeToAttributeView
                    ),
                },
                { type: STORE_ATTRIBUTES }
            )
        );

        if (eFileToUpdate.efile.ownerUserId !== eFileView.efile.ownerUserId) {
            await dispatch(loadPermissions(EntityTypeEnum.E_FILE.name, eFileToUpdate.efile.id));
            await updateEFilePermissions(eFileView.efile, eFileToUpdate.efile);
        }

        return eFileView;
    };
};
