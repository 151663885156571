import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { Box, cssVar } from 'arc';
import { useSelector } from 'react-redux';
import { Fieldset as MFTFieldset } from 'markformythree';
import styled from 'styled-components';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import FormRow from '../../../../../core/forms/components/FormRow';
import { FederatedSearchSidePanel } from '../../../../../federated-search/components/FederatedSearchSidePanel';
import { useOverlayStore } from '../../../../../core/overlays/hooks/useOverlayStore';
import InlineAttachmentsUploader from '../../../../../attachments/core/components/InlineAttachmentsUploader';
import { InlineAttachmentsEntityProfileUploader } from '../../../../../core/names/components/InlineAttachmentsEntityProfileUploader';
import {
    itemSidePanelMasterItemIdSelector,
    itemSidePanelAlwaysHideDexItemsQuerySearchSelector,
} from '../../../state/ui';
import { OpenDexFormPill } from '../OpenDexFormPill';
import { OverrideItemProfileModal } from '../OverrideItemProfileModal';
import { useGetFederatedSearchArticleButtonsConfiguration } from '../hooks';
import withItemCategoryFilterOptions from './withItemCategoryFilterOptions';

const StyledOpenDexFormPill = styled(OpenDexFormPill)`
    margin-top: ${cssVar('arc.space.3')};
    margin-bottom: ${cssVar('arc.space.3')};
`;

function OtherItemFieldset({ helpTextCollisionBoundary, itemCategoryFilterOptions }) {
    const overlayStore = useOverlayStore();
    const {
        federatedSearchButtonsConfiguration,
        handleOverrideProfile,
        handleCancelOverrideProfile,
    } = useGetFederatedSearchArticleButtonsConfiguration();

    const masterItemProfileId = useSelector(itemSidePanelMasterItemIdSelector);
    const alwaysHideDexItemsQuerySearch = useSelector(
        itemSidePanelAlwaysHideDexItemsQuerySearchSelector
    );

    return (
        <>
            <SidePanelSection>
                <MFTFieldset path="otherItem">
                    {!alwaysHideDexItemsQuerySearch && (
                        <FederatedSearchSidePanel
                            buttonsConfiguration={federatedSearchButtonsConfiguration}
                            renderButton={() => (
                                <StyledOpenDexFormPill
                                    onClick={() => overlayStore.open(overlayIdEnum.DEX_SIDE_PANEL)}
                                />
                            )}
                        />
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: cssVar('arc.space.6'),
                            mt: cssVar('arc.space.2'),
                        }}
                    >
                        <InlineAttachmentsUploader
                            entityId={masterItemProfileId}
                            entityType={EntityTypeEnum.ITEM_PROFILE.name}
                            linkType={LinkTypesEnum.ITEM_PROFILE_PHOTO}
                        >
                            {(inlineAttachmentsRenderProps) => (
                                <InlineAttachmentsEntityProfileUploader
                                    {...inlineAttachmentsRenderProps}
                                />
                            )}
                        </InlineAttachmentsUploader>
                        <Box>
                            <ArbiterMFTAttributeSelect
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="itemCategoryAttrId"
                                attributeType={AttributeTypeEnum.ITEM_CATEGORY.name}
                                filterOptions={itemCategoryFilterOptions}
                            />
                            <ArbiterMFTText
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="description"
                            />
                        </Box>
                    </Box>
                    <FormRow>
                        <ArbiterMFTAttributeSelect
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="primaryColorAttrId"
                            attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                            length="md"
                        />
                        <ArbiterMFTAttributeSelect
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="secondaryColorAttrId"
                            attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                            length="md"
                        />
                        <ArbiterMFTText
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="size"
                            length="md"
                        />
                        <ArbiterMFTText
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="serialNumber"
                            length="md"
                        />

                        <ArbiterMFTText
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="itemMake"
                            length="md"
                        />
                        <ArbiterMFTText
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="itemModel"
                            length="md"
                        />
                    </FormRow>
                </MFTFieldset>
            </SidePanelSection>
            <OverrideItemProfileModal
                handleOverrideProfile={handleOverrideProfile}
                handleCancelOverrideProfile={handleCancelOverrideProfile}
                entity="other item"
            />
        </>
    );
}

/**
 * Fieldset in the item entry v2 form that appears only when the item type is other item. It
 *   includes fields from the ItemProfile model.
 */
export default withItemCategoryFilterOptions(globalAttributes.itemType.item)(OtherItemFieldset);
