import React from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../../core/testIds';
import { IconButton } from '../../../../core/components/IconButton';
import useOnSaveSearchToggleHandler from '../../../../search/core/hooks/useOnSaveSearchToggleHandler';
import CreateSavedSearchModal from '../../../../search/saved-search/components/CreateSavedSearchModal';
import { SavedSearchDropdownButton } from '../../../../search/saved-search/components/SavedSearchDropdownButton';
import { evidenceDashboardSearch } from '../../state/ui';

const strings = componentStrings.search.savedSearch.SavedSearchDropdownButton;

export const ManageEvidenceSavedSearchButton = () => {
    const onSaveSearchToggle = useOnSaveSearchToggleHandler(evidenceDashboardSearch);

    return (
        <>
            <SavedSearchDropdownButton
                searchModule={evidenceDashboardSearch}
                buttonElement={
                    <IconButton
                        icon="MoreActions"
                        aria-label={strings.dropdownButton}
                        testId={testIds.SAVED_SEARCH_DROPDOWN_BUTTON}
                    />
                }
            />
            <CreateSavedSearchModal onSave={onSaveSearchToggle} />
        </>
    );
};
