import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';
import classNames from 'classnames';
import { max, map, get, at } from 'lodash';
import useFields, {
    useCaseFieldName,
    useOffenseFieldName,
} from '~/client-common/core/fields/hooks/useFields';
import { caseStatusGlobalAttrIdToChildAttrIdsSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID,
    CASE_CASE_DEFINITION_ID,
    DISPLAY_CASE_SUPERVISORS,
    DISPLAY_ONLY_CASE_SEARCH_CASE_STATUS_LABEL,
} from '~/client-common/core/enums/universal/fields';
import PrimaryLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/PrimaryLocationCell';
import OffenseLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/OffenseLocationCell';
import BatchBanners from '../../batch-operations/components/BatchBanners';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import BulkManageCasesSidePanel from '../../core/components/BulkManageCasesSidePanel';
import { iconTypes } from '../../../core/components/Icon';
import {
    allCasesSearch,
    setCurrentStatusAndUserDepartmentToForm,
    currentElasticQueryCaseStatusGlobalAttrIdSelector,
    searchTabToPathParam,
    casesDashboardTabNameToCountSelector,
    submitAllCasesSearchForm,
} from '../state/ui';
import {
    openCaseDetailsModalFromDashboard,
    openCreateManageCaseModalFromDashboard,
} from '../../core/state/ui';
import { abilitiesEnum } from '../../../core/abilities';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import ResultsHeader from '../../core/components/ResultsHeader';
import CasesResultsFooter from '../../core/components/CasesResultsFooter';
import CasesActionBar from '../../core/components/CasesActionBar';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import { Tooltip } from '../../../core/components/tooltip';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import { ElasticResultRowWithTargetProfileDetails } from '../../core/components/ElasticResultRowWithTargetProfileDetails';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import { Tab } from '../../../../legacy-redux/components/core/Tabs';
import CaseDashboardTabs from '../../core/components/CaseDashboardTabs';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import InvestigatorsCell from '../../core/components/InvestigatorsCell';
import AssignedUnitCell from '../../core/components/AssignedUnitCell';
import SupervisorsCell from '../../core/components/SupervisorsCell';
import CaseNumberNameCell from '../../core/components/CaseNumberNameCell';
import CaseTypeCell from '../../core/components/CaseTypeCell';
import CaseApprovalStatusCell from '../../core/components/CaseApprovalStatusCell';
import { sortKeyToColumnKey } from '../../core/configuration';
import CreateManageCaseModal from '../../core/components/CreateManageCaseModal';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import { useDashboardScroll } from '../../../search/core/hooks/useDashboardScroll';
import { exportsPageLoad } from '../../../../legacy-redux/actions/exportsActions';
import testIds from '../../../../core/testIds';
import CaseStatusCell from './CaseStatusCell';

import AllCasesSearchForm from './AllCasesSearchForm';

const AssignedDateCell = dateCellFactory('assignedDateUtc');
const UpdatedDateCell = dateCellFactory('updatedDateUtc');
const CreatedDateCell = dateCellFactory('createdDateUtc');
const DueDateCell = dateCellFactory('dueDateUtc');

const strings = componentStrings.cases.allCases.AllCases;

function ActionButton({ disabled, disabledTooltipText, ...props }) {
    const button = <Button className={buttonTypes.SECONDARY} disabled={disabled} {...props} />;
    return disabled && disabledTooltipText ? (
        <Tooltip side="top" hasButtonOffset content={disabledTooltipText}>
            <div>{button}</div>
        </Tooltip>
    ) : (
        button
    );
}

function AllCasesResults({
    // Current Query and Search Results
    results = [],
    query: { from, size, sortKey: currentSortKey, sortType: currentSortType },
    totalCount: totalResults,
    activeColumnKeys,
    tabNameToCount,

    // Table UI State
    tableLoading,
    selectedRows,
    highlightedRows,
    caseStatusGlobalAttrId,
    allResultsSelected,
    applicationSettings,

    // Event Handlers
    handleBulkRowSelect,
    handleRowClick,
    handleDateOptionClick,
    handleDateSortTypeClick,
    handleCaseInfoOptionClick,
    handlePersonnelOptionClick,
    handleLocationOptionClick,
    handlePaginationClick,
    handleSizeChange,
    handleTabClick,
    handleSelectAllRows,
    handleClearAllSelectedRows,
    handleManageCaseClick,
    handleCaseLinkClick,
    handleExportClick,

    currentUserHasAbility,
    router,
}) {
    const {
        pluralCaseFieldName: casesFieldName,
        singularCaseFieldName: caseFieldName,
    } = useCaseFieldName();

    const offenseDisplayName = useOffenseFieldName();

    const labels = useFields([
        DISPLAY_ONLY_CASE_SEARCH_CASE_STATUS_LABEL,
        CASE_CASE_DEFINITION_ID,
        CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID,
        DISPLAY_CASE_SUPERVISORS,
    ]);

    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: allCasesSearch,
    });

    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const columnKey = sortKeyToColumnKey[currentSortKey];

    const bulkManageCasesLimit = applicationSettings.RMS_BULK_MANAGE_CASES_LIMIT;

    const manageCaseButton =
        selectedRows.length > 1 ? (
            <BulkManageCasesSidePanel
                caseIds={map(selectedRows, (r) => results[r].id)}
                renderButton={(open) => (
                    <ActionButton
                        className={buttonTypes.SECONDARY_BOLD}
                        onClick={open}
                        testId={testIds.ACTION_BAR_BULK_MANAGE_CASE_BUTTON}
                        disabled={selectedRows.length > bulkManageCasesLimit}
                        disabledTooltipText={strings.maxBulkCaseManageReached(bulkManageCasesLimit, caseFieldName.toLowerCase())}
                    >
                        {strings.actionButtons.bulkManageCases(casesFieldName)}
                    </ActionButton>
                )}
            />
        ) : (
            <Button
                testId={testIds.ACTION_BAR_MANAGE_CASE_BUTTON}
                className={buttonTypes.SECONDARY_BOLD}
                onClick={handleManageCaseClick}
            >
                {strings.actionButtons.manageCase(caseFieldName)}
            </Button>
        );

    const bulkExportCaseLimit = applicationSettings.RMS_BULK_EXPORT_CASE_LIMIT;

    const casesActionBarButtons = (
        <>
            {manageCaseButton}
            <FeatureFlagged flag="RMS_BULK_EXPORT_CASE_ENABLED">
                <ActionButton
                    iconLeft={iconTypes.EXPORT}
                    className={buttonTypes.SECONDARY}
                    onClick={handleExportClick}
                    disabled={bulkExportCaseLimit > 1 && selectedRows.length > bulkExportCaseLimit}
                    disabledTooltipText={strings.maxBulkCaseExportReached(
                        caseFieldName.toLowerCase()
                    )}
                >
                    {strings.actionButtons.export}
                </ActionButton>
            </FeatureFlagged>
        </>
    );

    const createTab = (title, caseStatus) => (
        <Tab title={`${title} (${get(tabNameToCount, caseStatus, '0')})`} tabKey={caseStatus}>
            {resultsTable}
        </Tab>
    );
    const resultsTable = (
        <Table
            data={results}
            onRowClick={handleRowClick(getCurrentScrollPosition)}
            sortKey={currentSortKey}
            sortType={currentSortType}
            noRowsText={strings.noResults}
            disableBody={tableLoading}
            selectableRows={currentUserHasAbility(abilitiesEnum.CASES.EDIT_GENERAL)}
            selectableRow={false}
            selectedRows={selectedRows}
            highlightedRows={highlightedRows}
            onSelectRow={handleBulkRowSelect}
            containerClassName={classNames('search-results-table')}
            rowComponent={ElasticResultRowWithTargetProfileDetails}
            disableRowSelectWhen={(row) => row && !canRead(row.permissionSet)}
            tableCheckboxMargin="10px"
        >
            <TableHeader>
                <TableColumns>
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={columnKey}
                        activeValue={currentSortKey}
                        activeSortType={currentSortType}
                        onOptionClick={handleDateOptionClick}
                        onSortTypeClick={handleDateSortTypeClick}
                        width={169}
                        popoutWidth={160}
                    >
                        <OptionsTableColumnOption
                            display={strings.columns.assignedDateUtc.label}
                            value={sortKeyEnum.CASE_ASSIGNED_DATE_UTC}
                            columnKey="assignedDateUtc"
                            sortOptions={[
                                {
                                    display: strings.columns.assignedDateUtc.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.assignedDateUtc.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.updatedDateUtc.label}
                            value={sortKeyEnum.CASE_UPDATED_DATE_UTC}
                            columnKey="updatedDateUtc"
                            sortOptions={[
                                {
                                    display: strings.columns.updatedDateUtc.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.updatedDateUtc.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.createdDateUtc.label}
                            value={sortKeyEnum.CASE_CREATED_DATE_UTC}
                            columnKey="createdDateUtc"
                            sortOptions={[
                                {
                                    display: strings.columns.createdDateUtc.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.createdDateUtc.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.dueDateUtc.label}
                            value={sortKeyEnum.CASE_DUE_DATE_UTC}
                            columnKey="dueDateUtc"
                            sortOptions={[
                                {
                                    display: strings.columns.dueDateUtc.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.dueDateUtc.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                    </OptionsTableColumn>
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={activeColumnKeys.caseInfo}
                        activeValue={activeColumnKeys.caseInfo}
                        onOptionClick={handleCaseInfoOptionClick}
                        width={205}
                        popoutWidth={205}
                    >
                        <OptionsTableColumnOption
                            display={strings.columns.caseNumberName.label(caseFieldName)}
                            value="caseNumberName"
                        />
                        <OptionsTableColumnOption
                            display={labels[CASE_CASE_DEFINITION_ID]}
                            value="caseType"
                        />
                    </OptionsTableColumn>
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={activeColumnKeys.personnel}
                        activeValue={activeColumnKeys.personnel}
                        onOptionClick={handlePersonnelOptionClick}
                        width={204}
                        popoutWidth={204}
                    >
                        <OptionsTableColumnOption
                            display={strings.columns.investigators.label}
                            value="investigators"
                        />
                        <OptionsTableColumnOption
                            display={labels[CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID]}
                            value="assignedUnit"
                        />
                        <OptionsTableColumnOption
                            display={labels[DISPLAY_CASE_SUPERVISORS]}
                            value="supervisors"
                        />
                    </OptionsTableColumn>
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={activeColumnKeys.location}
                        activeValue={activeColumnKeys.location}
                        onOptionClick={handleLocationOptionClick}
                        width={132}
                        popoutWidth={170}
                    >
                        <OptionsTableColumnOption
                            display={strings.columns.primaryLocation.label}
                            value="primaryLocation"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.offenseLocation.label(offenseDisplayName)}
                            value="offenseLocation"
                        />
                    </OptionsTableColumn>
                    <TableColumn
                        display={labels[DISPLAY_ONLY_CASE_SEARCH_CASE_STATUS_LABEL]}
                        columnKey="caseStatus"
                        width={140}
                    />
                    <TableColumn
                        display={strings.columns.caseApprovalStatus.label}
                        columnKey="caseApprovalStatus"
                        width={70}
                    />
                </TableColumns>
            </TableHeader>
            <TableBody>
                <AssignedDateCell columnKey="assignedDateUtc" />
                <UpdatedDateCell columnKey="updatedDateUtc" />
                <CreatedDateCell columnKey="createdDateUtc" />
                <DueDateCell columnKey="dueDateUtc" />
                <CaseNumberNameCell
                    columnKey="caseNumberName"
                    handleCaseLinkClick={handleCaseLinkClick(getCurrentScrollPosition)}
                />
                <CaseTypeCell columnKey="caseType" />
                <InvestigatorsCell columnKey="investigators" />
                <AssignedUnitCell columnKey="assignedUnit" />
                <SupervisorsCell columnKey="supervisors" />
                <PrimaryLocationCell columnKey="primaryLocation" />
                <OffenseLocationCell columnKey="offenseLocation" />
                <CaseStatusCell columnKey="caseStatus" />
                <CaseApprovalStatusCell columnKey="caseApprovalStatus" />
            </TableBody>
        </Table>
    );
    return (
        <>
            <ScrollableUnderSubheader>
                <div className={allCasesSearch.resultsContainerClassName}>
                    <BatchBanners />
                    <ResultsHeader
                        from={from}
                        to={from + results.length}
                        totalResults={totalResults}
                        onSizeChange={handleSizeChange}
                        searchModule={allCasesSearch}
                        SearchForm={AllCasesSearchForm}
                        isSearchSavable={true}
                    />
                    <CaseDashboardTabs
                        selectedTab={caseStatusGlobalAttrId}
                        onSelectTab={handleTabClick}
                    >
                        {createTab(strings.tabs.open, globalAttributes.caseStatus.open)}
                        {createTab(strings.tabs.inactive, globalAttributes.caseStatus.inactive)}
                        {createTab(strings.tabs.closed, globalAttributes.caseStatus.closed)}
                    </CaseDashboardTabs>
                    {totalResults > 0 && (
                        <CasesResultsFooter
                            totalResults={totalResults}
                            searchSize={size}
                            currentPage={currentPage}
                            handlePaginationClick={handlePaginationClick}
                        />
                    )}

                    <CreateManageCaseModal
                        title={strings.manageCase}
                        okText={strings.save}
                        isBulkCreate={false}
                        includeLocalIdInTitle={true}
                    />
                </div>
            </ScrollableUnderSubheader>
            <CasesActionBar
                totalResultCount={totalResults}
                selectedResultCount={selectedRows.length}
                buttons={casesActionBarButtons}
                allResultsSelected={allResultsSelected}
                onClickSelectAll={handleSelectAllRows}
                onClickClearSelection={handleClearAllSelectedRows}
                visible={selectedRows.length > 0}
            />
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    // table ui state
    query: allCasesSearch.selectors.currentQuerySelector,
    results: allCasesSearch.selectors.currentResultsViewModelsSelector,
    highlightedRows: allCasesSearch.selectors.highlightedRowsSelector,
    totalCount: allCasesSearch.selectors.totalCountSelector,
    tableLoading: allCasesSearch.selectors.tableLoadingSelector,
    activeColumnKeys: allCasesSearch.selectors.activeColumnKeysSelector,
    caseStatusGlobalAttrIdToChildAttrIds: caseStatusGlobalAttrIdToChildAttrIdsSelector,
    caseStatusGlobalAttrId: currentElasticQueryCaseStatusGlobalAttrIdSelector,
    allResultsSelected: allCasesSearch.selectors.allResultsSelectedSelector,
    tabNameToCount: casesDashboardTabNameToCountSelector,
    applicationSettings: applicationSettingsSelector,

    // state needed for the assign bar
    selectedRows: allCasesSearch.selectors.selectedRowsSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handleTabClick(caseStatusAttrIds) {
        dispatch(setCurrentStatusAndUserDepartmentToForm(caseStatusAttrIds));
        dispatch(submitAllCasesSearchForm());
    },
    handleRowSelect(selectedRow) {
        dispatch(
            allCasesSearch.actionCreators.selectRows(
                !isUndefinedOrNull(selectedRow) ? [selectedRow] : []
            )
        );
    },
    handleBulkRowSelect(newSelectedRows) {
        dispatch(allCasesSearch.actionCreators.selectRows(newSelectedRows));
    },
    handleRowClick(getCurrentScrollPosition) {
        return (elasticCase, rowIndex) => {
            dispatch(
                allCasesSearch.actionCreators.openSearchResult(
                    elasticCase,
                    rowIndex,
                    router,
                    getCurrentScrollPosition()
                )
            );
        };
    },
    handleDateOptionClick({ columnKey, value, sortOptions }) {
        dispatch(allCasesSearch.actionCreators.setActiveColumnKey('date', columnKey));
        dispatch(
            allCasesSearch.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleDateSortTypeClick(sortType) {
        dispatch(allCasesSearch.actionCreators.search({ sortType }));
    },
    handleCaseInfoOptionClick({ value }) {
        dispatch(allCasesSearch.actionCreators.setActiveColumnKey('caseInfo', value));
    },
    handlePersonnelOptionClick({ value }) {
        dispatch(allCasesSearch.actionCreators.setActiveColumnKey('personnel', value));
    },
    handleLocationOptionClick({ value }) {
        dispatch(allCasesSearch.actionCreators.setActiveColumnKey('location', value));
    },
    handlePaginationClick(nextPage, size) {
        dispatch(
            allCasesSearch.actionCreators.search(
                { from: nextPage * size - size, size },
                { cacheBust: true }
            )
        );
    },
    handleSizeChange(size) {
        dispatch(allCasesSearch.actionCreators.search({ from: 0, size }));
    },
    handleAssignClick(elasticCase) {
        dispatch(openCaseDetailsModalFromDashboard(elasticCase.id));
    },
    handleManageCaseClick(elasticCase) {
        dispatch(openCreateManageCaseModalFromDashboard(elasticCase.id));
    },
    handleSelectAllRows() {
        dispatch(allCasesSearch.actionCreators.selectAllResults());
    },
    handleClearAllSelectedRows() {
        dispatch(allCasesSearch.actionCreators.selectRows([]));
    },
    handleCaseLinkClick(getCurrentScrollPosition) {
        return (event) => {
            event.stopPropagation();
            dispatch(allCasesSearch.actionCreators.setScrollPosition(getCurrentScrollPosition()));
        };
    },
    handleExportClick(alreadyActiveTab, selectedRows, results) {
        const caseIds = map(at(results, selectedRows), 'id');
        if (caseIds.length === 1) {
            dispatch(exportsPageLoad());
            router.push(`/cases/${caseIds[0]}/exports`);
        } else {
            dispatch(allCasesSearch.actionCreators.loadSearchExportPrintables(caseIds)).then(() =>
                router.push(`/cases/all/${searchTabToPathParam[alreadyActiveTab]}/exports`)
            );
        }
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        handleTabClick({ router, handleTabClick, caseStatusGlobalAttrIdToChildAttrIds }) {
            return (tabKey) => {
                handleTabClick(caseStatusGlobalAttrIdToChildAttrIds[tabKey]);
                router.push(`/cases/all/${searchTabToPathParam[tabKey]}`);
            };
        },
        handlePaginationClick({ handlePaginationClick, query: { size } }) {
            return (lastPage, nextPage) => handlePaginationClick(nextPage, size);
        },
        handleAssignClick({ handleAssignClick, selectedRows, results }) {
            return () => handleAssignClick(results[selectedRows[0]]);
        },
        handleManageCaseClick({ handleManageCaseClick, selectedRows, results }) {
            return () => handleManageCaseClick(results[selectedRows[0]]);
        },
        handleExportClick({ handleExportClick, caseStatusGlobalAttrId, selectedRows, results }) {
            return () => handleExportClick(caseStatusGlobalAttrId, selectedRows, results);
        },
    })
)(AllCasesResults);
