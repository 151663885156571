import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cssVar, Text, Box } from 'arc';
import {
    FederatedSearchExternalReadDataContext,
    QueryableEntityType,
    FederatedSearchFirearm,
} from 'mark43-federated-search';
import { EntityTypeEnum, Firearm } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { openItemSidePanel } from '../../state/ui/itemSidePanel';
import { FirearmPillSmall } from './FirearmPillSmall';
import { useMapDexFirearmsToRmsFirearms } from './hooks';

const strings = componentStrings.core.FirearmQuickAdd.dex;

export const AddFirearmFromDex = () => {
    const { resultForConsumerApp = {} } = useContext(FederatedSearchExternalReadDataContext) || {};
    const currentReport = useSelector(currentReportSelector);
    const dispatch = useDispatch();

    const dexFirearms: FederatedSearchFirearm[] = useMemo(
        () => resultForConsumerApp[QueryableEntityType.FIREARM] || [],
        [resultForConsumerApp]
    );

    const { rmsFirearms, loading, error } = useMapDexFirearmsToRmsFirearms(dexFirearms);

    const createOnPillClickHandler = (rmsFirearm: Partial<Firearm>) => () => {
        if (!currentReport) {
            return;
        }
        dispatch(
            openItemSidePanel({
                itemTypeAttrId: globalAttributes.itemType.firearm,
                ownerId: currentReport.id,
                ownerType: EntityTypeEnum.REPORT.name,
                operation: itemSidePanelOperationEnum.CREATE,
                reportingEventNumber: currentReport?.reportingEventNumber,
                isFormHidden: true,
                dexData: rmsFirearm,
                isAutosearch: true,
                query: rmsFirearm.serialNumber,
            })
        );
    };

    return (
        !loading && (
            <div>
                <Text fontSize="small" color="tertiary" sx={{ mb: cssVar('arc.space.2') }}>
                    {strings.addItem}
                </Text>
                {error && (
                    <Text variant="headingXs" color="negative">
                        {strings.genericLoadError}
                    </Text>
                )}
                {rmsFirearms.length > 0 &&
                    rmsFirearms.map((rmsFirearm) => (
                        <Box
                            display="grid"
                            gridTemplateColumns="1fr 1fr"
                            gap={cssVar('arc.space.2')}
                            key={rmsFirearm.id}
                        >
                            <FirearmPillSmall
                                firearm={rmsFirearm}
                                onClick={createOnPillClickHandler(rmsFirearm)}
                            />
                        </Box>
                    ))}
            </div>
        )
    );
};
