import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect, useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Observer, lifecycleOptions } from 'markformythree';
import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import {
    prefillSupervisorProfileFields,
    prefillUserProfileFields,
    prefillCurrentUserProfileFields,
    prefillNibrsOffenseCodeFields,
} from '~/client-common/core/domain/reports/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { dateDifferenceInYears } from '~/client-common/core/dates/utils/dateRangeHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import { DISPLAY_ONLY_REPORT_OTHER_AGENCY_INVOLVED_LABEL } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import {
    currentReportSelector,
    offenseReportIdForCurrentReportRENSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import FieldState from '../../../../core/forms/markformythree-arbiter/FieldState';
import Row from '../../../../core/components/Row';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTSelect } from '../../../../core/forms/components/selects/Select';

import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTUserSelect } from '../../../../core/forms/components/selects/UserSelect';
import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import IndentedFields from '../../../../core/components/IndentedFields';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import {
    currentUserHasAbilitySelector,
    currentUserIdSelector,
    currentUserProfileSelector,
} from '../../../../core/current-user/state/ui';
import { MFTNItems, RemoveButton } from '../../../../core/forms/components/NItems';
import { ArbiterMFTAttributeButtonRadio } from '../../../../core/forms/components/button-radios/AttributeButtonRadio';
import { loadAttributesForType } from '../../../../core/attributes/state/ui/loadAttributesForType';
import useOffenseCodesFromOffenseReportToFilterOptions from '../../hooks/useOffenseCodesFromOffenseReportToFilterOptions';
import { UseOfForceExtendedBooleanSelect } from '../../../../core/forms/components/selects/UseOfForceExtendedBooleanSelect';
import UseOfForceOfficerDemographics from './UseOfForceOfficerDemographics';

const strings = componentStrings.reports.core.UseOfForceCardForm;

const MyselfButton = styled(Button)`
    margin-top: 19px;
`;

const OfficerAge = ({ dateOfBirth, set }) => {
    useEffect(() => set('officerAge', dateDifferenceInYears(dateOfBirth, nowUtc())), [
        dateOfBirth,
        set,
    ]);

    return <ArbiterMFTText path="officerAge" disabled={true} length="sm" />;
};

const NibrsIncidentNumber = ({ nibrsIncidentNumber, set }) => {
    const offenseReportIdForCurrentReportREN = useSelector(
        offenseReportIdForCurrentReportRENSelector
    );
    const currentReport = useSelector(currentReportSelector);
    useEffect(() => {
        if (
            isUndefinedOrNull(nibrsIncidentNumber) &&
            offenseReportIdForCurrentReportREN &&
            currentReport?.reportingEventNumber
        ) {
            set('nibrsIncidentNumber', currentReport?.reportingEventNumber);
        }
    }, [nibrsIncidentNumber, set, currentReport, offenseReportIdForCurrentReportREN]);

    return <ArbiterMFTText path="nibrsIncidentNumber" length="md" />;
};

const mapStateToProps = createStructuredSelector({
    currentUserId: currentUserIdSelector,
    currentUserProfile: currentUserProfileSelector,
    reportById: reportByIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    prefillSupervisorProfileFields: ({
        onSceneSupervisorUserProfileId,
        formSet,
        formTransaction,
    }) =>
        dispatch(
            prefillSupervisorProfileFields({
                onSceneSupervisorUserProfileId,
                formSet,
                formTransaction,
            })
        ),
    prefillUserProfileFields: ({ userProfileId, formSet, formTransaction }) =>
        dispatch(prefillUserProfileFields({ userProfileId, formSet, formTransaction })),
    prefillCurrentUserProfileFields: ({ currentUserProfile, formSet, formTransaction }) =>
        dispatch(
            prefillCurrentUserProfileFields({
                currentUserProfile,
                formSet,
                formTransaction,
            })
        ),
    prefillNibrsOffenseCodeFields: ({ codes, offenseCodeOptions, formSet, formTransaction }) =>
        dispatch(
            prefillNibrsOffenseCodeFields({
                codes,
                offenseCodeOptions,
                formSet,
                formTransaction,
            })
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(function UseOfForceCardForm({
    currentUserId,
    currentUserProfile,
    reportById,
    prefillUserProfileFields,
    prefillSupervisorProfileFields,
    prefillCurrentUserProfileFields,
    prefillNibrsOffenseCodeFields,
    reportId,
    getSelectedUserProfileId,
    setSelectedUserProfileId,
    getSelectedSupervisorProfileId,
    setSelectedSupervisorProfileId,
}) {
    const report = reportById(reportId);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const dispatch = useDispatch();

    const offenseCodeOptions = useOffenseCodesFromOffenseReportToFilterOptions();

    useEffect(() => {
        dispatch(loadAttributesForType({ attributeType: [AttributeTypeEnum.RANK.name] }));
    }, [dispatch]);

    const otherAgencyInvolvedLabel = useFields(DISPLAY_ONLY_REPORT_OTHER_AGENCY_INVOLVED_LABEL)[
        DISPLAY_ONLY_REPORT_OTHER_AGENCY_INVOLVED_LABEL
    ];

    const effectiveDateFilter = report.isLegacyReport ? undefined : report.createdDateUtc;

    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={RefContextEnum.FORM_USE_OF_FORCE.name}
            context={RefContextEnum.FORM_USE_OF_FORCE.name}
            render={({ set, resetUi, transaction }) => (
                <CardSection>
                    <Observer
                        subscriptions={{
                            userProfileId: 'userProfileId',
                        }}
                        render={({ userProfileId }) => {
                            const currentUserHasViewGlobalUsersAbility = currentUserHasAbility(
                                abilitiesEnum.ADMIN.VIEW_GLOBAL_USERS
                            );

                            const shouldPrefillUserProfileFields =
                                !isUndefinedOrNull(userProfileId) &&
                                getSelectedUserProfileId() !== userProfileId;

                            // to prefill for any user in the select
                            if (
                                shouldPrefillUserProfileFields &&
                                currentUserHasViewGlobalUsersAbility
                            ) {
                                setSelectedUserProfileId(userProfileId);
                                prefillUserProfileFields({
                                    userProfileId,
                                    formSet: set,
                                    formTransaction: transaction,
                                });
                                // to prefill only for the current user
                            } else if (
                                shouldPrefillUserProfileFields &&
                                userProfileId === currentUserId
                            ) {
                                setSelectedUserProfileId(currentUserProfile.userId);
                                prefillCurrentUserProfileFields({
                                    currentUserProfile,
                                    formSet: set,
                                    formTransaction: transaction,
                                });
                            }
                            return null;
                        }}
                    />
                    <Row>
                        <ArbiterMFTAttributeSelect
                            path="useOfForceReasonAttrId"
                            length="md"
                            attributeType={AttributeTypeEnum.USE_OF_FORCE_REASON.name}
                        />
                    </Row>
                    <Row>
                        <IndentedFields>
                            <ArbiterMFTText path="useOfForceReasonOther" length="md" />
                        </IndentedFields>
                    </Row>
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTAttributeButtonRadio
                                path="nibrsIncidentNumberPendingUnknownAttrId"
                                attributeType={AttributeTypeEnum.USE_OF_FORCE_PENDING_UNKNOWN.name}
                            />
                        </Row>
                        <Observer
                            subscriptions={{ nibrsIncidentNumber: 'nibrsIncidentNumber' }}
                            render={({ nibrsIncidentNumber }) => (
                                <Row>
                                    <NibrsIncidentNumber
                                        nibrsIncidentNumber={nibrsIncidentNumber}
                                        set={set}
                                    />
                                </Row>
                            )}
                        />
                    </IndentedFields>
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTAttributeButtonRadio
                                path="nibrsOffenseCodePendingUnknownAttrId"
                                attributeType={AttributeTypeEnum.USE_OF_FORCE_PENDING_UNKNOWN.name}
                            />
                        </Row>
                        <FieldState
                            path="nibrsCode1"
                            render={({ hidden }) => {
                                if (hidden) {
                                    return null;
                                }

                                return (
                                    <Observer
                                        subscriptions={{
                                            nibrsCode1: 'nibrsCode1',
                                            nibrsCode2: 'nibrsCode2',
                                            nibrsCode3: 'nibrsCode3',
                                        }}
                                        render={({ nibrsCode1, nibrsCode2, nibrsCode3 }) => {
                                            prefillNibrsOffenseCodeFields({
                                                codes: [nibrsCode1, nibrsCode2, nibrsCode3],
                                                offenseCodeOptions,
                                                formSet: set,
                                                formTransaction: transaction,
                                            });

                                            const onNibrsCodeChange = (clearNextPaths) => (
                                                value
                                            ) => {
                                                if (!value) {
                                                    clearNextPaths.forEach((path) => set(path, ''));
                                                }
                                            };

                                            return (
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTSelect
                                                            options={offenseCodeOptions}
                                                            path="nibrsCode1"
                                                            length="md"
                                                            onChange={onNibrsCodeChange([
                                                                'nibrsCode2',
                                                                'nibrsCode3',
                                                            ])}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTSelect
                                                            options={offenseCodeOptions}
                                                            path="nibrsCode2"
                                                            length="md"
                                                            onChange={onNibrsCodeChange([
                                                                'nibrsCode3',
                                                            ])}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTSelect
                                                            options={offenseCodeOptions}
                                                            path="nibrsCode3"
                                                            length="md"
                                                        />
                                                    </Row>
                                                </IndentedFields>
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                    </IndentedFields>
                    <FieldState
                        path="userProfileId"
                        render={({ hidden }) =>
                            !hidden ? (
                                <Row>
                                    <ArbiterMFTUserSelect
                                        path="userProfileId"
                                        includeOther={false}
                                        effectiveDate={
                                            report.isLegacyReport
                                                ? undefined
                                                : report.createdDateUtc
                                        }
                                        length="md"
                                    />
                                    <MyselfButton
                                        className={buttonTypes.SECONDARY}
                                        onClick={() => {
                                            set('userProfileId', currentUserId);
                                            resetUi('userProfileId');
                                        }}
                                    >
                                        {strings.myself}
                                    </MyselfButton>
                                </Row>
                            ) : null
                        }
                    />
                    <Row>
                        <ArbiterMFTText path="officerName" length="md" />
                    </Row>
                    <Row>
                        <ArbiterMFTText path="officerBadgeNumber" length="md" />
                    </Row>
                    <UseOfForceOfficerDemographics>
                        <Row>
                            <ArbiterMFTDatePicker
                                path="officerDateOfBirth"
                                width={120}
                                variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                            />
                            <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                                <Observer
                                    subscriptions={{
                                        officerDateOfBirth: 'officerDateOfBirth',
                                    }}
                                    render={({ officerDateOfBirth }) => (
                                        <OfficerAge dateOfBirth={officerDateOfBirth} set={set} />
                                    )}
                                />
                            </FeatureFlagged>
                        </Row>
                    </UseOfForceOfficerDemographics>
                    <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                        <Row>
                            <UseOfForceExtendedBooleanSelect
                                path="officerFullPartTimeAttrId"
                                length="md"
                            />
                        </Row>
                    </FeatureFlagged>
                    <UseOfForceOfficerDemographics>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="officerSexAttrId"
                                length="md"
                                attributeType={AttributeTypeEnum.SEX.name}
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="officerRaceAttrId"
                                length="md"
                                attributeType={AttributeTypeEnum.RACE.name}
                            />
                        </Row>
                    </UseOfForceOfficerDemographics>
                    <Row>
                        <ArbiterMFTAttributeSelect
                            path="officerDressAttrId"
                            length="md"
                            attributeType={AttributeTypeEnum.OFFICER_DRESS.name}
                        />
                    </Row>
                    <Row>
                        <ArbiterMFTAttributeSelect
                            path="medicalAidReceivedAttrId"
                            length="md"
                            attributeType={AttributeTypeEnum.USE_OF_FORCE_MEDICAL_AID_RECEIVED.name}
                        />
                    </Row>
                    <Row>
                        <UseOfForceExtendedBooleanSelect
                            path="wasOfficerOnDutyAttrId"
                            length="md"
                        />
                    </Row>
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="officerDutyTypeAttrId"
                                length="md"
                                attributeType={
                                    AttributeTypeEnum.USE_OF_FORCE_OFFICER_DUTY_TYPE.name
                                }
                            />
                        </Row>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTText path="specialDutyContractNumber" length="md" />
                            </Row>
                        </IndentedFields>
                    </IndentedFields>
                    <Row>
                        <ArbiterMFTBooleanSelect path="incidentResultedInCrimeReport" length="md" />
                    </Row>
                    <Row>
                        <ArbiterMFTText path="officerRank" length="md" />
                    </Row>
                    <Row>
                        <ArbiterMFTText path="officerDutyAssignment" length="md" />
                    </Row>
                    <UseOfForceOfficerDemographics>
                        <Row>
                            <ArbiterMFTText path="officerYearsOfService" length="md" />
                        </Row>
                    </UseOfForceOfficerDemographics>
                    <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                        <UseOfForceOfficerDemographics>
                            <Row>
                                <ArbiterMFTText path="officerHeightFeet" length="md" />
                                <ArbiterMFTText path="officerHeightInches" length="md" />
                            </Row>
                            <Row>
                                <ArbiterMFTText path="officerWeight" length="md" />
                            </Row>
                        </UseOfForceOfficerDemographics>
                        <Row>
                            <UseOfForceExtendedBooleanSelect
                                path="didOfficerApproachAttrId"
                                length="md"
                            />
                            <UseOfForceExtendedBooleanSelect
                                path="seniorOfficerPresentAttrId"
                                length="md"
                            />
                        </Row>
                        <Observer
                            subscriptions={{
                                onSceneSupervisorUserProfileId: 'onSceneSupervisorUserProfileId',
                            }}
                            render={({ onSceneSupervisorUserProfileId }) => {
                                const shouldPrefillSupervisorProfileFields =
                                    !isUndefinedOrNull(onSceneSupervisorUserProfileId) &&
                                    getSelectedSupervisorProfileId() !==
                                        onSceneSupervisorUserProfileId;

                                if (shouldPrefillSupervisorProfileFields) {
                                    setSelectedSupervisorProfileId(onSceneSupervisorUserProfileId);
                                    prefillSupervisorProfileFields({
                                        onSceneSupervisorUserProfileId,
                                        formSet: set,
                                        formTransaction: transaction,
                                    });
                                }
                                return null;
                            }}
                        />
                        <Row>
                            <ArbiterMFTBooleanSelect path="supervisorOnScene" length="md" />
                        </Row>
                        <IndentedFields>
                            <FieldState
                                path="onSceneSupervisorUserProfileId"
                                render={({ hidden }) =>
                                    !hidden ? (
                                        <Row>
                                            <ArbiterMFTUserSelect
                                                path="onSceneSupervisorUserProfileId"
                                                includeOther={false}
                                                effectiveDate={effectiveDateFilter}
                                                label={strings.uofSupervisorName}
                                                length="md"
                                            />
                                        </Row>
                                    ) : null
                                }
                            />
                            <Row>
                                <ArbiterMFTText
                                    path="onSceneSupervisorHumanResourcesNumber"
                                    length="md"
                                />
                            </Row>
                            <Row>
                                <ArbiterMFTText path="onSceneSupervisorUnit" length="md" />
                            </Row>
                        </IndentedFields>
                        <Row>
                            <UseOfForceExtendedBooleanSelect
                                path="wasOfficerAmbushedAttrId"
                                length="md"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect
                                path="otherOfficersInvolvedButUnknown"
                                length="lg"
                            />
                        </Row>
                        <IndentedFields>
                            <Row>
                                <ArbiterMFTText
                                    path="minimumNumberOfUnknownOfficersInvolved"
                                    length="lg"
                                />
                            </Row>
                        </IndentedFields>
                        <MFTNItems
                            path="otherInvolvedAgencies"
                            formName={RefContextEnum.FORM_USE_OF_FORCE.name}
                            addItemOnEmpty={false}
                            addText={otherAgencyInvolvedLabel}
                            hideAddButtonOnEmptyItem={true}
                            childFieldKeys={[
                                'otherAgencyName',
                                'otherAgencyOri',
                                'otherAgencyCaseNumber',
                            ]}
                            render={() => (
                                <>
                                    <Row>
                                        <ArbiterMFTText path="otherAgencyName" length="lg" />
                                    </Row>
                                    <IndentedFields>
                                        <Row>
                                            <ArbiterMFTText path="otherAgencyOri" length="md" />
                                            <ArbiterMFTText
                                                path="otherAgencyCaseNumber"
                                                length="md"
                                            />
                                        </Row>
                                    </IndentedFields>
                                </>
                            )}
                            // the remove button was overridden because we need to show remove button
                            // when there is only one other agency exists
                            renderRemoveButton={({ removeItem }) => (
                                <RemoveButton onClick={removeItem} />
                            )}
                        />
                    </FeatureFlagged>
                    <FieldState
                        path="useOfForceStatistics"
                        render={({ hidden }) =>
                            !hidden ? (
                                <div>
                                    <Row>
                                        <ArbiterMFTAttributeCheckboxes
                                            path="useOfForceStatistics"
                                            attributeType={
                                                AttributeTypeEnum.USE_OF_FORCE_STATISTICS.name
                                            }
                                            columns={2}
                                            columnWidth="48%"
                                            gutterWidth="4%"
                                        />
                                    </Row>
                                    <IndentedFields>
                                        <Row>
                                            <ArbiterMFTText path="useOfForceStatisticsDescription" />
                                        </Row>
                                    </IndentedFields>
                                </div>
                            ) : null
                        }
                    />
                </CardSection>
            )}
        />
    );
});
