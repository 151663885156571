import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { TaskView } from '@mark43/rms-api';
import { columnKeys } from '../constants';
import TaskDateCell from '../../TaskDateCell';
import { tasksDashboardSearch } from '../../../../dashboard/state/ui';

export const TaskDueDateCell: FC<{ task: TaskView }> = ({ task }) => {
    const activeColumnKeys: Record<string, keyof typeof columnKeys> = useSelector(
        tasksDashboardSearch.selectors.activeColumnKeysSelector
    );

    if (!task.dueDateUtc) {
        return null;
    }

    return (
        <TaskDateCell
            date={task.dueDateUtc}
            statusAttrId={task.statusAttrId}
            isDueDate={columnKeys.dueDateUtc === activeColumnKeys.date}
        />
    );
};
