import React from 'react';
import _ from 'lodash';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    NAME_ATTRIBUTE_ATTRIBUTE_TYPE_PERSON_SKILL_ATTRIBUTE_ID,
    NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MOOD_ATTRIBUTE_ID,
    NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MODUS_OPERANDI_ATTRIBUTE_ID,
    NAME_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CHARACTERISTIC_ATTRIBUTE_ID,
    DISPLAY_ONLY_PERSON_PROFILE_DEMEANOR_LABEL,
} from '~/client-common/core/enums/universal/fields';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
} from '../../core/components/EntityProfileSection';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';

const mapAttribute = (attr) => <div key={attr}>{attr}</div>;

export default function DemeanorDetails({ nameAttributes }) {
    const fieldDisplayNames = useFields([
        NAME_ATTRIBUTE_ATTRIBUTE_TYPE_PERSON_SKILL_ATTRIBUTE_ID,
        DISPLAY_ONLY_PERSON_PROFILE_DEMEANOR_LABEL,
        NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MOOD_ATTRIBUTE_ID,
        NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MODUS_OPERANDI_ATTRIBUTE_ID,
        NAME_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CHARACTERISTIC_ATTRIBUTE_ID,
    ]);
    const behaviorCharacteristicAttributes =
        !!nameAttributes.BEHAVIORAL_CHARACTERISTIC &&
        _.map(nameAttributes.BEHAVIORAL_CHARACTERISTIC, mapAttribute);
    const moodAttributes = !!nameAttributes.MOOD && _.map(nameAttributes.MOOD, mapAttribute);
    const modusOperandiAttributes =
        !!nameAttributes.MODUS_OPERANDI && _.map(nameAttributes.MODUS_OPERANDI, mapAttribute);
    const skillsAttributes =
        !!nameAttributes.PERSON_SKILL && _.map(nameAttributes.PERSON_SKILL, mapAttribute);

    return (
        <EntityProfileSection title={fieldDisplayNames.DISPLAY_ONLY_PERSON_PROFILE_DEMEANOR_LABEL}>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    <SummaryRow
                        label={
                            fieldDisplayNames.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CHARACTERISTIC_ATTRIBUTE_ID
                        }
                    >
                        {behaviorCharacteristicAttributes}
                    </SummaryRow>
                    <SummaryRow
                        label={fieldDisplayNames.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MOOD_ATTRIBUTE_ID}
                    >
                        {moodAttributes}
                    </SummaryRow>
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    <SummaryRow
                        label={
                            fieldDisplayNames.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MODUS_OPERANDI_ATTRIBUTE_ID
                        }
                    >
                        {modusOperandiAttributes}
                    </SummaryRow>
                    <SummaryRow
                        label={
                            fieldDisplayNames.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_PERSON_SKILL_ATTRIBUTE_ID
                        }
                    >
                        {skillsAttributes}
                    </SummaryRow>
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </EntityProfileSection>
    );
}
