import { InlineReportCommentView } from '@mark43/rms-api';
import { defer, find, map, groupBy, keys, filter, includes, last } from 'lodash';
import { Store, Dispatch } from 'redux';
import type { Editor } from 'tinymce';
import { createSelector } from 'reselect';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import { storeReportInlineComments } from '~/client-common/core/domain/inline-report-comments/state/data';
import { LinkedModuleShape } from '~/client-common/core/utils/createLinkModule';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { atobUnicode, btoaUnicode } from '../../../../lib/base64';
import { currentUserHasAbilitySelector } from '../../current-user/state/ui';
import { RootState } from '../../../../legacy-redux/reducers/rootReducer';
import { mentionNarrativeDataSelector } from '../plugins/mentions/selectors';
import {
    currentReportSelector,
    approvalStatusSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import { logWarning } from '../../../../core/logging';
import { isAutogeneratedConversationUid, buildConversationUid } from './tinyMCEConversations';
import updateCommentSidebarPosition from './updateCommentSidebarPosition';
import { withTinyEditor } from './withTinyEditor';

const MCE_ANNOTATION_CLASSNAME = 'mce-annotation';
const MCE_ANNOTATION = 'mceAnnotation';
const MCE_ANNOTATION_UID = 'mceAnnotationUid';
const CUSTOM_ELEMENT = 'customElement';
const HIGHLIGHT = 'highlight';
const TINYCOMMENTS = 'tinycomments';
const TINY_COMMENTS_DEFAULT_HEADER = 'tinycomments|2.1|data:application/json;';
const BASE64_SPLIT_TOKEN = 'base64,';
const CATEGORY = 'CATEGORY';
const GUIDE_CATEGORY = 'GUIDE';
const TEXT_HTML = 'text/html';

function filterForElementsWithNewComments(doc: Document): HTMLElement[] {
    const commentedElements: HTMLElement[] = [];
    for (const element of Array.from(doc.getElementsByClassName(MCE_ANNOTATION_CLASSNAME))) {
        if (element instanceof HTMLElement && !includes(element.classList, 'highlight')) {
            commentedElements.push(element);
        }
    }
    return commentedElements;
}

/*
 * When a new comment is added to the narrative, we have to create the inline comment on the back end and update the
 * narrative with the comment id.
 *
 * This function expects the narrative to contain exactly 1 new comment.
 * If no new comments are found, do nothing.
 * If multiple new comments are found, only the first comment is saved.
 *
 * The new comment may be applied to multiple HTML elements (see preprocessHtml).
 */
export const useAddMark43CommentMetadata = () => {
    return function (
        newValue: string,
        currentReportId: number | undefined,
        setCurrentAutosaveValue: (value: string) => void,
        setError: (error: string) => void,
        dispatch: Dispatch,
        editor: Editor
    ): Promise<
        | {
              narrativeHtml: string;
              reportCommentId: number;
          }
        | undefined
    > {
        const valueDOM = domParser.parseFromString(newValue, TEXT_HTML);
        const commentedElements = filterForElementsWithNewComments(valueDOM);

        /* The Tiny-Comments-plugin stores comment data in an HTML Comment at the end of the
         * editor innerHTML. It links the text that the comment targets through an attribute
         * set on a span element surrounding that text called data-mce-annotation-uid which has
         * an id value which maps to the comment content in the HTML Comment.
         *
         * Here we pull out that HTML Comment so that we can access the content of the comment
         * which later is posted to the backend to create an inline comment in Mark43.
         */
        const tinyCommentsCommentNode = find(valueDOM.body.childNodes, (node) => {
            return node.nodeType === Node.COMMENT_NODE;
        });

        if (!(tinyCommentsCommentNode instanceof Comment) || commentedElements.length === 0) {
            return Promise.resolve(undefined);
        }

        const tinyCommentsJSON = parseTinyCommentsData(tinyCommentsCommentNode);
        let commentContent: string | undefined;
        for (const commentedElement of commentedElements) {
            const elementDataset = commentedElement.dataset;
            const tinyCommentId = elementDataset[MCE_ANNOTATION_UID];
            // our back end looks for a data-custom-element attribute to identify new comments
            if (!elementDataset[CUSTOM_ELEMENT] && tinyCommentId) {
                elementDataset[CUSTOM_ELEMENT] = HIGHLIGHT;
                // the highlight style is applied by us, not by TinyMCE
                commentedElement.classList.add(HIGHLIGHT);
                const tinyDataForComment = tinyCommentsJSON[tinyCommentId];
                if (!commentContent) {
                    commentContent = tinyDataForComment.comments[0].content;
                } else if (commentContent !== tinyDataForComment.comments[0].content) {
                    logWarning('Expected 1 new Tiny Comment, but found multiple', {
                        prevLength: commentContent.length,
                        nextLength: tinyDataForComment.comments[0].content.length,
                    });
                }
            }
        }
        if (!commentContent) {
            return Promise.resolve(undefined);
        }

        // Remove the text selection, which hides all comments.
        // The comments will be shown again afterwards after the narrative HTML and Redux state are both updated.
        editor.getDoc().getSelection()?.removeAllRanges();

        // If we have any new comments, we need to create them on the backend
        return getReportResource()
            .addInlineComment(currentReportId, commentContent, valueDOM.body.innerHTML)
            .then((response: InlineReportCommentView) => {
                const reportCommentId = response.id;
                let { narrativeHtml } = response;
                if (narrativeHtml) {
                    narrativeHtml = migrateNewComment(narrativeHtml, reportCommentId, editor);
                    dispatch(storeReportInlineComments(response));
                }
                return {
                    narrativeHtml: narrativeHtml || '',
                    reportCommentId,
                };
            })
            .catch((error: Error) => {
                setError(error.message);
                return error.message;
            });
    };
};

/**
 * This object comes from the Tiny Comments plugin.
 * The only property we use is `uid`.
 * For existing comments, we set the `uid` ourselves.
 * For new comments, the Tiny Comments plugin automatically sets the `uid`, and then we replace it with the value we expect.
 * See tinyMCEConversations.ts.
 */
interface TinyMCEComment {
    author: string;
    authorName: string;
    content: string;
    createdAt: string;
    modifiedAt: string;
    uid: string;
}
interface TinyMCEConversation {
    comments: TinyMCEComment[];
    uid: string;
}
type TinyMCEConversations = Record<string, TinyMCEConversation>;

const parseTinyCommentsData = (tinyCommentsCommentNode: Comment): TinyMCEConversations => {
    const tinyCommentsEncodedJson = tinyCommentsCommentNode.data.split(BASE64_SPLIT_TOKEN)[1];
    const tinyCommentsData = tinyCommentsEncodedJson ? atobUnicode(tinyCommentsEncodedJson) : '{}';
    try {
        return JSON.parse(tinyCommentsData);
    } catch (e) {
        // we don't include the JSON data in this warning to avoid sending CJIS data to Sentry
        // look in LogRepo for the API request data, and query the history_reports table for the narrative
        logWarning('Failed to parse JSON for narrative inline comments in TinyMCE editor');
        return {};
    }
};

const domParser = new DOMParser();

/**
 * When a new comment is added, the Tiny Comments plugin auto-generates a random uid. We replace that value with our own
 * id in each DOM element for the comment and in the Base64-encoded HTML.
 */
const migrateNewComment = (value: string, reportCommentId: number, editor: Editor) => {
    let tinyCommentsCommentNodeHeader = TINY_COMMENTS_DEFAULT_HEADER;

    const currentEditorValue = domParser.parseFromString(editor?.getContent() || '', TEXT_HTML);
    const newValue = domParser.parseFromString(value || '', TEXT_HTML);
    const tinyCommentsCommentNodes = filter(currentEditorValue.body.childNodes, (node) => {
        return node instanceof Comment && node.data.startsWith(TINYCOMMENTS);
    });
    const tinyCommentsCommentNode = tinyCommentsCommentNodes[0];

    if (tinyCommentsCommentNode instanceof Comment) {
        const tinyCommentsJSON = parseTinyCommentsData(tinyCommentsCommentNode);
        // we expect only 1 new comment to exist, but this code is flexible to handle 0 or multiple comments
        const newComments = filter(tinyCommentsJSON, (comment, uid) =>
            isAutogeneratedConversationUid(uid)
        );
        for (const comment of newComments) {
            const oldUid = comment.uid;
            const newUid = buildConversationUid(reportCommentId);
            const commentedElements = Array.from(
                newValue.querySelectorAll<HTMLElement>(`.highlight[data-id="${oldUid}"]`)
            );

            for (const commentedElement of commentedElements) {
                commentedElement.dataset[MCE_ANNOTATION] = TINYCOMMENTS;
                commentedElement.dataset[MCE_ANNOTATION_UID] = newUid;
            }

            tinyCommentsJSON[newUid] = {
                ...tinyCommentsJSON[oldUid],
                uid: newUid,
                comments: tinyCommentsJSON[oldUid].comments.map((oldComment) => ({
                    ...oldComment,
                    uid: newUid,
                })),
            };
            delete tinyCommentsJSON[oldUid];
        }

        tinyCommentsCommentNodeHeader = tinyCommentsCommentNode?.data?.split(BASE64_SPLIT_TOKEN)[0];

        newValue.body.append(tinyCommentsCommentNode);

        tinyCommentsCommentNode.data = `${tinyCommentsCommentNodeHeader}${BASE64_SPLIT_TOKEN}${btoaUnicode(
            JSON.stringify(tinyCommentsJSON)
        )}`;
    }
    return newValue.body.innerHTML;
};

/**
 * Preprocess the HTML before setting it into tinyMCE.
 *
 * This function is primarily used to take any `comment` markings,
 * and move them to be /directly/ around the text node if they are not already.
 *
 * This is because TinyMCE's comment plugin does not work properly when the
 * comment markings are not directly around the text node.
 *
 * The reason we can get into this state is because slateJS will wrap `comment` markers
 * one level above any other stylings.
 *
 * For instance, if slate text has formatting and comments, the DOM will look like this:
 *     <p>
 *         <span data-id="xx" class="highlight" data-custom-element="highlight">
 *             <em>
 *                 <span style="font-size: var(--arc-fontSizes-sm)">
 *                     <strong>
 *                         My text
 *                     </strong>
 *                     <em>
 *                         with a comment
 *                     </em>
 *                 </span>
 *             </em>
 *         </span>
 *     </p>
 *
 * On the otherhand, tinyMCE will put the comment markers directly around the text node(s):
 *     <p>
 *         <em>
 *             <span style="font-size: var(--arc-fontSizes-sm)">
 *                 <strong>
 *                     <span data-id="xx" class="highlight" data-custom-element="highlight">
 *                         My text
 *                     </span>
 *                 </strong>
 *                 <em>
 *                     <span data-id="xx" class="highlight" data-custom-element="highlight">
 *                         with a comment
 *                     </span>
 *                 </em>
 *             </span>
 *         </em>
 *     </p>
 */
const HTML_COMMENTS_REGEXP = /(?=<!--)([\s\S]*?)-->/;

// Strip out any HTML Comments that the TinyMCE library uses.
// Example: <!--tinycomments|2.1|data:application/json;base64,e30=-->
export const sanitizeTinyMCEValue = (value: string) => {
    return value.replace(HTML_COMMENTS_REGEXP, '');
};

export const preprocessHtml = (value: string) => {
    const valueDOM = domParser.parseFromString(value, TEXT_HTML);
    const commentedElements = Array.from(valueDOM.getElementsByClassName(HIGHLIGHT));

    /**
     * Recursively traverse the node's DOM tree and assert that each
     * node only has one child, where the lowest level node is a text node
     *
     * If the DOM tree has more than one child at a certain level, bail out
     *
     * Otherwise, return the actual text node
     */
    const getTextNodeLeafOfSinglyNestedTree = (node: Node): Node | undefined => {
        if (node.childNodes.length === 1 && node.firstChild) {
            return getTextNodeLeafOfSinglyNestedTree(node.firstChild);
        } else {
            return node.nodeType === Node.TEXT_NODE ? node : undefined;
        }
    };

    const commentedElementsThatNeedToBeFixed = commentedElements.filter((commentNode) => {
        // First, make sure there are no more comment nodes within this one
        if (commentNode.getElementsByClassName(HIGHLIGHT).length > 0) {
            return false;
        }

        // Next, make sure that the direct child of the comment node is not already a text node
        // This is a little arbitrary, but it should also be the only case we need to solve for
        if (
            commentNode.childNodes.length === 1 &&
            commentNode.firstChild?.nodeType === Node.TEXT_NODE
        ) {
            return false;
        }

        // Next, make sure that there is only one child node all the way down to the text node
        if (!getTextNodeLeafOfSinglyNestedTree(commentNode)) {
            return false;
        }

        // If we made it through, then this is a comment that we need to adjust
        return true;
    });

    // Now that we know which nodes need fixing, let's fix them
    commentedElementsThatNeedToBeFixed.forEach((commentNode) => {
        // Before we do any manipulation, we need to cache all our values
        // because we will be modifying the DOM tree

        const commentNodeOnlyChild = commentNode.firstChild;

        const textNode = getTextNodeLeafOfSinglyNestedTree(commentNode);
        const textNodeParent = textNode?.parentNode;

        // Only proceed if we actually have all the nodes we need (which we should, based on the filter above)
        if (textNode && textNodeParent && commentNodeOnlyChild) {
            // Now that we have all the nodes we need,
            // Step 1: Remove the comment node from the DOM tree
            commentNode.replaceWith(commentNodeOnlyChild);

            // Now, we need to put the comment node back into the tree
            // Step 2: Replace the comment node's children with our single text node
            commentNode.replaceChildren(textNode);
            // Step 3: Set the text node's parent's children to be the comment node
            textNodeParent.replaceChildren(commentNode);
            /**
             * Using the same example as above, the input is:
             *     commentNode.parentNode <p>
             *         commentNode <span class="highlight">
             *             commentNodeOnlyChild <em>
             *                 ... arbitrarily many descendants
             *                     textNodeParent <strong>
             *                         textNode "My text with a comment"
             *
             * The result of these 3 steps is:
             *     commentNode.parentNode <p>
             *         commentNodeOnlyChild <em> (step 1)
             *             ... arbitrarily many descendants
             *                 textNodeParent <strong>
             *                     commentNode <span class="highlight"> (step 3)
             *                         textNode "My text with a comment" (step 2)
             */
        }
    });

    // Now return the new DOM
    return valueDOM.body.innerHTML;
};

/*
 * This runs before the initialValue is set on the TinyEditor so that the TinyComments metadata will
 * exist in the narrative html. If this metadata doesn't exist then there will be no TinyComments shown.
 */
export const migratePreExistingComments = (
    value: string,
    formatMiniUserById: (id: number) => string,
    reportInlineCommentsView: LinkedModuleShape<InlineReportCommentView>
) => {
    const valueDOM = domParser.parseFromString(value, TEXT_HTML);
    // Here we get any elements which have legacy comments
    const commentedElements = Array.from(valueDOM.getElementsByClassName(HIGHLIGHT));
    // TinyComments stores its comment data in an HTML Comment appended to the narrative text
    // Here we extract that data so we can modify it if we find any legacy comments to migrate
    const tinyCommentsCommentNodes = filter(valueDOM.body.childNodes, (node) => {
        return node instanceof Comment && node.data.startsWith(TINYCOMMENTS);
    });
    let tinyCommentsCommentNode = tinyCommentsCommentNodes[0];
    // This is the header at the time of this integration, it could change in the future. We
    // need this default header to add Tiny compatible data to narratives which have not yet been
    // opened in Tiny or which don't have any comments.
    // The only known documentation for this header is on the HTML tab of this page:
    //    https://www.tiny.cloud/docs/tinymce/6/introduction-to-tiny-comments/#interactive-example
    const tinyCommentsCommentNodeHeader =
        tinyCommentsCommentNode instanceof Comment
            ? tinyCommentsCommentNode.data.split(BASE64_SPLIT_TOKEN)[0]
            : TINY_COMMENTS_DEFAULT_HEADER;

    if (!tinyCommentsCommentNode) {
        tinyCommentsCommentNode = new Comment(
            `${tinyCommentsCommentNodeHeader}${BASE64_SPLIT_TOKEN}${btoa('{}')}`
        );
        valueDOM.body.append(tinyCommentsCommentNode);
    }

    // We have to make sure any pre-existing comment get their TinyComments markup.
    for (const currentElement of commentedElements) {
        if (currentElement instanceof HTMLElement) {
            // We have a pre-existing comment that has not been migrated yet.
            const commentId = parseInt(currentElement.dataset.id || '', 10);
            if (tinyCommentsCommentNode instanceof Comment && commentId) {
                const conversationId = buildConversationUid(commentId);
                currentElement.dataset[MCE_ANNOTATION] = TINYCOMMENTS;
                currentElement.dataset[MCE_ANNOTATION_UID] = conversationId;
                // The TinyComments data is base64 encoded, here we decode it to get the JSON
                const tinyCommentsJSON = parseTinyCommentsData(tinyCommentsCommentNode);
                const mark43Comment = find(reportInlineCommentsView, (comment) => {
                    return comment.id === commentId;
                });
                if (mark43Comment) {
                    const tinyComment = mark43Comment.comment;
                    const creatorFullName = formatMiniUserById(tinyComment.createdBy);
                    // When multiple HTML elements reference the same comment, it is important for them to use the same
                    // `conversationId`. Otherwise, if the user opens the comment by clicking/selecting one element and
                    // resolves the comment, then the other elements will remain highlighted and unresolved because
                    // their `conversationId`s are different.
                    tinyCommentsJSON[conversationId] = {
                        uid: conversationId,
                        comments: [
                            {
                                uid: conversationId,
                                author: creatorFullName,
                                authorName: creatorFullName,
                                content: tinyComment.comment,
                                createdAt: tinyComment.createdDateUtc,
                                modifiedAt: tinyComment.updatedDateUtc,
                            },
                        ],
                    };
                    tinyCommentsCommentNode.data = `${tinyCommentsCommentNodeHeader}${BASE64_SPLIT_TOKEN}${btoaUnicode(
                        JSON.stringify(tinyCommentsJSON)
                    )}`;
                }
            }
        }
    }
    return valueDOM.body.innerHTML;
};

// Mentions Plugin
const CONTENT_EDITABLE = 'contenteditable';
const TRUE = 'true';
const DATA_MENTION_ID = 'data-mention-id';
const SPAN = 'span';
const SPAN_CLASS = 'mention';

interface MentionQuery {
    meta: {
        fetchType: string;
    };
}

interface Mention {
    id: string;
    name: string;
    text?: string;
    category: string;
}
interface MentionFetches {
    [key: string]: () => Mention[];
}

interface UserInfo {
    category: string;
    text: string;
}

interface MentionFetchType {
    meta: {
        fetchType: string;
    };
    text: string;
}

/**
 * The returned function is used as `mentions_fetch` in the TinyMCE mentions plugin.
 * https://www.tiny.cloud/docs/tinymce/6/mentions/#mentions_fetch
 */
export const createMentionsFetchHandler = (store: Store<RootState>) => {
    return (
        query: MentionQuery,
        success: (mention_fetches_for_type: Mention[], fetchTypes?: MentionFetchType[]) => void
    ) => {
        const currentReportId = currentReportSelector(store.getState())?.id;
        // Compute the mention data on the fly
        const mentionData = mentionNarrativeDataSelector(store.getState())(currentReportId);
        const mentionDataCategories = groupBy(mentionData, (entry) => {
            return entry.category;
        });
        const mentionCategories = keys(mentionDataCategories);
        const mention_fetch_types = map(
            filter(mentionCategories, (category) => {
                return category !== CATEGORY;
            }),
            (category) => {
                return {
                    meta: { fetchType: category },
                    text: category,
                };
            }
        );

        const mention_fetches: MentionFetches = {};
        for (let x = 0; x < mentionCategories.length; x++) {
            const category = mentionDataCategories[mentionCategories[x]][0].category;
            if (category !== CATEGORY) {
                mention_fetches[category] = () =>
                    map(mentionDataCategories[mentionCategories[x]], (categoryData) => {
                        return {
                            id: x.toString(),
                            name: `${categoryData.display.header}: ${categoryData.display.content}`,
                            text:
                                'contentToInsert' in categoryData.display
                                    ? categoryData.display.contentToInsert
                                    : categoryData.display.content?.toString(),
                            category: categoryData.category,
                        };
                    });
            }
        }

        if (query.meta?.fetchType) {
            success(mention_fetches[query.meta.fetchType]());
        } else {
            success([], mention_fetch_types);
        }
    };
};

/**
 * This function is used as the `mentions_menu_complete` handler in the TinyMCE mentions plugin.
 * https://www.tiny.cloud/docs/tinymce/6/mentions/#mentions_fetch
 */
export const insertMention = (
    editor: { getDoc: () => Document; getBody: () => HTMLElement },
    userInfo: UserInfo
) => {
    const span = editor.getDoc().createElement(SPAN);
    span.className = SPAN_CLASS;
    // store the user id in the mention so it can be identified later
    const m43MentionId = `mention_${new Date().getTime()}`;
    span.setAttribute(DATA_MENTION_ID, m43MentionId);
    // If the category is GUIDE
    if (userInfo.category === GUIDE_CATEGORY) {
        span.appendChild(domParser.parseFromString(userInfo.text, TEXT_HTML).body.children[0]);
    } else {
        span.appendChild(editor.getDoc().createTextNode(userInfo.text));
    }
    /* After we hand back control to TinyMCE replaces the span we hand it
     * with a different span. We defer to let TinyMCE do it's thing then
     * grab the resulting span using the m43mentionId and make it editable
     * then we remove the mention class because otherwise TinyMCE makes it
     * uneditable again when we refresh the page.
     * In the future if we decide to implement more interesting mentions
     * using the robust support TinyMCE provides for mention tooltips, etc.
     * we will have to revisit this approach or accept that mentions are
     * not editable.
     */
    defer(() => {
        const mentionSpan = editor
            ?.getBody()
            .querySelector(`[${DATA_MENTION_ID}="${m43MentionId}"]`);
        mentionSpan?.setAttribute(CONTENT_EDITABLE, TRUE);
        mentionSpan?.classList.remove('mention');
    });
    return span;
};

export function getEditorIdByReportId(reportId: number, isSummaryNarrative = false): string {
    return isSummaryNarrative ? getSummaryNarrativeName(reportId) : getNarrativeName(reportId);
}

export const hideTinyHeader = (editor: Editor) => {
    const tinyHeader = editor.getContainer().querySelector('.tox-editor-header');
    if (tinyHeader instanceof HTMLElement) {
        tinyHeader.style.display = 'none';
    }
};
export const showTinyHeader = (editor: Editor) => {
    const tinyHeader = editor.getContainer().querySelector('.tox-editor-header');
    if (tinyHeader instanceof HTMLElement) {
        tinyHeader.style.display = 'grid';
    }
};

export function toggleFullScreenEditor(editor: Editor): void {
    editor.execCommand('mceFullScreen');
}

export const getCurrentSelection = (editor: Editor) => {
    return editor?.getDoc()?.getSelection();
};

function hasDataset(node: Node | null): node is HTMLElement {
    return !!node && 'dataset' in node;
}

function hasCommentAnnotation(node: Node | null): boolean {
    return hasDataset(node) && node.dataset.mceAnnotation === 'tinycomments';
}

/**
 * Traverse through this node and all its ancestors to look for a TinyComments annotation.
 * The recursion stops when it reaches the html element within the iframe.
 */
function ancestorHasCommentAnnotation(node: Node | null): boolean {
    if (!node) {
        return false;
    }
    return hasCommentAnnotation(node) || ancestorHasCommentAnnotation(node.parentElement);
}

export const selectionIsComment = (selection: Selection | null) => {
    return selection && ancestorHasCommentAnnotation(selection.anchorNode);
};

export const selectionIsNotEmpty = (selection: Selection | null) => {
    if (selection?.rangeCount && selection?.rangeCount > 0) {
        const selectionRange = selection?.getRangeAt(0);
        return selectionRange && selectionRange.startOffset !== selectionRange.endOffset;
    } else {
        return false;
    }
};

export const getSummaryNarrativeName = (reportId: number) => `summary-narrative-${reportId}`;

export const getNarrativeName = (reportId: number) => `narrative-${reportId}`;

export const hideCommentSidebar = (editor: Editor) => {
    if (editor.queryCommandValue('ToggleSidebar')) {
        editor.execCommand('ToggleSidebar', false, 'showcomments');
    }
};

export const showCommentSidebar = (editor: Editor) => {
    if (!editor.queryCommandValue('ToggleSidebar')) {
        editor.execCommand('ToggleSidebar', false, 'showcomments');
        updateCommentSidebarPosition(editor);
    }
};

const enableInlineCommentsNoBaseCardSelector = createSelector(
    approvalStatusSelector,
    currentUserHasAbilitySelector,
    (approvalStatus, currentUserHasAbility) =>
        approvalStatus !== approvalStatusClientEnum.DRAFT &&
        currentUserHasAbility(abilitiesEnum.REPORTING.ADD_DELETE_REPORT_COMMENTS) &&
        currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_GENERAL)
);

export const onEditNarrativeCards = (
    reportId: number,
    isSummaryCard: boolean,
    state: RootState
) => {
    const inlineCommentsAllowed = enableInlineCommentsNoBaseCardSelector(state);

    /**
     * On Edit, need to make the comments resolveable and viewable
     * but no adding comments and need to make the editing toolbar show.
     *
     * Also, need to check if there is highlighted text on the screen.
     * If the text is selected (but not a comment yet) prior to editing then,
     * we want to remove the add comment box
     */
    const editorId = getEditorIdByReportId(reportId, isSummaryCard);
    withTinyEditor(editorId, 'MAYBE_DEFERRED', (editor: Editor) => {
        editor.mode.set('design');

        if (inlineCommentsAllowed) {
            // Check if there's a selection that's not a comment and hide the comments
            const selection = getCurrentSelection(editor);
            if (selection && !selectionIsComment(selection) && selectionIsNotEmpty(selection)) {
                hideCommentSidebar(editor);
            }
        }
    });
};

/**
 * Manually open the mentions menu (or any other menu that would otherwise open )
 *
 * Note that this function will not always open the mentions menu
 * when invoked.  It will only open the mentions menu
 * if the cursor is positioned over a text node that would
 * normally open up the mentions menu on keypress.
 *
 * For instance, if the cursor is nowhere near a '@' symbol
 * when this function is invoked, nothing will happen
 */
export const maybeOpenMentionsMenu = (editor: Editor) => {
    editor.execCommand('mceAutocompleterReload');
};

/**
 * Given a node, determine the node/offset at
 * which the first terminal word boundary occurs (for mentions)
 *
 * This is exported primarily for testing purposes
 */
export const findEndOfMentionFromSelection = ({
    node,
    offset,
    rootNode,
}: {
    node: Node;
    offset: number;
    rootNode: Node;
}) => {
    const whiteSpaceCharacters = [
        // A non-breaking white-space
        String.fromCharCode(160),
        // plain white-space
        ' ',
    ];
    if (!window.tinymce) {
        // eslint-disable-next-line no-console
        console.error(
            'Could not find global tinymce instance; bailing out of mention cursor movement'
        );
        return;
    }
    // eslint-disable-next-line new-cap
    const textSeeker = window.tinymce.dom.TextSeeker(window.tinymce.dom.DOMUtils.DOM);

    const getIsMentionSelection = (): boolean => {
        // construct the text string leading up to our cursor
        let combinedString = '';
        // Traverse back through every character
        // Note - we use tinymce helpers here because it specifically
        // iterates through all /inline/ elements within the same
        // /block/ parent.
        textSeeker.backwards(
            node,
            offset,
            (textNode, offset, text) => {
                combinedString = text.slice(0, offset) + combinedString;
                return -1;
            },
            rootNode
        );

        for (let i = combinedString.length - 1; i >= 0; i--) {
            const currentChar = combinedString[i];
            if (whiteSpaceCharacters.includes(currentChar)) {
                return false;
            } else if (currentChar === '@') {
                const prevChar = combinedString[i - 1];
                // If there is no previous char
                // or if the previous character is a whitespace character
                // then we are at the start of a mention
                if (!prevChar || whiteSpaceCharacters.includes(prevChar)) {
                    return true;
                }
                return false;
            }
        }
        // If we got to the end, then we don't have a mention
        return false;
    };

    const getEndOfMentionSelection = () => {
        // construct an annotated list of chars that occur within the same
        // block parent following our cursor
        const characterList: {
            char: string;
            offset: number;
            node: Node;
        }[] = [];
        // Traverse forwards through every character
        // Note - we use tinymce helpers here because it specifically
        // iterates through all /inline/ elements within the same
        // /block/ parent.
        textSeeker.forwards(
            node,
            offset,
            (textNode, offset, text) => {
                characterList.push(
                    ...text
                        .slice(offset)
                        .split('')
                        .map((char, idx) => {
                            return {
                                char,
                                offset: idx + offset + 1,
                                node: textNode,
                            };
                        })
                );
                return -1;
            },
            rootNode
        );

        return (
            characterList.find((char, idx) => {
                const nextChar = characterList[idx + 1];
                // If we find a whitespace character, then we can stop
                // searching and return this character
                if (nextChar && whiteSpaceCharacters.includes(nextChar.char)) {
                    return true;
                }
                return false;
            }) ||
            // If we didn't find any matches, it means that
            // there were no white space characters, and the terminal
            // character is the last one in our list
            last(characterList)
        );
    };

    if (getIsMentionSelection()) {
        const endOfMentionSelection = getEndOfMentionSelection();

        if (endOfMentionSelection) {
            return {
                container: endOfMentionSelection.node,
                offset: endOfMentionSelection.offset,
            };
        }
        return undefined;
    }
    return undefined;
};

/**
 * Open the mentions menu, and move the cursor to the end of the mention text
 *
 * We move the cursor because if a user clicks in the middle of the mention text
 * and then clicks on a mention option, it will only replace the mention text
 * up to the cursor. By moving the cursor to the end of the mention text,
 * we can ensure that the entire mention text is replaced
 */
export const maybeOpenMentionsMenuAndMoveCursorToEndOfMention = (editor: Editor) => {
    const selection = editor.selection.getSel();
    if (selection?.anchorNode) {
        const endOfMention = findEndOfMentionFromSelection({
            node: selection.anchorNode,
            offset: selection.anchorOffset,
            rootNode: editor.editorContainer,
        });
        if (endOfMention) {
            editor.selection.setCursorLocation(endOfMention.container, endOfMention.offset);
            maybeOpenMentionsMenu(editor);
        }
    }
};
