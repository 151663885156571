import { SideNav, SideNavItem } from '@arc/side-nav';
import React from 'react';
import { InjectedRouter, withRouter } from 'react-router';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import { BaseLinkWithAnalytics } from '../../../core/components/links/Link';

const strings = componentStrings.eFiles.core.sidebar;

const EFileSidebarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 180px;
    padding: var(--arc-space-4);
    background-color: var(--arc-colors-surface-background);
    border-right: 1px solid var(--arc-colors-border-default);
`;

type EFileSidebarT = {
    eFileId: number;
    router: InjectedRouter;
};

const getSidebarLinks = (eFileId: number) => [
    {
        route: `/e-file/${eFileId}/summary`,
        label: strings.summaryLink,
        testId: testIds.E_FILE_SIDEBAR_SUMMARY,
    },
    {
        route: `/e-file/${eFileId}/defendants`,
        label: strings.defendantsLink,
        testId: testIds.E_FILE_SIDEBAR_DEFENDANTS,
    },
    {
        route: `/e-file/${eFileId}/victims`,
        label: strings.victimsLink,
        testId: testIds.E_FILE_SIDEBAR_VICTIMS,
    },
    {
        route: `/e-file/${eFileId}/material`,
        label: strings.materialLink,
        testId: testIds.E_FILE_SIDEBAR_MATERIAL,
    },
    {
        route: `/e-file/${eFileId}/tasks`,
        label: strings.tasksLink,
        testId: testIds.E_FILE_SIDEBAR_TASKS,
    },
];

export const EFileSidebar = withRouter(({ eFileId, router }: EFileSidebarT) => {
    return (
        <EFileSidebarWrapper data-test-id={testIds.E_FILE_SIDEBAR}>
            <SideNav>
                {getSidebarLinks(eFileId).map(({ route, label, testId }) => (
                    <SideNavItem
                        as={BaseLinkWithAnalytics}
                        key={route}
                        to={route}
                        isActive={router.isActive(route)}
                        label={label}
                        testId={testId}
                    />
                ))}
            </SideNav>
        </EFileSidebarWrapper>
    );
});
