import { chain, findLastIndex, includes, isArray, slice, sortBy } from 'lodash';
import {
    FieldDetail,
    FieldConfiguration,
    Rule,
    RuleActionedField,
    RuleCondition,
    RuleConditionArg,
    RuleConfigurationContext,
    RuleTypeEnum,
    RefContextEnum,
    RuleComparisonOperatorEnum,
} from '@mark43/rms-api';
import { ruleComparisonOperators, ruleFieldGetters } from 'arbiter-mark43';

import { LOCATION_ENTITY_LINK_LINK_TYPE } from '~/client-common/core/enums/universal/fields';

import logicalStructures from '../constants/logicalStructures';
import { RuleConditionMFTFormDataShape } from '../state/forms/ruleConditionAdminForm';

export function sortRuleConfigurationContextsByContextName(
    ruleConfigurationContexts: RuleConfigurationContext[] = []
) {
    return sortBy(ruleConfigurationContexts, ({ context }) =>
        context ? RefContextEnum[context].displayName : undefined
    );
}

export const RULE_CONDITION_TYPES = {
    booleanOperator: 'BOOLEAN_OPERATOR',
    selfComparison: 'SELF_COMPARISON',
    staticValueComparison: 'STATIC_VALUE_COMPARISON',
    otherFieldComparison: 'OTHER_FIELD_COMPARISON',
    noOperator: 'NO_OPERATOR',
};
export const RULE_CONDITION_FIELDS = {
    ruleBooleanOperator: 'ruleBooleanOperator',
    ruleFieldGetter: 'ruleFieldGetter',
    fieldConfigurationId: 'fieldConfigurationId',
    ruleComparisonOperator: 'ruleComparisonOperator',
    staticValue: 'staticValue',
    ruleFieldGetter2: 'ruleFieldGetter2',
    fieldConfiguration2Id: 'fieldConfiguration2Id',
};
export const validFieldsByRuleConditionType = {
    [RULE_CONDITION_TYPES.booleanOperator]: [RULE_CONDITION_FIELDS.ruleBooleanOperator],
    [RULE_CONDITION_TYPES.selfComparison]: [
        RULE_CONDITION_FIELDS.ruleFieldGetter,
        RULE_CONDITION_FIELDS.fieldConfigurationId,
        RULE_CONDITION_FIELDS.ruleComparisonOperator,
    ],
    [RULE_CONDITION_TYPES.staticValueComparison]: [
        RULE_CONDITION_FIELDS.ruleFieldGetter,
        RULE_CONDITION_FIELDS.fieldConfigurationId,
        RULE_CONDITION_FIELDS.ruleComparisonOperator,
        RULE_CONDITION_FIELDS.staticValue,
    ],
    [RULE_CONDITION_TYPES.otherFieldComparison]: [
        RULE_CONDITION_FIELDS.ruleFieldGetter,
        RULE_CONDITION_FIELDS.fieldConfigurationId,
        RULE_CONDITION_FIELDS.ruleComparisonOperator,
        RULE_CONDITION_FIELDS.ruleFieldGetter2,
        RULE_CONDITION_FIELDS.fieldConfiguration2Id,
    ],
    [RULE_CONDITION_TYPES.noOperator]: [
        RULE_CONDITION_FIELDS.ruleFieldGetter,
        RULE_CONDITION_FIELDS.fieldConfigurationId,
    ],
};

type NestedRuleConditions = (RuleCondition | NestedRuleConditions)[];

type RuleConditionViewModel = RuleCondition & {
    fieldDetail: FieldDetail;
    fieldDetail2: FieldDetail;
    fieldConfiguration: FieldConfiguration;
    fieldConfiguration2: FieldConfiguration;
    ruleConditionArgs: RuleConditionArg[];
    staticValueDescription: string;
};

export type NestedRuleConditionViewModels =
    | RuleConditionViewModel
    | (RuleConditionViewModel | NestedRuleConditionViewModels)[];

/**
 * Creates 3-tuples of [BOOLEAN_OPERATOR, FIELD_OPERATOR, FIELD_OPERATOR],
 * where either FIELD_OPERATOR can be one of:
 *   - another 3-tuple of the same values
 *   - a comparison operator
 */
export function nestConditions(nextSet: NestedRuleConditions): NestedRuleConditions {
    const lastBooleanIndex = findLastIndex(nextSet, 'ruleBooleanOperator');

    if (lastBooleanIndex < 0) {
        return nextSet;
    }

    const lastOperationIndex = lastBooleanIndex + 3; // +2 noninclusive
    const headTokens = slice(nextSet, 0, lastBooleanIndex);
    const evalTokens = [slice(nextSet, lastBooleanIndex, lastOperationIndex)];
    const tailTokens = slice(nextSet, lastOperationIndex);

    return nestConditions(chain(headTokens).concat(evalTokens, tailTokens).value());
}

export function getLogicalStructureForStructuredConditions({
    rule,
    structuredConditions,
    sortedConditions,
    ruleActionedFields,
}: {
    rule: Rule;
    structuredConditions: NestedRuleConditionViewModels;
    sortedConditions: NestedRuleConditions;
    ruleActionedFields: RuleActionedField[];
}): keyof typeof logicalStructures {
    const { ruleType } = rule;

    if (
        isArray(structuredConditions) &&
        structuredConditions.length === 3 &&
        !isArray(structuredConditions[0]) &&
        !isArray(structuredConditions[1]) &&
        !isArray(structuredConditions[2])
    ) {
        // when the Rule has exactly 3 RuleConditions
        const [condition0, condition1, condition2] = structuredConditions;

        if (
            ruleType === RuleTypeEnum.CONDITIONAL.name &&
            condition0.ruleBooleanOperator === 'CAND' &&
            condition2.ruleFieldGetter === ruleFieldGetters.RETURN_ARGS
        ) {
            return logicalStructures.NIBRS_PROPERTY.name;
        }
        if (
            condition0.ruleBooleanOperator === 'XOR' &&
            condition1.ruleFieldGetter === ruleFieldGetters.GET_ATTRIBUTE_IS_OTHER &&
            condition2.ruleComparisonOperator === ruleComparisonOperators.IS_NOT_EMPTY
        ) {
            return logicalStructures.VALIDATION_ATTRIBUTE_OTHER.name;
        }
        if (
            condition0.ruleBooleanOperator === 'IMPLIES' &&
            condition1.fieldDetail?.fieldName === LOCATION_ENTITY_LINK_LINK_TYPE
        ) {
            return logicalStructures.VALIDATION_SUBDIVISION.name;
        }
        if (
            includes(['IMPLIES', 'OR'], condition0.ruleBooleanOperator) &&
            condition1.ruleComparisonOperator &&
            condition1.fieldConfigurationId &&
            !condition1.fieldConfiguration2Id &&
            condition2.ruleComparisonOperator &&
            condition2.fieldConfigurationId &&
            !condition2.fieldConfiguration2Id
        ) {
            if (ruleActionedFields.length > 0) {
                return logicalStructures.OR_2_N_ITEMS.name;
            }
        }
    } else if (sortedConditions.length >= 9) {
        // when the Rule has many RuleConditions
        return logicalStructures.VALIDATION_LONG.name;
    } else if (!isArray(structuredConditions)) {
        // when the Rule has only 1 RuleCondition
        const {
            fieldConfigurationId,
            fieldConfiguration2Id,
            ruleComparisonOperator,
            ruleFieldGetter,
            staticValue,
        } = structuredConditions;
        if (
            ruleType === RuleTypeEnum.VALIDATION.name &&
            ruleComparisonOperator === ruleComparisonOperators.IS_NOT_EMPTY
        ) {
            if (
                ruleActionedFields.length === 1 &&
                ruleActionedFields[0].fieldConfigurationId === fieldConfigurationId
            ) {
                return logicalStructures.VALIDATION_IS_NOT_EMPTY_1.name;
            }
            return logicalStructures.VALIDATION_IS_NOT_EMPTY_2.name;
        }
        if (ruleFieldGetter === ruleFieldGetters.GET_ATTRIBUTE_IS_OTHER) {
            if (
                ruleComparisonOperator === ruleComparisonOperators.IS_NOT_EQUAL_TO &&
                staticValue === 'true'
            ) {
                return logicalStructures.HIDE_ATTRIBUTE_OTHER.name;
            }
            return logicalStructures.HIDE_ATTRIBUTE_OTHER_2.name;
        }
        if (
            ruleType === RuleTypeEnum.VALIDATION.name &&
            fieldConfigurationId &&
            fieldConfiguration2Id &&
            ruleComparisonOperator
        ) {
            return logicalStructures.VALIDATION_COMPARE_2.name;
        }
        if (
            ruleType === RuleTypeEnum.HIDE.name &&
            fieldConfigurationId &&
            fieldConfiguration2Id &&
            ruleComparisonOperator
        ) {
            return logicalStructures.HIDE_COMPARE_2.name;
        }
        if (
            ruleType === RuleTypeEnum.HIDE.name &&
            fieldConfigurationId &&
            staticValue &&
            ruleComparisonOperator
        ) {
            return logicalStructures.HIDE_FIELDS_1.name;
        }
    }

    // other
    if (ruleType === RuleTypeEnum.HIDE.name) {
        return logicalStructures.OTHER_HIDE.name;
    } else if (ruleType === RuleTypeEnum.VALIDATION.name) {
        if (ruleActionedFields.length === 0) {
            return logicalStructures.VALIDATION_NO_RAF.name;
        }
        return logicalStructures.OTHER_VALIDATION.name;
    } else if (ruleType === RuleTypeEnum.CONDITIONAL.name) {
        return logicalStructures.OTHER_CONDITIONAL.name;
    } else {
        return logicalStructures.OTHER.name;
    }
}

const findRelatedTokenOrders = (
    nestedConditions: (RuleConditionViewModel | NestedRuleConditionViewModels)[],
    targetTokenOrder: number
) => {
    const tokenOrders: number[] = [];

    const findTokenOrderInNestedConditions = (
        array: (RuleConditionViewModel | NestedRuleConditionViewModels)[]
    ) => {
        for (const item of array) {
            if (Array.isArray(item)) {
                findTokenOrderInNestedConditions(item);
            } else if (Number(item.tokenOrder) === targetTokenOrder) {
                collectDescendants(array);
                return;
            }
        }
    };

    const collectDescendants = (item: NestedRuleConditionViewModels[]) => {
        item.forEach((subItem) => {
            if (Array.isArray(subItem)) {
                collectDescendants(subItem);
            } else if (subItem.tokenOrder && Number(subItem.tokenOrder) !== targetTokenOrder) {
                tokenOrders.push(Number(subItem.tokenOrder));
            }
        });
    };

    findTokenOrderInNestedConditions(nestedConditions);
    return tokenOrders;
};

export const determineConditionType = (ruleCondition: RuleCondition) => {
    if (!ruleCondition) {
        return RULE_CONDITION_TYPES.otherFieldComparison;
    }
    switch (true) {
        case !!ruleCondition.ruleBooleanOperator:
            return RULE_CONDITION_TYPES.booleanOperator;
        case ruleCondition.ruleComparisonOperator === RuleComparisonOperatorEnum.IS_EMPTY.name ||
            ruleCondition.ruleComparisonOperator === RuleComparisonOperatorEnum.IS_NOT_EMPTY.name:
            return RULE_CONDITION_TYPES.selfComparison;
        case !!ruleCondition.staticValue:
            return RULE_CONDITION_TYPES.staticValueComparison;
        case !!ruleCondition.fieldConfigurationId && !ruleCondition.ruleComparisonOperator:
            return RULE_CONDITION_TYPES.noOperator;
        default:
            return RULE_CONDITION_TYPES.otherFieldComparison;
    }
};

export const separateRuleConditionArgsByGetter = ({
    ruleCondition,
    ruleConditionArgs,
}: {
    ruleCondition: RuleConditionMFTFormDataShape;
    ruleConditionArgs: Partial<RuleConditionArg>[];
}) => {
    if (!ruleCondition?.id) {
        return {
            ruleFieldGetter1Args: [],
            ruleFieldGetter2Args: [],
        };
    }

    const relevantRuleConditionArgs = Object.values(ruleConditionArgs).filter(
        ({ ruleConditionId }) => ruleCondition.id === ruleConditionId
    );

    if (!relevantRuleConditionArgs.length) {
        return { ruleFieldGetter1Args: [], ruleFieldGetter2Args: [] };
    }

    const ruleFieldGetter1Args = relevantRuleConditionArgs.filter(
        // @ts-expect-error getterNum is a number
        ({ getterNum }) => parseInt(getterNum) === 1
    );
    const ruleFieldGetter2Args = relevantRuleConditionArgs.filter(
        // @ts-expect-error getterNum is a number
        ({ getterNum }) => parseInt(getterNum) === 2
    );
    return { ruleFieldGetter1Args, ruleFieldGetter2Args };
};

export const convertBooleanToNonBoolean = (
    conditions: RuleConditionMFTFormDataShape[],
    index: number,
    structuredConditions: NestedRuleConditionViewModels,
    ruleConditionForRulesAdminForm: RuleConditionMFTFormDataShape
) => {
    let tokenOrdersToRemove: number[] = [];
    if (!Array.isArray(structuredConditions)) {
        // this should never happen here because we add dummy conditions
        // to keep the boolean triple structure
        // @ts-expect-error api type is incorrect, tokenOrder is a number
        tokenOrdersToRemove = [structuredConditions.tokenOrder];
    } else {
        tokenOrdersToRemove = findRelatedTokenOrders(
            structuredConditions,
            // @ts-expect-error api type is incorrect, tokenOrder is a number
            ruleConditionForRulesAdminForm.tokenOrder
        );
    }
    const updatedConditions = conditions.filter(
        ({ tokenOrder }) => tokenOrder && !tokenOrdersToRemove.includes(tokenOrder)
    );

    const conditionsWithUpdatedTokenOrder = updateTokenOrder(updatedConditions, index);

    return conditionsWithUpdatedTokenOrder;
};

export const convertNonBooleanToBoolean = (
    conditions: RuleConditionMFTFormDataShape[],
    index: number,
    nextDummyId: number
) => {
    const updatedConditions = [...conditions];
    let dummyId = nextDummyId;
    // Insert two dummy non-boolean conditions
    const dummyCondition1: RuleConditionMFTFormDataShape = {
        id: dummyId--,
        tokenOrder: index + 1,
    };
    const dummyCondition2: RuleConditionMFTFormDataShape = {
        id: dummyId--,
        tokenOrder: index + 2,
    };

    updatedConditions.splice(index + 1, 0, dummyCondition1, dummyCondition2);

    const conditionsWithUpdatedTokenOrder = updatedConditions.map((condition, idx) => ({
        ...condition,
        tokenOrder: idx + 1,
    }));

    return conditionsWithUpdatedTokenOrder;
};

const updateTokenOrder = (conditions: RuleConditionMFTFormDataShape[], startIndex: number) => {
    return conditions.map((condition, index) => {
        if (index >= startIndex) {
            return { ...condition, tokenOrder: index + 1 };
        }
        return condition;
    });
};

export const getNextDummyId = (preExistingRecords: { id?: number }[]) => {
    if (!preExistingRecords.length) {
        return -1;
    }
    const lowestId = Math.min(...preExistingRecords.map(({ id }) => id ?? 0));
    return lowestId < 0 ? lowestId - 1 : -1;
};
