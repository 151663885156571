import { Flex } from '@arc/layout';
import { cssVar } from 'arc';
import invariant from 'invariant';
import Konva from 'konva';
import {
    DiagramCategoryEnum,
    DiagramCategoryEnumType,
    DiagramTypeEnum,
    Mark43File,
} from '@mark43/rms-api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { exportStageSVG } from 'react-konva-to-svg';
import styled from 'styled-components';
import { OverlayIdEnumType } from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { logError } from '../../../../../../../core/logging';
import { useFileUploader } from '../../../../../../attachments/files/hooks/useFileUploader';
import errorToMessage from '../../../../../../core/errors/utils/errorToMessage';
import { useOverlayStore } from '../../../../../../core/overlays/hooks/useOverlayStore';
import { useScreenBreakpoint } from '../../../../../../core/utils/useScreenBreakpoint';
import {
    widgetTypeToDefaultValue,
    STAGE_CENTER,
    DEFAULT_WIDGET_POSITION,
    FONT_SIZE,
} from '../config';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { useCrashDiagramHistory } from '../context/CrashDiagramHistoryContext';
import { createWidget, getCrashDiagramModalOverlayId } from '../helpers';
import {
    addAdditionalAttributesToSVGWidgetTags,
    addDataWidgetTypeAttributeToBackgroundImageTag,
} from '../serializer';
import { widgetTypes } from '../types';

import { CrashDiagram } from './CrashDiagram';
import { CrashDiagramActionButton } from './CrashDiagramActionButton';
import { DiagramModal, ModalContentWrapper } from './DiagramModal';
import { SelectedWidgetMenu } from './SelectedWidgetMenu';
import { TemplateThumbnail } from './TemplateThumbnail';

const DiagramCanvasWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: end;
    position: relative;
`;

const SelectedWidgetActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    overflow: auto;
`;

const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    overflow: auto;
`;

const FooterActionButton = styled(CrashDiagramActionButton)`
    margin-right: ${cssVar('arc.space.1')};
`;

type HeaderActionButtonProps = {
    isMobile?: boolean;
};
const HeaderActionButton = styled(CrashDiagramActionButton)<HeaderActionButtonProps>`
    padding: ${({ isMobile }) => (isMobile ? 0 : 'auto')};
`;

const crashDiagramModalStrings = componentStrings.dragon.crashDiagram.CrashDiagramModal;
const crashDiagramModalErrorMessageStrings = crashDiagramModalStrings.errorMessages;

type CrashDiagramModalPropsT = {
    identifier: string;
    overlayId: OverlayIdEnumType;
    onSave: (file: Mark43File) => void;
    openAddMapToBackground: () => void;
};

const getCrashDiagramFileName = (identifier: string) => {
    return `crash-diagram-${identifier}.svg`;
};

export const CrashDiagramModal: React.FC<CrashDiagramModalPropsT> = ({
    identifier,
    onSave,
    openAddMapToBackground,
}: CrashDiagramModalPropsT) => {
    const stageRef = useRef<Konva.Stage>(null);
    const overlayStore = useOverlayStore();
    const overlayId = getCrashDiagramModalOverlayId(identifier);
    const {
        widgets,
        clear,
        reset,
        addWidget,
        clearSelection,
        backgroundImage,
        assets,
        fetchAssets,
        isLoadingAssets: isLoading,
    } = useCrashDiagram();

    const { undo, redo, canPerformRedoAction, canPerformUndoAction } = useCrashDiagramHistory();
    const { isMobile } = useScreenBreakpoint();
    const [selectedAssetType, setSelectedAssetType] = useState<DiagramCategoryEnumType | undefined>(
        DiagramCategoryEnum.CRASH_BACKGROUND.name
    );
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onUploadStart = useCallback(() => {
        overlayStore.setLoading(overlayId, true);
    }, [overlayId, overlayStore]);

    const onUploadComplete = useCallback(() => {
        overlayStore.close(overlayId);
    }, [overlayId, overlayStore]);

    const { callResource: saveCrashDiagram, loading } = useFileUploader({
        onSave,
        onUploadStart,
        onUploadComplete,
    });

    const handleSetAssetType = (assetType?: DiagramCategoryEnumType) => {
        setSelectedAssetType((previousAssetType) => {
            return previousAssetType === assetType ? undefined : assetType;
        });
    };

    const handleAfterClose = useCallback(() => {
        setIsModalOpen(false);
        reset();
    }, [reset]);

    const onAddText = useCallback(() => {
        const widgetType = widgetTypes.TEXT;
        const value = widgetTypeToDefaultValue[widgetType] ?? '';
        const widget = createWidget({
            type: widgetType,
            value,
            position: {
                ...DEFAULT_WIDGET_POSITION,
                x: STAGE_CENTER[0],
                y: STAGE_CENTER[1],
                height: FONT_SIZE,
                offsetY: FONT_SIZE / 2,
            },
            opacity: 1,
        });
        addWidget({
            widget,
        });
    }, [addWidget]);

    const handleOnSave = useCallback(async () => {
        const konvaStage = stageRef?.current?.getStage();

        if (!konvaStage || !backgroundImage) {
            return;
        }

        clearSelection();

        const crashDiagramSvgString = await exportStageSVG(konvaStage);

        invariant(
            typeof crashDiagramSvgString === 'string',
            'Failed to export crash diagram as an svg string'
        );

        let modifiedCrashDiagramSvgString = addDataWidgetTypeAttributeToBackgroundImageTag(
            crashDiagramSvgString,
            backgroundImage
        );

        invariant(
            !!modifiedCrashDiagramSvgString,
            'Crash Diagram SVG is missing a background. Invalid Format'
        );

        modifiedCrashDiagramSvgString = addAdditionalAttributesToSVGWidgetTags(
            modifiedCrashDiagramSvgString,
            widgets
        );

        const crashDiagramBlob = new Blob([modifiedCrashDiagramSvgString], {
            type: 'image/svg+xml;charset=utf-8',
        });
        const formData = new window.FormData();
        formData.append('my-file', crashDiagramBlob, getCrashDiagramFileName(identifier));

        try {
            await saveCrashDiagram(formData);
        } catch (err) {
            logError(
                errorToMessage(err, crashDiagramModalErrorMessageStrings.unhandledUploadException),
                {
                    crashDiagramSvgString: modifiedCrashDiagramSvgString,
                }
            );
            throw err;
        }
    }, [identifier, stageRef, clearSelection, saveCrashDiagram, backgroundImage, widgets]);

    useEffect(() => {
        const backgroundAssets =
            assets[DiagramTypeEnum.CRASH.name][DiagramCategoryEnum.CRASH_BACKGROUND.name];
        if (backgroundAssets.isFetched || isLoading || !isModalOpen) {
            return;
        }

        fetchAssets(DiagramTypeEnum.CRASH.name, DiagramCategoryEnum.CRASH_BACKGROUND.name);
    }, [assets, fetchAssets, isLoading, isModalOpen]);

    return (
        <DiagramModal
            overlayId={overlayId}
            okText={crashDiagramModalStrings.okText}
            title={crashDiagramModalStrings.title}
            onAfterClose={handleAfterClose}
            onAfterOpen={() => setIsModalOpen(true)}
            onSave={handleOnSave}
            saveDisabled={widgets.length === 0 || !backgroundImage}
            errorMessages={loading.errorMessage ? [loading.errorMessage] : undefined}
        >
            <ModalContentWrapper>
                <ActionsContainer>
                    <Flex justifyContent="start">
                        <HeaderActionButton
                            disabled={widgets.length === 0}
                            leadingVisual="CloseX"
                            variant="ghost"
                            onClick={clear}
                            label={crashDiagramModalStrings.clearButtonText}
                            isMobile={isMobile}
                        />
                    </Flex>
                    <SelectedWidgetActionsContainer>
                        <SelectedWidgetMenu />
                    </SelectedWidgetActionsContainer>
                </ActionsContainer>
                <Flex justifyContent="end">
                    <HeaderActionButton
                        disabled={!canPerformUndoAction}
                        leadingVisual="Undo"
                        variant="ghost"
                        onClick={undo}
                        label={crashDiagramModalStrings.undoButtonText}
                        isMobile={isMobile}
                    />

                    <HeaderActionButton
                        disabled={!canPerformRedoAction}
                        leadingVisual="Redo"
                        variant="ghost"
                        onClick={redo}
                        label={crashDiagramModalStrings.redoButtonText}
                        isMobile={isMobile}
                    />
                </Flex>
                <DiagramCanvasWrapper>
                    <CrashDiagram
                        selectedAssetType={selectedAssetType}
                        stageRef={stageRef}
                        handleSetAssetType={handleSetAssetType}
                        openAddMapToBackground={openAddMapToBackground}
                    />
                </DiagramCanvasWrapper>
                <FooterContainer>
                    <Flex>
                        <FooterActionButton
                            leadingVisual="Title"
                            variant="outline"
                            colorVariant="black"
                            onClick={onAddText}
                            label={crashDiagramModalStrings.textButtonText}
                        />
                        {Object.values(DiagramCategoryEnum).map(
                            ({
                                diagramTypeId,
                                displayName,
                                name,
                            }: {
                                diagramTypeId: number;
                                displayName: string;
                                name: DiagramCategoryEnumType;
                            }) => (
                                <FooterActionButton
                                    key={diagramTypeId}
                                    isActive={selectedAssetType === name}
                                    variant="outline"
                                    colorVariant="black"
                                    onClick={() => handleSetAssetType(name)}
                                    label={displayName}
                                />
                            )
                        )}
                    </Flex>
                    <TemplateThumbnail onClick={handleSetAssetType} />
                </FooterContainer>
            </ModalContentWrapper>
        </DiagramModal>
    );
};
