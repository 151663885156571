import {
    createField,
    createFormConfiguration,
    lifecycleOptions,
    MFTFormConfiguration,
} from 'markformythree';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

const eFileProfilesVictimWitnessSearchFormConfig: MFTFormConfiguration = createFormConfiguration({
    firstName: createField<string>({}),
    lastName: createField<string>({}),
    sentToCPS: createField<string>({}),
    involvement: createField<string>({}),
    keyWitness: createField<string>({}),
});

export type EFileProfilesVictimWitnessSearchFormConfig = typeof eFileProfilesVictimWitnessSearchFormConfig;

const eFileProfilesVictimWitnessSearchForm = {
    name: formClientEnum.E_FILES_SEARCH_PROFILES_VICTIM_WITNESS_FORM,
    configuration: eFileProfilesVictimWitnessSearchFormConfig,
    lifecycle: lifecycleOptions.REGISTER_AND_RETAIN,
};

export default eFileProfilesVictimWitnessSearchForm;
