import React from 'react';
import styled from 'styled-components';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { TableCellComponentProps } from '../tableTypes';
import { CaseTaskViewModel } from '../../../../../modules/cases/task-list/state/ui/types';
import { isColumnKeyPropObject } from '../tableHelpers';

const strings = componentStrings.core.tables.caseTasksTable.TaskDescriptionCell;

const OverdueText = styled.span`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-sm);
    font-style: italic;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.red};
`;

type TaskDescriptionCellProps = TableCellComponentProps<CaseTaskViewModel>;

function TaskDescriptionCell(props: TaskDescriptionCellProps): JSX.Element | null {
    if (isColumnKeyPropObject(props)) {
        return null;
    }
    const { isOverdue, statusParentAttrId } = getViewModelProperties(props);
    const isNotApplicable = statusParentAttrId === globalAttributes.taskStatusGlobal.notApplicable;

    return (
        <>
            {props.description}{' '}
            {isOverdue && !isNotApplicable && <OverdueText>{strings.overdue}</OverdueText>}
        </>
    );
}

export default TaskDescriptionCell;
