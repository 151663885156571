import React, { useEffect } from 'react';
import styled from 'styled-components';
import { HStack, Spinner, Text } from 'arc';
import type { MapPropsT } from '../types';
import componentStrings from '../../strings/componentStrings';
import { useEsriSimpleBaseMap } from '../esri-hooks/useEsriSimpleBaseMap';
import { useGeoJSONLayer } from '../esri-hooks/useGeoJSONLayer';
import { useSimpleZoomControls } from '../esri-hooks/useSimpleZoomControls';
import { useStaticPolylineFeatureLayer } from '../esri-hooks/useStaticPolylineFeatureLayer';
import { useStaticPolygonFeatureLayer } from '../esri-hooks/useStaticPolygonFeatureLayer';
import { useSelectedStaticLocationFeatureLayer } from '../esri-hooks/useStaticSelectedLocationFeatureLayer';
import { useNearestLocationFeatureLayer } from '../esri-hooks/useNearestLocationFeatureLayer';
import { useMapApi } from '../esri-hooks/useMapApi';
import {
    ESRI_SIMPLE_MAP_DEFAULT_BASE_MAP_MODE,
    ESRI_SIMPLE_MAP_DEFAULT_ZOOM_LEVEL,
} from './config';

const strings = componentStrings.core.maps.Esri;

type OuterWrapperT = {
    width: number;
    height: number;
};

const OuterWrapper = styled.div<OuterWrapperT>`
    border: 1px solid;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
`;

const InnerWrapper = styled.div`
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: relative;
    right: 0;
    bottom: 0;
`;

export const EsriSimpleMap: React.FC<MapPropsT> = (props) => {
    const {
        defaultCenter,
        centerForUpdate,
        mapMode = ESRI_SIMPLE_MAP_DEFAULT_BASE_MAP_MODE,
        zoomForUpdate,
        zoom = ESRI_SIMPLE_MAP_DEFAULT_ZOOM_LEVEL,
        selectedLocation,
        polylines,
        polygons,
        webmapPortalId,
        enableMove,
        enableZoom,
        zoomToPolygons,
        width,
        height,
        zoomControlPosition,
        zoomButtonStyle,
        geoJSON,
        onClick,
        onChange,
        onLoadingStatusChange,
        onErrorStatusChange,
        onMapLoadingError,
    } = props;

    const { baseMap, mapView, webmap, isLoading: isMapViewLoading, isError: hasMapViewError } = useEsriSimpleBaseMap({
        center: defaultCenter,
        zoomLevel: zoom,
        enableMove,
        enableZoom,
        mapMode,
        mapOnChange: onChange,
        webmapPortalId,
        mapOnClick: onClick,
        onMapLoadingError,
    });

    useEffect(() => {
        if (onLoadingStatusChange) {
            onLoadingStatusChange(isMapViewLoading);
        }
    }, [isMapViewLoading, onLoadingStatusChange]);

    useEffect(() => {
        if (onErrorStatusChange) {
            onErrorStatusChange(hasMapViewError);
        }
    }, [hasMapViewError, onErrorStatusChange]);

    const zoomControls = useSimpleZoomControls({
        mapView,
        centerForUpdate,
        displayOnlyMode: !enableZoom,
        zoomForUpdate,
        zoom,
        zoomControlPosition,
        zoomButtonStyle,
    });

    useStaticPolylineFeatureLayer({
        polylines,
        webmap,
    });

    useStaticPolygonFeatureLayer({
        polygons,
        webmap,
        mapView,
        zoomToPolygons,
    });

    const { layer: selectedLocationLayer } = useSelectedStaticLocationFeatureLayer({
        selectedLocation,
        webmap,
    });

    const { layer: nearestLocationLayer } = useNearestLocationFeatureLayer({
        webmap,
    });

    useMapApi(props.mapRef, {
        selectedLocationLayer,
        nearestLocationLayer,
        mapView,
    });

    const { isLoading: isGeoJSONLayerLoading, isError: hasGeoJSONLayerError } = useGeoJSONLayer(
        geoJSON,
        webmap
    );

    if (width && height && typeof width === 'number' && typeof height === 'number') {
        return (
            <OuterWrapper width={width} height={height}>
                <InnerWrapper>
                    {baseMap}
                    {zoomControls}
                </InnerWrapper>
            </OuterWrapper>
        );
    }
    // if widht and height is not provided,
    // EsriStaticMap need to be wrapped in a outer div, which defines the width and height of the map.
    return (
        <InnerWrapper>
            {baseMap}
            {zoomControls}
            {(isGeoJSONLayerLoading ||
                isMapViewLoading ||
                hasGeoJSONLayerError ||
                hasMapViewError) && (
                <HStack style={{ position: 'absolute', left: '8px', bottom: '8px' }}>
                    {(isGeoJSONLayerLoading || isMapViewLoading) && (
                        <>
                            <Spinner size="sm" />
                            <Text variant="headingXs" color="brand">
                                {strings.loading}
                            </Text>
                        </>
                    )}
                    {(hasGeoJSONLayerError || hasMapViewError) && (
                        <Text variant="headingXs" color="negative">
                            {strings.loadingError}
                        </Text>
                    )}
                </HStack>
            )}
        </InnerWrapper>
    );
};
