import React, { Key, useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Box, FilterMenuRenderProps, Input, InputProps } from 'arc';

type InputFilterT = FilterMenuRenderProps & InputProps & { filterId: Key; debounced?: boolean };

export const InputFilter: React.FC<InputFilterT> = ({
    appliedOptions,
    setAppliedFilters,
    filterId,
    placeholder,
    debounced,
    ...props
}) => {
    const [query, setQuery] = useState(() =>
        appliedOptions.length ? appliedOptions[0].value : ''
    );

    const handleChange = useCallback(
        (value: string) => {
            setAppliedFilters({
                id: filterId,
                label: value,
                value,
            });
        },
        [filterId, setAppliedFilters]
    );

    const handleDebouncedChange = useMemo(() => {
        return debounce(handleChange, 700);
    }, [handleChange]);

    const onChange = debounced ? handleDebouncedChange : handleChange;

    return (
        <Box p={2}>
            <Input
                placeholder={placeholder}
                size="md"
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value);
                    onChange(e.target.value);
                }}
                {...props}
            />
        </Box>
    );
};
