import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { RefContextEnum } from '@mark43/rms-api';
import { getAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import strings from '~/client-common/core/strings/componentStrings';
import { isArrestReportSelector } from '~/client-common/core/domain/arrests/state/ui';

import reportSealingForm from '../state/forms/reportSealingForm';
import {
    reportSealingSubmissionErrorSelector,
    sealableInvolvedPersonsForReportIdSelector,
} from '../state/ui';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { ColumnFull } from '../../core/components/RecordPrivacyColumn';
import { FlexContainer, InnerContainer } from '../../core/components/RecordPrivacyContainers';
import { RightColumnNote } from '../../core/components/RecordPrivacyNote';
import { Form } from '../../core/components/Form';
import SubSectionTitle from '../../core/components/SubSectionTitle';
import ReportPartialSealingFormFields from './ReportPartialSealingFormFields';
import PartialSealingNarrativeSection from './PartialSealingNarrativeSection';
import PartialSealingReportContentSection from './PartialSealingReportContentSection';

class ReportPartialSealingForm extends React.Component {
    handleSealingTypeChange(isStatuteSealing) {
        this.props.changePath('sealingType', isStatuteSealing);
        if (!isStatuteSealing) {
            this.props.changePath('courtOrder.statuteCodeAttrId', undefined);
            this.props.changePath('statuteSealingPersonIdsToSeal', undefined);
        } else {
            this.props.changePath('personIdToSeal', undefined);
            this.props.changePath('courtOrder.courtCaseNumber', undefined);
            this.props.changePath('courtOrder.courtCodeAttrId', undefined);
            this.props.changePath('courtOrder.courtCaseStatute', undefined);
            this.props.changePath('courtOrder.courtCaseDateUtc', undefined);
            this.props.changePath('courtOrder.dateToPurgeUtc', undefined);
            this.props.changePath('courtOrder.isJuvenile', undefined);
            this.props.changePath('courtOrder.involvedPersonFullName', '');
            this.props.changePath('courtOrder.stateIdNumber', undefined);
        }
    }

    handleSealUnsealClick = (personId, involvedPersons) => {
        if (this.props.formModelByPath('sealingType')) {
            this.props.changePath('statuteSealingPersonIdsToSeal', [...personId]);
        } else {
            const selectedPerson = involvedPersons.find(person => person.id === personId);
            this.props.changePath('personIdToSeal', selectedPerson?.id);
            this.props.changePath('courtOrder.involvedPersonFullName', formatFullName(selectedPerson ?? ''));
            this.props.changePath('courtOrder.stateIdNumber', selectedPerson?.stateIdNumber);
        }
    };
    handleReportSealClick = (isReportSealed) => {
        if (isReportSealed && !this.props.report.isNarrativeSealed) {
            this.props.changePath('isSealNarrative', true);
        }
    };
    handleNarrativeSealClick = (sealingNarrative) => {
        if (sealingNarrative && !this.props.report.isNarrativeSealed) {
            this.props.changePath('isSealNarrative', true);
        } else {
            this.props.changePath('isSealNarrative', this.props.report.isNarrativeSealed);
        }
        // this triggers rule validation, unhiding fields
        this.props.validate();
    }

    render() {
        const {
            report,
            submissionError,
            sealableInvolvedPersonsForReportId,
            formModelByPath,
            isArrestReport,
        } = this.props;
        const personIdToSeal = formModelByPath('personIdToSeal');
        const involvedPersons = sealableInvolvedPersonsForReportId(report.id);
        const showArrestIdField = isArrestReport(report.id);
        const isStatuteSealing = formModelByPath('sealingType');
        const statuteSealingPersonIdsToSeal = formModelByPath('statuteSealingPersonIdsToSeal') ?? [];

        return (
            <Form {...reportSealingForm}>
                <FlexContainer>
                    {submissionError && (
                        <ColumnFull flexGrow={0}>
                            <InlineBanner status="error">{submissionError}</InlineBanner>
                        </ColumnFull>
                    )}
                    <InnerContainer>
                        <SubSectionTitle>
                            {strings.recordPrivacy.shared.sections.involvedPersons.title}
                        </SubSectionTitle>
                        <ReportPartialSealingFormFields
                            changePath={this.props.changePath}
                            formModelByPath={formModelByPath}
                            disableInvolvedPersonFullName={!personIdToSeal}
                            currentContext={RefContextEnum.FORM_REPORT_SEALING.name}
                            showArrestIdField={showArrestIdField}
                            showStateIdField={true}
                            onSealingTypeChange={this.handleSealingTypeChange}
                            isStatuteSealing={isStatuteSealing}
                            involvedPersons={involvedPersons}
                            statuteSealingPersonIdsToSeal={statuteSealingPersonIdsToSeal}
                            personIdToSeal={personIdToSeal}
                            onSealUnsealClick={this.handleSealUnsealClick}
                            reportId={report.id}
                        />
                        <SubSectionTitle>
                            {strings.recordPrivacy.sealing.NarrativeSection.title}
                        </SubSectionTitle>
                        <RightColumnNote>
                            {strings.recordPrivacy.sealing.NarrativeSection.note}
                        </RightColumnNote>
                        <PartialSealingNarrativeSection
                            report={report}
                            formModelByPath={formModelByPath}
                            onNarrativeSealClick={this.handleNarrativeSealClick}
                            involvedPersons={involvedPersons}
                        />
                        <SubSectionTitle>
                            {strings.recordPrivacy.sealing.ReportContentSection.title}
                        </SubSectionTitle>
                        <PartialSealingReportContentSection onReportSealClick={this.handleReportSealClick} />
                    </InnerContainer>
                </FlexContainer>
            </Form>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    submissionError: reportSealingSubmissionErrorSelector,
    sealableInvolvedPersonsForReportId: sealableInvolvedPersonsForReportIdSelector,
    formModelByPath: reportSealingForm.selectors.formModelByPathSelector,
    isArrestReport: isArrestReportSelector,
    getAttributeById: getAttributeByIdSelector,
});

export default connect(mapStateToProps, {
    changePath: reportSealingForm.actionCreators.changePath,
    validate: reportSealingForm.actionCreators.validate,
})(ReportPartialSealingForm);
