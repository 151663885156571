import { OperationTypeEnumType } from '@mark43/rms-api';
import { useCallback } from 'react';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { canManage } from '~/client-common/core/domain/entity-permissions/state/ui';
import { useAbilitySelector } from '../current-user/hooks/useAbilitySelector';

export const useIsReportOwnerRestricted = () => {
    const hasViewAbility = useAbilitySelector(abilitiesEnum.REPORTING.VIEW_REPORT_OWNER);

    return useCallback(
        (restrictViewReportOwners = false, permissionSet: OperationTypeEnumType[]) => {
            return restrictViewReportOwners && !(canManage(permissionSet) && hasViewAbility);
        },
        [hasViewAbility]
    );
};
