import React from 'react';
import { compose } from 'recompose';
import { ElasticReport } from '@mark43/rms-api';

import { getViewModelProperties, ViewModel } from '~/client-common/helpers/viewModelHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { ReportOwnerRestricted } from '../../../../../modules/core/components/ReportOwnerRestricted';

type Author = { id: number; display: string };
type ElasticReportViewModel = ViewModel<ElasticReport, { authorIds?: Author[] }>;

function ReportAuthorsCell(elasticReportViewModel: ElasticReportViewModel) {
    const authors = getViewModelProperties(elasticReportViewModel).authorIds;
    const { restrictViewReportOwners } = elasticReportViewModel.reportDefinition;

    return (
        <div>
            <FeatureFlagged
                flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                fallback={authors?.map(({ id, display }) => <div key={id}>{display}</div>) ?? ''}
            >
                <ReportOwnerRestricted
                    restrictViewReportOwners={restrictViewReportOwners}
                    permissionSet={elasticReportViewModel.permissionSet}
                >
                    {authors?.map(({ id, display }) => <div key={id}>{display}</div>) ?? ''}
                </ReportOwnerRestricted>
            </FeatureFlagged>
        </div>
    );
}

export default compose<ElasticReportViewModel, { columnKey: 'authors' }>()(ReportAuthorsCell);
