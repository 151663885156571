import { DiagramAssetUpsertParams, DiagramResource } from '@mark43/rms-api';
import { req } from '../../../lib/ajax';
import { createResource } from '../../../lib/resources/Resource';

export const diagramResource = createResource({
    name: 'Diagram Resource',
    methods: {
        getAssetsByCategory(diagramCategoryId: number) {
            return req<DiagramResource.GetDiagramAssets>({
                method: 'GET',
                url: `diagram/${diagramCategoryId}/assets`,
            });
        },

        saveAsset(asset: DiagramAssetUpsertParams) {
            return req<DiagramResource.SaveDiagramAsset>({
                method: 'PUT',
                url: 'diagram/assets',
                data: asset,
            });
        },
        deleteAsset(diagramAssetId: number) {
            return req<DiagramResource.DeleteDiagramAsset>({
                method: 'DELETE',
                url: `diagram/assets/${diagramAssetId}/`,
            });
        },
    },
});
