import { reduce, values, first, tail, filter, concat, compact } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, InjectedRouter } from 'react-router';

import { PrintingTemplateTypeEnum, Printable } from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';

import routesConfig from '../../../../routing/routesConfig';
import { routeNameSelector } from '../../../../routing/routerModule';
import { allCasesSearch } from '../../all-cases/state/ui';
import { myCasesSearch } from '../../my-cases/state/ui';
import { exportPDF } from '../../../../legacy-redux/actions/exportsActions';
import { openBox } from '../../../../legacy-redux/actions/boxActions';

const strings = componentStrings.exports.CombinedExports;

type CasePrintableById = {
    [caseId: string]: Printable[];
};

type BulkCaseExportFormOptionsFields = {
    includeNameAddendums?: boolean;
    combinedPDF?: boolean;
    redactedFields?: string[];
    redactFields?: boolean;
    includeAttachmentForBulkExport?: boolean;
    includeZipFilesForBulkExport?: boolean;
};

type BulkCaseExportFormOptions = {
    fields: BulkCaseExportFormOptionsFields;
};

export type BulkCasesExportFormOptionsProps = RouteComponentProps<never, never> &
    BulkCaseExportFormOptions;

const mapCasePacketsToPrintable = (packets: Printable[]): Printable | undefined => {
    const parentPrintable = first(packets);

    if (packets.length > 1 && parentPrintable !== undefined) {
        const childPrintables = tail(packets);

        // Ignore fillable PDFs
        const resultChildPrintables = filter(
            concat(parentPrintable.childPrintables, childPrintables),
            (printable) => printable.templateType === PrintingTemplateTypeEnum.JAVASCRIPT.name
        );

        return {
            ...parentPrintable,
            groupTitle: parentPrintable.title,
            fileName: strings.title,
            childPrintables: resultChildPrintables,
        };
    }

    return parentPrintable;
};

const mapBulkCasePacketsToPrintables = (printablesByCaseId: CasePrintableById): Printable[] => {
    const result = compact(
        reduce<Printable[], Printable[]>(
            values(printablesByCaseId),
            (resultPackets, currentPacket) => {
                const printable = mapCasePacketsToPrintable(currentPacket);
                return [...resultPackets, ...(printable ? [printable] : [])];
            },
            []
        )
    );

    return result;
};

const useBulkCasesExportData = () => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const routeName = useSelector(routeNameSelector);
    const searchModule =
        routeName === routesConfig.MY_CASES_EXPORTS.name ? myCasesSearch : allCasesSearch;
    const printablesByCaseId: CasePrintableById = useSelector(
        searchModule.selectors.printablesSelector
    );

    const dispatch = useDispatch();

    const openPreviousRoute = (router: InjectedRouter) => {
        router.goBack();
    };

    const openReleaseForm = (
        formDataFields: BulkCaseExportFormOptionsFields,
        selectedPackets: Printable[],
        router: InjectedRouter
    ) => {
        return dispatch(
            openBox(
                { name: boxEnum.CREATE_EXPORT_RELEASE_MODAL },
                {
                    formData: formDataFields,
                    selectedPackets,
                    onSuccess: () => openPreviousRoute(router),
                }
            )
        );
    };

    const download = (formData: BulkCaseExportFormOptions, router: InjectedRouter) => {
        const selectedPackets = mapBulkCasePacketsToPrintables(printablesByCaseId);
        if (applicationSettings.RELEASE_TRACKING_ENABLED) {
            openReleaseForm(formData.fields, selectedPackets, router);
        } else {
            dispatch(exportPDF(formData.fields, selectedPackets));
            openPreviousRoute(router);
        }
    };

    return {
        applicationSettings,
        printablesByCaseId,
        download,
        cancel: openPreviousRoute,
    };
};

export default useBulkCasesExportData;
