import React, { FC } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { Tooltip, Menu, MenuContent, MenuItem, MenuTrigger } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import testIds from '../../../../core/testIds';
import { Button } from '../../../core/components/Button';
import { IconButton } from '../../../core/components/IconButton';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { useEFileContext, useEFileDisplayName } from '../../hooks';
import { useEFileDetailsModal } from '../../hooks/useEFileDetailsModal';
import { TOOLTIP_DELAY_MS } from '../../constants';
import { EFileUpdateModal } from './EFileUpdateModal';
import { EFilePermissionsModal } from './EFilePermissionsModal';

const strings = componentStrings.eFiles.core.header;

const EFileHeaderContainer = styled.div`
    margin: 17px;
    display: flex;
    position: relative;
    float: right;
    column-gap: 10px;
`;

const _EFileHeader: FC<WithRouterProps> = ({ router }) => {
    const {
        getters: { efile },
    } = useEFileContext();

    const { eFileDisplayName } = useEFileDisplayName();
    const { openUpdateEFile } = useEFileDetailsModal();
    const overlayStore = useOverlayStore();

    const eFileViewModel = efile.getEFileViewModel();

    if (!eFileViewModel) {
        return null;
    }

    const openPermissionsModal = () => {
        overlayStore.open(overlayIdEnum.E_FILE_PERMISSIONS_OVERLAY);
    };

    const historyRoute = `/e-file/${eFileViewModel.id}/history`;

    const openHistoryPage = () => {
        router.push(historyRoute);
    };

    return (
        <>
            <EFileHeaderContainer>
                <Button leadingVisual={'Add'} variant={'outline'} disabled>
                    {strings.newSubmission}
                </Button>
                <Button variant={'outline'} disabled>
                    {strings.submission}
                </Button>
                <Tooltip
                    content={strings.managePermissions(eFileDisplayName)}
                    delayDuration={TOOLTIP_DELAY_MS}
                >
                    <IconButton
                        icon="Permissions"
                        aria-label={strings.managePermissions(eFileDisplayName)}
                        testId={testIds.E_FILE_MANAGE_PERMISSIONS}
                        onClick={openPermissionsModal}
                    />
                </Tooltip>
                <Tooltip content={strings.viewHistory} delayDuration={TOOLTIP_DELAY_MS}>
                    <IconButton
                        icon="History"
                        aria-label={strings.viewHistory}
                        testId={testIds.E_FILE_VIEW_HISTORY}
                        onClick={openHistoryPage}
                        isActive={router.isActive(historyRoute)}
                    />
                </Tooltip>
                <Menu>
                    <MenuTrigger asChild isDisabled={eFileViewModel && !eFileViewModel.canEdit}>
                        <IconButton
                            testId={testIds.E_FILE_HAMBURGER_MENU}
                            aria-label="E-file Hamburger Menu"
                            icon="Menu"
                            variant="outline"
                        />
                    </MenuTrigger>
                    <MenuContent align="end">
                        <MenuItem
                            data-test-id={testIds.E_FILE_MANAGE_DETAILS}
                            onSelect={openUpdateEFile}
                        >
                            {strings.hamburgerMenu.manageEFileDetails(eFileDisplayName)}
                        </MenuItem>
                    </MenuContent>
                </Menu>
            </EFileHeaderContainer>
            <EFileUpdateModal />
            <EFilePermissionsModal />
        </>
    );
};

export const EFileHeader = withRouter(_EFileHeader);
