import React, { RefObject } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../../core/testIds';
import { DataTableRow } from '../../../../core/components/DataTableWrapper';
import { EFileInvolvedProfileGridRowT, InvolvedProfileLinkType } from '../../../types';
import { RestrictedAccessRow } from '../../../../core/components/ElasticResultsDataTableRow';

const strings = componentStrings.eFiles.core.grid.deactivatedLabels;

type EFileProfilesGridRowProps = WithRouterProps & {
    item: EFileInvolvedProfileGridRowT;
    linkType: InvolvedProfileLinkType;
    eFileId: number;
    children: React.ReactNode;
    rowId: number;
    actionsRef: RefObject<HTMLDivElement>;
};

const EFileProfilesGridRowComponent = ({
    router,
    item,
    linkType,
    eFileId,
    rowId,
    children,
    actionsRef,
}: EFileProfilesGridRowProps) => {
    const { isDeactivated, nameData } = item;
    const { entityId, entityType } = nameData;

    const entityTypeRoute =
        entityType === EntityTypeEnum.PERSON_PROFILE.name ? 'person' : 'organization';

    const route = `e-file/${eFileId}/defendants/${entityId}/${entityTypeRoute}`;
    const isInteractive = !isDeactivated && linkType === LinkTypesEnum.DEFENDANT_IN_EFILE;

    return (
        <>
            {isDeactivated ? (
                <RestrictedAccessRow $isRestricted text={strings[linkType]}>
                    {children}
                </RestrictedAccessRow>
            ) : (
                <DataTableRow
                    onClick={() => {
                        if (isInteractive && !actionsRef.current) {
                            router.push(route);
                        }
                    }}
                    key={rowId}
                    isInteractive={isInteractive}
                    data-test-id={testIds.TABLE_ROW}
                >
                    {children}
                </DataTableRow>
            )}
        </>
    );
};

export const EFileProfilesGridRow = withRouter(EFileProfilesGridRowComponent);
