import {
    AttributeTypeEnum,
    EntityTypeEnum,
    LinkTypesEnum,
    Location,
    LocationEntityLink,
    NameReportLink,
    RefContextEnum,
} from '@mark43/rms-api';
import React, { MouseEvent } from 'react';
import { size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { cssVar } from 'arc';
import { lifecycleOptions } from 'markformythree';
import styled from 'styled-components';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import { agencyProfileOptionsWhereSelector } from '~/client-common/core/domain/agency-profiles/state/ui';
import { mostRecentActiveUnitForUserSelector } from '~/client-common/core/domain/user-assignments/state/ui';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { offensesByReportIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { formatNameReportLinkTypeIdSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import * as fields from '~/client-common/core/enums/universal/fields';
import {
    ASSISTING_OFFICER_OFFICER_ID,
    DISPLAY_ONLY_MY_UNIT_LABEL,
    DISPLAY_ONLY_OFFENSE,
    DISPLAY_ONLY_RESPONDER_LABEL,
    REPORT_NOTIFICATION_NOTIFICATION_TYPE_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { cadTicketsViewModelWhereSelector } from '~/client-common/core/domain/cad-tickets/state/ui';
import hotkeysActionEnum from '~/client-common/core/enums/client/hotkeysActionEnum';
import { reportDefinitionRestrictViewReportOwnersSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { Button as ArcButton } from '../../../../core/components/Button';
import { IconButton as ArcIconButton } from '../../../../core/components/IconButton';
import { ExternalReportStatusSummary } from '../../../../core/external-report-statuses/components/ExternalReportStatusSummary';
import { OffenseCaseStatusesSummary } from '../../../../core/offense-case-statuses/components/OffenseCaseStatusSummary';
import { abilitiesEnum, OnlyWithAbility } from '../../../../core/abilities';

import testIds from '../../../../../core/testIds';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { MFTNItems, RemoveButton } from '../../../../core/forms/components/NItems';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { ArbiterMFTUserSelect } from '../../../../core/forms/components/selects/UserSelect';
import { ArbiterMFTAgencyProfileSelect } from '../../../../core/forms/components/selects/AgencyProfileSelect';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';
import {
    currentUserDepartmentIdSelector,
    currentUserIdSelector,
} from '../../../../core/current-user/state/ui';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { eventInfoFormConfiguration } from '../../state/forms/eventInfoForm';
import { saveEventInfoForm } from '../../state/ui/eventInfo';
import { createNItemsIdAdder, createNItemsIdRemover } from '../../utils/nItemsHelpers';
import {
    autofillDateTypeEnum,
    MFTAutofillDateButton,
} from '../../../../core/components/AutofillDateButton';
import ReactHotKeysWrapper from '../../../../core/hotkeys/components/ReactHotKeysWrapper';
import { RmsDispatch } from '../../../../../core/typings/redux';
import { useIsReportOwnerRestricted } from '../../../../core/hooks/useIsReportOwnerRestricted';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import ReportCaseStatusSummary from './ReportCaseStatusSummary';
import CadTicketSummary from './CadTicketSummary';

const respondingOfficerIdFormModelPath = 'respondingOfficerId';
const personnelUnitAttrIdFormModelPath = 'personnelUnitAttrId';
const strings = componentStrings.reports.core.EventInfoCard;

const CalendarIconButton = styled(ArcIconButton)`
    margin-top: 23px;
    flex-shrink: 0;
`;

const AssignSpecificButton = styled(ArcButton)`
    margin-top: 23px;
    flex-shrink: 0;
`;

const NotificationArbiterMFTDatePicker = styled(ArbiterMFTDatePicker)`
    margin-right: 10;
`;

const AssistingPersonelRow = styled(Row)`
    display: flex;
    align-items: center;
`;

const FlexNotificationWrapper = styled.div`
    display: flex;
    flex: 1;
`;
const NotificationContainer = styled(FlexNotificationWrapper)`
    justify-items: space-between;
`;

const NotificationRowContainer = styled(FlexNotificationWrapper)`
    flex-wrap: wrap;
`;

const RemoveButtonElement = styled(RemoveButton)`
    margin-top: 20px;
`;

const FlexRow = styled.div`
    display: flex;
    align-items: flex-start;
`;

const StyledCardSection = styled(CardSection)`
    padding-right: ${cssVar('arc.space.6')};
`;

const locationDescriptionPath = () => 'reportTakenLocation.description';
const locationPositionAttrIdPath = () => 'reportTakenLocation.positionAttrId';

const usePreloadResources = (reportId: number) => {
    const agencyProfileOptionsWhere = useSelector(agencyProfileOptionsWhereSelector);
    const mostRecentActiveUnitForUser = useSelector(mostRecentActiveUnitForUserSelector);
    const currentUserId = useSelector(currentUserIdSelector);
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const nameReportLinksWhere = useSelector(nameReportLinksWhereSelector);
    const chargesWhere = useSelector(chargesWhereSelector);
    const arrestForReportId = useSelector(arrestForReportIdSelector);
    const offensesByReportId = useSelector(offensesByReportIdSelector);

    const agencyProfileOptionsPredicate = {
        departmentId: currentUserDepartmentId,
    };
    const suspectInReportLinks = nameReportLinksWhere({
        reportId,
        linkType: LinkTypesEnum.SUSPECT_IN_REPORT,
    });
    const witnessInReportLinks = nameReportLinksWhere({
        reportId,
        linkType: LinkTypesEnum.WITNESS_IN_REPORT,
    });
    const victimInReportLinks = nameReportLinksWhere({
        reportId,
        linkType: LinkTypesEnum.VICTIM_IN_REPORT,
    });
    const otherNameInReportLinks = nameReportLinksWhere({
        reportId,
        linkType: LinkTypesEnum.OTHER_NAME_IN_REPORT,
    });

    const getChargesInReport = () => {
        const arrestId = arrestForReportId(reportId)?.id;
        if (arrestId) {
            return chargesWhere({ arrestId });
        }

        const offensesInReport = offensesByReportId(reportId);
        return offensesInReport.flatMap((offense) =>
            chargesWhere({
                offenseId: offense.id,
            })
        );
    };

    // legacy charges do not have offense ID and should not affect changing the agency ID
    const chargesInReport = getChargesInReport().filter((c) => !!c.arrestId && !!c.offenseId);

    const hasSuspectInReport = suspectInReportLinks.length > 0;
    const hasWitnessInReport = witnessInReportLinks.length > 0;
    const hasVictimInReport = victimInReportLinks.length > 0;
    const hasOtherNameInReport = otherNameInReportLinks.length > 0;

    const legacyLinkTypeConfigs = [
        {
            linkType: LinkTypesEnum.SUSPECT_IN_REPORT,
            title: strings.suspectSectionTitle,
            show: { people: true, organizations: false },
            showSection: hasSuspectInReport,
        },
        {
            linkType: LinkTypesEnum.WITNESS_IN_REPORT,
            title: strings.witnessSectionTitle,
            show: { people: true, organizations: false },
            showSection: hasWitnessInReport,
        },
        {
            linkType: LinkTypesEnum.VICTIM_IN_REPORT,
            title: strings.victimSectionTitle,
            show: { people: true, organizations: true },
            showSection: hasVictimInReport,
        },
        {
            linkType: LinkTypesEnum.OTHER_NAME_IN_REPORT,
            title: strings.otherInvolvedNameSectionTitle,
            show: { people: true, organizations: true },
            showSection: hasOtherNameInReport,
        },
    ];

    return {
        agencyProfileOptions: agencyProfileOptionsWhere(agencyProfileOptionsPredicate),
        agencyProfileOptionsPredicate,
        mostRecentActiveAssignment: mostRecentActiveUnitForUser(currentUserId),
        legacyLinkTypeConfigs,
        hasChargeInReport: chargesInReport.length > 0,
    };
};

type EventInfoCardFormProps = {
    reportId: number;
    currentReportREN: string;
    isLegacyReport: boolean;
    reportingEventId: number;
};

const EventInfoCardForm = ({
    reportId,
    currentReportREN,
    isLegacyReport,
    reportingEventId,
}: EventInfoCardFormProps) => {
    const dispatch = useDispatch<RmsDispatch>();

    const currentUserId = useSelector(currentUserIdSelector);
    const formatNameReportLinkTypeId = useSelector(formatNameReportLinkTypeIdSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const cadTicketsViewModelWhere = useSelector(cadTicketsViewModelWhereSelector);
    const currentReport = useSelector(currentReportSelector);
    const reportDefinitionRestrictViewReportOwners = useSelector(
        reportDefinitionRestrictViewReportOwnersSelector
    );

    const fieldsLabels = useFields([
        DISPLAY_ONLY_OFFENSE,
        DISPLAY_ONLY_RESPONDER_LABEL,
        DISPLAY_ONLY_MY_UNIT_LABEL,
        REPORT_NOTIFICATION_NOTIFICATION_TYPE_ATTR_ID,
        ASSISTING_OFFICER_OFFICER_ID,
    ]);

    const {
        agencyProfileOptions,
        agencyProfileOptionsPredicate,
        mostRecentActiveAssignment,
        legacyLinkTypeConfigs,
        hasChargeInReport,
    } = usePreloadResources(reportId);

    const checkIsReportOwnerRestricted = useIsReportOwnerRestricted();

    if (!currentReport) {
        return null;
    }

    const cadTicketViewModels = cadTicketsViewModelWhere(currentReportREN, null);

    const restrictViewReportOwners = reportDefinitionRestrictViewReportOwners(
        currentReport.reportDefinitionId
    );
    const isReportOwnerRestricted = checkIsReportOwnerRestricted(
        restrictViewReportOwners,
        currentReport.permissionSet
    );

    return (
        <ArbiterForm
            name={RefContextEnum.FORM_EVENT_INFO.name}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            context={RefContextEnum.FORM_EVENT_INFO.name}
            configuration={eventInfoFormConfiguration}
            render={(form) => {
                const { get: formGet, set: formSet, resetUi } = form;

                const getForm = () => form;
                const onReportingPartyAdd = (nameReportLink: NameReportLink) => {
                    if (applicationSettings.RMS_DYNAMIC_REPORT_ENABLED) {
                        dispatch(saveEventInfoForm()).then(() => {
                            return createNItemsIdAdder({
                                getForm,
                                path: 'reportingParty',
                                id: nameReportLink?.nameId,
                            });
                        });
                    } else {
                        return createNItemsIdAdder({
                            getForm,
                            path: 'reportingParty',
                            id: nameReportLink?.nameId,
                        });
                    }
                };

                const onReportingPartyRemove = (nameReportLink: NameReportLink) => {
                    if (applicationSettings.RMS_DYNAMIC_REPORT_ENABLED) {
                        dispatch(saveEventInfoForm()).then(() => {
                            return createNItemsIdRemover({
                                getForm,
                                path: 'reportingParty',
                                id: nameReportLink?.nameId,
                            });
                        });
                    } else {
                        return createNItemsIdRemover({
                            getForm,
                            path: 'reportingParty',
                            id: nameReportLink?.nameId,
                        });
                    }
                };

                const onRespondingOfficerAssignToMe = () => {
                    formSet(respondingOfficerIdFormModelPath, currentUserId);
                    resetUi(respondingOfficerIdFormModelPath);
                };

                const onPersonnelUnitAssignToMyUnit = () => {
                    formSet(
                        personnelUnitAttrIdFormModelPath,
                        mostRecentActiveAssignment?.personnelUnitAttrId
                    );
                    resetUi(personnelUnitAttrIdFormModelPath);
                };

                const setCadEventStartDate = () => {
                    formSet('eventStartUtc', cadTicketViewModels[0].eventStartDateUtc);
                };
                const setCadEventEndDate = () => {
                    formSet('eventEndUtc', cadTicketViewModels[0].eventClosedDateUtc);
                };

                const hotKeysConfigForStartDate = {
                    [hotkeysActionEnum.FILL_CAD_EVENT_DATES.name]: {
                        handler: setCadEventStartDate,
                    },
                };

                const hotKeysConfigForEndDate = {
                    [hotkeysActionEnum.FILL_CAD_EVENT_DATES.name]: {
                        handler: setCadEventEndDate,
                    },
                };

                const legacyNameSummaryViews = legacyLinkTypeConfigs.map((config) => {
                    return (
                        config.showSection && (
                            <CardSection title={config.title}>
                                <NameSummaryViewWrapper
                                    renForRecents={currentReportREN}
                                    summaryMode={false}
                                    linkType={config.linkType}
                                    show={config.show}
                                    reportId={reportId}
                                    contextType={EntityTypeEnum.REPORT.name}
                                    contextId={reportId}
                                    parentEntityType={EntityTypeEnum.REPORT.name}
                                    parentId={reportId}
                                    onAddSuccess={onReportingPartyAdd}
                                    onRemoveSuccess={onReportingPartyRemove}
                                />
                            </CardSection>
                        )
                    );
                });

                const reportingPartyDisplayName = formatNameReportLinkTypeId(
                    LinkTypesEnum.REPORTING_PARTY_IN_REPORT
                );

                const effectiveDateFilter = isLegacyReport
                    ? undefined
                    : formGet('eventStartUtc') || nowUtc();

                const respondingOfficerIdField = (
                    <FlexRow>
                        <ArbiterMFTUserSelect
                            path="respondingOfficerId"
                            effectiveDate={effectiveDateFilter}
                            additionalIds={[currentUserId]}
                        />
                        <AssignSpecificButton
                            isTextTransformNone
                            variant="outline"
                            testId={testIds.ASSIGN_TO_ME_BUTTON}
                            onClick={onRespondingOfficerAssignToMe}
                        >
                            {strings.respondingOfficerAssignToMe}
                        </AssignSpecificButton>
                    </FlexRow>
                );

                const isRespondingOfficerIdFieldVisible =
                    !formGet('respondingOfficerId') || !isReportOwnerRestricted;

                return (
                    <div>
                        <StyledCardSection>
                            <FeatureFlagged
                                flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                                fallback={
                                    <>
                                        <CadTicketSummary reportId={reportId} />
                                        <Row>
                                            <ArbiterMFTDatePicker
                                                path="eventStartUtc"
                                                includeTime={true}
                                                autoFocus={true}
                                            />
                                        </Row>
                                        <Row>
                                            <ArbiterMFTDatePicker
                                                path="eventEndUtc"
                                                includeTime={true}
                                            />
                                        </Row>
                                    </>
                                }
                            >
                                <ReactHotKeysWrapper hotKeysConfig={hotKeysConfigForStartDate}>
                                    <Row>
                                        <ArbiterMFTDatePicker
                                            path="eventStartUtc"
                                            includeTime={true}
                                            autoFocus={true}
                                        />
                                        {cadTicketViewModels.length > 0 && (
                                            <CalendarIconButton
                                                variant="outline"
                                                aria-label="Autofill start date"
                                                testId={testIds.AUTOFILL_DATE_BUTTON}
                                                onClick={setCadEventStartDate}
                                                icon="AutoFillDate"
                                            />
                                        )}
                                    </Row>
                                </ReactHotKeysWrapper>
                                <ReactHotKeysWrapper hotKeysConfig={hotKeysConfigForEndDate}>
                                    <Row>
                                        <ArbiterMFTDatePicker
                                            path="eventEndUtc"
                                            includeTime={true}
                                        />
                                        {cadTicketViewModels.length > 0 && (
                                            <CalendarIconButton
                                                variant="outline"
                                                aria-label="Autofill end date"
                                                testId={testIds.AUTOFILL_DATE_BUTTON}
                                                onClick={setCadEventEndDate}
                                                icon="AutoFillDate"
                                            />
                                        )}
                                    </Row>
                                </ReactHotKeysWrapper>
                            </FeatureFlagged>
                            {size(agencyProfileOptions) > 1 && (
                                <Row>
                                    <ArbiterMFTAgencyProfileSelect
                                        path="agencyId"
                                        clearable={false}
                                        predicate={agencyProfileOptionsPredicate}
                                        disabled={hasChargeInReport}
                                        helpText={
                                            hasChargeInReport
                                                ? strings.agencyIdHelpText(
                                                      fieldsLabels.DISPLAY_ONLY_OFFENSE
                                                  )
                                                : null
                                        }
                                    />
                                </Row>
                            )}
                            <VisibilityObserver
                                path="respondingOfficerId"
                                formName={RefContextEnum.FORM_EVENT_INFO.name}
                                render={({ hidden }) =>
                                    !hidden ? (
                                        <FeatureFlagged
                                            flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                                            fallback={respondingOfficerIdField}
                                        >
                                            {isRespondingOfficerIdFieldVisible &&
                                                respondingOfficerIdField}
                                        </FeatureFlagged>
                                    ) : null
                                }
                            />
                            <VisibilityObserver
                                path="personnelUnitAttrId"
                                formName={RefContextEnum.FORM_EVENT_INFO.name}
                                render={({ hidden }) =>
                                    !hidden ? (
                                        <FlexRow>
                                            <ArbiterMFTAttributeSelect
                                                path="personnelUnitAttrId"
                                                attributeType={
                                                    AttributeTypeEnum.PERSONNEL_UNIT.name
                                                }
                                            />
                                            {mostRecentActiveAssignment && (
                                                <AssignSpecificButton
                                                    isTextTransformNone
                                                    className={buttonTypes.SECONDARY}
                                                    onClick={onPersonnelUnitAssignToMyUnit}
                                                >
                                                    {fieldsLabels[DISPLAY_ONLY_MY_UNIT_LABEL]}
                                                </AssignSpecificButton>
                                            )}
                                        </FlexRow>
                                    ) : null
                                }
                            />
                            <VisibilityObserver
                                path="assistingPersonnel"
                                formName={RefContextEnum.FORM_EVENT_INFO.name}
                                render={({ hidden }) =>
                                    !hidden ? (
                                        <Row
                                            testId={testIds.EVENT_INFO_ASSISTING_PERSONNEL_SECTION}
                                        >
                                            <MFTNItems
                                                path="assistingPersonnel"
                                                addItemOnEmpty={true}
                                                addText={fieldsLabels[ASSISTING_OFFICER_OFFICER_ID]}
                                                hideAddButtonOnEmptyItem={true}
                                                renderRemoveButton={undefined}
                                                childFieldKeys={[
                                                    'officerId',
                                                    'assistTypeAttrIds',
                                                    'description',
                                                ]}
                                                autoFocusFirstInputOnAdd={true}
                                                render={({ removeItem, index, items }) =>
                                                    (setFirstInputRef: unknown) => (
                                                        <div>
                                                            <AssistingPersonelRow>
                                                                <ArbiterMFTUserSelect
                                                                    path="officerId"
                                                                    effectiveDate={
                                                                        effectiveDateFilter
                                                                    }
                                                                    length="md"
                                                                    setRef={setFirstInputRef}
                                                                />
                                                                <ArbiterMFTAttributeSelect
                                                                    path="assistTypeAttrIds"
                                                                    attributeType={
                                                                        AttributeTypeEnum
                                                                            .OFFICER_ASSIST_TYPE
                                                                            .name
                                                                    }
                                                                    multiple={true}
                                                                    length="md"
                                                                />
                                                                {(index > 0 ||
                                                                    items.length > 1) && (
                                                                    <RemoveButton
                                                                        onClick={removeItem}
                                                                    />
                                                                )}
                                                            </AssistingPersonelRow>
                                                            <IndentedFields>
                                                                <Row>
                                                                    <ArbiterMFTText path="description" />
                                                                </Row>
                                                            </IndentedFields>
                                                        </div>
                                                    )}
                                            />
                                        </Row>
                                    ) : null
                                }
                            />
                            <Row>
                                <ArbiterMFTTextArea path="crimeDescription" length="lg" rows={3} />
                            </Row>
                            <Row>
                                <ArbiterMFTAttributeSelect
                                    length="lg"
                                    path="weatherAttrIds"
                                    attributeType={AttributeTypeEnum.WEATHER.name}
                                    multiple={true}
                                />
                            </Row>
                            <IndentedFields>
                                <Row>
                                    <ArbiterMFTText path="weatherDescription" length="md" />
                                </Row>
                            </IndentedFields>
                            <Row>
                                <ArbiterMFTBooleanSelect path="isStreetCrime" length="md" />
                            </Row>
                            <Row>
                                <ArbiterMFTBooleanSelect
                                    path="anyNonOfficerFiredWeapon"
                                    length="md"
                                />
                            </Row>
                            <IndentedFields>
                                <Row>
                                    <ArbiterMFTBooleanSelect
                                        path="wasAnyoneStruckByBullet"
                                        length="md"
                                    />
                                </Row>
                            </IndentedFields>
                            <Row>
                                <ArbiterMFTBooleanSelect path="paramedicsAtScene" length="md" />
                            </Row>
                            <IndentedFields data-test-id={testIds.EVENT_INFO_PARAMEDICS_SECTION}>
                                <Row>
                                    <ArbiterMFTText path="paramedicsUnit" length="md" />
                                </Row>
                                <Row>
                                    <MFTNItems
                                        path="paramedics"
                                        addItemOnEmpty={true}
                                        addText={
                                            fieldsLabels[
                                                REPORT_NOTIFICATION_NOTIFICATION_TYPE_ATTR_ID
                                            ]
                                        }
                                        hideAddButtonOnEmptyItem={true}
                                        childFieldKeys={['name', 'idNumber']}
                                        autoFocusFirstInputOnAdd={true}
                                        renderRemoveButton={undefined}
                                        render={({ items, index, removeItem }) =>
                                            (setFirstInputRef: unknown) => (
                                                <div>
                                                    <ArbiterMFTText
                                                        path="name"
                                                        length="md"
                                                        label={strings.paramedicNameLabel}
                                                        setRef={setFirstInputRef}
                                                    />
                                                    <ArbiterMFTText
                                                        path="idNumber"
                                                        length="md"
                                                        label={strings.paramedicIdNumberLabel}
                                                    />

                                                    {(index > 0 || items.length > 1) && (
                                                        <RemoveButtonElement onClick={removeItem} />
                                                    )}
                                                </div>
                                            )}
                                    />
                                </Row>
                            </IndentedFields>
                            <Row>
                                <ArbiterMFTBooleanSelect path="isSchoolRelated" length="md" />
                            </Row>
                            <Row>
                                <ArbiterMFTBooleanSelect
                                    path="isArrestOrCustodialRelatedDeath"
                                    length="md"
                                />
                            </Row>

                            <ReportCaseStatusSummary reportId={reportId} />
                            <FeatureFlagged flag="RMS_CLEARANCE_IMPROVEMENTS_ENABLED">
                                <OnlyWithAbility
                                    has={abilitiesEnum.REPORTING.VIEW_EXTERNAL_REPORT_STATUS}
                                >
                                    <Row>
                                        <ExternalReportStatusSummary
                                            reportingEventId={reportingEventId}
                                        />
                                    </Row>
                                </OnlyWithAbility>
                            </FeatureFlagged>
                            <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_CASE_STATUS}>
                                <Row>
                                    <OffenseCaseStatusesSummary reportId={reportId} />
                                </Row>
                            </OnlyWithAbility>
                            <VisibilityObserver
                                path="eventStatisticsAttrIds"
                                formName={RefContextEnum.FORM_EVENT_INFO.name}
                                render={({ hidden }) =>
                                    !hidden ? (
                                        <div>
                                            <ArbiterMFTAttributeCheckboxes
                                                path="eventStatisticsAttrIds"
                                                attributeType={
                                                    AttributeTypeEnum.EVENT_STATISTICS.name
                                                }
                                                columns={2}
                                                columnWidth="48%"
                                                gutterWidth="4%"
                                            />
                                            <IndentedFields>
                                                <Row>
                                                    <ArbiterMFTText path="eventStatisticsDescription" />
                                                </Row>
                                            </IndentedFields>
                                        </div>
                                    ) : null
                                }
                            />
                        </StyledCardSection>
                        <VisibilityObserver
                            path="reportTakenLocation.locationId"
                            formName={RefContextEnum.FORM_EVENT_INFO.name}
                            render={({ hidden }: { hidden: boolean }) =>
                                !hidden ? (
                                    <CardSection
                                        fieldName={
                                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_EVENT_LINK_TYPE
                                        }
                                        testId={testIds.REPORT_TAKEN_LOCATION_SECTION}
                                    >
                                        <LocationSummaryViewWrapperWithFormFields
                                            entityType={EntityTypeEnum.REPORT.name}
                                            entityId={reportId}
                                            linkType={LinkTypesEnum.LOCATION_OF_EVENT}
                                            summaryMode={false}
                                            locationDescriptionPath={locationDescriptionPath}
                                            locationPositionAttrIdPath={locationPositionAttrIdPath}
                                            onLocationAdd={(
                                                location: Location,
                                                modelEntityLink: LocationEntityLink
                                            ) => {
                                                formSet('reportTakenLocation', modelEntityLink);
                                                if (
                                                    applicationSettings.RMS_DYNAMIC_REPORT_ENABLED
                                                ) {
                                                    dispatch(saveEventInfoForm());
                                                }
                                            }}
                                            onLocationRemove={() => {
                                                formSet('reportTakenLocation', undefined);
                                                if (
                                                    applicationSettings.RMS_DYNAMIC_REPORT_ENABLED
                                                ) {
                                                    dispatch(saveEventInfoForm());
                                                }
                                            }}
                                        />
                                    </CardSection>
                                ) : null
                            }
                        />
                        <VisibilityObserver
                            path="notifications"
                            formName={RefContextEnum.FORM_EVENT_INFO.name}
                            render={({ hidden }: { hidden: boolean }) =>
                                !hidden ? (
                                    <CardSection
                                        fieldName={
                                            fields.DISPLAY_ONLY_EVENT_DETAIL_NOTIFICATION_N_ITEMS_WRAPPER
                                        }
                                        title={strings.notificationsSectionTitle}
                                        testId={testIds.EVENT_INFO_NOTIFICATIONS_SECTION}
                                    >
                                        <MFTNItems
                                            path="notifications"
                                            addItemOnEmpty={true}
                                            addText={
                                                fieldsLabels[
                                                    REPORT_NOTIFICATION_NOTIFICATION_TYPE_ATTR_ID
                                                ]
                                            }
                                            hideAddButtonOnEmptyItem={true}
                                            renderRemoveButton={undefined}
                                            childFieldKeys={[
                                                'notificationTypeAttrId',
                                                'notificationDateUtc',
                                                'details',
                                            ]}
                                            autoFocusFirstInputOnAdd={true}
                                            render={({ index, items, removeItem }) =>
                                                (setFirstInputRef: unknown) => (
                                                    <NotificationContainer>
                                                        <NotificationRowContainer>
                                                            <ArbiterMFTAttributeSelect
                                                                path="notificationTypeAttrId"
                                                                attributeType={
                                                                    AttributeTypeEnum
                                                                        .REPORT_NOTIFICATION_TYPE
                                                                        .name
                                                                }
                                                                setRef={setFirstInputRef}
                                                                style={{
                                                                    float: 'none',
                                                                    minWidth: '33%',
                                                                    flex: '1',
                                                                }}
                                                            />
                                                            <NotificationArbiterMFTDatePicker
                                                                path="notificationDateUtc"
                                                                includeTime={true}
                                                            />
                                                            <MFTAutofillDateButton
                                                                onClick={(
                                                                    _e: MouseEvent<HTMLButtonElement>,
                                                                    {
                                                                        eventStartUtc,
                                                                    }: { eventStartUtc: string }
                                                                ) =>
                                                                    formSet(
                                                                        `notifications[${index}].notificationDateUtc`,
                                                                        eventStartUtc
                                                                    )
                                                                }
                                                                autofillDateType={
                                                                    autofillDateTypeEnum.START_DATE_TIME
                                                                }
                                                                startDatePath="notificationDateUtc"
                                                            />
                                                            <IndentedFields>
                                                                <Row>
                                                                    <ArbiterMFTText path="details" />
                                                                </Row>
                                                            </IndentedFields>
                                                        </NotificationRowContainer>

                                                        {(index > 0 || items.length > 1) && (
                                                            <RemoveButtonElement
                                                                onClick={removeItem}
                                                            />
                                                        )}
                                                    </NotificationContainer>
                                                )}
                                        />
                                    </CardSection>
                                ) : null
                            }
                        />
                        <VisibilityObserver
                            path="reportingParty"
                            formName={RefContextEnum.FORM_EVENT_INFO.name}
                            render={({ hidden }: { hidden: boolean }) =>
                                !hidden ? (
                                    <CardSection
                                        fieldName={
                                            fields.NAME_REPORT_LINK_LINK_TYPE_REPORTING_PARTY_IN_REPORT_LINK_TYPE
                                        }
                                        testId={testIds.REPORTING_PARTY_SECTION}
                                    >
                                        <NameSummaryViewWrapper
                                            renForRecents={currentReportREN}
                                            summaryMode={false}
                                            addNameButtonText={reportingPartyDisplayName}
                                            reportId={reportId}
                                            contextType={EntityTypeEnum.REPORT.name}
                                            contextId={reportId}
                                            parentEntityType={EntityTypeEnum.REPORT.name}
                                            parentId={reportId}
                                            linkType={LinkTypesEnum.REPORTING_PARTY_IN_REPORT}
                                            show={{
                                                people: true,
                                                organizations: true,
                                                societies: true,
                                            }}
                                            onAddSuccess={onReportingPartyAdd}
                                            onRemoveSuccess={onReportingPartyRemove}
                                        />
                                    </CardSection>
                                ) : null
                            }
                        />
                        {legacyNameSummaryViews}
                    </div>
                );
            }}
        />
    );
};

export default EventInfoCardForm;
