import { VisibleFilter } from 'arc';
import { BriefingsSearchView } from '@mark43/rms-api';
import noop from 'lodash/noop';
import { RmsDispatch } from '../../../core/typings/redux';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { initialFilters } from '../state/forms/briefingsDashboardFilters';
import { briefingsDashboardSearch, searchBriefingDashboard } from '../state/ui';

export function onBriefingsDashboardEnter(this: {
    dispatch: RmsDispatch;
    getState: () => RootState;
}) {
    const { dispatch } = this;

    dispatch(briefingsDashboardSearch.actionCreators.setIsInitialSearch(true));

    dispatch(briefingsDashboardSearch.actionCreators.loadAndExecuteLatestAutoSavedSearch()).then(
        (result?: { briefingsSearchView: BriefingsSearchView; formModel: VisibleFilter[] }) => {
            if (!result) {
                dispatch(
                    searchBriefingDashboard(
                        {
                            formData: initialFilters,
                            sortKey: undefined,
                            sortType: undefined,
                        },
                        { autoSave: false }
                    )
                )
                    .then(() => {
                        dispatch(briefingsDashboardSearch.actionCreators.setIsInitialSearch(false));
                    })
                    .catch(noop);
            }
        }
    );
}
