import { useCallback, useState, useEffect } from 'react';
import { Vehicle, Firearm, ElasticSearchTypeEnum, ItemProfile } from '@mark43/rms-api';
import { useDispatch, useSelector } from 'react-redux';
import {
    FederatedSearchVehicle,
    FederatedSearchBoat,
    ResultActionButtonsConfiguration,
    FederatedSearchFirearm,
    FederatedSearchArticle,
} from 'mark43-federated-search';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import merge from 'lodash/merge';

import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import { queryParamDefaults } from '~/client-common/configs/advancedSearchConfig';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import OverlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { NEXUS_STATE_PROP as VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { RmsDispatch } from '../../../../../../core/typings/redux';
import elasticSearchResource from '../../../../../../legacy-redux/resources/elasticSearchResource';
import { useOverlayStore } from '../../../../../core/overlays/hooks/useOverlayStore';
import { loadEntityProfile } from '../../../../../entity-profiles/core/state/data';
import {
    openItemSidePanel,
    convertToFormModelFromRmsVehicle,
    itemSidePanelOwnerIdSelector,
    itemSidePanelOwnerTypeSelector,
    generateSidePanelFormDataAsObjFromRmsFirearm,
    generateSidePanelFormDataAsObjFromRmsOtherItem,
} from '../../../state/ui/itemSidePanel';
import {
    mapDexItemsToRmsVehicles,
    DexItemType,
    mapDexFirearmsToRmsFirearms,
    mapDexArticlesToRmsProperties,
} from '../helpers';

const strings = componentStrings.reports.core.ItemSidePanel;

export const useMapDexItemsToRmsVehicles = (dexItems: DexItemType[]) => {
    const dispatch = useDispatch<RmsDispatch>();

    const [rmsVehicles, setRmsVehicles] = useState<Partial<Vehicle>[]>([]);

    const mapDexItemsToRmsVehiclesMemoized = useCallback(
        () => dispatch(mapDexItemsToRmsVehicles(dexItems)),
        [dispatch, dexItems]
    );

    const onResourceSuccess = useCallback((result: Partial<Vehicle>[]) => {
        setRmsVehicles(result.map((vehicle) => omitBy(vehicle, isUndefined)));
    }, []);

    const { loading, callResource } = useResourceDeferred<Partial<Vehicle>[]>(
        mapDexItemsToRmsVehiclesMemoized,
        onResourceSuccess
    );

    useEffect(() => {
        callResource();
    }, [callResource]);

    return {
        loading: loading.isLoading,
        error: loading.errorMessage,
        rmsVehicles,
    };
};

export const useMapDexFirearmsToRmsFirearms = (dexFirearms: FederatedSearchFirearm[]) => {
    const dispatch = useDispatch<RmsDispatch>();

    const [rmsFirearms, setRmsFirearms] = useState<Partial<Firearm>[]>([]);

    const mapDexFirearmsToRmsFireamrsMemoized = useCallback(
        () => dispatch(mapDexFirearmsToRmsFirearms(dexFirearms)),
        [dispatch, dexFirearms]
    );

    const onResourceSuccess = useCallback((result: Partial<Firearm>[]) => {
        setRmsFirearms(result.map((firearm) => omitBy(firearm, isUndefined)));
    }, []);

    const { loading, callResource } = useResourceDeferred<Partial<Firearm>[]>(
        mapDexFirearmsToRmsFireamrsMemoized,
        onResourceSuccess
    );

    useEffect(() => {
        callResource();
    }, [callResource]);

    return {
        loading: loading.isLoading,
        error: loading.errorMessage,
        rmsFirearms,
    };
};

export const useGetFederatedSearchVehicleButtonsConfiguration = () => {
    const dispatch = useDispatch<RmsDispatch>();
    const overlayStore = useOverlayStore();

    const ownerType: string = useSelector(itemSidePanelOwnerTypeSelector);
    const ownerId: number = useSelector(itemSidePanelOwnerIdSelector);

    const handleOverrideProfile = async (
        dexItems: (FederatedSearchVehicle | FederatedSearchBoat)[],
        rmsProfileId: number
    ) => {
        const rmsVehicles = await dispatch(mapDexItemsToRmsVehicles(dexItems));

        if (rmsVehicles[0]) {
            await dispatch(loadEntityProfile(VEHICLES_NEXUS_STATE_PROP, rmsProfileId));

            dispatch(
                openItemSidePanel({
                    isVehicle: true,
                    operation: itemSidePanelOperationEnum.EDIT,
                    ownerType,
                    ownerId,
                    isFormHidden: false,
                    itemTypeAttrId: globalAttributes.itemType.vehicle,
                    itemProfileId: rmsProfileId,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge({}, convertToFormModelFromRmsVehicle(rmsVehicles[0]), formModel),
                })
            );

            overlayStore.close(OverlayIdEnum.DEX_SIDE_PANEL);
        }
    };

    const handleCancelOverrideProfile = async (rmsProfileId: number) => {
        await dispatch(loadEntityProfile(VEHICLES_NEXUS_STATE_PROP, rmsProfileId));

        dispatch(
            openItemSidePanel({
                isVehicle: true,
                operation: itemSidePanelOperationEnum.EDIT,
                ownerType,
                ownerId,
                isFormHidden: false,
                itemTypeAttrId: globalAttributes.itemType.vehicle,
                itemProfileId: rmsProfileId,
            })
        );

        overlayStore.close(OverlayIdEnum.DEX_SIDE_PANEL);
    };

    const handleCreateNewProfile = async (
        dexItems: (FederatedSearchVehicle | FederatedSearchBoat)[]
    ) => {
        const rmsVehicles = await dispatch(mapDexItemsToRmsVehicles(dexItems));

        if (rmsVehicles[0]) {
            dispatch(
                openItemSidePanel({
                    isVehicle: true,
                    operation: itemSidePanelOperationEnum.CREATE,
                    ownerType,
                    ownerId,
                    isFormHidden: false,
                    itemTypeAttrId: globalAttributes.itemType.vehicle,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge({}, formModel, convertToFormModelFromRmsVehicle(rmsVehicles[0])),
                })
            );

            overlayStore.close(OverlayIdEnum.DEX_SIDE_PANEL);
        }
    };

    const federatedSearchButtonsConfiguration: ResultActionButtonsConfiguration = {
        profile: {
            searchProfiles: async (searchString) => {
                const response = await elasticSearchResource.searchAll({
                    size: queryParamDefaults.SIZE,
                    query: searchString,
                    searchTypes: [ElasticSearchTypeEnum.VEHICLE.name],
                });
                return response.vehicles;
            },
            addToProfile: async ({ profileId, result }) => {
                const dexItems = result?.VEHICLE || result?.BOAT;
                if (dexItems) {
                    if (profileId) {
                        overlayStore.open(OverlayIdEnum.OVERRIDE_ITEM_PROFILE_MODAL, {
                            dexItems,
                            rmsProfileId: profileId,
                        });
                    } else {
                        handleCreateNewProfile(dexItems);
                    }
                }
            },
            buttonTitle: strings.addToReport,
        },
    };

    return {
        federatedSearchButtonsConfiguration,
        handleOverrideProfile,
        handleCancelOverrideProfile,
    };
};

export const useGetFederatedSearchFirearmButtonsConfiguration = () => {
    const dispatch = useDispatch<RmsDispatch>();
    const overlayStore = useOverlayStore();

    const ownerType: string = useSelector(itemSidePanelOwnerTypeSelector);
    const ownerId: number = useSelector(itemSidePanelOwnerIdSelector);

    const handleOverrideProfile = async (
        dexItems: FederatedSearchFirearm[],
        rmsProfileId: number
    ) => {
        const rmsFirearms = await dispatch(mapDexFirearmsToRmsFirearms(dexItems));

        if (rmsFirearms[0]) {
            await dispatch(loadEntityProfile('property', rmsProfileId));

            dispatch(
                openItemSidePanel({
                    operation: itemSidePanelOperationEnum.EDIT,
                    ownerType,
                    ownerId,
                    itemTypeAttrId: globalAttributes.itemType.firearm,
                    itemProfileId: rmsProfileId,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge(
                            {},
                            generateSidePanelFormDataAsObjFromRmsFirearm(rmsFirearms[0]),
                            formModel
                        ),
                })
            );

            overlayStore.close(OverlayIdEnum.DEX_SIDE_PANEL);
        }
    };

    const handleCancelOverrideProfile = async (rmsProfileId: number) => {
        await dispatch(loadEntityProfile('property', rmsProfileId));

        dispatch(
            openItemSidePanel({
                operation: itemSidePanelOperationEnum.EDIT,
                ownerType,
                ownerId,
                itemTypeAttrId: globalAttributes.itemType.firearm,
                itemProfileId: rmsProfileId,
            })
        );

        overlayStore.close(OverlayIdEnum.DEX_SIDE_PANEL);
    };

    const handleCreateNewProfile = async (dexItems: FederatedSearchFirearm[]) => {
        const rmsFirearms = await dispatch(mapDexFirearmsToRmsFirearms(dexItems));

        if (rmsFirearms[0]) {
            dispatch(
                openItemSidePanel({
                    operation: itemSidePanelOperationEnum.CREATE,
                    ownerType,
                    ownerId,
                    itemTypeAttrId: globalAttributes.itemType.firearm,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge(
                            {},
                            formModel,
                            generateSidePanelFormDataAsObjFromRmsFirearm(rmsFirearms[0])
                        ),
                })
            );
        }

        overlayStore.close(OverlayIdEnum.DEX_SIDE_PANEL);
    };

    const federatedSearchButtonsConfiguration: ResultActionButtonsConfiguration = {
        profile: {
            searchProfiles: async (searchString) => {
                const response = await elasticSearchResource.searchAll({
                    size: queryParamDefaults.SIZE,
                    query: searchString,
                    searchTypes: [ElasticSearchTypeEnum.PROPERTY.name],
                });
                return response.property;
            },
            addToProfile: async ({ profileId, result }) => {
                const dexItems = result?.FIREARM;
                if (dexItems) {
                    if (profileId) {
                        overlayStore.open(OverlayIdEnum.OVERRIDE_ITEM_PROFILE_MODAL, {
                            dexItems,
                            rmsProfileId: profileId,
                        });
                    } else {
                        handleCreateNewProfile(dexItems);
                    }
                }
            },
            buttonTitle: strings.addToReport,
        },
    };

    return {
        federatedSearchButtonsConfiguration,
        handleOverrideProfile,
        handleCancelOverrideProfile,
    };
};

export const useMapDexArticleToRmsProperty = (dexArticles: FederatedSearchArticle[]) => {
    const dispatch = useDispatch<RmsDispatch>();

    const [rmsProperties, setRmsProperties] = useState<Partial<ItemProfile>[]>([]);

    const mapDexArticlesToRmsPropertiesMemoized = useCallback(
        () => dispatch(mapDexArticlesToRmsProperties(dexArticles)),
        [dispatch, dexArticles]
    );

    const onResourceSuccess = useCallback((result: Partial<ItemProfile>[]) => {
        setRmsProperties(result.map((article) => omitBy(article, isUndefined)));
    }, []);

    const { loading, callResource } = useResourceDeferred<Partial<ItemProfile>[]>(
        mapDexArticlesToRmsPropertiesMemoized,
        onResourceSuccess
    );

    useEffect(() => {
        callResource();
    }, [callResource]);

    return {
        loading: loading.isLoading,
        error: loading.errorMessage,
        rmsProperties,
    };
};

export const useGetFederatedSearchArticleButtonsConfiguration = () => {
    const dispatch = useDispatch<RmsDispatch>();
    const overlayStore = useOverlayStore();

    const ownerType: string = useSelector(itemSidePanelOwnerTypeSelector);
    const ownerId: number = useSelector(itemSidePanelOwnerIdSelector);

    const handleOverrideProfile = async (
        dexItems: FederatedSearchArticle[],
        rmsProfileId: number
    ) => {
        const rmsProperties = await dispatch(mapDexArticlesToRmsProperties(dexItems));

        if (rmsProperties[0]) {
            await dispatch(loadEntityProfile('property', rmsProfileId));

            dispatch(
                openItemSidePanel({
                    operation: itemSidePanelOperationEnum.EDIT,
                    ownerType,
                    ownerId,
                    itemTypeAttrId: globalAttributes.itemType.item,
                    itemProfileId: rmsProfileId,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge(
                            {},
                            generateSidePanelFormDataAsObjFromRmsOtherItem(rmsProperties[0]),
                            formModel
                        ),
                })
            );

            overlayStore.close(OverlayIdEnum.DEX_SIDE_PANEL);
        }
    };

    const handleCancelOverrideProfile = async (rmsProfileId: number) => {
        await dispatch(loadEntityProfile('property', rmsProfileId));

        dispatch(
            openItemSidePanel({
                operation: itemSidePanelOperationEnum.EDIT,
                ownerType,
                ownerId,
                itemTypeAttrId: globalAttributes.itemType.item,
                itemProfileId: rmsProfileId,
            })
        );

        overlayStore.close(OverlayIdEnum.DEX_SIDE_PANEL);
    };

    const handleCreateNewProfile = async (dexItems: FederatedSearchArticle[]) => {
        const rmsProperties = await dispatch(mapDexArticlesToRmsProperties(dexItems));

        if (rmsProperties[0]) {
            dispatch(
                openItemSidePanel({
                    operation: itemSidePanelOperationEnum.CREATE,
                    ownerType,
                    ownerId,
                    itemTypeAttrId: globalAttributes.itemType.item,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge(
                            {},
                            formModel,
                            generateSidePanelFormDataAsObjFromRmsOtherItem(rmsProperties[0])
                        ),
                })
            );
        }

        overlayStore.close(OverlayIdEnum.DEX_SIDE_PANEL);
    };

    const federatedSearchButtonsConfiguration: ResultActionButtonsConfiguration = {
        profile: {
            searchProfiles: async (searchString) => {
                const response = await elasticSearchResource.searchAll({
                    size: queryParamDefaults.SIZE,
                    query: searchString,
                    searchTypes: [ElasticSearchTypeEnum.PROPERTY.name],
                });
                return response.property;
            },
            addToProfile: async ({ profileId, result }) => {
                const dexItems = result?.ARTICLE;
                if (dexItems) {
                    if (profileId) {
                        overlayStore.open(OverlayIdEnum.OVERRIDE_ITEM_PROFILE_MODAL, {
                            dexItems,
                            rmsProfileId: profileId,
                        });
                    } else {
                        handleCreateNewProfile(dexItems);
                    }
                }
            },
            buttonTitle: strings.addToReport,
        },
    };

    return {
        federatedSearchButtonsConfiguration,
        handleOverrideProfile,
        handleCancelOverrideProfile,
    };
};
