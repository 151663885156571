import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { noop } from 'lodash';
import { ReportStatusView } from '@mark43/rms-api';
import withFields from '~/client-common/core/fields/components/withFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import reportApprovalLevelClientEnum from '~/client-common/core/enums/client/reportApprovalLevelClientEnum';
import { DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW } from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { isOffenseModifyingSupplementReportSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { Field } from '~/client-common/core/fields/state/config';
import { OnlyWithAbility } from '../../../../core/abilities';
import { Tooltip } from '../../../../core/components/tooltip';
import { reportIsUcrValidatedSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import Row from '../../../../core/components/Row';
import { secondaryApproveReport } from '../../state/ui/submissions';
import testIds from '../../../../../core/testIds';
import { validateStaffReview } from '../../state/ui/submissionValidations';
import { Button as ArcButton, ButtonGroup } from '../../../../core/components/Button';
import { currentReportSealingSelector } from '../../../../record-privacy/sealing/state/ui';
import CardWithApprovalStatusIcon from './CardWithApprovalStatusIcon';
import EditRecordLabels from './EditRecordLabels';
import RescindStaffReviewModal from './RescindStaffReviewModal';
import SubmissionErrorModal from './SubmissionErrorModal';
import { NibrsErrors } from './NibrsAdminSection';

const strings = componentStrings.reports.core.ReportStatusCommentsCard;

interface PendingSecondaryReviewCardOuterPropsT {
    className?: string;
    disabled?: boolean;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
    reportStatusView: ReportStatusView;
    rejectReport: (callback: () => void) => void;
    ucrRejectReport: () => void;
    nibrsErrorsData?: NibrsErrors;
    shouldDisableApprovalButton?: boolean;
    currentReportApprovalLevel?: string;
}
interface PendingSecondaryReviewCardInnerPropsT extends PendingSecondaryReviewCardOuterPropsT {
    isOmsReport: ReturnType<typeof isOffenseModifyingSupplementReportSelector>;
    fieldDisplayNames: Record<Field, string>;
    reportIsUcrValidated: boolean;
    secondaryApproveReport: () => Promise<void>;
    validateStaffReview: () => void;
}

const PendingSecondaryReviewCard = ({
    reportStatusView,
    className,
    reportIsUcrValidated,
    isOmsReport,
    ucrRejectReport,
    secondaryApproveReport,
    disabled,
    reportSealingInfo,
    validateStaffReview,
    fieldDisplayNames,
    rejectReport,
    nibrsErrorsData,
    shouldDisableApprovalButton,
    currentReportApprovalLevel,
}: PendingSecondaryReviewCardInnerPropsT) => {
    const [validationAttempted, setValidationAttempted] = useState(false);
    const handleValidateStaffReview = () => {
        setValidationAttempted(true);
        return validateStaffReview();
    };
    const isSingleReview = currentReportApprovalLevel === reportApprovalLevelClientEnum.ONE;
    const latestHistoryText = strings.pendingSecondaryReview.pendingSecondaryReviewText;

    const canSecondaryActions =
        reportStatusView.canStaffReview || reportStatusView.canSecondaryReject;

    const onRejectReport = reportStatusView.canSecondaryReject
        ? ucrRejectReport
        : reportStatusView.canReject
        ? rejectReport
        : noop;

    const toolTipContent = reportStatusView.canStaffReview
        ? strings.core.tooltips.canSecondaryApprove
        : strings.core.tooltips.missingAbilityToApprove;

    const forceApproveButton = (
        <ArcButton
            isTextTransformNone
            testId={
                testIds.REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD_FORCE_APPROVE_BUTTON
            }
            onClick={secondaryApproveReport}
            isDisabled={!validationAttempted || reportIsUcrValidated}
            variant="solid"
        >
            {strings.core.forceApprove}
        </ArcButton>
    );

    return (
        <CardWithApprovalStatusIcon
            approvalStatus={approvalStatusClientEnum.PENDING_SECONDARY_REVIEW}
            reportSealingInfo={reportSealingInfo}
            title={fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW}
            className={className}
            latestHistoryText={latestHistoryText}
            customSummaryModeColor={'lightYellow'}
            customSummaryModeBorderColor={'brightYellow'}
            testId={testIds.REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD}
            shouldDisableApprovalButton={reportIsUcrValidated && shouldDisableApprovalButton}
            nibrsErrorsData={nibrsErrorsData}
            isSingleReview={isSingleReview}
        >
            <Row>{!disabled && <EditRecordLabels reportId={reportStatusView.report.id} />}</Row>
            <ButtonGroup style={{ marginTop: 'var(--arc-space-4)' }}>
                {canSecondaryActions && !disabled && (
                    <>
                        <ArcButton
                            isTextTransformNone
                            isDisabled={!reportStatusView.canStaffReview}
                            onClick={handleValidateStaffReview}
                            testId={testIds.VALIDATE_REPORT_BUTTON}
                            variant="solid"
                        >
                            {strings.core.validate}
                        </ArcButton>
                        <SubmissionErrorModal />
                    </>
                )}
                {(reportStatusView.canReject || canSecondaryActions) && !disabled && (
                    <ButtonGroup>
                        {reportIsUcrValidated ? (
                            <Tooltip side="bottom" content={shouldDisableApprovalButton ? componentStrings.reports.core.NibrsAdminSection.message.restrictReportTooltip : ''}>
                                <ArcButton
                                    leadingVisual="Check"
                                    isTextTransformNone
                                    variant="solid"
                                    testId={testIds.REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD_APPROVE_BUTTON}
                                    onClick={reportIsUcrValidated ? secondaryApproveReport : noop}
                                    isDisabled={reportIsUcrValidated && shouldDisableApprovalButton}
                                >
                                    {strings.core.approve}
                                </ArcButton>
                            </Tooltip>
                        ) : (
                            <Tooltip side="bottom" content={toolTipContent}>
                                <ArcButton
                                    leadingVisual="Check"
                                    isTextTransformNone
                                    variant="solid"
                                    testId={testIds.REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD_APPROVE_BUTTON}
                                    onClick={reportIsUcrValidated ? secondaryApproveReport : noop}
                                    isDisabled={!reportIsUcrValidated}
                                >
                                    {strings.core.approve}
                                </ArcButton>
                            </Tooltip>
                        )}
                        <ArcButton
                            leadingVisual="CloseX"
                            isTextTransformNone
                            isDisabled={
                                !(reportStatusView.canReject || reportStatusView.canSecondaryReject)
                            }
                            variant="outline"
                            onClick={onRejectReport}
                            testId={
                                testIds.REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD_REJECT_BUTTON
                            }
                        >
                            {strings.core.reject}
                        </ArcButton>
                    </ButtonGroup>
                )}
            </ButtonGroup>
            {/* allows mark43 support to force approve OMS reports with validation errors that cannot
                be resolved without an SQL script for customers who don't want to use RMS_NEW_OMS_WORKFLOW_ENABLED */}
            {reportStatusView.report?.id && isOmsReport(reportStatusView.report.id) && (
                <OnlyWithAbility has={abilitiesEnum.SUPPORT.GENERAL_INTERNAL_SUPPORT}>
                    <FeatureFlagged
                        flag="RMS_NEW_OMS_WORKFLOW_ENABLED"
                        fallback={
                            <Row style={{ marginTop: 'var(--arc-space-3)' }}>
                                {forceApproveButton}
                            </Row>
                        }
                    />
                </OnlyWithAbility>
            )}
            <RescindStaffReviewModal />
        </CardWithApprovalStatusIcon>
    );
};

const mapStateToProps = createStructuredSelector({
    reportIsUcrValidated: reportIsUcrValidatedSelector,
    isOmsReport: isOffenseModifyingSupplementReportSelector,
});

const mapDispatchToProps = {
    secondaryApproveReport,
    validateStaffReview,
};

export default compose<
    PendingSecondaryReviewCardInnerPropsT,
    PendingSecondaryReviewCardOuterPropsT
>(
    withFields([DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW]),
    connect(mapStateToProps, mapDispatchToProps)
)(PendingSecondaryReviewCard);
