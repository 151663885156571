import React from 'react';
import styled from 'styled-components';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import { SearchModuleType } from '../../../../core/typings/search-module';
import Modal from '../../../core/overlays/components/Modal';
import OverlayButton, { buttonTypes } from '../../../core/overlays/components/OverlayButton';
import SavedSearchContent from './SavedSearchContent';
import ShareSavedSearchModal from './ShareSavedSearchModal';

interface SavedSearchModalProps {
    searchModule: SearchModuleType;
    onSavedSearchExecuteSuccess?: () => void;
    hideSubscribeOption?: boolean;
    disabled?: boolean;
}

const StyledButton = styled(OverlayButton)`
    float: right;
    margin-top: 0;
    width: max-content;
`;

const strings = componentStrings.search.savedSearch.SavedSearchModal;
const labels = strings.labels;
const contentStyle = { height: 492 };

const SavedSearchModal: React.FC<SavedSearchModalProps> = ({
    hideSubscribeOption,
    onSavedSearchExecuteSuccess,
    searchModule,
    disabled,
}) => {
    const buttonElement = (
        <div className="saved-search-button-wrapper">
            <StyledButton
                disabled={disabled}
                className={buttonTypes.SECONDARY}
                id={overlayIdEnum.SAVED_SEARCH_MODAL}
                testId={testIds.SAVED_SEARCH_BUTTON}
            >
                {labels.button}
            </StyledButton>
        </div>
    );

    return (
        <Modal
            buttonElement={buttonElement}
            cancelText={labels.cancelButton}
            contentStyle={contentStyle}
            id={overlayIdEnum.SAVED_SEARCH_MODAL}
            okText={null}
            shouldCloseOnOverlayClick={true}
            title={strings.title}
        >
            <ShareSavedSearchModal searchModule={searchModule} />
            <SavedSearchContent
                hideSubscribeOption={hideSubscribeOption}
                onSavedSearchExecuteSuccess={onSavedSearchExecuteSuccess}
                searchModule={searchModule}
            />
        </Modal>
    );
};

export default SavedSearchModal;
