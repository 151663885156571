import React, { useCallback } from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { EFileView } from '@mark43/rms-api';
import { useResource } from '~/client-common/core/hooks/useResource';
import { storeAttributes } from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import Page from '../../../core/components/Page';
import _ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import Subheader from '../../../core/components/Subheader';
import { EFileProvider } from '../../contexts/eFileContext';
import { useEFileDisplayName, useEFileContext, useEFileTitle } from '../../hooks';
import { eFileResource } from '../../resources';
import { EFileSidebar } from './EFileSidebar';
import { EFileHeader } from './EFileHeader';

const strings = componentStrings.eFiles.core.header;

const ScrollableUnderSubheader = styled(_ScrollableUnderSubheader)`
    .mark43-auto-center {
        display: flex;
    }
`;

const EFilePageWrapper = styled.div`
    display: flex;
    flex: 1;
    max-height: 100%;
    overflow: hidden;
`;

const EFileContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
    background-color: ${(props) => props.theme.colors.white};
    min-width: ${(props) => props.theme.widths.caseContent}px;
    width: 100%;
`;

type ParamsT = {
    eFileId: string;
};

type EFileContainerT = RouteComponentProps<ParamsT, Record<string, unknown>> & {
    children: React.ReactNode;
};

const EFileContainerContent = ({ params, children, router }: EFileContainerT) => {
    const { eFileDisplayName } = useEFileDisplayName();
    const {
        actions,
        getters,
        handlers: { onFetchError },
    } = useEFileContext();
    const dispatch = useDispatch();
    const eFileId = parseInt(params.eFileId);

    const fetchEFileById = useCallback(() => {
        return eFileResource.getEFileById(eFileId);
    }, [eFileId]);

    const onFetchEFileByIdSuccess = useCallback(
        (eFileView: EFileView) => {
            const { attributes = [] } = eFileView;

            // eslint-disable-next-line no-restricted-syntax
            dispatch(storeAttributes(map(attributes, convertAttributeToAttributeView)));

            actions.efile.setEfile(eFileView);
        },
        [actions.efile, dispatch]
    );

    const { isLoading } = useResource(fetchEFileById, onFetchEFileByIdSuccess, onFetchError);

    const eFileTitle = useEFileTitle();

    if (isLoading) {
        return null;
    }

    const eFileViewModel = getters.efile.getEFileViewModel();

    if (!eFileViewModel) {
        return null;
    }

    const hideSidebar = router.isActive(`/e-file/${eFileViewModel.id}/history`);

    return (
        <Page>
            <Subheader
                title={strings.title(eFileDisplayName, eFileTitle)}
                onBackButtonClick={router.goBack}
            >
                <EFileHeader />
            </Subheader>
            <ScrollableUnderSubheader>
                <EFilePageWrapper>
                    {!hideSidebar && <EFileSidebar eFileId={eFileId} />}
                    <EFileContentContainer>{children}</EFileContentContainer>
                </EFilePageWrapper>
            </ScrollableUnderSubheader>
        </Page>
    );
};

export const EFileContainer = (props: EFileContainerT) => {
    return (
        <EFileProvider>
            <EFileContainerContent {...props} />
        </EFileProvider>
    );
};
