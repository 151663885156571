import React, { createContext, useContext, useMemo, useRef, useCallback, useState } from 'react';
import { DataTableConfig as ArcDataTableConfig, useToast } from 'arc';
import { debounce } from 'lodash';
import { DataTableEnum, DataTableEnumType, DataTableConfig } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResource, useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { safeJsonParse } from '~/client-common/helpers/jsonHelpers';
import { LoadingState } from '~/client-common/core/hooks/useLoadingState';
import dataTableConfigsResource from './dataTableConfigResource';

const BASE_CONFIG_MAP_ID = 'BASE_ID';

export type DataTableEnumValues = (typeof DataTableEnum)[keyof typeof DataTableEnum]['value'];
export type DataTableConfigUpsertData = Pick<DataTableConfig, 'configJson' | 'dataTableId'> &
    Partial<Pick<DataTableConfig, 'savedSearchId'>>;

type UseDataTableConfigReturn = {
    /** Accepts a table config and updates local currentConfig with that. If it is set to upsert it will also upsert the config for the provided saved search id. */
    updateSavedSearchConfig: (props: UpdateSavedSearchProps) => void;
    /** Accepts a table config to add or update in the table, sets both local state and automatically upserts it. */
    upsertDefaultDataTableConfig: (config: ArcDataTableConfig) => void;
    // If provided a savedSearchId, returns a parsed config for that saved search id if available, otherwise returns the currentConfig.
    getCurrentConfig: (savedSearchId?: number) => ArcDataTableConfig;
    /** Loading state from useResource while it is upserting the configs */
    loading: LoadingState;
};

type UseDataTableConfigProps = {
    dataTableName: DataTableEnumType;
};

type DataTableConfigMap = Map<string, string>;

type DataTableConfigContextT = UseDataTableConfigReturn | Record<string, never>;
type DataTableConfigContextProviderProps = UseDataTableConfigProps;

type UpdateSavedSearchProps = {
    /** Config to update with */
    config: ArcDataTableConfig;
} & (
    | {
          /** If true, it will also upsert the config to the BE. */
          shouldUpsert: true;
          savedSearchId: number;
      }
    | {
          /** If false it will only update the current config locally */
          shouldUpsert: false;
      }
);

const DataTableConfigContext = createContext<DataTableConfigContextT>({});

const UPSERT_DEBOUNCE_DELAY = 1500;

export function useDataTableConfigContext(): DataTableConfigContextT {
    const context = useContext(DataTableConfigContext);

    return context ?? {};
}

const strings = componentStrings.core.tables.tableConfigs;

const convertConfigsToMap = (configs: DataTableConfig[]): DataTableConfigMap => {
    const map = new Map();

    configs.forEach((config) =>
        map.set(config.savedSearchId?.toString() ?? BASE_CONFIG_MAP_ID, config.configJson)
    );

    return map;
};

export const DataTableConfigContextProvider = ({
    children,
    dataTableName,
}: React.PropsWithChildren<DataTableConfigContextProviderProps>) => {
    const dataTableId = DataTableEnum[dataTableName].value;
    const toast = useToast();

    const [configs, setConfigs] = useState<DataTableConfigMap>(new Map());
    const [currentConfig, setCurrentConfig] = useState<ArcDataTableConfig>();

    const handleFetchSuccess = useCallback((fetchedConfigs: DataTableConfig[]) => {
        const newConfigs = convertConfigsToMap(fetchedConfigs);
        setConfigs(newConfigs);
        const myCurrentConfig = newConfigs.get(BASE_CONFIG_MAP_ID);

        if (myCurrentConfig) {
            setCurrentConfig(safeJsonParse(myCurrentConfig));
        }
    }, []);

    const getCurrentConfig = useCallback(
        (savedSearchId?: number) => {
            if (!savedSearchId) {
                return currentConfig;
            }
            const newCurrentConfig = configs.get(savedSearchId?.toString());

            if (newCurrentConfig) {
                return safeJsonParse(newCurrentConfig);
            }

            return currentConfig;
        },
        [configs, currentConfig]
    );

    const fetchTableConfigs = useCallback(() => {
        return dataTableConfigsResource.getByDataTableId(dataTableId);
    }, [dataTableId]);

    useResource(fetchTableConfigs, handleFetchSuccess);

    const handleUpsertSuccess = useCallback(() => {
        toast.closeAll();
    }, [toast]);

    const handleUpsertError = useCallback(() => {
        toast.closeAll();

        toast({
            status: 'error',
            description: strings.upsertError,
        });
    }, [toast]);

    const upsertConfig = useCallback(
        (config: ArcDataTableConfig, savedSearchId?: number) => {
            const data: DataTableConfigUpsertData = {
                dataTableId,
                configJson: JSON.stringify(config),
                savedSearchId,
            };
            return dataTableConfigsResource.upsertDataTableConfig(data);
        },
        [dataTableId]
    );

    const { loading, callResource: callUpsertTableConfig } = useResourceDeferred(
        upsertConfig,
        handleUpsertSuccess,
        handleUpsertError
    );

    const debouncedUpsertCall = useRef(
        debounce((config: ArcDataTableConfig, savedSearchId?: number) => {
            if (loading.isLoading) {
                return;
            }
            setConfigs((prevMap) => {
                const updatedConfigs: DataTableConfigMap = new Map(prevMap);
                const mapKey = savedSearchId?.toString() ?? BASE_CONFIG_MAP_ID;
                updatedConfigs.set(mapKey, JSON.stringify(config));
                return updatedConfigs;
            });

            callUpsertTableConfig(config, savedSearchId);
        }, UPSERT_DEBOUNCE_DELAY)
    );

    const upsertDefaultDataTableConfig = useCallback(
        (config: ArcDataTableConfig) => {
            setCurrentConfig(config);
            debouncedUpsertCall.current.cancel();
            debouncedUpsertCall.current(config);
        },
        [debouncedUpsertCall]
    );

    const updateSavedSearchConfig = useCallback(
        (props: UpdateSavedSearchProps) => {
            setCurrentConfig(props.config);
            if (props.shouldUpsert) {
                debouncedUpsertCall.current.cancel();
                debouncedUpsertCall.current(props.config, props.savedSearchId);
            }
        },
        [debouncedUpsertCall]
    );

    const value = useMemo(() => {
        return {
            getCurrentConfig,
            upsertDefaultDataTableConfig,
            updateSavedSearchConfig,
            loading,
        };
    }, [getCurrentConfig, upsertDefaultDataTableConfig, updateSavedSearchConfig, loading]);

    return (
        <DataTableConfigContext.Provider value={value}>{children}</DataTableConfigContext.Provider>
    );
};
