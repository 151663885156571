import React from 'react';
import styled from 'styled-components';
import { CaseNote } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import { FormattedDate } from '~/client-common/core/dates/components';
import Link from '../../../../../modules/core/components/links/Link';
import { TableCellComponentProps } from '../tableTypes';
import { isColumnKeyPropObject } from '../tableHelpers';

const strings = componentStrings.core.tables.caseNotesTable.NoteTitleAuthorDateCell;

const StyledLink = styled(Link)`
    display: inline-block;
    margin-bottom: 10px;
`;

type NoteTitleAuthorDateCellProps = TableCellComponentProps<CaseNote>;

function NoteTitleAuthorDateCell(props: NoteTitleAuthorDateCellProps): JSX.Element | null {
    if (isColumnKeyPropObject(props)) {
        return null;
    }
    const { id, caseId, title, author, createdDateUtc } = props;
    const titleText = title || strings.untitled;

    return (
        <div>
            <StyledLink
                hoverOnUnderline={true}
                to={`cases/${caseId}/notes/${id}`}
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
            >
                {titleText}
            </StyledLink>
            <div>
                <ConnectedFormattedUser
                    userId={author}
                    format={FORMATS.FULL_NAME_WITH_FIRST_INITIAL_AND_ID_NUMBER}
                />
            </div>
            <div>
                <FormattedDate date={createdDateUtc} format={FormattedDate.FORMATS.SUMMARY_DATE}>
                    {(formattedDate) => <>{strings.createdDate(formattedDate || '')}</>}
                </FormattedDate>
            </div>
        </div>
    );
}

export default NoteTitleAuthorDateCell;
