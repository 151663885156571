import * as React from 'react';
import { useSelector } from 'react-redux';
import { RMSDragonForm } from './components/rms/rms-dragon-form';
import { dragonDataSelector } from './dragonRedux';

export const Main: React.FC<{ isNewReport: boolean; reportId: number }> = ({
    isNewReport,
    reportId,
}) => {
    const dragonData = useSelector(dragonDataSelector)[reportId];
    // don't render anything if our data is not available yet
    if (!dragonData) {
        return null;
    }

    return (
        <RMSDragonForm
            formData={dragonData.form}
            summaryData={dragonData.summary}
            isNewReport={isNewReport}
            reportId={reportId}
        />
    );
};
