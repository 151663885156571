import { InvolvementStatusEnum, StorageLocationTypeEnum } from '@mark43/evidence-api';
import { chain, find, get, head, omit } from 'lodash';

import { createSelector } from 'reselect';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import {
    DISPLAY_CHAIN_OF_CUSTODY_ACQUIRED_DATE_UTC,
    DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_DISPOSITION_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_TEMPORARY_LOCATIONS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_LOCATIONS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_IN_POLICE_CUSTODY_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_IS_OVERDUE_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_LATEST_STATUS_UPDATE_DATE_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_PERSONNEL_NAME_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_PRIMARY_REPORTING_OFFICERS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_REASONS_FOR_POLICE_CUSTODY_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_RECOVERING_OFFICERS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_SERIAL_NUMBER_LABEL,
    DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL,
    ITEM_IDENTIFIER_ITEM_IDENTIFIER_TYPE_ATTR_ID,
    DISPLAY_ONLY_ITEM_IDENTIFIER_LABEL,
    LOCATION_ENTITY_LINK_AGENCY_ID,
    REPORT_REPORTING_EVENT_NUMBER,
    DISPLAY_EVIDENCE_LABEL_ITEM_ID,
} from '~/client-common/core/enums/universal/fields';
import { createFormModule } from '../../../../core/forms';
import {
    formDataIsEmpty,
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { convertFormModelToFlatFilters } from '../../../../../legacy-redux/helpers/formFilterHelpers';
/**
 * @typedef {import('../../components/filters/manageEvidenceFilterHelpers').ManageEvidenceFormData} ManageEvidenceFormData
 */

const { FIELDSET, N_ITEMS_FIELDSET, RANGE, ATTRIBUTE } = fieldTypeClientEnum;
const { DATE_TIME_RANGE } = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;
const { PERMANENT } = StorageLocationTypeEnum;
const strings = componentStrings.evidence.dashboard.filterForm;

const fieldViewModels = {
    ...buildFlatFormFieldViewModels(['query']),
    acquiredDateRangeQuery: {
        type: FIELDSET,
        key: 'acquiredDateRangeQuery',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'withinLastPeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: WITHIN_LAST_PERIOD,
            },
            {
                key: 'toDatePeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: TO_DATE_PERIOD,
            },
            {
                key: 'startDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_START,
            },
            {
                key: 'endDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_END,
            },
        ]),
    },
    chainEventDateRangeQuery: {
        type: FIELDSET,
        key: 'chainEventDateRangeQuery',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'withinLastPeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: WITHIN_LAST_PERIOD,
            },
            {
                key: 'toDatePeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: TO_DATE_PERIOD,
            },
            {
                key: 'startDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_START,
            },
            {
                key: 'endDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_END,
            },
        ]),
    },
    renCreationDateRangeQuery: {
        type: FIELDSET,
        key: 'renCreationDateRangeQuery',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'withinLastPeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: WITHIN_LAST_PERIOD,
            },
            {
                key: 'toDatePeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: TO_DATE_PERIOD,
            },
            {
                key: 'startDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_START,
            },
            {
                key: 'endDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_END,
            },
        ]),
    },
    chainEventTypeIds: {
        key: 'chainEventTypeIds',
        type: fieldTypeClientEnum.CHAIN_EVENT_TYPE,
    },
    isOverdue: {
        key: 'isOverdue',
    },
    facilityIds: {
        key: 'facilityIds',
        label: strings.facility,
        type: fieldTypeClientEnum.FACILITY,
    },
    dispositionStatuses: {
        key: 'dispositionStatuses',
        type: fieldTypeClientEnum.DISPOSITION_STATUS,
    },
    storageLocationIds: {
        key: 'storageLocationIds',
        type: fieldTypeClientEnum.STORAGE_LOCATION,
    },
    excludeTemporaryStorageLocations: {
        key: 'excludeTemporaryStorageLocations',
    },
    excludeExpiredStorageLocations: {
        key: 'excludeExpiredStorageLocations',
    },
    itemCategoryAttrIds: {
        key: 'itemCategoryAttrIds',
        type: fieldTypeClientEnum.ATTRIBUTE,
    },
    propertyStatusAttrIds: {
        key: 'propertyStatusAttrIds',
        type: fieldTypeClientEnum.ATTRIBUTE,
    },
    reasonForPoliceCustodyAttrIds: {
        key: 'reasonForPoliceCustodyAttrIds',
        type: fieldTypeClientEnum.ATTRIBUTE,
    },
    itemTypeAttrIds: {
        key: 'itemTypeAttrIds',
        type: fieldTypeClientEnum.ATTRIBUTE,
    },
    reportingEventNumbers: {
        key: 'reportingEventNumbers',
        type: N_ITEMS_FIELDSET,
        fieldNameForFilterLabel: REPORT_REPORTING_EVENT_NUMBER,
        fields: buildFlatFormFieldViewModels(['reportingEventNumber']),
        label: '', // dynamic label, don't use fieldStrings.js
    },
    responsibleOfficerIds: {
        key: 'responsibleOfficerIds',
        type: fieldTypeClientEnum.USER,
    },
    recoveredByOfficerIds: {
        key: 'recoveredByOfficerIds',
        type: fieldTypeClientEnum.USER,
    },
    excludeExpiredStatuses: {
        key: 'excludeExpiredStatuses',
    },
    agencyIds: {
        key: 'agencyIds',
        type: fieldTypeClientEnum.AGENCY,
    },
    serialNumber: {
        key: 'serialNumber',
    },
    itemIds: {
        key: 'itemIds',
        type: N_ITEMS_FIELDSET,
        fields: buildFlatFormFieldViewModels(['itemId']),
        fieldNameForFilterLabel: DISPLAY_EVIDENCE_LABEL_ITEM_ID,
    },
    identifierTypeAttrIds: {
        key: 'identifierTypeAttrIds',
        type: N_ITEMS_FIELDSET,
        fields: buildFlatFormFieldViewModels([
            {
                key: 'identifierTypeAttrId',
                type: ATTRIBUTE,
                fieldName: ITEM_IDENTIFIER_ITEM_IDENTIFIER_TYPE_ATTR_ID,
            },
            'identifier',
        ]),
        filterLabel: strings.itemIdentifier,
    },
    personnelIds: {
        key: 'personnelIds',
        type: N_ITEMS_FIELDSET,
        fieldNameForFilterLabel: DISPLAY_ONLY_EVIDENCE_SEARCH_PERSONNEL_NAME_LABEL,
        fields: buildFlatFormFieldViewModels([
            {
                key: 'personnelId',
                type: fieldTypeClientEnum.USER,
            },
            {
                key: 'involvementStatus',
                type: fieldTypeClientEnum.INVOLVEMENT_STATUS,
            },
        ]),
    },
};

/**
 * Convert the given search query model to form state. While the query is flat,
 *   the form state has a nested structure. The logic here is temporarily based
 *   on the elastic property model as of 2016-10-21.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {ManageEvidenceFormData}
 */
export function convertEvidenceDashboardSearchElasticQueryToFormModel(elasticQuery = {}) {
    const propertyStatus = head(elasticQuery.propertyStatuses) || {};
    const storageLocationTypes = get(elasticQuery, 'chainEvents[0].storageLocationTypes') || [];
    return buildFormModel(
        {
            query: elasticQuery.query,
            acquiredDateRangeQuery: elasticQuery.acquiredDateRangeQuery,
            renCreationDateRangeQuery: elasticQuery.renCreationDateRangeQuery,
            chainEventDateRangeQuery: get(elasticQuery, 'chainEvents[0].eventDateRangeQuery'),
            chainEventTypeIds: get(elasticQuery, 'chainEvents[0].eventTypeIds'),
            storageLocationIds: get(elasticQuery, 'chainEvents[0].storageLocationIds'),
            facilityIds: get(elasticQuery, 'chainEvents[0].facilityIds'),
            excludeTemporaryStorageLocations:
                storageLocationTypes.length === 1 && storageLocationTypes[0] === PERMANENT.name
                    ? true
                    : undefined,
            excludeExpiredStorageLocations: elasticQuery.isExpired ? true : undefined,
            itemCategoryAttrIds: elasticQuery.itemCategoryAttrIds,
            itemTypeAttrIds: elasticQuery.itemTypeAttrIds,
            personnelIds: chain(elasticQuery)
                .get('involvedUsers')
                .map((involvedUser) => ({
                    personnelId: involvedUser.userIds?.[0],
                    involvementStatus: find(
                        InvolvementStatusEnum,
                        (involvementStatus) =>
                            involvedUser.involvementStatusEnumIds?.[0] === involvementStatus.value
                    )?.name,
                }))
                .value(),
            reportingEventNumbers: chain(elasticQuery)
                .get('reportingEventNumbers')
                .map((reportingEventNumber) => ({ reportingEventNumber }))
                .value(),
            itemIds: chain(elasticQuery)
                .get('itemIds')
                .map((itemId) => ({ itemId }))
                .value(),
            identifierTypeAttrIds: chain(elasticQuery)
                .get('itemIdentifiers')
                .map((itemIdentifier) => ({
                    identifierTypeAttrId: itemIdentifier.itemIdentifierTypeAttrId,
                    identifier: itemIdentifier.identifier,
                }))
                .value(),
            responsibleOfficerIds: elasticQuery.responsibleOfficerIds,
            recoveredByOfficerIds: get(propertyStatus, 'recoveredByOfficerIds'),
            propertyStatusAttrIds: get(propertyStatus, 'propertyStatusAttrIds'),
            reasonForPoliceCustodyAttrIds: get(propertyStatus, 'reasonForPoliceCustodyAttrIds'),
            dispositionStatuses: elasticQuery.dispositionStatuses,
            // leaving this field blank means searching for items that are either overdue or not overdue
            isOverdue: !!get(elasticQuery, 'overdueDateRangeQuery.withinLastPeriod')
                ? true
                : undefined,
            agencyIds: elasticQuery.agencyIds,
            serialNumber: elasticQuery.serialNumber,
        },
        fieldViewModels
    );
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching. The logic here is temporarily based on
 *   the elastic property model as of 2016-10-21.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
export function convertEvidenceDashboardSearchFormModelToElasticQuery(formModel = {}) {
    return filterFormData({
        query: formModel.query,
        propertyStatuses: [
            {
                propertyStatusAttrIds: formModel.propertyStatusAttrIds,
                reasonForPoliceCustodyAttrIds: formModel.reasonForPoliceCustodyAttrIds,
                recoveredByOfficerIds: formModel.recoveredByOfficerIds,
            },
        ],
        acquiredDateRangeQuery: formModel.acquiredDateRangeQuery,
        renCreationDateRangeQuery: formModel.renCreationDateRangeQuery,
        itemCategoryAttrIds: formModel.itemCategoryAttrIds,
        itemTypeAttrIds: formModel.itemTypeAttrIds,
        itemIdentifiers: chain(formModel)
            .get('identifierTypeAttrIds')
            .map((identifierType) => ({
                itemIdentifierTypeAttrId: identifierType.identifierTypeAttrId,
                identifier: identifierType.identifier,
            }))
            .value(),
        reportingEventNumbers: chain(formModel)
            .get('reportingEventNumbers')
            .map(({ reportingEventNumber }) => reportingEventNumber)
            .value(),
        itemIds: chain(formModel)
            .get('itemIds')
            .map(({ itemId }) => itemId)
            .value(),
        responsibleOfficerIds: formModel.responsibleOfficerIds,
        dispositionStatuses: formModel.dispositionStatuses,
        isExpired: formModel.excludeExpiredStorageLocations ? true : undefined,
        chainEvents: [
            {
                eventDateRangeQuery: formModel.chainEventDateRangeQuery,
                storageLocationIds: formModel.storageLocationIds,
                facilityIds: formModel.facilityIds,
                storageLocationTypes: formModel.excludeTemporaryStorageLocations
                    ? [PERMANENT.name]
                    : undefined,
                eventTypeIds: formModel.chainEventTypeIds,
            },
        ],
        // The server accepts a DateRangeQuery, so we convert our 1 boolean field to a
        // DateRangeQuery object with a long period instead of a timestamp because the timestamp
        // won't work with saved search. Do not arbitrarily change 9999 because prod users can have
        // it saved in localStorage.
        overdueDateRangeQuery: {
            withinLastPeriod: formModel.isOverdue ? 'P9999Y' : undefined,
        },
        agencyIds: formModel.agencyIds,
        serialNumber: formModel.serialNumber,
        involvedUsers: formModel.personnelIds?.map((pi) => ({
            userIds: [pi.personnelId],
            involvementStatusEnumIds: [InvolvementStatusEnum[pi.involvementStatus]?.value],
        })),
    });
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object} boundSelectors  selectors that were bound to the search module
 * @return {Object}   Array of filter group view models.
 */
export function convertEvidenceDashboardSearchFormModelToFilterGroups(
    formModel,
    formatFieldValue,
    boundSelectors
) {
    const renCreationDateRangeLabel = boundSelectors.renCreationDateRangeLabel;
    const formatFieldByName = boundSelectors.formatFieldByName;
    const relabeledFieldViewModels = {
        ...fieldViewModels,
        acquiredDateRangeQuery: {
            ...fieldViewModels.acquiredDateRangeQuery,
            fields: {
                ...fieldViewModels.acquiredDateRangeQuery.fields,
                withinLastPeriod: {
                    ...fieldViewModels.renCreationDateRangeQuery.fields.withinLastPeriod,
                    label: formatFieldByName(DISPLAY_CHAIN_OF_CUSTODY_ACQUIRED_DATE_UTC),
                },
            },
        },
        chainEventDateRangeQuery: {
            ...fieldViewModels.chainEventDateRangeQuery,
            fields: {
                ...fieldViewModels.chainEventDateRangeQuery.fields,
                withinLastPeriod: {
                    ...fieldViewModels.chainEventDateRangeQuery.fields.withinLastPeriod,
                    label: formatFieldByName(
                        DISPLAY_ONLY_EVIDENCE_SEARCH_LATEST_STATUS_UPDATE_DATE_LABEL
                    ),
                },
            },
        },
        chainEventTypeIds: {
            ...fieldViewModels.chainEventTypeIds,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL),
        },
        isOverdue: {
            ...fieldViewModels.isOverdue,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_IS_OVERDUE_LABEL),
        },
        dispositionStatuses: {
            ...fieldViewModels.dispositionStatuses,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_DISPOSITION_STATUSES_LABEL),
        },
        storageLocationIds: {
            ...fieldViewModels.storageLocationIds,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL),
        },
        excludeTemporaryStorageLocations: {
            ...fieldViewModels.excludeTemporaryStorageLocations,
            label: formatFieldByName(
                DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_TEMPORARY_LOCATIONS_LABEL
            ),
        },
        excludeExpiredStorageLocations: {
            ...fieldViewModels.excludeExpiredStorageLocations,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_LOCATIONS_LABEL),
        },
        itemCategoryAttrIds: {
            ...fieldViewModels.itemCategoryAttrIds,
            label: formatFieldByName(DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL),
        },
        propertyStatusAttrIds: {
            ...fieldViewModels.propertyStatusAttrIds,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_IN_POLICE_CUSTODY_STATUSES_LABEL),
        },
        reasonForPoliceCustodyAttrIds: {
            ...fieldViewModels.reasonForPoliceCustodyAttrIds,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_REASONS_FOR_POLICE_CUSTODY_LABEL),
        },
        identifierTypeAttrIds: {
            ...fieldViewModels.identifierTypeAttrIds,
            fields: {
                ...fieldViewModels.identifierTypeAttrIds.fields,
                identifierTypeAttrId: {
                    ...fieldViewModels.identifierTypeAttrIds.fields.identifierTypeAttrId,
                    label: formatFieldByName(ITEM_IDENTIFIER_ITEM_IDENTIFIER_TYPE_ATTR_ID),
                },
                identifier: {
                    ...fieldViewModels.identifierTypeAttrIds.fields.identifier,
                    label: formatFieldByName(DISPLAY_ONLY_ITEM_IDENTIFIER_LABEL),
                },
            },
        },
        itemTypeAttrIds: {
            ...fieldViewModels.itemTypeAttrIds,
            label: formatFieldByName(DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL),
        },
        responsibleOfficerIds: {
            ...fieldViewModels.responsibleOfficerIds,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_PRIMARY_REPORTING_OFFICERS_LABEL),
        },
        recoveredByOfficerIds: {
            ...fieldViewModels.recoveredByOfficerIds,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_RECOVERING_OFFICERS_LABEL),
        },
        excludeExpiredStatuses: {
            ...fieldViewModels.excludeExpiredStatuses,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_STATUSES_LABEL),
        },
        agencyIds: {
            ...fieldViewModels.agencyIds,
            label: formatFieldByName(LOCATION_ENTITY_LINK_AGENCY_ID),
        },
        serialNumber: {
            ...fieldViewModels.serialNumber,
            label: formatFieldByName(DISPLAY_ONLY_EVIDENCE_SEARCH_SERIAL_NUMBER_LABEL),
        },
        renCreationDateRangeQuery: {
            ...fieldViewModels.renCreationDateRangeQuery,
            fields: {
                ...fieldViewModels.renCreationDateRangeQuery.fields,
                withinLastPeriod: {
                    ...fieldViewModels.renCreationDateRangeQuery.fields.withinLastPeriod,
                    label: renCreationDateRangeLabel,
                },
                startDateUtc: {
                    ...fieldViewModels.renCreationDateRangeQuery.fields.startDateUtc,
                    label: renCreationDateRangeLabel,
                },
                endDateUtc: {
                    ...fieldViewModels.renCreationDateRangeQuery.fields.endDateUtc,
                    label: renCreationDateRangeLabel,
                },
                toDatePeriod: {
                    ...fieldViewModels.renCreationDateRangeQuery.fields.toDatePeriod,
                    label: renCreationDateRangeLabel,
                },
            },
        },
    };
    return convertFormModelToFlatFilters(
        formModel,
        relabeledFieldViewModels,
        formatFieldValue,
        formatFieldByName
    );
}

const evidenceDashboardSearchForm = createFormModule({
    formName: formClientEnum.EVIDENCE_DASHBOARD_SEARCH,
    fieldViewModels,
    convertToFormModel: convertEvidenceDashboardSearchElasticQueryToFormModel,
    convertFromFormModel: convertEvidenceDashboardSearchFormModelToElasticQuery,
});

evidenceDashboardSearchForm.selectors.formIsEmptySelector = createSelector(
    evidenceDashboardSearchForm.selectors.formModelSelector,
    (formModel) => formDataIsEmpty(omit(formModel, 'query'))
);

/**
 * Module of the search form on the evidence dashboard. It contains both the
 *   free text search input and the filters below it.
 * @type {Object}
 */
export default evidenceDashboardSearchForm;
