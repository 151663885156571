import {
    compact,
    filter,
    find,
    identity,
    includes,
    isArray,
    isEmpty,
    map,
    noop,
    reduce,
    reject,
    uniqBy,
} from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    AttributeTypeEnum,
    ElasticStorageLocation,
    OperationTypeEnumType,
    SearchResultElasticStorageLocation,
    StorageLocationTypeEnumType,
} from '@mark43/evidence-api';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatStorageLocationNames } from '~/client-common/core/domain/storage-locations/utils/storageLocationHelpers';
import type { ClientElasticStorageLocation } from '~/client-common/core/domain/elastic-storage-locations/state/data';
import {
    NEXUS_STATE_PROP as ELASTIC_STORAGE_LOCATIONS_NEXUS_STATE_PROP,
    elasticStorageLocationsSelector,
} from '~/client-common/core/domain/elastic-storage-locations/state/data';
import { RmsDispatch } from '../../../../../core/typings/redux';
import { attributeLoadingStateByAttributeTypeSelector } from '../../../attributes/state/ui';
import { loadAttributesForType } from '../../../attributes/state/ui/loadAttributesForType';
import { computeLoadableAttributeTypes } from '../../../attributes/utils/computeLoadableAttributeTypes';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import type { SelectOption } from '../../helpers/selectHelpers';
import { useFetchStorageLocations } from '../../../hooks/useFetchStorageLocations';
import { arbiterMFTInput } from '../../../arbiter';
import DeprecatedStorageLocationSelect from './DeprecatedStorageLocationSelect';

import AsyncSelect from './AsyncSelect';
import type { SelectProps } from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

function valueToArray(value: unknown): number[] {
    if (isArray(value)) {
        return map(value, Number);
    } else if (value) {
        return [Number(value)];
    } else {
        return [];
    }
}
function convertStorageLocationToOption(
    elasticStorageLocation: ClientElasticStorageLocation
): SelectOption {
    return {
        value: elasticStorageLocation.id,
        label: formatStorageLocationNames(elasticStorageLocation.parentLocationNames),
        subtitle: elasticStorageLocation.barcodeValue,
    };
}

function useOptions(
    selectedIds: number[]
): {
    options: SelectOption[];
    cacheSelectedValues: (values: number[]) => void;
    replaceOptions: (elasticStorageLocations: ElasticStorageLocation[]) => void;
    clearOptions: () => void;
} {
    const dispatch = useDispatch<RmsDispatch>();

    // the first cache represents the currently selected options, which must persist when the search results change
    // the second cache represents search results from the server, which change independently of the currently selected options
    // these 2 arrays may have duplicate values, we combine and de-duplicate them below
    const [selectedOptions, setSelectedOptions] = React.useState<SelectOption[]>([]);
    const [searchResults, setSearchResults] = React.useState<ElasticStorageLocation[]>([]);
    const elasticStorageLocations = useSelector(elasticStorageLocationsSelector);

    React.useEffect(() => {
        const cachedValues = map(selectedOptions, 'value');
        // find any values that are not yet cached, and add them to the options cache using data from Redux state
        const newIds = reject(selectedIds, (id) => includes(cachedValues, id));
        const newLocations = compact(map(newIds, (id) => elasticStorageLocations[id]));
        if (newLocations.length > 0) {
            setSelectedOptions((cache) => [
                ...cache,
                ...map(newLocations, (location) => convertStorageLocationToOption(location)),
            ]);
        }
    }, [selectedIds, selectedOptions, elasticStorageLocations]);

    // build the dropdown options in 1 or 2 groups
    const options: SelectOption[] = React.useMemo(() => {
        return uniqBy(
            [
                ...selectedOptions,
                ...map(searchResults, (location) => convertStorageLocationToOption(location)),
            ],
            'value'
        );
    }, [selectedOptions, searchResults]);

    // update the cache whenever a storage location is added or removed from the selected values
    const cacheSelectedValues = React.useCallback(
        (values: number[]) => {
            const newSelectedOptions = reduce<number, SelectOption[]>(
                values,
                (acc, value) => {
                    const option = find(options, {
                        value,
                    });
                    if (option) {
                        return [...acc, option];
                    }
                    return acc;
                },
                []
            );
            setSelectedOptions(newSelectedOptions);

            if (values.length > 0) {
                // multiple usages of this component rely on the selected values to be cached in Redux state
                // (not all search results, only the ones that the user has selected)
                const ids = map(values, Number);
                const locationsToCache = filter(searchResults, (elasticStorageLocation) =>
                    includes(ids, elasticStorageLocation.id)
                );
                dispatch((dispatch, getState, dependencies) =>
                    dispatch(
                        dependencies.nexus.withEntityItems(
                            {
                                [ELASTIC_STORAGE_LOCATIONS_NEXUS_STATE_PROP]: locationsToCache,
                            },
                            { type: 'CACHE_ELASTIC_STORAGE_LOCATIONS' }
                        )
                    )
                );
            }
        },
        [options, searchResults, dispatch]
    );

    const clearOptions = React.useCallback(() => {
        setSearchResults([]);
    }, []);

    return {
        options,
        cacheSelectedValues,
        replaceOptions: setSearchResults,
        clearOptions,
    };
}

type StorageLocationSelectProps<Multi extends boolean = false> = SelectProps<
    SelectOption,
    Multi
> & {
    isExpired?: boolean;
    evidenceFacilityGlobalAttrIdFilter?: number[];
    facilityIdFilter?: number;
    storageLocationTypeFilter?: StorageLocationTypeEnumType[];
    requiredPermissions?: OperationTypeEnumType[];
};

function StorageLocationSelect<Multi extends boolean = false>(
    props: StorageLocationSelectProps<Multi>
): JSX.Element {
    const {
        isExpired,
        evidenceFacilityGlobalAttrIdFilter,
        facilityIdFilter,
        storageLocationTypeFilter,
        requiredPermissions,
        onChange,
        value,
    } = props;

    const dispatch: RmsDispatch = useDispatch();
    const { searchStorageLocations } = useFetchStorageLocations();

    // load Evidence Facility attributes in order to support evidenceFacilityGlobalAttrIdFilter
    const attributeLoadingStateByAttributeType = useSelector(
        attributeLoadingStateByAttributeTypeSelector
    );
    const attributeLoadingState = attributeLoadingStateByAttributeType(
        AttributeTypeEnum.EVIDENCE_FACILITY.name
    );
    const attributeTypesToLoad = React.useMemo(
        () => computeLoadableAttributeTypes(attributeLoadingState),
        [attributeLoadingState]
    );
    React.useEffect(() => {
        if (!isEmpty(attributeTypesToLoad)) {
            dispatch(loadAttributesForType({ attributeType: attributeTypesToLoad })).catch(noop);
        }
    }, [attributeTypesToLoad, dispatch]);

    const { options, cacheSelectedValues, replaceOptions, clearOptions } = useOptions(
        valueToArray(value)
    );

    const asyncAction = React.useCallback(
        (query: string) => {
            return searchStorageLocations({
                query,
                isExpired,
                evidenceFacilityGlobalAttrIdFilter,
                facilityIdFilter,
                requiredPermissions,
                storageLocationTypeFilter,
            })
                .then((result: SearchResultElasticStorageLocation) => {
                    if (result.items) {
                        // update the cache with new search results
                        replaceOptions(result.items);
                    }
                });
        },
        [
            searchStorageLocations,
            evidenceFacilityGlobalAttrIdFilter,
            facilityIdFilter,
            isExpired,
            replaceOptions,
            requiredPermissions,
            storageLocationTypeFilter,
        ]
    );

    const handleChange = React.useCallback(
        (value: number | number[]) => {
            cacheSelectedValues(valueToArray(value));

            if (onChange) {
                // @ts-expect-error see processChangeValue
                onChange(value);
            }
        },
        [cacheSelectedValues, onChange]
    );

    return (
        <AsyncSelect
            {...props}
            value={value}
            options={options}
            onChange={handleChange}
            // clear the cache on blur, otherwise the cached dropdown options would quickly appear and disappear the
            // next time the dropdown is opened, since the search results will be different
            onBlur={clearOptions}
            asyncAction={asyncAction}
            // ignore default sorting function in Select
            sortAttributes={identity}
            typeaheadThrottle={600}
        />
    );
}

function FeatureFlaggedStorageLocationSelect<Multi extends boolean = false>(
    props: StorageLocationSelectProps<Multi>
): JSX.Element {
    return (
        <FeatureFlagged
            flag="STORAGE_LOCATION_SEARCH_IMPROVEMENTS_ENABLED"
            fallback={<DeprecatedStorageLocationSelect {...props} />}
        >
            <StorageLocationSelect {...props} />
        </FeatureFlagged>
    );
}

/**
 * Typeahead for searching storage locations asynchronously.
 * - The sort order of search results from the server must be preserved. This component ignores the default front end
 *   sort logic for selects, otherwise it would show irrelevant results at the top.
 * - Unlike FacilitySelect and DeprecatedStorageLocationSelect, this component does not store new search results in
 *   global Redux state. Instead, it uses local state and directly converts new search results into dropdown options. It
 *   reads from Redux state using elasticStorageLocationsSelector only to show options for the initially selected
 *   values.
 * - The currently selected values are also cached in local state in order to persist as options, otherwise those values
 *   would disappear from the select input when the search results are cleared.
 */
export default FeatureFlaggedStorageLocationSelect;

// @ts-expect-error client-common to client RND-7529
export const RRFStorageLocationSelect = connectRRFInput(FeatureFlaggedStorageLocationSelect);
export const ArbiterMFTStorageLocationSelect = arbiterMFTInput(FeatureFlaggedStorageLocationSelect);
