import { Card, CardBody, CardHeader, DataItem, DataList } from 'arc';
import React from 'react';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    E_FILE_ATTRIBUTE_ATTRIBUTE_TYPE_COURT_UNIT_ATTRIBUTE_ID,
    E_FILE_ATTRIBUTE_ATTRIBUTE_TYPE_E_FILE_CLASSIFICATION_ATTRIBUTE_ID,
    E_FILE_ATTRIBUTE_TYPE_E_FILE_STATUS_STATUS_ATTR_ID,
    E_FILE_OFFICER_USER_ID,
    E_FILE_OWNER_USER_ID,
    E_FILE_ROLE_LINK_ROLE_ID,
    E_FILE_TITLE,
} from '~/client-common/core/enums/universal/fields';
import testIds from '../../../../../core/testIds';
import { useEFileContext, useEFileDisplayName } from '../../../hooks';
import { EFileSummaryRow } from './EFileSummaryRow';

const strings = componentStrings.eFiles.core.summaryPage.detailsCard;

const StyledDataList = styled(DataList)`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
`;

const StyledCardHeader = styled(CardHeader)`
    background-color: var(--arc-colors-surface-accent);
`;

const StyledCardBody = styled(CardBody)`
    display: flex;
`;
const StyledCard = styled(Card)`
    border: 0;
`;

export const EFileSummaryCard = () => {
    const { eFileDisplayName } = useEFileDisplayName();
    const { getters } = useEFileContext();
    const eFileViewModel = getters.efile.getEFileViewModel();

    return (
        <StyledCard>
            <StyledCardHeader title={strings.title(eFileDisplayName)} />
            <StyledCardBody data-test-id={testIds.E_FILE_SUMMARY_SECTION}>
                <StyledDataList variant="inline">
                    <DataItem dataKey={eFileDisplayName}>{eFileViewModel?.localId}</DataItem>
                    <EFileSummaryRow fieldName={E_FILE_TITLE}>
                        {eFileViewModel?.operationName}
                    </EFileSummaryRow>
                    <EFileSummaryRow
                        fieldName={
                            E_FILE_ATTRIBUTE_ATTRIBUTE_TYPE_E_FILE_CLASSIFICATION_ATTRIBUTE_ID
                        }
                    >
                        {eFileViewModel?.classifications}
                    </EFileSummaryRow>
                    <EFileSummaryRow
                        fieldName={E_FILE_ATTRIBUTE_ATTRIBUTE_TYPE_COURT_UNIT_ATTRIBUTE_ID}
                    >
                        {eFileViewModel?.cpsUnit}
                    </EFileSummaryRow>
                </StyledDataList>
                <StyledDataList variant="inline">
                    <EFileSummaryRow fieldName={E_FILE_OFFICER_USER_ID}>
                        {eFileViewModel?.officer}
                    </EFileSummaryRow>
                    <EFileSummaryRow fieldName={E_FILE_OWNER_USER_ID}>
                        {eFileViewModel?.owner}
                    </EFileSummaryRow>
                    <EFileSummaryRow fieldName={E_FILE_ROLE_LINK_ROLE_ID}>
                        {eFileViewModel?.contacts}
                    </EFileSummaryRow>
                    <EFileSummaryRow fieldName={E_FILE_ATTRIBUTE_TYPE_E_FILE_STATUS_STATUS_ATTR_ID}>
                        {eFileViewModel?.status}
                    </EFileSummaryRow>
                </StyledDataList>
            </StyledCardBody>
        </StyledCard>
    );
};
