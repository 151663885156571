import { flowRight, filter, chain } from 'lodash';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Flex } from 'arc';

import styled from 'styled-components';
import { OperationTypeEnum } from '@mark43/rms-api';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { vehiclesEnabledSelector } from '~/client-common/core/domain/evidence-department-config/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    DISPLAY_CHAIN_OF_CUSTODY_ACQUIRED_DATE_UTC,
    DISPLAY_ONLY_EVIDENCE_SEARCH_LATEST_STATUS_UPDATE_DATE_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL,
    DISPLAY_ONLY_PROPERTY_TYPE_LABEL,
    DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_PRIMARY_REPORTING_OFFICERS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_RECOVERING_OFFICERS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_DISPOSITION_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_IN_POLICE_CUSTODY_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_REASONS_FOR_POLICE_CUSTODY_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_IS_OVERDUE_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_TEMPORARY_LOCATIONS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_LOCATIONS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_SERIAL_NUMBER_LABEL,
    LOCATION_ENTITY_LINK_AGENCY_ID,
    DISPLAY_ONLY_ITEM_IDENTIFIER_LABEL,
    DISPLAY_EVIDENCE_LABEL_ITEM_ID,
    DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_ITEM_ID_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_IDENTIFIER_TYPE_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_PERSONNEL_NAME_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_INVOLVEMENT_STATUS_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_PERSONNEL_LABEL,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { chainEventTypeOptionsSelector } from '~/client-common/core/domain/chain-event-types/state/ui';

import useFields from '~/client-common/core/fields/hooks/useFields';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { agencyProfilesSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import testIds from '../../../../../core/testIds';
import { RRFItemCategoryAttributeSelect } from '../../../../core/forms/components/selects/ItemCategoryAttributeSelect';
import { RRFItemTypeAttributeSelect } from '../../../../core/forms/components/selects/ItemTypeAttributeSelect';
import ReactReduxForm from '../../../../core/forms/components/ReactReduxForm';
import Column from '../../../../core/components/Column';
import Row from '../../../../core/components/Row';
import { RRFDateRangePicker } from '../../../../core/forms/components/DateRangePicker';
import { RRFNItems } from '../../../../core/forms/components/NItems';
import { RRFText } from '../../../../core/forms/components/Text';
import { RRFCheckbox } from '../../../../core/forms/components/checkboxes/Checkbox';
import { RRFAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { DeprecatedRRFFacilityStorageLocationSelect } from '../../../../core/forms/components/selects/DeprecatedFacilityStorageLocationSelect';
import { RRFFacilityStorageLocationSelect } from '../../../../core/forms/components/selects/FacilityStorageLocationSelect';
import { RRFDispositionStatusSelect } from '../../../../core/forms/components/selects/DispositionStatusSelect';
import { RRFChainEventTypeSelect } from '../../../../core/forms/components/selects/ChainEventTypeSelect';
import { RRFUserSelect } from '../../../../core/forms/components/selects/UserSelect';
import { RRFAgencyProfileSelect } from '../../../../core/forms/components/selects/AgencyProfileSelect';
import { useDataTableConfigContext } from '../../../../core/data-table-config/DataTableConfigProvider';

import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import useOnSaveSearchToggleHandler from '../../../../search/core/hooks/useOnSaveSearchToggleHandler';

import {
    evidenceDashboardSearch,
    submitEvidenceDashboardSearchForm,
    clearAllFilters,
    executeSavedSearchFromFilter,
    applyFiltersAndUpdateSearch,
    renCreationDateRangeLabelSelector,
} from '../../state/ui';
import evidenceDashboardSearchForm from '../../state/forms/evidenceDashboardSearchForm';
import CreateSavedSearchModal from '../../../../search/saved-search/components/CreateSavedSearchModal';
import { DeleteSavedSearchConfirmationModal } from '../../../../search/saved-search/components/SavedSearchConfirmationModals';
import { SavedSearchDropdownButton } from '../../../../search/saved-search/components/SavedSearchDropdownButton';
import SearchCapabilityIcon, {
    SEARCH_CAPABILITY_TYPE_ENUM,
} from '../../../../search/core/components/SearchCapabilityIcon';
import { SearchName } from '../../../../search/core/components/SearchName';
import { IconButton } from '../../../../core/components/IconButton';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { RRFPersonnelInvolvementStatusSelect } from '../../../../core/forms/components/selects/PersonnelInvolvementStatusSelect';

const { withRRFActions } = reactReduxFormHelpers;

const strings = componentStrings.evidence.dashboard.filterForm;
const facilityStorageLocationSelectPaths = {
    storageLocationId: 'storageLocationIds',
    facilityId: 'facilityIds',
};
const quickSearchFieldName = 'query';

const withinLastPeriodOptions = ['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D'];
const toDatePeriodOptions = ['P1M', 'P1Y'];

const helpTextPlacement = 'top';

const DeleteButtonWrapper = styled(Flex)`
    align-items: center;
    margin-top: 10px;
`;

const FormWrapper = styled.div`
    margin-top: 20px;
    margin-left: 10px;
`;

const FooterWrapper = styled.div`
    display: flex;
`;

const IsOverdueCheckBox = styled(RRFCheckbox)`
    .help-text-container {
        /* this margin-top is to offset the 'margin-top: -1px' from HelpTextContainer */
        margin-top: 1px;
    }
`;

const StyledButton = styled(Button)`
    margin: 17px 14px 16px 4px;
    font-size: var(--arc-fontSizes-md);
    float: left;
    clear: none;
    color: ${(props) => props.theme.colors.mediumGrey};
    fill: ${(props) => props.theme.colors.mediumGrey};

    &:active,
    &:focus,
    &:hover {
        color: ${(props) => props.theme.colors.cobaltBlue};
        fill: ${(props) => props.theme.colors.cobaltBlue};
    }
`;

const UpdateSavedSearchButtonWrapper = styled.div`
    margin-top: 12px;
`;

const InlineIcon = styled.span`
    margin-left: var(--arc-space-2);
`;

function WildcardIcon() {
    return (
        <InlineIcon>
            <SearchCapabilityIcon variant={SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD.name} />
        </InlineIcon>
    );
}

const SaveSearchButton = ({ isSavedSearch, createSavedSearch, deleteSavedSearch, formIsEmpty }) => (
    <StyledButton
        className={buttonTypes.ICON_LINK}
        iconLeft={
            <Icon
                type={
                    isSavedSearch ? iconTypes.SAVED_SEARCH_ACTIVE : iconTypes.SAVED_SEARCH_INACTIVE
                }
            />
        }
        hoveredIconLeft={
            <Icon
                type={
                    isSavedSearch ? iconTypes.SAVED_SEARCH_INACTIVE : iconTypes.SAVED_SEARCH_ACTIVE
                }
            />
        }
        onClick={isSavedSearch ? deleteSavedSearch : createSavedSearch}
        disabled={formIsEmpty}
    >
        {isSavedSearch ? strings.footer.unsaveSearch : strings.footer.saveSearch}
    </StyledButton>
);

const buildEvidenceDashboardSavedSearch = (query, formModel) => {
    // We have to augment the query because we can save the search before executing it
    // Elsewhere in our codebase, we only allow saving searches after that search has been executed,
    return {
        ...query,
        elasticQuery: {
            ...evidenceDashboardSearch.form.convertFromFormModel(formModel),
            [quickSearchFieldName]: '',
        },
    };
};

const Footer = ({ dirty, setDirty }) => {
    const formIsEmpty = useSelector(evidenceDashboardSearch.form.selectors.formIsEmptySelector);
    const query = useSelector(evidenceDashboardSearch.selectors.currentQuerySelector);
    const currentSavedSearch = useSelector(
        evidenceDashboardSearch.selectors.currentSavedSearchSelector
    );
    const formModel = useSelector(evidenceDashboardSearchForm.selectors.formModelSelector);
    const isEvidenceDashboardRefreshEnabled = useSelector(
        applicationSettingsSelector
    ).RMS_EVIDENCE_DASHBOARD_REFRESH_ENABLED;
    const dataTableConfigContext = useDataTableConfigContext();

    const updateConfigOnSuccess = useCallback(
        (savedSearchId) => {
            if (!isEvidenceDashboardRefreshEnabled) {
                return;
            }
            const { updateSavedSearchConfig, getCurrentConfig } = dataTableConfigContext;
            const config = getCurrentConfig();

            if (config) {
                updateSavedSearchConfig({ config, shouldUpsert: true, savedSearchId });
            }
        },
        [dataTableConfigContext, isEvidenceDashboardRefreshEnabled]
    );

    const onSaveSearchToggle = useOnSaveSearchToggleHandler(
        evidenceDashboardSearch,
        updateConfigOnSuccess
    );
    const onSaveSearch = useCallback(
        (props) => {
            onSaveSearchToggle({
                query: buildEvidenceDashboardSavedSearch(query, formModel),
                ...props,
            });
        },
        [onSaveSearchToggle, query, formModel]
    );
    const dispatch = useDispatch();
    const applyFilters = () => dispatch(submitEvidenceDashboardSearchForm());
    const clearAppliedFilters = () => {
        dispatch(clearAllFilters());
    };
    const applySavedSearch = (savedSearchId) =>
        dispatch(executeSavedSearchFromFilter(savedSearchId));
    const updateSavedSearchButtonClickHandler = useCallback(() => {
        dispatch(evidenceDashboardSearchForm.actionCreators.changePath(quickSearchFieldName, ''));

        dispatch(applyFiltersAndUpdateSearch(currentSavedSearch, updateConfigOnSuccess));
    }, [dispatch, currentSavedSearch, updateConfigOnSuccess]);

    const isSavedSearch = query.savedSearchId && !query.isAutoSave;

    return (
        <FooterWrapper>
            <Button
                testId={testIds.EVIDENCE_DASHBOARD_FILTER_FORM_APPLY_FILTER}
                onClick={() => {
                    if (isSavedSearch && !dirty) {
                        applySavedSearch(query.savedSearchId);
                    } else {
                        applyFilters();
                    }
                }}
                className={classNames(buttonTypes.PRIMARY)}
            >
                {strings.applyFilters}
            </Button>
            <UpdateSavedSearchButtonWrapper>
                <SavedSearchDropdownButton
                    searchModule={evidenceDashboardSearch}
                    updateSavedSearchOverride={updateSavedSearchButtonClickHandler}
                />
            </UpdateSavedSearchButtonWrapper>
            <StyledButton
                testId={testIds.EVIDENCE_DASHBOARD_FILTER_FORM_CLEAR_FILTER}
                onClick={clearAppliedFilters}
                className={classNames(buttonTypes.ICON_LINK)}
                iconLeft={<Icon type={iconTypes.CLOSE_X} />}
            >
                {strings.clearFilters}
            </StyledButton>
            <FeatureFlagged
                flag="RMS_SAVED_SEARCH_SHARING_ENABLED"
                fallback={
                    <SaveSearchButton
                        isSavedSearch={isSavedSearch}
                        createSavedSearch={() => {
                            setDirty(false);
                            onSaveSearchToggle({
                                query: buildEvidenceDashboardSavedSearch(query, formModel),
                            });
                        }}
                        deleteSavedSearch={() => {
                            setDirty(true);
                            onSaveSearchToggle();
                        }}
                        formIsEmpty={formIsEmpty}
                    />
                }
            />
            <CreateSavedSearchModal onSave={onSaveSearch} />
            <DeleteSavedSearchConfirmationModal searchModule={evidenceDashboardSearch} />
        </FooterWrapper>
    );
};

const Body = withRRFActions(({ handleFormChange, formModel, formActions }) => {
    const vehiclesEnabled = useSelector(vehiclesEnabledSelector);
    const fieldDisplayNames = useFields([
        REPORT_REPORTING_EVENT_NUMBER,
        DISPLAY_CHAIN_OF_CUSTODY_ACQUIRED_DATE_UTC,
        DISPLAY_ONLY_EVIDENCE_SEARCH_LATEST_STATUS_UPDATE_DATE_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL,
        DISPLAY_ONLY_PROPERTY_TYPE_LABEL,
        DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_PRIMARY_REPORTING_OFFICERS_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_RECOVERING_OFFICERS_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_DISPOSITION_STATUSES_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_IN_POLICE_CUSTODY_STATUSES_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_REASONS_FOR_POLICE_CUSTODY_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_IS_OVERDUE_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_TEMPORARY_LOCATIONS_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_LOCATIONS_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_STATUSES_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_SERIAL_NUMBER_LABEL,
        LOCATION_ENTITY_LINK_AGENCY_ID,
        DISPLAY_ONLY_ITEM_IDENTIFIER_LABEL,
        DISPLAY_EVIDENCE_LABEL_ITEM_ID,
        DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_ITEM_ID_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_IDENTIFIER_TYPE_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_PERSONNEL_NAME_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_INVOLVEMENT_STATUS_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_PERSONNEL_LABEL,
    ]);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const agencyProfiles = useSelector(agencyProfilesSelector);
    const dispatch = useDispatch();
    const renCreationDateRangeLabel = useSelector(renCreationDateRangeLabelSelector);

    const isEvidenceFiltersEnabled = !!applicationSettings.EVIDENCE_FILTER_SORT_UPDATES;
    const chainEventTypeOptions = useSelector(chainEventTypeOptionsSelector);

    if (isEvidenceFiltersEnabled) {
        return (
            <>
                <Column width={267} marginRight={50}>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_STORAGE_LOCATION}>
                        <FeatureFlagged
                            flag="STORAGE_LOCATION_SEARCH_IMPROVEMENTS_ENABLED"
                            fallback={
                                <DeprecatedRRFFacilityStorageLocationSelect
                                    label={
                                        fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL
                                    }
                                    multiple={true}
                                    onChange={handleFormChange}
                                    paths={facilityStorageLocationSelectPaths}
                                    width="100%"
                                    useStorageLocationIdsInstead={true}
                                    requiredPermissions={[
                                        OperationTypeEnum.READ.name,
                                        OperationTypeEnum.MANAGE.name,
                                    ]}
                                />
                            }
                        >
                            <RRFFacilityStorageLocationSelect
                                label={
                                    fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL
                                }
                                multiple={true}
                                onChange={handleFormChange}
                                paths={facilityStorageLocationSelectPaths}
                                width="100%"
                                maxMenuHeight={350}
                            />
                        </FeatureFlagged>
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_EXCLUDE_TEMPORARY_LOCATION}>
                        <RRFCheckbox
                            onChange={handleFormChange}
                            path="excludeTemporaryStorageLocations"
                            label={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_TEMPORARY_LOCATIONS_LABEL
                            }
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_EXCLUDE_EXPIRED_LOCATION}>
                        <RRFCheckbox
                            onChange={handleFormChange}
                            path="excludeExpiredStorageLocations"
                            label={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_LOCATIONS_LABEL
                            }
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_CHAIN_OF_CUSTODY_STATUS}>
                        <RRFChainEventTypeSelect
                            label={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL
                            }
                            width="100%"
                            includeExpired={!formModel.excludeExpiredStatuses}
                            onChange={handleFormChange}
                            path="chainEventTypeIds"
                            multiple={true}
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_EXCLUDE_EXPIRE_STATUSES}>
                        <RRFCheckbox
                            onChange={(value) => {
                                // filter out selected expired statuses
                                if (!!value && formModel.chainEventTypeIds) {
                                    const expiredChainEventTypeById = chain(chainEventTypeOptions)
                                        .filter(({ isExpired }) => isExpired === true)
                                        .keyBy('value')
                                        .value();

                                    const newChainEventTypeIds = filter(
                                        formModel.chainEventTypeIds,
                                        (id) => !expiredChainEventTypeById[id]
                                    );
                                    dispatch(
                                        evidenceDashboardSearchForm.actionCreators.changePath(
                                            'chainEventTypeIds',
                                            newChainEventTypeIds
                                        )
                                    );
                                }
                                handleFormChange();
                            }}
                            path="excludeExpiredStatuses"
                            label={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_STATUSES_LABEL
                            }
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_DISPOSITION_STATUS}>
                        <RRFDispositionStatusSelect
                            label={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_DISPOSITION_STATUSES_LABEL
                            }
                            width="100%"
                            onChange={handleFormChange}
                            path="dispositionStatuses"
                            multiple={true}
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_IN_POLICE_CUSTODY_STATUS}>
                        <RRFAttributeSelect
                            attributeType="PROPERTY_LOSS"
                            label={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_IN_POLICE_CUSTODY_STATUSES_LABEL
                            }
                            multiple={true}
                            showSelectedGroup={false}
                            includeExpired={true}
                            width="100%"
                            onChange={handleFormChange}
                            path="propertyStatusAttrIds"
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_REASON_FOR_POLICE_CUSTODY}>
                        <RRFAttributeSelect
                            label={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_REASONS_FOR_POLICE_CUSTODY_LABEL
                            }
                            attributeType="REASON_FOR_POLICE_CUSTODY_OF_PROPERTY"
                            multiple={true}
                            includeExpired={true}
                            path="reasonForPoliceCustodyAttrIds"
                            width="100%"
                            onChange={handleFormChange}
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_IS_OVERDUE}>
                        <IsOverdueCheckBox
                            onChange={handleFormChange}
                            path="isOverdue"
                            label={fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_IS_OVERDUE_LABEL}
                            helpText={strings.isOverdueHelpText}
                            helpTextPlacement={helpTextPlacement}
                        />
                    </Row>
                </Column>
                <Column width={267} marginRight={50}>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_DATE_ACQUIRED}>
                        <RRFDateRangePicker
                            label={fieldDisplayNames.DISPLAY_CHAIN_OF_CUSTODY_ACQUIRED_DATE_UTC}
                            includeTime={true}
                            width="100%"
                            withinLastPeriodOptions={withinLastPeriodOptions}
                            toDatePeriodOptions={toDatePeriodOptions}
                            onChange={handleFormChange}
                            paths={{
                                withinLastPeriod: 'acquiredDateRangeQuery.withinLastPeriod',
                                toDatePeriod: 'acquiredDateRangeQuery.toDatePeriod',
                                startDateUtc: 'acquiredDateRangeQuery.startDateUtc',
                                endDateUtc: 'acquiredDateRangeQuery.endDateUtc',
                            }}
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_LATEST_UPDATE}>
                        <RRFDateRangePicker
                            label={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_LATEST_STATUS_UPDATE_DATE_LABEL
                            }
                            includeTime={true}
                            width="100%"
                            withinLastPeriodOptions={withinLastPeriodOptions}
                            toDatePeriodOptions={toDatePeriodOptions}
                            onChange={handleFormChange}
                            paths={{
                                withinLastPeriod: 'chainEventDateRangeQuery.withinLastPeriod',
                                toDatePeriod: 'chainEventDateRangeQuery.toDatePeriod',
                                startDateUtc: 'chainEventDateRangeQuery.startDateUtc',
                                endDateUtc: 'chainEventDateRangeQuery.endDateUtc',
                            }}
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_REN_CREATION_DATE}>
                        <RRFDateRangePicker
                            label={renCreationDateRangeLabel}
                            includeTime={true}
                            width="100%"
                            withinLastPeriodOptions={withinLastPeriodOptions}
                            toDatePeriodOptions={toDatePeriodOptions}
                            onChange={handleFormChange}
                            paths={{
                                withinLastPeriod: 'renCreationDateRangeQuery.withinLastPeriod',
                                toDatePeriod: 'renCreationDateRangeQuery.toDatePeriod',
                                startDateUtc: 'renCreationDateRangeQuery.startDateUtc',
                                endDateUtc: 'renCreationDateRangeQuery.endDateUtc',
                            }}
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_PROPERTY_TYPE}>
                        <RRFItemTypeAttributeSelect
                            label={fieldDisplayNames.DISPLAY_ONLY_PROPERTY_TYPE_LABEL}
                            path="itemTypeAttrIds"
                            onChange={handleFormChange}
                            width="100%"
                            rejectByCategoryFilter={
                                !vehiclesEnabled ? globalAttributes.itemType.vehicle : undefined
                            }
                        />
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_PROPERTY_CATEGORY}>
                        <RRFItemCategoryAttributeSelect
                            label={fieldDisplayNames.DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL}
                            path="itemCategoryAttrIds"
                            onChange={handleFormChange}
                            width="100%"
                            rejectByCategoryFilter={
                                !vehiclesEnabled ? globalAttributes.itemType.vehicle : undefined
                            }
                        />
                    </Row>
                    {Object.keys(agencyProfiles).length > 0 && (
                        <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_AGENCY}>
                            <RRFAgencyProfileSelect
                                label={fieldDisplayNames.LOCATION_ENTITY_LINK_AGENCY_ID}
                                path="agencyIds"
                                multiple
                                onChange={handleFormChange}
                                width="100%"
                            />
                        </Row>
                    )}
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_SERIAL_NUMBER}>
                        <RRFText
                            extraLabelContent={<WildcardIcon />}
                            label={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_SERIAL_NUMBER_LABEL
                            }
                            labelClassName=""
                            onChange={handleFormChange}
                            path="serialNumber"
                            width="100%"
                        />
                    </Row>
                </Column>
                <Column width={267} marginRight={0}>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_IDENTIFIER_TYPES}>
                        <RRFNItems
                            addText={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_IDENTIFIER_TYPE_LABEL
                            }
                            addItemOnDirty={false}
                            hideAddButtonOnEmptyItem={true}
                            path="identifierTypeAttrIds"
                        >
                            {(item, index, deleteButton, removeItem) => (
                                <>
                                    <Flex>
                                        <RRFAttributeSelect
                                            attributeType="ITEM_IDENTIFIER_TYPE"
                                            includeExpired={true}
                                            path="identifierTypeAttrId"
                                            onChange={(val) => {
                                                if (!val) {
                                                    formActions.change(
                                                        `identifierTypeAttrIds[${index}].identifier`,
                                                        undefined
                                                    );
                                                }
                                            }}
                                        />

                                        <DeleteButtonWrapper>
                                            <IconButton
                                                icon="TrashCan"
                                                onClick={removeItem}
                                                variant="ghost"
                                                disabled={
                                                    formModel.identifierTypeAttrIds.length === 1
                                                }
                                            />
                                        </DeleteButtonWrapper>
                                    </Flex>
                                    {formModel.identifierTypeAttrIds[index]
                                        .identifierTypeAttrId && (
                                        <Flex marginLeft={15}>
                                            <RRFText
                                                width={210}
                                                label={
                                                    fieldDisplayNames.DISPLAY_ONLY_ITEM_IDENTIFIER_LABEL
                                                }
                                                onChange={handleFormChange}
                                                path="identifier"
                                            />
                                        </Flex>
                                    )}
                                </>
                            )}
                        </RRFNItems>
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_ITEM_IDS}>
                        <RRFNItems
                            addText={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_ITEM_ID_LABEL
                            }
                            addItemOnDirty={false}
                            hideAddButtonOnEmptyItem={true}
                            path="itemIds"
                        >
                            {(item, index, deleteButton, removeItem) => (
                                <Flex>
                                    <RRFText
                                        width="100%"
                                        label={fieldDisplayNames.DISPLAY_EVIDENCE_LABEL_ITEM_ID}
                                        onChange={handleFormChange}
                                        path="itemId"
                                    />
                                    <DeleteButtonWrapper>
                                        <IconButton
                                            icon="TrashCan"
                                            onClick={removeItem}
                                            variant="ghost"
                                            disabled={formModel.itemIds.length === 1}
                                        />
                                    </DeleteButtonWrapper>
                                </Flex>
                            )}
                        </RRFNItems>
                    </Row>
                    <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_REN}>
                        <RRFNItems
                            addText={strings.addRen(
                                fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER
                            )}
                            addItemOnDirty={false}
                            hideAddButtonOnEmptyItem={true}
                            path="reportingEventNumbers"
                        >
                            {(item, index, deleteButton, removeItem) => (
                                <Flex>
                                    <RRFText
                                        width="100%"
                                        label={fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER}
                                        onChange={handleFormChange}
                                        path="reportingEventNumber"
                                    />
                                    <DeleteButtonWrapper>
                                        <IconButton
                                            icon="TrashCan"
                                            onClick={removeItem}
                                            variant="ghost"
                                            disabled={formModel.reportingEventNumbers.length === 1}
                                        />
                                    </DeleteButtonWrapper>
                                </Flex>
                            )}
                        </RRFNItems>
                    </Row>
                    <Row>
                        <RRFNItems
                            addText={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_PERSONNEL_LABEL
                            }
                            addItemOnDirty={false}
                            hideAddButtonOnEmptyItem={true}
                            path="personnelIds"
                        >
                            {(item, index, deleteButton, removeItem) => (
                                <>
                                    <Flex>
                                        <RRFUserSelect
                                            multiple={false}
                                            width="100%"
                                            label={
                                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_PERSONNEL_NAME_LABEL
                                            }
                                            onChange={(val) => {
                                                if (!val) {
                                                    formActions.change(
                                                        `personnelIds[${index}].involvementStatus`,
                                                        undefined
                                                    );
                                                }
                                                handleFormChange();
                                            }}
                                            path="personnelId"
                                            excludeUsersWithInactiveDutyStatus={false}
                                        />
                                        <DeleteButtonWrapper>
                                            <IconButton
                                                icon="TrashCan"
                                                onClick={removeItem}
                                                variant="ghost"
                                                disabled={formModel.personnelIds.length === 1}
                                            />
                                        </DeleteButtonWrapper>
                                    </Flex>
                                    {formModel.personnelIds[index].personnelId && (
                                        <Flex marginLeft={15} width={220}>
                                            <RRFPersonnelInvolvementStatusSelect
                                                label={
                                                    fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_INVOLVEMENT_STATUS_LABEL
                                                }
                                                path="involvementStatus"
                                                onChange={handleFormChange}
                                            />
                                        </Flex>
                                    )}
                                </>
                            )}
                        </RRFNItems>
                    </Row>
                </Column>
            </>
        );
    }

    return (
        <>
            <Column width={400} marginRight={80}>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_DATE_ACQUIRED}>
                    <RRFDateRangePicker
                        label={fieldDisplayNames.DISPLAY_CHAIN_OF_CUSTODY_ACQUIRED_DATE_UTC}
                        includeTime={true}
                        width="100%"
                        withinLastPeriodOptions={withinLastPeriodOptions}
                        toDatePeriodOptions={toDatePeriodOptions}
                        onChange={handleFormChange}
                        paths={{
                            withinLastPeriod: 'acquiredDateRangeQuery.withinLastPeriod',
                            toDatePeriod: 'acquiredDateRangeQuery.toDatePeriod',
                            startDateUtc: 'acquiredDateRangeQuery.startDateUtc',
                            endDateUtc: 'acquiredDateRangeQuery.endDateUtc',
                        }}
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_LATEST_UPDATE}>
                    <RRFDateRangePicker
                        label={
                            fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_LATEST_STATUS_UPDATE_DATE_LABEL
                        }
                        includeTime={true}
                        width="100%"
                        withinLastPeriodOptions={withinLastPeriodOptions}
                        toDatePeriodOptions={toDatePeriodOptions}
                        onChange={handleFormChange}
                        paths={{
                            withinLastPeriod: 'chainEventDateRangeQuery.withinLastPeriod',
                            toDatePeriod: 'chainEventDateRangeQuery.toDatePeriod',
                            startDateUtc: 'chainEventDateRangeQuery.startDateUtc',
                            endDateUtc: 'chainEventDateRangeQuery.endDateUtc',
                        }}
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_STORAGE_LOCATION}>
                    <FeatureFlagged
                        flag="STORAGE_LOCATION_SEARCH_IMPROVEMENTS_ENABLED"
                        fallback={
                            <DeprecatedRRFFacilityStorageLocationSelect
                                label={
                                    fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL
                                }
                                multiple={true}
                                onChange={handleFormChange}
                                paths={facilityStorageLocationSelectPaths}
                                width="100%"
                                useStorageLocationIdsInstead={true}
                                requiredPermissions={[
                                    OperationTypeEnum.READ.name,
                                    OperationTypeEnum.MANAGE.name,
                                ]}
                            />
                        }
                    >
                        <RRFFacilityStorageLocationSelect
                            label={
                                fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL
                            }
                            multiple={true}
                            onChange={handleFormChange}
                            paths={facilityStorageLocationSelectPaths}
                            width="100%"
                            maxMenuHeight={350}
                        />
                    </FeatureFlagged>
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_EXCLUDE_TEMPORARY_LOCATION}>
                    <RRFCheckbox
                        onChange={handleFormChange}
                        path="excludeTemporaryStorageLocations"
                        label={
                            fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_TEMPORARY_LOCATIONS_LABEL
                        }
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_PRIMARY_REPORTING_OFFICERS}>
                    <RRFUserSelect
                        multiple={true}
                        width="100%"
                        label={
                            fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_PRIMARY_REPORTING_OFFICERS_LABEL
                        }
                        onChange={handleFormChange}
                        path="responsibleOfficerIds"
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_RECOVERING_OFFICERS}>
                    <RRFUserSelect
                        multiple={true}
                        width="100%"
                        label={
                            fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_RECOVERING_OFFICERS_LABEL
                        }
                        onChange={handleFormChange}
                        path="recoveredByOfficerIds"
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_REN}>
                    <RRFNItems
                        addText={strings.addRen(fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER)}
                        addItemOnDirty={false}
                        hideAddButtonOnEmptyItem={true}
                        path="reportingEventNumbers"
                    >
                        {(item, index, deleteButton, removeItem) => (
                            <Flex>
                                <RRFText
                                    width="100%"
                                    label={fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER}
                                    onChange={handleFormChange}
                                    path="reportingEventNumber"
                                />
                                <DeleteButtonWrapper>
                                    <IconButton
                                        icon="TrashCan"
                                        onClick={removeItem}
                                        variant="ghost"
                                        disabled={formModel.reportingEventNumbers.length === 1}
                                    />
                                </DeleteButtonWrapper>
                            </Flex>
                        )}
                    </RRFNItems>
                </Row>
            </Column>
            <Column width={400} marginRight={0}>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_CHAIN_OF_CUSTODY_STATUS}>
                    <RRFChainEventTypeSelect
                        label={
                            fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL
                        }
                        width="100%"
                        includeExpired={true}
                        onChange={handleFormChange}
                        path="chainEventTypeIds"
                        multiple={true}
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_DISPOSITION_STATUS}>
                    <RRFDispositionStatusSelect
                        label={
                            fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_DISPOSITION_STATUSES_LABEL
                        }
                        width="100%"
                        onChange={handleFormChange}
                        path="dispositionStatuses"
                        multiple={true}
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_PROPERTY_TYPE}>
                    <RRFItemTypeAttributeSelect
                        label={fieldDisplayNames.DISPLAY_ONLY_PROPERTY_TYPE_LABEL}
                        path="itemTypeAttrIds"
                        onChange={handleFormChange}
                        width="100%"
                        rejectByCategoryFilter={
                            !vehiclesEnabled ? globalAttributes.itemType.vehicle : undefined
                        }
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_PROPERTY_CATEGORY}>
                    <RRFItemCategoryAttributeSelect
                        label={fieldDisplayNames.DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL}
                        path="itemCategoryAttrIds"
                        onChange={handleFormChange}
                        width="100%"
                        rejectByCategoryFilter={
                            !vehiclesEnabled ? globalAttributes.itemType.vehicle : undefined
                        }
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_IN_POLICE_CUSTODY_STATUS}>
                    <RRFAttributeSelect
                        attributeType="PROPERTY_LOSS"
                        label={
                            fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_IN_POLICE_CUSTODY_STATUSES_LABEL
                        }
                        multiple={true}
                        showSelectedGroup={false}
                        includeExpired={true}
                        width="100%"
                        onChange={handleFormChange}
                        path="propertyStatusAttrIds"
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_REASON_FOR_POLICE_CUSTODY}>
                    <RRFAttributeSelect
                        label={
                            fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_REASONS_FOR_POLICE_CUSTODY_LABEL
                        }
                        attributeType="REASON_FOR_POLICE_CUSTODY_OF_PROPERTY"
                        multiple={true}
                        includeExpired={true}
                        path="reasonForPoliceCustodyAttrIds"
                        width="100%"
                        onChange={handleFormChange}
                    />
                </Row>
                <Row testId={testIds.EVIDENCE_DASHBOARD_FILTER_IS_OVERDUE}>
                    <IsOverdueCheckBox
                        onChange={handleFormChange}
                        path="isOverdue"
                        label={fieldDisplayNames.DISPLAY_ONLY_EVIDENCE_SEARCH_IS_OVERDUE_LABEL}
                        helpText={strings.isOverdueHelpText}
                        helpTextPlacement={helpTextPlacement}
                    />
                </Row>
            </Column>
        </>
    );
});

export default flowRight(
    connect(
        createStructuredSelector({
            currentSavedSearch: evidenceDashboardSearch.selectors.currentSavedSearchSelector,
            formModel: evidenceDashboardSearchForm.selectors.formModelSelector,
        })
    )
)(({ currentSavedSearch, formModel }) => {
    const [dirty, setDirty] = useState(false);
    const handleFormChange = () => setDirty(true);

    return (
        <FormWrapper data-test-id={testIds.EVIDENCE_DASHBOARD_FILTER_FORM}>
            <ReactReduxForm {...evidenceDashboardSearch.form}>
                <Row>
                    <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={false} />
                </Row>
                <Body
                    handleFormChange={handleFormChange}
                    formModel={formModel}
                    form={evidenceDashboardSearch.form}
                />
            </ReactReduxForm>
            <Footer dirty={dirty} setDirty={setDirty} />
        </FormWrapper>
    );
});
