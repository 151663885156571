import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { chain, compact, filter, find, first, isUndefined, join, map, reduce } from 'lodash';
import {
    AttributeTypeEnum,
    AttributeTypeEnumType,
    EFileAttribute,
    EntityTypeEnum,
    LinkTypesEnum,
    NameEFileLink,
    OrganizationProfile,
    PersonProfile,
} from '@mark43/rms-api';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import { formatName } from '~/client-common/core/domain/mini-users/utils/miniUsersHelpers';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { organizationProfileViewModelByIdSelector } from '~/client-common/core/domain/organization-profiles/state/ui';
import { personProfileViewModelByIdSelector } from '~/client-common/core/domain/person-profiles/state/ui';
import { formatUserByIdSelector } from '../../../legacy-redux/selectors/userSelectors';
import { useDateTimeFormatter } from '../../core/current-user/hooks/dateTimeFormats';
import {
    EFileGettersT,
    EFileViewModelT,
    EFileReducerType,
    EFileInvolvedProfileGridRowT,
    ImportableInvolvedProfileViewModelT,
    SelectedNameEntityT,
} from '../types';

import { formatEFileInvolvedProfileAddresses } from '../helpers';

/**
 * This hook can only be used within the E-File Module and the eFileContext
 * Using this outside of the module will cause errors
 * @returns EFileGettersT
 */

const DELIMITED_STRING_CHARACTER = ', ';

const getEFileAttributeByAttributeType = (
    eFileAttributes: EFileAttribute[],
    attributeType: AttributeTypeEnumType
) => {
    return chain(eFileAttributes)
        .filter((attribute) => attribute.attributeType === attributeType)
        .value();
};

export function useEFileGetters(state: EFileReducerType): EFileGettersT {
    const organizationProfileViewModelById: ReturnType<
        typeof organizationProfileViewModelByIdSelector
    > = useSelector(organizationProfileViewModelByIdSelector);
    const personProfileViewModelById: ReturnType<typeof personProfileViewModelByIdSelector> =
        useSelector(personProfileViewModelByIdSelector);
    const dateTimeFormatter = useDateTimeFormatter();

    const getAttributeById = useSelector(getAttributeByIdSelector);

    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const formatUserById = useSelector(formatUserByIdSelector);

    const getEFileViewModel = useCallback((): EFileViewModelT | undefined => {
        const eFileView = state.eFile;

        if (!eFileView) {
            return undefined;
        }

        const {
            efile: { title = '', localId = '', ownerUserId, officerUserId, statusAttrId, id },
            efileAttributes,
            involvedRoles,
            canEdit,
            canManage,
            canView,
        } = eFileView;

        const classificationEFileAttributes = getEFileAttributeByAttributeType(
            efileAttributes,
            AttributeTypeEnum.E_FILE_CLASSIFICATION.name
        );

        const courtUnitEFileAttribute = getEFileAttributeByAttributeType(
            efileAttributes,
            AttributeTypeEnum.COURT_UNIT.name
        )[0];

        const classifications = chain(classificationEFileAttributes)
            .map((attribute) => {
                return formatAttributeById(attribute.attributeId);
            })
            .value()
            .join(DELIMITED_STRING_CHARACTER);

        const contacts = chain(involvedRoles)
            .map('user')
            .map((user) => formatName(user))
            .value()
            .join(DELIMITED_STRING_CHARACTER);

        return {
            id,
            operationName: title,
            localId,
            classifications,
            cpsUnit: formatAttributeById(courtUnitEFileAttribute?.attributeId),
            owner: formatUserById(ownerUserId),
            officer: formatUserById(officerUserId),
            contacts,
            status: formatAttributeById(statusAttrId),
            canEdit,
            canView,
            canManage,
        };
    }, [formatAttributeById, formatUserById, state.eFile]);

    const getEFile = useCallback(() => state.eFile, [state.eFile]);

    const getEFileProfiles = useCallback((): EFileInvolvedProfileGridRowT[] => {
        if (state.search.profiles) {
            const { items } = state.search.profiles;
            const result: EFileInvolvedProfileGridRowT[] = [];

            const profileViewModels = reduce(
                items,
                (result, item) => {
                    const {
                        isDeactivated = false,
                        person,
                        organizationProfile,
                        personProfile,
                    } = item;

                    if (person && personProfile) {
                        const { nameEFileLinks = [] } = personProfile;
                        const nameEFileLink = first(nameEFileLinks);

                        if (!nameEFileLink) {
                            return result;
                        }

                        const personProfileViewModel = personProfileViewModelById(personProfile.id);

                        if (personProfileViewModel) {
                            const { locations, personProfile: innerPersonProfile } = personProfile;
                            const { dateOfBirth } = innerPersonProfile;
                            const {
                                fullName,
                                phoneNumbers,
                                nameAttributes,
                                mostRecentMugshotImage,
                            } = getViewModelProperties(personProfileViewModel);

                            const locationData = formatEFileInvolvedProfileAddresses(locations);

                            result = [
                                ...result,
                                {
                                    eFileLinkId: nameEFileLink.id,
                                    nameData: {
                                        name: fullName,
                                        entityId: personProfile.id,
                                        entityType: EntityTypeEnum.PERSON_PROFILE.name,
                                        imageUrl:
                                            mostRecentMugshotImage?.thumbnailSmallFile
                                                ?.fileWebServerPath ?? '',
                                        nameAttributes: filter(nameAttributes, {
                                            attributeType:
                                                AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
                                        }),
                                    },
                                    identifiers: [
                                        {
                                            label: 'DOB',
                                            value: dateOfBirth ?? '',
                                        },
                                        ...map(phoneNumbers, (phoneNumber) => {
                                            return {
                                                label: phoneNumber.phoneType ?? '',
                                                value: phoneNumber.phoneNumber ?? '',
                                            };
                                        }),
                                    ],
                                    locationData,
                                    isDeactivated,
                                },
                            ];
                        }
                    } else if (!person && organizationProfile) {
                        const organizationProfileViewModel = organizationProfileViewModelById(
                            organizationProfile.id
                        );

                        if (organizationProfileViewModel) {
                            const { locations = [], nameEFileLinks = [] } = organizationProfile;

                            const nameEFileLink = first(nameEFileLinks);

                            if (!nameEFileLink) {
                                return result;
                            }

                            // @ts-expect-error client-common to client RND-7529
                            const { organizationTypeAttrId } = organizationProfileViewModel;
                            // @ts-expect-error client-common to client RND-7529
                            const { phoneNumbers, primaryProfilePhoto } = getViewModelProperties(
                                organizationProfileViewModel
                            );

                            const organizationTypeAttribute =
                                getAttributeById(organizationTypeAttrId);

                            const locationData = formatEFileInvolvedProfileAddresses(locations);

                            result = [
                                ...result,
                                {
                                    isDeactivated,
                                    eFileLinkId: nameEFileLink.id,
                                    nameData: {
                                        // @ts-expect-error client-common to client RND-7529
                                        name: organizationProfileViewModel.name,
                                        // @ts-expect-error client-common to client RND-7529
                                        entityId: organizationProfileViewModel.id,
                                        entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                                        imageUrl:
                                            primaryProfilePhoto?.thumbnailSmallFile
                                                ?.fileWebServerPath ?? '',
                                    },
                                    locationData,
                                    identifiers: compact([
                                        organizationTypeAttribute
                                            ? {
                                                  label: 'Type',
                                                  value: organizationTypeAttribute.val,
                                              }
                                            : undefined,
                                        ...map(phoneNumbers, (phoneNumber) => {
                                            return {
                                                label: phoneNumber.phoneType,
                                                value: phoneNumber.phoneNumber,
                                            };
                                        }),
                                    ]),
                                },
                            ];
                        }
                    }
                    return result;
                },
                result
            );

            return profileViewModels;
        }
        return [];
    }, [
        organizationProfileViewModelById,
        personProfileViewModelById,
        state.search.profiles,
        getAttributeById,
    ]);

    const getEFileDefendantPersonHeaderViewModel = useCallback(() => {
        if (!state.selectedEFileEntity.defendantPersonProfile) {
            return undefined;
        }

        const { id, nameEFileLinks } =
            state.selectedEFileEntity.defendantPersonProfile.hydratedPerson;

        const eFile = getEFile();
        if (isUndefined(eFile)) {
            return undefined;
        }

        const linkData = find(
            nameEFileLinks,
            (nameEFileLink: NameEFileLink) => nameEFileLink.efileId === eFile.efile.id
        );
        if (isUndefined(linkData)) {
            return undefined;
        }

        return {
            eFileId: linkData.efileId,
            entityType: EntityTypeEnum.PERSON_PROFILE.name,
            entityId: id,
            eFileLinkId: linkData.id,
            eFileLinkType: LinkTypesEnum.DEFENDANT_IN_EFILE,
            isDeactivated: linkData.isDeactivated,
            entityName: formatPersonTitle(
                state.selectedEFileEntity.defendantPersonProfile.hydratedPerson.personProfile
            ),
        };
    }, [getEFile, state.selectedEFileEntity.defendantPersonProfile]);

    const getEFileDefendantOrganizationHeaderViewModel = useCallback(() => {
        if (state.selectedEFileEntity.defendantOrganizationProfile) {
            const { id, nameEFileLinks, organizationProfile } =
                state.selectedEFileEntity.defendantOrganizationProfile.hydratedOrganization;

            const eFile = getEFile();
            if (isUndefined(eFile)) {
                return undefined;
            }

            const linkData = find(
                nameEFileLinks,
                (nameEFileLink: NameEFileLink) => nameEFileLink.efileId === eFile.efile.id
            );
            if (isUndefined(linkData)) {
                return undefined;
            }

            return {
                eFileId: linkData.efileId,
                entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                entityId: id,
                eFileLinkId: linkData.id,
                eFileLinkType: LinkTypesEnum.DEFENDANT_IN_EFILE,
                isDeactivated: linkData.isDeactivated,
                entityName: organizationProfile.name,
            };
        }
        return undefined;
    }, [getEFile, state.selectedEFileEntity.defendantOrganizationProfile]);

    const formatPersonTitle = (personProfile: PersonProfile) => {
        return join([personProfile.firstName, personProfile.lastName], ' ');
    };

    const formatPersonDescription = useCallback(
        (personProfile: PersonProfile) => {
            const descriptionParts = compact([
                personProfile.dateOfBirth
                    ? dateTimeFormatter.formatDate(personProfile.dateOfBirth)
                    : null,
                personProfile.sexAttrId ? formatAttributeById(personProfile.sexAttrId) : null,
                personProfile.raceAttrId ? formatAttributeById(personProfile.raceAttrId) : null,
            ]);
            return join(descriptionParts, ' | ');
        },
        [formatAttributeById, dateTimeFormatter]
    );

    const formatOrganizationTitle = (organizationProfile: OrganizationProfile) => {
        return organizationProfile.name || '';
    };

    const formatOrganizationDescription = useCallback(
        (organizationProfile: OrganizationProfile) => {
            return formatAttributeById(organizationProfile.organizationTypeAttrId) || '';
        },
        [formatAttributeById]
    );

    const getEFileSelectedInvolvedProfilesToImport = useCallback((): SelectedNameEntityT[] => {
        return state.sidePanel.selectedInvolvedProfilesForImport;
    }, [state.sidePanel.selectedInvolvedProfilesForImport]);

    const getEFileSelectedInvolvedProfiles = useCallback((): SelectedNameEntityT[] => {
        return state.grid.selectedInvolvedProfiles;
    }, [state.grid.selectedInvolvedProfiles]);

    const getEFileInvolvedProfilesToImport =
        useCallback((): ImportableInvolvedProfileViewModelT[] => {
            const involvedProfilesForImport: ImportableInvolvedProfileViewModelT[] = [];
            if (!state.sidePanel.involvedProfilesForImport) {
                return involvedProfilesForImport;
            }

            const matchingPersonProfiles = compact(
                map(
                    state.sidePanel.involvedProfilesForImport.matchingPersonProfiles,
                    (personProfile) => {
                        return personProfile.masterPersonId
                            ? {
                                  isOther: false,
                                  entityType: EntityTypeEnum.PERSON_PROFILE.name,
                                  viewModel: {
                                      id: personProfile.masterPersonId,
                                      title: formatPersonTitle(personProfile),
                                      description: formatPersonDescription(personProfile),
                                      imageUrl:
                                          personProfile.mugshotImage?.thumbnailSmallFile
                                              ?.fileWebServerPath ?? '',
                                  },
                              }
                            : null;
                    }
                )
            );

            const matchingOrgProfiles = compact(
                map(
                    state.sidePanel.involvedProfilesForImport.matchingOrgProfiles,
                    (organizationProfile) => {
                        return organizationProfile.masterOrganizationId
                            ? {
                                  isOther: false,
                                  entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                                  viewModel: {
                                      id: organizationProfile.masterOrganizationId,
                                      title: formatOrganizationTitle(organizationProfile),
                                      description:
                                          formatOrganizationDescription(organizationProfile),
                                      imageUrl:
                                          organizationProfile.primaryProfilePhoto
                                              ?.thumbnailSmallFile?.fileWebServerPath ?? '',
                                  },
                              }
                            : null;
                    }
                )
            );

            const otherPersonProfiles = compact(
                map(
                    state.sidePanel.involvedProfilesForImport.otherPersonProfiles,
                    (personProfile) => {
                        return personProfile.masterPersonId
                            ? {
                                  isOther: true,
                                  entityType: EntityTypeEnum.PERSON_PROFILE.name,
                                  viewModel: {
                                      id: personProfile.masterPersonId,
                                      title: formatPersonTitle(personProfile),
                                      description: formatPersonDescription(personProfile),
                                      imageUrl:
                                          personProfile.mugshotImage?.thumbnailSmallFile
                                              ?.fileWebServerPath ?? '',
                                  },
                              }
                            : null;
                    }
                )
            );

            const otherOrgProfiles = compact(
                map(
                    state.sidePanel.involvedProfilesForImport.otherOrgProfiles,
                    (organizationProfile) => {
                        return organizationProfile.masterOrganizationId
                            ? {
                                  isOther: true,
                                  entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                                  viewModel: {
                                      id: organizationProfile.masterOrganizationId,
                                      title: formatOrganizationTitle(organizationProfile),
                                      description:
                                          formatOrganizationDescription(organizationProfile),
                                      imageUrl:
                                          organizationProfile.primaryProfilePhoto
                                              ?.thumbnailSmallFile?.fileWebServerPath ?? '',
                                  },
                              }
                            : null;
                    }
                )
            );
            involvedProfilesForImport.push(
                ...matchingPersonProfiles,
                ...matchingOrgProfiles,
                ...otherPersonProfiles,
                ...otherOrgProfiles
            );

            return involvedProfilesForImport;
        }, [
            formatOrganizationDescription,
            formatPersonDescription,
            state.sidePanel.involvedProfilesForImport,
        ]);

    return {
        efile: {
            getEFileViewModel,
            getEFile,
        },
        search: {
            getEFileProfiles,
            searchResult: state.search.profiles,
            searchRequest: state.search.request,
        },
        selectedEFileEntity: {
            getEFileDefendantPersonHeaderViewModel,
            getEFileDefendantOrganizationHeaderViewModel,
        },
        sidePanel: {
            getEFileInvolvedProfilesToImport,
            getEFileSelectedInvolvedProfilesToImport,
        },
        grid: {
            getEFileSelectedInvolvedProfiles,
        },
    };
}
