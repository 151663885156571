import { ElasticUser } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'userSearchResults';

// hard to tell if this is ever used
const userSearchResultModule = createNormalizedModule<ElasticUser>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// ACTIONS
export const storeUserSearchResults = userSearchResultModule.actionCreators.storeEntities;

// SELECTORS
export const userSearchResultsSelector = userSearchResultModule.selectors.entitiesSelector;
export const userSearchResultsByIdSelector = userSearchResultModule.selectors.entityByIdSelector;

// REDUCER
export default userSearchResultModule.reducerConfig;
