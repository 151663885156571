import React from 'react';

import { FormattedDate } from '~/client-common/core/dates/components';
import { TableCellComponentProps } from '../tableTypes';
import { CaseTaskViewModel } from '../../../../../modules/cases/task-list/state/ui/types';
import { isColumnKeyPropObject } from '../tableHelpers';

type TaskDueDateCellProps = TableCellComponentProps<CaseTaskViewModel>;

function TaskDueDateCell(props: TaskDueDateCellProps): JSX.Element | null {
    if (isColumnKeyPropObject(props)) {
        return null;
    }
    const { dueDateUtc } = props;
    return <FormattedDate date={dueDateUtc} format={FormattedDate.FORMATS.FORM_DATE} />;
}

export default TaskDueDateCell;
