// TODO PRINTING-REFACTOR move all roles code in to this module
import { Role, UserRole, RoleTypeEnum, RoleTypeEnumType } from '@mark43/rms-api';
import _, { get, isArray } from 'lodash';
import { createSelector } from 'reselect';

import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import { groupRolesByRoleType } from '../../../../../helpers/rolesHelpers';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

import { userSearchResultsSelector } from '../../../user-search-results/state/data';
import {
    miniUsersByUserRoleIdSelector,
    formatMiniUserByIdSelector,
    allMiniUserFormatsByIdSelector,
} from '../../../mini-users/state/data';
import { allMiniUserFormats } from '../../../mini-users/utils/miniUsersHelpers';
import { isUserActive } from '../../../../../helpers/userHelpers';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '../../../consortium-link-view/state/ui';
import getRolesResource from '../../resources/rolesResource';
import { ClientCommonAction } from '../../../../../redux/types';

const { NORMAL, DEPARTMENT, USER, SUPPORT } = RoleTypeEnum;

export const NEXUS_STATE_PROP = 'roles';

const rolesModule = createNormalizedModule<Role>({
    type: NEXUS_STATE_PROP,
});

export const storeRoles = rolesModule.actionCreators.storeEntities;

// REDUCER
export default rolesModule.reducerConfig;

export const LOAD_DEPARTMENT_ROLES_START = 'roles/LOAD_DEPARTMENT_ROLES_START';
export const LOAD_DEPARTMENT_ROLES_SUCCESS = 'roles/LOAD_DEPARTMENT_ROLES_SUCCESS';
export const LOAD_DEPARTMENT_ROLES_FAILURE = 'roles/LOAD_DEPARTMENT_ROLES_FAILURE';

export const CREATE_ROLE_START = 'roles/CREATE_ROLE_START';
export const CREATE_ROLE_SUCCESS = 'roles/CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'roles/CREATE_ROLE_FAILURE';

const loadRoleDataForDepartmentRolesStart = () => ({
    type: LOAD_DEPARTMENT_ROLES_START,
});

const loadRoleDataForDepartmentRolesSuccess = (departmentRoles: Role[]) => ({
    type: LOAD_DEPARTMENT_ROLES_SUCCESS,
    departmentRoles,
});

const loadRoleDataForDepartmentRolesFailure = (message: string) => ({
    type: LOAD_DEPARTMENT_ROLES_FAILURE,
    message,
});

const createRoleStart = () => ({
    type: CREATE_ROLE_START,
});

const createRoleSuccess = (role: Role) => ({
    type: CREATE_ROLE_SUCCESS,
    payload: role,
});

const createRoleFailure = (message: string) => ({
    type: CREATE_ROLE_FAILURE,
    payload: message,
});

export function loadRoleDataForDepartmentRoles(): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        dispatch(loadRoleDataForDepartmentRolesStart());
        const resource = getRolesResource();
        return resource
            .getRolesForDepartment(true)
            .then((departmentRoles: Role[]) => {
                dispatch(storeRoles(departmentRoles));
                dispatch(loadRoleDataForDepartmentRolesSuccess(departmentRoles));
            })
            .catch((err: Error) => {
                dispatch(loadRoleDataForDepartmentRolesFailure(err.message));
                throw err;
            });
    };
}

export function createRole(role: Role): ClientCommonAction<Promise<Role>> {
    return (dispatch) => {
        dispatch(createRoleStart());
        const rolesResource = getRolesResource();
        return rolesResource
            .createRole(role)
            .then((role: Role) => {
                dispatch(storeRoles([role]));
                dispatch(createRoleSuccess(role));
                return role;
            })
            .catch((err: Error) => {
                dispatch(createRoleFailure(err.message));
                throw err;
            });
    };
}

/**
 * Initially, the roles that come down from the server with the current user's
 *   profile, which contains mostly group roles. Most user roles are missing and
 *   may to be searched for when needed. This object is keyed by role id.
 */
export const rolesSelector = rolesModule.selectors.entitiesSelector;

export const rolesWhereSelector = rolesModule.selectors.entitiesWhereSelector;

export const roleByIdSelector = createSelector(rolesSelector, (roles) => (roleId: number) =>
    roles[roleId]
);

export const rolesByRoleTypeSelector = createSelector(rolesSelector, groupRolesByRoleType);

/**
 * `UserRole`s, keyed by user id.
 */
// TODO PRINTING-REFACTOR this shouldn't happen
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const allUserRolesSelector = (state: any) => state.data.userRolesTODO as Record<number, UserRole[]>;

export const rolesForUserSelector = createSelector(
    allUserRolesSelector,
    (allUserRoles) => (userId: number) => allUserRoles[userId]
);

/**
 * The current user's user role
 * DO NOT USE
 */
export const currentUserUserRoleIdSelector = createSelector(
    // TODO PRINTING-REFACTOR de-dupe this, will have to figure out user / current-user stuff
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.data.user,
    (userData) => get(userData, 'profile.userRoleId')
);

/**
 * Format a role for display. For a user role, this is the user's full name
 *   rather than the role name (email address) as its display.
 */
export const formatRoleNameSelector = createSelector(
    formatMiniUserByIdSelector,
    departmentNameFromConsortiumLinksByDepartmentIdSelector,
    (formatMiniUserById, departmentNameFromConsortiumLinksByDepartmentId) => (
        {
            name,
            userId,
            departmentId,
        }: { name?: string; userId?: number; departmentId?: number } = {},
        prefixWithDepartmentName?: boolean
    ) => {
        if (prefixWithDepartmentName) {
            const departmentName = departmentNameFromConsortiumLinksByDepartmentId(departmentId);
            return `${departmentName} - ${name}`;
        }

        return userId ? formatMiniUserById(userId) : name;
    }
);

type AllRoleFormatsUndefined = {
    roleType: undefined;
    default: undefined;
};

type AllRoleFormatsAnyRoleType = {
    roleType: RoleTypeEnumType;
    default: string;
};

type AllRoleFormatsUser = {
    roleType: typeof RoleTypeEnum.USER.name;
    default: string;
} & ReturnType<typeof allMiniUserFormats>;

export type AllRoleFormats =
    | AllRoleFormatsUndefined
    | AllRoleFormatsAnyRoleType
    | AllRoleFormatsUser;

export function isUserRoleFormat(roleFormat: AllRoleFormats): roleFormat is AllRoleFormatsUser {
    return roleFormat.roleType === RoleTypeEnum.USER.name;
}

/**
 * Return an object which contains all possible known formats for displaying a role.
 *   Properties that exist on the returned object depend on which type of role
 *   is being returned. Every role type will contain a 'default' property for
 *   convenience, and furthermore the role type itself will be included in case
 *   any consumer wishes to use it to drive logic.
 */
export const allRoleFormatsByRoleIdSelector = createSelector(
    roleByIdSelector,
    miniUsersByUserRoleIdSelector,
    allMiniUserFormatsByIdSelector,
    (roleById, miniUsersByUserRoleId, allMiniUserFormatsById) => (
        roleId: number
    ): AllRoleFormats => {
        if (!roleId) {
            // placeholder object to prevent unsafe access problems in the ui
            // layer downstream
            // TODO: fix this
            return {
                roleType: undefined,
                default: undefined,
            };
        }
        // the role exists in state, and it is not a user role
        const role = roleById(roleId);
        if (role && !role.userId) {
            const { name, roleType } = role;
            return {
                roleType,
                default: name,
            };
        } else {
            // the role may or may not exist in state, but it is a user role,
            // so we reference the mini user directly to format it
            const id = get(miniUsersByUserRoleId[roleId], 'id');
            const miniUserFormats = allMiniUserFormatsById(id);
            return {
                roleType: USER.name,
                default: miniUserFormats.fullName,
                ...miniUserFormats,
            };
        }
    }
);

export const formatRoleNameByRoleIdSelector = createSelector(
    roleByIdSelector,
    formatRoleNameSelector,
    (roleById, formatRoleName) => (ids: number[]) =>
        !isArray(ids)
            ? formatRoleName(roleById(ids))
            : joinTruthyValues(
                  _(ids)
                      .map((id) => formatRoleName(roleById(id)))
                      .sortBy()
                      .value()
              )
);

export const rolesCheckboxOptionsForUserSelector = createSelector(
    rolesForUserSelector,
    formatRoleNameSelector,
    roleByIdSelector,
    (rolesForUser, formatRoleName, roleById) => (userId: number) =>
        _(rolesForUser(userId))
            .filter(
                (role) =>
                    roleById(role.roleId) && get(roleById(role.roleId), 'roleType') !== USER.name
            )
            .map((role) => ({
                display: formatRoleName(roleById(role.roleId)),
                value: role.roleId,
            }))
            .sortBy('display')
            .value()
);

export const rolesManagedByUserSelector = createSelector(
    rolesForUserSelector,
    (rolesForUser) => (userId: number) => {
        return _(rolesForUser(userId))
            .filter((role) => role.isUserAdmin)
            .value();
    }
);

/**
 * `Select` dropdown options for roles. Includes roles that are permanently
 *   stored in data state as well as temporary search results for user roles.
 * @type {Object[]}
 */
export const roleOptionsByTypesSelector = createSelector(
    rolesSelector,
    userSearchResultsSelector,
    formatRoleNameSelector,
    formatMiniUserByIdSelector,
    (roles, userSearchResults, formatRoleName, formatMiniUserById) => (
        types = [NORMAL.name, DEPARTMENT.name, USER.name, SUPPORT.name],
        predicate: Partial<Role> | ((role: Role) => boolean),
        prefixWithDepartmentName = false
    ) =>
        _(roles) // grab all existing roles from data state
            // include only certain role types
            .filter(({ roleType }) => _.includes(types, roleType))
            // This filters out all the users from the rolesSelector
            // so that it only uses the users from the search results
            .filter(({ roleType }) => roleType !== USER.name)
            .filter(predicate || _.identity)
            .map((role) => ({
                value: role.id,
                display: formatRoleName(role, prefixWithDepartmentName),
            }))
            // add options for current search results for users
            // status is a user's isDisabled key ie. is the user active or not
            // EXPIRED and ACTIVE are used since the ASYNC SELECT sorts by EXPIRED by default
            .concat(
                _.includes(types, USER.name)
                    ? _.map(userSearchResults, (user) => ({
                          value: user.userRoleId,
                          status: user.isDisabled ? 'EXPIRED' : 'ACTIVE',
                          noteDisplay: isUserActive(user) ? '' : '(inactive)',
                          display: formatMiniUserById(user.id),
                      }))
                    : []
            )
            .uniqBy('value')
            .sortBy(['status', 'display']) // sort by active users than alphabetical
            .value()
);
