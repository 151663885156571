import { EntityTypeEnum, InputTypeEnum } from '@mark43/rms-api';
import { SelectInputType } from 'dragon-react';

export function getDragonReportCoreSelectEntityType(inputType: SelectInputType) {
    switch (inputType) {
        case InputTypeEnum.FIREARM_SELECT.name:
            return 'FIREARM';
        case InputTypeEnum.ITEM_PROFILE_SELECT.name:
            return EntityTypeEnum.ITEM_PROFILE.name;
        case InputTypeEnum.COURT_SELECT.name:
            return EntityTypeEnum.COURT.name;
        case InputTypeEnum.VEHICLE_SELECT.name:
            return EntityTypeEnum.VEHICLE.name;
        default:
            throw new Error('Unsupported Select Input Type Specified');
    }
}
