import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { BodyMediumText } from '../../../core/components/typography';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import Pill from '../../../core/components/Pill';
import { NotepadLinksSidePanel } from './NotepadLinksSidePanel';

const strings = componentStrings.notepad.pill;

const BodyMediumLinkText = styled(BodyMediumText)`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const NotepadPill = connect(
    createStructuredSelector({
        applicationSettings: applicationSettingsSelector,
        currentUserHasAbility: currentUserHasAbilitySelector,
    })
)(function NotepadCollectionsPillUnconnected({
    applicationSettings,
    currentUserHasAbility,
    entityType,
    onEntityClick,
    className,
    id,
    mode,
}) {
    const showNotepadCollections = !!(
        applicationSettings.RMS_MOBILE_ENABLED &&
        currentUserHasAbility(abilitiesEnum.REPORTING.FIELD_NOTES)
    );
    return (
        showNotepadCollections && (
            <NotepadLinksSidePanel
                id={id}
                entityType={entityType}
                onEntityClick={onEntityClick}
                renderButton={({ overlayBase: { open }, setCloseFocusRefs }) =>
                    mode === 'render-as-link' ? (
                        <BodyMediumLinkText onClick={open} color="brand" ref={setCloseFocusRefs}>
                            {strings.label(entityType)}
                        </BodyMediumLinkText>
                    ) : (
                        <Pill
                            onClick={open}
                            hoverable={true}
                            showChevron={true}
                            className={className}
                            ref={setCloseFocusRefs}
                        >
                            <BodyMediumText fontWeight="semibold">
                                {strings.label(entityType)}
                            </BodyMediumText>
                        </Pill>
                    )
                }
            />
        )
    );
});
