import React from 'react';
import { useSelector } from 'react-redux';
import { map, reject } from 'lodash';
import { AttributeTypeEnum } from '@mark43/rms-api';

import { prettify } from '~/client-common/helpers/stringHelpers';
import { vehiclesEnabledSelector } from '~/client-common/core/domain/evidence-department-config/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import {
    AttributeSelectFilterT,
    AttributeSelectFilter,
    AttributeSelectFilterOption,
} from '../../../core/filters/components';

export const PropertyTypeFilter: React.FC<Omit<AttributeSelectFilterT, 'attributeType'>> = ({
    includeExpired,
    ...props
}) => {
    const vehiclesEnabled = useSelector(vehiclesEnabledSelector);

    const getOptions = (options: AttributeSelectFilterOption[]) => {
        const prettifyOption = (option: AttributeSelectFilterOption) => ({
            ...option,
            label: `${prettify(option.label)}`,
        });
        const prettifiedOptions = map(options, prettifyOption);

        if (!vehiclesEnabled) {
            const filteredOptions = reject(prettifiedOptions, {
                value: `${globalAttributes.itemType.vehicle}`,
            });
            return filteredOptions;
        } else {
            return prettifiedOptions;
        }
    };

    return (
        <>
            <AttributeSelectFilter
                {...props}
                includeExpired={includeExpired}
                attributeType={AttributeTypeEnum.ITEM_TYPE.name}
                filterOptions={(options) => getOptions(options)}
            />
        </>
    );
};
