import React, { useCallback } from 'react';
import { useToast } from 'arc';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { InlineFormReferenceValuesView } from '@mark43/rms-api';
import { FormReference } from 'dragon-react';
import { cssVar } from '@arc/core';
import { Button } from '@arc/button';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import {
    RMSDragonConfigurationExtensions,
    DragonInlineFormReferenceState,
} from '../../../rms-types';
import { useDragonCurrentFormNestingPathContext } from '../../../context/dragon-current-form';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import dragonFormsResource from '../../../resource/dragonFormsResource';
import { getConfiguredFormPath } from '../../../utils/get-configured-form-path';
import { dragonFormValueCreateAction } from '../../../dragonRedux';
import { logError } from '../../../../../core/logging';
import errorToMessage from '../../../../core/errors/utils/errorToMessage';

const Wrapper = styled.div`
    margin-top: ${cssVar('arc.space.2')};
`;

export const CreateFormReferenceButton = ({
    label,
    configuration,
    uiConfigurationPath,
}: {
    uiConfigurationPath: string;
    label: string;
    configuration: FormReference<RMSDragonConfigurationExtensions>;
}): JSX.Element | null => {
    const currentReport = useSelector(currentReportSelector);
    const toast = useToast();
    const currentFormNestingPath = useDragonCurrentFormNestingPathContext();
    const dispatch = useDispatch();
    const configuredFormId = configuration.formConfigurationId;

    const createFormReference = useCallback(async () => {
        if (!currentReport?.id) {
            return;
        }

        return await dragonFormsResource.createFormReference({
            parentFormPathInstance: getConfiguredFormPath(currentFormNestingPath, {
                onlyParentPath: true,
            }),
            parentReferencingUiConfigurationId: configuration.id,
            configuredFormId,
            hideLoadingBar: false,
        });
    }, [currentReport?.id, configuration.id, configuredFormId, currentFormNestingPath]);

    const onCreateFormReferenceSuccess = useCallback(
        (inlineFormValuesView?: InlineFormReferenceValuesView) => {
            if (!inlineFormValuesView || !currentReport) {
                return;
            }
            const reportId = currentReport.id;
            if (reportId) {
                dispatch(
                    dragonFormValueCreateAction({
                        formValues: inlineFormValuesView.values as DragonInlineFormReferenceState,
                        uiConfigurationPath,
                        reportId,
                        configuredFormId,
                    })
                );
                toast({
                    status: 'default',
                    description: `Successfully created ${label} card.`,
                });
            }
        },
        [dispatch, toast, configuredFormId, uiConfigurationPath, label, currentReport]
    );

    const onCreateFormReferenceError = useCallback(
        (error?: Error) => {
            const message = errorToMessage(error, `Failed to create ${label} card.`);
            logError(message);
            toast({
                status: 'error',
                description: message,
            });
        },
        [toast, label]
    );

    const {
        callResource: onCreateFormReferenceButtonClick,
        loading: { isLoading },
    } = useResourceDeferred(
        createFormReference,
        onCreateFormReferenceSuccess,
        onCreateFormReferenceError
    );

    return (
        <Wrapper>
            <Button
                isFullWidth={true}
                leadingVisual="Add"
                variant="outline"
                size="xl"
                onClick={onCreateFormReferenceButtonClick}
                isLoading={isLoading}
            >
                {`Add ${label}`}
            </Button>
        </Wrapper>
    );
};
