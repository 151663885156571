import { Mark43File } from '@mark43/rms-api';

type BaseAssetProperties = {
    id: number;
    name: string;
    diagramCategoryId: number;
};

export type M43Asset = BaseAssetProperties & {
    departmentId: number;
    isDefault: boolean;
    file: Pick<Mark43File, 'id' | 'fileWebServerPath' | 'fileAppServerPath'>;
};

export type ExternalAsset = BaseAssetProperties & {
    base64String: string;
};

export type Asset = M43Asset | ExternalAsset;

export const isExternalAsset = (asset: Asset): asset is ExternalAsset => {
    return 'base64String' in asset;
};

export const isM43Asset = (asset: Asset): asset is M43Asset => {
    return 'file' in asset;
};
