import { DataItem } from 'arc';
import React, { FC } from 'react';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { Field } from '~/client-common/core/fields/state/config';
import { isValueDisplayable } from '~/client-common/helpers/logicHelpers';

export const EFileSummaryRow: FC<{ fieldName: Field }> = ({ fieldName, children }) => {
    const displayName = useFields(fieldName);

    return isValueDisplayable(children) ? (
        <DataItem dataKey={displayName[fieldName]}>{children}</DataItem>
    ) : null;
};
