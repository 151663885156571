import { CaseNote, EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';

import { cssVar } from 'arc';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import { attachmentViewModelsByEntitySelector } from '~/client-common/core/domain/attachments/state/ui';
import { caseNoteLocationByNoteIdSelector } from '../../../../../modules/cases/core/state/ui';
import { useSubPremiseFormatters } from '../../../../../modules/core/locations/hooks/useSubPremiseFormatters';
import Icon, { iconTypes, iconSizes } from '../../../../../modules/core/components/Icon';
import { FileDownloadLink } from '../../Files';
import { getFilePath } from '../../../../helpers/fileHelper';
import { BodyMediumText } from '../../../../../modules/core/components/typography';
import { TableCellComponentProps } from '../tableTypes';
import { ThemeColorsT } from '../../../../../modules/core/utils/getThemeColor';
import { isColumnKeyPropObject } from '../tableHelpers';

const Content = styled.div`
    margin-bottom: 20px;
    overflow: auto;

    table {
        margin: 10px 0;
    }

    // Support for Cell Spacing. Check TinyEditor.tsx getBaseContentStyles for details
    table[style*='border-spacing']:not([style*='border-spacing: 0px']) {
        border-collapse: separate !important;
        border-width: 0 !important;
    }

    td,
    th {
        border: 1px solid #ccc;
        vertical-align: middle;
        padding: 0.4rem;
    }
`;

const Location = styled.div`
    display: flex;
    margin-bottom: 15px;
`;

const LocationInfo = styled(BodyMediumText)`
    flex: 1;
    padding-left: 10px;
    color: ${cssVar('arc.colors.text.tertiary')};
`;

const StyledFileDownloadLink = styled(FileDownloadLink)`
    a {
        width: calc(100% - 24px);
    }
`;

type NoteContentCellProps = TableCellComponentProps<CaseNote>;

function NoteContentCell(props: NoteContentCellProps): JSX.Element | null {
    const theme = useTheme();
    const caseNoteLocationByNoteId = useSelector(caseNoteLocationByNoteIdSelector);
    const attachmentViewModelsByEntity = useSelector(attachmentViewModelsByEntitySelector);
    const { locationNameFormatsForLocation } = useSubPremiseFormatters();

    if (isColumnKeyPropObject(props)) {
        return null;
    }
    const { id, content } = props;

    const location = caseNoteLocationByNoteId(id);
    const attachments = attachmentViewModelsByEntity(EntityTypeEnum.CASE_NOTE.name, id);

    return (
        <>
            {content && <Content dangerouslySetInnerHTML={{ __html: content }} />}
            {location && (
                <Location>
                    <Icon
                        color={theme.colors.mediumGrey as ThemeColorsT}
                        type={iconTypes.LOCATION}
                        size={iconSizes.MEDIUM}
                    />
                    <LocationInfo>{locationNameFormatsForLocation({ location }).full}</LocationInfo>
                </Location>
            )}
            {attachments.map((attachment) => {
                const attachmentViewModel = getViewModelProperties(attachment);
                const file = attachmentViewModel.originalFile;
                return (
                    <StyledFileDownloadLink
                        displayName={file.originalFileName}
                        path={getFilePath(file)}
                        key={file.id}
                    />
                );
            })}
        </>
    );
}

export default NoteContentCell;
