import { useCallback, useImperativeHandle, useRef } from 'react';
import type {
    MapPropsT,
    MapRefShapeT,
    FeatureLayerT,
    MapViewT,
    EsriScreenshotFormats,
} from '../types';
import { handleMapViewGotoCatch } from '../helpers/mapHelpers';
import { logError, logWarning } from '../../../../core/logging';
import errorToMessage from '../../../../modules/core/errors/utils/errorToMessage';
import { createSelectedLocationGraphic } from './useStaticSelectedLocationFeatureLayer';

/**
 * Provide an imperative API for handling various map states
 */
export const useMapApi = (
    mapRef: MapPropsT['mapRef'],
    props: {
        selectedLocationLayer?: FeatureLayerT;
        nearestLocationLayer?: FeatureLayerT;
        mapView?: MapViewT;
    }
) => {
    const mapViewRef = useRef<MapViewT | undefined>(props.mapView);
    mapViewRef.current = props.mapView;

    const clearGraphicsInLayer = useCallback((layer?: FeatureLayerT) => {
        if (layer) {
            return layer.queryFeatures().then((featureSet) => {
                const graphicsToRemove = featureSet.features;
                layer?.applyEdits({
                    deleteFeatures: graphicsToRemove,
                });
            });
        }
        return Promise.resolve();
    }, []);

    const replaceGraphicsInLayer = useCallback(
        ({
            layer,
            params,
        }: {
            layer?: FeatureLayerT;
            params: Parameters<MapRefShapeT['setSelectedLocation']>[0];
        }) => {
            const selectedLocationGraphic = createSelectedLocationGraphic({
                latitude: params.lat,
                longitude: params.lng,
            });
            layer?.queryFeatures().then((featureSet) => {
                const graphicsToRemove = featureSet.features;
                layer?.applyEdits({
                    deleteFeatures: graphicsToRemove,
                    addFeatures: selectedLocationGraphic ? [selectedLocationGraphic] : [],
                });
            });
        },
        []
    );

    useImperativeHandle(
        mapRef,
        () => {
            return {
                clearNearestLocations: () => {
                    clearGraphicsInLayer(props.nearestLocationLayer);
                },
                clearSelectedLocations: () => {
                    clearGraphicsInLayer(props.selectedLocationLayer);
                },
                setNearestLocation: (params) => {
                    replaceGraphicsInLayer({ layer: props.nearestLocationLayer, params });
                },
                setSelectedLocation: (params) => {
                    replaceGraphicsInLayer({ layer: props.selectedLocationLayer, params });
                },
                ready: () => {
                    // We need this method because `mapViewRef` is asynchronously
                    // set, and so we need a way to make sure the ref is available
                    // before we start acting on it
                    return new Promise((resolve, reject) => {
                        let count = 0;
                        const maxWaitTime = 10000;
                        const interval = 200;
                        const maxIterations = maxWaitTime / interval;

                        const waitForMap = () => {
                            count++;
                            // Check if props.mapView is set
                            if (mapViewRef.current) {
                                mapViewRef.current.when().then(() => {
                                    resolve();
                                });
                            } else {
                                // If we waited 10 seconds and still have no map,
                                // then we are in bad shape
                                if (count > maxIterations) {
                                    reject('There was an error loading the map');
                                }
                                setTimeout(() => {
                                    waitForMap();
                                }, interval);
                            }
                        };

                        waitForMap();
                    });
                },
                updateMap: (params) => {
                    props.mapView
                        ?.goTo(
                            {
                                target: params.target
                                    ? [params.target.lng, params.target.lat]
                                    : undefined,
                                zoom: params.zoom || props.mapView.zoom,
                            },
                            { animate: !!params.animate }
                        )
                        .catch(handleMapViewGotoCatch);
                },
                onTakeScreenshot: async (screenshotFormat: EsriScreenshotFormats) => {
                    if (props.mapView) {
                        try {
                            const screenshot = await props.mapView.takeScreenshot({
                                format: screenshotFormat,
                            });
                            return screenshot.dataUrl;
                        } catch (error) {
                            logError(errorToMessage(error), { error });
                            return undefined;
                        }
                    } else {
                        logWarning('Failed to initialize the esri mapView object');
                        return undefined;
                    }
                },
            };
        },
        [
            clearGraphicsInLayer,
            props.nearestLocationLayer,
            props.selectedLocationLayer,
            props.mapView,
            replaceGraphicsInLayer,
        ]
    );
};
