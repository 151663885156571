import { first, flatMap, get, intersection, map, reduce, size, uniq } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    AttributeTypeEnum,
    EntityTypeEnum,
    ItemInvolvementTypeEnum,
    LinkTypesEnum,
    RefContextEnum,
    RegionalGroupEnum,
    NibrsOffenseCodeEnum,
    ComplianceGroupEnum,
} from '@mark43/rms-api';
import { _Form, Fieldset, Form, formEvents, lifecycleOptions, Observer } from 'markformythree';
import React from 'react';
import styled from 'styled-components';
import Promise from 'bluebird';
import * as fields from '~/client-common/core/enums/universal/fields';
import {
    buildOffenseCardTitle,
    isClientSideOffenseStub,
} from '~/client-common/core/domain/offenses/utils/offensesHelpers';
import {
    isPropertyStatusAllowedOnOffense,
    recoveredImpliesStolen,
    validateNibrsLinkedItems,
} from '~/client-common/core/domain/nibrs-offense-codes/utils/nibrsPropertyHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatNameReportLinkTypeIdSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import { nibrsOffenseCodesSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { nameAttributesWhereSelector } from '~/client-common/core/domain/name-attributes/state/data';
import nibrsCrimesAgainst from '~/client-common/core/constants/nibrsCrimeAgainst';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { propertyLossCodes } from '~/client-common/core/constants/nibrsCodes';
import { duplicateOffense } from '~/client-common/core/domain/offenses/state/data';
import { isValueDisplayable } from '~/client-common/helpers/logicHelpers';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import { isWarrantModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategorySelector } from '~/client-common/core/domain/attribute-codes/state/ui';
import { ukOffenseCodeExtensionByOffenseCodeIdSelector } from '~/client-common/core/domain/uk-offense-code-extensions/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { fieldDetailsSelector } from '~/client-common/core/domain/field-details/state/data';
import { fieldConfigurationsSelector } from '~/client-common/core/domain/field-configurations/state/data';
import { fieldConfigurationContextsSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { coreModelConfigurationsSelector } from '~/client-common/core/domain/core-model-configurations/state/data';
import testIds from '../../../../../core/testIds';
import {
    currentUserDepartmentIdSelector,
    currentUserDepartmentProfileSelector,
} from '../../../../core/current-user/state/ui';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import Link from '../../../../core/components/links/Link';
import WithComplianceGroup from '../../../../core/components/WithComplianceGroup';
import withCard from '../../utils/withCard';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import { createNItemsAdder, createNItemsRemover } from '../../utils/nItemsHelpers';
import {
    itemSummaryViewModelsForCurrentReportSelector,
    offenseLinkedArrestSelector,
    offenseLinkedWarrantSelector,
    openOffenseIncidentDeletionModal,
    refreshOffenseForm,
    registerForm,
} from '../../state/ui';
import {
    offenseFormConfiguration,
    runOffenseCardConditionalRules,
} from '../../state/forms/offenseForm';
import formsRegistry from '../../../../../core/formsRegistry';
import offenseCards from '../../state/ui/offenseCards';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import Row from '../../../../core/components/Row';
import { OffenseIncidentTitle } from '../OffenseIncidentTitle';
import LegacyEntityDetails from '../../../../records/core/components/LegacyEntityDetails';
import ItemSectionsInOffense from '../items/ItemSectionsInOffense';
import { registerCard } from '../../utils/cardsRegistry';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { fieldsetAdder, fieldsetRemover } from '../../../../core/forms/helpers/fieldsetHelpers';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import IndentedFields from '../../../../core/components/IndentedFields';
import { useGetValidOffenseCodeByNameAndDate } from '../../hooks/useGetValidOffenseCodeByNameAndDate';
import OffenseCardForm from './OffenseCardForm';
import OffenseCardSummary from './OffenseCardSummary';
import { OffenseVictimAdditionalFields } from './OffenseVictimAdditionalFields';
import { OffenseSuspectAdditionalFields } from './OffenseSuspectAdditionalFields';
import { OffenseOffenderAdditionalFields } from './OffenseOffenderAdditionalFields';
import { OffenseWitnessAdditionalFields } from './OffenseWitnessAdditionalFields';
import { OffenseOtherNameAdditionalFields } from './OffenseOtherNameAdditionalFields';
import { OffenseLocationSummary } from './OffenseLocationSummary';

const strings = componentStrings.reports.core.OffenseCard;

const DUPLICATE_OFFENSE_ERROR_MESSAGE_DISPLAY_TIME_SECONDS = 10;

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    formatAttributeById: formatAttributeByIdSelector,
    applicationSettings: applicationSettingsSelector,
    departmentProfile: currentUserDepartmentProfileSelector,
    nibrsOffenseCodes: nibrsOffenseCodesSelector,
    nameAttributesWhere: nameAttributesWhereSelector,
    formatNameReportLinkTypeId: formatNameReportLinkTypeIdSelector,
    offenseLinkedArrest: offenseLinkedArrestSelector,
    offenseLinkedWarrant: offenseLinkedWarrantSelector,
    successMessages: offenseCards.selectors.successMessagesSelector,
    formatOffenseCodeById: formatOffenseCodeByIdSelector,
    offenseCodes: offenseCodesSelector,
    itemSummaryViewModelsForCurrentReport: itemSummaryViewModelsForCurrentReportSelector,
    federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory: federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategorySelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    isWarrantModuleActive: isWarrantModuleActiveSelector,
    ukOffenseCodeExtensionByOffenseCodeId: ukOffenseCodeExtensionByOffenseCodeIdSelector,
    fieldDetails: fieldDetailsSelector,
    fieldConfigurations: fieldConfigurationsSelector,
    fieldConfigurationContexts: fieldConfigurationContextsSelector,
    coreModelConfigurations: coreModelConfigurationsSelector,
});

const MessagePill = styled.div`
    margin: 10px 28px 10px 28px;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-sm);
    padding: 12px;
    margin-top: 15px;
    border-radius: 4px;
    display: block;
`;

const ErrorMessagePill = styled(MessagePill)`
    background-color: var(--arc-colors-negative-default);
    border: 1px solid var(--arc-colors-negative-default);
`;

const SuccessMessagePill = styled(MessagePill)`
    background-color: ${(props) => props.theme.colors.lightBlue};
    border: 1px solid ${(props) => props.theme.colors.mediumBlue};
`;

/**
 * Validation handler will run the default RMS client arbiter MFT handler. And append additional
 *   panel error messages based on nibrs validation.
 *   Declared inline with component to avoid dependecy loop form->ui->form.
 * @param arbiter
 * @param offenseId
 * @param formatFieldByName
 */
function createOffenseFormValidationHandler(
    arbiter,
    offenseId,
    formatFieldByName,
    setNibrsAllowedProperty,
    selectors
) {
    // not using `=>` to keep `this` as reference to Form instance
    return function (config) {
        const arbiterResults = createArbiterMFTValidationHandler(
            arbiter,
            RefContextEnum.FORM_OFFENSE.name,
            formatFieldByName
        )(config);

        const {
            formatAttributeById,
            federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory,
            currentUserDepartmentId,
            applicationSettings,
            getItemSummaryViewModels,
            departmentProfile,
            ukOffenseCodeExtensionByOffenseCodeId,
        } = selectors;
        if (departmentProfile.complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name) {
            /* eslint-disable-next-line consistent-this, @typescript-eslint/no-this-alias */
            const form = this;
            const offenseCodeId = form.get('offense.offenseCodeId');
            if (!offenseCodeId) {
                return arbiterResults;
            }
            const ukOffenseCodeExtension = ukOffenseCodeExtensionByOffenseCodeId(offenseCodeId);
            const { isPropertyEntryRequired, isDrugEntryRequired } = ukOffenseCodeExtension || {};
            if (!isPropertyEntryRequired && !isDrugEntryRequired) {
                return arbiterResults;
            }
            const vehicles = getItemSummaryViewModels(true, offenseId);
            const property = getItemSummaryViewModels(false, offenseId);
            const drugs = property.filter(
                ({ itemTypeAttrId }) => itemTypeAttrId === globalAttributes.itemType.drugs
            );
            switch (true) {
                case !!isDrugEntryRequired && !drugs.length:
                    arbiterResults.success = false;
                    arbiterResults.formErrors.push(strings.ukDrugEntryRequired);
                    arbiterResults.rawErrors.push(strings.ukDrugEntryRequired);
                    return arbiterResults;
                case !!isPropertyEntryRequired && !property.length && !vehicles.length:
                    arbiterResults.success = false;
                    arbiterResults.formErrors.push(strings.ukPropertyEntryRequired);
                    arbiterResults.rawErrors.push(strings.ukPropertyEntryRequired);
                    return arbiterResults;
                default:
                    return arbiterResults;
            }
        }
        if (
            config.eventType !== formEvents.FORM_SUBMIT ||
            !applicationSettings.RMS_ITEM_ENTRY_V2_NIBRS_VALIDATION
        ) {
            return arbiterResults;
        }

        setNibrsAllowedProperty();
        /* eslint-disable-next-line consistent-this, @typescript-eslint/no-this-alias */
        const form = this;
        const vehicles = getItemSummaryViewModels(true, offenseId);
        const property = getItemSummaryViewModels(false, offenseId);
        const nibrsAllowedProperty = runOffenseCardConditionalRules(arbiter, form);
        const itemInvolvementType = form.get('offense.itemInvolvementType');
        const nibrsLinkedItemsValidationMessage = validateNibrsLinkedItems({
            vehicles: vehicles.length,
            property: property.length,
            nibrsAllowedProperty,
            itemInvolvementType,
            formatFieldByName,
        });

        if (!!nibrsLinkedItemsValidationMessage) {
            arbiterResults.success = false;
            arbiterResults.formErrors.push(nibrsLinkedItemsValidationMessage);
            arbiterResults.rawErrors.push(nibrsLinkedItemsValidationMessage);
            return arbiterResults;
        }

        const offenseNibrsCode = form.get('offense.nibrsCodeCode');
        const propertyStatusesWithItemProfile = flatMap([...vehicles, ...property], (itemProfile) =>
            map(itemProfile.propertyStatus, (propertyStatus) => ({ propertyStatus, itemProfile }))
        );

        const allVehiclePropertyStatusesOnOffense = flatMap(vehicles, 'propertyStatus');

        const propertyStatusDisallowedErrorMessages = reduce(
            propertyStatusesWithItemProfile,
            (acc, propertyStatusWithItemProfile) => {
                const { propertyStatus, itemProfile } = propertyStatusWithItemProfile;

                const { allowed, message } = isPropertyStatusAllowedOnOffense({
                    ...propertyStatusWithItemProfile,
                    nibrsAllowedProperty,
                    federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory,
                    currentUserDepartmentId,
                    offenseNibrsCode,
                    formatAttributeById,
                    formatFieldByName,
                });

                if (allowed === false && !!message) {
                    acc.push(message);
                }

                const {
                    allowed: recoveredImpliesStolenAllowed,
                    message: recoveredImpliesStolenMessage,
                } = recoveredImpliesStolen({
                    propertyStatus,
                    itemProfile,
                    nibrsAllowedProperty,
                    federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory,
                    currentUserDepartmentId,
                    allVehiclePropertyStatusesOnOffense,
                    formatFieldByName,
                });

                if (recoveredImpliesStolenAllowed === false && !!recoveredImpliesStolenMessage) {
                    acc.push(recoveredImpliesStolenMessage);
                }

                return acc;
            },
            []
        );

        if (propertyStatusDisallowedErrorMessages.length > 0) {
            arbiterResults.success = false;
            arbiterResults.formErrors.push(...uniq(propertyStatusDisallowedErrorMessages));
        }
        return arbiterResults;
    };
}

const createOffenseForm = (options = {}) => {
    const {
        initialState,
        arbiter,
        formatFieldByName,
        offenseId,
        setNibrsAllowedProperty,
        selectors,
        additionalData,
    } = options;

    return new _Form({
        name: RefContextEnum.FORM_OFFENSE.name,
        initialState,
        onValidate: createOffenseFormValidationHandler(
            arbiter,
            offenseId,
            formatFieldByName,
            setNibrsAllowedProperty,
            selectors
        ),
        validationEvents: mftArbiterValidationEvents,
        configuration: offenseFormConfiguration({ additionalData }),
    });
};

const locationDescriptionPath = () => 'links.location.description';
const locationPositionAttrIdPath = () => 'links.location.positionAttrId';

class OffenseCard extends React.Component {
    constructor(...args) {
        super(...args);
        const {
            offense,
            formatFieldByName,
            arbiter,
            index,
            formatAttributeById,
            federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory,
            currentUserDepartmentId,
            applicationSettings,
            departmentProfile,
            ukOffenseCodeExtensionByOffenseCodeId,
        } = this.props;
        const initialState = this.props.refreshOffenseForm(get(offense, 'id'));
        this.initialOffenseState = initialState;
        const form = createOffenseForm({
            initialState,
            formatFieldByName,
            arbiter,
            offenseId: offense.id,
            setNibrsAllowedProperty: this.setNibrsAllowedProperty.bind(this),
            selectors: {
                formatAttributeById,
                federalOrRegionalNibrsCodeForAttributeIdAndCodeTypeCategory,
                currentUserDepartmentId,
                applicationSettings,
                getItemSummaryViewModels: this.getItemSummaryViewModels.bind(this),
                departmentProfile,
                ukOffenseCodeExtensionByOffenseCodeId,
            },
            additionalData: {
                fieldDetails: this.props.fieldDetails,
                fieldConfigurationContexts: this.props.fieldConfigurationContexts,
                fieldConfigurations: this.props.fieldConfigurations,
                coreModelConfigurations: Object.values(
                    this.props.coreModelConfigurations || {}
                ).map((coreModelConfiguration) => ({
                    ...coreModelConfiguration,
                    properties: Object.values(coreModelConfiguration.properties || {}),
                })),
            },
        });
        registerForm({ form, index });
        const victimDefaultFields = {
            leokaAssignmentTypeAttrId: undefined,
            leokaActivityAttrId: undefined,
            leokaOtherJurisdictionOri: undefined,
            leokaOtherJurisdictionOriAttrId: undefined,
            informationProvidedAttrIds: undefined,
        };
        this.handleVictimAdd = createNItemsAdder({
            getForm: this.getForm,
            path: 'links.victims',
            defaults: {
                ...victimDefaultFields,
                isLeoka: false,
            },
        });
        this.handleVictimRemove = ({ nameId }) => {
            return createNItemsRemover({
                getForm: this.getForm,
                path: 'links.victims',
            })({ nameId });
        };
        this.handleSuspectAdd = createNItemsAdder({
            getForm: this.getForm,
            path: 'links.suspects',
        });
        this.handleSuspectRemove = ({ nameId }) => {
            return createNItemsRemover({
                getForm: this.getForm,
                path: 'links.suspects',
            })({ nameId });
        };
        this.handleOffenderAdd = createNItemsAdder({
            getForm: this.getForm,
            path: 'links.offenders',
        });
        this.handleOffenderRemove = ({ nameId }) => {
            return createNItemsRemover({
                getForm: this.getForm,
                path: 'links.offenders',
            })({ nameId });
        };
        this.handleWitnessAdd = createNItemsAdder({
            getForm: this.getForm,
            path: 'links.witnesses',
        });
        this.handleWitnessRemove = ({ nameId }) => {
            return createNItemsRemover({
                getForm: this.getForm,
                path: 'links.witnesses',
            })({ nameId });
        };
        this.handleOtherNamesAdd = createNItemsAdder({
            getForm: this.getForm,
            path: 'links.otherNames',
        });
        this.handleOtherNamesRemove = ({ nameId }) => {
            return createNItemsRemover({
                getForm: this.getForm,
                path: 'links.otherNames',
            })({ nameId });
        };

        this.state = {
            nibrsAllowedProperty: {},
            itemInvolvementValue: form.get('offense.itemInvolvementType'),
            errorMessage: null,
        };

        this.unregisterCard = registerCard({
            cardModule: offenseCards,
            onSave: this.onSave,
            index,
        });
    }

    componentDidMount = () => this.setNibrsAllowedProperty(true);

    componentWillUnmount() {
        this.unregisterCard();
    }

    getItemSummaryViewModels(...args) {
        return this.props.itemSummaryViewModelsForCurrentReport(...args);
    }

    setNibrsAllowedProperty = (dryRun) => {
        if (!this.props.applicationSettings.RMS_ITEM_ENTRY_V2_NIBRS_VALIDATION) {
            return;
        }

        const form = this.getForm();
        const result = runOffenseCardConditionalRules(this.props.arbiter, form, dryRun);

        this.setState({ nibrsAllowedProperty: result });

        const { propertyAllowed, vehiclesAllowed, allowedPropertyLossNibrsCodes } = result;
        const noneOrUnknownPropertyLossCodes = [propertyLossCodes.none, propertyLossCodes.unknown];

        if (propertyAllowed === false && vehiclesAllowed === false) {
            form.set('offense.itemInvolvementType', ItemInvolvementTypeEnum.NONE.name);
            this.setItemInvolvement(ItemInvolvementTypeEnum.NONE.name);
        } else if (
            (!!allowedPropertyLossNibrsCodes &&
                allowedPropertyLossNibrsCodes.length > 0 &&
                intersection(allowedPropertyLossNibrsCodes, noneOrUnknownPropertyLossCodes)
                    .length === 0) ||
            (!allowedPropertyLossNibrsCodes && (propertyAllowed || vehiclesAllowed))
        ) {
            // property must be involved if allowedPropertyLossNibrsCodes doesn't include None or
            // Unknown, or if it is not defined at all (which means all are allowed)
            form.set('offense.itemInvolvementType', ItemInvolvementTypeEnum.PROPERTY_INVOLVED.name);
            this.setItemInvolvement(ItemInvolvementTypeEnum.PROPERTY_INVOLVED.name);
        }
    };

    setItemInvolvement = (value) => this.setState({ itemInvolvementValue: value });

    onEdit = () => this.props.editCallback(() => this.props.onEdit({ index: this.props.index }));

    getForm = () => formsRegistry.get(RefContextEnum.FORM_OFFENSE.name, this.props.index);

    validateOffenseCode = ({ scrollToTop }) => {
        const form = this.getForm();
        const offenseId = get(this.props.offense, 'id');
        const offenseCodeId = form.get('offense.offenseCodeId');
        const offenseCode = get(this.props.offenseCodes, offenseCodeId);
        const linkedArrests = this.props.offenseLinkedArrest(offenseId);
        const offenseDisplayName = this.props.formatFieldByName(fields.DISPLAY_ONLY_OFFENSE);

        /**
         * Handle when the user tries to switch from an `isOffenseType` to an `isIncidentType`
         * We want to hard prevent saving offenses with `isIncidentType` offense if an offense
         * is already linked to an arrest
         */
        if (offenseCode && offenseCode.isIncidentType && linkedArrests.length > 0) {
            this.props.setErrorMessages(
                [strings.offenseIsAssociatedWithAnArrest(offenseDisplayName)],
                this.props.index
            );
            if (scrollToTop) {
                offenseCards.scrollToTop({ index: this.props.index });
            }
            return false;
        }
        return true;
    };

    onSaveProgress = ({ customEventType, changeToSummaryModeAfterSubmission } = {}) => {
        if (this.validateOffenseCode({ scrollToTop: true })) {
            this.props.onSaveProgress(this.getForm(), {
                index: this.props.index,
                customEventType,
                changeToSummaryModeAfterSubmission,
            });
        }
    };

    onSave = () => {
        const { offense, formatFieldByName } = this.props;
        if (!isClientSideOffenseStub(offense)) {
            if (!this.validateOffenseCode({ scrollToTop: false })) {
                const offenseDisplayName = formatFieldByName(fields.DISPLAY_ONLY_OFFENSE);
                return Promise.reject(strings.offenseIsAssociatedWithAnArrest(offenseDisplayName));
            }

            return this.props.onSave(this.getForm(), {
                index: this.props.index,
            });
        }
        return Promise.resolve();
    };

    onStubOffenseCodeChange = () =>
        this.onSaveProgress({
            customEventType: 'FORM_INITIAL_SUBMIT',
            changeToSummaryModeAfterSubmission: false,
        });

    onFullOffenseCodeChange = (offenseCodeId) => {
        if (get(this.props.offense, 'offenseCodeId') !== offenseCodeId) {
            this.props.clearSuccessMessage(this.props.offense.id);
        }
    };

    onRemove = () => this.props.openOffenseIncidentDeletionModal();

    onDuplicateSuccess = (offenseDuplicationResultView) => {
        const offense = get(offenseDuplicationResultView, 'offenseIncidentCardBundle.offense');
        const offenseDisplayName = this.props.formatFieldByName(fields.DISPLAY_ONLY_OFFENSE);
        this.props.transitionDuplicateOffenseToEditMode(offense.id);
        this.props.setSuccessMessage(
            strings.duplicateOffenseSuccessMessage(offenseDisplayName),
            offense.id
        );
        const duplicateOffenseCardFormState = this.props.refreshOffenseForm(offense.id);
        formsRegistry
            .get(RefContextEnum.FORM_OFFENSE.name, offense.id)
            .set('', duplicateOffenseCardFormState);
        this.setState({ errorMessage: null });
    };

    onDuplicateError = (offenseId, err) => {
        const offenseDisplayName = this.props.formatFieldByName(fields.DISPLAY_ONLY_OFFENSE);
        const errorMessage = `${strings.duplicateOffenseErrorMessage(offenseDisplayName)} ${
            err.message
        }`;
        this.setState({
            errorMessage,
        });
        offenseCards.scrollToTop({ index: offenseId });
        Promise.delay(DUPLICATE_OFFENSE_ERROR_MESSAGE_DISPLAY_TIME_SECONDS * 1000).then(() =>
            this.setState({ errorMessage: null })
        );
    };

    onDuplicate = () => this.props.duplicateOffense(this.onDuplicateSuccess, this.onDuplicateError);

    handleLocationAdd = (location, modelEntityLink) => {
        const form = this.getForm();
        if (!!form) {
            fieldsetAdder({ form, path: 'links.location', item: modelEntityLink });
        }
    };

    handleLocationRemove = () => {
        const form = this.getForm();
        if (!!form) {
            form.transaction(() => {
                fieldsetRemover({ form, path: 'links.location' });
                form.set('offense.burgNumberOfPremisesEntered', undefined);
                form.set('offense.schoolWeaponInvolved', undefined);
                form.set('offense.secondaryLocationCategoryAttrId', undefined);
            });
        }
    };

    renderAdditionalVictimItem = (props) => (
        <OffenseVictimAdditionalFields
            {...props}
            offenseFormIndex={this.props.index}
            formatAttributeById={this.props.formatAttributeById}
            nameAttributesWhere={this.props.nameAttributesWhere}
            hideSubstanceAbuseAttr={
                this.props.applicationSettings.RMS_NY_NIBRS_HIDE_VICTIM_SUBSTANCE_ABUSE_ENABLED
            }
        />
    );

    renderAdditionalSuspectItem = (props) => (
        <OffenseSuspectAdditionalFields
            {...props}
            offenseFormIndex={this.props.index}
            formatAttributeById={this.props.formatAttributeById}
            nameAttributesWhere={this.props.nameAttributesWhere}
        />
    );

    renderAdditionalOffenderItem = (props) => (
        <OffenseOffenderAdditionalFields
            {...props}
            offenseFormIndex={this.props.index}
            formatAttributeById={this.props.formatAttributeById}
            nameAttributesWhere={this.props.nameAttributesWhere}
        />
    );

    renderAdditionalWitnessItem = (props) => (
        <OffenseWitnessAdditionalFields {...props} offenseFormIndex={this.props.index} />
    );

    renderAdditionalOtherNamesItem = (props) => (
        <OffenseOtherNameAdditionalFields {...props} offenseFormIndex={this.props.index} />
    );

    renderContent = (summaryMode) => {
        const {
            card = {},
            index: offenseFormIndex,
            offenseReportId,
            currentReportREN,
            offense,
            formatFieldByName,
            formatNameReportLinkTypeId,
            offenseLinkedArrest,
            isWarrantModuleActive,
            offenseLinkedWarrant,
            successMessages,
            departmentProfile,
            applicationSettings,
            callGetValidOffenseCodeByNameAndDate,
            isLoadingValidOffenseCodeByNameAndDate,
        } = this.props;

        const offenseId = offense.id;
        const reportId = offense.reportId;
        const isClientSideStub = isClientSideOffenseStub(offense);
        const offenseDisplayName = formatFieldByName(fields.DISPLAY_ONLY_OFFENSE);
        const suspectDisplayName = formatNameReportLinkTypeId(LinkTypesEnum.SUSPECT_IN_OFFENSE);
        const offenderDisplayName = formatNameReportLinkTypeId(LinkTypesEnum.OFFENDER_IN_OFFENSE);
        const victimDisplayName = formatNameReportLinkTypeId(LinkTypesEnum.VICTIM_IN_OFFENSE);
        const linkedArrests = offenseLinkedArrest(offenseId);
        const linkedWarrants = offenseLinkedWarrant(offenseId);
        const successMessage = first(successMessages);

        const personOverlayIdPrefix = `${overlayIdEnum.PERSON_OVERLAY_OFFENSE_CARD}.${offenseFormIndex}`;

        const organizationOverlayIdPrefix = `${overlayIdEnum.ORGANIZATION_OVERLAY_OFFENSE_CARD}.${offenseFormIndex}`;
        const locationSummaryProps = {
            summaryMode,
            entityType: EntityTypeEnum.OFFENSE.name,
            entityId: offenseId,
            linkType: LinkTypesEnum.OFFENSE_LOCATION,
            onLocationAdd: this.handleLocationAdd,
            onLocationRemove: this.handleLocationRemove,
        };
        return (
            <div>
                {isValueDisplayable(successMessage) && (
                    <SuccessMessagePill>{successMessage}</SuccessMessagePill>
                )}
                {isValueDisplayable(this.state.errorMessage) && (
                    <ErrorMessagePill>{this.state.errorMessage}</ErrorMessagePill>
                )}
                <CardSection>
                    {(linkedArrests.length > 0 || linkedWarrants.length > 0) && (
                        <SummaryList labelWidth={160}>
                            {linkedArrests.length > 0 && (
                                <SummaryRow label={strings.offenseLinkedArrest}>
                                    {map(linkedArrests, (linkedArrest) => (
                                        <>
                                            <Link
                                                to={`/reports/${linkedArrest.id}`}
                                                testId={testIds.OFFENSE_CARD_LINKED_ARREST}
                                            >
                                                {linkedArrest.title}
                                            </Link>
                                            <br />
                                        </>
                                    ))}
                                </SummaryRow>
                            )}
                            {linkedWarrants.length > 0 && (
                                <SummaryRow label={strings.offenseLinkedWarrant}>
                                    {map(linkedWarrants, (linkedWarrant) => (
                                        <>
                                            <Link
                                                to={`/warrants/${linkedWarrant.id}`}
                                                testId={testIds.OFFENSE_CARD_LINKED_WARRANT}
                                                disabled={!isWarrantModuleActive}
                                            >
                                                {linkedWarrant.title}
                                            </Link>
                                            <br />
                                        </>
                                    ))}
                                </SummaryRow>
                            )}
                        </SummaryList>
                    )}
                </CardSection>
                {summaryMode ? (
                    <OffenseCardSummary offenseId={offenseId} offenseFormIndex={this.props.index} />
                ) : (
                    <OffenseCardForm
                        offenseId={offenseId}
                        index={offenseFormIndex}
                        isClientSideStub={isClientSideStub}
                        onStubOffenseCodeChange={this.onStubOffenseCodeChange}
                        onFullOffenseCodeChange={this.onFullOffenseCodeChange}
                        isSaving={card.saving}
                        setNibrsAllowedProperty={this.setNibrsAllowedProperty}
                        nibrsAllowedProperty={this.state.nibrsAllowedProperty}
                        setItemInvolvement={this.setItemInvolvement}
                        getForm={this.getForm}
                        onSave={this.onSave}
                        reportId={offenseReportId}
                        autoFocus={this.props.autoFocus}
                        setErrorMessages={this.props.setErrorMessages}
                        initialState={this.initialOffenseState}
                        callGetValidOffenseCodeByNameAndDate={callGetValidOffenseCodeByNameAndDate}
                        isLoadingValidOffenseCodeByNameAndDate={
                            isLoadingValidOffenseCodeByNameAndDate
                        }
                    />
                )}
                {!isClientSideStub && (
                    <div>
                        <CardSection
                            title={strings.offenseLocationSectionTitle(offenseDisplayName)}
                            testId={testIds.OFFENSE_LOCATION_SECTION}
                            fieldName={
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_LINK_TYPE
                            }
                        >
                            {summaryMode ? (
                                <OffenseLocationSummary {...locationSummaryProps} />
                            ) : (
                                <Form
                                    name={RefContextEnum.FORM_OFFENSE.name}
                                    index={offenseFormIndex}
                                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                                    render={() => {
                                        return (
                                            <>
                                                <LocationSummaryViewWrapperWithFormFields
                                                    {...locationSummaryProps}
                                                    locationDescriptionPath={
                                                        locationDescriptionPath
                                                    }
                                                    locationPositionAttrIdPath={
                                                        locationPositionAttrIdPath
                                                    }
                                                />
                                                <Fieldset
                                                    path="offenseAttributes"
                                                    render={() => (
                                                        <IndentedFields>
                                                            <Row>
                                                                <ArbiterMFTAttributeSelect
                                                                    path="OFFENSE_SCENE_OF_CRIME_LOCATION.attributeIds"
                                                                    attributeType={
                                                                        AttributeTypeEnum
                                                                            .OFFENSE_SCENE_OF_CRIME_LOCATION
                                                                            .name
                                                                    }
                                                                    multiple={true}
                                                                    length="lg"
                                                                />
                                                            </Row>
                                                        </IndentedFields>
                                                    )}
                                                />
                                                <Fieldset path="offense">
                                                    <IndentedFields>
                                                        <Row>
                                                            <ArbiterMFTBooleanSelect
                                                                path="schoolWeaponInvolved"
                                                                length="md"
                                                            />
                                                        </Row>
                                                        <Row>
                                                            <ArbiterMFTText
                                                                // `burgNumberOfPremisesEntered` is displayed in the Offense Location
                                                                // section in Edit Mode, but shows up in the `OffenseCardSummary`
                                                                // section in Summary Mode.
                                                                path="burgNumberOfPremisesEntered"
                                                                length="md"
                                                            />
                                                        </Row>
                                                        <Row>
                                                            <ArbiterMFTAttributeSelect
                                                                path="secondaryLocationCategoryAttrId"
                                                                attributeType={
                                                                    AttributeTypeEnum.LOCATION_CATEGORY.name
                                                                }
                                                                length="lg"
                                                            />
                                                        </Row>
                                                    </IndentedFields>
                                                </Fieldset>
                                            </>
                                        );
                                    }}
                                />
                            )}
                        </CardSection>
                        <CardSection
                            testId={testIds.VICTIM_SECTION}
                            fieldName={
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_LINK_TYPE
                            }
                        >
                            <Observer
                                subscriptions={{
                                    nibrsOffenseCodeId: 'offense.nibrsOffenseCodeId',
                                    offenseCodeId: 'offense.offenseCodeId',
                                }}
                                formName={RefContextEnum.FORM_OFFENSE.name}
                                formIndex={offenseFormIndex}
                                render={({ nibrsOffenseCodeId, offenseCodeId }) => {
                                    let victimCanBePerson = true;
                                    let victimCanBeSociety = true;
                                    let victimCanBeOrg = true;
                                    let isCrimeAgainstSociety = false;

                                    const societyOffenseRequiresSocietyVictim =
                                        applicationSettings.RMS_NIBRS_SOCIETY_OFFENSE_REQUIRES_SOCIETY_VICTIM_ENABLED;

                                    const societyOffenseAllowSecondaryVictims =
                                        applicationSettings.RMS_NIBRS_SOCIETY_OFFENSE_ALLOW_NONSOCIETY_VICTIMS_ENABLED;

                                    let nibrsCode = nibrsOffenseCodeId
                                        ? this.props.nibrsOffenseCodes[nibrsOffenseCodeId]
                                        : undefined;

                                    if (nibrsCode) {
                                        isCrimeAgainstSociety =
                                            nibrsCode.crimeAgainst === nibrsCrimesAgainst.society;
                                        const isCrimeAgainstProperty =
                                            nibrsCode.crimeAgainst === nibrsCrimesAgainst.property;
                                        const isGroupBOffense = nibrsCode.group === 'B';
                                        // nibrs code 90F and 90J allows person/org information to be added for certain agencies for crimes against society
                                        const isSocietyOffenseCodesException =
                                            nibrsCode.code === NibrsOffenseCodeEnum.TR.code ||
                                            nibrsCode.code === NibrsOffenseCodeEnum.FON.code;
                                        const isSocietyOffenseException =
                                            societyOffenseAllowSecondaryVictims &&
                                            isSocietyOffenseCodesException;

                                        victimCanBePerson =
                                            !(
                                                isCrimeAgainstSociety &&
                                                societyOffenseRequiresSocietyVictim
                                            ) || isSocietyOffenseException;

                                        victimCanBeSociety =
                                            isGroupBOffense || isCrimeAgainstSociety;
                                        victimCanBeOrg =
                                            isCrimeAgainstProperty ||
                                            isCrimeAgainstSociety ||
                                            isGroupBOffense;

                                        // special req for LIBRS 40C that is different from NIBRS
                                        // https://docs.librs.org/data-element-definitions#type-of-victim-25
                                        if (
                                            departmentProfile.nibrsRegionalGroup ===
                                                RegionalGroupEnum.LOUISIANA_NIBRS.name &&
                                            nibrsCode.code === NibrsOffenseCodeEnum.PPR.code
                                        ) {
                                            victimCanBeSociety = false;
                                            victimCanBeOrg = false;
                                        }
                                    }

                                    if (
                                        departmentProfile.complianceGroup ===
                                        ComplianceGroupEnum.UNITED_KINGDOM.name
                                    ) {
                                        nibrsCode = { id: offenseCodeId };
                                    }
                                    return (
                                        <NameSummaryViewWrapper
                                            renForRecents={currentReportREN}
                                            personOverlayIdPrefix={personOverlayIdPrefix}
                                            organizationOverlayIdPrefix={
                                                organizationOverlayIdPrefix
                                            }
                                            onAddSuccess={this.handleVictimAdd}
                                            onRemoveSuccess={this.handleVictimRemove}
                                            addNameButtonText={victimDisplayName}
                                            summaryMode={summaryMode}
                                            contextType={EntityTypeEnum.OFFENSE.name}
                                            contextId={offenseId}
                                            parentEntityType={EntityTypeEnum.REPORT.name}
                                            parentId={offenseReportId}
                                            linkType={LinkTypesEnum.VICTIM_IN_OFFENSE}
                                            show={{
                                                people: victimCanBePerson,
                                                organizations: victimCanBeOrg,
                                                societies: victimCanBeSociety,
                                                noInfoKnown: !!applicationSettings.RMS_UNKNOWN_VICTIM_QUICK_ADD_ENABLED,
                                            }}
                                            renderAdditionalItem={this.renderAdditionalVictimItem}
                                            nibrsOffenseCode={nibrsCode}
                                            societyOffenseRequiresSocietyVictim={
                                                societyOffenseRequiresSocietyVictim
                                            }
                                            nibrsCrimeAgainstSociety={isCrimeAgainstSociety}
                                        />
                                    );
                                }}
                            />
                        </CardSection>
                        <CardSection
                            testId={testIds.SUSPECT_SECTION}
                            fieldName={
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_LINK_TYPE
                            }
                        >
                            <Observer
                                subscriptions={{ offenseCodeId: 'offense.offenseCodeId' }}
                                formName={RefContextEnum.FORM_OFFENSE.name}
                                formIndex={offenseFormIndex}
                                render={({ offenseCodeId }) => {
                                    const sendCode =
                                        departmentProfile.complianceGroup ===
                                        ComplianceGroupEnum.UNITED_KINGDOM.name;
                                    const nibrsOffenseCode = sendCode
                                        ? { id: offenseCodeId }
                                        : undefined;

                                    return (
                                        <NameSummaryViewWrapper
                                            renForRecents={currentReportREN}
                                            personOverlayIdPrefix={personOverlayIdPrefix}
                                            organizationOverlayIdPrefix={
                                                organizationOverlayIdPrefix
                                            }
                                            onAddSuccess={this.handleSuspectAdd}
                                            onRemoveSuccess={this.handleSuspectRemove}
                                            addNameButtonText={suspectDisplayName}
                                            summaryMode={summaryMode}
                                            contextType={EntityTypeEnum.OFFENSE.name}
                                            contextId={offenseId}
                                            parentEntityType={EntityTypeEnum.REPORT.name}
                                            parentId={offenseReportId}
                                            linkType={LinkTypesEnum.SUSPECT_IN_OFFENSE}
                                            show={{
                                                people: true,
                                                organizations: applicationSettings.RMS_ALLOW_ORGANIZATION_SUSPECT_ENABLED,
                                                noInfoKnown: true,
                                            }}
                                            renderAdditionalItem={this.renderAdditionalSuspectItem}
                                            nibrsOffenseCode={nibrsOffenseCode}
                                        />
                                    );
                                }}
                            />
                        </CardSection>
                        <WithComplianceGroup
                            complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                        >
                            <CardSection
                                testId={testIds.OFFENDER_SECTION}
                                fieldName={
                                    fields.NAME_REPORT_LINK_LINK_TYPE_OFFENDER_IN_OFFENSE_LINK_TYPE
                                }
                            >
                                <Observer
                                    subscriptions={{ offenseCodeId: 'offense.offenseCodeId' }}
                                    formName={RefContextEnum.FORM_OFFENSE.name}
                                    formIndex={offenseFormIndex}
                                    render={({ offenseCodeId }) => {
                                        return (
                                            <NameSummaryViewWrapper
                                                renForRecents={currentReportREN}
                                                personOverlayIdPrefix={personOverlayIdPrefix}
                                                organizationOverlayIdPrefix={
                                                    organizationOverlayIdPrefix
                                                }
                                                onAddSuccess={this.handleOffenderAdd}
                                                onRemoveSuccess={this.handleOffenderRemove}
                                                addNameButtonText={offenderDisplayName}
                                                summaryMode={summaryMode}
                                                contextType={EntityTypeEnum.OFFENSE.name}
                                                contextId={offenseId}
                                                parentEntityType={EntityTypeEnum.REPORT.name}
                                                parentId={offenseReportId}
                                                linkType={LinkTypesEnum.OFFENDER_IN_OFFENSE}
                                                show={{
                                                    people: true,
                                                    organizations: applicationSettings.RMS_ALLOW_ORGANIZATION_SUSPECT_ENABLED,
                                                    noInfoKnown: false,
                                                }}
                                                renderAdditionalItem={
                                                    this.renderAdditionalOffenderItem
                                                }
                                                nibrsOffenseCode={{ id: offenseCodeId }}
                                            />
                                        );
                                    }}
                                />
                            </CardSection>
                        </WithComplianceGroup>
                        <CardSection
                            testId={testIds.WITNESS_SECTION}
                            fieldName={
                                fields.NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_LINK_TYPE
                            }
                        >
                            <NameSummaryViewWrapper
                                renForRecents={currentReportREN}
                                personOverlayIdPrefix={personOverlayIdPrefix}
                                organizationOverlayIdPrefix={organizationOverlayIdPrefix}
                                onAddSuccess={this.handleWitnessAdd}
                                onRemoveSuccess={this.handleWitnessRemove}
                                addNameButtonText={this.props.formatFieldByName(
                                    fields.NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_LINK_TYPE
                                )}
                                summaryMode={summaryMode}
                                contextType={EntityTypeEnum.OFFENSE.name}
                                contextId={offenseId}
                                parentEntityType={EntityTypeEnum.REPORT.name}
                                parentId={offenseReportId}
                                linkType={LinkTypesEnum.WITNESS_IN_OFFENSE}
                                show={{ people: true, organizations: false }}
                                renderAdditionalItem={this.renderAdditionalWitnessItem}
                            />
                        </CardSection>
                        <CardSection
                            title={strings.offenseOtherInvolvedNamesSectionTitle}
                            testId={testIds.OTHER_NAMES_SECTION}
                        >
                            <NameSummaryViewWrapper
                                renForRecents={currentReportREN}
                                personOverlayIdPrefix={personOverlayIdPrefix}
                                organizationOverlayIdPrefix={organizationOverlayIdPrefix}
                                onAddSuccess={this.handleOtherNamesAdd}
                                onRemoveSuccess={this.handleOtherNamesRemove}
                                addNameButtonText={
                                    strings.offenseOtherInvolvedNamesSectionAddButton
                                }
                                summaryMode={summaryMode}
                                contextType={EntityTypeEnum.OFFENSE.name}
                                contextId={offenseId}
                                parentEntityType={EntityTypeEnum.REPORT.name}
                                parentId={offenseReportId}
                                linkType={LinkTypesEnum.OTHER_NAME_IN_OFFENSE}
                                show={{ people: true, organizations: true }}
                                renderAdditionalItem={this.renderAdditionalOtherNamesItem}
                            />
                        </CardSection>
                        <CardSection>
                            <Observer
                                subscriptions={{
                                    offenseNibrsCode: 'offense.nibrsCodeCode',
                                }}
                                formName={RefContextEnum.FORM_OFFENSE.name}
                                formIndex={offenseFormIndex}
                                render={({ offenseNibrsCode }) => (
                                    <ItemSectionsInOffense
                                        hidden={false}
                                        offenseId={offenseId}
                                        reportId={reportId}
                                        summaryMode={summaryMode}
                                        nibrsAllowedProperty={this.state.nibrsAllowedProperty}
                                        itemInvolvementValue={this.state.itemInvolvementValue}
                                        offenseNibrsCode={offenseNibrsCode}
                                    />
                                )}
                            />
                        </CardSection>

                        {summaryMode && (
                            <LegacyEntityDetails
                                legacyEntityDetails={this.props.legacyEntityDetails}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const {
            card = {},
            offense,
            index,
            successMessages,
            offenseReportId,
            cardId,
            departmentProfile,
            isLoadingValidOffenseCodeByNameAndDate,
        } = this.props;
        const isClientSideStub = isClientSideOffenseStub(offense);
        const hideOffenseCode = size(successMessages) > 0;

        return (
            <Observer
                subscriptions={{
                    offenseCodeId: 'offense.offenseCodeId',
                }}
                formName={RefContextEnum.FORM_OFFENSE.name}
                formIndex={index}
                render={({ offenseCodeId }) => (
                    <Card
                        title={
                            <OffenseIncidentTitle
                                card={card}
                                isClientSideStub={isClientSideStub}
                                maxOffenseNumber={this.props.maxOffenseNumber}
                                onReorder={this.props.onReorder}
                                offense={{ ...offense, offenseCodeId }}
                                buildCardTitle={buildOffenseCardTitle}
                                hideOffenseCode={hideOffenseCode}
                                offenseReportId={offenseReportId}
                                cardId={cardId}
                            />
                        }
                        testId={
                            isClientSideStub
                                ? testIds.STUB_OFFENSE_INCIDENT_CARD
                                : testIds.OFFENSE_CARD
                        }
                        onEdit={this.onEdit}
                        errors={card.errorMessages}
                        summaryMode={card.summaryMode}
                        canEdit={get(
                            card.canEditOffenseIncidentReportCardStatus,
                            'canEditReportCard'
                        )}
                        canEditErrorMessage={get(
                            card.canEditOffenseIncidentReportCardStatus,
                            'errorMessage'
                        )}
                        saving={card.saving}
                        onSave={isClientSideStub ? undefined : this.onSaveProgress}
                        saveDisabled={isClientSideStub || isLoadingValidOffenseCodeByNameAndDate}
                        onRemove={this.onRemove}
                        anchor={card.anchorForIndex(offense.id)}
                        renderContent={this.renderContent}
                        onDuplicate={
                            departmentProfile.complianceGroup ===
                            ComplianceGroupEnum.UNITED_KINGDOM.name
                                ? null
                                : this.onDuplicate
                        }
                    />
                )}
            />
        );
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    openOffenseIncidentDeletionModal: () =>
        dispatch(
            openOffenseIncidentDeletionModal({
                entityId: props.offense.id,
                isIncident: false,
            })
        ),
    refreshOffenseForm: (offenseId) => dispatch(refreshOffenseForm({ offenseId })),
    duplicateOffense: (successCallback, errorCallback) =>
        dispatch(duplicateOffense(props.offense.id, successCallback, errorCallback)),
    transitionDuplicateOffenseToEditMode: (offenseId) => {
        dispatch(offenseCards.actionCreators.transitionToEditMode({ index: offenseId }));
        offenseCards.scrollToTop({ index: offenseId });
    },
    setSuccessMessage: (message, index) =>
        dispatch(offenseCards.actionCreators.setSuccessMessages([message], { index })),
    clearSuccessMessage: (index) =>
        dispatch(offenseCards.actionCreators.setSuccessMessages([], { index })),
    setErrorMessages: (messages, index) =>
        dispatch(offenseCards.actionCreators.setErrorMessages(messages, { index })),
});

const OffenseCardFCWrapper = (props) => {
    const {
        callGetValidOffenseCodeByNameAndDate,
        isLoadingValidOffenseCodeByNameAndDate,
    } = useGetValidOffenseCodeByNameAndDate(props.index);
    return (
        <OffenseCard
            {...props}
            callGetValidOffenseCodeByNameAndDate={callGetValidOffenseCodeByNameAndDate}
            isLoadingValidOffenseCodeByNameAndDate={isLoadingValidOffenseCodeByNameAndDate}
        />
    );
};

export default compose(
    withCard(offenseCards),
    connect(mapStateToProps, mapDispatchToProps, null, {
        forwardRef: true,
    })
)(OffenseCardFCWrapper);
