import { Action } from 'redux';
import styled from 'styled-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { WebMap, MapView } from '../constructors';
import {
    CenterT,
    MapViewT,
    WebMapT,
    EsriBaseMapModeT,
    PointT,
    MapOnChangeProps,
    ViewClickEventT,
} from '../types';
import { handleMapViewGotoCatch } from '../helpers/mapHelpers';
import { areLatLngsEqual, isValidLatLng } from '../../domain/locations/utils/locationHelpers';
import { ESRI_MAP_DEFAULT_ROTATION_ENABLED } from '../components/config';
import { EsriUtils } from './esriUtils';

const WebmapContainer = styled.div`
    height: 100%;
    width: 100%;
`;

type UseEsriESModuleSimpleMapProps = {
    center: CenterT;
    mapRef: React.MutableRefObject<HTMLDivElement | null>;
    esriBaseMapMode?: EsriBaseMapModeT;
    zoomLevel?: number;
    enableMove?: boolean;
    enableZoom?: boolean;
    mapOnChange?: (data: MapOnChangeProps) => Action;
    webmapPortalId?: string;
    mapOnClick?: (e: ViewClickEventT) => void;
    onMapLoadingError?: () => void;
};
const useEsriESModuleSimpleMap = ({
    center,
    mapRef,
    esriBaseMapMode,
    zoomLevel,
    enableMove,
    enableZoom,
    mapOnChange,
    webmapPortalId,
    mapOnClick,
    onMapLoadingError,
}: UseEsriESModuleSimpleMapProps) => {
    const [mapView, setMapView] = useState<MapViewT>();
    const [webmap, setWebMap] = useState<WebMapT>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const prevCenterRef = useRef<CenterT>();
    const prevZoomRef = useRef<number>();
    const centerRef = useRef<CenterT>(center);
    // Wrapping this in a ref so that it is always stable
    // Otherwise, it'll cause the map to re-init itself every render
    const stableMapOnClickRef = useRef<undefined | ((e: ViewClickEventT) => void)>(undefined);
    stableMapOnClickRef.current = mapOnClick;

    useEffect(() => {
        if (
            !webmap ||
            !mapView ||
            (center.lat === centerRef.current.lat && center.lng === centerRef.current.lng) ||
            !isValidLatLng(center.lat, center.lng)
        ) {
            return;
        }

        mapView
            .goTo(
                {
                    target: [center.lng, center.lat],
                },
                { animate: false }
            )
            .catch(handleMapViewGotoCatch);
        centerRef.current = {
            lat: center.lat,
            lng: center.lng,
        };
    }, [center.lat, center.lng, webmap, mapView]);

    useEffect(() => {
        const webmap = new WebMap(
            webmapPortalId
                ? {
                      portalItem: {
                          id: webmapPortalId,
                      },
                  }
                : {
                      basemap: esriBaseMapMode,
                  }
        );

        EsriUtils.attach(webmap).catchWebMapLoadError();

        // @ts-expect-error esri type conversion
        const point: PointT = {
            type: 'point',
            latitude: centerRef.current.lat,
            longitude: centerRef.current.lng,
        };
        // Create View
        const view = new MapView({
            container: mapRef.current || undefined,
            map: webmap,
            center: point,
            zoom: zoomLevel,
            constraints: {
                rotationEnabled: ESRI_MAP_DEFAULT_ROTATION_ENABLED,
            },
        });

        // Set General popup rules

        view.popup.dockOptions = {
            position: 'top-left',
        };

        EsriUtils.attach(view).enableMove(!!enableMove).enableZoom(!!enableZoom);

        view.on('click', (event: ViewClickEventT) => {
            stableMapOnClickRef.current?.(event);
        });

        if (mapOnChange) {
            prevCenterRef.current = {
                lat: centerRef.current.lat,
                lng: centerRef.current.lng,
            };
            prevZoomRef.current = view.zoom;

            view.watch('updating', (newValue, oldValue) => {
                const currentCenter = {
                    lat: view.center.latitude,
                    lng: view.center.longitude,
                };
                // only call when finished updating and center or zoom has changed value
                if (
                    oldValue &&
                    !newValue &&
                    (!areLatLngsEqual(currentCenter, prevCenterRef.current) ||
                        view.zoom !== prevZoomRef.current)
                ) {
                    mapOnChange({
                        center: currentCenter,
                        zoom: view.zoom,
                    });
                    // update prev values to current values
                    prevCenterRef.current = currentCenter;
                    prevZoomRef.current = view.zoom;
                }
            });
        }
        // Make Esri modules available to other components
        // Remove All Unused ESRI Widgets
        view.ui.components = [];
        setMapView(view);
        setWebMap(webmap);

        // Cleanup function to destroy mapView when unmounting
        return () => {
            if (view) {
                view.destroy();
            }
        };
    }, [
        mapRef,
        mapOnChange,
        enableMove,
        zoomLevel,
        enableZoom,
        esriBaseMapMode,
        webmapPortalId,
        stableMapOnClickRef,
    ]);

    const handleError = useCallback(() => {
        setIsError(true);
        if (onMapLoadingError) {
            onMapLoadingError();
        }
    }, [onMapLoadingError]);

    useEffect(() => {
        if (!mapView) {
            return;
        }
        mapView
            .when(() => setIsLoading(false))
            .catch(() => {
                handleError();
            });
    }, [handleError, mapView]);

    return {
        webmap,
        mapView,
        isError,
        isLoading,
    };
};

type UseEsriSimpleBaseMapProps = {
    center: CenterT;
    zoomLevel: number;
    enableMove?: boolean;
    enableZoom?: boolean;
    mapMode?: EsriBaseMapModeT;
    mapOnChange?: (data: MapOnChangeProps) => Action;
    webmapPortalId?: string;
    mapOnClick?: (e: ViewClickEventT) => void;
    onMapLoadingError?: () => void;
};
export const useEsriSimpleBaseMap = ({
    center,
    zoomLevel,
    enableMove,
    enableZoom,
    mapMode,
    mapOnChange,
    webmapPortalId,
    mapOnClick,
    onMapLoadingError,
}: UseEsriSimpleBaseMapProps) => {
    const mapRef = useRef<HTMLDivElement | null>(null);
    const { mapView, webmap, isError, isLoading } = useEsriESModuleSimpleMap({
        center,
        mapRef,
        esriBaseMapMode: mapMode,
        zoomLevel,
        enableMove,
        enableZoom,
        mapOnChange,
        webmapPortalId,
        mapOnClick,
        onMapLoadingError,
    });

    const baseMap = <WebmapContainer ref={mapRef} />;

    return {
        baseMap,
        mapView,
        webmap,
        isError,
        isLoading,
    };
};
