import { RefContextEnum, LinkTypesEnum, AttributeTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import {
    map,
    pick,
    chain,
    get,
    first,
    find,
    assign,
    omit,
    filter,
    reduce,
    forEach,
    isNumber,
    concat,
    flatMap,
    orderBy,
} from 'lodash';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import {
    deduplicatePropertyStatuses,
    formatDeclaredValueAndForfeitureValueInFormModel,
    mergePropertyStatuses,
} from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { filterFormData } from '~/client-common/helpers/formHelpers';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { otherUserId } from '~/client-common/helpers/userHelpers';
import { versionableBaseFieldKeys } from '~/client-common/helpers/dataHelpers';
import formsRegistry from '../../../../../core/formsRegistry';
import { cautionFormFields } from '../../../../core/cautions/configuration';
import { convertCautionsFromFormModel } from '../../../../core/cautions/helpers';

const OTHER_OPTION_VALUE = -1;
const mapOmit = (object, predicate) => map(object, (o) => omit(o, predicate));

// property status ids passed into form to match on convert from form model
const propertyStatusesFields = [
    'declaredValue',
    'forfeitureValue',
    'declaredValueUnknown',
    'id',
    'propertyStatusAttrId',
    'statusDateUtc',
    'vehicleRecoveryTypeAttrId',
    'marijuanaTypeAttrId',
    'marijuanaQuantity',
];
const custodyStatusFields = [
    'reasonForPoliceCustodyAttrId',
    'storageFacility',
    'storageLocation',
    'policeCustodyTypeEntityType',
    'infieldTransferByOfficerId',
];
const recoveryInfoFields = [
    'recoveredByOfficerId',
    'recoveredByOtherName',
    'recoveredDateUtc',
    'collectedFromEntityType',
    'collectedVehicleRegistrationNumber',
    'collectedVehicleStateAttrId',
    'intakePerson',
    'statementOfFacts',
    'ownerNotified',
    'notifiedDateUtc',
    'notifierName',
];
const impoundInfoFields = [
    'towingCompanyAttrId',
    'towingCompanyOther',
    'towingLocation',
    'towingNumber',
    'wasVehicleSearchConsentedTo',
    'wasVehicleSearched',
    'towAuthorizedByAttrId',
];
const firearmFieldsFromItemProfile = [
    'description',
    'itemCategoryAttrId',
    'itemModel',
    'serialNumber',
];
const firearmFields = [
    'alterationIndicated',
    'barrelLength',
    'caliber',
    'compensationRequiredAttrId',
    'finishAttrId',
    'firearmMakeAttrId',
    'gripAttrId',
    'indemnityObtainedAttrId',
    'isRenderedSafe',
    'numberOfShots',
    'registrationNumber',
    'renderedSafeByOfficerId',
    'scopeAttachedAttrId',
    'stockAttrId',
    'ncicFirearmCaliberAttrId',
    'atfManufacturerId',
];
const vehicleFields = [
    'bodyStyleAttrId',
    'bodyStyleOther',
    'insurancePolicyNumber',
    'insuranceProviderName',
    'insuranceCode',
    'dateInsuranceExpiration',
    'mileage',
    'registrationStateAttrId',
    'registrationType',
    'registrationYear',
    'tag',
    'wasAntifreezeInVehicle',
    'wasRadiatorTaggedAndDrained',
    'vehicleMakeAttrId',
    'vehicleMakeId',
    'vehicleModelAttrId',
    'vehicleModelId',
    'vinNumber',
    'yearOfManufacture',
    'sourceCadVehicleProfileId',
];
const vehicleFieldsFromItemProfile = [
    'description',
    'itemCategoryAttrId',
    'itemMake',
    'itemModel',
    'primaryColorAttrId',
    'secondaryColorAttrId',
];
const otherItemFields = [
    'description',
    'itemCategoryAttrId',
    'itemMake',
    'itemModel',
    'primaryColorAttrId',
    'secondaryColorAttrId',
    'serialNumber',
    'size',
];
const sharedItemDetailFieldsFromItemProfile = [
    'biohazardDescription',
    'isBiohazard',
    'returnToOwnerAttrId',
];
const sharedItemDetailFieldsFromPropertyStatus = [
    'measurementUnitsAttrId',
    'isQuantityUnknown',
    'wasContainerOpen',
    'containerAmountRemaining',
    'wasNcicCheckMadeForIdentifiableProperty',
    'ncicSearchNumbers',
    'ncicNumber',
];
const drugsFields = [
    'description',
    'itemCategoryAttrId',
    'isCounterfeit',
    'isPrecursorChemical',
    'itemMake',
    'primaryColorAttrId',
];
const sharedDrugsFieldsFromPropertyStatus = ['wasContainerOpen', 'containerAmountRemaining'];
// item identifier ids passed into form to help find itemIdentifiers for convert from form model
const itemIdentifierFields = [
    'id',
    'identifier',
    'itemIdentifierTypeAttrId',
    'itemIdentifierTypeOtherDesc',
];
const nameItemLinkFields = [
    'entityType',
    'id',
    'linkType',
    'nameId',
    'nameItemAssociationAttrId',
    'nameItemAssociationOther',
    'proofOfOwnershipAttrId',
    'proofOfOwnershipOther',
    'dateEffectiveFrom',
    'dateEffectiveTo',
];
const itemFacilityLinkFields = ['storageLocationId'];

const attributeLinksFields = [
    'modelNumberLocationAttrIds',
    'serialNumberLocationAttrIds',
    'vehicleCautionAttrIds',
    'drugDataSourceAttrId',
    'vehicleAdditionalSecondaryColorAttrIds',
    'vehicleUseAttrId',
];

const acceptableAttributeLinksFieldsMap = attributeLinksFields.reduce(
    (obj, field) => ({
        ...obj,
        [field]: true,
    }),
    {}
);
const attributePathTypeMap = {
    modelNumberLocationAttrIds: AttributeTypeEnum.FIREARM_MODEL_NUMBER_LOCATION.name,
    serialNumberLocationAttrIds: AttributeTypeEnum.FIREARM_SERIAL_NUMBER_LOCATION.name,
    vehicleCautionAttrIds: AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name,
    drugDataSourceAttrId: AttributeTypeEnum.DRUGS_DATA_SOURCE.name,
    vehicleAdditionalSecondaryColorAttrIds: AttributeTypeEnum.ITEM_COLOR.name,
    vehicleUseAttrId: AttributeTypeEnum.QC_EMERGENCY_MOTOR_VEHICLE_USE.name,
};

const attributeTypePathToOtherDescription = {
    [AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name]: 'vehicleCautionDescription',
};

const attributeTypePathMap = Object.fromEntries(
    Object.entries(attributePathTypeMap).map(([k, v]) => [v, k])
);

const { DUPLICATE } = itemSidePanelOperationEnum;

export const initialState = {
    itemTypeAttrId: undefined,
    firearm: {
        serialNumber: undefined,
        serialNumberUnknown: undefined,
        itemCategoryAttrId: undefined,
        description: undefined,
        firearmMakeAttrId: undefined,
        itemModel: undefined,
        registrationNumber: undefined,
        finishAttrId: undefined,
        stockAttrId: undefined,
        gripAttrId: undefined,
        caliber: undefined,
        numberOfShots: undefined,
        barrelLength: undefined,
        alterationIndicated: undefined,
        scopeAttachedAttrId: undefined,
        isRenderedSafe: undefined,
        renderedSafeByOfficerId: undefined,
        indemnityObtainedAttrId: undefined,
        compensationRequiredAttrId: undefined,
        atfManufacturerId: undefined,
    },
    drugs: {
        description: undefined,
        itemCategoryAttrId: undefined,
        isPrecursorChemical: undefined,
        wasContainerOpen: undefined,
        containerAmountRemaining: undefined,
        itemMake: undefined,
        primaryColorAttrId: undefined,
    },
    otherItem: {
        itemCategoryAttrId: undefined,
        description: undefined,
        primaryColorAttrId: undefined,
        secondaryColorAttrId: undefined,
        size: undefined,
        serialNumber: undefined,
        itemMake: undefined,
        itemModel: undefined,
    },
    vehicle: {
        itemCategoryAttrId: undefined,
        vehicleMakeAttrId: undefined,
        vehicleModelAttrId: undefined,
        vehicleMakeId: undefined,
        vehicleModelId: undefined,
        itemMake: undefined,
        itemModel: undefined,
        description: undefined,
        vinNumber: undefined,
        tag: undefined,
        yearOfManufacture: undefined,
        primaryColorAttrId: undefined,
        secondaryColorAttrId: undefined,
        bodyStyleAttrId: undefined,
        bodyStyleOther: undefined,
        registrationYear: undefined,
        registrationStateAttrId: undefined,
        registrationType: undefined,
        mileage: undefined,
        insuranceProviderName: undefined,
        insurancePolicyNumber: undefined,
        insuranceCode: undefined,
        dateInsuranceExpiration: undefined,
        wasAntifreezeInVehicle: undefined,
        wasRadiatorTaggedAndDrained: undefined,
        vehicleModelHasBodyStyleOptions: undefined,
    },
    sharedItemDetails: {
        isBiohazard: undefined,
        biohazardDescription: undefined,
        firearmQuantity: undefined,
        drugQuantity: undefined,
        drugQuantityTwo: undefined,
        drugQuantityThree: undefined,
        otherItemQuantity: undefined,
        isQuantityUnknown: undefined,
        measurementUnitsAttrId: undefined,
        measurementUnitsTwoAttrId: undefined,
        measurementUnitsThreeAttrId: undefined,
        returnToOwnerAttrId: undefined,
        itemIdentifiers: [],
        drugDataSourceAttrId: undefined,
    },
    nameItemLinks: [],
    propertyStatuses: [],
    attributeLinks: {
        modelNumberLocationAttrIds: [],
        serialNumberLocationAttrIds: [],
        vehicleAdditionalSecondaryColorAttrIds: [],
        vehicleUseAttrId: [],
    },
    isImpounded: undefined,
    impoundInfo: {
        towingCompanyAttrId: undefined,
        towingCompanyOther: undefined,
        towingNumber: undefined,
        towingLocation: undefined,
        wasVehicleSearched: undefined,
        wasVehicleSearchConsentedTo: undefined,
        towAuthorizedByAttrId: undefined,
    },
    isInPoliceCustody: undefined,
    custodyStatus: {
        reasonForPoliceCustodyAttrId: undefined,
        storageFacility: undefined,
        storageLocation: undefined,
        storageLocationId: undefined,
        policeCustodyTypeEntityType: undefined,
        infieldTransferByOfficerId: undefined,
        exhibitNumber: undefined,
    },
    recoveryInfo: {
        recoveredByOfficerId: undefined,
        recoveredByOtherName: undefined,
        recoveredDateUtc: undefined,
        collectedFromEntityType: undefined,
        collectedVehicleRegistrationNumber: undefined,
        collectedVehicleStateAttrId: undefined,
        locationEntityLink: {
            locationId: undefined,
            positionAttrId: undefined,
            description: undefined,
        },
        intakePerson: undefined,
        statementOfFacts: undefined,
        ownerNotified: undefined,
        notifiedDateUtc: undefined,
        notifierName: undefined,
    },
    itemLocations: {
        otherLocations: [],
    },
};

export function getHydratedItemForm() {
    return formsRegistry.get(RefContextEnum.FORM_HYDRATED_ITEM.name);
}

export function convertToRecoveryInfoFormModel(hydratedItem, baseCombinedPropertyStatus) {
    const locationEntityLink = chain(hydratedItem.locations)
        .map('entityLinks')
        .flatten()
        .filter({ linkType: LinkTypesEnum.PROPERTY_RECOVERED_LOCATION })
        .orderBy(['updatedDateUtc'], ['desc'])
        .first()
        .value();

    const recoveryInfo = pick(baseCombinedPropertyStatus, recoveryInfoFields);

    if (!recoveryInfo.recoveredByOfficerId && recoveryInfo.recoveredByOtherName) {
        recoveryInfo.recoveredByOfficerId = otherUserId;
    }

    // Just store the whole entityLink into the form.
    // This makes things less confusing, because then
    // we don't have to worry about partially storing the entityLink
    // when the item exists, but fully storing the entityLink
    // if the item doesn't exist
    return {
        ...recoveryInfo,
        locationEntityLink,
    };
}

export function convertToItemLocationsFormModel(hydratedItem) {
    const filteredOtherLocations = flatMap(hydratedItem.locations, 'entityLinks').filter(
        (location) => location.linkType !== LinkTypesEnum.PROPERTY_RECOVERED_LOCATION
    );

    const otherLocations = orderBy(filteredOtherLocations, ['updatedDateUtc'], ['asc']);

    return {
        otherLocations,
    };
}

/**
 * Convert hydrated item and item facility links to hydrated item form
 * @param {Object} hydratedItem
 * @param {Object[]} itemFacilityLinks
 * @param {string} operation
 * @return {Object}
 */
export function convertToFormModel(hydratedItem, itemFacilityLinks, operation) {
    const item = first(hydratedItem.items);
    const itemType = get(item, 'itemTypeAttrId');
    const exhibitNumber = get(item, 'exhibitNumber');

    const dedupedPropertyStatuses = deduplicatePropertyStatuses(hydratedItem.propertyStatuses);
    const propertyStatus = mergePropertyStatuses(dedupedPropertyStatuses);

    const rawItemFacilityLink = chain(itemFacilityLinks)
        .orderBy(['updatedDateUtc'], ['desc'])
        .first()
        .value();
    const itemFacilityLink = pick(rawItemFacilityLink || {}, itemFacilityLinkFields);
    const custodyStatus = {
        ...pick(propertyStatus, custodyStatusFields),
        ...itemFacilityLink,
        exhibitNumber,
    };

    const impoundInfo = pick(propertyStatus, impoundInfoFields);

    const isInPoliceCustody = get(propertyStatus, 'isInPoliceCustody');

    let isImpounded = get(propertyStatus, 'isImpounded');
    if (itemType === globalAttributes.itemType.vehicle && !isImpounded) {
        isImpounded = false;
    }

    // full models passed into form
    const nameItemLinks = map(hydratedItem.nameItemLinks, (nameItemLink) =>
        pick(nameItemLink, nameItemLinkFields)
    );

    const propertyStatuses = map(dedupedPropertyStatuses, (propertyStatus) => ({
        ...pick(propertyStatus, propertyStatusesFields),
        declaredValue: formatDeclaredValueAndForfeitureValueInFormModel(
            propertyStatus.declaredValue
        ),
        forfeitureValue: formatDeclaredValueAndForfeitureValueInFormModel(
            propertyStatus.forfeitureValue
        ),
    }));

    let sharedItemDetails = {
        ...pick(propertyStatus, sharedItemDetailFieldsFromPropertyStatus),
        ...pick(item, sharedItemDetailFieldsFromItemProfile),
        itemIdentifiers: map(hydratedItem.itemIdentifiers, (itemIdentifier) =>
            pick(itemIdentifier, itemIdentifierFields)
        ),
    };
    let itemModel = {};

    switch (itemType) {
        case globalAttributes.itemType.vehicle:
            const vehicle = first(hydratedItem.vehicles) || {};

            const selectedVehicleFields = pick(vehicle, vehicleFields);
            const selectedVehicleFieldsFromItemProfile = pick(item, vehicleFieldsFromItemProfile);

            const { vehicleMakeId = OTHER_OPTION_VALUE, vehicleModelId = OTHER_OPTION_VALUE } =
                selectedVehicleFields;
            itemModel = {
                vehicle: {
                    ...selectedVehicleFields,
                    ...selectedVehicleFieldsFromItemProfile,
                    vehicleMakeId,
                    vehicleModelId,
                },
                attributeLinks: convertAttributeLinksToFormModel(hydratedItem.attributeLinks, {
                    vehicleCautionAttrIds: [],
                    vehicleAdditionalSecondaryColorAttrIds: [],
                    vehicleCautionDescription: undefined,
                    vehicleUseAttrId: [],
                }),
                cautions: hydratedItem.cautions.map((caution) => pick(caution, cautionFormFields)),
            };

            break;
        case globalAttributes.itemType.firearm:
            const firearm = first(hydratedItem.firearms) || {};
            const selectedFirearmFields = pick(firearm, firearmFields);
            const selectedFirearmFieldsFromItemProfile = pick(item, firearmFieldsFromItemProfile);

            itemModel = {
                firearm: {
                    ...selectedFirearmFields,
                    ...selectedFirearmFieldsFromItemProfile,
                    serialNumberUnknown:
                        isUndefinedOrNull(get(item, 'serialNumber')) && operation !== DUPLICATE
                            ? true
                            : undefined,
                },
                attributeLinks: convertAttributeLinksToFormModel(hydratedItem.attributeLinks, {
                    modelNumberLocationAttrIds: [],
                    serialNumberLocationAttrIds: [],
                }),
            };
            sharedItemDetails = {
                ...sharedItemDetails,
                firearmQuantity: propertyStatus.quantity,
            };
            break;
        case globalAttributes.itemType.drugs:
            itemModel = {
                drugs: {
                    ...pick(item, drugsFields),
                    ...pick(propertyStatus, sharedDrugsFieldsFromPropertyStatus),
                },
            };

            const attributeLinks = convertAttributeLinksToFormModel(hydratedItem.attributeLinks, {
                drugDataSourceAttrId: [],
            });
            const drugDataSourceAttrId = first(get(attributeLinks, 'drugDataSourceAttrId'));

            sharedItemDetails = {
                ...sharedItemDetails,
                drugQuantity: propertyStatus.quantity,
                drugQuantityTwo: propertyStatus.propertyStatusQuantities?.quantityTwo,
                drugQuantityThree: propertyStatus.propertyStatusQuantities?.quantityThree,
                measurementUnitsTwoAttrId:
                    propertyStatus.propertyStatusQuantities?.measurementUnitsTwoAttrId,
                measurementUnitsThreeAttrId:
                    propertyStatus.propertyStatusQuantities?.measurementUnitsThreeAttrId,
                drugDataSourceAttrId,
            };

            break;
        case globalAttributes.itemType.item:
            itemModel = {
                otherItem: pick(item, otherItemFields),
            };
            sharedItemDetails = {
                ...sharedItemDetails,
                otherItemQuantity: propertyStatus.quantity,
            };
            break;
        default:
            break;
    }

    return {
        custodyStatus,
        impoundInfo,
        isInPoliceCustody,
        isImpounded,
        itemTypeAttrId: itemType,
        nameItemLinks,
        propertyStatuses,
        recoveryInfo: convertToRecoveryInfoFormModel(hydratedItem, propertyStatus),
        sharedItemDetails,
        itemLocations: convertToItemLocationsFormModel(hydratedItem),
        ...itemModel,
    };
}

function convertQuantityFromFormModel({
    itemTypeAttrId,
    sharedItemDetails = {},
    propertyStatusFromState = {},
}) {
    switch (itemTypeAttrId) {
        case globalAttributes.itemType.vehicle:
            return propertyStatusFromState.quantity || 1;
        case globalAttributes.itemType.firearm:
            return sharedItemDetails.firearmQuantity;
        case globalAttributes.itemType.drugs:
            return sharedItemDetails.drugQuantity;
        case globalAttributes.itemType.item:
        default:
            return sharedItemDetails.otherItemQuantity;
    }
}

export function convertPropertyStatusQuantitiesFromFormModel({
    itemTypeAttrId,
    sharedItemDetails = {},
}) {
    if (itemTypeAttrId !== globalAttributes.itemType.drugs) {
        return undefined;
    }
    return {
        quantityTwo: sharedItemDetails.drugQuantityTwo,
        quantityThree: sharedItemDetails.drugQuantityThree,
        measurementUnitsTwoAttrId: sharedItemDetails.measurementUnitsTwoAttrId,
        measurementUnitsThreeAttrId: sharedItemDetails.measurementUnitsThreeAttrId,
    };
}

/**
 * Convert form model to property statuses.
 *   Generates property status model from relevant fields in form.
 *
 *   Offense linking:
 *     Removes offense link if property status type is changed or deleted.
 *     Otherwise, maintains any existing offense links.
 * @param {Object}   formModel
 * @param {Object}   [stateData.propertyStatuses]
 * @return {Object[]}
 */
export function convertFromFormModelToPropertyStatuses(formModel, stateData) {
    return reduce(
        filterFormData(formModel.propertyStatuses),
        (propertyStatuses, propertyStatusFromForm) => {
            const propertyStatusFromState =
                find(stateData.propertyStatuses, {
                    id: propertyStatusFromForm.id,
                }) || {};
            const duplicatePropertyStatuses = filter(
                stateData.propertyStatuses,
                ({ id, propertyStatusAttrId }) =>
                    propertyStatusAttrId === propertyStatusFromState.propertyStatusAttrId &&
                    id !== propertyStatusFromForm.id
            );
            const nonFormPropertyStatusPropsFromState = omit(propertyStatusFromState, [
                ...versionableBaseFieldKeys,
                ...propertyStatusesFields,
                ...custodyStatusFields,
                ...recoveryInfoFields,
                ...impoundInfoFields,
            ]);
            const recoveryInfoForm = chain(formModel.recoveryInfo)
                .pick(recoveryInfoFields)
                .thru((recoveryInfo) =>
                    recoveryInfo.recoveredByOfficerId === otherUserId
                        ? omit(recoveryInfo, 'recoveredByOfficerId')
                        : recoveryInfo
                )
                .value();
            // TODO add RuleConditions to CUSTODY_WRAPPER_HIDDEN_IF_ITEM_NOT_IN_CUSTODY before we
            // can remove this check on isInPoliceCustody
            const custodyStatusForm = formModel.isInPoliceCustody
                ? omit(formModel.custodyStatus, itemFacilityLinkFields)
                : {};

            const impoundInfoForm = pick(formModel.impoundInfo, impoundInfoFields);

            const statusTypeChanged =
                propertyStatusFromState &&
                propertyStatusFromState.propertyStatusAttrId !==
                    propertyStatusFromForm.propertyStatusAttrId;

            const propertyStatus = {
                ...nonFormPropertyStatusPropsFromState,
                ...propertyStatusFromForm,
                ...custodyStatusForm,
                ...recoveryInfoForm,
                ...impoundInfoForm,
                isInPoliceCustody: formModel.isInPoliceCustody,
                isImpounded: formModel.isImpounded,
                ...pick(formModel.sharedItemDetails, sharedItemDetailFieldsFromPropertyStatus),
                ...pick(formModel.drugs, sharedDrugsFieldsFromPropertyStatus),
                quantity: convertQuantityFromFormModel({
                    itemTypeAttrId: formModel.itemTypeAttrId,
                    sharedItemDetails: formModel.sharedItemDetails,
                    propertyStatusFromState,
                }),
                propertyStatusQuantities: convertPropertyStatusQuantitiesFromFormModel({
                    itemTypeAttrId: formModel.itemTypeAttrId,
                    sharedItemDetails: formModel.sharedItemDetails,
                }),
                ...(statusTypeChanged ? { offenseId: undefined } : {}),
            };

            propertyStatuses.push(propertyStatus);

            if (duplicatePropertyStatuses.length > 0 && !statusTypeChanged) {
                forEach(duplicatePropertyStatuses, ({ id, offenseId }) =>
                    propertyStatuses.push({ ...propertyStatus, id, offenseId })
                );
            }

            return propertyStatuses;
        },
        []
    );
}

/**
 * Convert form model to attribute links.
 *   Generates attribute links model from relevant fields in form.
 *
 * @param {Object}   attributeLinks
 * @param {Object[]}   attributes // Attribute[]
 * @return {Object[]}
 */
export const convertFromFormModelToAttributeLinks = (attributeLinks, attributes = []) =>
    Object.entries(attributeLinks || {})
        .filter(([attributePath]) => attributePath in acceptableAttributeLinksFieldsMap)
        .flatMap(([attributePath, attributeIds]) => {
            const attributeType = attributePathTypeMap[attributePath];
            return (isNumber(attributeIds) ? [attributeIds] : attributeIds || []).map(
                (attributeId) => {
                    const attribute = attributes.find((item) => item.id === attributeId);
                    if (attribute && attribute.isOther) {
                        const otherPropertyName =
                            attributeTypePathToOtherDescription[attributeType];
                        return {
                            attributeType,
                            attributeId,
                            description: attributeLinks[otherPropertyName],
                        };
                    }
                    return {
                        attributeType,
                        attributeId,
                    };
                }
            );
        });

/**
 * Mutatively adds all `attributeLinks` ids or descriptions to the correct list on `formModelAttributeLinksToPopulate`. Skips attribute links if their
 * configured target property cannot be found.
 */
const convertAttributeLinksToFormModel = (
    attributeLinks = [],
    formModelAttributeLinksToPopulate
) => {
    return attributeLinks.reduce((obj, { attributeType, attributeId, description }) => {
        const key = attributeTypePathMap[attributeType];
        const existingValue = obj[key];
        if (existingValue) {
            obj[key] = [...obj[key], attributeId];
        }

        const descriptionKey = attributeTypePathToOtherDescription[attributeType];
        // Descriptions are only used for "other" values, which are always connected to an attribute.
        // Because of this we only set description values if the attribute was also found.
        if (existingValue && descriptionKey && !!description) {
            obj[descriptionKey] = description;
        }

        return obj;
    }, formModelAttributeLinksToPopulate);
};

const prepareItemForStandaloneUpdate = (item) => {
    const { masterItemId, ...restItemFields } = item;

    return {
        ...restItemFields,
        itemId: masterItemId,
        id: masterItemId,
        ownerId: masterItemId,
    };
};

/**
 * Convert hydrated item form model to hydrated item payload
 * @param {Object}   hydratedItemFormModel
 * @param {Object}   [stateData.firearm]
 * @param {Object[]} [stateData.itemIdentifiers]
 * @param {Object}   [stateData.itemProfile]
 * @param {number}   [stateData.itemProfileId]
 * @param {number}   [stateData.masterItemId]       If provided, overrides the masterItemId in itemProfile.
 * @param {string}   [stateData.ownerType]          If provided, overrides the ownerType in itemProfile.
 * @param {number}   [stateData.ownerId]            If provided, overrides the ownerId in itemProfile.
 * @param {Object}   [stateData.locationEntityLink]
 * @param {Object[]} [stateData.propertyStatuses]
 * @param {Object}   [stateData.vehicle]
 * @param {Object}   [stateData.vehicleCautionAttributes] type: AttributeT[]
 * @return {Object}
 */
export function convertFromFormModelToHydratedItem(formModel, stateData = {}) {
    const itemIdentifiers = map(
        filter(
            filterFormData(formModel.sharedItemDetails.itemIdentifiers),
            (o) => o.identifier !== undefined
        ),
        (itemIdentifierFromForm) => {
            const itemIdentifierFromState =
                find(stateData.itemIdentifiers, { id: itemIdentifierFromForm.id }) || {};
            return assign({}, itemIdentifierFromState, itemIdentifierFromForm);
        }
    );

    // Blank out all the 'other' fields if we have an 'other' or 'DL' attribute selected
    const nameItemLinks = filterFormData(
        map(formModel.nameItemLinks, (nameItemLink) => ({
            ...nameItemLink,
            itemProfileId: stateData.itemProfileId,
        }))
    );

    const { itemTypeAttrId } = formModel;
    const exhibitNumber = get(formModel, 'custodyStatus.exhibitNumber');
    const sharedDetails = pick(formModel.sharedItemDetails, sharedItemDetailFieldsFromItemProfile);
    let itemModel = {};
    const ownerData = {
        masterItemId: stateData.masterItemId || -1,
        ...pick(stateData, ['ownerType', 'ownerId']),
    };
    if (!!exhibitNumber) {
        ownerData.exhibitNumber = exhibitNumber;
    }

    const isStandaloneUpdate =
        stateData.ownerType === EntityTypeEnum.ITEM_PROFILE.name &&
        stateData.operation === itemSidePanelOperationEnum.EDIT;

    switch (itemTypeAttrId) {
        case globalAttributes.itemType.vehicle:
            const { cautions = [] } = formModel;
            const { vehicleMakeId, vehicleModelId } = formModel.vehicle;
            const makeModel = {
                vehicleMakeId: vehicleMakeId === OTHER_OPTION_VALUE ? undefined : vehicleMakeId,
                vehicleModelId: vehicleModelId === OTHER_OPTION_VALUE ? undefined : vehicleModelId,
            };

            let vehicle = {
                ...get(stateData, 'vehicle', {}),
                ...sharedDetails,
                ...formModel.vehicle,
                ...makeModel,
                itemTypeAttrId,
                ...ownerData,
            };

            if (isStandaloneUpdate) {
                vehicle = prepareItemForStandaloneUpdate(vehicle);
            }

            itemModel = {
                vehicles: [vehicle],
                attributeLinks: convertFromFormModelToAttributeLinks(
                    formModel.attributeLinks,
                    stateData.vehicleCautionAttributes
                ),
                cautions: convertCautionsFromFormModel(
                    cautions,
                    EntityTypeEnum.VEHICLE.name,
                    stateData.itemProfileId
                ),
            };
            break;
        case globalAttributes.itemType.firearm:
            let firearm = {
                ...get(stateData, 'firearm', {}),
                ...sharedDetails,
                ...formModel.firearm,
                itemModel:
                    formModel.firearm.atfManufacturerId && !formModel.firearm.itemModel
                        ? ''
                        : formModel.firearm.itemModel,
                firearmMakeAttrId: formModel.firearm.atfManufacturerId
                    ? undefined
                    : formModel.firearm.firearmMakeAttrId,
                itemTypeAttrId,
                ...ownerData,
                serialNumberUnknown: undefined,
            };

            if (isStandaloneUpdate) {
                firearm = prepareItemForStandaloneUpdate(firearm);
            }

            itemModel = {
                firearms: [firearm],
                attributeLinks: convertFromFormModelToAttributeLinks(formModel.attributeLinks),
            };

            break;
        case globalAttributes.itemType.drugs:
            let drug = {
                ...get(stateData, 'itemProfile', {}),
                ...sharedDetails,
                ...formModel.drugs,
                itemTypeAttrId,
                ...ownerData,
            };

            if (isStandaloneUpdate) {
                drug = prepareItemForStandaloneUpdate(drug);
            }

            itemModel = {
                items: [drug],
                attributeLinks: convertFromFormModelToAttributeLinks(formModel.sharedItemDetails),
            };

            break;
        case globalAttributes.itemType.item:
            let item = {
                ...get(stateData, 'itemProfile', {}),
                ...sharedDetails,
                ...formModel.otherItem,
                itemTypeAttrId,
                ...ownerData,
            };

            if (isStandaloneUpdate) {
                item = prepareItemForStandaloneUpdate(item);
            }

            itemModel = {
                items: [item],
            };
            break;
        default:
            break;
    }
    return {
        itemIdentifiers,
        nameItemLinks,
        propertyStatuses: convertFromFormModelToPropertyStatuses(formModel, stateData),
        ...itemModel,
    };
}

/**
 * Convert hydrated item form model to item facility links. This always returns either 0 links or 1
 *   link because the form either hides the Drop-Off Location field or allows the user to fill in 1
 *   Drop-Off Location for a contexted item regardless of how many propertyStatuses it has.
 * @param {Object}   hydratedItemFormModel
 * @param {number}   stateData.facilityId
 * @param {Object[]} [stateData.itemFacilityLinks] All existing itemFacilityLinks for this
 *   itemProfileId.
 * @param {number}   stateData.itemProfileId
 * @param {number[]} stateData.propertyStatusIds   There must be at least 1 id because this helper
 *   should be called after propertyStatuses are created/updated. When there are multiple ids, the
 *   order matters in choosing which status to pick. The order doesn't actually matter for business
 *   requirements, only for having predictable data.
 * @return {Object[]}
 */
export function convertFromFormModelToItemFacilityLinks(formModel, stateData = {}) {
    const { custodyStatus } = formModel;
    const { facilityId, itemFacilityLinks, itemProfileId, propertyStatusIds = [] } = stateData;

    return !get(custodyStatus, 'storageLocationId')
        ? []
        : chain(propertyStatusIds)
              .reduce(
                  (link, propertyStatusId) => link || find(itemFacilityLinks, { propertyStatusId }),
                  undefined
              )
              .thru(
                  (itemFacilityLink) =>
                      itemFacilityLink || {
                          propertyStatusId: propertyStatusIds[0],
                      }
              )
              .thru((itemFacilityLink) => ({
                  ...itemFacilityLink,
                  ...pick(custodyStatus, itemFacilityLinkFields),
                  facilityId,
                  itemProfileId,
              }))
              .thru((itemFacilityLink) => [itemFacilityLink])
              .value();
}

export function convertFromFormModelToAllLocationEntityLinks(formModel) {
    const recoveryLocationLink = convertFromFormModelToLocationEntityLinks(formModel);
    const otherLocationsFromModel = get(formModel, 'itemLocations.otherLocations');
    const otherLocationLinks = !otherLocationsFromModel
        ? []
        : filter(otherLocationsFromModel, (link) => link.locationId);
    return concat(otherLocationLinks, recoveryLocationLink);
}

export function convertFromFormModelToLocationEntityLinks(formModel) {
    // The link is only valid if we have a `locationId`
    return get(formModel, 'recoveryInfo.locationEntityLink.locationId')
        ? [get(formModel, 'recoveryInfo.locationEntityLink')]
        : [];
}

/**
 * Return a duplicate copy of the given hydrated item. The duplicate will be used to create a new
 *   hydrated item with no relation to the existing hydrated item, so the itemProfileId or other
 *   uniquely identifying ids are cleared from all the entities and links.
 *
 * A vehicle cannot be duplicated.
 *
 * For the remaining 3 item types, locations, nameItemLinks, and propertyStatuses are always
 *   duplicated.
 *
 * Offense links will be removed.
 *
 * For a drug, the itemProfile is also duplicated.
 *
 * For an other item, the itemProfile is also duplicated, except for serialNumber.
 * @param  {Object} hydratedItem
 * @param  {Object} stateData
 * @return {Object}
 */
export function convertHydratedItemToDuplicate(hydratedItem = {}) {
    const itemTypeAttrId = get(hydratedItem.items, '[0].itemTypeAttrId');

    const locations = map(hydratedItem.locations, (locationView) => {
        return {
            ...locationView,
            id: undefined,
            entityLinks: mapOmit(locationView.entityLinks, [
                ...versionableBaseFieldKeys,
                'entityId',
            ]),
        };
    });
    const nameItemLinks = mapOmit(hydratedItem.nameItemLinks, [
        ...versionableBaseFieldKeys,
        'itemProfileId',
    ]);

    const dedupedPropertyStatuses = deduplicatePropertyStatuses(hydratedItem.propertyStatuses);
    const propertyStatuses = mapOmit(dedupedPropertyStatuses, [
        ...versionableBaseFieldKeys,
        'itemProfileId',
        'offenseId',
    ]);

    switch (itemTypeAttrId) {
        case globalAttributes.itemType.firearm:
            return {
                items: [{ itemTypeAttrId: globalAttributes.itemType.firearm }],
                locations,
                nameItemLinks,
                propertyStatuses,
            };
        case globalAttributes.itemType.drugs:
            return {
                items: mapOmit(hydratedItem.items, [
                    ...versionableBaseFieldKeys,
                    'masterItemId',
                    'ownerType',
                    'ownerId',
                    'returnToOwnerAttrId',
                    'itemMake',
                    'primaryColorAttrId',
                    'exhibitNumber',
                ]),
                locations,
                nameItemLinks,
                propertyStatuses,
            };
        case globalAttributes.itemType.item:
            return {
                items: mapOmit(hydratedItem.items, [
                    ...versionableBaseFieldKeys,
                    'masterItemId',
                    'ownerType',
                    'ownerId',
                    'serialNumber',
                    'returnToOwnerAttrId',
                    'exhibitNumber',
                ]),
                locations,
                nameItemLinks,
                propertyStatuses,
            };
        default:
            return {};
    }
}
