import React from "react";

import strings from '~/client-common/core/strings/componentStrings';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import testIds from '../../../../core/testIds';

const reportContentStrings = strings.recordPrivacy.sealing.ReportContentSection;
const helpString = strings.recordPrivacy.shared.ReportSealingFormFields.isSealReport.helpText;

interface PartialSealingReportContentSectionProps {
    handleReportSealClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PartialSealingReportContentSection = (props: PartialSealingReportContentSectionProps) => {
    return (
        <RRFCheckbox
            onChange={props.handleReportSealClick}
            path="isSealReport"
            label={reportContentStrings.boxLabel}
            helpText={helpString}
            testId={testIds.SEAL_CONTENTS_BUTTON}
            customStyles={{ marginTop: '20px' }}
        />
    );
}

export default PartialSealingReportContentSection;