import React from 'react';
import styled from 'styled-components';
import { Flex } from '@arc/layout';
import { Avatar } from '@arc/avatar';
import { Text } from '@arc/typography';
import { DiagramCategoryEnumType, DiagramCategoryEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { useScreenBreakpoint } from '../../../../../../core/utils/useScreenBreakpoint';

const strings = componentStrings.dragon.crashDiagram.CrashDiagramModal;

type Props = {
    onClick: (assetType: DiagramCategoryEnumType) => void;
};

const SelectedBackgroundThumbnail = styled(Avatar)`
    filter: brightness(80%) contrast(160%);
    margin-left: var(--arc-space-1);
`;

export const TemplateThumbnail: React.FC<Props> = ({ onClick: onOuterClick }) => {
    const { backgroundImage } = useCrashDiagram();
    const { isMobile } = useScreenBreakpoint();

    const onClick = () => {
        onOuterClick(DiagramCategoryEnum.CRASH_BACKGROUND.name);
    };

    const displayButtonText = !isMobile && backgroundImage;
    return (
        <Flex justifyContent="end" alignItems="center">
            {displayButtonText && (
                <Text variant="caption" fontWeight="bold">
                    {strings.streetLayersButtonText}
                </Text>
            )}

            {backgroundImage && (
                <SelectedBackgroundThumbnail
                    size="sm"
                    src={backgroundImage}
                    ignoreFallback={true}
                    onClick={onClick}
                />
            )}
        </Flex>
    );
};
