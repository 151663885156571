import React, { FC } from 'react';
import {
    FederatedSearchVehicle,
    FederatedSearchBoat,
    FederatedSearchFirearm,
} from 'mark43-federated-search';

import OverlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import CoreButton from '../../../../../legacy-redux/components/core/Button';
import { OverlayBaseHelper } from '../../../../core/components/OverlayBaseHelper';
import Modal from '../../../../core/overlays/components/Modal';
import { buttonTypes } from '../../../../core/overlays/components/OverlayButton';

const strings = componentStrings.reports.core.ItemSidePanel;

type OverrideItemProfileModalProps = {
    handleOverrideProfile: (
        dexItems: (FederatedSearchVehicle | FederatedSearchBoat)[] | FederatedSearchFirearm[],
        rmsProfileId: number
    ) => Promise<void>;
    handleCancelOverrideProfile: (rmsProfileId: number) => Promise<void>;
    entity: string;
};

export const OverrideItemProfileModal: FC<OverrideItemProfileModalProps> = ({
    handleOverrideProfile,
    handleCancelOverrideProfile,
    entity,
}) => {
    return (
        <OverlayBaseHelper<{
            dexItems: (FederatedSearchVehicle | FederatedSearchBoat)[] | FederatedSearchFirearm[];
            rmsProfileId: number;
        }>
            id={OverlayIdEnum.OVERRIDE_ITEM_PROFILE_MODAL}
        >
            {({ overlayBase }) => {
                const { dexItems, rmsProfileId } = overlayBase.overlayState.customProperties;
                const { close } = overlayBase;

                return (
                    <Modal
                        id={OverlayIdEnum.OVERRIDE_ITEM_PROFILE_MODAL}
                        title={strings.overrideItemProfileModalTitle(entity)}
                        okText={strings.overrideItemProfileBtnLabel}
                        onSave={() => handleOverrideProfile(dexItems, rmsProfileId)}
                        cancelText={null}
                        additionalFooterContent={
                            <CoreButton
                                className={buttonTypes.SECONDARY}
                                onClick={() => {
                                    close();
                                    handleCancelOverrideProfile(rmsProfileId);
                                }}
                            >
                                {strings.overrideItemProfileCancelBtnLabel}
                            </CoreButton>
                        }
                    >
                        {strings.overrideItemProfileModalBody(entity)}
                    </Modal>
                );
            }}
        </OverlayBaseHelper>
    );
};
