import React from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';
import NoDataBlock from '../../../core/components/NoDataBlock';
import ElasticModelWrapper from '../../../../legacy-redux/components/core/tables/ElasticModelWrapper';
import {
    profilePersonsViewModelsSelector,
    sortedProfileOrganizationsViewModelsSelector,
} from '../state/ui';
import EntityProfileSection from './EntityProfileSection';
import EntityProfileSummaryWrapper from './EntityProfileSummaryWrapper';
import EntityProfilePersonSummary from './EntityProfilePersonSummary';
import EntityProfileOrganizationSummary from './EntityProfileOrganizationSummary';

const strings = componentStrings.entityProfiles.EntityProfileRelationships;

function EntityProfileRelationships({ profilePersons, profileOrganizations }) {
    const personComponents = map(profilePersons, (profilePerson) => (
        <ElasticModelWrapper key={profilePerson.id} entity={profilePerson}>
            {({ hasReadAccess, entity }) => (
                <EntityProfileSummaryWrapper
                    linkUrl={`/profiles/persons/${entity.id}`}
                    hasReadAccess={hasReadAccess && !profilePerson.isUnborn}
                >
                    <EntityProfilePersonSummary elasticPerson={entity} />
                </EntityProfileSummaryWrapper>
            )}
        </ElasticModelWrapper>
    ));
    const organizationComponents = map(profileOrganizations, (profileOrganization) => (
        <ElasticModelWrapper key={profileOrganization.id} entity={profileOrganization}>
            {({ hasReadAccess, entity }) => (
                <EntityProfileSummaryWrapper
                    linkUrl={`/profiles/organizations/${entity.id}`}
                    hasReadAccess={hasReadAccess}
                >
                    <EntityProfileOrganizationSummary elasticOrganization={entity} />
                </EntityProfileSummaryWrapper>
            )}
        </ElasticModelWrapper>
    ));
    const organizationLabel = useFields(DISPLAY_ONLY_ORGANIZATION_LABEL)[
        DISPLAY_ONLY_ORGANIZATION_LABEL
    ];
    const contents =
        profilePersons.length || profileOrganizations.length ? (
            <div>
                {profilePersons.length ? (
                    <EntityProfileSection title={strings.personsTitle}>
                        {personComponents}
                    </EntityProfileSection>
                ) : undefined}
                {profileOrganizations.length ? (
                    <EntityProfileSection title={strings.organizationsTitle(organizationLabel)}>
                        {organizationComponents}
                    </EntityProfileSection>
                ) : undefined}
            </div>
        ) : (
            <div className="no-data-container">
                <NoDataBlock>{strings.noRelationshipsText}</NoDataBlock>
            </div>
        );
    return <div>{contents}</div>;
}

const mapStateToProps = createStructuredSelector({
    profilePersons: profilePersonsViewModelsSelector,
    profileOrganizations: sortedProfileOrganizationsViewModelsSelector,
});

export default connect(mapStateToProps)(EntityProfileRelationships);
