import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Tile } from 'arc';
import styled from 'styled-components';

import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { PropertyProfileImage } from '../../../../core/components/ProfileImage';
import { iconSizes } from '../../../../core/components/Icon';
import { PropertyFormData } from './helpers';

const strings = componentStrings.core.ItemPillSmall;

const StyledTile = styled(Tile)`
    & .chakra-text {
        white-space: pre-wrap;
        display: block;
    }
`;

type PropertyPillSmallProps = {
    onClick: () => void;
    property: PropertyFormData;
};

export const PropertyPillSmall: FC<PropertyPillSmallProps> = ({ onClick, property }) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const propertyType = formatAttributeById(property.itemCategoryAttrId);

    return (
        <StyledTile
            onMouseDown={onClick}
            title={joinTruthyValues([propertyType, property.itemModel], ' - ')}
            description={`${strings.serialNumber} ${
                property.serialNumber || strings.epmtySerialNumber
            }`}
            media={
                <PropertyProfileImage iconSize={iconSizes.LARGE} size="40px" url={property.img} />
            }
        />
    );
};
