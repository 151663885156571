import React from 'react';
import styled from 'styled-components';

import {
    HStack,
    VStack,
    Box,
    Divider,
    cssVar,
    Text as ArcText,
    Drawer,
    DrawerContent,
    DrawerBody,
    DrawerHeader,
    useBreakpointValue,
    Popover,
    PopoverTrigger,
    PopoverContent,
} from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../../core/testIds';
import { NavItemToggle } from '../../../../core/components/Navigation/components';

// this url is hardcoded for demo purposes
// how it will be configured by tenant or environment in the future is to be determined
const CHATBOT_URL = 'https://cq3c04nt.chat.qbusiness.us-east-1.on.aws';

const strings = componentStrings.chatbot;

const StyledPopoverContent = styled(PopoverContent)`
    width: 40rem;
    height: calc(100vh - 2rem);
`;

const IFrame = styled.iframe`
    width: 100%;
    height: 100%;
`;

const Header = styled(HStack)`
    justify-content: space-between;
    padding: ${cssVar('arc.space.2')} ${cssVar('arc.space.3')};
`;

const StyledDrawerHeader = styled(DrawerHeader)`
    justify-content: space-between;
    padding-right: var(--arc-space-12);
    min-height: 4rem;
    align-items: center;
    display: flex;
`;

const StyledDrawerBody = styled(DrawerBody)`
    padding: 0;
`;

export function ChatbotNavItem(): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);

    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });

    // Resuable content pieces to be used in render the drawer and popover
    const iframe = <IFrame src={CHATBOT_URL} />;
    const headerContent = <ArcText variant="headingSm">AI Chatbot</ArcText>;
    const toggleButton = (
        <NavItemToggle
            label={strings.navigation}
            icon="Messaging"
            data-test-id={testIds.CHATBOT_NAV_ITEM}
            onClick={() => {
                setIsOpen((prev) => !prev);
            }}
            isActive={isOpen}
        />
    );

    if (isMobile) {
        return (
            <>
                {toggleButton}
                <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
                    <DrawerContent>
                        <StyledDrawerHeader>{headerContent}</StyledDrawerHeader>
                        <StyledDrawerBody>{iframe}</StyledDrawerBody>
                    </DrawerContent>
                </Drawer>
            </>
        );
    } else {
        return (
            <Popover isOpen={isOpen}>
                <PopoverTrigger asChild>{toggleButton}</PopoverTrigger>
                <StyledPopoverContent
                    hasPadding={false}
                    side="right"
                    align="end"
                    onEscapeKeyDown={() => {
                        setIsOpen(false);
                    }}
                    data-test-id={testIds.CHATBOT_POPOVER_CONTENT}
                >
                    <VStack width="100%" height="100%" spacing={0}>
                        <Header>{headerContent}</Header>
                        <Divider />
                        <Box width="100%" height="100%">
                            {iframe}
                        </Box>
                    </VStack>
                </StyledPopoverContent>
            </Popover>
        );
    }
}
