import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { itemEvidenceStateByMasterItemIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import AttachmentsSidePanel from '../../../attachments/core/components/AttachmentsSidePanel';
import { initializeAttachmentsSidePanelForm } from '../../../attachments/core/state/ui/attachmentsSidePanel';
import { openItemSidePanel } from '../../../reports/core/state/ui/itemSidePanel';
import Header from '../../core/components/header/EntityProfileHeader';
import HeaderMasterName from '../../core/components/header/EntityProfileHeaderMasterName';
import HeaderMenu from '../../core/components/header/EntityProfileHeaderMenu';
import HeaderSecondaryInfoSection from '../../core/components/header/EntityProfileHeaderSecondaryInfoSection';
import ExternalDepartmentNameSecondaryHeaderInfo from '../../core/components/header/ExternalDepartmentSecondaryHeaderInfo';
import { currentEntityProfileIdSelector, propertyHeaderDataSelector } from '../../core/state/ui';
import HighRiskPropertyLabel from './HighRiskPropertyLabel';

function EntityProfilePropertyHeader({
    propertyHeaderData,
    ownerDepartmentName,
    entityId,
    itemEvidenceStateByMasterItemId,
}) {
    const dispatch = useDispatch();

    const { title } = propertyHeaderData;
    const itemEvidenceState = itemEvidenceStateByMasterItemId(entityId);
    const attachmentEntityLinkDetails = {
        entityId,
        entityType: EntityTypeEnum.ITEM_PROFILE.name,
        imageLinkType: LinkTypesEnum.ITEM_PROFILE_PHOTO,
        attachmentLinkType: LinkTypesEnum.ITEM_PROFILE_ATTACHMENT,
    };

    const isHighValue = itemEvidenceState?.isHighValue || itemEvidenceState?.isInHighValueContainer;

    return (
        <Header>
            <HeaderMenu
                editProfileSidePanel={(renderEditButton) => (
                    <AttachmentsSidePanel
                        getInitialCustomPropertyState={() => attachmentEntityLinkDetails}
                        overlayId={overlayIdEnum.ENTITY_PROFILE_HEADER_ATTACHMENTS_SIDE_PANEL}
                        renderButton={({ setCloseFocusRefs }) =>
                            renderEditButton({
                                open: () => {
                                    dispatch(
                                        openItemSidePanel({
                                            itemProfileId: entityId,
                                            isVehicle: false,
                                            operation: itemSidePanelOperationEnum.EDIT,
                                            ownerType: EntityTypeEnum.ITEM_PROFILE.name,
                                            alwaysHideItemTypeNavigation: true,
                                            alwaysHideDexItemsQuerySearch: true,
                                            alwaysHideItemsInlineSearch: true,
                                        })
                                    );
                                },
                                setCloseFocusRefs,
                            })
                        }
                    />
                )}
                entityName={title}
                itemType={globalAttributes.itemType.item}
            />
            <HeaderMasterName>{title}</HeaderMasterName>
            {(ownerDepartmentName || isHighValue) && (
                <HeaderSecondaryInfoSection>
                    {ownerDepartmentName && (
                        <ExternalDepartmentNameSecondaryHeaderInfo
                            ownerDepartmentName={ownerDepartmentName}
                        />
                    )}
                    {isHighValue && <HighRiskPropertyLabel />}
                </HeaderSecondaryInfoSection>
            )}
        </Header>
    );
}

const mapStateToProps = createStructuredSelector({
    propertyHeaderData: propertyHeaderDataSelector,
    entityId: currentEntityProfileIdSelector,
    itemEvidenceStateByMasterItemId: itemEvidenceStateByMasterItemIdSelector,
});

export default connect(mapStateToProps, { initializeAttachmentsSidePanelForm })(
    EntityProfilePropertyHeader
);
