/**
 * This enum contains all Abilities from {@link
 *   server-security-client/security-client/src/main/java/com/mark43/security/client/modules}.
 *
 * Keep this file alphabetical!  All entries must match Java.
 *
 * Generated via the following Java code in Modules.java:
 *
 *    public static void main(String[] args)
 *    {
 *        Reflections ref = new Reflections("com.mark43.security.client.modules", new SubTypesScanner(false));
 *        Set<Class<?>> moduleContainingClasses = ref.getSubTypesOf(Object.class);
 *
 *        System.out.println("export default {");
 *        System.out.println($.of(moduleContainingClasses).sortedBy(Class::getSimpleName).map(clazz ->
 *        {
 *            String prefix = "    " + CaseFormat.UPPER_CAMEL.to(CaseFormat.UPPER_UNDERSCORE, clazz.getSimpleName().replace("Abilities", "")) + ": {\n";
 *
 *            String moduleString = $.of(clazz.getFields())
 *                                   .filter(f -> f.getType().equals(Ability.class)).sortedBy(Field::getName)
 *                                   .map(f -> "        " + f.getName() + ": " + Class43.safeFieldGet(f, null, Ability.class).getId())
 *                                   .join(",\n");
 *
 *            return prefix + moduleString + "\n    }";
 *        }).join(",\n"));
 *        System.out.println("};");
 *    }
 *
 * @type {Object}
 */
export default {
    ADMIN: {
        ATTRIBUTE_EXPORT: 13,
        ATTRIBUTE_IMPORT: 14,
        COURTS_ADMIN: 478,
        DELETE_CASE_CONFIGURATION: 3,
        DELETE_DEPARTMENT_PERMISSION: 423,
        DELETE_GLOBAL_ROLE_CONFIGURATION: 138,
        DELETE_GLOBAL_ROLE_ENROLLMENT: 6,
        DELETE_INTERNAL_PERMISSIONING: 38,
        DELETE_LOCATION_MANAGEMENT: 25,
        EDIT_AGENCY_CONFIGURATION: 32,
        EDIT_ATTRIBUTE_CODE_TYPES: 137,
        EDIT_CASE_CONFIGURATION: 2,
        EDIT_DEPARTMENT_CONFIGURATION: 30,
        EDIT_DETENTION_DISPOSITION: 512,
        EDIT_EXPORT_RELEASE_SETTING: null, // TODO RMS-6263
        EDIT_EXTERNAL_LINKS: 312,
        EDIT_GLOBAL_ATTRIBUTE_CONFIGURATION: 8,
        EDIT_GLOBAL_ROLE_CONFIGURATION: 16,
        EDIT_GLOBAL_ROLE_ENROLLMENT: 5,
        EDIT_GLOBAL_USERS: 18,
        EDIT_GLOBAL_VALIDATION: 519,
        EDIT_INTERNAL_PERMISSIONING: 37,
        EDIT_LOCATION_MANAGEMENT: 24,
        EDIT_MENTIONS_CONFIGURATION: 110,
        VIEW_OFFENSE_CODE_CONFIGURATION: 183,
        EDIT_OFFENSE_CODE_CONFIGURATION: 10,
        EDIT_PRODUCT_MODULES: 189,
        EDIT_REPORT_CONFIGURATION: 34,
        EDIT_RELATIONSHIP_CONFIGURATION: 338,
        EDIT_SECURITY_SETTINGS: 28,
        EDIT_STATE_AUTHENTICATION_FIELDS_FOR_GLOBAL_USERS: 288,
        EDIT_SOCIETY_PROFILES: 230,
        EDIT_VALIDATION: 26,
        EVENT_LABELS_ADMIN: 367,
        FEATURE_ADMIN_ACCESS: 180,
        ID_FORMAT_CONFIGURATION: 152,
        MANAGE_CONSORTIUM: 244,
        MANAGE_MDM_ACCESS: 608,
        MANAGE_MDM_TOKEN: 609,
        MERGE_PROFILES: 223,
        OFFENSE_CODE_EXPORT: 11,
        OFFENSE_CODE_IMPORT: 12,
        REQUEST_ASSISTANCE_ADMIN: 384,
        USER_ACCOUNT_MANAGEMENT: 22,
        USER_EXPORT: 20,
        USER_IMPORT: 21,
        USER_MFA_DEVICE_MANAGEMENT: 525,
        VEHICLE_MAKE_MODEL_EXPORT: 158,
        VEHICLE_MAKE_MODEL_IMPORT: 159,
        VIEW_AGENCY_CONFIGURATION: 31,
        VIEW_ATTRIBUTE_CODE_TYPES: 136,
        VIEW_CASE_CONFIGURATION: 1,
        VIEW_DEPARTMENT_CONFIGURATION: 29,
        VIEW_EXPORT_RELEASE_CONFIGURATION: null, // TODO RMS-6263
        VIEW_GLOBAL_ATTRIBUTE_CONFIGURATION: 7,
        VIEW_GLOBAL_ROLE_CONFIGURATION: 15,
        VIEW_GLOBAL_ROLE_ENROLLMENT: 4,
        VIEW_GLOBAL_USERS: 17,
        VIEW_INTERNAL_PERMISSIONING: 36,
        VIEW_LOCATION_MANAGEMENT: 23,
        VIEW_PRODUCT_MODULES: 188,
        VIEW_REPORT_CONFIGURATION: 33,
        VIEW_SECURITY_SETTINGS: 27,
        VIEW_VALIDATION: 184,
        VIEW_MASTER_ENTITY_CONFIGURATION: 198,
        EDIT_MASTER_ENTITY_CONFIGURATION: 199,
        VIEW_WARRANT_CONFIGURATION: 200,
        EDIT_WARRANT_CONFIGURATION: 201,
        EDIT_DEPARTMENT_STATUS: 205,
        VIEW_WARRANT_ACTIVITY_MAPPING: 262,
        EDIT_WARRANT_ACTIVITY_MAPPING: 263,
        LOCATION_ALIAS_EXPORT: 268,
        LOCATION_ALIAS_IMPORT: 269,
        LOCATION_CAUTION_EXPORT: 270,
        LOCATION_CAUTION_IMPORT: 271,
        STREET_ALIAS_EXPORT: 272,
        STREET_ALIAS_IMPORT: 273,
        STREET_SEGMENT_EXPORT: 295,
        STREET_SEGMENT_IMPORT: 296,
        EDIT_EXPORT_PRESETS: 325,
        EDIT_GUIDES: 315,
        EDIT_RADIO_CHANNELS: 363,
        SAVED_SEARCH_SHARING: 377,
        RIPA_CODES_BULK_EXPORT: 526,
        RIPA_CODES_BULK_IMPORT: 527,
    },
    AML: {
        AML_GENERAL: 364,
        AML_ADMIN: 365,
    },
    ANALYSIS: {
        GENERAL: 39,
        BISUITE_EXPLORER_ANALYTICS: 302,
        BISUITE_DEMO_ANALYTICS: 303,
        BISUITE_ADMIN: 314,
    },
    JMS: {
        JMS_VIEW_BOOKING: 429,
    },
    CAD: {
        ALERT_DISPATCH_AREAS: 253,
        ASSIGN_ALL_DISPATCH_AREAS: 254,
        CAD_CLI_CONFIG_ADMIN: 204,
        CAD_ROLODEX_EDIT: 197,
        CAD_ROLODEX_VIEW: 196,
        CALL_FOR_SERVICE_EXPORT: 130,
        CALL_FOR_SERVICE_IMPORT: 131,
        CALL_FOR_SERVICES_ADMIN: 123,
        CSV_ROLODEX_EXPORT: 206,
        CSV_ROLODEX_IMPORT: 207,
        DEBUG_SUPPORT_CAD: 345,
        DELETE_ADDITIONAL_INFO: 185,
        DEPARTMENT_RESET: 145,
        DISPATCH_AREAS_ADMIN: 218,
        DUPLICATE_EVENT_SEARCH_ADMIN: 229,
        EDIT_GENERAL: 94,
        GPS_CONFIGS_ADMIN: 228,
        GPS_RETENTION_ADMIN: 491,
        HELD_EVENTS: 289,
        CALL_TAKER_STATIONS_ADMIN: 290,
        INTERNAL: 95,
        MANAGE_CAD_MESSAGING: 236,
        MANAGE_CAD_SERVICE_ROTATION: 258,
        USE_CAD_SERVICE_ROTATION: 259,
        OWN_DISPATCH_AREAS: 252,
        PREFERENCES_ADMIN: 141,
        QUEUES_WORKSPACES_ADMIN: 224,
        ROUND_TRIP: 157,
        SEARCH_ALL_USER_ACTIONS: 250,
        SEARCH_GENERAL: 92,
        SEARCH_SUPPORT: 144,
        SEND_CREATE_MESSAGES: 235,
        SEND_MESSAGES_AS_INDIVIDUAL: 245,
        SHORTCUT_KEYS_ADMIN: 216,
        STATIONS_ADMIN: 257,
        UNIT_BULK_LOGON_ADMIN: 330,
        UNIT_EXPORT: 128,
        UNIT_IMPORT: 129,
        UNIT_TIMER_POLICY_ADMIN: 360,
        UNIT_STATE_ADMIN: 151,
        UNITS_ADMIN: 142,
        UNLINK_EVENTS: 139,
        VIEW_GENERAL: 93,
        MONITOR_DISPATCH_AREAS: 282,
        RESPONSE_PLANS_ADMIN: 326,
        SOCKET_SYNC: 332,
        BOLO_VIEW_AUDIT_LOG: 358,
        EDIT_BOLOS: 355,
        EDIT_HOSPITALS: 515,
    },
    CAD_PARTNERSHIPS: {
        DUTY_ROSTER_FEED: 105,
        E911_FEED: 104,
        SANITIZED_CAD_DATA_FEED: 106,
        UNIT_LOCATIONS: 101,
    },
    CALL_TAKING: {
        ADD_TOW_VEHICLE_UNASSIGNED: 175,
        CREATE_ADDITIONAL_INFO: 177,
        CREATE_EVENT: 174,
        DELETE_ADDITIONAL_INFO: 185,
        EDIT_UNASSIGNED_EVENTS: 165,
        GENERATE_REN_UNASSIGNED: 171,
    },
    CASES: {
        DELETE_GENERAL: 42,
        EDIT_APPROVAL: 50,
        CREATE_CASE_FROM_REPORT: 52,
        CREATE_CASE_FROM_DASHBOARD: 251,
        EDIT_GENERAL: 41,
        EDIT_NOTES: 47,
        EDIT_STATUSES: 51,
        VIEW_GENERAL: 40,
        VIEW_HISTORY: 49,
        VIEW_NOTES: 46,
        VIEW_CASE_TASKS: 43,
    },
    CORE: {
        CURRENT_VIEWERS: 178,
        DELETE_GENERAL: 56,
        DELETE_TASK_UPDATES: 458,
        DELETE_CAUTIONS: 521,
        DOWNLOAD_PREVIOUS_EXPORT_RELEASES: 191,
        EDIT_ATTACHMENTS: 217,
        EDIT_EXPORT_RELEASE_ATTACHMENTS: 192,
        EDIT_EXPORT_RELEASE_TRACKING: 241,
        EDIT_GENERAL: 55,
        EDIT_PERSONAL_DASHBOARD: 309,
        EDIT_TASKS: 44,
        EDIT_TASK_TITLE_AND_DESCRIPTION: 459,
        EDIT_USER_ACCOUNT_SETTINGS: 58,
        FILLABLE_PDF_MANAGEMENT: 319,
        MANAGE_SHARED_SAVED_SEARCH: 194,
        MASTER_DELETE_TASKS: 45,
        PAPERMILL_MANAGEMENT: 59,
        PAPERMILL_VIEW: 212,
        SEARCH_GENERAL: 53,
        TASK_ENTITY_LINKING_LIMIT: 393,
        UPLOAD_FILES: 208,
        VIEW_EXPORT_RELEASE_TRACKING: 190,
        VIEW_GENERAL: 54,
        VIEW_NON_CASE_TASKS: 311,
        VIEW_USER_ACCOUNT_SETTINGS: 57,
        VIEW_USER_ERROR_REPORTS: 214,
        REDACT_REPORT_EXPORTS: 328,
        MANAGE_PERSONAL_TOKENS: 460,
        VIEW_SUBMISSIONS_MESSAGES: 475,
        SEND_SUBMISSIONS_MESSAGES: 476,
        CREATE_SUBMISSION_MESSAGE_TYPES: 477,
        SELF_INITIATE_EVENT: 173,
    },
    DATA_EXCHANGE: {
        DATA_EXCHANGE_ADMIN: 161,
        DEX_INQUIRIES: 162,
        DATA_EXCHANGE_INTERNAL: 163,
        DEX_RESULTS_ASSOCIATED_WITH_EVENT: 277,
        DEX_DEVICE_ADMIN: 301,
        CLIPS_SUBMIT: 385,
    },
    DISCLAIMER_MESSAGES: {
        MANAGE_DISCLAIMER_MESSAGES: 604,
    },
    DISPATCHER: {
        ADD_TOW_VEHICLE_ASSIGNED: 168,
        CLEAR_EVENT: 169,
        CORE: 96,
        EDIT_ASSIGNED_EVENTS: 166,
        GENERATE_REN_ASSIGNED_CLEARED: 172,
        LINK_EVENTS: 170,
        REACTIVATE_EVENT: 176,
        UNIT_MANAGEMENT: 167,
        VIEW_DISPATCH: 164,
    },
    ENTITY_PERMISSIONS: {
        GENERAL: 153,
    },
    EFILES: {
        VIEW: 471,
        EDIT: 472,
    },
    EVIDENCE: {
        CONFIGURATION: 118,
        CREATE_CHAIN_EVENTS: 112,
        DELETE_EVIDENCE_ITEMS: 293,
        EDIT_CUSTODIAL_ATTACHMENTS: 256,
        EDIT_CUSTODIAL_RESPONSIBLE_OFFICER: 150,
        EDIT_INVENTORIES: 187,
        EDIT_REPORTING: 120,
        EDIT_STAFF_REMARKS: 221,
        EVIDENCE_SUPPORT: 211,
        INTERNAL: 134,
        ITEM_QUEUE: 114,
        MASTER_EDIT_CHAIN_OF_CUSTODY: 298,
        REQUEST_DISPOSITION: 115,
        SEARCH_ITEMS: 133,
        STORAGE_ADMIN: 119,
        VIEW_CUSTODIAL_ATTACHMENTS: 255,
        VIEW_CUSTODIAL_REPORT_HISTORY: 297,
        VIEW_GENERAL: 111,
        VIEW_INVENTORIES: 186,
        VIEW_STAFF_REMARKS: 222,
        VIEW_EVIDENCE_ITEM_HISTORY: 294,
        RESET_RETENTION_POLICY: 305,
        SET_EVIDENCE_ITEM_HIGH_VALUE_STATUS: 376,
    },
    FIRST_RESPONDER: {
        CORE: 97,
        ASSIGN_SELF_TO_EVENTS: 291,
        CAD_OFF_DUTY_UNITS: 299,
    },
    INSIGHTS: {
        VIEWER: 613,
        CREATOR: 614,
        INTERNAL_MANAGEMENT_VIEWER: 615,
    },
    INTEGRATIONS: {
        BLACKSMITH: 76,
    },
    JOBS: {
        ARMADA: 61,
    },
    NOTIFICATIONS: {
        ADMIN: 121,
        ALERTS_MANAGEMENT: 240,
        CORE: 98,
        INTERNAL: 135,
    },
    REPORTING: {
        ADD_DELETE_REPORT_COMMENTS: 307,
        ADD_IMAGE_NARRATIVE: 202,
        CANCEL_CASE_STATUS: 507,
        COMPLIANCE_EXPORT_DASHBOARD: 340,
        CREATE_REPORTS: 160,
        CREATE_REPORT_FROM_DASHBOARD: 300,
        CREATE_RMS_TOW_VEHICLE_REPORT_FROM_CAD: 103,
        DELETE_APPROVED_REPORTS: 215,
        DELETE_UNAPPROVED_REPORTS: 203,
        EDIT_BOOKING: 64,
        EDIT_CASE_STATUS: 75,
        EDIT_CHARGES: 65,
        EDIT_EXTERNAL_REPORT_STATUS: 341,
        EDIT_GENERAL: 63,
        EDIT_HOME_OFFICE_CLASSIFICATION_CODE: 508,
        EDIT_REPORTING_EVENT_NUMBER: 73,
        EDIT_TOW_VEHICLE_CHECK_IN: 99,
        EDIT_TOW_VEHICLE_RELEASE: 100,
        EDIT_TRAFFIC_CRASH_REPORTS: 347,
        EXECUTE_COURT_ORDERS: 82,
        FIELD_NOTES: 195,
        GANG_UNIT_REVIEW: 260,
        MANAGE_ASSOCIATED_RECORDS: 247,
        MANAGE_REPORT_LABELS: 209,
        MASTER_ATTACHMENT_EDIT: 83,
        MASTER_EDIT: 71,
        NIBRS_CODING: 68,
        NIBRS_EXPORT: 226,
        NIBRS_SEGMENT_GENERATION: 473,
        NIBRS_WORKSPACE: 225,
        SEND_ARREST_TO_BOOKING: 242,
        SELF_APPROVE_REPORTS: 397,
        STAFF_REVIEW: 67,
        UCR: 69,
        VIEW_CASE_STATUS: 74,
        VIEW_CHARGES: 149,
        VIEW_COURT_ORDERS: 66,
        VIEW_EXTERNAL_REPORT_STATUS: 343,
        VIEW_GENERAL: 62,
        VIEW_HISTORY: 70,
        VIEW_REPORT_COMMENTS: 72,
        VIEW_TRAFFIC_CRASH_REPORTS: 346,
        VIEW_UCR: 266,
        EDIT_FIELD_CONFIGURATION: 520,
        MANAGE_DIAGRAM_ASSETS: 523,
        MANAGE_GLOBAL_DIAGRAM_ASSETS: 606,
        VIEW_REPORT_OWNER: 617,
    },
    BRIEFING: {
        EDIT_BRIEFING: 481,
        MASTER_EDIT_BRIEFING: 518,
        MASTER_MANAGE_BRIEFING: 482,
    },
    RMS_PARTNERSHIPS: {
        BOOKING_INTEGRATION: 90,
        COPLINK: 91,
        COPLOGIC: 89,
        CRIMEREPORTS: 156,
        SERIALIZED_PROPERTY_REPORT: 182,
    },
    SECURITY_CLASSIFICATION: {
        EDIT_SECURITY_CLASSIFICATIONS: 602,
    },
    SUPPORT: {
        ARMADA: 78,
        BLACKSMITH: 77,
        CONSORTIUM_MANAGEMENT: 244,
        DELETE_ROLES: 233,
        DEPARTMENT_RESET: 80,
        DEPARTMENT_SETUP: 79,
        GENERAL_INTERNAL_SUPPORT: 284,
        MIGRATION: 81,
        SEARCH: 143,
        MANAGE_SSO_CONFIG: 276,
    },
    TELEPHONE_RESPONSE_UNIT: {
        CORE: 126,
        EVENT_CREATION: 140,
    },
    THIRD_PARTY_SEARCH: {
        MANAGE_THIRD_PARTY_SEARCH_ADMIN: 102,
        THIRD_PARTY_SEARCH_CORE: 122,
        THIRD_PARTY_SEARCH_INTERNAL: 127,
    },
    USAGE_LOGS: {
        VIEW_GENERAL: 124,
    },
    WARRANTS: {
        CREATE_FROM_DASHBOARD: 154,
        DELETE_GENERAL: 155,
        EDIT_GENERAL: 148,
        EDIT_STATUSES: 147,
        VIEW_GENERAL: 146,
        ADD_WARRANT_ACTIVITIES: 274,
        EDIT_DELETE_WARRANT_ACTIVITIES: 275,
        WARRANT_EXPORTS: 278,
        RMS_WARRANTS_DEX: 281,
    },
    WORKFLOW: {
        VIEW_ADMIN: 500,
        EDIT_BPMN: 501,
    },
    RMS_DEX: {
        ADMIN_INTERFACES_AND_QUERY_FORMS: 504,
        RMS_DEX_MAKE_INQUIRIES: 505,
        RMS_DEX_VIEW_RESULTS: 506,
        RMS_DEX_LOG_ADMIN: 522,
    },
    DRAGON: {
        EDIT_CORE_CONFIGURATION: 516,
    },
} as const;
