import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { parseInt, find } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportsSelector } from '~/client-common/core/domain/reports/state/data';
import { courtOrdersByReportIdSelector } from '~/client-common/core/domain/court-orders/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatReportTitleForReportIdSelector } from '~/client-common/core/domain/reports/state/ui';

import withCourtOrdersForReport from '../../core/components/withCourtOrdersForReport';
import Select from '../../../core/forms/components/selects/Select';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import reportUnsealingForm from '../state/forms/reportUnsealingForm';
import {
    submitReportUnsealingForm,
    cleanupReportUnsealing,
    reportUnsealingLoadingStateSelector,
    reportUnsealingIsSubmittingSelector,
    initializeReportUnsealingForm,
} from '../state/ui';
import { InlineBanner } from '../../../core/components/InlineBanner';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import { ColumnFullBottomBorder } from '../../core/components/RecordPrivacyColumn';
import testIds from '../../../../core/testIds';
import ReportUnsealingForm from './ReportUnsealingForm';
import UnsealingActionBar from './UnsealingActionBar';

const NUM_COURT_ORDERS_TO_FORCE_NARRATIVE_UNSEAL = 1;
class UnsealingDashboard extends React.Component {
    state = {
        selectedCourtOrder: undefined,
    };

    componentWillUnmount() {
        this.props.resetState();
    }

    handleCourtOrderSelectChange = (value) => {
        const parsedCourtOrderId = parseInt(value);
        this.setState({ selectedCourtOrder: parsedCourtOrderId });
        const { reportId } = this.props.routeParams;
        const report = this.props.reports[reportId];
        const forceNarrativeUnseal =
            this.props.courtOrdersForReport.length === NUM_COURT_ORDERS_TO_FORCE_NARRATIVE_UNSEAL &&
            report.isNarrativeSealed;

        this.props.initializeReportUnsealingForm({
            reportId,
            courtOrderId: parsedCourtOrderId,
            isUnsealNarrative: forceNarrativeUnseal,
            report,
        });
    };

    render() {
        const {
            routeParams,
            onFormSubmit,
            formIsValid,
            editState,
            isSubmitting,
            reports,
            courtOrderOptions,
        } = this.props;
        const { reportId } = routeParams;
        const backUrl = `/reports/${reportId}`;
        const { isLoading, loadingError, loaded } = editState;
        const title = loaded ? this.props.formatReportTitleForReportId(reportId) : '';
        return (
            <Page>
                <Subheader
                    title={componentStrings.recordPrivacy.unsealing.UnsealingDashboard.title(title)}
                    backButtonTo={backUrl}
                />
                <ScrollableUnderSubheader style={{ display: 'flex' }} forceNoClassName={true}>
                    {isLoading && <SimpleLoading />}
                    {loadingError && <InlineBanner status="error">{loadingError}</InlineBanner>}
                    {loaded && !this.state.selectedCourtOrder && (
                        <ColumnFullBottomBorder data-test-id={testIds.COURT_CASE_SELECT}>
                            <Select
                                options={courtOrderOptions}
                                onChange={this.handleCourtOrderSelectChange}
                                value={this.state.selectedCourtOrder}
                                label={
                                    componentStrings.recordPrivacy.courtOrders.CourtOrderSelect
                                        .label
                                }
                            />
                        </ColumnFullBottomBorder>
                    )}
                    {loaded && this.state.selectedCourtOrder && (
                        <ReportUnsealingForm
                            report={reports[reportId]}
                            courtOrder={find(
                                this.props.courtOrdersForReport,
                                ({ id }) => id === this.state.selectedCourtOrder
                            )}
                            forceNarrativeUnseal={
                                this.props.courtOrdersForReport.length ===
                                NUM_COURT_ORDERS_TO_FORCE_NARRATIVE_UNSEAL
                            }
                        />
                    )}
                </ScrollableUnderSubheader>
                {loaded && (
                    <UnsealingActionBar
                        saveButtonDisabled={
                            !this.state.selectedCourtOrder || !formIsValid || isSubmitting
                        }
                        onSaveClick={onFormSubmit}
                        backUrl={backUrl}
                    />
                )}
            </Page>
        );
    }
}

export default compose(
    connect(
        createStructuredSelector({
            formIsValid: reportUnsealingForm.selectors.formIsValidSelector,
            formatReportTitleForReportId: formatReportTitleForReportIdSelector,
            editState: reportUnsealingLoadingStateSelector,
            isSubmitting: reportUnsealingIsSubmittingSelector,
            courtOrdersByReportId: courtOrdersByReportIdSelector,
            reports: reportsSelector,
            formatFieldByName: formatFieldByNameSelector,
            formatAttributeById: formatAttributeByIdSelector,
        }),
        (dispatch, props) => ({
            onFormSubmit: () => dispatch(submitReportUnsealingForm({ router: props.router })),
            resetState: () => {
                dispatch(reportUnsealingForm.actionCreators.reset());
                dispatch(cleanupReportUnsealing());
            },
            initializeReportUnsealingForm: (...args) =>
                dispatch(initializeReportUnsealingForm(...args)),
        })
    ),
    withCourtOrdersForReport
)(UnsealingDashboard);
