import { RefContextEnum } from '@mark43/rms-api';

import baseCourtOrderFormFieldViewModels from '../../../core/state/forms/baseCourtOrderFormFieldViewModels';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const fieldViewModels = {
    ...buildFlatFormFieldViewModels([{ key: 'chargeIdsToSeal' }]),
    ...baseCourtOrderFormFieldViewModels,
};

/**
 * @typedef CourtOrderFormModelSelectors
 * @property {function(): any} formModelByPathSelector
 */

/**
 * @typedef CourtOrderFormModelActions
 * @property {function(string, any): void} changePath
 */

/**
 * @typedef CourtOrderFormModel
 * @property {CourtOrderFormModelSelectors} selectors
 * @property {CourtOrderFormModelActions} actionCreators
 */

/** @type CourtOrderFormModel */
export default createFormModule({
    context: RefContextEnum.FORM_COURT_ORDER_EDIT.name,
    fieldViewModels,
});
