import React, { useCallback, useRef, useEffect, useState } from 'react';
import { cssVar, Input, EmptyState, Button as _Button, Icon as _Icon } from 'arc';
import { Text } from '@arc/typography';
import { Skeleton } from '@arc/skeleton';
import { Flex } from '@arc/layout';

import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Konva from 'konva';
import { DiagramCategoryEnumType, DiagramCategoryEnum, DiagramTypeEnum } from '@mark43/rms-api';
import invariant from 'invariant';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useFetchSVGAsset } from '../hooks';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { useOnClickInside } from '../../../../../../../legacy-redux/helpers/reactHelpers';
import { Asset } from '../../../../../../diagram/types';
import { getAssetUrl, sortDiagramAssets } from '../helpers';
import { Widget } from './Widget';
import { NoImagePlaceHolder } from './shared';

const crashDiagramModalStrings = componentStrings.dragon.crashDiagram.CrashDiagramModal;

const ScrollableAssetsPanelWrapper = styled.div`
    background-color: var(--arc-colors-surface-foreground);
    height: 14.69rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    position: absolute;
    z-index: 1;
    border-top: 1px solid var(--arc-colors-border-default);
    border-bottom: 1px solid var(--arc-colors-border-default);
    border-radius: var(--arc-radii-base);
    padding: var(--arc-space-3);
`;

const AssetsContainer = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    height: 100%;
`;

const StyledInput = styled(Input)`
    width: 12.5rem;
`;

const AssetWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding-left: ${cssVar('arc.space.2')};
    padding-right: ${cssVar('arc.space.2')};
`;

const DiagramAssetWrapper = styled(AssetWrapper)`
    padding: ${cssVar('arc.space.4')};
`;

const BackgroundWrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: inline-block;
`;

const DiagramBackgroundAsset = styled(LazyLoadImage)`
    max-width: 100px;
    width: 100px;
    max-height: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: var(--arc-radii-base);
    filter: brightness(85%) contrast(135%);
`;

const MapButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${cssVar('arc.space.4')};
    padding: ${cssVar('arc.space.4')};
    margin-top: ${cssVar('arc.space.1')};
`;

const Button = styled(_Button)`
    width: 100%;
    height: fit-content;
    padding: ${cssVar('arc.space.4')};
    margin-bottom: ${cssVar('arc.space.1')};
`;

const Icon = styled(_Icon)`
    width: ${cssVar('arc.sizes.16')};
    height: ${cssVar('arc.sizes.16')};
`;

type AssetPanelProps = {
    assetType: DiagramCategoryEnumType;
    stageRef: React.RefObject<Konva.Stage>;
    render: (args: { labelComponent: React.ReactNode; src: string }) => React.ReactNode;
    onClick?: (assetUrl: string) => void;
    handleSetAssetType: (assetType?: DiagramCategoryEnumType) => void;
    openAddMapToBackground?: () => void;
};

const AssetsPanel: React.FC<AssetPanelProps> = ({
    assetType,
    stageRef,
    render,
    onClick,
    handleSetAssetType,
    openAddMapToBackground,
}) => {
    const { assets, fetchAssets, isLoadingAssets: isLoading } = useCrashDiagram();
    const [searchTerm, setSearchTerm] = useState('');
    const [panelAssets, setPanelAssets] = useState<Asset[]>([]);
    const [filteredAssets, setFilteredAssets] = useState<Asset[]>([]);
    const { addMapBackgroundModalEnabled } = useCrashDiagram();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const categoryAssetsState = assets[DiagramTypeEnum.CRASH.name][assetType];
        if (categoryAssetsState.isFetched) {
            const sortedAssets = sortDiagramAssets(categoryAssetsState.assets);
            setPanelAssets(sortedAssets);
            setFilteredAssets(sortedAssets);
            return;
        }

        fetchAssets(DiagramTypeEnum.CRASH.name, assetType);
    }, [assetType, assets, fetchAssets, isLoading]);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.currentTarget;
            setSearchTerm(value);
            setFilteredAssets(
                panelAssets.filter((asset) =>
                    asset.name.toLowerCase().includes(value.toLowerCase())
                )
            );
        },
        [panelAssets]
    );

    const assetsPanelRef = useRef<HTMLDivElement | null>(null);

    useOnClickInside(stageRef.current?.container(), assetsPanelRef.current, () =>
        handleSetAssetType(undefined)
    );

    const Wrapper =
        assetType === DiagramCategoryEnum.CRASH_BACKGROUND.name
            ? DiagramAssetWrapper
            : AssetWrapper;

    const handleOpenAddMapToBackground = () => {
        invariant(openAddMapToBackground, 'openAddMapToBackground must be defined');
        openAddMapToBackground();
    };

    return (
        <ScrollableAssetsPanelWrapper ref={assetsPanelRef}>
            <StyledInput
                value={searchTerm}
                onChange={handleChange}
                leftIcon="Search"
                isClearable
                isLoading={isLoading}
            />
            <AssetsContainer>
                {assetType === DiagramCategoryEnum.CRASH_BACKGROUND.name &&
                    addMapBackgroundModalEnabled && (
                        <MapButtonWrapper>
                            <Button onClick={handleOpenAddMapToBackground}>
                                <Icon icon="Map" />
                            </Button>
                            <AssetLabel label={'Select from Map'} />
                        </MapButtonWrapper>
                    )}
                {filteredAssets.length === 0 && !isLoading ? (
                    <Flex justifyContent="center" alignItems="center" width="100%">
                        <EmptyState title="" subtitle={crashDiagramModalStrings.noResultsFound} />
                    </Flex>
                ) : (
                    filteredAssets.map((asset, index) => {
                        const assetUrl = getAssetUrl(asset);
                        return (
                            <Wrapper
                                key={index}
                                onClick={() => {
                                    if (onClick) {
                                        onClick(assetUrl);
                                    }
                                }}
                            >
                                {render({
                                    labelComponent: <AssetLabel label={asset.name} />,
                                    src: assetUrl,
                                })}
                            </Wrapper>
                        );
                    })
                )}
            </AssetsContainer>
        </ScrollableAssetsPanelWrapper>
    );
};

type AssetLabelProps = {
    label: string;
};

const AssetLabel: React.FC<AssetLabelProps> = ({ label }) => {
    return (
        <Text align="center" variant="caption" fontWeight="bold">
            {label}
        </Text>
    );
};

const BackgroundThumbnail: React.FC<{ src: string }> = ({ src }) => {
    const { setBackground, backgroundImage } = useCrashDiagram();
    const { svgDataUrl: backgroundWidgetSVGDataUrl, isLoading: isFetchingBackgroundWidget } =
        useFetchSVGAsset({
            value: src,
            assetKey: src,
        });

    const { svgDataUrl: backgroundImageSVGDataUrl, isLoading: isFetchingBackgroundImage } =
        useFetchSVGAsset({
            value: backgroundImage,
            assetKey: backgroundImage,
        });

    const isLoading = isFetchingBackgroundWidget || isFetchingBackgroundImage;

    if (!backgroundWidgetSVGDataUrl && !isLoading) {
        return <NoImagePlaceHolder />;
    }

    const isSelected = backgroundImageSVGDataUrl === backgroundWidgetSVGDataUrl;

    return (
        <Skeleton isLoaded={!isLoading}>
            <BackgroundWrapper>
                <DiagramBackgroundAsset
                    src={backgroundWidgetSVGDataUrl}
                    onClick={() => {
                        if (backgroundWidgetSVGDataUrl) {
                            setBackground(backgroundWidgetSVGDataUrl);
                        }
                    }}
                    effect="opacity"
                    style={
                        isSelected
                            ? {
                                  border: 'var(--arc-borders-md) var(--arc-colors-selected-default)',
                              }
                            : undefined
                    }
                />
            </BackgroundWrapper>
        </Skeleton>
    );
};

type ScrollableBackgroundPanelProps = {
    stageRef: React.RefObject<Konva.Stage>;
    handleSetAssetType: (assetType?: DiagramCategoryEnumType) => void;
    openAddMapToBackground: () => void;
};

const ScrollableBackgroundPanel: React.FC<ScrollableBackgroundPanelProps> = ({
    stageRef,
    handleSetAssetType,
    openAddMapToBackground,
}) => {
    return (
        <AssetsPanel
            stageRef={stageRef}
            assetType={DiagramCategoryEnum.CRASH_BACKGROUND.name}
            handleSetAssetType={handleSetAssetType}
            openAddMapToBackground={openAddMapToBackground}
            render={({ labelComponent, src }) => {
                return (
                    <>
                        <BackgroundThumbnail src={src} />
                        {labelComponent}
                    </>
                );
            }}
        />
    );
};

type ScrollableAssetsProps = {
    assetType?: DiagramCategoryEnumType;
    stageRef: React.RefObject<Konva.Stage>;
    handleSetAssetType: (assetType?: DiagramCategoryEnumType) => void;
    openAddMapToBackground: () => void;
};

export const ScrollableAssetsPanel: React.FC<ScrollableAssetsProps> = ({
    assetType,
    stageRef,
    handleSetAssetType,
    openAddMapToBackground,
}) => {
    if (!assetType) {
        return null;
    }

    if (assetType === DiagramCategoryEnum.CRASH_BACKGROUND.name) {
        return (
            <ScrollableBackgroundPanel
                stageRef={stageRef}
                handleSetAssetType={handleSetAssetType}
                openAddMapToBackground={openAddMapToBackground}
            />
        );
    }

    return (
        <AssetsPanel
            handleSetAssetType={handleSetAssetType}
            stageRef={stageRef}
            assetType={assetType}
            openAddMapToBackground={openAddMapToBackground}
            render={({ labelComponent, src }) => {
                return (
                    <>
                        <Widget src={src} />
                        {labelComponent}
                    </>
                );
            }}
        />
    );
};
