import React from 'react';
import { LinkTypesEnum } from '@mark43/rms-api';
import { withRouter, WithRouterProps } from 'react-router';
import { EFileProfilesPage } from '../profiles/EFileProfilesPage';

const EFileDefendantsPageComponent = ({ params }: WithRouterProps) => {
    return (
        <EFileProfilesPage
            eFileId={parseInt(params.eFileId)}
            linkType={LinkTypesEnum.DEFENDANT_IN_EFILE}
        />
    );
};

export const EFileDefendantsPage = withRouter(EFileDefendantsPageComponent);
