import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { mediaQueries } from 'arc';

const StyledPageContent = styled.div<{ fullscreen: boolean | undefined; minHeight?: number | string }>`
    position: relative;
    background-color: ${(props) => props.theme.colors.white};
    height: fit-content;
    padding: var(--arc-space-2);
    border-color: var(--arc-colors-border-default);
    border-left-width: 1px;
    border-right-width: 1px;
    ${({minHeight}) => `min-height: ${minHeight ?? '100%'};`}

    ${({ fullscreen }) =>
        fullscreen === true &&
        `width: 100%;

        @media (min-width: ${mediaQueries.md}) {
            padding: 0;
            margin: 0 var(--arc-space-8);
            max-width: calc(100% - var(--arc-space-8) * 2);

        }
        `}
`;

/**
 * This is baked into the ScrollableUnderSubheader and UnderSubheader as the content area, it can be centered or full width.
 * This can also be used separately inside a more custom layout if needed.
 */

export const PageContent: React.FC<{
    children: React.ReactNode;
    fullscreen?: boolean;
    minHeight?: number|string;
    useConditionalClassNames?: boolean;
    className?: string;
    style?: React.CSSProperties;
}> = ({ children, fullscreen,minHeight, useConditionalClassNames = true, style, className, ...props }) => {
    return (
        <StyledPageContent
            fullscreen={fullscreen}
            minHeight={minHeight}
            className={classNames(
                useConditionalClassNames && !fullscreen && 'mark43-auto-center',
                className
            )}
            style={style}
            {...props}
        >
            {children}
        </StyledPageContent>
    );
};
