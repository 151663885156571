import { Table as _Table, TableRow as _Row, TableColumn as Column } from 'components-mark43';
import { chain, map, size } from 'lodash';
import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import _ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import FormattedAttribute from '~/client-common/core/domain/attributes/components/FormattedAttribute';
import _ConnectedFormattedReportsLinkedToItem from '~/client-common/core/domain/item-report-links/components/ConnectedFormattedReportsLinkedToItem';
import ConnectedFormattedPropertyStatusQuantityForItem from '~/client-common/core/domain/property-statuses/components/ConnectedFormattedPropertyStatusQuantityForItem';
import _ConnectedPropertyStatusTitlesForItem from '~/client-common/core/domain/property-statuses/components/ConnectedPropertyStatusTitlesForItem';
import PropertyTitle from '~/client-common/core/domain/item-profiles/components/PropertyTitle';
import { itemProfilesByIdsSelector } from '~/client-common/core/domain/item-profiles/state/data';
import _SidePanel from '../../../../../legacy-redux/components/core/SidePanel';
import { currentReportRENSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import _Checkbox, {
    INDETERMINATE,
    CheckboxBox,
    CheckboxDescription,
    CheckboxLabel,
} from '../../../../core/forms/components/checkboxes/Checkbox';
import {
    closeRecentItemsSidePanel,
    recentItemsSidePanelIsVehicleSelector,
    recentItemsSidePanelItemProfileIdsSelector,
    recentItemsSidePanelLimitToOneSelector,
    recentItemsSidePanelOwnerIdSelector,
    recentItemsSidePanelOwnerTypeSelector,
    saveRecentItemsSidePanel,
} from '../../state/ui';

const SidePanel = styled(_SidePanel)`
    .react-side-panel-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    && .react-side-panel-content-wrapper {
        padding: 0;
    }
`;

const Wrapper = styled(Column)`
    width: 100%;
`;

const Header = styled(
    ({ className, itemProfilesSize, onToggleAllCheckboxes, selectedItemProfileIdsSize }) => {
        const HeaderRow = _Row;
        const strings = componentStrings.reports.core.RecentItemsSidePanel;
        const checkboxesAllSelected = selectedItemProfileIdsSize === itemProfilesSize;
        const checkboxValue = checkboxesAllSelected
            ? true
            : selectedItemProfileIdsSize > 0
            ? INDETERMINATE
            : false;

        return (
            <HeaderRow className={className}>
                <Column>
                    <Checkbox
                        label={strings.selectAll}
                        onChange={onToggleAllCheckboxes}
                        value={checkboxValue}
                    />
                </Column>
            </HeaderRow>
        );
    }
)`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    box-shadow: 0 3px 3px -3px ${(props) => props.theme.colors.darkGrey};
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.55px;
    padding: 19px 26px;
    text-transform: uppercase;
`;

const Row = styled(_Row)`
    border-bottom: 2px solid ${(props) => props.theme.colors.extraLightGrey};
    display: flex;
    flex-direction: row;
    font-size: var(--arc-fontSizes-sm);
    margin: 0 16px;
    padding: 10px;
`;

const Table = styled(_Table)`
    flex: 1 1 auto;
    overflow: auto;
    padding: 16px 0;
    word-break: break-word;
`;

const Checkbox = styled(_Checkbox)`
    /* stylelint-disable */
    ${/* sc-selector */ CheckboxBox} {
        flex: 0 0 auto;
    }

    ${/* sc-selector */ CheckboxDescription} {
        flex: 1 1 auto;
    }

    ${/* sc-selector */ CheckboxLabel} {
        display: flex;
        float: none;
    }
    float: none;
    margin-bottom: 0;
    /* stylelint-enable */
`;

const PropertyTitleAndQuantity = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const ConnectedFormattedReportsLinkedToItem = styled(_ConnectedFormattedReportsLinkedToItem)`
    display: flex;
    flex-direction: column;
    margin-top: 3px;
`;

const ConnectedFormattedAttribute = styled(_ConnectedFormattedAttribute)`
    display: block;
`;

const ConnectedPropertyStatusTitlesForItem = styled(_ConnectedPropertyStatusTitlesForItem)`
    display: flex;
    flex-direction: column;
    margin-top: 3px;
`;

const RecentItemSidePanel = ({
    fieldDisplayNames: { REPORT_REPORTING_EVENT_NUMBER },
    itemProfiles,
    isVehicle,
    limitToOne,
    onCancelHandler,
    onSaveSidePanel,
    onToggleAllCheckboxes,
    onToggleCheckbox,
    reportingEventNumber,
    selectedItemProfileIds,
}) => {
    const selectedItemProfileIdsSize = size(selectedItemProfileIds);
    const itemProfilesSize = size(itemProfiles);
    const context = { name: boxEnum.RECENT_ITEMS_SIDE_PANEL };
    const strings = componentStrings.reports.core.RecentItemsSidePanel;
    const saveText = isVehicle
        ? limitToOne
            ? strings.addVehicleToReport
            : strings.addVehiclesToReport(selectedItemProfileIdsSize)
        : strings.addPropertyToReport(selectedItemProfileIdsSize);

    const otherItemsInRen = isVehicle ? strings.otherVehiclesInREN : strings.otherPropertyInREN;
    const title = otherItemsInRen({
        renDisplayName: REPORT_REPORTING_EVENT_NUMBER,
        reportingEventNumber,
    });

    const recentItems = map(itemProfiles, (itemProfile) => ({
        ...itemProfile,
        isVehicle,
    }));

    const saveDisabled = limitToOne && selectedItemProfileIdsSize > 1;

    const renderBodyRow = ({ data: itemProfile, key }) => {
        const onToggleThisCheckbox = () => onToggleCheckbox(itemProfile.id);
        const reportsLinkedSortPredicate = ({ reportId }) =>
            reportId === itemProfile.ownerId ? 0 : 1;

        const label = (
            <Column>
                <PropertyTitleAndQuantity>
                    <PropertyTitle itemProfile={itemProfile} />
                    {!isVehicle && (
                        <ConnectedFormattedPropertyStatusQuantityForItem
                            itemProfileId={itemProfile.id}
                        >
                            {(renderQuantity) => (
                                <span>
                                    {' ('}
                                    {renderQuantity}
                                    {')'}
                                </span>
                            )}
                        </ConnectedFormattedPropertyStatusQuantityForItem>
                    )}
                </PropertyTitleAndQuantity>
                {!isVehicle && (
                    <ConnectedFormattedAttribute
                        attributeId={itemProfile.itemTypeAttrId}
                        format={FormattedAttribute.FORMATS.ITEM_TYPE}
                    />
                )}
                <ConnectedPropertyStatusTitlesForItem itemProfileId={itemProfile.id} />
                <ConnectedFormattedReportsLinkedToItem
                    masterItemId={itemProfile.masterItemId}
                    reportingEventNumber={reportingEventNumber}
                    sortPredicate={reportsLinkedSortPredicate}
                />
            </Column>
        );

        return (
            <Row key={key} onClick={onToggleThisCheckbox}>
                <Column>
                    <Checkbox
                        label={label}
                        onChange={onToggleThisCheckbox}
                        value={selectedItemProfileIds.indexOf(itemProfile.id) >= 0}
                    />
                </Column>
            </Row>
        );
    };

    return (
        <SidePanel
            context={context}
            onSave={onSaveSidePanel}
            onCancel={onCancelHandler}
            saveDisabled={saveDisabled}
            saveText={saveText}
            title={title}
        >
            <Wrapper>
                <Header
                    itemProfilesSize={itemProfilesSize}
                    onToggleAllCheckboxes={onToggleAllCheckboxes}
                    selectedItemProfileIdsSize={selectedItemProfileIdsSize}
                />

                <Table data={recentItems} renderBodyRow={renderBodyRow} />
            </Wrapper>
        </SidePanel>
    );
};

const mapStateToProps = createStructuredSelector({
    reportingEventNumber: currentReportRENSelector,
    isVehicle: recentItemsSidePanelIsVehicleSelector,
    itemProfiles: createSelector(
        itemProfilesByIdsSelector,
        recentItemsSidePanelItemProfileIdsSelector,
        (itemProfilesByIds, recentItemsSidePanelItemProfileIds = []) =>
            itemProfilesByIds(recentItemsSidePanelItemProfileIds)
    ),
    limitToOne: recentItemsSidePanelLimitToOneSelector,
    ownerId: recentItemsSidePanelOwnerIdSelector,
    ownerType: recentItemsSidePanelOwnerTypeSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onClickAddButton: () => {},
    onCloseSidePanel: () => dispatch(closeRecentItemsSidePanel()),
    onSaveSidePanel: ({ isVehicle, itemProfileIds, ownerId, ownerType, reportingEventNumber }) =>
        dispatch(
            saveRecentItemsSidePanel({
                isVehicle,
                itemProfileIds,
                ownerId,
                ownerType,
                reportingEventNumber,
            })
        ),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFields([REPORT_REPORTING_EVENT_NUMBER]),
    withState('selectedItemProfileIds', 'setSelectedItemProfileIds', []),
    withHandlers({
        onCancelHandler: ({ onCloseSidePanel, setSelectedItemProfileIds }) => () => {
            onCloseSidePanel();
            setSelectedItemProfileIds([]);
        },
        onSaveSidePanel: ({
            isVehicle,
            onCloseSidePanel,
            onSaveSidePanel,
            ownerId,
            ownerType,
            reportingEventNumber,
            selectedItemProfileIds,
            setSelectedItemProfileIds,
        }) => () => {
            onSaveSidePanel({
                isVehicle,
                itemProfileIds: selectedItemProfileIds,
                ownerId,
                ownerType,
                reportingEventNumber,
            });

            onCloseSidePanel();
            setSelectedItemProfileIds([]);
        },
        onToggleAllCheckboxes: ({
            itemProfiles,
            selectedItemProfileIds,
            setSelectedItemProfileIds,
        }) => () =>
            size(itemProfiles) !== size(selectedItemProfileIds)
                ? setSelectedItemProfileIds(map(itemProfiles, 'id'))
                : setSelectedItemProfileIds([]),

        onToggleCheckbox: ({ selectedItemProfileIds, setSelectedItemProfileIds }) => (
            itemProfileId
        ) => {
            if (selectedItemProfileIds.indexOf(itemProfileId) >= 0) {
                return chain(selectedItemProfileIds)
                    .filter((item) => item !== itemProfileId)
                    .thru(setSelectedItemProfileIds)
                    .value();
            }

            return chain(selectedItemProfileIds)
                .concat(itemProfileId)
                .thru(setSelectedItemProfileIds)
                .value();
        },
    })
)(RecentItemSidePanel);
