import React from 'react';
import { get, sortBy, last, thru, map } from 'lodash';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { LinkTypesEnum } from '@mark43/rms-api';
import { Text as ArcText, cssVar } from 'arc';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import { elasticOrganizationsSelector } from '~/client-common/core/domain/elastic-organizations/state/data/';
import { locationNameFormatsForSearch } from '~/client-common/core/domain/locations/utils/locationHelpers';

import Pill from '../../components/Pill';
import Icon, { iconTypes, iconSizes } from '../../components/Icon';
import testIds from "../../../../core/testIds";

type OrganizationPillProps = {
    className?: string;
    id: number;
    onClick: (id: number) => void;
    subtitle?: string;
};

const Description = styled.div`
    margin-left: 20px;
    flex: 1;
    overflow: hidden;
`;

const OrganizationType = styled(ArcText)`
    font-size: ${cssVar('arc.fontSizes.sm')};
    line-height: ${cssVar('arc.lineHeights.short')};
`;

const Subtitle = styled(ArcText)`
    margin-top: 10px;
    font-size: ${cssVar('arc.fontSizes.sm')};
    line-height: ${cssVar('arc.lineHeights.short')};
`;

const OrganizationLocation = styled(ArcText)`
    margin-top: 10px;
    font-size: ${cssVar('arc.fontSizes.sm')};
    line-height: ${cssVar('arc.lineHeights.short')};
`;
const OrganizationPill: React.FC<OrganizationPillProps> = ({
    className,
    subtitle,
    onClick,
    id,
}) => {
    const elasticOrganizations = useSelector(elasticOrganizationsSelector);
    const elasticOrganization = elasticOrganizations[id];
    const isSubPremiseSupportEnabled = useSelector(applicationSettingsSelector)
        .SUBPREMISE_SUPPORT_ENABLED;

    const isDisabled = !canRead(get(elasticOrganization, 'permissionSet'));

    const handleClick = () => {
        onClick?.(id);
    };

    const locationToShow = get(
        last(
            sortBy(elasticOrganization?.involvedLocations, [
                // First sort by locations first (not mailing address)
                (location) => (location.type === LinkTypesEnum.BUSINESS_LOCATION ? 1 : -1),
                // Next, sort by most recently created
                'rmsEventId',
            ])
        ),
        'locationAddress'
    );
    return (
        <Pill
            className={className}
            disabled={isDisabled}
            onClick={isDisabled ? undefined : handleClick}
            hoverable={true}
            showChevron={true}
            testId={testIds.ORGANIZATION_PILL}
        >
            <Icon type={iconTypes.ORGANIZATION} size={iconSizes.MEDIUM} />
            <Description>
                <ArcText variant="headingSm">{get(elasticOrganization, 'name')}</ArcText>
                <OrganizationType>
                    {joinTruthyValues(
                        [
                            get(elasticOrganization, [
                                'organizationTypeAttrDetail',
                                'displayValue',
                            ]),
                            get(elasticOrganization, ['industryAttrDetail', 'displayValue']),
                        ],
                        ' / '
                    )}
                </OrganizationType>
                {!!subtitle && <Subtitle variant="bodyMd">{subtitle}</Subtitle>}
                {!!locationToShow && (
                    <OrganizationLocation variant="bodyMd">
                        {thru(
                            locationNameFormatsForSearch({
                                elasticLocation: locationToShow,
                                showRange: true,
                                isSubPremiseSupportEnabled,
                            }),
                            ({ lines }) =>
                                map(lines, (line, index) => <div key={index}>{line}</div>)
                        )}
                    </OrganizationLocation>
                )}
            </Description>
        </Pill>
    );
};

export default OrganizationPill;
