import { DexStateUserIdView, OriAliasesResource, OriAliasSetupView } from '@mark43/dex-api';
import { createResource } from '../../../../../lib/resources/Resource';
import { req } from '../../../../../lib/ajax';

export default createResource({
    name: 'ORI Alias Resource',
    methods: {
        /**
         * Get ORI Aliases For a Department
         * @path /ori_aliases/options
         */
        getOriAliasOptions() {
            return req<OriAliasesResource.GetOriAliasOptionsForDepartment>({
                method: 'GET',
                url: 'ori_aliases/options',
                baseUrl: '/dex/api',
            });
        },
        /**
         * Setup ORI Aliases from shapefile (deleting all previous existing aliases)
         * @path /ori_aliases/setup
         */
        setupOriAliases(data: OriAliasSetupView) {
            return req<OriAliasesResource.SetupOriAliases>({
                method: 'POST',
                url: 'ori_aliases/setup',
                baseUrl: '/dex/api',
                data,
            });
        },
        getStateOrisForUser(userId: string | number) {
            return req({
                method: 'GET',
                url: `ori_aliases/dex_state_user_ids/${userId}`,
                baseUrl: '/dex/api',
            });
        },
        upsertMultiStateOrisForUser(dexStateUserIds: DexStateUserIdView) {
            return req<OriAliasesResource.UpsertDexStateUserIds>({
                method: 'POST',
                url: 'ori_aliases/dex_state_user_ids',
                baseUrl: '/dex/api',
                data: dexStateUserIds,
            });
        },
        setDefaultOriAlias(oriAliasId: number) {
            return req<OriAliasesResource.SetDefaultOriAliases>({
                method: 'POST',
                url: 'ori_aliases/set_default',
                data: { oriAliasId },
                baseUrl: '/dex/api',
            });
        },
    },
});
