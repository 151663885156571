import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const fieldViewModels = buildFlatFormFieldViewModels([
    'isUnsealNarrative',
    'courtOrderId',
    'reportId',
    'report',
]);

export default createFormModule({
    formName: formClientEnum.REPORT_UNSEALING,
    fieldViewModels,
});
