import * as React from 'react';
import { InputTypeEnum } from '@mark43/rms-api';
import { EmptyState } from 'arc';
import {
    isSelectFieldConfiguration,
    FieldUiOptions,
    FieldsetUiOptions,
    FormReferenceSummaryUiOptions,
} from 'dragon-react';

import { FormattedDate } from '~/client-common/core/dates/components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import { RMSDragonConfigurationExtensions } from '../../../rms-types';
import { useSummaryValueForPath } from '../../../hooks/use-summary-value-for-path';
import { SignatureSummary } from './signature/components/signature-form-field';
import { CrashDiagramImage } from './crash-diagram/components/CrashDiagramImage';
import { Wrapper as CrashDiagramWrapper } from './crash-diagram/components/shared';
import { PersonProfileSelectSummary } from './person-profile-select/PersonProfileSelect';
import { RMSDragonFieldsetHelper } from './shared';

/**
 * Summary mode renderers
 * These renderers implement the required functionality we need to provide to Dragon in order to render a summary mode for a form.
 * It is important to note that these are _not_ React components but simple render callback functions.
 */

export const fieldsetSummary = ({
    props,
    renderChildren,
}: FieldsetUiOptions<RMSDragonConfigurationExtensions>): JSX.Element => (
    <RMSDragonFieldsetHelper
        key={props.key}
        label={props.label}
        render={() => (
            <SummaryList {...props} labelWidth={160} contentWidth={380}>
                {renderChildren()}
            </SummaryList>
        )}
    />
);

export function FieldSummary({
    props,
    configuration,
    fullyQualifiedPath,
}: FieldUiOptions<RMSDragonConfigurationExtensions>): JSX.Element | null {
    const value = useSummaryValueForPath(fullyQualifiedPath);
    const inputType = configuration.ui.inputType;

    if (inputType === InputTypeEnum.INTERNAL_DATA.name) {
        return null;
    }

    if (inputType === InputTypeEnum.SIGNATURE.name) {
        const fileId = Number(value);
        return (
            <FeatureFlagged
                flag="RMS_DIGITAL_SIGNATURES_ENABLED"
                fallback={
                    <EmptyState
                        title=""
                        headingLevel="h2"
                        subtitle="Signature Capture is currently turned off for your department."
                    />
                }
            >
                <SignatureSummary fileId={!isNaN(fileId) ? fileId : undefined} />
            </FeatureFlagged>
        );
    } else if (inputType === InputTypeEnum.CRASH_DIAGRAM.name) {
        const fileId = Number(value);
        return (
            <FeatureFlagged
                flag="RMS_CRASH_DIAGRAM_ENABLED"
                fallback={
                    <EmptyState
                        title=""
                        headingLevel="h2"
                        subtitle="Crash Diagram is currently turned off for your department."
                    />
                }
            >
                <CrashDiagramWrapper>
                    <CrashDiagramImage
                        fileId={!isNaN(fileId) ? fileId : undefined}
                        mode="SUMMARY"
                        onEdit={undefined}
                        onDelete={undefined}
                    />
                </CrashDiagramWrapper>
            </FeatureFlagged>
        );
    }

    let resolvedValue: unknown;

    // this is a very very crude POC implementation on how to handle values, not pretty by any means
    if (value && isSelectFieldConfiguration(configuration)) {
        if (typeof configuration.ui.selectConfiguredEntityDisplayPropertyId !== 'number') {
            throw new Error(
                'The provided SelectFieldConfiguration did not have a value for selectConfiguredEntityDisplayPropertyId'
            );
        }
        // MULTISELECT and CHECKBOXES will have an array of values
        if (Array.isArray(value)) {
            resolvedValue = (value as Record<string, unknown>[])
                .map(
                    (value) => value[configuration.ui.selectConfiguredEntityDisplayPropertyId ?? -1]
                )
                .join(', ');
            // SELECT, BOOLEAN_SELECT, USER_SELECT and VEHICLE_SELECT will have a single value
            // NOTE currently USER_SELECT and VEHICLE_SELECT are not configurable to contain multiple values,
            // but this needs to be fixed
        } else {
            resolvedValue = (value as Record<string, unknown>)[
                configuration.ui.selectConfiguredEntityDisplayPropertyId
            ];
        }
    } else {
        resolvedValue = value;
    }

    if (resolvedValue) {
        switch (inputType) {
            case InputTypeEnum.DATE.name:
            case InputTypeEnum.DATETIME.name:
                resolvedValue =
                    typeof resolvedValue === 'string' ? (
                        <FormattedDate
                            date={resolvedValue}
                            format={
                                inputType === InputTypeEnum.DATE.name
                                    ? FormattedDate.FORMATS.SUMMARY_DATE
                                    : FormattedDate.FORMATS.SUMMARY_DATE_TIME
                            }
                        />
                    ) : undefined;
                break;
            case InputTypeEnum.PERSON_SELECT.name:
                resolvedValue =
                    typeof resolvedValue === 'number' ? (
                        <PersonProfileSelectSummary value={resolvedValue} />
                    ) : undefined;
                break;
            default:
                break;
        }
    }

    return (
        <SummaryRow key={props.key} label={props.label}>
            {resolvedValue}
        </SummaryRow>
    );
}

export const formReferenceSummary = ({
    props,
    renderChildren,
}: FormReferenceSummaryUiOptions<RMSDragonConfigurationExtensions>) => (
    <div {...props}>{renderChildren()}</div>
);
