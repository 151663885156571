import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { InlineBanner } from 'arc';
import { BriefingStateEnum, BriefingStateEnumType, EntityTypeEnum } from '@mark43/rms-api';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Briefing } from '~/client-common/core/domain/briefings/state/data';
import { attachmentsByEntitySelector } from '~/client-common/core/domain/attachments/state/data';
import { taskViewsByOwnerSelector } from '~/client-common/core/domain/taskViews/state/data';
import { loadNarrativeGuides } from '~/client-common/core/domain/narrative-guides/state/data';

import UnderSubheader from '../../core/components/UnderSubheader';
import TaskSidePanel from '../../tasks/core/components/TaskSidePanel';
import AttachmentsSidePanel from '../../attachments/core/components/AttachmentsSidePanel';
import { useAbilitySelector } from '../../core/current-user/hooks/useAbilitySelector';

import { BriefingModes } from '../types';
import { currentUserCanCreateBriefingTasksSelector } from '../state/ui';
import useBriefingAutosave from '../hooks/useBriefingAutosave';
import useBriefingLatestUpdate from '../hooks/useBriefingLatestUpdate';
import useBriefingPermissions from '../hooks/useBriefingPermissions';
import BriefingEditor from './BriefingEditor';
import BriefingSidebar from './BriefingSidebar';
import BriefingEditModeHeader from './header/BriefingEditModeHeader';
import BriefingViewModeHeader from './header/BriefingViewModeHeader';

interface BriefingProps {
    briefing: Briefing;
    initialBody: string | undefined;
    hasEditQueryParam: boolean;
}

const strings = componentStrings.briefings.core;

const ContentWrapper = styled.div`
    display: flex;
    height: 100%;
`;

const BriefingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const getBriefingMode = (
    canEdit: boolean,
    state: BriefingStateEnumType,
    hasEditQueryParam: boolean,
    isRecentlyUpdatedByOtherUser: boolean
) => {
    if (isRecentlyUpdatedByOtherUser) {
        return BriefingModes.VIEW;
    }
    if (canEdit && (hasEditQueryParam || state === BriefingStateEnum.DRAFT.name)) {
        return BriefingModes.EDIT;
    }
    return BriefingModes.VIEW;
};

const BriefingComponent: React.FC<BriefingProps> = ({
    briefing,
    initialBody,
    hasEditQueryParam = false,
}) => {
    const canViewTasks = useAbilitySelector(abilitiesEnum.CORE.VIEW_NON_CASE_TASKS);
    const canCreateTasks = useSelector(currentUserCanCreateBriefingTasksSelector);

    const attachments = useSelector(attachmentsByEntitySelector)(
        EntityTypeEnum.BRIEFING.name,
        briefing.id
    );
    const tasks = useSelector(taskViewsByOwnerSelector)(EntityTypeEnum.BRIEFING.name, briefing.id);

    // tasks is empty on initial render because fetching logic is implemented in LinkedTasksSection down the component tree
    // BE sets the hasTasks flag so we can decide on showing sidebar during initial render
    const hasTasks = canViewTasks && (briefing.hasTasks || tasks.length > 0);
    const hasAttachments = attachments.length > 0;

    const { canEdit } = useBriefingPermissions(briefing);
    const { isRecentlyUpdatedByOtherUser, updateAuthor, updateTime } =
        useBriefingLatestUpdate(briefing);

    const [showSidebar, setShowSidebar] = useState(hasTasks || hasAttachments);
    const [mode, setMode] = useState(
        getBriefingMode(canEdit, briefing.state, hasEditQueryParam, isRecentlyUpdatedByOtherUser)
    );

    const dispatch = useDispatch();
    useBriefingAutosave(briefing.id, briefing.state, briefing.body, canEdit);

    const isEditMode = mode === BriefingModes.EDIT;

    useEffect(() => {
        setShowSidebar(hasTasks || hasAttachments);
    }, [hasTasks, hasAttachments]);

    useEffect(() => {
        setMode(
            getBriefingMode(
                canEdit,
                briefing.state,
                hasEditQueryParam,
                isRecentlyUpdatedByOtherUser
            )
        );
    }, [canEdit, briefing.state, hasEditQueryParam, isRecentlyUpdatedByOtherUser]);

    useEffect(() => {
        if (isEditMode) {
            dispatch(loadNarrativeGuides());
        }
    }, [isEditMode, dispatch]);

    return (
        <>
            {isEditMode ? (
                <BriefingEditModeHeader
                    briefing={briefing}
                    disableActions={isRecentlyUpdatedByOtherUser}
                />
            ) : (
                <BriefingViewModeHeader
                    briefing={briefing}
                    disableActions={isRecentlyUpdatedByOtherUser}
                    onModeChange={setMode}
                />
            )}
            <UnderSubheader>
                <ContentWrapper>
                    <BriefingWrapper>
                        {isRecentlyUpdatedByOtherUser && (
                            <InlineBanner
                                status="attention"
                                description={strings.editBannerMessage(updateAuthor, updateTime)}
                                title={strings.editBannerTitle}
                            />
                        )}
                        <BriefingEditor id={briefing.id} body={initialBody} mode={mode} />
                    </BriefingWrapper>
                    {showSidebar && (
                        <BriefingSidebar
                            briefingId={briefing.id}
                            briefingTitle={briefing.title}
                            showTasks={hasTasks}
                            showAttachments={hasAttachments}
                        />
                    )}
                </ContentWrapper>
            </UnderSubheader>
            {canCreateTasks && <TaskSidePanel />}
            {canEdit && (
                <AttachmentsSidePanel overlayId={overlayIdEnum.BRIEFING_ATTACHMENTS_SIDE_PANEL} />
            )}
        </>
    );
};

export default BriefingComponent;
