import React from 'react';
import styled from 'styled-components';
import { FilterMenuRenderProps } from '@arc/filter-list';
import { FormRow } from '@arc/form-control';
import { createField, Observer } from 'markformythree';
import {
    DISPLAY_ONLY_ITEM_IDENTIFIER_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_IDENTIFIER_TYPE_LABEL,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import fieldStrings from '~/client-common/configs/fieldStrings';
import { selectInputContainerMaxContent } from '../../styles/mixins';
import { MFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { MFTText } from '../../forms/components/Text';
import { NItemsFormFilter } from '.';

const StyledFormRow = styled(FormRow)`
    max-width: 32rem;
    ${selectInputContainerMaxContent}
`;

const fieldsConfig = {
    identifierTypeAttrId: createField<number>({}),
    identifier: createField<string>({}),
};

export type IdentifierTypesValue = {
    identifierTypeAttrId: number;
    identifier?: string;
};

const NItemsRow = ({ index }: { index: number }) => {
    const fields = useFields([DISPLAY_ONLY_ITEM_IDENTIFIER_LABEL]);

    return (
        <StyledFormRow>
            <Observer
                subscriptions={{
                    identifierTypeAttrId: `nItems[${index}].identifierTypeAttrId`,
                }}
                render={({ identifierTypeAttrId }, form) => {
                    return (
                        <>
                            <MFTAttributeSelect
                                attributeType="ITEM_IDENTIFIER_TYPE"
                                includeExpired={true}
                                path="identifierTypeAttrId"
                                length="md"
                                onChange={(val) => {
                                    if (!val) {
                                        form.set(`nItems[${index}].identifier`, undefined);
                                    }
                                }}
                                menuPortalTarget={document.body}
                            />
                            <MFTText
                                length="md"
                                label={fields.DISPLAY_ONLY_ITEM_IDENTIFIER_LABEL}
                                path="identifier"
                                disabled={!identifierTypeAttrId}
                            />
                        </>
                    );
                }}
            />
        </StyledFormRow>
    );
};

export const IdentifierTypesFilter: React.FC<FilterMenuRenderProps<IdentifierTypesValue>> = ({
    appliedOptions,
    ...props
}) => {
    const fields = useFields([DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_IDENTIFIER_TYPE_LABEL]);
    return (
        <NItemsFormFilter<IdentifierTypesValue>
            fields={fieldsConfig}
            appliedOptions={appliedOptions}
            name={fieldStrings.attributeTypes.ITEM_IDENTIFIER_TYPE}
            showValue={false}
            render={(props) => <NItemsRow {...props} />}
            addText={fields.DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_IDENTIFIER_TYPE_LABEL}
            {...props}
        />
    );
};
