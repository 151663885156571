import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { EFileView, EntityTypeEnum } from '@mark43/rms-api';
import { lifecycleOptions } from 'markformythree';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { E_FILE_OWNER_USER_ID } from '~/client-common/core/enums/universal/fields';

import HelpText from '../../../../legacy-redux/components/core/HelpText';
import GenericPermissionsModal from '../../../core/permissions/components/GenericPermissionsModal';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { SecondarySectionHeader } from '../../../core/components/typography';
import { entityPermissionsByIdSelector } from '../../../core/permissions/state/ui';
import { useEFileContext, useEFileDisplayName, useEFileTitle } from '../../hooks';
import { isOwnerPermission } from '../../helpers/permissions';

const strings = componentStrings.eFiles.core.eFilePermissionsModal;

const Section = styled.div`
    margin-bottom: 30px;
`;

export const EFilePermissionsModal: FC = () => {
    const {
        getters: { efile },
    } = useEFileContext();

    const eFileView = efile.getEFile();
    const eFileTitle = useEFileTitle();
    const { eFileDisplayName } = useEFileDisplayName();
    const entityPermissionsById = useSelector(entityPermissionsByIdSelector);

    if (!eFileView) {
        return null;
    }

    const { efile: eFile } = eFileView;

    const permissions = entityPermissionsById(EntityTypeEnum.E_FILE.name, eFile.id);
    const disabledRoleIds = permissions
        .filter((permission) => isOwnerPermission(permission, eFile.ownerUserId))
        .map((permission) => permission.roleId);

    return (
        <GenericPermissionsModal
            entityId={eFile.id}
            entityType={EntityTypeEnum.E_FILE.name}
            overlayId={overlayIdEnum.E_FILE_PERMISSIONS_OVERLAY}
            permissionSet={eFile.permissionSet}
            title={strings.title(eFileDisplayName, eFileTitle)}
            headerElement={<EFilePermissionsModalHeader eFileView={eFileView} />}
            formProps={{
                disabledRoleIds,
                lifecycle: lifecycleOptions.REGISTER_AND_UNREGISTER,
            }}
        />
    );
};

const EFilePermissionsModalHeader: FC<{ eFileView: EFileView }> = ({ eFileView }) => {
    const overlayStore = useOverlayStore();
    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);

    const openEFileDetailsModal = () => {
        overlayStore.open(overlayIdEnum.UPSERT_E_FILE_MODAL);
    };

    const fieldDisplayNames = useFields(E_FILE_OWNER_USER_ID);
    const { eFileDisplayName } = useEFileDisplayName();

    const {
        canManage,
        efile: { ownerUserId },
    } = eFileView;

    return (
        <>
            <SecondarySectionHeader onEditClick={canManage ? openEFileDetailsModal : undefined}>
                {fieldDisplayNames.E_FILE_OWNER_USER_ID}
                <HelpText
                    collisionBoundary={document.querySelector('.mark43-react-modal')}
                    content={strings.helpText(
                        eFileDisplayName,
                        fieldDisplayNames.E_FILE_OWNER_USER_ID
                    )}
                />
            </SecondarySectionHeader>
            <Section>
                {ownerUserId
                    ? formatMiniUserById(ownerUserId)
                    : strings.noUserAssigned(eFileDisplayName)}
            </Section>
        </>
    );
};
