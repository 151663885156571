import React, {
    createContext,
    useCallback,
    useContext,
    useState,
    ReactNode,
    FC,
    ComponentType,
} from 'react';
import { useSelector } from 'react-redux';
import {
    SearchResultElasticCase,
    ElasticCase,
    AttributeTypeEnum,
    SearchQuery,
} from '@mark43/rms-api';
import invariant from 'invariant';

import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import SortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import SortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { elasticCasesViewModelForElasticCasesSelector } from '~/client-common/core/domain/elastic-cases/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { MAX_TARGET_PROFILES_IN_BANNER } from '../../configuration';
import attributesResource from '../../../../core/attributes/resources/attributesResource';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';

interface ActiveTargetCasesContextProps {
    callResource: (query: SearchQuery) => Promise<void>;
    resetState: () => void;
    activeTargetCasesViewModels: ReturnType<
        ReturnType<typeof elasticCasesViewModelForElasticCasesSelector>
    >;
    isActiveTarget: boolean;
}

const ActiveTargetCasesContext = createContext<ActiveTargetCasesContextProps | undefined>(
    undefined
);

export const useActiveTargetCases = (): ActiveTargetCasesContextProps => {
    const context = useContext(ActiveTargetCasesContext);

    invariant(context, 'useActiveTargetCases must be used within an ActiveTargetCasesProvider');

    return context;
};

interface ActiveTargetCasesProviderProps {
    children: ReactNode;
}

const ActiveTargetCasesProvider: FC<ActiveTargetCasesProviderProps> = ({ children }) => {
    const [activeTargetCases, setActiveTargetCases] = useState<ElasticCase[]>([]);

    const elasticCasesViewModelsSelector = useSelector(
        elasticCasesViewModelForElasticCasesSelector
    );

    const loadTargetProfiles = useCallback((searchQuery: SearchQuery) => {
        return attributesResource
            .getAttributesOfParentAttr({
                parentId: globalAttributes.caseStatus.open,
                attributeType: AttributeTypeEnum.CASE_STATUS.name,
            })
            .then((result) => result.map((attr) => attr.id))
            .then((activeStatusAttrIds) =>
                elasticSearchResource.searchCases(
                    {
                        isTargetProfile: true,
                        currentStatusAttrIds: activeStatusAttrIds,
                        ...searchQuery,
                    },
                    0,
                    MAX_TARGET_PROFILES_IN_BANNER,
                    SortKeyEnum.CASE_ASSIGNED_DATE_UTC,
                    SortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                    undefined,
                    true
                )
            );
    }, []);

    const onResourceSuccess = useCallback((result: SearchResultElasticCase) => {
        setActiveTargetCases(result.items);
    }, []);

    const { callResource } = useResourceDeferred(loadTargetProfiles, onResourceSuccess);

    const activeTargetCasesViewModels = elasticCasesViewModelsSelector(activeTargetCases);

    const resetState = useCallback(() => {
        setActiveTargetCases([]);
    }, []);

    return (
        <ActiveTargetCasesContext.Provider
            value={{
                callResource,
                resetState,
                activeTargetCasesViewModels,
                isActiveTarget: !!activeTargetCasesViewModels.length,
            }}
        >
            {children}
        </ActiveTargetCasesContext.Provider>
    );
};

export const withActiveTargetCases =
    <P,>(Component: ComponentType<P>): ComponentType<P> =>
    (props) => {
        return (
            <ActiveTargetCasesProvider>
                <Component {...props} />
            </ActiveTargetCasesProvider>
        );
    };
