import React from 'react';
import styled from 'styled-components';
import { FilterMenuRenderProps } from '@arc/filter-list';
import { FormRow } from '@arc/form-control';
import { createField, Observer } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    DISPLAY_ONLY_EVIDENCE_SEARCH_PERSONNEL_NAME_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_PERSONNEL_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_INVOLVEMENT_STATUS_LABEL,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { selectInputContainerMaxContent } from '../../styles/mixins';
import { MFTPersonnelInvolvementStatusSelect } from '../../forms/components/selects/PersonnelInvolvementStatusSelect';
import { MFTUserSelect } from '../../forms/components/selects/UserSelect';
import { NItemsFormFilter } from '.';

export const involvedPersonsFilterName =
    componentStrings.evidence.dashboard.filterForm.involvedPersons;

const StyledFormRow = styled(FormRow)`
    max-width: 32rem;
    ${selectInputContainerMaxContent}
`;

const fieldsConfig = {
    personnelId: createField<number>({}),
    involvementStatus: createField<string>({}),
};

export type InvolvedPersonsValueT = {
    personnelId: number;
    involvementStatus?: string;
};

const NItemsRow = ({ index }: { index: number }) => {
    const fields = useFields([
        DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_PERSONNEL_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_PERSONNEL_NAME_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_INVOLVEMENT_STATUS_LABEL,
    ]);

    return (
        <StyledFormRow>
            <Observer
                subscriptions={{
                    personnelId: `nItems[${index}].personnelId`,
                }}
                render={({ personnelId }, form) => {
                    return (
                        <>
                            <MFTUserSelect
                                length="md"
                                multiple={false}
                                label={fields.DISPLAY_ONLY_EVIDENCE_SEARCH_PERSONNEL_NAME_LABEL}
                                onChange={(val) => {
                                    if (!val) {
                                        form.set(`nItems[${index}].involvementStatus`, undefined);
                                    }
                                }}
                                path="personnelId"
                                menuPortalTarget={document.body}
                                excludeUsersWithInactiveDutyStatus={false}
                            />
                            <MFTPersonnelInvolvementStatusSelect
                                length="md"
                                label={fields.DISPLAY_ONLY_EVIDENCE_SEARCH_INVOLVEMENT_STATUS_LABEL}
                                path="involvementStatus"
                                disabled={!personnelId}
                                menuPortalTarget={document.body}
                            />
                        </>
                    );
                }}
            />
        </StyledFormRow>
    );
};

export const InvolvedPersonsFilter: React.FC<FilterMenuRenderProps<InvolvedPersonsValueT>> = ({
    appliedOptions,
    ...props
}) => {
    return (
        <NItemsFormFilter<InvolvedPersonsValueT>
            fields={fieldsConfig}
            appliedOptions={appliedOptions}
            showValue={false}
            name={involvedPersonsFilterName}
            render={(props) => <NItemsRow {...props} />}
            addText={`Add ${involvedPersonsFilterName}`}
            {...props}
        />
    );
};
