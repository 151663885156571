import * as React from 'react';
import {
    _Form,
    MFTFormConfiguration,
    MFTFormConfigurationDescriminator,
    FormConstructorProps,
    formEvents,
    Ui,
} from 'markformythree';
import { set } from 'lodash';
import { FormReferenceReplaceRequest, DragonFormsResource } from '@mark43/rms-api';
import { useSelector, useDispatch } from 'react-redux';
import { FormConfiguration, FormReference } from 'dragon-react';
import { Required } from 'utility-types';
import { useDragonConfigDataContext } from '../../context/dragon-config-data';
import { req } from '../../../../lib/ajax';
import formsRegistry from '../../../../core/formsRegistry';
import Card from '../../../../legacy-redux/components/core/Card';
import errorToMessage from '../../../core/errors/utils/errorToMessage';
import { useDragonCurrentFormNestingPathContext } from '../../context/dragon-current-form';
import { dragonFormValueReplaceAction } from '../../dragonRedux';
import createCard, { destroyCard } from '../../../reports/core/utils/createCard';
import { editMediator } from '../../../reports/core/state/ui/submissions';
import {
    focusFirstElementOnCard,
    scrollToCardAtOffsetAndFocusFirstElement,
} from '../../../reports/core/utils/cardHelpers';
import { registerCard } from '../../../reports/core/utils/cardsRegistry';
import { registerForm } from '../../../reports/core/state/ui';
import { canEditReportCardStatusSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import { getConfiguredFormPath } from '../../utils/get-configured-form-path';
import { uiConfigurationIdToNumber } from '../../utils/ui-configuration-id-to-number';
import { createDragonFormAnchor } from './dragonReportSidebar';
import { syncCoreInstancesAction } from './state';
import {
    setDisplayStateForConfiguredFormInstance,
    removeDisplayStateForConfiguredFormInstance,
} from './state/card-display-mode-tracker';

const IsNewReportContext = React.createContext(false);

export const IsNewReportContextProvider = IsNewReportContext.Provider;

const useIsNewReportContext = () => {
    const val = React.useContext(IsNewReportContext);
    return val;
};

type DragonCardState = {
    isSaving: boolean;
    isInSummaryMode: boolean;
    errors?: string[];
};

type DragonCardSavingAction = {
    type: 'SAVING';
    payload: boolean;
};

type DragonCardSavingFailedAction = {
    type: 'SAVING_FAILED';
    payload: string[];
};

type DragonCardSummaryModeRequestedAction = {
    type: 'SUMMARY_MODE_REQUESTED';
};

type DragonCardFormModeRequestedAction = {
    type: 'FORM_MODE_REQUESTED';
};

type DragonCardActions =
    | DragonCardSavingAction
    | DragonCardFormModeRequestedAction
    | DragonCardSummaryModeRequestedAction
    | DragonCardSavingFailedAction;

function dragonCardReducer(state: DragonCardState, action: DragonCardActions): DragonCardState {
    switch (action.type) {
        case 'FORM_MODE_REQUESTED':
            return {
                ...state,
                isInSummaryMode: false,
            };
        case 'SAVING':
            return {
                ...state,
                errors: [],
                isSaving: action.payload,
            };
        case 'SAVING_FAILED':
            return {
                ...state,
                errors: action.payload,
                isSaving: false,
            };
        case 'SUMMARY_MODE_REQUESTED':
            return {
                isSaving: false,
                isInSummaryMode: true,
            };
        default:
            return state;
    }
}

export type DragonMFTFormConstructorProps<T extends MFTFormConfiguration = MFTFormConfiguration> =
    Required<FormConstructorProps<T>, 'configuration' | 'validationEvents' | 'onValidate'>;

function createForm({
    name,
    initialState,
    configuration,
    validationEvents,
    onValidate,
}: DragonMFTFormConstructorProps): _Form<MFTFormConfiguration> {
    return new _Form({
        name,
        initialState,
        configuration,
        validationEvents,
        onValidate,
    });
}

interface MFTUi {
    [index: string]: MFTUi | Ui;
}

function isUi(value: unknown): value is Ui {
    return typeof value === 'object' && value !== null && 'hidden' in value;
}

function unsetValuesForHiddenFields(
    form: _Form<MFTFormConfiguration>,
    model: Record<string, unknown>,
    ui: MFTUi,
    parentPath = '',
    ancestorIsHidden = false
) {
    const keys = Object.keys(ui).filter((key) =>
        // FIXME using our prefix convention to work around an issue with MFT setting ui state for select option objects
        key.startsWith('_')
    );
    const hidden = ancestorIsHidden || form.getUi(parentPath)?.hidden;

    // FIXME this does not work for nItems
    for (const key of keys) {
        const value = ui[key];
        const currentPath = parentPath ? `${parentPath}.${key}` : key;
        const nestedHidden = hidden || form.getUi(currentPath)?.hidden;
        if (nestedHidden) {
            set(model, currentPath, null);
        }
        if (value && !isUi(value)) {
            unsetValuesForHiddenFields(form, model, value, currentPath, nestedHidden);
        }
    }
}

export const DragonCard: React.FC<{
    formConfiguration: FormConfiguration;
    formReference: FormReference;
    instanceId: string | number;
    renderSummary: () => JSX.Element | null;
    testId?: string;
    formProps: DragonMFTFormConstructorProps;
}> = ({
    instanceId,
    formConfiguration,
    formReference,
    children,
    renderSummary,
    testId,
    formProps,
}) => {
    const { reportId } = useDragonConfigDataContext();
    const canEditReportCardStatus = useSelector(canEditReportCardStatusSelector);
    const reduxDispatch = useDispatch();
    const isNewReport = useIsNewReportContext();
    const currentFormNestingPath = useDragonCurrentFormNestingPathContext();
    const [state, dispatch] = React.useReducer(dragonCardReducer, {
        isSaving: false,
        isInSummaryMode: !isNewReport,
    });
    const { name } = formProps;

    // Using a ref for now because we are passing this value around in a closure and we need to ensure that we always
    // call the correct version in case it is being re-created.
    const onSaveRef = React.useRef<
        undefined | ((config?: { isReportSubmission?: boolean }) => () => Promise<void>)
    >(undefined);
    const cardObjectRef = React.useRef<
        undefined | { anchor: string; actionCreators: { transitionToEditMode: () => void } }
    >();
    const anchor = createDragonFormAnchor(formConfiguration.displayName, instanceId);
    onSaveRef.current =
        ({ isReportSubmission } = {}) =>
        () => {
            const form = formsRegistry.get<MFTFormConfigurationDescriminator>(name, instanceId);

            // The MFT registry types suggest it's possible that this may be undefined, though this
            // should not be the case in practice for Dragon forms
            if (!form) {
                // eslint-disable-next-line no-console
                console.error(`Form ${name} (${instanceId}) could not be found in forms registry.`);
                return Promise.resolve();
            }

            return form
                .submit()
                .then(async ({ form }) => {
                    dispatch({ type: 'SAVING', payload: true });
                    const { model, ui } = form.getState();

                    // mutate the form model
                    unsetValuesForHiddenFields(form, model, ui);
                    // TODO move this conversion into dragon space so that consumers don't have to deal with this
                    // Can we just pass a `submit` function into this handler which does the below for us and allows us to just chain onto it?
                    const parsedInstanceId = parseInt(instanceId.toString(), 10);
                    const request: FormReferenceReplaceRequest = {
                        formConfigurationId: formReference.formConfigurationId,
                        instanceId: parsedInstanceId,
                        values: model,
                        parentReferencingUiConfigurationId: uiConfigurationIdToNumber(
                            formReference.id
                        ),
                        parentFormPathInstance: getConfiguredFormPath(currentFormNestingPath),
                    };

                    try {
                        const result = await req<DragonFormsResource.ReplaceFormInstance>({
                            url: 'dragon/forms/replace',
                            method: 'PUT',
                            data: request,
                        });
                        // setting values in redux will automatically cause the form to unmount and remount,
                        // reinitializing its state with the new form values. Because of this we do not have
                        // to set values directly on the form. Setting values directly will cause a `MODEL_CHANGE`
                        // event to be triggered in addition to our `FORM_MOUNT` validation we are running when a card
                        // mounts. Running both would cause issues with form state due to validation being async and
                        // the form being remounted while being validated. Due to this we rely only on our `FORM_MOUNT`
                        // validation
                        reduxDispatch(
                            dragonFormValueReplaceAction({
                                formValues: result.configuredFormView.formValues[0],
                                reportId,
                            })
                        );
                        reduxDispatch(
                            syncCoreInstancesAction(
                                result.inlineFormReferenceDeleteResponseView.deletedCoreInstances,
                                result.inlineFormReferenceDeleteResponseView.updatedCoreInstances
                            )
                        );
                        dispatch({ type: 'SUMMARY_MODE_REQUESTED' });
                        setDisplayStateForConfiguredFormInstance({
                            configuredFormId: formReference.formConfigurationId,
                            instanceId: parsedInstanceId,
                            mode: 'SUMMARY',
                        });
                        scrollToCardAtOffsetAndFocusFirstElement({
                            startingAnchor: anchor,
                            offset: 1,
                        });
                    } catch (e) {
                        return Promise.reject({
                            validationResult: {
                                formErrors: [errorToMessage(e)],
                            },
                        });
                    }
                })
                .catch(({ validationResult }) => {
                    dispatch({
                        type: 'SAVING_FAILED',
                        payload: validationResult.formErrors,
                    });
                    scrollToCardAtOffsetAndFocusFirstElement({
                        startingAnchor: anchor,
                        offset: 0,
                    });
                    if (isReportSubmission) {
                        // eslint-disable-next-line
                        throw ['Form Invalid Placeholder'];
                    }
                });
        };

    /**
     * This effect is used to sync our local card UI state to our singleton
     * so we can at any give time see what mode a form is in.
     * This is used to determine whether we need to update only summary values
     * or also form values when core entities change.
     */
    React.useEffect(() => {
        setDisplayStateForConfiguredFormInstance({
            configuredFormId: formReference.formConfigurationId,
            instanceId: parseInt(instanceId.toString(), 10),
            mode: state.isInSummaryMode ? 'SUMMARY' : 'FORM',
        });
    }, [instanceId, formReference.formConfigurationId, state.isInSummaryMode]);

    React.useEffect(() => {
        return () => {
            removeDisplayStateForConfiguredFormInstance({
                configuredFormId: formReference.formConfigurationId,
                instanceId: parseInt(instanceId.toString(), 10),
            });
        };
    }, [instanceId, formReference.formConfigurationId, state.isInSummaryMode]);

    React.useEffect(() => {
        // we are creating the new MFT form instance here so that it is available even if the form itself isn't rendered
        // NOTE: right now this will trigger a re-creation of the form when `initialState` changes, i.e. after the form has been saved.
        const existingForm = formsRegistry.get(name, instanceId);

        // Only create the form if it doesn't already exist. Without this, we can end up in a "race condition"
        // where an MFT form that mounts below creates/registers a form, which this block will then clobber
        // if not wrapped with the check for the existing form
        if (!existingForm) {
            const mftForm = createForm(formProps);
            registerForm({ form: mftForm, index: instanceId });
        }
        formsRegistry.maybeDeferredOperation(name, instanceId, (form) => {
            form.validate({ eventType: formEvents.FORM_MOUNT, path: '' });
        });
        return () => {
            formsRegistry.unregister(name, instanceId);
        };
    }, [formProps, name, instanceId]);

    React.useEffect(() => {
        const cardName = `${name}-${instanceId}`;

        // In the future we will need to add an index to support NItems of forms: https://mark43.atlassian.net/browse/DRGN-342
        // @ts-expect-error: TS infers the types incorrectly here
        cardObjectRef.current = createCard({
            name: cardName,
            baseSelector: () => ({}),
            anchor,
        });
        // @ts-expect-error: TS infers the types incorrectly here
        registerCard({
            cardModule: {
                ...cardObjectRef.current,
                actionCreators: {
                    ...(cardObjectRef.current?.actionCreators ?? {}),
                    transitionToEditMode() {
                        dispatch({ type: 'FORM_MODE_REQUESTED' });
                    },
                    edit: () => {
                        return editMediator(() => {
                            dispatch({ type: 'FORM_MODE_REQUESTED' });
                        });
                    },
                },
            },
            onSave: () => onSaveRef.current?.({ isReportSubmission: true })() ?? Promise.resolve(),
        });

        // unlike "static" cards we have to destroy this card as it will be re-created when
        // we load the same report again. Most of the existing card functionality isn't applicable to
        // the dragon cards anyways. We will have to rewrite a fair bit of logic soon to account for this.
        return () => destroyCard(cardName);
    }, [name, anchor, instanceId]);

    // After a card is put into edit mode, its first field should be focused.
    // It's worth noting that as of the time that I'm writing this comment, this behaviour
    // is bugged / not working for many or most cards due to a timing issue.
    React.useEffect(() => {
        if (!state.isInSummaryMode && !isNewReport) {
            const cardObjectAnchor = cardObjectRef.current ? cardObjectRef.current.anchor : '';
            focusFirstElementOnCard(cardObjectAnchor);
        }
    }, [state.isInSummaryMode, isNewReport]);

    return (
        <Card
            title={formConfiguration.displayName}
            anchor={anchor}
            summaryMode={state.isInSummaryMode}
            canEdit={canEditReportCardStatus ? canEditReportCardStatus.canEditReportCard : false}
            canEditErrorMessage={
                canEditReportCardStatus ? canEditReportCardStatus.errorMessage : undefined
            }
            saving={state.isSaving}
            testId={testId}
            errors={state.errors}
            onEdit={() => {
                reduxDispatch(
                    editMediator(() => {
                        dispatch({ type: 'FORM_MODE_REQUESTED' });
                    })
                );
            }}
            onSave={onSaveRef.current({ isReportSubmission: false })}
            renderContent={(summaryMode: boolean) => {
                return summaryMode ? <div>{renderSummary()}</div> : children;
            }}
        />
    );
};
