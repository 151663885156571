import { DiagramCategoryEnumType, DiagramTypeEnumType } from '@mark43/rms-api';
import { CrashDiagramPosition, Widget, WidgetAction } from '../../../types';
import { M43Asset, ExternalAsset } from '../../../../../../../../diagram/types';
import { CrashDiagramState } from '.';
/* ACTION TYPES */
const actionTypes = {
    ADD_WIDGET_TO_DIAGRAM: 'ADD_WIDGET_TO_DIAGRAM',
    REMOVE_WIDGET_FROM_DIAGRAM: 'REMOVE_WIDGET_FROM_DIAGRAM',
    TRANFORM_WIDGET_ON_DIAGRAM: 'TRANFORM_WIDGET_ON_DIAGRAM',
    SET_SELECTED_WIDGET: 'SET_SELECTED_WIDGET',
    CLEAR_SELECTED_WIDGET: 'CLEAR_SELECTED_WIDGET',
    CLEAR_DIAGRAM: 'CLEAR_DIAGRAM',
    RESET_DIAGRAM: 'RESET_DIAGRAM',
    SET_DIAGRAM_BACKGROUND_IMAGE: 'SET_DIAGRAM_BACKGROUND_IMAGE',
    UPDATE_WIDGET_VALUE: 'UPDATE_WIDGET_VALUE',
    DUPLICATE_WIDGET_ON_DIAGRAM: 'DUPLICATE_WIDGET_ON_DIAGRAM',
    CREATE_GHOST_WIDGETS_ON_DIAGRAM: 'CREATE_GHOST_WIDGETS_ON_DIAGRAM',
    SET_WIDGET_OPACITY_ON_DIAGRAM: 'SET_WIDGET_OPACITY_ON_DIAGRAM',
    WIDGET_ACTION_TYPE: 'WIDGET_ACTION_TYPE',
    SET_CRASH_DIAGRAM_STATE: 'SET_CRASH_DIAGRAM_STATE',
    SET_DIAGRAM_ASSETS: 'SET_DIAGRAM_ASSETS',
    ADD_EXTERNAL_DIAGRAM_ASSET: 'ADD_EXTERNAL_DIAGRAM_ASSET',
} as const;

/* ACTIONS */

export const setWidgetOpacityOnDiagram = (widgetId: string, opacity: number) => {
    return {
        type: actionTypes.SET_WIDGET_OPACITY_ON_DIAGRAM,
        payload: {
            widgetId,
            opacity,
        },
    } as const;
};

export const applyWidgetActionOnDiagram = (widgetId: string, action: WidgetAction) => {
    return {
        type: actionTypes.WIDGET_ACTION_TYPE,
        payload: {
            widgetId,
            action,
        },
    } as const;
};

export const duplicateWidgetOnDiagram = (widgetId: string) => {
    return {
        type: actionTypes.DUPLICATE_WIDGET_ON_DIAGRAM,
        payload: {
            widgetId,
        },
    } as const;
};

export const createGhostWidgetsOnDiagram = (widgetId: string, numberOfDuplicates: number) => {
    return {
        type: actionTypes.CREATE_GHOST_WIDGETS_ON_DIAGRAM,
        payload: {
            widgetId,
            numberOfDuplicates,
        },
    } as const;
};

export const addWidgetToDiagram = (widget: Widget) => {
    return {
        type: actionTypes.ADD_WIDGET_TO_DIAGRAM,
        payload: {
            widget,
        },
    } as const;
};

export const removeWidgetFromDiagram = (widgetId: string) => {
    return {
        type: actionTypes.REMOVE_WIDGET_FROM_DIAGRAM,
        payload: {
            widgetId,
        },
    } as const;
};

export const moveWidgetOnDiagram = (widgetId: string, position: CrashDiagramPosition) => {
    return {
        type: actionTypes.TRANFORM_WIDGET_ON_DIAGRAM,
        payload: {
            widgetId,
            position,
        },
    } as const;
};

export const setSelectedWidget = (widget: Widget) => {
    return {
        type: actionTypes.SET_SELECTED_WIDGET,
        payload: {
            widget,
        },
    } as const;
};

export const clearSelectedWidget = () => {
    return {
        type: actionTypes.CLEAR_SELECTED_WIDGET,
    } as const;
};

export const clearDiagram = () => {
    return {
        type: actionTypes.CLEAR_DIAGRAM,
    };
};

export const resetDiagram = () => {
    return {
        type: actionTypes.RESET_DIAGRAM,
    };
};
export const setDiagramBackgroundImage = (backgroundImage: string) => {
    return {
        type: actionTypes.SET_DIAGRAM_BACKGROUND_IMAGE,
        payload: {
            backgroundImage,
        },
    } as const;
};

export const updateWidgetValue = (widgetId: string, value: Widget['value'], commit: boolean) => {
    return {
        type: actionTypes.UPDATE_WIDGET_VALUE,
        payload: {
            widgetId,
            value,
            commit,
        },
    } as const;
};

export const setCrashDiagramState = (crashDiagramState: CrashDiagramState) => {
    return {
        type: actionTypes.SET_CRASH_DIAGRAM_STATE,
        payload: {
            crashDiagramState,
        },
    };
};

export const setDiagramAssets = (
    diagramType: DiagramTypeEnumType,
    diagramCategory: DiagramCategoryEnumType,
    assets: M43Asset[]
) => {
    return {
        type: actionTypes.SET_DIAGRAM_ASSETS,
        payload: {
            diagramType,
            diagramCategory,
            assets,
        },
    };
};

export const addExternalDiagramAsset = (
    diagramType: DiagramTypeEnumType,
    diagramCategory: DiagramCategoryEnumType,
    asset: ExternalAsset
) => {
    return {
        type: actionTypes.ADD_EXTERNAL_DIAGRAM_ASSET,
        payload: {
            diagramType,
            diagramCategory,
            asset,
        },
    };
};

export type CrashDiagramActionTypes = ReturnType<
    | typeof addWidgetToDiagram
    | typeof removeWidgetFromDiagram
    | typeof moveWidgetOnDiagram
    | typeof clearDiagram
    | typeof resetDiagram
    | typeof setSelectedWidget
    | typeof clearSelectedWidget
    | typeof setDiagramBackgroundImage
    | typeof updateWidgetValue
    | typeof duplicateWidgetOnDiagram
    | typeof createGhostWidgetsOnDiagram
    | typeof applyWidgetActionOnDiagram
    | typeof setCrashDiagramState
    | typeof setWidgetOpacityOnDiagram
    | typeof setDiagramAssets
    | typeof addExternalDiagramAsset
>;
