import {
    EFileView,
    SearchResultEFileNameSearchView,
    InvolvedProfilesForImport,
    EFileDefendantOrganizationView,
    EFileDefendantPersonView,
    EFileNameSearchRequest,
} from '@mark43/rms-api';
import React, { useCallback } from 'react';
import {
    setEfileAction,
    EFileActionTypes,
    setEfileProfilesAction,
    setEFileProfilesSearchRequestAction,
    resetEFileProfilesSearchAction,
    setEFileInvolvedProfilesToImportAction,
    setEFileSelectedInvolvedProfilesToImportAction,
    resetEFileSidePanelAction,
    setEfileDefendantOrganizationProfileAction,
    setEfileDefendantPersonProfileAction,
    setEFileSelectedInvolvedProfilesAction,
    resetEFileGridAction,
} from '../contexts/actions';
import { EFileActionsT, EFileSearchRequest, SelectedNameEntityT } from '../types';

/**
 * This hook can only be used within the E-File Module and the eFileContext
 * Using this outside of the module will cause errors
 * @returns EFileActionsT
 */

export function useEFileActions(dispatch: React.Dispatch<EFileActionTypes>): EFileActionsT {
    const setEfile = useCallback(
        (eFile: EFileView) => {
            dispatch(setEfileAction(eFile));
        },
        [dispatch]
    );

    const setEFileProfiles = useCallback(
        (profilesSearchResult: SearchResultEFileNameSearchView) => {
            dispatch(setEfileProfilesAction(profilesSearchResult));
        },
        [dispatch]
    );

    const setEFileProfilesSearchRequest = useCallback(
        (searchRequest: EFileSearchRequest<EFileNameSearchRequest>) => {
            dispatch(setEFileProfilesSearchRequestAction(searchRequest));
        },
        [dispatch]
    );

    const resetEFileProfilesSearch = useCallback(() => {
        dispatch(resetEFileProfilesSearchAction());
    }, [dispatch]);

    const setEFileDefendantPersonProfile = useCallback(
        (personProfile: EFileDefendantPersonView) => {
            dispatch(setEfileDefendantPersonProfileAction(personProfile));
        },
        [dispatch]
    );
    const setEFileDefendantOrganizationProfile = useCallback(
        (organizationProfile: EFileDefendantOrganizationView) => {
            dispatch(setEfileDefendantOrganizationProfileAction(organizationProfile));
        },
        [dispatch]
    );

    const setEFileInvolvedProfilesToImport = useCallback(
        (involvedProfilesForImport: InvolvedProfilesForImport) => {
            dispatch(setEFileInvolvedProfilesToImportAction(involvedProfilesForImport));
        },
        [dispatch]
    );

    const setEFileSelectedInvolvedProfilesToImport = useCallback(
        (selectedInvolvedProfilesToImport: SelectedNameEntityT[]) => {
            dispatch(
                setEFileSelectedInvolvedProfilesToImportAction(selectedInvolvedProfilesToImport)
            );
        },
        [dispatch]
    );

    const resetEFileSidePanel = useCallback(() => {
        dispatch(resetEFileSidePanelAction());
    }, [dispatch]);

    const setEFileSelectedInvolvedProfiles = useCallback(
        (selectedDefendants: SelectedNameEntityT[]) => {
            dispatch(setEFileSelectedInvolvedProfilesAction(selectedDefendants));
        },
        [dispatch]
    );

    const resetEFileGrid = useCallback(() => {
        dispatch(resetEFileGridAction());
    }, [dispatch]);

    return React.useMemo(() => {
        return {
            efile: {
                setEfile,
            },
            search: {
                setEFileProfiles,
                setEFileProfilesSearchRequest,
                resetEFileProfilesSearch,
            },
            sidePanel: {
                setEFileInvolvedProfilesToImport,
                setEFileSelectedInvolvedProfilesToImport,
                resetEFileSidePanel,
            },
            selectedEFileEntity: {
                setEFileDefendantPersonProfile,
                setEFileDefendantOrganizationProfile,
            },
            grid: {
                setEFileSelectedInvolvedProfiles,
                resetEFileGrid,
            },
        };
    }, [
        resetEFileSidePanel,
        resetEFileProfilesSearch,
        setEFileDefendantOrganizationProfile,
        setEFileDefendantPersonProfile,
        setEFileProfiles,
        setEFileProfilesSearchRequest,
        setEFileInvolvedProfilesToImport,
        setEFileSelectedInvolvedProfilesToImport,
        setEfile,
        setEFileSelectedInvolvedProfiles,
        resetEFileGrid,
    ]);
}
