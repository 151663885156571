import React, { Dispatch, SetStateAction } from 'react';
import { Button, HStack } from 'arc';
import { Form } from 'markformythree';
import { EFileNameSearchFilter, LinkTypesEnum } from '@mark43/rms-api';
import { getDropdownOptionsFromObject } from '~/client-common/helpers/dropdownOptionHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Row from '../../../../core/components/Row';
import { MFTSelect } from '../../../../core/forms/components/selects/Select';
import { MFTText } from '../../../../core/forms/components/Text';
import { InvolvedProfileLinkType } from '../../../types';
import testIds from '../../../../../core/testIds';
import { useFormGetter } from '../../../../core/forms/hooks/useFormGetter';
import { getSearchFormConfig, getSearchFormName } from '../../../helpers/searchForm';

interface EFileProfilesSearchFormProps {
    linkType: InvolvedProfileLinkType;
    setFilters: Dispatch<SetStateAction<EFileNameSearchFilter | undefined>>;
}

const strings = componentStrings.eFiles.profiles.filters;
const FIELD_WIDTH = 246;

export const EFileProfilesSearchForm = ({ linkType, setFilters }: EFileProfilesSearchFormProps) => {
    const { getForm } = useFormGetter();
    const formName = getSearchFormName(linkType);
    const form = getForm(formName);
    const onSubmit = async () => {
        if (!form) {
            return;
        }
        const success = await form.submit();
        if (success) {
            setFilters(form.getState().model);
        }
    };
    const onReset = () => {
        if (!form) {
            return;
        }
        form.resetModel();
    };
    const isDefendantsView = linkType === LinkTypesEnum.DEFENDANT_IN_EFILE;
    const isVictimWitnessView = linkType === LinkTypesEnum.INVOLVED_PROFILE_IN_EFILE;
    const formConfig = getSearchFormConfig(linkType);
    if (!formConfig) {
        return null;
    }
    const renderSentToCPSField = (hasRightMargin: boolean) => (
        <MFTText
            data-test-id={testIds.E_FILE_PROFILE_SEARCH_FORM_SENT_TO_CPS}
            label={strings.sentToCPS.label}
            path="sentToCPS"
            style={{ marginRight: hasRightMargin ? 10 : 0 }}
            width={FIELD_WIDTH}
        />
    );
    return (
        <Form
            {...formConfig}
            render={() => (
                <>
                    <Row>
                        <MFTText
                            data-test-id={testIds.E_FILE_PROFILE_SEARCH_FORM_FIRST_NAME}
                            label={strings.firstName.label}
                            path="firstName"
                            width={FIELD_WIDTH}
                        />
                        <MFTText
                            data-test-id={testIds.E_FILE_PROFILE_SEARCH_FORM_LAST_NAME}
                            label={strings.lastName.label}
                            path="lastName"
                            width={FIELD_WIDTH}
                        />
                        {isVictimWitnessView && renderSentToCPSField(false)}
                        {isDefendantsView && (
                            <MFTText
                                data-test-id={testIds.E_FILE_PROFILE_SEARCH_FORM_ORGANIZATION_NAME}
                                label={strings.organizationName.label}
                                path="organizationName"
                                style={{ marginRight: 0 }}
                                width={FIELD_WIDTH}
                            />
                        )}
                    </Row>
                    <Row>
                        {isVictimWitnessView && (
                            <>
                                <MFTSelect
                                    data-test-id={testIds.E_FILE_PROFILE_SEARCH_FORM_INVOLVEMENT}
                                    label={strings.involvementSelect.label}
                                    options={getDropdownOptionsFromObject(
                                        strings.involvementSelect.options
                                    )}
                                    path="involvement"
                                    width={FIELD_WIDTH}
                                />
                                <MFTSelect
                                    data-test-id={testIds.E_FILE_PROFILE_SEARCH_FORM_KEY_WITNESS}
                                    label={strings.keyWitnessSelect.label}
                                    options={getDropdownOptionsFromObject(
                                        strings.keyWitnessSelect.options
                                    )}
                                    path="keyWitness"
                                    style={{ marginRight: 0 }}
                                    width={FIELD_WIDTH}
                                />
                            </>
                        )}
                        {isDefendantsView && (
                            <>
                                {renderSentToCPSField(true)}
                                <MFTSelect
                                    data-test-id={testIds.E_FILE_PROFILE_SEARCH_FORM_DECISION}
                                    label={strings.decisionSelect.label}
                                    options={getDropdownOptionsFromObject(
                                        strings.decisionSelect.options
                                    )}
                                    path="decision"
                                    width={FIELD_WIDTH}
                                />
                            </>
                        )}
                    </Row>
                    <Row>
                        <HStack>
                            <Button variant="solid" type="submit" onClick={onSubmit}>
                                {strings.actionButtons.apply}
                            </Button>
                            <Button variant="outline" onClick={onReset}>
                                {strings.actionButtons.clearAllFilters}
                            </Button>
                        </HStack>
                    </Row>
                </>
            )}
        />
    );
};
