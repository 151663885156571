import {
    createField,
    createFormConfiguration,
    InferFormDataShape,
} from 'markformythree';
import { E_FILE_ENTITY_REMOVE_DEACTIVATE_RATIONALE } from '~/client-common/core/enums/universal/fields';

export const eFileProfileRemoveDeactivateFormConfiguration = createFormConfiguration({
    rationale: createField<string>({ fieldName: E_FILE_ENTITY_REMOVE_DEACTIVATE_RATIONALE }),
});

export type EFileProfileRemoveDeactivateFormConfiguration = typeof eFileProfileRemoveDeactivateFormConfiguration;
type EFileProfileRemoveDeactivateFormDataShape = InferFormDataShape<EFileProfileRemoveDeactivateFormConfiguration>;
interface EFileProfileRemoveDeactivateFields {
    rationale: string;
}

export function convertFromFormModel(eFileProfileRemoveDeactivateFields: EFileProfileRemoveDeactivateFormDataShape): EFileProfileRemoveDeactivateFields {
    const { rationale } = eFileProfileRemoveDeactivateFields;

    return {
        rationale: rationale || '',
    };
}

export const initialState = {
    rationale: '',
}
