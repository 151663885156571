import { EntityTypeEnum, OperationTypeEnum } from '@mark43/rms-api';
import { TableRow, TableColumn, TableCell } from 'components-mark43';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import keyMirror from 'keymirror';
import styled from 'styled-components';
import { compose, withPropsOnChange } from 'recompose';
import { isEmpty, map, find, filter, get, includes, values, head } from 'lodash';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { taskEntityLinksSelector } from '~/client-common/core/domain/task-entity-links/state/data';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { convertAttachmentsToAttachmentViewModel } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sqlSortKeyEnum from '~/client-common/core/enums/universal/sqlSortKeyEnum';
import { CustomLink, PopoutLink } from '../../../core/components/links/Link';
import RMSTableComposite from '../../../core/components/RMSTableComposite';
import AttachmentsIndicator from '../../../attachments/core/components/AttachmentsIndicator';
import testIds from '../../../../core/testIds';
import { abilitiesEnum } from '../../../core/abilities';
import {
    currentUserHasAbilitySelector,
    currentUserIdSelector,
} from '../../../core/current-user/state/ui';
import { convertToFormModel } from '../forms/taskForm';
import { canEditTaskSelector, openTaskSidePanelForTask, TaskOverlayMode } from '../state/ui';
import { taskAttachmentsMapSelector } from '../data';
import { tasksDashboardSearch } from '../../dashboard/state/ui';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import { BRIEFINGS_ROUTES } from '../../../briefings/configuration/routes';
import { TaskContextProvider } from '../hooks/useTaskContext';
import { useLoadTaskAttributes } from '../hooks/useLoadTaskAttributes';
import { useTaskStatusOptions } from '../hooks/useTaskStatusOptions';
import TaskDateCell from './TaskDateCell';
import TaskPopOverMenu from './TaskPopOverMenu';
import TaskSidePanel from './TaskSidePanel';
import TaskTableTooltip from './TaskTableTooltip';
import TaskStatusCell from './TaskStatusCell';

const strings = componentStrings.tasks.core.TaskTable;
const { columnHeaders, sorts } = strings;

export const columnKeys = keyMirror({
    title: null,
    source: null,
    assigneeRoleId: null,
    dueDateUtc: null,
    updatedDateUtc: null,
    createdDateUtc: null,
    typeAttrId: null,
    priorityAttrId: null,
    statusAttrId: null,
});

const taskEnhancementsColumnWidthConfigs = [
    {
        width: 133,
        key: columnKeys.title,
    },
    {
        width: 123,
        key: columnKeys.source,
    },
    {
        width: 123,
        key: columnKeys.assigneeRoleId,
    },
    {
        width: 165,
        key: columnKeys.dueDateUtc,
    },
    {
        width: 123,
        key: columnKeys.priorityAttrId,
    },
    {
        width: 123,
        key: columnKeys.typeAttrId,
    },
    {
        width: 165,
        key: columnKeys.statusAttrId,
    },
];

const defaultColumnConfigs = [
    {
        title: columnHeaders.title,
        filterable: false,
        sortable: false,
        width: 272,
        key: columnKeys.title,
    },
    {
        title: columnHeaders.source,
        filterable: false,
        sortable: false,
        width: 272,
        key: columnKeys.source,
    },
    {
        title: columnHeaders.assignee,
        filterable: false,
        sortable: false,
        width: 175,
        key: columnKeys.assigneeRoleId,
    },
    {
        title: columnHeaders.dueDate,
        filterable: false,
        sortable: true,
        width: 100,
        key: columnKeys.dueDateUtc,
    },
    {
        title: columnHeaders.priority,
        filterable: false,
        sortable: false,
        key: columnKeys.priorityAttrId,
    },
    {
        title: columnHeaders.type,
        filterable: false,
        sortable: false,
        width: 272,
        key: columnKeys.typeAttrId,
    },
    {
        title: columnHeaders.status,
        filterable: false,
        sortable: false,
        width: 226,
        key: columnKeys.statusAttrId,
    },
];

const HeaderCell = styled(TableCell)`
    padding: 8px 10px;
    font-size: var(--arc-fontSizes-sm);
    text-transform: uppercase;
    ${({ theme }) => {
        return `
            font-family: ${theme.fontFamilies.proximaNova}, sans-serif;
            font-weight: ${theme.fontWeights.semiBold};
        `;
    }};
`;

const TaskTableRow = styled(TableRow)`
    height: 47px;
    ${({ theme, selected }) => {
        return `
            border-bottom: 1px solid ${theme.colors.lightGrey};
            background-color: ${selected ? theme.colors.lightBlue : 'none'};
            &:hover {
                background-color: ${theme.colors.lightBlue};
            }
        `;
    }};
`;

export const TasksHeaderTableColumn = styled(TableColumn)`
    ${({ width, theme }) => {
        return `
            width: ${width}px;
            border-right: 1px solid ${theme.colors.lightGrey};
        `;
    }} height: 45px;
    display: flex;
    justify-content: center;
    position: relative;
`;

export const TaskHeaderRow = styled(TableRow)`
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.12);

    .column:last-child {
        border-right: none;
    }
`;

const Content = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-sm);
`;

const ClickableContent = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-sm);
    &:hover {
        cursor: pointer;
    }
`;

const TaskStatusContent = styled(Content)`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    overflow: visible;
`;

const TaskBodyColumn = styled(TableColumn)`
    ${({ padding }) => {
        return `
                padding: ${padding};
            `;
    }};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ width }) => {
        return `
                width: ${width}px;
            `;
    }};
`;

const getColumnWidthByColumnKey = (columns, key) => {
    return get(find(columns, { key }), 'width');
};

const TaskSourceWrapper = styled.div`
    width: 100%;
`;

const TaskSource = compose(
    renderOnlyIf(({ ownerId, ownerType }) => ownerId && ownerType),
    withPropsOnChange(
        ['ownerId', 'ownerType', 'ownerTitle', 'taskEntityLinks'],
        ({ ownerId, ownerType, ownerTitle, taskEntityLinks }) => {
            // Determine the value to display in source column cell
            let sourceEntityType = ownerType;
            let sourceEntityId = ownerId;
            let sourceEntityTitle = ownerTitle;
            if (ownerType === EntityTypeEnum.TASK.name && !isEmpty(taskEntityLinks)) {
                const taskEntityLink = head(taskEntityLinks);
                sourceEntityType = taskEntityLink.entityType;
                sourceEntityId = taskEntityLink.entityId;
                sourceEntityTitle = taskEntityLink.entityTitle;
            }

            let navigateToUrl;
            switch (sourceEntityType) {
                case EntityTypeEnum.CASE.name:
                    navigateToUrl = `/cases/${sourceEntityId}/summary`;
                    break;
                case EntityTypeEnum.REPORT.name:
                    navigateToUrl = `/reports/${sourceEntityId}`;
                    break;
                case EntityTypeEnum.WARRANT.name:
                    navigateToUrl = `/warrants/${sourceEntityId}`;
                    break;
                case EntityTypeEnum.PERSON_PROFILE.name:
                    navigateToUrl = `/profiles/persons/${sourceEntityId}`;
                    break;
                case EntityTypeEnum.ITEM_PROFILE.name:
                    navigateToUrl = `/profiles/property/${sourceEntityId}/details`;
                    break;
                case EntityTypeEnum.E_FILE.name:
                    navigateToUrl = `/e-file/${sourceEntityId}/summary`;
                    break;
                case EntityTypeEnum.BRIEFING.name:
                    navigateToUrl = BRIEFINGS_ROUTES.item.url(sourceEntityId);
                    break;
                default:
                    break;
            }

            return {
                sourceEntityTitle,
                navigateToUrl,
            };
        }
    )
)(({ sourceEntityTitle, navigateToUrl }) => {
    if (!sourceEntityTitle) {
        return null;
    }

    return (
        <TaskSourceWrapper>
            <TaskTableTooltip content={sourceEntityTitle}>
                <Content>
                    <PopoutLink to={navigateToUrl}>{sourceEntityTitle}</PopoutLink>
                </Content>
            </TaskTableTooltip>
        </TaskSourceWrapper>
    );
});

const Link = styled(CustomLink)`
    text-decoration: none;
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

const TaskSourceWithMultipleEntityLinks = ({ count, onClick }) => {
    return (
        <TaskSourceWrapper>
            <Content>
                <Link onClick={onClick}>{strings.linkedItems(count)}</Link>
            </Content>
        </TaskSourceWrapper>
    );
};

const HideableColumnElement = ({ hiddenColumnKeys, columnKey, render }) => {
    if (!includes(hiddenColumnKeys, columnKey)) {
        return render();
    } else {
        return <></>;
    }
};

const renderHeaderRow =
    ({
        hiddenColumnKeys,
        handleDateOptionClick,
        activeColumnKeys,
        searchTasks,
        canSortColumns,
        activeSort,
    }) =>
    ({ columns }) => {
        const activeDateColumnToSqlSortKeyEnum = {
            dueDateUtc: sqlSortKeyEnum.TASK_DUE_DATE,
            updatedDateUtc: sqlSortKeyEnum.TASK_MODIFIED_DATE,
            createdDateUtc: sqlSortKeyEnum.TASK_CREATED_DATE,
        };
        return (
            <TaskHeaderRow>
                <TasksHeaderTableColumn
                    key={columnKeys.title}
                    width={getColumnWidthByColumnKey(columns, columnKeys.title)}
                    className="column"
                >
                    <FeatureFlagged
                        flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                        fallback={<HeaderCell>{columnHeaders.title}</HeaderCell>}
                    >
                        {canSortColumns ? (
                            <OptionsTableColumn
                                display={({ display }) => display}
                                columnKey={columnKeys.title}
                                activeValue={columnKeys.title}
                                activeSortType={
                                    activeSort?.sortKey === sqlSortKeyEnum.TASK_TITLE
                                        ? activeSort?.sortType
                                        : undefined
                                }
                                onSortTypeClick={(sortTypeEnum) =>
                                    searchTasks(sortTypeEnum, sqlSortKeyEnum.TASK_TITLE)
                                }
                                popoutWidth={160}
                                height={'100%'}
                            >
                                <OptionsTableColumnOption
                                    display={columnHeaders.title}
                                    value={columnKeys.title}
                                    columnKey={columnKeys.title}
                                    sortOptions={[
                                        {
                                            display: sorts.alphaAsc,
                                            sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                        },
                                        {
                                            display: sorts.alphaDesc,
                                            sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                        },
                                    ]}
                                />
                            </OptionsTableColumn>
                        ) : (
                            <HeaderCell>{columnHeaders.title}</HeaderCell>
                        )}
                    </FeatureFlagged>
                </TasksHeaderTableColumn>

                <HideableColumnElement
                    hiddenColumnKeys={hiddenColumnKeys}
                    columnKey={columnKeys.source}
                    render={() => (
                        <TasksHeaderTableColumn
                            key={columnKeys.source}
                            width={getColumnWidthByColumnKey(columns, columnKeys.source)}
                            className="column"
                        >
                            <HeaderCell>{columnHeaders.source}</HeaderCell>
                        </TasksHeaderTableColumn>
                    )}
                />

                <HideableColumnElement
                    hiddenColumnKeys={hiddenColumnKeys}
                    columnKey={columnKeys.assigneeRoleId}
                    render={() => (
                        <TasksHeaderTableColumn
                            key={columnKeys.assigneeRoleId}
                            width={getColumnWidthByColumnKey(columns, columnKeys.assigneeRoleId)}
                            className="column"
                        >
                            <FeatureFlagged
                                flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                                fallback={<HeaderCell>{columnHeaders.assignee}</HeaderCell>}
                            >
                                <OptionsTableColumn
                                    display={({ display }) => display}
                                    columnKey={columnKeys.assigneeRoleId}
                                    activeValue={columnKeys.assigneeRoleId}
                                    activeSortType={
                                        activeSort?.sortKey === sqlSortKeyEnum.TASK_ASSIGNEE
                                            ? activeSort?.sortType
                                            : undefined
                                    }
                                    onSortTypeClick={(sortTypeEnum) =>
                                        searchTasks(sortTypeEnum, sqlSortKeyEnum.TASK_ASSIGNEE)
                                    }
                                    popoutWidth={160}
                                    height={'100%'}
                                >
                                    <OptionsTableColumnOption
                                        display={columnHeaders.assignee}
                                        value={columnKeys.assigneeRoleId}
                                        columnKey={columnKeys.assigneeRoleId}
                                        sortOptions={[
                                            {
                                                display: sorts.alphaAsc,
                                                sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                            },
                                            {
                                                display: sorts.alphaDesc,
                                                sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                            },
                                        ]}
                                    />
                                </OptionsTableColumn>
                            </FeatureFlagged>
                        </TasksHeaderTableColumn>
                    )}
                />

                <TasksHeaderTableColumn
                    key={columnKeys.dueDateUtc}
                    width={getColumnWidthByColumnKey(columns, columnKeys.dueDateUtc)}
                    className="column"
                >
                    <FeatureFlagged
                        flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                        fallback={<HeaderCell>{columnHeaders.dueDate}</HeaderCell>}
                    >
                        {canSortColumns ? (
                            <OptionsTableColumn
                                display={({ display }) => display}
                                columnKey={activeColumnKeys['date']}
                                activeValue={activeColumnKeys['date']}
                                activeSortType={
                                    activeSort?.sortKey ===
                                    activeDateColumnToSqlSortKeyEnum[activeColumnKeys['date']]
                                        ? activeSort?.sortType
                                        : undefined
                                }
                                onOptionClick={(data) => handleDateOptionClick(data.value)}
                                onSortTypeClick={(sortTypeEnum) => {
                                    searchTasks(
                                        sortTypeEnum,
                                        activeDateColumnToSqlSortKeyEnum[activeColumnKeys['date']]
                                    );
                                }}
                                popoutWidth={160}
                                height={'100%'}
                            >
                                <OptionsTableColumnOption
                                    display={columnHeaders.dueDate}
                                    value={columnKeys.dueDateUtc}
                                    columnKey={columnKeys.dueDateUtc}
                                    sortOptions={[
                                        {
                                            display: sorts.dateAsc,
                                            sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                        },
                                        {
                                            display: sorts.dateDesc,
                                            sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                        },
                                    ]}
                                />
                                <OptionsTableColumnOption
                                    display={columnHeaders.modifiedDate}
                                    value={columnKeys.updatedDateUtc}
                                    columnKey={columnKeys.updatedDateUtc}
                                    sortOptions={[
                                        {
                                            display: sorts.dateAsc,
                                            sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                        },
                                        {
                                            display: sorts.dateDesc,
                                            sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                        },
                                    ]}
                                />
                                <OptionsTableColumnOption
                                    display={columnHeaders.createdDate}
                                    value={columnKeys.createdDateUtc}
                                    columnKey={columnKeys.createdDateUtc}
                                    sortOptions={[
                                        {
                                            display: sorts.dateAsc,
                                            sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                        },
                                        {
                                            display: sorts.dateDesc,
                                            sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                        },
                                    ]}
                                />
                            </OptionsTableColumn>
                        ) : (
                            <HeaderCell>{columnHeaders.dueDate}</HeaderCell>
                        )}
                    </FeatureFlagged>
                </TasksHeaderTableColumn>
                <HideableColumnElement
                    hiddenColumnKeys={hiddenColumnKeys}
                    columnKey={columnKeys.priorityAttrId}
                    render={() => (
                        <TasksHeaderTableColumn
                            key={columnKeys.priorityAttrId}
                            width={getColumnWidthByColumnKey(columns, columnKeys.priorityAttrId)}
                            className="column"
                        >
                            {canSortColumns ? (
                                <OptionsTableColumn
                                    display={({ display }) => display}
                                    columnKey={columnKeys.priorityAttrId}
                                    activeValue={columnKeys.priorityAttrId}
                                    activeSortType={
                                        activeSort?.sortKey === sqlSortKeyEnum.TASK_PRIORITY
                                            ? activeSort?.sortType ===
                                              sortTypeEnum.ALPHABETICAL_A_TO_Z
                                                ? sortTypeEnum.ALPHABETICAL_Z_TO_A
                                                : sortTypeEnum.ALPHABETICAL_A_TO_Z
                                            : undefined
                                    }
                                    onSortTypeClick={(sortType) => {
                                        if (sortType === sortTypeEnum.ALPHABETICAL_A_TO_Z) {
                                            sortType = sortTypeEnum.ALPHABETICAL_Z_TO_A;
                                        } else {
                                            sortType = sortTypeEnum.ALPHABETICAL_A_TO_Z;
                                        }
                                        searchTasks(sortType, sqlSortKeyEnum.TASK_PRIORITY);
                                    }}
                                    popoutWidth={160}
                                    height={'100%'}
                                >
                                    <OptionsTableColumnOption
                                        display={columnHeaders.priority}
                                        value={columnKeys.priorityAttrId}
                                        columnKey={columnKeys.priorityAttrId}
                                        sortOptions={[
                                            {
                                                display: sorts.priorityAsc,
                                                sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                            },
                                            {
                                                display: sorts.priorityDesc,
                                                sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                            },
                                        ]}
                                    />
                                </OptionsTableColumn>
                            ) : (
                                <HeaderCell>{columnHeaders.priority}</HeaderCell>
                            )}
                        </TasksHeaderTableColumn>
                    )}
                />
                <HideableColumnElement
                    hiddenColumnKeys={hiddenColumnKeys}
                    columnKey={columnKeys.typeAttrId}
                    render={() => (
                        <TasksHeaderTableColumn
                            key={columnKeys.typeAttrId}
                            width={getColumnWidthByColumnKey(columns, columnKeys.typeAttrId)}
                            className="column"
                        >
                            {canSortColumns ? (
                                <OptionsTableColumn
                                    display={({ display }) => display}
                                    columnKey={columnKeys.type}
                                    activeValue={columnKeys.type}
                                    activeSortType={
                                        activeSort?.sortKey === sqlSortKeyEnum.TASK_TYPE
                                            ? activeSort?.sortType
                                            : undefined
                                    }
                                    onSortTypeClick={(sortTypeEnum) =>
                                        searchTasks(sortTypeEnum, sqlSortKeyEnum.TASK_TYPE)
                                    }
                                    popoutWidth={160}
                                    height={'100%'}
                                >
                                    <OptionsTableColumnOption
                                        display={columnHeaders.type}
                                        value={columnKeys.type}
                                        columnKey={columnKeys.type}
                                        sortOptions={[
                                            {
                                                display: sorts.alphaAsc,
                                                sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                            },
                                            {
                                                display: sorts.alphaDesc,
                                                sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                            },
                                        ]}
                                    />
                                </OptionsTableColumn>
                            ) : (
                                <HeaderCell>{columnHeaders.type}</HeaderCell>
                            )}
                        </TasksHeaderTableColumn>
                    )}
                />
                <TasksHeaderTableColumn
                    key={columnKeys.statusAttrId}
                    width={getColumnWidthByColumnKey(columns, columnKeys.statusAttrId)}
                    className="column"
                >
                    <FeatureFlagged
                        flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                        fallback={<HeaderCell>{columnHeaders.status}</HeaderCell>}
                    >
                        {canSortColumns ? (
                            <OptionsTableColumn
                                display={({ display }) => display}
                                columnKey={columnKeys.statusAttrId}
                                activeValue={columnKeys.statusAttrId}
                                activeSortType={
                                    activeSort?.sortKey === sqlSortKeyEnum.TASK_STATUS
                                        ? activeSort?.sortType
                                        : undefined
                                }
                                onSortTypeClick={(sortTypeEnum) =>
                                    searchTasks(sortTypeEnum, sqlSortKeyEnum.TASK_STATUS)
                                }
                                popoutWidth={160}
                                height={'100%'}
                            >
                                <OptionsTableColumnOption
                                    display={columnHeaders.status}
                                    value={columnKeys.statusAttrId}
                                    columnKey={columnKeys.statusAttrId}
                                    sortOptions={[
                                        {
                                            display: sorts.alphaAsc,
                                            sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                        },
                                        {
                                            display: sorts.alphaDesc,
                                            sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                        },
                                    ]}
                                />
                            </OptionsTableColumn>
                        ) : (
                            <HeaderCell>{columnHeaders.status}</HeaderCell>
                        )}
                    </FeatureFlagged>
                </TasksHeaderTableColumn>
            </TaskHeaderRow>
        );
    };

const TaskTitleCellWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const TaskTitleContent = styled.div`
    min-width: 0;
    padding-right: 10px;
`;
/**
 *
 * @param {object} props
 * @param {{width: number, key: string}[] | undefined} props.columnConfigurations
 * @param {Function} props.onSave
 * @param {Function} props.onDeleteTask
 * @param {import('@mark43/rms-api').TaskView[]} props.tasks
 * @param {string[]} props.hiddenColumnKeys The name of the shop
 * @returns {JSX.Element}
 */
const TaskTable = ({
    className = undefined,
    tasks,
    columnConfigurations,
    includePagination = undefined,
    paginationOptions = undefined,
    showHeaderRow = undefined,
    hiddenColumnKeys: _hiddenColumnKeys = [],
    canDelete = true,
    onDeleteTask,
    onSave,
    canSortColumns = true,
}) => {
    const allRoleFormatsByRoleId = useSelector(allRoleFormatsByRoleIdSelector);
    const canEditTask = useSelector(canEditTaskSelector);
    const taskAttachmentsMap = useSelector(taskAttachmentsMapSelector);
    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const currentUserId = useSelector(currentUserIdSelector);
    const activeColumnKeys = useSelector(tasksDashboardSearch.selectors.activeColumnKeysSelector);
    const currentQuery = useSelector(tasksDashboardSearch.selectors.currentQuerySelector);
    const statusOptions = useTaskStatusOptions();

    const dispatch = useDispatch();

    const handleDateOptionClick = useCallback(
        (activeColumn) => {
            dispatch(tasksDashboardSearch.actionCreators.setActiveColumnKey('date', activeColumn));
        },
        [dispatch]
    );

    const searchTasks = (sortType, sortKey) =>
        dispatch(tasksDashboardSearch.actionCreators.search({ sortKey, sortType }));

    useEffect(() => {
        handleDateOptionClick('dueDateUtc');
    }, [handleDateOptionClick, dispatch]);

    useLoadTaskAttributes();

    // selectedRowIndex is used for the edit and delete functions on a singular task.
    const [selectedRowIndex, setSelectedRowIndex] = useState();
    const [taskView, setTaskView] = useState();
    const [taskViewEntityLinks, setTaskViewEntityLinks] = useState();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const allTaskEntityLinks = useSelector(taskEntityLinksSelector);
    const taskEnhancementsEnabled = applicationSettings['RMS_TASK_ENHANCEMENTS_ENABLED'];
    const hiddenColumnKeys = taskEnhancementsEnabled
        ? [..._hiddenColumnKeys]
        : [..._hiddenColumnKeys, 'typeAttrId', 'priorityAttrId'];

    const columns = map(defaultColumnConfigs, (defaultColumn) => ({
        ...defaultColumn,
        width:
            getColumnWidthByColumnKey(columnConfigurations, defaultColumn.key) ||
            (taskEnhancementsEnabled
                ? getColumnWidthByColumnKey(taskEnhancementsColumnWidthConfigs, defaultColumn.key)
                : defaultColumn.width),
    }));

    const titleColumnWidth = getColumnWidthByColumnKey(columns, columnKeys.title);
    const sourceColumnWidth = getColumnWidthByColumnKey(columns, columnKeys.source);
    const assigneeColumnWidth = getColumnWidthByColumnKey(columns, columnKeys.assigneeRoleId);
    const dueDateUtcColumnWidth = getColumnWidthByColumnKey(columns, columnKeys.dueDateUtc);
    const priorityColumnWidth = getColumnWidthByColumnKey(columns, columnKeys.priorityAttrId);
    const typeColumnWidth = getColumnWidthByColumnKey(columns, columnKeys.typeAttrId);
    const statusAttrIdColumnWidth = getColumnWidthByColumnKey(columns, columnKeys.statusAttrId);

    return (
        <div className={className}>
            <TaskContextProvider
                value={{
                    taskView,
                    setTaskView,
                    taskViewEntityLinks,
                    setTaskViewEntityLinks,
                }}
            >
                <RMSTableComposite
                    className="cobalt-table"
                    items={tasks}
                    columns={columns}
                    testId={testIds.TASK_TABLE}
                    includePagination={includePagination}
                    paginationOptions={paginationOptions}
                    showHeaderRow={showHeaderRow}
                    renderHeaderRow={renderHeaderRow({
                        hiddenColumnKeys,
                        handleDateOptionClick,
                        activeColumnKeys,
                        searchTasks,
                        canSortColumns,
                        activeSort: head(currentQuery?.sorts),
                    })}
                    renderBodyRow={({ item: task, index }) => {
                        const isSelected = index === selectedRowIndex;
                        const { id, ownerId, ownerType, ownerTitle, createdBy } = task;
                        const taskAttachmentsViewModels = values(
                            convertAttachmentsToAttachmentViewModel(
                                taskAttachmentsMap[id],
                                formatMiniUserById
                            )
                        );
                        const taskEntityLinks = convertToFormModel(
                            task,
                            applicationSettings.RMS_TASK_ENTITY_LINKS_ENABLED,
                            filter(allTaskEntityLinks, { taskId: id })
                        ).taskEntityLinks;

                        const selectAndOpenTask = () => {
                            setSelectedRowIndex(index);
                            if (
                                canEditTask &&
                                task.permissionSet?.includes(OperationTypeEnum.WRITE.name)
                            ) {
                                dispatch(openTaskSidePanelForTask(task, TaskOverlayMode.EDIT));
                            } else {
                                dispatch(openTaskSidePanelForTask(task, TaskOverlayMode.VIEW));
                            }
                        };

                        // Title
                        const title = task[columnKeys.title];

                        // Assignee
                        const assigneeRoleId = task[columnKeys.assigneeRoleId];
                        const assignee = allRoleFormatsByRoleId(assigneeRoleId);

                        // Current Date
                        const currDate = task[activeColumnKeys.date];

                        // Type
                        const typeAttrId = task[columnKeys.typeAttrId];

                        // Priority
                        const priorityAttrId = task[columnKeys.priorityAttrId];

                        // Status Attribute
                        const statusAttrId = task[columnKeys.statusAttrId];

                        const hasMasterDeleteTasksAbility = currentUserHasAbility(
                            abilitiesEnum.CORE.MASTER_DELETE_TASKS
                        );

                        const canDeleteTask =
                            canDelete &&
                            (hasMasterDeleteTasksAbility ||
                                (currentUserHasAbility(abilitiesEnum.CORE.EDIT_TASKS) &&
                                    includes(task.permissionSet, OperationTypeEnum.DELETE.name) &&
                                    createdBy === currentUserId));

                        const renderTaskPopOverMenu =
                            hasMasterDeleteTasksAbility ||
                            includes(task.permissionSet, OperationTypeEnum.WRITE.name);

                        const padding = taskEnhancementsEnabled ? '0px 4px' : '8px 10px';

                        return (
                            <TaskTableRow
                                key={index}
                                selected={isSelected}
                                onClick={() => {
                                    setTaskView(task);
                                    setTaskViewEntityLinks(taskEntityLinks);
                                    setSelectedRowIndex(index);
                                }}
                                onDoubleClick={selectAndOpenTask}
                                data-test-id={testIds.TASK_TABLE_ROW}
                            >
                                <TaskBodyColumn
                                    padding={padding}
                                    width={titleColumnWidth}
                                    data-test-id={testIds.TASK_TABLE_TITLE_COLUMN}
                                >
                                    <Content>
                                        <TaskTitleCellWrapper>
                                            <TaskTitleContent>
                                                <FeatureFlagged
                                                    flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                                                    fallback={
                                                        <TaskTableTooltip content={title}>
                                                            <Content>{title}</Content>
                                                        </TaskTableTooltip>
                                                    }
                                                >
                                                    <TaskTableTooltip content={title}>
                                                        <ClickableContent
                                                            onClick={selectAndOpenTask}
                                                        >
                                                            {title}
                                                        </ClickableContent>
                                                    </TaskTableTooltip>
                                                </FeatureFlagged>
                                            </TaskTitleContent>
                                            <AttachmentsIndicator
                                                attachmentViewModels={taskAttachmentsViewModels}
                                            />
                                        </TaskTitleCellWrapper>
                                    </Content>
                                </TaskBodyColumn>
                                <HideableColumnElement
                                    hiddenColumnKeys={hiddenColumnKeys}
                                    columnKey={columnKeys.source}
                                    render={() => {
                                        return (
                                            <TaskBodyColumn
                                                padding={padding}
                                                width={sourceColumnWidth}
                                            >
                                                {taskEntityLinks?.length > 1 ? (
                                                    <TaskSourceWithMultipleEntityLinks
                                                        count={taskEntityLinks.length}
                                                        onClick={selectAndOpenTask}
                                                    />
                                                ) : (
                                                    <TaskSource
                                                        taskEntityLinks={taskEntityLinks}
                                                        ownerId={ownerId}
                                                        ownerType={ownerType}
                                                        ownerTitle={ownerTitle}
                                                    />
                                                )}
                                            </TaskBodyColumn>
                                        );
                                    }}
                                />
                                <HideableColumnElement
                                    hiddenColumnKeys={hiddenColumnKeys}
                                    columnKey={columnKeys.assigneeRoleId}
                                    render={() => (
                                        <TaskBodyColumn
                                            padding={padding}
                                            width={assigneeColumnWidth}
                                        >
                                            <Content>
                                                {assignee && (
                                                    <TaskTableTooltip content={assignee.default}>
                                                        <Content>{assignee.default}</Content>
                                                    </TaskTableTooltip>
                                                )}
                                            </Content>
                                        </TaskBodyColumn>
                                    )}
                                />
                                <TaskBodyColumn
                                    padding={padding}
                                    width={dueDateUtcColumnWidth}
                                    data-test-id={testIds.TASK_TABLE_DUE_DATE_COLUMN}
                                >
                                    <Content>
                                        <TaskDateCell
                                            date={currDate}
                                            statusAttrId={statusAttrId}
                                            isDueDate={
                                                columnKeys.dueDateUtc === activeColumnKeys.date
                                            }
                                        />
                                    </Content>
                                </TaskBodyColumn>
                                <HideableColumnElement
                                    hiddenColumnKeys={hiddenColumnKeys}
                                    columnKey={columnKeys.priorityAttrId}
                                    render={() => (
                                        <TaskBodyColumn
                                            padding={padding}
                                            width={priorityColumnWidth}
                                        >
                                            <Content>
                                                <ConnectedFormattedAttribute
                                                    attributeId={priorityAttrId}
                                                >
                                                    {(formattedAttribute) => {
                                                        return <div>{formattedAttribute}</div>;
                                                    }}
                                                </ConnectedFormattedAttribute>
                                            </Content>
                                        </TaskBodyColumn>
                                    )}
                                />
                                <HideableColumnElement
                                    hiddenColumnKeys={hiddenColumnKeys}
                                    columnKey={columnKeys.typeAttrId}
                                    render={() => (
                                        <TaskBodyColumn padding={padding} width={typeColumnWidth}>
                                            <Content>
                                                <ConnectedFormattedAttribute
                                                    attributeId={typeAttrId}
                                                >
                                                    {(formattedAttribute) => {
                                                        return <div>{formattedAttribute}</div>;
                                                    }}
                                                </ConnectedFormattedAttribute>
                                            </Content>
                                        </TaskBodyColumn>
                                    )}
                                />
                                <TaskBodyColumn padding={padding} width={statusAttrIdColumnWidth}>
                                    <TaskStatusContent>
                                        <TaskStatusCell
                                            task={task}
                                            taskEntityLinks={taskEntityLinks}
                                            options={statusOptions}
                                            onUpdate={onSave}
                                            style={{ width: '150px' }}
                                        />
                                        {renderTaskPopOverMenu && (
                                            <TaskPopOverMenu
                                                task={task}
                                                canDelete={canDeleteTask}
                                                onDeleteTask={onDeleteTask}
                                            />
                                        )}
                                    </TaskStatusContent>
                                </TaskBodyColumn>
                            </TaskTableRow>
                        );
                    }}
                />
            </TaskContextProvider>

            <TaskSidePanel onSave={onSave} />
        </div>
    );
};

export default TaskTable;
