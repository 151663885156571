import { v4 as uuidv4 } from 'uuid';
import Konva from 'konva';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import {
    AddTextWidgetUserInteractionType,
    ArrangeAction,
    CrashDiagramHistoryUserInteraction,
    DeleteTextWidgetUserInteractionType,
    DiagramUserInteractionType,
    ExtendedAddWidgetUserInteractionType,
    FlipAction,
    Widget,
    widgetActions,
    widgetTypes,
} from '../../types';
import {
    widgetTypeToDefaultValue,
    DEFAULT_WIDGET_POSITION,
    STAGE_WIDTH_LARGE,
    STAGE_HEIGHT,
} from '../../config';
import { createWidget, getWidgetActionType, calculateConstrainedPosition } from '../../helpers';
import { ApplyWidgetActionArgs } from '../CrashDiagramContext';
import { CrashDiagramActionTypes } from './diagram/actions';
import { CrashDiagramHistoryActionTypes } from './history/actions';
import { initialState as diagramInitialState, CrashDiagramState } from './diagram';
import { initialState as historyInitialState, CrashDiagramHistoryState } from './history';

export type RootCrashDiagramState = {
    diagram: CrashDiagramState;
    history: CrashDiagramHistoryState;
};

export type RootCrashDiagramActions = CrashDiagramActionTypes | CrashDiagramHistoryActionTypes;

export const rootInitialState: RootCrashDiagramState = {
    diagram: diagramInitialState,
    history: historyInitialState,
};

type AddWidgetsToDiagramProps = (
    widgets: RootCrashDiagramState['diagram']['widgets'],
    newWidgets: Widget[]
) => RootCrashDiagramState['diagram']['widgets'];

type AddToExistingUserActionsArgs = (
    existingUserActions: CrashDiagramHistoryUserInteraction[],
    currentPosition: number,
    newUserActions: CrashDiagramHistoryUserInteraction[]
) => Pick<RootCrashDiagramState['history'], 'userActions' | 'currentPosition'>;

const removeWidgetsFromDiagram = (
    widgets: RootCrashDiagramState['diagram']['widgets'],
    widgetIds: string[]
): RootCrashDiagramState['diagram']['widgets'] => {
    return widgets.filter((widget) => !widgetIds.includes(widget.id));
};

type WidgetProperties = Partial<Pick<Widget, 'opacity' | 'position'>>;

const getUpdatedArrangeState = (state: RootCrashDiagramState, payload: ApplyWidgetActionArgs) => {
    const widgetIsInPosition =
        state.diagram.widgets.length > 0 &&
        (payload.action === widgetActions.BRING_ITEM_TO_FRONT
            ? state.diagram.widgets.slice(-1)[0].id === payload.widgetId
            : state.diagram.widgets[0].id === payload.widgetId);

    const widgetToArrange = getWidgetFromStateById(state.diagram.widgets, payload.widgetId);

    if (widgetIsInPosition || !widgetToArrange) {
        return state;
    }

    const action =
        payload.action === 'BRING_ITEM_TO_FRONT' ? 'BRING_ITEM_TO_FRONT' : 'SEND_ITEM_TO_BACK';

    const userActions = createUserInteractionHistoryEvents([
        {
            type: action,
            widgetId: widgetToArrange.id,
            widgetType: widgetToArrange.type,
            konvaInstance: widgetToArrange.konvaInstance,
            before: undefined,
            after: undefined,
        },
    ]);

    return {
        ...state,
        diagram: {
            ...state.diagram,
            widgets: arrangeWidgetOnDiagram(state.diagram.widgets, payload.widgetId, action),
            selectedWidget: widgetToArrange,
        },
        history: {
            ...state.history,
            ...addToExistingUserActions(
                state.history.userActions,
                state.history.currentPosition,
                userActions
            ),
        },
    };
};

const getUpdatedFlipState = (state: RootCrashDiagramState, payload: ApplyWidgetActionArgs) => {
    const widgetToFlip = state.diagram.widgets.find((widget) => widget.id === payload.widgetId);

    if (!widgetToFlip) {
        return state;
    }

    const action =
        payload.action === 'FLIP_ITEM_HORIZONTAL' ? 'FLIP_ITEM_HORIZONTAL' : 'FLIP_ITEM_VERTICAL';

    const userActions = createUserInteractionHistoryEvents([
        {
            type: action,
            widgetId: widgetToFlip.id,
            widgetType: widgetToFlip.type,
            konvaInstance: undefined,
            before: {
                value: undefined,
                position: widgetToFlip.position,
                opacity: undefined,
            },
            after: {
                value: undefined,
                position: widgetToFlip.position,
                opacity: undefined,
            },
        },
    ]);

    return {
        ...state,
        diagram: {
            ...state.diagram,
            widgets: flipWidgetOnDiagram(state.diagram.widgets, payload.widgetId, action),
            selectedWidget: widgetToFlip,
        },
        history: {
            ...state.history,
            ...addToExistingUserActions(
                state.history.userActions,
                state.history.currentPosition,
                userActions
            ),
        },
    };
};

const flipWidgetOnDiagram = (
    widgets: RootCrashDiagramState['diagram']['widgets'],
    widgetId: string,
    action: FlipAction
): RootCrashDiagramState['diagram']['widgets'] => {
    const targetIndex = widgets.findIndex((widget) => widget.id === widgetId);

    if (targetIndex === -1) {
        return widgets;
    }

    const updatedWidgets = widgets.map((widget, index) => {
        if (index !== targetIndex) {
            return widget;
        }

        const { position } = widget;
        const { scaleX = DEFAULT_WIDGET_POSITION.scaleX, scaleY = DEFAULT_WIDGET_POSITION.scaleY } =
            position;

        return {
            ...widget,
            position: {
                ...position,
                scaleX: action === widgetActions.FLIP_ITEM_HORIZONTAL ? -scaleX : scaleX,
                scaleY: action === widgetActions.FLIP_ITEM_VERTICAL ? -scaleY : scaleY,
            },
        };
    });

    return updatedWidgets;
};

const createUserInteractionHistoryEvents = (
    historyUserInteractionTypes: DiagramUserInteractionType[]
): CrashDiagramHistoryUserInteraction[] => {
    const groupId = uuidv4();
    return historyUserInteractionTypes.map((item) => {
        return { ...item, createdDateUtc: nowUtc(), groupId };
    });
};

const addToExistingWidgetsOnDiagram: AddWidgetsToDiagramProps = (
    existingWidgets,
    newWidgets
): RootCrashDiagramState['diagram']['widgets'] => {
    return [...existingWidgets, ...newWidgets];
};

const arrangeWidgetOnDiagram = (
    widgets: RootCrashDiagramState['diagram']['widgets'],
    widgetId: string,
    action: ArrangeAction
): RootCrashDiagramState['diagram']['widgets'] => {
    const targetIndex = widgets.findIndex((widget) => widget.id === widgetId);

    if (targetIndex === -1) {
        return widgets;
    }

    const updatedWidgets = widgets;

    const [targetWidget] = updatedWidgets.splice(targetIndex, 1);

    switch (action) {
        case widgetActions.BRING_ITEM_TO_FRONT:
            updatedWidgets.push(targetWidget);
            break;
        case widgetActions.SEND_ITEM_TO_BACK:
            updatedWidgets.unshift(targetWidget);
            break;
        default:
            return widgets;
    }

    return updatedWidgets;
};

export const getCrashDiagramStateFromAddingNewWidget = (
    state: RootCrashDiagramState,
    widget: Widget
) => {
    const userActions = createUserInteractionHistoryEvents([
        {
            type: 'ADD_WIDGET',
            widgetId: widget.id,
            widgetType: widget.type,
            konvaInstance: widget.konvaInstance,
            before: undefined,
            after: {
                value: widget.value,
                position: widget.position,
                opacity: widget.opacity,
            },
        },
    ]);

    return {
        ...state,
        diagram: {
            ...state.diagram,
            widgets: addToExistingWidgetsOnDiagram(state.diagram.widgets, [widget]),
            selectedWidget: widget,
        },
        history: {
            ...state.history,
            ...addToExistingUserActions(
                state.history.userActions,
                state.history.currentPosition,
                userActions
            ),
        },
    };
};

export const getGhostOpacity = (
    originalOpacity: number,
    numberOfDuplicates: number,
    index: number
) => {
    const adjustment = (index + 1) * (originalOpacity / (numberOfDuplicates + 1));
    const rawOpacity = originalOpacity - adjustment;
    const opacity = Math.round(rawOpacity * 100) / 100;

    return Math.max(opacity, 0.1);
};

const updateWidgetPropertiesOnDiagram = (
    widgets: RootCrashDiagramState['diagram']['widgets'],
    widgetId: string,
    widgetProperties: WidgetProperties
) => {
    return widgets.map((widget) => {
        if (widget.id === widgetId) {
            return { ...widget, ...widgetProperties };
        }
        return widget;
    });
};

const getUpdatedTextWidgetPosition = (konvaInstance: Konva.Text, value: string) => {
    const MAX_WIDTH = DEFAULT_WIDGET_POSITION.width;
    const textNode = konvaInstance;

    if (!textNode) {
        return {
            height: DEFAULT_WIDGET_POSITION.height,
            offsetY: DEFAULT_WIDGET_POSITION.offsetY,
        };
    }

    textNode.setText(value);
    const textWidth = textNode.width();
    let textHeight = textNode.height();

    if (textWidth > MAX_WIDTH) {
        textNode.width(MAX_WIDTH);
        textHeight = textNode.height();
    }

    const newOffsetY = textHeight / 2;

    return {
        height: textHeight,
        offsetY: newOffsetY,
    };
};

const isDeleteTextWidgetUserInteractionType = (
    action: CrashDiagramHistoryUserInteraction
): action is DeleteTextWidgetUserInteractionType => {
    return action.type === 'DELETE_WIDGET' && action.widgetType === widgetTypes.TEXT;
};

const isAddTextWidgetUserInteractionType = (
    action: CrashDiagramHistoryUserInteraction
): action is AddTextWidgetUserInteractionType => {
    return action.type === 'ADD_WIDGET' && action.widgetType === widgetTypes.TEXT;
};

const isImageWidgetType = (widgetType: string): widgetType is 'IMAGE' | 'BACKGROUND_IMAGE' => {
    return widgetType === widgetTypes.IMAGE || widgetType === widgetTypes.BACKGROUND_IMAGE;
};

const updateWidgetsValue = (
    widgets: RootCrashDiagramState['diagram']['widgets'],
    widgetId: string,
    value: Widget['value']
): RootCrashDiagramState['diagram']['widgets'] => {
    return widgets.map((widget) => {
        if (widget.id === widgetId) {
            const { position } = widget;
            let newPosition = position;

            if (widget.type === widgetTypes.TEXT) {
                const { height, offsetY } = getUpdatedTextWidgetPosition(
                    widget.konvaInstance,
                    value
                );
                newPosition = {
                    ...position,
                    height,
                    offsetY,
                };
            }

            return {
                ...widget,
                value,
                position: newPosition,
            };
        }
        return widget;
    });
};

const getWidgetInitialValue = (
    userActions: CrashDiagramHistoryUserInteraction[],
    widget: Widget
) => {
    const lastWidgetMoveUserAction = userActions
        .reverse()
        .find((item) => item.widgetId === widget.id && item.type === 'WIDGET_VALUE_CHANGE');

    const initialValue = !!lastWidgetMoveUserAction?.after?.value
        ? lastWidgetMoveUserAction.after.value
        : widgetTypeToDefaultValue[widget.type];

    return initialValue;
};

export const addToExistingUserActions: AddToExistingUserActionsArgs = (
    existingUserActions,
    currentPosition,
    newUserActions
): Pick<RootCrashDiagramState['history'], 'userActions' | 'currentPosition'> => {
    const updatedUserActions = existingUserActions.slice(0, currentPosition);
    updatedUserActions.push(...newUserActions);

    return {
        userActions: updatedUserActions.sort((a, b) => {
            if (a.createdDateUtc < b.createdDateUtc) {
                return -1;
            }
            if (a.createdDateUtc > b.createdDateUtc) {
                return 1;
            }
            return 0;
        }),
        currentPosition: updatedUserActions.length,
    };
};

const getWidgetFromStateById = (widgets: Widget[], widgetId: string) => {
    return widgets.find((widget) => widget.id === widgetId);
};

export const crashDiagramRootReducer = (
    state: RootCrashDiagramState,
    action: RootCrashDiagramActions
): RootCrashDiagramState => {
    switch (action.type) {
        case 'DUPLICATE_WIDGET_ON_DIAGRAM':
            const sourceWidget = getWidgetFromStateById(
                state.diagram.widgets,
                action.payload.widgetId
            );

            if (!sourceWidget) {
                return state;
            }

            const { position, value, type, opacity } = sourceWidget;

            const {
                x,
                y,
                width = DEFAULT_WIDGET_POSITION.width,
                height = DEFAULT_WIDGET_POSITION.height,
                scaleX = DEFAULT_WIDGET_POSITION.scaleX,
                scaleY = DEFAULT_WIDGET_POSITION.scaleY,
                rotation = DEFAULT_WIDGET_POSITION.rotation,
            } = position;

            const { x: newX, y: newY } = calculateConstrainedPosition(
                {
                    width,
                    height,
                    scaleX,
                    scaleY,
                    rotation,
                    x,
                    y,
                },
                STAGE_WIDTH_LARGE,
                STAGE_HEIGHT,
                true
            );

            const duplicatedWidget = createWidget({
                value,
                type,
                opacity,
                position: {
                    ...position,
                    x: newX,
                    y: newY,
                },
            });

            return getCrashDiagramStateFromAddingNewWidget(state, duplicatedWidget);
        case 'CREATE_GHOST_WIDGETS_ON_DIAGRAM':
            const ghostSourceWidget = getWidgetFromStateById(
                state.diagram.widgets,
                action.payload.widgetId
            );

            if (!ghostSourceWidget) {
                return state;
            }

            const {
                position: ghostPosition,
                value: ghostValue,
                type: ghostType,
                opacity: ghostOpacity,
            } = ghostSourceWidget;

            const {
                x: ghostX,
                y: ghostY,
                width: ghostWidth = DEFAULT_WIDGET_POSITION.width,
                height: ghostHeight = DEFAULT_WIDGET_POSITION.height,
                scaleX: ghostScaleX = DEFAULT_WIDGET_POSITION.scaleX,
                scaleY: ghostScaleY = DEFAULT_WIDGET_POSITION.scaleY,
                rotation: ghostRotation = DEFAULT_WIDGET_POSITION.rotation,
            } = ghostPosition;

            const newWidgets = [];
            const newActions: DiagramUserInteractionType[] = [];

            let previousX = ghostX;
            let previousY = ghostY;

            for (let i = 0; i < action.payload.numberOfDuplicates; i++) {
                const isFirst = i === 0;
                const { x: newX, y: newY } = calculateConstrainedPosition(
                    {
                        width: ghostWidth,
                        height: ghostHeight,
                        scaleX: ghostScaleX,
                        scaleY: ghostScaleY,
                        rotation: ghostRotation,
                        x: isFirst ? ghostX : previousX,
                        y: isFirst ? ghostY : previousY,
                    },
                    STAGE_WIDTH_LARGE,
                    STAGE_HEIGHT,
                    true
                );

                const newWidget = createWidget({
                    value: ghostValue,
                    type: ghostType,
                    opacity: getGhostOpacity(ghostOpacity, action.payload.numberOfDuplicates, i),
                    position: {
                        ...ghostPosition,
                        x: newX,
                        y: newY,
                    },
                });

                newWidgets.push(newWidget);

                newActions.push({
                    type: 'ADD_WIDGET',
                    widgetId: newWidget.id,
                    widgetType: newWidget.type,
                    konvaInstance: newWidget.konvaInstance,
                    before: undefined,
                    after: {
                        value: newWidget.value,
                        position: newWidget.position,
                        opacity: newWidget.opacity,
                    },
                });

                previousX = newX;
                previousY = newY;
            }

            const updatedWidgets = addToExistingWidgetsOnDiagram(state.diagram.widgets, newWidgets);
            const updatedUserActions = createUserInteractionHistoryEvents(newActions);

            return {
                ...state,
                diagram: {
                    ...state.diagram,
                    widgets: updatedWidgets,
                },
                history: {
                    ...state.history,
                    ...addToExistingUserActions(
                        state.history.userActions,
                        state.history.currentPosition,
                        updatedUserActions
                    ),
                },
            };
        case 'ADD_WIDGET_TO_DIAGRAM':
            return getCrashDiagramStateFromAddingNewWidget(state, action.payload.widget);
        case 'WIDGET_ACTION_TYPE':
            const widgetActionType = getWidgetActionType(action.payload.action);
            switch (widgetActionType) {
                case 'FLIP_WIDGET_ON_DIAGRAM':
                    return getUpdatedFlipState(state, action.payload);
                case 'ARRANGE_WIDGET_ON_DIAGRAM':
                    return getUpdatedArrangeState(state, action.payload);
                default:
                    return state;
            }
        case 'REMOVE_WIDGET_FROM_DIAGRAM':
            const widgetToDelete = getWidgetFromStateById(
                state.diagram.widgets,
                action.payload.widgetId
            );

            if (!widgetToDelete) {
                return state;
            }

            const deleteUserActions = createUserInteractionHistoryEvents([
                {
                    type: 'DELETE_WIDGET',
                    widgetId: widgetToDelete.id,
                    widgetType: widgetToDelete.type,
                    konvaInstance: widgetToDelete.konvaInstance,
                    after: undefined,
                    before: {
                        value: widgetToDelete.value,
                        position: widgetToDelete.position,
                        opacity: widgetToDelete.opacity,
                    },
                },
            ]);

            return {
                ...state,
                diagram: {
                    ...state.diagram,
                    widgets: removeWidgetsFromDiagram(state.diagram.widgets, [
                        action.payload.widgetId,
                    ]),
                    selectedWidget: undefined,
                },
                history: {
                    ...state.history,
                    ...addToExistingUserActions(
                        state.history.userActions,
                        state.history.currentPosition,
                        deleteUserActions
                    ),
                },
            };
        case 'SET_WIDGET_OPACITY_ON_DIAGRAM':
            const opacityWidget = getWidgetFromStateById(
                state.diagram.widgets,
                action.payload.widgetId
            );

            const newOpacity = action.payload.opacity / 100;

            if (!opacityWidget || opacityWidget.opacity === newOpacity) {
                return state;
            }

            const setOpacityUserActions = createUserInteractionHistoryEvents([
                {
                    type: 'SET_WIDGET_OPACITY',
                    widgetId: opacityWidget.id,
                    widgetType: opacityWidget.type,
                    konvaInstance: undefined,
                    before: {
                        value: undefined,
                        position: opacityWidget.position,
                        opacity: opacityWidget.opacity,
                    },
                    after: {
                        value: undefined,
                        position: opacityWidget.position,
                        opacity: newOpacity,
                    },
                },
            ]);

            return {
                ...state,
                diagram: {
                    ...state.diagram,
                    widgets: updateWidgetPropertiesOnDiagram(
                        state.diagram.widgets,
                        action.payload.widgetId,
                        { opacity: newOpacity }
                    ),
                },
                history: {
                    ...state.history,
                    ...addToExistingUserActions(
                        state.history.userActions,
                        state.history.currentPosition,
                        setOpacityUserActions
                    ),
                },
            };
        case 'TRANFORM_WIDGET_ON_DIAGRAM':
            const widget = getWidgetFromStateById(state.diagram.widgets, action.payload.widgetId);

            if (!widget) {
                return state;
            }

            const transformUserActions = createUserInteractionHistoryEvents([
                {
                    type: 'TRANSFORM_WIDGET',
                    widgetId: widget.id,
                    widgetType: widget.type,
                    konvaInstance: widget.konvaInstance,
                    before: {
                        value: undefined,
                        position: widget.position,
                        opacity: undefined,
                    },
                    after: {
                        value: undefined,
                        position: action.payload.position,
                        opacity: undefined,
                    },
                },
            ]);

            return {
                ...state,
                diagram: {
                    ...state.diagram,
                    widgets: updateWidgetPropertiesOnDiagram(
                        state.diagram.widgets,
                        action.payload.widgetId,
                        { position: action.payload.position }
                    ),
                },
                history: {
                    ...state.history,
                    ...addToExistingUserActions(
                        state.history.userActions,
                        state.history.currentPosition,
                        transformUserActions
                    ),
                },
            };
        case 'UNDO':
            const previousPosition = state.history.currentPosition - 1;
            const currentUserAction = state.history.userActions[previousPosition];

            if (!currentUserAction) {
                return state;
            }

            let undoActionUpdatedWidgets = state.diagram.widgets;

            const undoGroupId = currentUserAction.groupId;
            const filteredUndoUserActions = state.history.userActions.filter(
                (action) => action.groupId === undoGroupId
            );
            const numberOfUndoUserActions = filteredUndoUserActions.length;
            const newState = state;

            for (let i = 0; i < numberOfUndoUserActions; i++) {
                const current = filteredUndoUserActions[0];
                switch (current.type) {
                    case 'ADD_WIDGET':
                        const widgetId = filteredUndoUserActions[i].widgetId;
                        undoActionUpdatedWidgets = removeWidgetsFromDiagram(
                            undoActionUpdatedWidgets,
                            [widgetId]
                        );
                        break;
                    case 'FLIP_ITEM_HORIZONTAL':
                    case 'FLIP_ITEM_VERTICAL':
                        undoActionUpdatedWidgets = flipWidgetOnDiagram(
                            undoActionUpdatedWidgets,
                            current.widgetId,
                            current.type
                        );
                        break;
                    case 'SET_WIDGET_OPACITY':
                        undoActionUpdatedWidgets = updateWidgetPropertiesOnDiagram(
                            undoActionUpdatedWidgets,
                            current.widgetId,
                            { opacity: current.before.opacity }
                        );
                        break;
                    case 'TRANSFORM_WIDGET':
                        undoActionUpdatedWidgets = updateWidgetPropertiesOnDiagram(
                            undoActionUpdatedWidgets,
                            current.widgetId,
                            { position: current.before.position }
                        );
                        break;
                    case 'DELETE_WIDGET':
                        const commonProperties = {
                            id: current.widgetId,
                            value: current.before.value,
                            position: current.before.position,
                            opacity: current.before.opacity,
                        };

                        let widgetToAdd;
                        if (isImageWidgetType(current.widgetType)) {
                            widgetToAdd = {
                                ...commonProperties,
                                type: current.widgetType,
                                konvaInstance: undefined,
                            };
                        } else if (isDeleteTextWidgetUserInteractionType(current)) {
                            widgetToAdd = {
                                ...commonProperties,
                                type: current.widgetType,
                                konvaInstance: current.konvaInstance,
                            };
                        }

                        if (!widgetToAdd) {
                            return newState;
                        }

                        undoActionUpdatedWidgets = addToExistingWidgetsOnDiagram(
                            undoActionUpdatedWidgets,
                            [widgetToAdd]
                        );
                        break;
                    case 'WIDGET_VALUE_CHANGE':
                        undoActionUpdatedWidgets = updateWidgetsValue(
                            undoActionUpdatedWidgets,
                            current.widgetId,
                            current.before.value
                        );
                        break;
                    case 'BRING_ITEM_TO_FRONT':
                        undoActionUpdatedWidgets = arrangeWidgetOnDiagram(
                            undoActionUpdatedWidgets,
                            current.widgetId,
                            widgetActions.SEND_ITEM_TO_BACK
                        );
                        break;
                    case 'SEND_ITEM_TO_BACK':
                        undoActionUpdatedWidgets = arrangeWidgetOnDiagram(
                            undoActionUpdatedWidgets,
                            current.widgetId,
                            widgetActions.BRING_ITEM_TO_FRONT
                        );
                        break;
                    default:
                        break;
                }
            }

            return {
                ...newState,
                diagram: {
                    ...newState.diagram,
                    widgets: undoActionUpdatedWidgets,
                    selectedWidget: undefined,
                },
                history: {
                    ...newState.history,
                    currentPosition: newState.history.currentPosition - numberOfUndoUserActions,
                },
            };
        case 'REDO':
            const currentPosition = state.history.currentPosition;
            const nextUserAction = state.history.userActions[currentPosition];

            if (!nextUserAction) {
                return state;
            }

            let redoActionWidgets = state.diagram.widgets;

            const redoGroupId = nextUserAction.groupId;
            const filteredRedoUserActions = state.history.userActions.filter(
                (action) => action.groupId === redoGroupId
            );
            const numberOfRedoUserActions = filteredRedoUserActions.length;
            const updatedState = state;

            for (let i = 0; i < numberOfRedoUserActions; i++) {
                const current = filteredRedoUserActions[i];
                switch (current.type) {
                    case 'ADD_WIDGET':
                        const widget = filteredRedoUserActions
                            .filter(
                                (action): action is ExtendedAddWidgetUserInteractionType =>
                                    !!action.after
                            )
                            .map((userAction) => {
                                const commonProperties = {
                                    id: userAction.widgetId,
                                    value: userAction.after.value,
                                    position: userAction.after.position,
                                    opacity: userAction.after.opacity,
                                };

                                if (isAddTextWidgetUserInteractionType(userAction)) {
                                    return {
                                        ...commonProperties,
                                        type: userAction.widgetType,
                                        konvaInstance: userAction.konvaInstance,
                                    };
                                } else if (isImageWidgetType(userAction.widgetType)) {
                                    return {
                                        ...commonProperties,
                                        type: userAction.widgetType,
                                        konvaInstance: undefined,
                                    };
                                } else {
                                    return;
                                }
                            })[i];

                        if (!widget) {
                            return updatedState;
                        }

                        redoActionWidgets = addToExistingWidgetsOnDiagram(redoActionWidgets, [
                            widget,
                        ]);

                        break;
                    case 'BRING_ITEM_TO_FRONT':
                    case 'SEND_ITEM_TO_BACK':
                        redoActionWidgets = arrangeWidgetOnDiagram(
                            redoActionWidgets,
                            filteredRedoUserActions[i].widgetId,
                            current.type
                        );
                        break;
                    case 'FLIP_ITEM_HORIZONTAL':
                    case 'FLIP_ITEM_VERTICAL':
                        redoActionWidgets = flipWidgetOnDiagram(
                            redoActionWidgets,
                            current.widgetId,
                            current.type
                        );
                        break;
                    case 'SET_WIDGET_OPACITY':
                        redoActionWidgets = updateWidgetPropertiesOnDiagram(
                            redoActionWidgets,
                            current.widgetId,
                            { opacity: current.after.opacity }
                        );
                        break;
                    case 'TRANSFORM_WIDGET':
                        redoActionWidgets = updateWidgetPropertiesOnDiagram(
                            redoActionWidgets,
                            current.widgetId,
                            { position: current.after.position }
                        );
                        break;
                    case 'DELETE_WIDGET':
                        redoActionWidgets = removeWidgetsFromDiagram(redoActionWidgets, [
                            current.widgetId,
                        ]);
                        break;
                    case 'WIDGET_VALUE_CHANGE':
                        redoActionWidgets = updateWidgetsValue(
                            redoActionWidgets,
                            current.widgetId,
                            current.after.value
                        );
                        break;
                    default:
                        break;
                }
            }

            return {
                ...updatedState,
                diagram: {
                    ...updatedState.diagram,
                    widgets: redoActionWidgets,
                    selectedWidget: undefined,
                },
                history: {
                    ...updatedState.history,
                    currentPosition: currentPosition + numberOfRedoUserActions,
                },
            };
        case 'SET_SELECTED_WIDGET':
            return {
                ...state,
                diagram: {
                    ...state.diagram,
                    selectedWidget: action.payload.widget,
                },
            };
        case 'CLEAR_SELECTED_WIDGET':
            return {
                ...state,
                diagram: {
                    ...state.diagram,
                    selectedWidget: undefined,
                },
            };
        case 'SET_DIAGRAM_BACKGROUND_IMAGE':
            return {
                ...state,
                diagram: {
                    ...state.diagram,
                    backgroundImage: action.payload.backgroundImage,
                },
            };
        case 'UPDATE_WIDGET_VALUE':
            const widgetToUpdate = getWidgetFromStateById(
                state.diagram.widgets,
                action.payload.widgetId
            );

            if (!widgetToUpdate) {
                return state;
            }

            let historyState = state.history;
            const diagramState: CrashDiagramState = {
                ...state.diagram,
                widgets: updateWidgetsValue(
                    state.diagram.widgets,
                    action.payload.widgetId,
                    action.payload.value
                ),
                selectedWidget: {
                    ...widgetToUpdate,
                    value: action.payload.value,
                },
            };
            if (action.payload.commit) {
                const widgetsInitialValue = getWidgetInitialValue(
                    state.history.userActions,
                    widgetToUpdate
                );

                const valueChangeUserActions = createUserInteractionHistoryEvents([
                    {
                        widgetId: widgetToUpdate.id,
                        type: 'WIDGET_VALUE_CHANGE',
                        widgetType: widgetToUpdate.type,
                        konvaInstance: widgetToUpdate.konvaInstance,
                        before: {
                            position: undefined,
                            value: widgetsInitialValue,
                            opacity: undefined,
                        },
                        after: {
                            position: undefined,
                            value: action.payload.value,
                            opacity: undefined,
                        },
                    },
                ]);

                historyState = {
                    ...historyState,
                    ...addToExistingUserActions(
                        state.history.userActions,
                        state.history.currentPosition,
                        valueChangeUserActions
                    ),
                };
            }

            return {
                ...state,
                diagram: diagramState,
                history: historyState,
            };
        case 'CLEAR_DIAGRAM':
            return {
                ...rootInitialState,
                diagram: {
                    ...rootInitialState.diagram,
                    backgroundImage: state.diagram.backgroundImage,
                },
            };
        case 'RESET_DIAGRAM':
            return rootInitialState;
        case 'SET_CRASH_DIAGRAM_STATE':
            return {
                ...rootInitialState,
                diagram: action.payload.crashDiagramState,
            };
        case 'SET_DIAGRAM_ASSETS':
            const { diagramType, diagramCategory, assets } = action.payload;
            return {
                ...state,
                diagram: {
                    ...state.diagram,
                    assets: {
                        ...state.diagram.assets,
                        [diagramType]: {
                            ...state.diagram.assets[diagramType],
                            [diagramCategory]: {
                                ...state.diagram.assets[diagramType][diagramCategory],
                                assets: [
                                    ...state.diagram.assets[diagramType][diagramCategory].assets,
                                    ...assets,
                                ],
                                isFetched: true,
                            },
                        },
                    },
                },
            };
        case 'ADD_EXTERNAL_DIAGRAM_ASSET':
            const {
                diagramType: diagramTypeForExternalAsset,
                diagramCategory: diagramCategoryForExternalAsset,
                asset,
            } = action.payload;
            return {
                ...state,
                diagram: {
                    ...state.diagram,
                    backgroundImage: asset.base64String,
                    assets: {
                        ...state.diagram.assets,
                        [diagramTypeForExternalAsset]: {
                            ...state.diagram.assets[diagramTypeForExternalAsset],
                            [diagramCategoryForExternalAsset]: {
                                ...state.diagram.assets[diagramTypeForExternalAsset][
                                    diagramCategoryForExternalAsset
                                ],
                                assets: [
                                    asset,
                                    ...state.diagram.assets[diagramTypeForExternalAsset][
                                        diagramCategoryForExternalAsset
                                    ].assets,
                                ],
                            },
                        },
                    },
                },
            };
        default:
            return state;
    }
};
