import { AttributeTypeEnum } from '@mark43/rms-api';

import {
    createFormConfiguration,
    createFieldset,
    createNItems,
    createField,
    MFTFieldsetConfiguration,
    MFTFieldsetConfigurationBase,
    InferFormDataShape,
} from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import { ValueOf } from '~/client-common/types';
import { personProfilePaths } from './types';

function createPhoneNumberFields(
    displayNumberFieldKey: string,
    displayNumberField: ValueOf<typeof fields>
) {
    return {
        // see PhoneNumberInput for how these 3 fields are used
        baseDisplayNumber: createField<string>({}),
        hashedCountryCode: createField<string>({}),
        initialCountryCode: createField<string>({}),
        [displayNumberFieldKey]: createField<string>({ fieldName: displayNumberField }),
    };
}

export function createPersonProfileFormConfiguration() {
    return createFormConfiguration({
        // We don't really need this here, just keeping
        // it for visibility
        [personProfilePaths.id]: createField<number>({}),
        // only used to look up related data when saving the entity.
        // this is required because we pass down a different/nulled out
        // id through our "connect props" inside our panel
        [personProfilePaths.masterPersonId]: createField<number>({}),
        [personProfilePaths.firstNameUnknown]: createField<boolean>({
            fieldName: fields.DISPLAY_PERSON_PROFILE_IS_FIRST_NAME_UNKNOWN,
        }),
        [personProfilePaths.lastNameUnknown]: createField<boolean>({
            fieldName: fields.DISPLAY_PERSON_PROFILE_IS_LAST_NAME_UNKNOWN,
        }),
        [personProfilePaths.firstName]: createField<string>({
            fieldName: fields.PERSON_PROFILE_FIRST_NAME,
        }),
        [personProfilePaths.middleName]: createField<string>({
            fieldName: fields.PERSON_PROFILE_MIDDLE_NAME,
        }),
        [personProfilePaths.lastName]: createField<string>({
            fieldName: fields.PERSON_PROFILE_LAST_NAME,
        }),
        [personProfilePaths.motherName]: createField<string>({
            fieldName: fields.PERSON_PROFILE_MOTHER_NAME,
        }),
        [personProfilePaths.motherMaidenName]: createField<string>({
            fieldName: fields.PERSON_PROFILE_MOTHER_MAIDEN_NAME,
        }),
        [personProfilePaths.fatherName]: createField<string>({
            fieldName: fields.PERSON_PROFILE_FATHER_NAME,
        }),
        [personProfilePaths.title]: createField<string>({
            fieldName: fields.PERSON_PROFILE_TITLE,
        }),
        [personProfilePaths.suffix]: createField<string>({
            fieldName: fields.PERSON_PROFILE_SUFFIX,
        }),
        [personProfilePaths.dateOfBirth]: createField<string>({
            fieldName: fields.PERSON_PROFILE_DATE_OF_BIRTH,
        }),
        [personProfilePaths.isDobUnknown]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_IS_DOB_UNKNOWN,
        }),
        [personProfilePaths.age]: createField<number>({
            fieldName: fields.DISPLAY_ONLY_PERSON_PROFILE_AGE,
        }),
        [personProfilePaths.dateOfEmancipation]: createField<string>({
            fieldName: fields.PERSON_PROFILE_DATE_OF_EMANCIPATION,
        }),
        [personProfilePaths.placeOfBirth]: createField<string>({
            fieldName: fields.PERSON_PROFILE_PLACE_OF_BIRTH,
        }),
        // These will be display only fields
        // and only exist so that we can
        // configure the names
        [personProfilePaths.ageRangeLabel]: createField({
            fieldName: fields.DISPLAY_ONLY_AGE_RANGE,
        }),
        [personProfilePaths.ageMin]: createField<number>({
            fieldName: fields.PERSON_PROFILE_AGE_MIN,
        }),
        [personProfilePaths.ageMax]: createField<number>({
            fieldName: fields.PERSON_PROFILE_AGE_MAX,
        }),
        [personProfilePaths.isUnborn]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_IS_UNBORN,
        }),
        [personProfilePaths.expectedDateOfBirth]: createField<string>({
            fieldName: fields.PERSON_PROFILE_EXPECTED_DATE_OF_BIRTH,
        }),
        [personProfilePaths.isExpectedDateOfBirthUnknown]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_IS_EXPECTED_DATE_OF_BIRTH_UNKNOWN,
        }),
        [personProfilePaths.isDead]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_IS_DEAD,
        }),
        [personProfilePaths.dateOfDeathUtc]: createField<string>({
            fieldName: fields.PERSON_PROFILE_DATE_OF_DEATH_UTC,
        }),
        // POLE
        [personProfilePaths.verificationOfDeathAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_VERIFICATION_OF_DEATH_ATTR_ID,
        }),
        [personProfilePaths.isJuvenile]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_IS_JUVENILE,
        }),
        [personProfilePaths.infantAge]: createField<number>({
            fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_INFANT_AGE_ATTRIBUTE_ID,
        }),
        [personProfilePaths.isPregnantAttrId]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_IS_PREGNANT_ATTR_ID,
        }),
        [personProfilePaths.ssn]: createField<number>({
            fieldName: fields.PERSON_PROFILE_SSN,
        }),
        // We need to keep track of this in the form
        // object so that we can properly disable the field
        [personProfilePaths.showAdditionalHeightFields]: createField<boolean>({
            fieldName: fields.DISPLAY_ONLY_SHOW_HEIGHT_RANGE,
        }),
        // These will be display only fields
        // and only exist so that we can
        // configure the names
        [personProfilePaths.heightLabel]: createField<number>({
            fieldName: fields.DISPLAY_ONLY_HEIGHT,
        }),
        [personProfilePaths.heightMinLabel]: createField<number>({
            fieldName: fields.DISPLAY_ONLY_HEIGHT_MIN,
        }),
        [personProfilePaths.heightMaxLabel]: createField<number>({
            fieldName: fields.DISPLAY_ONLY_HEIGHT_MAX,
        }),
        [personProfilePaths.height]: createField<number>({
            fieldName: fields.PERSON_PROFILE_HEIGHT,
        }),
        [personProfilePaths.heightFeet]: createField<number>({
            fieldName: fields.PERSON_PROFILE_HEIGHT_FEET,
        }),
        [personProfilePaths.heightInches]: createField<number>({
            fieldName: fields.PERSON_PROFILE_HEIGHT_INCHES,
        }),
        [personProfilePaths.heightMinFeet]: createField<number>({
            fieldName: fields.PERSON_PROFILE_HEIGHT_MIN_FEET,
        }),
        [personProfilePaths.heightMinInches]: createField<number>({
            fieldName: fields.PERSON_PROFILE_HEIGHT_MIN_INCHES,
        }),
        [personProfilePaths.heightMaxFeet]: createField<number>({
            fieldName: fields.PERSON_PROFILE_HEIGHT_MAX_FEET,
        }),
        [personProfilePaths.heightMaxInches]: createField<number>({
            fieldName: fields.PERSON_PROFILE_HEIGHT_MAX_INCHES,
        }),
        // We need to keep track of this in the form
        // object so that we can properly disable the field
        [personProfilePaths.showAdditionalWeightFields]: createField<boolean>({
            fieldName: fields.DISPLAY_ONLY_SHOW_WEIGHT_RANGE,
        }),
        [personProfilePaths.weight]: createField<number>({
            fieldName: fields.PERSON_PROFILE_WEIGHT,
        }),
        [personProfilePaths.weightRangeMin]: createField<number>({
            fieldName: fields.PERSON_PROFILE_WEIGHT_RANGE_MIN,
        }),
        [personProfilePaths.weightRangeMax]: createField<number>({
            fieldName: fields.PERSON_PROFILE_WEIGHT_RANGE_MAX,
        }),
        [personProfilePaths.clothing]: createFieldset({
            // the actual fields here are dynamic and based on attribute values,
            // so we cannot fill them in unfortunately
            fields: createField({}),
        }),
        [personProfilePaths.isNonDisclosureRequest]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_IS_NON_DISCLOSURE_REQUEST,
        }),
        [personProfilePaths.fbiUcn]: createField<string>({
            fieldName: fields.PERSON_PROFILE_FBI_UCN,
        }),
        [personProfilePaths.stateIdNumber]: createField<string>({
            fieldName: fields.PERSON_PROFILE_STATE_ID_NUMBER,
        }),
        [personProfilePaths.details]: createField<string>({
            fieldName: fields.PERSON_PROFILE_DETAILS,
        }),
        [personProfilePaths.needsInterpreter]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_NEEDS_INTERPRETER,
        }),
        [personProfilePaths.sexAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_SEX_ATTR_ID,
        }),
        [personProfilePaths.raceAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_RACE_ATTR_ID,
        }),
        [personProfilePaths.ethnicityAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_ETHNICITY_ATTR_ID,
        }),
        [personProfilePaths.citizenshipAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_CITIZENSHIP_ATTR_ID,
        }),
        [personProfilePaths.eyeColorAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_EYE_COLOR_ATTR_ID,
        }),
        [personProfilePaths.skinToneAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_SKIN_TONE_ATTR_ID,
        }),
        [personProfilePaths.hairColorAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_HAIR_COLOR_ATTR_ID,
        }),
        [personProfilePaths.hairStyleAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_HAIR_STYLE_ATTR_ID,
        }),
        [personProfilePaths.hairLengthAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_HAIR_LENGTH_ATTR_ID,
        }),
        [personProfilePaths.facialHairTypeAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_FACIAL_HAIR_TYPE_ATTR_ID,
        }),
        [personProfilePaths.visionAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_VISION_ATTR_ID,
        }),
        [personProfilePaths.buildAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_BUILD_ATTR_ID,
        }),
        [personProfilePaths.maritalStatusAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_MARITAL_STATUS_ATTR_ID,
        }),
        [personProfilePaths.religionAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_RELIGION_ATTR_ID,
        }),
        [personProfilePaths.religionOther]: createField<string>({
            fieldName: fields.PERSON_PROFILE_RELIGION_OTHER,
        }),
        [personProfilePaths.sexualOrientationAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_SEXUAL_ORIENTATION_ATTR_ID,
        }),
        [personProfilePaths.croId]: createField<string>({
            fieldName: fields.PERSON_PROFILE_CRO_ID,
        }),
        [personProfilePaths.pncId]: createField<string>({
            fieldName: fields.PERSON_PROFILE_PNC_ID,
        }),
        [personProfilePaths.nationalIdNumber]: createField<string>({
            fieldName: fields.PERSON_PROFILE_NATIONAL_ID_NUMBER,
        }),
        [personProfilePaths.isVulnerable]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_IS_VULNERABLE,
        }),
        [personProfilePaths.dateVulnerableFrom]: createField<string>({
            fieldName: fields.PERSON_PROFILE_DATE_VULNERABLE_FROM,
        }),
        [personProfilePaths.dateVulnerableTo]: createField<string>({
            fieldName: fields.PERSON_PROFILE_DATE_VULNERABLE_TO,
        }),
        [personProfilePaths.sexualOrientationOther]: createField<string>({
            fieldName: fields.PERSON_PROFILE_SEXUAL_ORIENTATION_OTHER,
        }),
        [personProfilePaths.personProbations]: createNItems({
            fields: {
                probationTypeAttrId: createField<number>({
                    fieldName: fields.PERSON_PROBATION_PROBATION_TYPE_ATTR_ID,
                }),
                probationOfficer: createField<string>({
                    fieldName: fields.PERSON_PROBATION_PROBATION_OFFICER,
                }),
                miscDescription: createField<string>({
                    fieldName: fields.PERSON_PROBATION_MISC_DESCRIPTION,
                }),
            },
        }),
        [personProfilePaths.birthStateAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_BIRTH_STATE_ATTR_ID,
        }),
        [personProfilePaths.isResidentOfJurisdictionAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_IS_RESIDENT_OF_JURISDICTION_ATTR_ID,
        }),
        [personProfilePaths.dlCountry]: createField<string>({
            fieldName: fields.PERSON_PROFILE_DL_COUNTRY,
        }),
        [personProfilePaths.dlNumber]: createField<string>({
            fieldName: fields.PERSON_PROFILE_DL_NUMBER,
        }),
        [personProfilePaths.dlTypeAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_DL_TYPE_ATTR_ID,
        }),
        [personProfilePaths.dlTypeOther]: createField<string>({
            fieldName: fields.PERSON_PROFILE_DL_TYPE_OTHER,
        }),
        [personProfilePaths.dlStateAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_DL_STATE_ATTR_ID,
        }),
        [personProfilePaths.dlStatusAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_DL_STATUS_ATTR_ID,
        }),
        [personProfilePaths.dlExpiryDateUtc]: createField<string>({
            fieldName: fields.PERSON_PROFILE_DL_EXPIRY_DATE_UTC,
        }),
        [personProfilePaths.dlEndorsementAttrId]: createField<number>({
            fieldName: fields.PERSON_PROFILE_DL_ENDORSEMENT_ATTR_ID,
        }),
        [personProfilePaths.priorHistoryOfDomesticViolence]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_PRIOR_HISTORY_OF_DOMESTIC_VIOLENCE,
        }),
        [personProfilePaths.emergencyContacts]: createNItems({
            fieldName: fields.PERSON_EMERGENCY_CONTACT_N_ITEMS_WRAPPER,
            fields: {
                emergencyContactAddress: createField<string>({
                    fieldName: fields.PERSON_EMERGENCY_CONTACT_EMERGENCY_CONTACT_ADDRESS,
                }),
                emergencyContactName: createField<string>({
                    fieldName: fields.PERSON_EMERGENCY_CONTACT_EMERGENCY_CONTACT_NAME,
                }),
                ...createPhoneNumberFields(
                    'emergencyContactPhoneNumber',
                    fields.PERSON_EMERGENCY_CONTACT_EMERGENCY_CONTACT_PHONE_NUMBER
                ),
                emergencyContactLinkType: createField<string>({
                    fieldName: fields.PERSON_EMERGENCY_CONTACT_EMERGENCY_CONTACT_LINK_TYPE,
                }),
                isGuardian: createField<boolean>({
                    fieldName: fields.PERSON_EMERGENCY_CONTACT_IS_GUARDIAN,
                }),
                dateEffectiveFrom: createField<string>({
                    fieldName: fields.PERSON_EMERGENCY_CONTACT_DATE_EFFECTIVE_FROM,
                }),
                dateEffectiveTo: createField<string>({
                    fieldName: fields.PERSON_EMERGENCY_CONTACT_DATE_EFFECTIVE_TO,
                }),
            },
        }),
        [personProfilePaths.isPhoneNumberNotGiven]: createField<boolean>({
            fieldName: fields.DISPLAY_ONLY_PERSON_PROFILE_IS_PHONE_NUMBER_NOT_GIVEN,
        }),
        // These 2 field are used to validate with offense code
        [personProfilePaths.nibrsCodeCode]: createField<string>({
            fieldName: fields.DISPLAY_ONLY_PERSON_PROFILE_OFFENSE_CODE,
        }),
        [personProfilePaths.nibrsOffenseCodeId]: createField<number>({
            fieldName: fields.DISPLAY_ONLY_PERSON_PROFILE_OFFENSE_NIBRS_CODE_ID,
        }),
        // gang tracking
        [personProfilePaths.isAllegedGangMember]: createField<boolean>({
            fieldName: fields.PERSON_PROFILE_IS_ALLEGED_GANG_MEMBER,
        }),
        [personProfilePaths.gangName]: createFieldset({
            fields: {
                gangNameAttrId: createField<number>({
                    fieldName: fields.PERSON_GANG_TRACKING_GANG_NAME_ATTR_ID,
                }),
                gangNameOther: createField<string>({
                    fieldName: fields.PERSON_GANG_TRACKING_GANG_NAME_OTHER,
                }),
            },
            fieldName: fields.DISPLAY_ONLY_PERSON_PROFILE_GANG_NAME_WRAPPER,
        }),
        [personProfilePaths.gangCriteria]: createNItems({
            fieldName: fields.DISPLAY_ONLY_GANG_CRITERIA_N_ITEMS_WRAPPER,
            fields: {
                attributeId: createField<number>({
                    fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_GANG_CRITERIA_ATTRIBUTE_ID,
                }),
                description: createField<string>({
                    fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_GANG_CRITERIA_DESCRIPTION,
                }),
            },
        }),
        [personProfilePaths.phoneNumbers]: createNItems({
            fieldName: fields.DISPLAY_ONLY_NAME_PHONE_NUMBER_N_ITEMS_WRAPPER,
            fields: {
                id: createField<number>({}), // used to update existing phone numbers
                phoneType: createField<string>({
                    fieldName: fields.NAME_PHONE_NUMBER_PHONE_TYPE,
                }),
                phoneTypeOther: createField<string>({
                    fieldName: fields.DISPLAY_ONLY_NAME_PHONE_NUMBER_PHONE_TYPE_OTHER,
                }),
                ...createPhoneNumberFields('displayNumber', fields.NAME_PHONE_NUMBER_PHONE_NUMBER),
                isPrimary: createField<boolean>({
                    fieldName: fields.NAME_PHONE_NUMBER_IS_PRIMARY,
                }),
                dateEffectiveFrom: createField<string>({
                    fieldName: fields.NAME_PHONE_NUMBER_DATE_EFFECTIVE_FROM,
                }),
                dateEffectiveTo: createField<string>({
                    fieldName: fields.NAME_PHONE_NUMBER_DATE_EFFECTIVE_TO,
                }),
                provenance: createField<string>({
                    fieldName: fields.NAME_PHONE_NUMBER_PROVENANCE,
                }),
                isSafeContact: createField<string>({
                    fieldName: fields.NAME_PHONE_NUMBER_IS_SAFE_CONTACT,
                }),
                safeContactDetails: createField<string>({
                    fieldName: fields.NAME_PHONE_NUMBER_SAFE_CONTACT_DETAILS,
                }),
            },
        }),
        [personProfilePaths.emails]: createNItems({
            fields: {
                id: createField<number>({}), // used to update existing emails
                emailType: createField<string>({
                    fieldName: fields.NAME_EMAIL_EMAIL_TYPE,
                }),
                emailTypeOther: createField<string>({
                    fieldName: fields.DISPLAY_ONLY_NAME_EMAIL_EMAIL_TYPE_OTHER,
                }),
                emailAddress: createField<string>({
                    fieldName: fields.NAME_EMAIL_EMAIL_ADDRESS,
                }),
                dateEffectiveFrom: createField<string>({
                    fieldName: fields.NAME_EMAIL_DATE_EFFECTIVE_FROM,
                }),
                dateEffectiveTo: createField<string>({
                    fieldName: fields.NAME_EMAIL_DATE_EFFECTIVE_TO,
                }),
                provenance: createField<string>({
                    fieldName: fields.NAME_EMAIL_PROVENANCE,
                }),
                isPrimary: createField<boolean>({
                    fieldName: fields.NAME_EMAIL_IS_PRIMARY,
                }),
                isSafeContact: createField<boolean>({
                    fieldName: fields.NAME_EMAIL_IS_SAFE_CONTACT,
                }),
                safeContactDetails: createField<string>({
                    fieldName: fields.NAME_EMAIL_SAFE_CONTACT_DETAILS,
                }),
            },
        }),
        [personProfilePaths.monikers]: createNItems({
            fields: {
                id: createField<number>({}), // used to update existing monikers
                moniker: createField<string>({
                    fieldName: fields.NAME_MONIKER_MONIKER,
                }),
                dateOfBirthOther: createField<string>({
                    fieldName: fields.NAME_MONIKER_DATE_OF_BIRTH_OTHER,
                }),
            },
        }),
        [personProfilePaths.passports]: createNItems({
            fieldName: fields.PASSPORT_N_ITEMS_WRAPPER,
            fields: {
                number: createField<string>({
                    fieldName: fields.PASSPORT_NUMBER,
                }),
                dateOfExpiry: createField<string>({
                    fieldName: fields.PASSPORT_DATE_OF_EXPIRY,
                }),
                dateOfIssue: createField<string>({
                    fieldName: fields.PASSPORT_DATE_OF_ISSUE,
                }),
                country: createField<string>({
                    fieldName: fields.PASSPORT_COUNTRY,
                }),
            },
        }),
        [personProfilePaths.employmentHistories]: createNItems({
            fields: {
                id: createField<number>({}), // used to update existing employment histories
                employerName: createField<string>({
                    fieldName: fields.EMPLOYMENT_HISTORY_EMPLOYER_NAME,
                }),
                employerAddress: createField<string>({
                    fieldName: fields.EMPLOYMENT_HISTORY_EMPLOYER_ADDRESS,
                }),
                occupation: createField<string>({
                    fieldName: fields.EMPLOYMENT_HISTORY_OCCUPATION,
                }),
                ...createPhoneNumberFields('displayNumber', fields.EMPLOYMENT_HISTORY_PHONE_NUMBER),
                dateStart: createField<string>({
                    fieldName: fields.EMPLOYMENT_HISTORY_DATE_START,
                }),
                dateEnd: createField<string>({
                    fieldName: fields.EMPLOYMENT_HISTORY_DATE_END,
                }),
            },
        }),
        [personProfilePaths.militaryHistories]: createNItems({
            fields: {
                id: createField<number>({}), // used to update existing military histories
                militaryBranchAttrId: createField<number>({
                    fieldName: fields.EMPLOYMENT_HISTORY_MILITARY_BRANCH_ATTR_ID,
                }),
                dateStart: createField<string>({
                    fieldName: fields.MILITARY_HISTORY_DATE_START,
                }),
                dateEnd: createField<string>({
                    fieldName: fields.MILITARY_HISTORY_DATE_END,
                }),
            },
        }),
        [personProfilePaths.schoolHistories]: createNItems({
            fields: {
                id: createField<number>({}), // used to update existing school histories
                schoolName: createField<string>({
                    fieldName: fields.SCHOOL_HISTORY_SCHOOL_NAME,
                }),
                schoolAddress: createField<string>({
                    fieldName: fields.SCHOOL_HISTORY_SCHOOL_ADDRESS,
                }),
                grade: createField<string>({
                    fieldName: fields.SCHOOL_HISTORY_GRADE,
                }),
                status: createField<string>({
                    fieldName: fields.SCHOOL_HISTORY_STATUS,
                }),
                ...createPhoneNumberFields('displayNumber', fields.SCHOOL_HISTORY_PHONE_NUMBER),
            },
        }),
        [personProfilePaths.identifiers]: createNItems({
            fields: {
                id: createField<number>({}), // used to update existing identifiers
                nameIdentifierTypeAttrId: createField<number>({
                    fieldName: fields.NAME_IDENTIFIER_NAME_IDENTIFIER_TYPE_ATTR_ID,
                }),
                identifier: createField<string>({
                    fieldName: fields.NAME_IDENTIFIER_IDENTIFIER,
                }),
                nameIdentifierTypeOther: createField<string>({
                    fieldName: fields.NAME_IDENTIFIER_NAME_IDENTIFIER_TYPE_OTHER,
                }),
            },
        }),
        [personProfilePaths.identifyingMarks]: createNItems({
            fields: {
                id: createField<number>({}), // used to update existing identifying marks
                description: createField<string>({
                    fieldName: fields.IDENTIFYING_MARK_DESCRIPTION,
                }),
                bodyPartAttrId: createField<number>({
                    fieldName: fields.IDENTIFYING_MARK_BODY_PART_ATTR_ID,
                }),
                identifyingMarkTypeAttrId: createField<number>({
                    fieldName: fields.IDENTIFYING_MARK_IDENTIFYING_MARK_TYPE_ATTR_ID,
                }),
                // not really the id, it's an object with `file`, `image` and `preview` properties
                // technically this should be a fieldset with nested fields, but if we don't want
                // to validate this field we might not have to do this, as it is worse for
                // performance
                imageId: createField<Record<string, unknown>>({}),
            },
        }),
        [personProfilePaths.homeAddresses]: createNItems({
            // Only spread the values of the entityLink here
            // there is no need to keep track
            // of any locations for the form
            fields: {
                locationId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_LOCATION_ID,
                }),
                isUnknownLocation: createField<boolean>({
                    fieldName: fields.DISPLAY_ONLY_IS_UNKNOWN_LOCATION,
                }),
                positionAttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_POSITION_ATTR_ID,
                }),
                description: createField<string>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_DESCRIPTION,
                }),
                startDateUtc: createField<string>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_START_DATE_UTC,
                }),
                endDateUtc: createField<string>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_END_DATE_UTC,
                }),
                additionalDates: createNItems({
                    fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_HOME_ADDRESS_N_ITEMS_WRAPPER,
                    fields: {
                        startDateUtc: createField<string>({
                            fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_HOME_ADDRESS_START_DATE_UTC,
                        }),
                        endDateUtc: createField<string>({
                            fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_HOME_ADDRESS_END_DATE_UTC,
                        }),
                        description: createField<string>({
                            fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_HOME_ADDRESS_DESCRIPTION,
                        }),
                    },
                }),
            },
        }),
        [personProfilePaths.workAddresses]: createNItems({
            // Only spread the values of the entityLink here
            // there is no need to keep track
            // of any locations for the form
            fields: {
                locationId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_WORKS_AT_LOCATION_ID,
                }),
                positionAttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_WORKS_AT_POSITION_ATTR_ID,
                }),
                startDateUtc: createField<string>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_WORKS_AT_START_DATE_UTC,
                }),
                endDateUtc: createField<string>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_WORKS_AT_END_DATE_UTC,
                }),
                additionalDates: createNItems({
                    fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_WORK_ADDRESS_N_ITEMS_WRAPPER,
                    fields: {
                        startDateUtc: createField<string>({
                            fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_WORK_ADDRESS_START_DATE_UTC,
                        }),
                        endDateUtc: createField<string>({
                            fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_WORK_ADDRESS_END_DATE_UTC,
                        }),
                        description: createField<string>({
                            fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_WORK_ADDRESS_DESCRIPTION,
                        }),
                    },
                }),
            },
        }),
        [personProfilePaths.otherAddresses]: createNItems({
            // Only spread the values of the entityLink here
            // there is no need to keep track
            // of any locations for the form
            fields: {
                locationId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LOCATION_ID,
                }),
                positionAttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_OTHER_PERSON_LOCATION_POSITION_ATTR_ID,
                }),
                description: createField<string>({
                    fieldName: fields.LOCATION_ENTITY_LINK_OTHER_PERSON_LOCATION_DESCRIPTION,
                }),
                startDateUtc: createField<string>({
                    fieldName: fields.LOCATION_ENTITY_LINK_OTHER_PERSON_LOCATION_START_DATE_UTC,
                }),
                endDateUtc: createField<string>({
                    fieldName: fields.LOCATION_ENTITY_LINK_OTHER_PERSON_LOCATION_END_DATE_UTC,
                }),
                additionalDates: createNItems({
                    fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_OTHER_ADDRESS_N_ITEMS_WRAPPER,
                    fields: {
                        startDateUtc: createField<string>({
                            fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_OTHER_ADDRESS_START_DATE_UTC,
                        }),
                        endDateUtc: createField<string>({
                            fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_OTHER_ADDRESS_END_DATE_UTC,
                        }),
                        description: createField<string>({
                            fieldName: fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_OTHER_ADDRESS_DESCRIPTION,
                        }),
                    },
                }),
            },
        }),
        [personProfilePaths.injuries]: createNItems({
            fieldName: fields.PERSON_INJURY_N_ITEMS_WRAPPER,
            fields: {
                description: createField<string>({
                    fieldName: fields.PERSON_INJURY_DESCRIPTION,
                }),
                injuryTypeAttrId: createField<number>({
                    fieldName: fields.PERSON_INJURY_INJURY_TYPE_ATTR_ID,
                }),
                bodyPartAttrId: createField<number>({
                    fieldName: fields.PERSON_INJURY_BODY_PART_ATTR_ID,
                }),
                weaponsUsedAttrId: createField<number>({
                    fieldName: fields.PERSON_INJURY_WEAPONS_USED_ATTR_ID,
                }),
                wasWeaponSeized: createField<boolean>({
                    fieldName: fields.PERSON_INJURY_WAS_WEAPON_SEIZED,
                }),
                injurySeverityAttrId: createField<boolean>({
                    fieldName: fields.PERSON_INJURY_INJURY_SEVERITY_ATTR_ID,
                }),
            },
        }),
        [personProfilePaths.showMedicalTreatment]: createField<boolean>({
            fieldName: fields.DISPLAY_ONLY_PERSON_PROFILE_SHOW_MEDICAL_TREATMENT,
        }),
        [personProfilePaths.medicalTreatment]: createFieldset({
            fieldName: fields.DISPLAY_ONLY_PERSON_PROFILE_MEDICAL_TREATMENT_FIELDSET_WRAPPER,
            fields: {
                medicalTreatmentAttrId: createField<number>({
                    fieldName: fields.PERSON_PROFILE_MEDICAL_TREATMENT_ATTR_ID,
                }),
                medicineTransported: createField<boolean>({
                    fieldName: fields.DISPLAY_ONLY_PERSON_PROFILE_MEDICINE_IS_TRANSPORTED,
                }),
                medicalTransportationTypeAttrId: createField<number>({
                    fieldName: fields.PERSON_PROFILE_MEDICAL_TRANSPORTATION_TYPE_ATTR_ID,
                }),
                ambulanceNumber: createField<string>({
                    fieldName: fields.PERSON_PROFILE_AMBULANCE_NUMBER,
                }),
                takenToHospitalBy: createField<string>({
                    fieldName: fields.PERSON_PROFILE_TAKEN_TO_HOSPITAL_BY,
                }),
                medicalFacilityAttrId: createField<number>({
                    fieldName: fields.PERSON_PROFILE_MEDICAL_FACILITY_ATTR_ID,
                }),
                hospitalTreatedAt: createField<string>({
                    fieldName: fields.PERSON_PROFILE_HOSPITAL_TREATED_AT,
                }),
                attendingPhysicians: createNItems({
                    fields: {
                        name: createField<string>({
                            fieldName: fields.PHYSICIAN_NAME,
                        }),
                    },
                }),
                medicalStatisticsAttrIds: createField<number[]>({
                    fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MEDICAL_STATISTICS_ATTRIBUTE_ID,
                }),
            },
        }),
        [personProfilePaths.attributes]: createFieldset({
            fields: [
                [
                    AttributeTypeEnum.PHYSICAL_CHARACTERISTIC.name,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_PHYSICAL_CHARACTERISTIC_ATTRIBUTE_ID,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_PHYSICAL_CHARACTERISTIC_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.BEHAVIORAL_CHARACTERISTIC.name,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CHARACTERISTIC_ATTRIBUTE_ID,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CHARACTERISTIC_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.MOOD.name,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MOOD_ATTRIBUTE_ID,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MOOD_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_ATTRIBUTE_ID,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.MODUS_OPERANDI.name,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MODUS_OPERANDI_ATTRIBUTE_ID,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MODUS_OPERANDI_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.PERSON_SKILL.name,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_PERSON_SKILL_ATTRIBUTE_ID,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_PERSON_SKILL_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.LANGUAGE.name,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_LANGUAGE_ATTRIBUTE_ID,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_LANGUAGE_DESCRIPTION,
                    fields.DISPLAY_ONLY_PERSON_PROFILE_LANGUAGE_WRAPPER,
                ],
                [
                    AttributeTypeEnum.SUBJECT_DATA_DISABILITY.name,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_DISABILITY_ATTRIBUTE_ID,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_DISABILITY_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.VICTIM_DISABILITY_TYPE.name,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_VICTIM_DISABILITY_TYPE_ATTRIBUTE_ID,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_VICTIM_DISABILITY_TYPE_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.QC_DRIVER_LICENSE_RESTRICTIONS.name,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_QC_DRIVER_LICENSE_RESTRICTIONS_ATTRIBUTE_ID,
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_QC_DRIVER_LICENSE_RESTRICTIONS_DESCRIPTION,
                ],
            ].reduce<Record<string, MFTFieldsetConfiguration>>(
                (acc, [attributeType, idsFieldName, descriptionFieldName, wrapperFieldName]) => {
                    const fieldsetConfig: MFTFieldsetConfigurationBase = {
                        fields: {
                            attributeIds: createField<number[]>({
                                fieldName: idsFieldName,
                            }),
                            description: createField<string>({
                                fieldName: descriptionFieldName,
                            }),
                        },
                    };
                    if (wrapperFieldName) {
                        fieldsetConfig.fieldName = wrapperFieldName;
                    }
                    acc[attributeType] = createFieldset(fieldsetConfig);
                    return acc;
                },
                {}
            ),
        }),
        [personProfilePaths.cautions]: createNItems({
            fields: {
                // not visible on UI but needed to differentiate creation from updates
                id: createField<number>({}),
                // not visible on UI but needed to prevent deletion of protected cautions
                isProtected: createField<boolean>({}),
                cautionAttrId: createField<number>({
                    fieldName:
                        fields.CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_CAUTION_ATTR_ID,
                }),
                cautionOther: createField<string>({
                    fieldName: fields.CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_CAUTION_OTHER,
                }),
                dateEffectiveFrom: createField<string>({
                    fieldName:
                        fields.CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_DATE_EFFECTIVE_FROM,
                }),
                dateEffectiveTo: createField<string>({
                    fieldName:
                        fields.CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_DATE_EFFECTIVE_TO,
                }),
                description: createField<string>({
                    fieldName: fields.CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_DESCRIPTION,
                }),
                provenance: createField<string>({
                    fieldName: fields.CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_PROVENANCE,
                }),
            },
        }),
    });
}

type PersonProfileFormConfiguration = ReturnType<typeof createPersonProfileFormConfiguration>;
export type PersonProfileFormDataShape = InferFormDataShape<PersonProfileFormConfiguration>;
