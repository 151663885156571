import { ChainEventCategoryEnum } from '@mark43/evidence-api';
import _, { get } from 'lodash';
import { createSelector } from 'reselect';
import { isExpired } from '../../../../dates/utils/dateHelpers';
import { chainEventTypesByCategorySelector, chainEventTypeByIdSelector } from '../data';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';

export const formatChainEventTypeByIdSelector = createSelector(
    chainEventTypeByIdSelector,
    (chainEventTypeById) => (eventTypeId) => {
        return !_.isArray(eventTypeId)
            ? get(chainEventTypeById(eventTypeId), 'name')
            : joinTruthyValues(
                  _(eventTypeId)
                      .map((eventTypeId) => get(chainEventTypeById(eventTypeId), 'name'))
                      .sortBy()
                      .value()
              );
    }
);

/**
 * Chain event type dropdown options in enum order, not sorted.
 * @type {(state: DefaultRootState) => {value: number, display: string, noteDisplay: string, isExpired: boolean}[]}
 */
export const chainEventTypeOptionsSelector = createSelector(
    chainEventTypesByCategorySelector,
    formatChainEventTypeByIdSelector,
    (chainEventTypesByCategory, formatChainEventTypeById) =>
        _(ChainEventCategoryEnum)
            .map('name')
            .map(chainEventTypesByCategory)
            .flatten()
            .map((chainEventType) => {
                const isChainEventExpired = isExpired(chainEventType.expiredDateUtc);
                return {
                    value: chainEventType.id,
                    display: formatChainEventTypeById(chainEventType.id),
                    noteDisplay: isChainEventExpired ? '(expired)' : '',
                    isExpired: isChainEventExpired,
                };
            })
            .sortBy('display')
            .value()
);
