import { NotificationStatusEnum, NotificationCategoryEnum } from '@mark43/notifications-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';
import styled from 'styled-components';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { acknowledgeUserNotification } from '~/client-common/core/domain/user-notifications/state/data';

import { FormattedDate } from '~/client-common/core/dates/components';
import Link from '../../../core/components/links/Link';
import Icon, { iconSizes, iconTypes } from '../../../core/components/Icon';
import _Checkbox from '../../../core/forms/components/checkboxes/Checkbox';

import testIds from '../../../../core/testIds';
import { routerLocationSelector } from '../../../../routing/routerModule';
import NotificationSummaryText from './NotificationSummaryText';

const { ACKNOWLEDGED, UNREAD, READ } = NotificationStatusEnum;

const horizontalSpacing = 10;
const iconWidth = 16;

const Checkbox = styled(_Checkbox)`
    margin-top: 8px;
    margin-left: ${horizontalSpacing}px;
`;

// icon that appears at the left side within a notification
function _NotificationIcon({ notificationCategory, className }) {
    let iconType;
    switch (notificationCategory) {
        case NotificationCategoryEnum.REPORTS.name:
            iconType = iconTypes.REPORT;
            break;
        case NotificationCategoryEnum.CASES.name:
            iconType = iconTypes.CASE;
            break;
        case NotificationCategoryEnum.EVIDENCE.name:
            iconType = iconTypes.PROPERTY;
            break;
        case NotificationCategoryEnum.TASKS.name:
            iconType = iconTypes.TASK;
            break;
        case NotificationCategoryEnum.SAVED_SEARCHES.name:
            iconType = iconTypes.BOOKMARK;
            break;
        case NotificationCategoryEnum.SUBSCRIPTIONS.name:
            iconType = iconTypes.BOOKMARK;
            break;
        case NotificationCategoryEnum.CAD.name:
            iconType = iconTypes.CAD;
            break;
        default:
            iconType = iconTypes.NOTIFICATION;
            break;
    }
    return <Icon color="cobaltBlue" type={iconType} size={iconSizes.SMALL} className={className} />;
}

const NotificationIcon = styled(_NotificationIcon)`
    margin-left: 2px;
    margin-right: ${horizontalSpacing}px;
    color: ${(props) => props.theme.colors.cobaltBlue};
`;

// container of all text within a notification
const NotificationText = styled(({ className, children }) => (
    <div className={className}>{children}</div>
))`
    display: inline-block;
    vertical-align: top;
    font-size: var(--arc-fontSizes-md);
    width: ${({ width }) => width}px;
    color: 'var(--arc-colors-text-primary)';
`;

const NotificationDate = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

function _NotificationSummary({
    userNotificationViewModel,
    baseOnClick,
    acknowledge,
    className,
    width,
    selectable,
    selected,
    onSelect,
    location,
    onClick,
}) {
    const { notificationCategory, routePath } = getViewModelProperties(userNotificationViewModel);

    const icon = <NotificationIcon notificationCategory={notificationCategory} />;

    const text = (
        <NotificationText width={width - iconWidth - 4 * horizontalSpacing - (selectable ? 50 : 0)}>
            <NotificationSummaryText userNotificationViewModel={userNotificationViewModel} />
            <NotificationDate>
                <FormattedDate
                    date={userNotificationViewModel.userNotificationCreatedDateUtc}
                    format={FormattedDate.FORMATS.FORM_DATE_TIME}
                />
            </NotificationDate>
        </NotificationText>
    );

    const checkbox = selectable ? (
        <Checkbox
            onChange={(newValue) => {
                onSelect(newValue);
            }}
            value={selected}
        />
    ) : null;

    if (!routePath || routePath === location.pathname) {
        // don't render as a link if the target url is the current route or is
        // somehow missing for this notification
        return (
            <div
                className={className}
                onClick={baseOnClick}
                data-test-id={testIds.NOTIFICATION_SUMMARY}
            >
                {checkbox}
                <NotificationSummaryNonLink>
                    {icon}
                    {text}
                </NotificationSummaryNonLink>
            </div>
        );
    }

    return (
        <div className={className} data-test-id={testIds.NOTIFICATION_SUMMARY}>
            {checkbox}
            <NotificationSummaryLink
                to={routePath}
                onClick={() => {
                    acknowledge();
                    onClick?.();
                }}
                selectable={selectable}
            >
                {icon}
                {text}
            </NotificationSummaryLink>
        </div>
    );
}

const NotificationSummaryNonLink = styled.div`
    padding: ${horizontalSpacing}px ${horizontalSpacing * 2}px ${horizontalSpacing}px
        ${horizontalSpacing}px;
    font-size: var(--arc-fontSizes-md);
`;

// here we write <Link> with explicit props to avoid passing down the
// `selectable` prop to the underlying <a>
const NotificationSummaryLink = styled(({ to, onClick, className, children }) => (
    <Link to={to} onClick={onClick} className={className}>
        {children}
    </Link>
))`
    display: block;
    margin-left: ${({ selectable }) => (selectable ? 40 : 0)}px;
    padding: ${horizontalSpacing}px ${horizontalSpacing * 2}px ${horizontalSpacing}px
        ${horizontalSpacing}px;
    color: ${(props) => props.theme.colors.darkGrey};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-md);
`;

function backgroundColor({ userNotificationViewModel: { notificationStatus } }) {
    return notificationStatus === ACKNOWLEDGED.name
        ? 'var(--arc-colors-surface-accent)'
        : 'var(--arc-colors-surface-foreground)';
}

function borderColor({ userNotificationViewModel: { notificationStatus } }) {
    return [UNREAD.name, READ.name].includes(notificationStatus)
        ? 'var(--arc-colors-brand-default)'
        : 'transparent';
}

function textColor({ userNotificationViewModel: { notificationStatus } }) {
    return notificationStatus === ACKNOWLEDGED.name
        ? 'var(--arc-colors-text-secondary)'
        : 'var(--arc-colors-text-primary)';
}

const NotificationSummary = styled(_NotificationSummary)`
    clear: both;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    border-left: 2px solid ${borderColor};
    width: ${({ width }) => width}px;
    background-color: ${backgroundColor};

    &,
    * {
        box-sizing: border-box;
    }

    ${NotificationText} {
        color: ${textColor};
    }

    &:hover {
        background-color: var(--arc-colors-interactive-hover);
    }

    &:last-child {
        border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    }
`;

const mapStateToProps = createStructuredSelector({
    location: routerLocationSelector,
});

const mapDispatchToProps = (dispatch) => ({
    acknowledgeUserNotification: (notification) =>
        dispatch(acknowledgeUserNotification(notification)),
});

/**
 * Summary row of a single UserNotification. Clicking this goes to the target
 *   url of the notification. This component is intended to be reused across
 *   different views, such as inside the notifications popover and inside the
 *   full notifications table.
 * @param {Object}   userNotificationViewModel
 * @param {number}   width
 * @param {function} [baseOnClick] Default handler for clicking on this summary
 *   row when the notification's target url either does not exist or is already
 *   the current route.
 * @param {boolean}  [selectable=false] Whether to render a checkbox at the
 *   left.
 * @param {boolean}  [selected=false]
 * @param {function} [onSelect]
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        acknowledge({ acknowledgeUserNotification, userNotificationViewModel }) {
            return () => acknowledgeUserNotification(userNotificationViewModel);
        },
    })
)(NotificationSummary);
