import { flowRight } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    REPORT_RECORD_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import Modal from '../../../../legacy-redux/components/core/Modal';
import { createModalSelector } from '../../../core/box/state/ui';
import { RestrictVisibilityWithDash } from '../../../core/components/RestrictVisibilityWithDash';
import { useIsReportOwnerRestricted } from '../../../core/hooks/useIsReportOwnerRestricted';

const context = {
    name: boxEnum.NARRATIVE_MODAL,
};
const strings = componentStrings.cases.unassignedReports.NarrativeModal;

/**
 * TODO: The dangerouslySetInnerHTML prop prepares for proper narrative
 *   formatting, see RMS-3267.
 *
 * TODO: The style of this modal needs to be updated to match the design.
 */
export default flowRight(
    connect(
        createStructuredSelector({
            reportingEventNumber: createModalSelector(context, 'reportingEventNumber'),
            currentSubmissionOwnerId: createModalSelector(context, 'currentSubmissionOwnerId'),
            eventStartUtc: createModalSelector(context, 'eventStartUtc'),
            narrative: createModalSelector(context, 'narrative'),
            recordNumber: createModalSelector(context, 'recordNumber'),
            permissionSet: createModalSelector(context, 'permissionSet'),
            restrictViewReportOwners: createModalSelector(context, 'restrictViewReportOwners'),
            formatFieldByName: formatFieldByNameSelector,
        })
    ),
    renderOnlyIf(({ narrative }) => !!narrative)
)(function NarrativeModal({
    reportingEventNumber,
    recordNumber,
    currentSubmissionOwnerId,
    eventStartUtc,
    narrative,
    permissionSet,
    restrictViewReportOwners,
    formatFieldByName,
}) {
    const checkIsReportOwnerRestricted = useIsReportOwnerRestricted();

    return (
        <Modal
            context={context}
            title={strings.title}
            okText={null}
            cancelText={strings.close}
            shouldCloseOnOverlayClick={true}
        >
            <div className="narrative-modal-reporting-event-number">
                {!!reportingEventNumber
                    ? `${formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)} ${reportingEventNumber}`
                    : `${formatFieldByName(REPORT_RECORD_NUMBER)} ${recordNumber}`}
            </div>
            <div className="narrative-modal-byline">
                <FeatureFlagged
                    flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                    fallback={currentSubmissionOwnerId}
                >
                    <RestrictVisibilityWithDash
                        restricted={checkIsReportOwnerRestricted(
                            restrictViewReportOwners,
                            permissionSet
                        )}
                    >
                        {currentSubmissionOwnerId}
                    </RestrictVisibilityWithDash>
                </FeatureFlagged>
                {eventStartUtc && ` on ${eventStartUtc}`}
            </div>
            <div
                className="narrative-modal-narrative"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: narrative }}
            />
        </Modal>
    );
});
