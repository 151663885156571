import React from 'react';
import { Observer, Fields } from 'markformythree';
import {
    reduce,
    values,
    some,
    every,
    isNil,
    map,
    compact,
    flatMap,
    size,
    isArray,
    isObject,
    pickBy,
} from 'lodash';
import { useSelector } from 'react-redux';
import { nonInternalStaticallyHiddenFieldConfigurationsInContextSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';

const hasValue = (val) => {
    if (isArray(val)) {
        return !!size(val);
    } else if (isObject(val)) {
        return !!size(pickBy(val, (val) => !isNil(val)));
    } else {
        return !isNil(val);
    }
};

const someFormPathValuesAreDefined = (formPathValues) =>
    some(map(values(formPathValues), hasValue));

const someFormPathValuesAreTruthy = (formPathValues) => some(values(formPathValues));
const someFormPathErrorsAreTruthy = (formPathValues) =>
    some(compact(flatMap(values(formPathValues), values)));
const ObserverWrapper = ({ formPathsToObserve, children, fields, formPathValuesProcessor }) => (
    <Observer
        subscriptions={reduce(
            formPathsToObserve,
            (acc, formPath) => {
                acc[formPath] = fields ? [formPath, fields] : formPath;
                return acc;
            },
            {}
        )}
        render={(formPathValues) =>
            children(
                formPathValuesProcessor ? formPathValuesProcessor(formPathValues) : formPathValues
            )
        }
    />
);

export const SomeFieldsHaveValue = ({ formPathsToObserve, children }) => (
    <ObserverWrapper
        formPathValuesProcessor={someFormPathValuesAreDefined}
        formPathsToObserve={formPathsToObserve}
        children={children}
    />
);

export const SomeFieldsHaveTruthyValue = ({ formPathsToObserve, children }) => (
    <ObserverWrapper
        formPathValuesProcessor={someFormPathValuesAreTruthy}
        formPathsToObserve={formPathsToObserve}
        children={children}
    />
);

export const AllFieldsAreHidden = ({ pathConfig, formName, children }) => {
    const staticallyHiddenFields = map(
        useSelector(nonInternalStaticallyHiddenFieldConfigurationsInContextSelector)(formName),
        'fieldName'
    );

    const paths = map(pathConfig, 'path');
    const pathToFieldNameMap = pathConfig.reduce((acc, item) => {
        acc[item.path] = item.fieldName;
        return acc;
    }, {});

    const allFieldsAreHidden = (children) => (fieldDynamicHiddenMap) => {
        const allFieldsHidden = every(paths, (path) => {
            const fieldName = pathToFieldNameMap[path];
            return fieldDynamicHiddenMap[path] || staticallyHiddenFields.includes(fieldName);
        });
        return children(allFieldsHidden);
    };

    return (
        <ObserverWrapper
            formPathsToObserve={paths}
            fields={Fields.HIDDEN}
            children={allFieldsAreHidden(children)}
        />
    );
};

export const SomeFieldsHaveError = ({ formPathsToObserve, children }) => (
    <ObserverWrapper
        formPathValuesProcessor={someFormPathErrorsAreTruthy}
        formPathsToObserve={formPathsToObserve}
        fields={Fields.ERRORS}
        children={children}
    />
);
