import { useEffect, useState } from 'react';
import { FeatureLayerT, WebMapT } from '../types';
import { nearestLocationPin } from '../images/nearestLocationPin';
import { SPATIAL_REFERENCE } from '../constants';
import { FeatureLayer } from '../constructors';

type NearestLocationFeatureLayerProps = {
    webmap?: WebMapT;
};

export const useNearestLocationFeatureLayer = ({ webmap }: NearestLocationFeatureLayerProps) => {
    const [nearestLocationLayer, setNearestLocationLayer] = useState<FeatureLayerT>();

    // handles initializing empty feature layer
    useEffect(() => {
        // need modules to load
        if (webmap) {
            const nearestLocationFeatureLayer = createNearestLocationLayer();
            setNearestLocationLayer(nearestLocationFeatureLayer);
            webmap.layers.add(nearestLocationFeatureLayer);
        }
    }, [webmap]);

    return {
        layer: nearestLocationLayer,
    };
};

const addressIconSvgStringEncoded = btoa(nearestLocationPin);

const nearestLocationIconRenderer = {
    type: 'unique-value', // autocasts as new UniqueValueRenderer()
    field: ' ',
    defaultSymbol: {
        // used when item field dont match anything defined in uniqueValueInfos
        type: 'picture-marker',
        url: `data:image/svg+xml;base64,${addressIconSvgStringEncoded}`,
        width: '22px',
        height: '32px',
    },
    authoringInfo: undefined,
};

const createNearestLocationLayer = () => {
    const nearestLocationFeatureLayer = new FeatureLayer({
        title: 'Nearest Location',
        source: [], // initialize with an empty collection
        renderer: nearestLocationIconRenderer,
        objectIdField: 'objectId', // This must be defined when creating a layer from `Graphic` objects
        outFields: ['*'],
        geometryType: 'point',
        spatialReference: SPATIAL_REFERENCE,
    });

    return nearestLocationFeatureLayer;
};
