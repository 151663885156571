import React from 'react';
import { values } from 'lodash';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { EntityTypeEnum } from '@mark43/rms-api';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';

import LinkedReports from '../../../records/core/components/sidebar/LinkedReports';
import LinkedTasksSection from '../../../records/core/components/sidebar/LinkedTasksSection';
import RecordsSidebar from '../../../records/core/components/sidebar/RecordsSidebar';
import RecordSidebarSection from '../../../records/core/components/sidebar/RecordSidebarSection';
import { warrantLinkedReportShortTitleViewModelsSelector } from '../state/ui';
import WarrantActivities from './WarrantActivities';
import WarrantDexSidebarSection from './WarrantDexSidebarSection';

const strings = componentStrings.warrants.warrant.WarrantSidebar;

// Overriding existing CSS
const StyledRecordsSidebar = styled(RecordsSidebar)`
    position: sticky;
    top: 0;

    .report-sidebar {
        display: flex;
    }

    && .report-sidebar-content {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        width: 100%;
    }
`;

const NoReportsText = styled.div`
    padding: 10px 0;
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: var(--arc-fontSizes-md);
    color: ${(props) => props.theme.colors.darkGrey};
`;

const WarrantSidebar = ({ warrantId }: { warrantId: number }) => {
    const linkedReportShortTitleViewModels = useSelector(
        warrantLinkedReportShortTitleViewModelsSelector
    );

    const relatedReportsSection =
        values(linkedReportShortTitleViewModels).length === 0 ? (
            <NoReportsText>{strings.noReportsForRen}</NoReportsText>
        ) : (
            <div>
                <LinkedReports reportShortTitleViewModels={linkedReportShortTitleViewModels} />
            </div>
        );
    return (
        <StyledRecordsSidebar>
            <FeatureFlagged flag="RMS_WARRANTS_DATA_EXCHANGE_ENABLED">
                <OnlyWithAbility has={abilitiesEnum.WARRANTS.RMS_WARRANTS_DEX}>
                    <WarrantDexSidebarSection warrantId={warrantId} />
                </OnlyWithAbility>
            </FeatureFlagged>
            <WarrantActivities warrantId={warrantId} />
            <RecordSidebarSection title={strings.title}>
                {relatedReportsSection}
            </RecordSidebarSection>
            <FeatureFlagged flag="RMS_TASK_ENTITY_LINKS_ENABLED">
                <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_NON_CASE_TASKS}>
                    {warrantId && (
                        <LinkedTasksSection
                            entityType={EntityTypeEnum.WARRANT.name}
                            entityId={warrantId}
                        />
                    )}
                </OnlyWithAbility>
            </FeatureFlagged>
        </StyledRecordsSidebar>
    );
};

export default WarrantSidebar;
