/**
 * Import types for JSDoc
 *
 * @typedef {import('@mark43/rms-api').SealingRequest} SealingRequest
 * @typedef {import('@mark43/rms-api').SealingResponse} SealingResponse
 * @typedef {import('@mark43/rms-api').UnsealingRequest} UnsealingRequest
 * @typedef {import('@mark43/rms-api').CompleteClientReportView} CompleteClientReportView
 * @typedef {import('@mark43/rms-api').ReportRelatedEntities} ReportRelatedEntities
 * @typedef {import('@mark43/rms-api').SealingTermsRequest} SealingTermsRequest
 * @typedef {import('@mark43/rms-api').CourtOrderSealedTerm} CourtOrderSealedTerm
 * @typedef {import('@mark43/rms-api').SealingPartialNarrativeRequest} SealingPartialNarrativeRequest
 * @typedef {import('@mark43/rms-api').UnsealingTermsRequest} UnsealingTermsRequest
 * @typedef {import('@mark43/rms-api').UnsealingPartialNarrativeRequest} UnsealingPartialNarrativeRequest
 */

let resource;
export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Sealing Resource',
        methods: {
            /**
             * Seal report
             * @param {SealingRequest} sealingRequest
             * @returns {Promise<SealingResponse>}
             */
            sealReport(sealingRequest) {
                return req({
                    method: 'POST',
                    url: 'reporting/sealing/seal',
                    data: sealingRequest,
                });
            },
            /**
             * Unseal report
             * @param {UnsealingRequest} unsealingRequest
             * @returns {Promise<SealingResponse>}
             */
            unsealReport(unsealingRequest) {
                return req({
                    method: 'POST',
                    url: 'reporting/sealing/unseal',
                    data: unsealingRequest,
                });
            },
            /**
             * Update sealed report
             * @param {SealingRequest} sealingRequest
             * @returns {Promise<SealingResponse>}
             */
            updateSealedReport(sealingRequest) {
                return req({
                    method: 'PUT',
                    url: 'reporting/sealing/seal',
                    data: sealingRequest,
                });
            },
            /**
             * Fetch report
             * @param {number} reportId
             * @returns {Promise<CompleteClientReportView>}
             */
            loadSealedReportView(reportId) {
                return req({
                    method: 'GET',
                    url: `reporting/sealing/report/${reportId}`,
                });
            },
            /**
             * Load related entities for sealed report
             * @param {number} reportId
             * @returns {Promise<ReportRelatedEntities>}
             */
            loadSealedReportRelatedEntities(reportId) {
                return req({
                    method: 'GET',
                    url: `reporting/sealing/report/${reportId}/related_entities`,
                });
            },
            /**
             * Fetch court orders and attachments by report id
             * @param {number} reportId
             * @returns {Promise<SealingResponse>}
             */
            loadCourtOrders(reportId) {
                return req({
                    method: 'GET',
                    url: `reporting/sealing/court_orders/report/${reportId}`,
                });
            },
            /**
             * Sends list of words to be partially sealed from narrative,
             * and receives back a list of ids corresponding to each word
             *
             * @param {SealingTermsRequest} sealTerms
             * @returns {Promise<CourtOrderSealedTerm[]>}
             */
            sealNarrativeWords(sealTerms) {
                return req({
                    method: 'POST',
                    url: `reporting/sealing/seal/narrative/terms`,
                    data: sealTerms,
                });
            },
            /**
             * Sends modified narrative to be appended to report
             *
             * @param {SealingPartialNarrativeRequest} partialSealReq
             * @returns {Promise<Boolean>}
             */
            partiallySealNarrative(partialSealReq) {
                return req({
                    method: 'POST',
                    url: `reporting/sealing/seal/narrative`,
                    data: partialSealReq,
                });
            },
            /**
             * Fetch words to be unsealed from narrative
             *
             * @param {UnsealingTermsRequest} reqData
             * @returns {Promise<CourtOrderSealedTerm[]>}
             */
            loadCourtOrderTermsToUnseal(reqData) {
                return req({
                    method: 'POST',
                    url: `reporting/sealing/unseal/narrative/terms`,
                    data: reqData,
                });
            },
            /**
             * Update report narrative with unsealed terms
             *
             * @param {UnsealingPartialNarrativeRequest} reqData
             * @returns {Promise<boolean>}
             */
            partiallyUnsealNarrative(reqData) {
                return req({
                    method: 'POST',
                    url: `reporting/sealing/unseal/narrative`,
                    data: reqData,
                });
            },
        },
    });
}

/**
 * Resource for sealing. We need to build on app boot and use the getter
 * below to prevent a cirular dependency issue
 * @type {function}
 */
/* eslint-disable-next-line import/no-anonymous-default-export */
export default () => resource;
