import { DiagramCategoryEnumType, DiagramTypeEnumType } from '@mark43/rms-api';
import { Widget } from '../../../types';
import { Asset } from '../../../../../../../../diagram/types';
import { createDiagramAssetsMapInitialState } from '../../../helpers';

type CategoryAssetsState = {
    assets: Asset[];
    isFetched: boolean;
};

export type DiagramAssetsMap = Record<
    DiagramTypeEnumType,
    Record<DiagramCategoryEnumType, CategoryAssetsState>
>;

export type CrashDiagramState = {
    widgets: Widget[];
    selectedWidget?: Widget;
    backgroundImage?: string;
    assets: DiagramAssetsMap;
};

export const initialState: CrashDiagramState = {
    widgets: [],
    selectedWidget: undefined,
    backgroundImage: undefined,
    assets: createDiagramAssetsMapInitialState(),
};
