import { EntityTypeEnum, EntityTypeEnumType } from '@mark43/rms-api';
import { useCallback, useMemo, useContext, useEffect } from 'react';
import {
    QueryableEntityType,
    FederatedSearchExternalReadDataContext,
    FederatedSearchExternalWriteDataContext,
} from 'mark43-federated-search';
import { useDispatch } from 'react-redux';
import { merge } from 'lodash';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { itemProfileIsVehicle } from '~/client-common/core/domain/item-profiles/utils/itemProfileHelpers';
import { SCREENS } from '../../../../core/locations/state/ui';
import { useOverlayStore } from '../../../../core/overlays/hooks/useOverlayStore';
import {
    mapDexArticlesToRmsProperties,
    mapDexFirearmsToRmsFirearms,
    mapDexItemsToRmsVehicles,
} from '../../../../reports/core/components/items/helpers';
import {
    generateSidePanelFormDataAsObjFromRmsFirearm,
    generateSidePanelFormDataAsObjFromRmsOtherItem,
    convertToFormModelFromRmsVehicle,
    openItemSidePanel,
} from '../../../../reports/core/state/ui';
import { RmsDispatch } from 'src/scripts/core/typings/redux';

export const useEntityProfileDexConfiguration = ({
    entityId,
    itemType,
    entityType,
    isExternalProfile,
    isEditable,
    editProfileSidePanel,
}: {
    entityId: number;
    itemType?: number;
    entityType: EntityTypeEnumType;
    isExternalProfile?: boolean;
    isEditable?: boolean;
    editProfileSidePanel?: () => void;
}) => {
    const dispatch: RmsDispatch = useDispatch();
    const { resultForConsumerApp } = useContext(FederatedSearchExternalReadDataContext) || {};
    const { resetResultForConsumerApp } = useContext(FederatedSearchExternalWriteDataContext) || {};
    const overlayStore = useOverlayStore();

    const addDexPersonToProfile = useCallback(
        ({ result }) => {
            const dexPerson = result.PERSON[0];
            if (!dexPerson) {
                return;
            }
            overlayStore.close(overlayIdEnum.DEX_SIDE_PANEL);
            overlayStore.open(overlayIdEnum.PERSON_OVERLAY_ENTITY_PROFILE_PERSON_HEADER, {
                ownerId: entityId,
                onCloseAction: resetResultForConsumerApp,
                screenStack: [
                    {
                        screen: SCREENS.PROFILE_EDIT,
                        screenState: {
                            currentScreen: SCREENS.PROFILE_EDIT,
                            dexPerson,
                        },
                    },
                ],
            });
        },
        [entityId, overlayStore, resetResultForConsumerApp]
    );

    const addDexItemToProfile = useCallback(
        async ({ result }) => {
            const dexArticles = result.ARTICLE;
            const dexFirearms = result.FIREARM;

            let formData: Record<string, unknown>;
            if (dexArticles) {
                const rmsProperties = await dispatch(mapDexArticlesToRmsProperties(dexArticles));
                formData = generateSidePanelFormDataAsObjFromRmsOtherItem(rmsProperties[0]);
            } else {
                const rmsFirearms = await dispatch(mapDexFirearmsToRmsFirearms(dexFirearms));
                formData = generateSidePanelFormDataAsObjFromRmsFirearm(rmsFirearms[0]);
            }
            overlayStore.close(overlayIdEnum.DEX_SIDE_PANEL);
            dispatch(
                openItemSidePanel({
                    operation: itemSidePanelOperationEnum.EDIT,
                    ownerType: EntityTypeEnum.ITEM_PROFILE.name,
                    itemTypeAttrId: globalAttributes.itemType.item,
                    itemProfileId: entityId,
                    isVehicle: false,
                    alwaysHideItemTypeNavigation: true,
                    alwaysHideDexItemsQuerySearch: true,
                    alwaysHideItemsInlineSearch: true,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge({}, formData, formModel),
                })
            );
        },
        [dispatch, entityId, overlayStore]
    );

    const addDexVehicleToProfile = useCallback(
        async ({ result }) => {
            const dexVehicles = result.VEHICLE;

            const rmsVehicle = await dispatch(mapDexItemsToRmsVehicles(dexVehicles));
            const formData = convertToFormModelFromRmsVehicle(rmsVehicle[0]);
            overlayStore.close(overlayIdEnum.DEX_SIDE_PANEL);

            dispatch(
                openItemSidePanel({
                    operation: itemSidePanelOperationEnum.EDIT,
                    ownerType: EntityTypeEnum.ITEM_PROFILE.name,
                    itemProfileId: entityId,
                    isVehicle: true,
                    alwaysHideItemsInlineSearch: true,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge({}, formData, formModel),
                })
            );
        },
        [dispatch, entityId, overlayStore]
    );

    useEffect(() => {
        if (resultForConsumerApp?.PERSON?.[0]) {
            addDexPersonToProfile({ result: resultForConsumerApp });
        }
    }, [addDexPersonToProfile, resultForConsumerApp]);

    const isPersonProfile = entityType === EntityTypeEnum.PERSON_PROFILE.name;
    const isVehicleProfile = itemProfileIsVehicle({ itemTypeAttrId: itemType });
    const isItemProfile = itemType === globalAttributes.itemType.item;

    const showSearchFromDex =
        !isExternalProfile &&
        isEditable &&
        !!editProfileSidePanel &&
        (isPersonProfile || isVehicleProfile || isItemProfile);

    const federatedSearchProfileButtonConfiguration = useMemo(() => {
        if (isVehicleProfile) {
            return {
                addToProfile: addDexVehicleToProfile,
                entityTypes: [QueryableEntityType.BOAT, QueryableEntityType.VEHICLE],
            };
        }
        if (isPersonProfile) {
            return {
                addToProfile: addDexPersonToProfile,
                entityTypes: [QueryableEntityType.PERSON],
            };
        }
        if (isItemProfile) {
            return {
                addToProfile: addDexItemToProfile,
                entityTypes: [QueryableEntityType.FIREARM, QueryableEntityType.ARTICLE],
            };
        }
        return null;
    }, [
        addDexItemToProfile,
        addDexPersonToProfile,
        addDexVehicleToProfile,
        isItemProfile,
        isPersonProfile,
        isVehicleProfile,
    ]);

    return {
        federatedSearchButtonConfiguration: { profile: federatedSearchProfileButtonConfiguration },
        showSearchFromDex,
    };
};
