import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    Checkbox,
    Flex,
    Divider,
    FilterMenuList,
    FilterMenuRenderProps,
    FilterSearchInput,
} from 'arc';
import {
    DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_STATUSES_LABEL,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { chainEventTypeOptionsSelector } from '~/client-common/core/domain/chain-event-types/state/ui';

const StyledFilterMenuList = styled(FilterMenuList)`
    max-width: 25rem;
`;

export const ChainOfCustodyFilter: React.FC<FilterMenuRenderProps> = ({
    setAppliedFilters,
    ...props
}) => {
    const [query, setQuery] = useState('');
    const [excludeExpired, setExcludeExpired] = React.useState(false);

    const options = useSelector(chainEventTypeOptionsSelector);

    const fields = useFields([
        DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_STATUSES_LABEL,
    ]);

    const filteredOptions = excludeExpired ? options.filter((item) => !item.isExpired) : options;

    const chainOfCustodyOptions = filteredOptions.map((item) => {
        return {
            label: `${item.display}${item.isExpired ? ` ${item.noteDisplay}` : ''}`,
            value: String(item.value),
            id: Number(item.value),
        };
    });

    return (
        <>
            <FilterSearchInput onChange={(e) => setQuery(e.target.value)} />
            <StyledFilterMenuList
                query={query}
                options={chainOfCustodyOptions}
                onSelect={setAppliedFilters}
                {...props}
            />
            <Divider />
            <Flex padding="var(--arc-space-3)">
                <Checkbox
                    size="md"
                    isChecked={excludeExpired}
                    onChange={() => setExcludeExpired((prev) => !prev)}
                >
                    {fields.DISPLAY_ONLY_EVIDENCE_SEARCH_EXCLUDE_EXPIRED_STATUSES_LABEL}
                </Checkbox>
            </Flex>
        </>
    );
};
