import React from 'react';
import { RefContextEnum } from '@mark43/rms-api';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../core/overlays/components/Modal';
import { OverlayBaseHelper } from '../../../../core/components/OverlayBaseHelper';
import { useEFileDisplayName } from '../../../hooks';
import { ActionType } from '../table/IceCreamMenu';
import { useFormGetter } from '../../../../core/forms/hooks/useFormGetter';
import { EFileProfileRemoveDeactivateForm } from './EFileProfileRemoveDeactivateForm';

interface EFileProfileRemoveDeactivateModalProps {
    onSave?: () => void;
    title: ActionType;
}

const strings = componentStrings.eFiles.profiles.removeDeactivateModal;

export const EFileProfileRemoveDeactivateModal = () => {
    const { eFileDisplayName } = useEFileDisplayName();
    const { getForm } = useFormGetter();
    const form = getForm(RefContextEnum.FORM_E_FILE_REMOVE_DEACTIVATE_ENTITY.name);
    const onModalCancel = () => {
        if (!form) {
            return;
        }
        form.resetModel();
    }

    return (
        <OverlayBaseHelper<EFileProfileRemoveDeactivateModalProps> id={overlayIdEnum.E_FILE_PROFILE_REMOVE_DEACTIVATE_MODAL}>
            {(renderProps) => {
                const { onSave, title } = renderProps.overlayBase.overlayState.customProperties;
                return (
                    <Modal
                        id={overlayIdEnum.E_FILE_PROFILE_REMOVE_DEACTIVATE_MODAL}
                        title={strings.title(title, eFileDisplayName)}
                        okText={strings.title(title, eFileDisplayName)}
                        onSave={onSave}
                        onCancel={onModalCancel}
                    >
                        <EFileProfileRemoveDeactivateForm />
                    </Modal>
                );
            }}
        </OverlayBaseHelper>
    )
}
