import React from 'react';
import { map } from 'lodash';
import { InvolvementStatusEnum } from '@mark43/rms-api';
import { simpleControl, SimpleControlInjectedProps } from 'markformythree';
import { prettify } from '~/client-common/helpers/stringHelpers';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { SelectOption } from '../../helpers/selectHelpers';
import Select, { SelectAndFormElementProps as _SelectAndFormElementProps } from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

type PickedSelectProps = Pick<
    _SelectAndFormElementProps<SelectOption, false>,
    'menuPortalTarget' | 'disabled' | 'length'
>;

type PersonnelInvolvementStatusSelectProps = PickedSelectProps & {
    label: string;
    path: string;
    onChange: (value: string) => void;
};

const PersonnelInvolvementStatusSelect = (props: PersonnelInvolvementStatusSelectProps) => {
    const options = map(InvolvementStatusEnum, (status) => ({
        display: prettify(status.name),
        value: status.name,
    }));
    return <Select {...props} options={options} />;
};

export const RRFPersonnelInvolvementStatusSelect = connectRRFInput(
    // @ts-expect-error client-common to client migration
    PersonnelInvolvementStatusSelect
);

type MFTPersonnelInvolvementStatusSelectProps = PersonnelInvolvementStatusSelectProps &
    SimpleControlInjectedProps<string>;

export const MFTPersonnelInvolvementStatusSelect = simpleControl<MFTPersonnelInvolvementStatusSelectProps>(
    PersonnelInvolvementStatusSelect
);
