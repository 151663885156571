import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
import _, { get } from 'lodash';
import { withHandlers } from 'recompose';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';
import { reduxForm } from 'redux-form-mark43';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { compose } from 'redux';

import {
    AttributeTypeEnum,
    ComplianceGroupEnum,
    EntityTypeEnum,
    LinkTypesEnum,
    UserGroupEnum,
} from '@mark43/rms-api';
import { HStack } from 'arc';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fieldNames from '~/client-common/core/enums/universal/fields';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { replaceLocationLinksForEntity } from '~/client-common/core/domain/location-entity-links/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import withFields from '~/client-common/core/fields/components/withFields';
import { getDropdownOptionsFromEnum } from '~/client-common/helpers/dropdownOptionHelpers';

import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import oriAliasResource from '~/client-common/core/domain/dex-devices/resources/oriAliasResource';
import InlineAttachmentsUploader from '../../../../modules/attachments/core/components/InlineAttachmentsUploader';
import { LocationSidePanel } from '../../../../modules/core/locations/components/LocationSidePanel';
import {
    fullPanelFormWidth,
    halfPanelFormWidth,
    smallPanelFormWidth,
    userProfileFormFieldList,
    userProfileFormLabelsFieldNames,
} from '../../../configs/adminConfig';
import HelpText from '../../core/HelpText';
import DutyStatusSelect from '../../../../modules/core/forms/components/selects/DutyStatusSelect';
import AgencyProfileSelect from '../../../../modules/core/forms/components/selects/AgencyProfileSelect';

import {
    cancelUserProfilePanel,
    fileUploadFailure,
    fileUploadStart,
    fileUploadSuccess,
    resetUserProfileForm,
} from '../../../actions/userProfileAdminActions';
import { saveBoxHalt } from '../../../actions/boxActions';
import {
    clearUploads,
    saveInlineAttachments,
    updateUploadingFiles,
    uploadingFilesSelector,
} from '../../../../modules/attachments/core/state/ui/inlineAttachmentsUploader';
import { isProcessingUploadingAttachmentsSelector } from '../../../../modules/attachments/core/state/ui/sharedAttachmentsSelectors';
import {
    uiSelector,
    userProfileAdminPermissionSelector,
    userProfileAdminProfileSelector,
} from '../../../selectors/userProfileAdminSelectors';
import { createModalSelector } from '../../../../modules/core/box/state/ui';
import Text from '../../../../modules/core/forms/components/Text';
import SidePanel from '../../core/SidePanel';
import PhoneNumberInput from '../../../../modules/core/forms/components/PhoneNumberInput';
import Select from '../../../../modules/core/forms/components/selects/Select';
import BooleanSelect from '../../../../modules/core/forms/components/selects/BooleanSelect';
import IndentedFields from '../../../../modules/core/components/IndentedFields';
import Row from '../../../../modules/core/components/Row';
import NItems from '../../../../modules/core/forms/components/NItems';
import _LocationSummary from '../../summaries/LocationSummary';
import { iconTypes } from '../../../../modules/core/components/Icon';
import DatePicker from '../../../../modules/core/forms/components/DatePicker';
import Checkbox from '../../../../modules/core/forms/components/checkboxes/Checkbox';
import Button, { buttonTypes } from '../../core/Button';
import AttributeSelect from '../../../../modules/core/forms/components/selects/AttributeSelect';
import _AdminSummarySectionHeader from '../../../../modules/admin/core/components/AdminSummarySectionHeader';
import { abilitiesEnum, OnlyWithAbility } from '../../../../modules/core/abilities';
import {
    currentUserDepartmentIdSelector,
    currentUserHasAbilitySelector,
} from '../../../../modules/core/current-user/state/ui';
import WithComplianceGroup from '../../../../modules/core/components/WithComplianceGroup';
import UserSelect from '../../../../modules/core/forms/components/selects/UserSelect';
import { Tooltip } from '../../../../modules/core/components/tooltip';
import Radio from '../../../../modules/core/forms/components/Radio';

import UserProfileAdminPhotoUpload from './UserProfileAdminPhotoUpload';
import UserProfileAdminSignatureUpload from './UserProfileAdminSignatureUpload';
import { getUserProfileFormValidation } from './form-validation';

const sectionHeaders = componentStrings.admin.userProfile.sectionHeaders;
const { fieldHelpTexts, fieldLabels } = componentStrings.admin.userProfile;

const context = {
    name: boxEnum.USER_PROFILE_ADMIN_SIDE_PANEL,
};

const accountStatusOptions = [
    {
        display: fieldLabels.active,
        value: false,
    },
    {
        display: fieldLabels.inactive,
        value: true,
    },
];

const primaryUserGroupOptions = getDropdownOptionsFromEnum(UserGroupEnum, 'name', true);

const AdminSummarySectionHeader = styled(_AdminSummarySectionHeader)`
    margin-bottom: 10px;
`;

const FlexRow = styled(Row)`
    display: flex;
    align-items: center;
`;
const FormFieldsWrapper = styled.div`
    margin-bottom: 5px;
`;

function UserProfileFormSection({ title, children }) {
    return (
        <div>
            <AdminSummarySectionHeader>{title}</AdminSummarySectionHeader>
            <FormFieldsWrapper>{children}</FormFieldsWrapper>
        </div>
    );
}

function UserAccountFields({ isEditingSidepanel, userPermissions, fields, fieldDisplayNames }) {
    return (
        <UserProfileFormSection title={sectionHeaders.userAccount}>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_ACCOUNT_PRIMARY_EMAIL}
                    disabled={
                        isEditingSidepanel
                            ? !userPermissions.canEditAccount
                            : !userPermissions.canEditProfile
                    }
                    width={halfPanelFormWidth}
                    {...fields.primaryEmail}
                />
            </Row>
            <Row>
                <Tooltip content={fieldHelpTexts.isSsoUser} side="top">
                    <div>
                        <Checkbox
                            label={fieldDisplayNames.DISPLAY_ONLY_USER_PROFILE_IS_SSO_LABEL}
                            disabled={!userPermissions.canEditAccount}
                            {...fields.isSsoUser}
                        />
                    </div>
                </Tooltip>
            </Row>
            {userPermissions.canEditStatus && (
                <Row>
                    <Select
                        options={accountStatusOptions}
                        label={fieldDisplayNames.USER_IS_DISABLED}
                        width={halfPanelFormWidth}
                        {...fields.isDisabled}
                    />
                </Row>
            )}
            <FlexRow>
                <Select
                    options={primaryUserGroupOptions}
                    label={fieldDisplayNames.USER_PROFILE_PRIMARY_USER_GROUP}
                    width={halfPanelFormWidth}
                    disabled={!userPermissions.canEditProfile}
                    {...fields.primaryUserGroup}
                />
                <HelpText content={fieldHelpTexts.userProfilePrimaryUserGroup} />
            </FlexRow>
        </UserProfileFormSection>
    );
}

function DexStateAuthenticationFields({ fields, fieldDisplayNames, applicationSettings }) {
    const isMultipleOriEnabled = applicationSettings.DEX_MULTIPLE_ORI_ENABLED;
    const [oriAliasOptions, setOptions] = useState([]);

    const onLoadOriAliasOptions = useCallback((result) => {
        setOptions(
            result.oriAliases.map((alias) => ({
                label: alias.oriAlias,
                value: alias.id,
            }))
        );
    }, []);

    const { callResource } = useResourceDeferred(
        oriAliasResource.getOriAliasOptions,
        onLoadOriAliasOptions
    );

    useEffect(() => {
        if (isMultipleOriEnabled) {
            callResource();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMultipleOriEnabled]);

    const disableCertificationField = isMultipleOriEnabled
        ? !fields?.dexStateUserIdOriAliasAssociations?.some(
              (alias) => !!alias.dexUserIdValue.value && !!alias.oriAliasId.value
          )
        : !fields.dexStateUserId.value;

    return (
        <UserProfileFormSection
            title={fieldDisplayNames.DISPLAY_ONLY_USER_PROFILE_STATE_AUTHENTICATION_LABEL}
        >
            {isMultipleOriEnabled ? (
                <NItems
                    items={fields.dexStateUserIdOriAliasAssociations}
                    addText={'Add State User ID'}
                >
                    {(dexStateUserIdOriAliasAssociation) => (
                        <Row>
                            <Text
                                label={fieldDisplayNames.USER_PROFILE_DEX_STATE_USER_ID}
                                width={smallPanelFormWidth}
                                {...dexStateUserIdOriAliasAssociation.dexUserIdValue}
                            />
                            <Select
                                options={oriAliasOptions}
                                label={fieldDisplayNames.USER_PROFILE_DEX_ORI_ALIAS}
                                width={smallPanelFormWidth}
                                {...dexStateUserIdOriAliasAssociation.oriAliasId}
                            />
                        </Row>
                    )}
                </NItems>
            ) : (
                <Row>
                    <Text
                        label={fieldDisplayNames.USER_PROFILE_DEX_STATE_USER_ID}
                        width={halfPanelFormWidth}
                        {...fields.dexStateUserId}
                    />
                </Row>
            )}
            <Row>
                <DatePicker
                    label={fieldDisplayNames.USER_PROFILE_DATE_DEX_CERTIFICATION_EXPIRES}
                    disabled={disableCertificationField}
                    {...fields.dateDexCertificationExpires}
                    variant={DatePicker.variants.LOCAL_DATE}
                    width={fullPanelFormWidth}
                />
            </Row>
        </UserProfileFormSection>
    );
}

/**
 * Special section for California RIPA.
 * These field labels come from the Stop form because those are the fields that get prefilled with these values.
 */
function CaliforniaStopFields(props) {
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const attributeIsOther = useSelector(attributeIsOtherSelector);
    const { fields } = props;

    return (
        <UserProfileFormSection
            title={
                <>
                    {sectionHeaders.californiaStop}{' '}
                    <HelpText content={sectionHeaders.californiaStopHelpText} />
                </>
            }
        >
            <Row>
                <Text
                    label={formatFieldByName(fieldNames.STOP_USER_YEARS_OF_EXPERIENCE)}
                    width={halfPanelFormWidth}
                    isRequired={true}
                    {...fields.yearsOfExperience}
                />
            </Row>
            <Row>
                <AttributeSelect
                    attributeType={AttributeTypeEnum.STOP_USER_ASSIGNMENT_TYPE.name}
                    label={formatFieldByName(fieldNames.STOP_USER_ASSIGNMENT_TYPE_ATTR_ID)}
                    width={fullPanelFormWidth}
                    {...fields.stopUserAssignmentTypeAttrId}
                />
            </Row>
            {attributeIsOther(get(fields.stopUserAssignmentTypeAttrId, 'value')) ? (
                <IndentedFields>
                    <Row>
                        <Text
                            label={formatFieldByName(fieldNames.STOP_USER_ASSIGNMENT_TYPE_OTHER)}
                            width={halfPanelFormWidth}
                            {...fields.stopUserAssignmentTypeOther}
                        />
                    </Row>
                </IndentedFields>
            ) : undefined}
            <Row>
                <AttributeSelect
                    attributeType="STOP_DATA_OFFICER_RACE"
                    multiple={true}
                    label={formatFieldByName(
                        fieldNames.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_DATA_OFFICER_RACE_ATTRIBUTE_ID
                    )}
                    {...fields.attributes.STOP_DATA_OFFICER_RACE}
                    width={fullPanelFormWidth}
                />
            </Row>
            <Row>
                <AttributeSelect
                    attributeType="STOP_DATA_OFFICER_GENDER"
                    multiple={false}
                    label={formatFieldByName(
                        fieldNames.STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_DATA_OFFICER_GENDER_ATTRIBUTE_ID
                    )}
                    {...fields.attributes.STOP_DATA_OFFICER_GENDER}
                    width={fullPanelFormWidth}
                />
            </Row>
            <Row>
                <BooleanSelect
                    path="isNonbinaryOfficer"
                    label={formatFieldByName(fieldNames.STOP_IS_NONBINARY_OFFICER)}
                    {...fields.isNonbinaryOfficer}
                />
            </Row>
        </UserProfileFormSection>
    );
}

const CjisIndicatorRadio = styled(Radio)`
    label {
        font-size: var(--arc-fontSizes-sm);
    }
`;

function CjisInfoFields(props) {
    const { fields, fieldDisplayNames } = props;
    const booleanOptions = [
        { value: true, label: 'True' },
        { value: false, label: 'False' },
    ];
    return (
        <UserProfileFormSection title={sectionHeaders.cjisInfo}>
            <Row>
                <CjisIndicatorRadio
                    {...fields.hasCounterTerrorismDataSelfHomePrivilegeIndicator}
                    size="sm"
                    options={booleanOptions}
                    label={
                        fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_COUNTER_TERRORISM_DATA_SELF_HOME_PRIVILEGE_INDICATOR
                    }
                />
            </Row>
            <Row>
                <CjisIndicatorRadio
                    {...fields.hasCriminalHistoryDataSelfSearchHomePrivilegeIndicator}
                    size="md"
                    options={booleanOptions}
                    label={
                        fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_CRIMINAL_HISTORY_DATA_SELF_SEARCH_HOME_PRIVILEGE_INDICATOR
                    }
                />
            </Row>
            <Row>
                <CjisIndicatorRadio
                    {...fields.hasCriminalIntelDataSelfSearchHomePrivilegeIndicator}
                    size="md"
                    options={booleanOptions}
                    label={
                        fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_CRIMINAL_INTEL_DATA_SELF_SEARCH_HOME_PRIVILEGE_INDICATOR
                    }
                />
            </Row>
            <Row>
                <CjisIndicatorRadio
                    {...fields.hasCriminalInvestigDataSelfSearchHomePrivilegeIndicator}
                    size="md"
                    options={booleanOptions}
                    label={
                        fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_CRIMINAL_INVESTIG_DATA_SELF_SEARCH_HOME_PRIVILEGE_INDICATOR
                    }
                />
            </Row>
            <Row>
                <CjisIndicatorRadio
                    {...fields.hasGovernmentDataSelfSearchHomePrivilegeIndicator}
                    size="md"
                    options={booleanOptions}
                    label={
                        fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_GOVERNMENT_DATA_SELF_SEARCH_HOME_PRIVILEGE_INDICATOR
                    }
                />
            </Row>
            <Row>
                <HStack alignItems="normal">
                    <CjisIndicatorRadio
                        {...fields.hasNcicCertificateIndicator}
                        size="md"
                        options={booleanOptions}
                        label={
                            fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_NCIC_CERTIFICATE_INDICATOR
                        }
                        width={halfPanelFormWidth}
                    />
                    <CjisIndicatorRadio
                        {...fields.hasNdexPrivilegeIndicator}
                        size="md"
                        options={booleanOptions}
                        label={
                            fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_NDEX_PRIVILEGE_INDICATOR
                        }
                        width={halfPanelFormWidth}
                    />
                </HStack>
            </Row>
            <Row>
                <HStack alignItems="normal">
                    <CjisIndicatorRadio
                        {...fields.hasPciiCertificateIndicator}
                        size="md"
                        options={booleanOptions}
                        label={
                            fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_PCII_CERTIFICATE_INDICATOR
                        }
                    />
                    <CjisIndicatorRadio
                        {...fields.hasCfrCertificateIndicator}
                        size="md"
                        options={booleanOptions}
                        label={
                            fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_CFR_CERTIFICATE_INDICATOR
                        }
                    />
                </HStack>
            </Row>
            <Row>
                <HStack alignItems="normal">
                    <CjisIndicatorRadio
                        {...fields.hasPublicSafetyOfficerIndicator}
                        size="md"
                        options={booleanOptions}
                        label={
                            fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_PUBLIC_SAFETY_OFFICER_INDICATOR
                        }
                    />
                    <CjisIndicatorRadio
                        {...fields.hasIntelligenceAnalystIndicator}
                        size="md"
                        options={booleanOptions}
                        label={
                            fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_INTELLIGENCE_ANALYST_INDICATOR
                        }
                    />
                </HStack>
            </Row>
            <Row>
                <CjisIndicatorRadio
                    {...fields.hasSwornLawEnforcementOfficerIndicator}
                    size="md"
                    options={booleanOptions}
                    label={
                        fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_HAS_SWORN_LAW_ENFORCEMENT_OFFICER_INDICATOR
                    }
                />
            </Row>
            <Row>
                <Text
                    {...fields.authenticatorAssuranceLevel}
                    label={
                        fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_AUTHENTICATOR_ASSURANCE_LEVEL
                    }
                    width={halfPanelFormWidth}
                />
                <Text
                    {...fields.federationAssuranceLevel}
                    label={fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_FEDERATION_ASSURANCE_LEVEL}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    {...fields.identityAssuranceLevel}
                    label={fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_IDENTITY_ASSURANCE_LEVEL}
                    width={halfPanelFormWidth}
                />
                <Text
                    {...fields.employerName}
                    label={fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_EMPLOYER_NAME}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    {...fields.federationId}
                    label={fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_FEDERATION_ID}
                    width={halfPanelFormWidth}
                />
                <Text
                    {...fields.identityProviderId}
                    label={fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_IDENTITY_PROVIDER_ID}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    {...fields.localId}
                    label={fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_LOCAL_ID}
                    width={halfPanelFormWidth}
                />
                <Text
                    {...fields.employerOri}
                    label={fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_EMPLOYER_ORI}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    {...fields.employerOrganizationGeneralCategoryCode}
                    label={
                        fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_EMPLOYER_ORGANIZATION_GENERAL_CATEGORY_CODE
                    }
                    width={fullPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    {...fields.employerStateCode}
                    label={fieldDisplayNames.USER_PROFILE_CJIS_EXTENSION_EMPLOYER_STATE_CODE}
                    width={halfPanelFormWidth}
                />
            </Row>
        </UserProfileFormSection>
    );
}

function ProfileInfoFields(props) {
    const { fields, fieldDisplayNames } = props;

    return (
        <UserProfileFormSection title={sectionHeaders.profileInfo}>
            <Row>
                <UserProfileAdminPhotoUpload {...props} />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_BADGE_NUMBER}
                    {...fields.badgeNumber}
                    width={fullPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_FIRST_NAME}
                    {...fields.firstName}
                    width={halfPanelFormWidth}
                />
                <Text
                    label={fieldDisplayNames.USER_PROFILE_MIDDLE_NAME}
                    {...fields.middleName}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_LAST_NAME}
                    {...fields.lastName}
                    width={halfPanelFormWidth}
                />
                <Text
                    label={fieldDisplayNames.USER_PROFILE_SUFFIX}
                    {...fields.suffix}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <DatePicker
                    label={fieldDisplayNames.USER_PROFILE_DATE_OF_BIRTH}
                    includeTime={false}
                    variant={DatePicker.variants.LOCAL_DATE}
                    {...fields.dateOfBirth}
                />
            </Row>
            <Row>
                <UserSelect
                    label={fieldDisplayNames.USER_PROFILE_SUPERVISOR_USER_ID}
                    width={halfPanelFormWidth}
                    {...fields.supervisorUserId}
                />
                <AttributeSelect
                    attributeType="OFFICER_EMPLOYMENT_TYPE"
                    label={fieldDisplayNames.DISPLAY_ONLY_USER_PROFILE_EMPLOYMENT_TYPE_LABEL}
                    {...fields.employmentTypeAttrId}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <AttributeSelect
                    attributeType="EDUCATION_LEVEL"
                    label={fieldDisplayNames.USER_PROFILE_EDUCATION_LEVEL_ATTR_ID}
                    {...fields.educationLevelAttrId}
                    width={halfPanelFormWidth}
                />
                <AttributeSelect
                    attributeType="EMPLOYEE_TYPE"
                    label={fieldDisplayNames.USER_PROFILE_EMPLOYEE_TYPE_ATTR_ID}
                    {...fields.employeeTypeAttrId}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_DRIVERS_LICENSE}
                    {...fields.driversLicense}
                    width={halfPanelFormWidth}
                />
                <AttributeSelect
                    attributeType="DL_TYPE"
                    label={fieldDisplayNames.USER_PROFILE_DRIVERS_LICENSE_TYPE_ATTR_ID}
                    {...fields.driversLicenseTypeAttrId}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <AttributeSelect
                    attributeType="SEX"
                    label={fieldDisplayNames.USER_PROFILE_SEX_ATTR_ID}
                    {...fields.sexAttrId}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <AttributeSelect
                    attributeType="BUILD"
                    label={fieldDisplayNames.USER_PROFILE_BUILD_ATTR_ID}
                    {...fields.buildAttrId}
                    width={halfPanelFormWidth}
                />
                <AttributeSelect
                    attributeType="RACE"
                    label={fieldDisplayNames.USER_PROFILE_RACE_ATTR_ID}
                    {...fields.raceAttrId}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <AttributeSelect
                    attributeType="EYE_COLOR"
                    label={fieldDisplayNames.USER_PROFILE_EYE_COLOR_ATTR_ID}
                    {...fields.eyeColorAttrId}
                    width={halfPanelFormWidth}
                />
                <AttributeSelect
                    attributeType="HAIR_COLOR"
                    label={fieldDisplayNames.USER_PROFILE_HAIR_COLOR_ATTR_ID}
                    {...fields.hairColorAttrId}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_HEIGHT_FEET}
                    {...fields.heightFeet}
                    width={halfPanelFormWidth}
                />
                <Text
                    label={fieldDisplayNames.USER_PROFILE_HEIGHT_INCHES}
                    {...fields.heightInches}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_WEIGHT}
                    {...fields.weight}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_SSN}
                    {...fields.ssn}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <AgencyProfileSelect
                    predicate={{
                        departmentId: props.currentUserDepartmentId,
                    }}
                    label={fieldDisplayNames.USER_PROFILE_DEPARTMENT_AGENCY_ID}
                    {...fields.departmentAgencyId}
                    width={halfPanelFormWidth}
                />
                <AttributeSelect
                    attributeType="ARRESTING_AGENCY"
                    label={fieldDisplayNames.USER_PROFILE_DEFAULT_ARRESTING_AGENCY_ATTR_ID}
                    {...fields.defaultArrestingAgencyAttrId}
                    width={halfPanelFormWidth}
                />
                <Checkbox
                    label={fieldDisplayNames.USER_PROFILE_IS_NON_AGENCY}
                    {...fields.isNonAgency}
                />
            </Row>
            <Row>
                <DatePicker
                    label={fieldDisplayNames.USER_PROFILE_DATE_HIRED}
                    includeTime={false}
                    variant={DatePicker.variants.LOCAL_DATE}
                    {...fields.dateHired}
                />
            </Row>
            <WithComplianceGroup
                complianceGroup={ComplianceGroupEnum.CALIFORNIA.name}
                fallback={
                    <Row>
                        <Text
                            label={fieldLabels.userProfileYearsOfPreviousService}
                            {...fields.yearsOfExperience}
                            width={halfPanelFormWidth}
                            helpText={fieldLabels.userProfileYearsOfPreviousServiceHelp}
                        />
                    </Row>
                }
            />
        </UserProfileFormSection>
    );
}

function IdentifiersFields({ fields, fieldDisplayNames }) {
    return (
        <UserProfileFormSection title={sectionHeaders.identifiers}>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_EXTERNAL_CAD_ID}
                    {...fields.externalCadId}
                    width={halfPanelFormWidth}
                />
                <Text
                    label={fieldDisplayNames.USER_PROFILE_CITY_ID_NUMBER}
                    {...fields.cityIdNumber}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_EXTERNAL_HR_ID}
                    {...fields.externalHrId}
                    width={halfPanelFormWidth}
                />
                <Text
                    label={fieldDisplayNames.USER_PROFILE_STATE_ID_NUMBER}
                    {...fields.stateIdNumber}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_TRAINING_ID_NUMBER}
                    {...fields.trainingIdNumber}
                    width={halfPanelFormWidth}
                />
                <Text
                    label={fieldDisplayNames.USER_PROFILE_MOBILE_ID}
                    {...fields.mobileId}
                    width={halfPanelFormWidth}
                />
            </Row>
        </UserProfileFormSection>
    );
}

function SignatureField(props) {
    return (
        <UserProfileFormSection title={sectionHeaders.signature}>
            <Row>
                <UserProfileAdminSignatureUpload width={fullPanelFormWidth} {...props} />
            </Row>
        </UserProfileFormSection>
    );
}

function DutyStatusFields({ fields, fieldDisplayNames }) {
    return (
        <UserProfileFormSection title={sectionHeaders.dutyStatus}>
            <NItems
                items={fields.dutyStatuses}
                addText={fieldDisplayNames.DUTY_STATUS_HISTORY_DUTY_STATUS}
            >
                {(dutyStatus) => (
                    <div>
                        <DutyStatusSelect
                            label={fieldDisplayNames.DUTY_STATUS_HISTORY_DUTY_STATUS}
                            {...dutyStatus.dutyStatus}
                            width={smallPanelFormWidth}
                        />
                        <DatePicker
                            label={fieldDisplayNames.DUTY_STATUS_HISTORY_DATE_EFFECTIVE}
                            includeTime={false}
                            variant={DatePicker.variants.LOCAL_DATE}
                            {...dutyStatus.dateEffective}
                            width={smallPanelFormWidth}
                        />
                    </div>
                )}
            </NItems>
        </UserProfileFormSection>
    );
}

const DeleteButtonWrapper = styled.div`
    float: right;

    i::before {
        font-size: 24px;
    }
`;

function AssignmentFields({ profile, fields, fieldDisplayNames }) {
    return (
        <UserProfileFormSection title={sectionHeaders.assignment}>
            <Row>
                <NItems
                    items={fields.userAssignments}
                    addText={fieldDisplayNames.DISPLAY_ONLY_USER_PROFILE_ASSIGNMENT_LABEL}
                    automaticallyIncludeDeleteButton={false}
                >
                    {(userAssignment, index, deleteButton) => (
                        <div key={userAssignment.id}>
                            <Row>
                                <DatePicker
                                    label={fieldDisplayNames.USER_ASSIGNMENT_START_DATE_UTC}
                                    includeTime={false}
                                    {...userAssignment.startDateUtc}
                                />
                                <DatePicker
                                    label={fieldDisplayNames.USER_ASSIGNMENT_END_DATE_UTC}
                                    includeTime={false}
                                    {...userAssignment.endDateUtc}
                                />
                                <DeleteButtonWrapper>{deleteButton}</DeleteButtonWrapper>
                            </Row>
                            <Row>
                                <Text
                                    label={fieldDisplayNames.USER_ASSIGNMENT_TITLE}
                                    {...userAssignment.title}
                                    width={halfPanelFormWidth}
                                />
                            </Row>
                            <Row>
                                <AttributeSelect
                                    attributeType="RANK"
                                    label={fieldDisplayNames.USER_ASSIGNMENT_RANK_ATTR_ID}
                                    {...userAssignment.rankAttrId}
                                    width={halfPanelFormWidth}
                                />
                                <AttributeSelect
                                    attributeType="RANK"
                                    label={fieldDisplayNames.USER_ASSIGNMENT_ACTING_RANK_ATTR_ID}
                                    {...userAssignment.actingRankAttrId}
                                    width={halfPanelFormWidth}
                                />
                            </Row>
                            <Row>
                                <AttributeSelect
                                    attributeType="BRANCH"
                                    label={fieldDisplayNames.USER_ASSIGNMENT_BRANCH_ATTR_ID}
                                    {...userAssignment.branchAttrId}
                                    width={halfPanelFormWidth}
                                />
                                <AttributeSelect
                                    attributeType="BUREAU"
                                    label={fieldDisplayNames.USER_ASSIGNMENT_BUREAU_ATTR_ID}
                                    {...userAssignment.bureauAttrId}
                                    width={halfPanelFormWidth}
                                />
                            </Row>
                            <Row>
                                <AttributeSelect
                                    attributeType="DIVISION"
                                    label={fieldDisplayNames.USER_ASSIGNMENT_DIVISION_ATTR_ID}
                                    {...userAssignment.divisionAttrId}
                                    width={halfPanelFormWidth}
                                />
                                <AttributeSelect
                                    attributeType="PERSONNEL_UNIT"
                                    label={fieldDisplayNames.USER_ASSIGNMENT_PERSONNEL_UNIT_ATTR_ID}
                                    {...userAssignment.personnelUnitAttrId}
                                    width={halfPanelFormWidth}
                                />
                            </Row>
                            <Row>
                                <Checkbox
                                    label={fieldDisplayNames.USER_ASSIGNMENT_IS_DETAIL}
                                    {...userAssignment.isDetail}
                                />
                            </Row>
                            <Row>
                                {profile.subdivisionUsage.subdivision1 && (
                                    <AttributeSelect
                                        attributeType="SUBDIVISION_DEPTH_1"
                                        label={
                                            fieldDisplayNames.USER_ASSIGNMENT_SUBDIVISION_1_ATTR_ID
                                        }
                                        {...userAssignment.subdivision1AttrId}
                                        width={halfPanelFormWidth}
                                    />
                                )}
                                {profile.subdivisionUsage.subdivision2 && (
                                    <AttributeSelect
                                        attributeType="SUBDIVISION_DEPTH_2"
                                        label={
                                            fieldDisplayNames.USER_ASSIGNMENT_SUBDIVISION_2_ATTR_ID
                                        }
                                        {...userAssignment.subdivision2AttrId}
                                        width={halfPanelFormWidth}
                                    />
                                )}
                            </Row>
                            <Row>
                                {profile.subdivisionUsage.subdivision3 && (
                                    <AttributeSelect
                                        attributeType="SUBDIVISION_DEPTH_3"
                                        label={
                                            fieldDisplayNames.USER_ASSIGNMENT_SUBDIVISION_3_ATTR_ID
                                        }
                                        {...userAssignment.subdivision3AttrId}
                                        width={halfPanelFormWidth}
                                    />
                                )}
                                {profile.subdivisionUsage.subdivision4 && (
                                    <AttributeSelect
                                        attributeType="SUBDIVISION_DEPTH_4"
                                        label={
                                            fieldDisplayNames.USER_ASSIGNMENT_SUBDIVISION_4_ATTR_ID
                                        }
                                        {...userAssignment.subdivision4AttrId}
                                        width={halfPanelFormWidth}
                                    />
                                )}
                            </Row>
                            <Row>
                                {profile.subdivisionUsage.subdivision5 && (
                                    <AttributeSelect
                                        attributeType="SUBDIVISION_DEPTH_5"
                                        label={
                                            fieldDisplayNames.USER_ASSIGNMENT_SUBDIVISION_5_ATTR_ID
                                        }
                                        {...userAssignment.subdivision5AttrId}
                                        width={halfPanelFormWidth}
                                    />
                                )}
                            </Row>
                        </div>
                    )}
                </NItems>
            </Row>
        </UserProfileFormSection>
    );
}

function TrainingCertificationFields({ fields, fieldDisplayNames }) {
    return (
        <UserProfileFormSection title={sectionHeaders.trainingCertification}>
            <Row>
                <AttributeSelect
                    attributeType="USER_TRAINING"
                    multiple={true}
                    label={fieldDisplayNames.DISPLAY_ATTRIBUTE_PERSON_TRAINING}
                    {...fields.attributes.USER_TRAINING}
                    width={fullPanelFormWidth}
                />
            </Row>
            <Row>
                <AttributeSelect
                    attributeType="USER_SKILL"
                    multiple={true}
                    label={fieldDisplayNames.DISPLAY_ATTRIBUTE_PERSON_SKILL}
                    {...fields.attributes.USER_SKILL}
                    width={fullPanelFormWidth}
                />
            </Row>
        </UserProfileFormSection>
    );
}

const LocationSummary = styled(_LocationSummary)`
    margin-bottom: 10px;
`;

const _TrashButton = React.forwardRef(({ className, onClick }, ref) => {
    return (
        <Button
            className={classNames(buttonTypes.ICON_LINK, className)}
            iconLeft={iconTypes.TRASH_CAN}
            onClick={onClick}
            ref={ref}
        />
    );
});

const LocationSummaryWrapper = styled.div`
    position: relative;

    i::before {
        font-size: 24px;
    }
`;

const TrashButton = styled(_TrashButton)`
    position: absolute;
    top: 0;
    right: 0;
`;

const HomeAddressWrapper = styled.div`
    margin-bottom: 10px;
`;

function ContactInfoFields({
    profile,
    fields,
    removeHomeAddress,
    isEditingSidepanel,
    fieldDisplayNames,
}) {
    const locationSidePanelSaveRef = useRef(null);
    const locationSidePanelProps = {
        entityType: EntityTypeEnum.USER.name,
        entityId: profile.userId,
        linkType: LinkTypesEnum.USER_HOME_ADDRESS,
        id: 0,
    };
    return (
        <UserProfileFormSection title={sectionHeaders.contactInfo}>
            {isEditingSidepanel && (
                <HomeAddressWrapper>
                    <Row>
                        {profile.homeAddress ? (
                            <LocationSummaryWrapper>
                                <LocationSummary location={profile.homeAddress} hideMap={true} />
                                <TrashButton
                                    onClick={removeHomeAddress}
                                    ref={locationSidePanelSaveRef}
                                />
                            </LocationSummaryWrapper>
                        ) : (
                            <LocationSidePanel
                                overlayId={overlayIdEnum.LOCATION_OVERLAY_USER_PROFILE_ADMIN_FORM}
                                renderButton={({ overlayBase: { open }, setCancelFocusRef }) => (
                                    <Button
                                        className={buttonTypes.ICON_LINK}
                                        iconLeft={iconTypes.ADD}
                                        onClick={open}
                                        ref={setCancelFocusRef}
                                    >
                                        {fieldLabels.addHomeAddress}
                                    </Button>
                                )}
                                saveRef={locationSidePanelSaveRef}
                                {...locationSidePanelProps}
                            />
                        )}
                    </Row>
                </HomeAddressWrapper>
            )}
            <Row>
                <NItems items={fields.phones} addText={fieldDisplayNames.USER_PHONE_PHONE_NUMBER}>
                    {(phone) => (
                        <FeatureFlagged
                            flag="RMS_DIALING_CODE_DISPLAYED_ENABLED"
                            fallback={
                                <Text
                                    label={fieldDisplayNames.USER_PHONE_PHONE_NUMBER}
                                    {...phone.phoneNumber}
                                    width={halfPanelFormWidth}
                                />
                            }
                        >
                            <PhoneNumberInput
                                label={fieldDisplayNames.USER_PHONE_PHONE_NUMBER}
                                baseDisplayNumber={phone.baseDisplayNumber.value}
                                hashedCountryCode={phone.hashedCountryCode.value}
                                initialCountryCode={phone.initialCountryCode.value}
                                onChangeBaseDisplayNumber={phone.baseDisplayNumber.onChange}
                                onChangeHashedCountryCode={phone.hashedCountryCode.onChange}
                                onChangeInitialCountryCode={phone.initialCountryCode.onChange}
                                onChangeDisplayNumber={phone.phoneNumber.onChange}
                            />
                        </FeatureFlagged>
                    )}
                </NItems>
            </Row>
        </UserProfileFormSection>
    );
}

function EmergencyContactInfoFields({ fields, fieldDisplayNames }) {
    return (
        <UserProfileFormSection title={sectionHeaders.emergencyContactInfo}>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_EMERGENCY_CONTACT_NAME}
                    {...fields.emergencyContactName}
                    width={fullPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_EMERGENCY_CONTACT_PHONE}
                    {...fields.emergencyContactPhone}
                    width={halfPanelFormWidth}
                />
                <Text
                    label={fieldDisplayNames.USER_PROFILE_EMERGENCY_CONTACT_EMAIL}
                    {...fields.emergencyContactEmail}
                    width={halfPanelFormWidth}
                />
            </Row>
            <Row>
                <Text
                    label={fieldDisplayNames.USER_PROFILE_EMERGENCY_CONTACT_ADDRESS}
                    {...fields.emergencyContactAddress}
                    width={fullPanelFormWidth}
                />
            </Row>
        </UserProfileFormSection>
    );
}

class UserProfileAdminForm extends Component {
    componentDidMount() {
        this.props.resetUserProfileForm(this.props.ui);
    }

    render() {
        const { props } = this;

        const saveDisabled =
            _(props.uploadingInlineAttachments).some((attachment) => {
                return !attachment.file.isDeleted;
            }) || this.props.isProcessingUploadingAttachments;

        const userSignatureEnabled = props.applicationSettings.PDF_EXPORTS_SHOW_SIGNATURE_IMAGES;

        const fullContent = (
            <>
                <UserAccountFields {...props} />
                <OnlyWithAbility
                    has={abilitiesEnum.ADMIN.EDIT_STATE_AUTHENTICATION_FIELDS_FOR_GLOBAL_USERS}
                >
                    <DexStateAuthenticationFields {...props} />
                </OnlyWithAbility>
                <WithComplianceGroup complianceGroup={ComplianceGroupEnum.CALIFORNIA.name}>
                    <CaliforniaStopFields {...props} />
                </WithComplianceGroup>
                <ProfileInfoFields {...props} />
                <IdentifiersFields {...props} />
                {userSignatureEnabled && <SignatureField {...props} />}
                <DutyStatusFields {...props} />
                <AssignmentFields {...props} />
                <TrainingCertificationFields {...props} />
                <ContactInfoFields {...props} />
                <CjisInfoFields {...props} />
                <EmergencyContactInfoFields {...props} />
                <InlineAttachmentsUploader
                    buttonText={props.fieldDisplayNames.DISPLAY_ONLY_USER_PROFILE_ATTACHMENT_LABEL}
                    entityId={props.profile.userId}
                    entityType={EntityTypeEnum.USER.name}
                    linkType={LinkTypesEnum.USER_PROFILE_ATTACHMENT}
                    parentContext={context}
                    onFileUploadStart={props.handleFileUploadStart}
                    onFileUploadFinish={props.handleFileUploadSuccess}
                    onFileUploadFailure={props.handleFileUploadFailure}
                />
            </>
        );

        const selfEditContent = (
            <>
                <UserAccountFields {...props} />
                <WithComplianceGroup complianceGroup={ComplianceGroupEnum.CALIFORNIA.name}>
                    <CaliforniaStopFields {...props} />
                </WithComplianceGroup>
                <ProfileInfoFields {...props} />
                <TrainingCertificationFields {...props} />
                <ContactInfoFields {...props} />
                <CjisInfoFields {...props} />
                <EmergencyContactInfoFields {...props} />
                <InlineAttachmentsUploader
                    buttonText={props.fieldDisplayNames.DISPLAY_ONLY_USER_PROFILE_ATTACHMENT_LABEL}
                    entityId={props.profile.userId}
                    entityType={EntityTypeEnum.USER.name}
                    linkType={LinkTypesEnum.USER_PROFILE_ATTACHMENT}
                    parentContext={context}
                    onFileUploadStart={props.handleFileUploadStart}
                    onFileUploadFinish={props.handleFileUploadSuccess}
                    onFileUploadFailure={props.handleFileUploadFailure}
                />
            </>
        );

        return (
            <SidePanel
                title={
                    props.isEditingSidepanel
                        ? sectionHeaders.sidepanelEditing
                        : sectionHeaders.sidepanelAdding
                }
                onSave={() => {
                    props.touchAll();
                    if (!props.valid) {
                        return props.saveBoxHalt();
                    } else {
                        props.saveAttachments();
                        return props.handleSubmit();
                    }
                }}
                onCancel={props.onCancel}
                context={context}
                saveDisabled={saveDisabled}
            >
                {props.userPermissions.canEditProfile
                    ? fullContent
                    : props.userPermissions.canSelfEditProfile && selfEditContent}
            </SidePanel>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    profile: userProfileAdminProfileSelector,
    isEditingSidepanel: createModalSelector(context, 'isEditing'),
    uploadingInlineAttachments: uploadingFilesSelector,
    userPermissions: userProfileAdminPermissionSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    applicationSettings: applicationSettingsSelector,
    isProcessingUploadingAttachments: isProcessingUploadingAttachmentsSelector,
    ui: uiSelector,
});

const mapDispatchToProps = (dispatch) => ({
    cancelUserProfilePanel: ({ clearForm }) => dispatch(cancelUserProfilePanel({ clearForm })),
    saveBoxHalt: () => dispatch(saveBoxHalt(context)),
    removeHomeAddress: (userId) =>
        dispatch(replaceLocationLinksForEntity(EntityTypeEnum.USER.name, userId, [])),
    handleSaveAttachments: (config) => {
        dispatch(
            saveInlineAttachments({
                entityType: config.entityType,
                entityId: config.entityId,
                linkType: config.linkType,
            })
        );
    },
    clearAttachments: () => {
        dispatch(updateUploadingFiles([]));
        dispatch(clearUploads());
    },
    handleFileUploadStart: () => {
        dispatch(fileUploadStart());
    },
    handleFileUploadSuccess: () => {
        dispatch(fileUploadSuccess());
    },
    handleFileUploadFailure: () => {
        dispatch(fileUploadFailure());
    },
    resetUserProfileForm: (ui) => {
        dispatch(resetUserProfileForm(ui));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFields(userProfileFormLabelsFieldNames),
    withHandlers({
        onSubmit: ({ isEditingSidepanel, onEditSubmit, onAddSubmit }) =>
            isEditingSidepanel ? onEditSubmit : onAddSubmit,
        onCancel({ isEditingSidepanel, cancelUserProfilePanel, clearAttachments }) {
            return () => {
                clearAttachments();
                cancelUserProfilePanel({ clearForm: !isEditingSidepanel });
            };
        },
        removeHomeAddress({ profile, removeHomeAddress }) {
            return () => removeHomeAddress(profile.userId);
        },
        saveAttachments({ profile, handleSaveAttachments }) {
            return () => {
                handleSaveAttachments({
                    entityId: profile.userId,
                    entityType: EntityTypeEnum.USER.name,
                    linkType: LinkTypesEnum.USER_PROFILE_ATTACHMENT,
                });
            };
        },
    }),
    reduxForm({
        form: 'userProfileAdmin',
        fields: userProfileFormFieldList,
        validate: (data, props) => getUserProfileFormValidation(data, props),
    })
)(UserProfileAdminForm);
