import { EntityPermission, EntityPermissionsResource } from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export type EntityPermissionForUpdate = {
    entityId: EntityPermission['entityId'];
    entityType: EntityPermission['entityType'];
    operationType?: EntityPermission['operationType'];
    roleId?: EntityPermission['roleId'];
};

export default createResource({
    name: 'Permissions Resource',
    methods: {
        /**
         * Get the permissions for the given entity or entities.
         */
        getPermissions(
            entityType: string,
            entityIds: number | number[],
            overrideRoleRestrictions?: boolean
        ) {
            return req<EntityPermissionsResource.GetEntityPermissions>({
                method: 'GET',
                url: 'security/permissions/entity',
                params: {
                    entity_type: entityType,
                    entity_ids: [entityIds].flat(),
                    override_role_restrictions: overrideRoleRestrictions,
                },
            });
        },

        /**
         * Update the permissions for the given entity, by REPLACING THEM.
         */
        updatePermissions<T extends EntityPermissionForUpdate>(
            entityType: string,
            entityId: number,
            permissions: T[]
        ) {
            return req<EntityPermissionsResource.ReplaceEntityPermissionsForEntity>({
                method: 'PUT',
                url: 'security/permissions/entity',
                params: {
                    entity_type: entityType,
                    entity_id: entityId,
                },
                // @ts-expect-error EntityPermissionForUpdate is not compatible with EntityPermission but we don't
                // need to send metadata like `createdBy`, `rmsEventId`, and so on.
                data: permissions,
            });
        },
    },
});
