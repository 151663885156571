import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { reject } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Row from '../../../../../core/components/Row';
import ReactReduxForm from '../../../../../core/forms/components/ReactReduxForm';
import { ArbiterMFTTextArea, RRFTextArea } from '../../../../../core/forms/components/TextArea';
import {
    ArbiterMFTAttributeSelect,
    RRFAttributeSelect,
} from '../../../../../core/forms/components/selects/AttributeSelect';
import { locationEntityLinkSummaryVariants } from './LocationEntityLinkSummary';
import { LocationSummaryViewWrapper } from './LocationSummaryViewWrapper';

const { withRRFActions, withRRFModelSelector } = reactReduxFormHelpers;
const locationDescriptionLabel =
    componentStrings.reports.core.LocationWithEditableDescription.labels.description;

export const LocationSummaryViewWrapperWithFormFields = ({
    locationDescriptionPath,
    locationPositionAttrIdPath,
    summaryMode,
    hideCountry = false,
    ...locationSummaryProps
}) => (
    <LocationSummaryViewWrapper
        {...locationSummaryProps}
        summaryMode={summaryMode}
        hideCountry={hideCountry}
        locationEntityLinkSummaryVariant={
            summaryMode ? undefined : locationEntityLinkSummaryVariants.WITHOUT_DESCRIPTION
        }
        additionalLocationContent={(locationBundle, index) =>
            !summaryMode && (
                <>
                    <Row>
                        <ArbiterMFTAttributeSelect
                            path={locationPositionAttrIdPath(locationBundle, index)}
                            attributeType={AttributeTypeEnum.LOCATION_POSITION.name}
                            length="lg"
                        />
                    </Row>
                    <Row>
                        <ArbiterMFTTextArea path={locationDescriptionPath(locationBundle, index)} />
                    </Row>
                </>
            )
        }
    />
);

// This component must exist within the context of a `ReactReduxForm`,
// else it will error out
const RRFLocationSummaryViewWrapperWithFormFieldsWithinFormWrapper = compose(
    withRRFActions,
    withRRFModelSelector,
    connect(
        (state, { formModelSelector }) => ({
            model: formModelSelector(state),
        }),
        (dispatch, { formActions: { change } }) => ({
            formChange: (modelPath, value) => change(modelPath, value),
        })
    )
)(
    ({
        locationPath,
        summaryMode,
        hideCountry,
        formChange,
        allowMultiple,
        model,
        ...locationSummaryProps
    }) => (
        <LocationSummaryViewWrapper
            {...locationSummaryProps}
            additionalLocationContent={(locationBundle, index) =>
                !summaryMode && (
                    <>
                        <Row>
                            <RRFAttributeSelect
                                path={
                                    allowMultiple
                                        ? `${locationPath}.${index}.positionAttrId`
                                        : `${locationPath}.positionAttrId`
                                }
                                attributeType={AttributeTypeEnum.LOCATION_POSITION.name}
                                length="lg"
                                ignoreValidation={true}
                            />
                        </Row>
                        <Row>
                            <RRFTextArea
                                path={
                                    allowMultiple
                                        ? `${locationPath}.${index}.description`
                                        : `${locationPath}.description`
                                }
                                label={locationDescriptionLabel}
                                ignoreValidation={true}
                            />
                        </Row>
                    </>
                )
            }
            summaryMode={summaryMode}
            hideCountry={hideCountry}
            onLocationAdd={(location, modelEntityLink) => {
                if (allowMultiple) {
                    // Override the existing location
                    // if we are adding the same one again
                    const filteredModel = reject(
                        model[locationPath],
                        (currentEntityLink) =>
                            currentEntityLink.locationId === modelEntityLink.locationId
                    );
                    formChange(locationPath, [...filteredModel, modelEntityLink]);
                } else {
                    formChange(locationPath, modelEntityLink);
                }
            }}
            onLocationRemove={(removedLocationEntityLink, locationEntityLinksLeft) => {
                if (allowMultiple) {
                    formChange(locationPath, locationEntityLinksLeft);
                } else {
                    formChange(locationPath, null);
                    formChange(`${locationPath}.description`, null);
                    formChange(`${locationPath}.positionAttrId`, null);
                }
            }}
            locationEntityLinkSummaryVariant={
                summaryMode ? undefined : locationEntityLinkSummaryVariants.WITHOUT_DESCRIPTION
            }
            allowMultiple={allowMultiple}
        />
    )
);

// If we did not pass `reactReduxForm` into this component,
// it is assumed that the component is already wrapped by
// `ReactReduxForm` higher in the DOM tree
//
// In some cases, the `LocationSummarView` will still not be wrapped in
// a `ReactReduxForm`, and in those cases, the methods above will just `noop`
// or not be called
//
// This happens specifically for when the location summary view is being used
// to render the `summary` portion of the card
export const RRFLocationSummaryViewWrapperWithFormFields = ({ reactReduxForm, ...props }) =>
    reactReduxForm ? (
        <ReactReduxForm {...reactReduxForm}>
            <RRFLocationSummaryViewWrapperWithFormFieldsWithinFormWrapper {...props} />
        </ReactReduxForm>
    ) : (
        <RRFLocationSummaryViewWrapperWithFormFieldsWithinFormWrapper {...props} />
    );
