import { EntityTypeEnum, OperationTypeEnum } from '@mark43/rms-api';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FederatedSearchExternalReadDataContext } from 'mark43-federated-search';
import includes from 'lodash/includes';
import merge from 'lodash/merge';
import { useToast } from 'arc';

import strings from '~/client-common/core/strings/componentStrings';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { userHasAbilitySelector } from '~/client-common/core/domain/abilities/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import {
    currentEntityProfileIdSelector,
    currentEntityProfileIsEditableSelector,
    currentEntityProfileUserOperationTypesSelector,
} from '../state/ui';
import {
    openItemSidePanel,
    convertToFormModelFromRmsVehicle,
    generateSidePanelFormDataAsObjFromRmsFirearm,
    generateSidePanelFormDataAsObjFromRmsOtherItem,
} from '../../../reports/core/state/ui/itemSidePanel';
import {
    useMapDexItemsToRmsVehicles,
    useMapDexFirearmsToRmsFirearms,
    useMapDexArticleToRmsProperty,
} from '../../../reports/core/components/items/hooks';
import { currentUserIdSelector } from '../../../core/current-user/state/ui';

const headerStrings = strings.entityProfiles.EntityProfileVehicleHeader;

export const useHandleRedirectFromDexEdit = () => {
    const entityId: number = useSelector(currentEntityProfileIdSelector);

    const isEditable = useSelector(currentEntityProfileIsEditableSelector);

    const userOperationTypes: string[] = useSelector(
        currentEntityProfileUserOperationTypesSelector
    );
    const hasEditPermission = includes(userOperationTypes, OperationTypeEnum.WRITE.name);

    const currentUserId = useSelector(currentUserIdSelector);
    const userHasAbility = useSelector(userHasAbilitySelector);
    const hasEditAbility = userHasAbility(currentUserId, abilitiesEnum.CORE.EDIT_GENERAL);

    const dispatch = useDispatch();

    const toast = useToast();

    const canEditItemProfile = isEditable && hasEditPermission && hasEditAbility;

    const { resultForConsumerApp } = useContext(FederatedSearchExternalReadDataContext) || {};

    const dexVehicles = useMemo(
        () => resultForConsumerApp?.VEHICLE || resultForConsumerApp?.BOAT || [],
        [resultForConsumerApp]
    );
    const dexFirearms = useMemo(() => resultForConsumerApp?.FIREARM || [], [resultForConsumerApp]);
    const dexArticles = useMemo(() => resultForConsumerApp?.ARTICLE || [], [resultForConsumerApp]);
    const { rmsVehicles, error: vehiclesMapErrorMsg } = useMapDexItemsToRmsVehicles(dexVehicles);
    const { rmsFirearms, error: firearmsMapErrorMsg } = useMapDexFirearmsToRmsFirearms(dexFirearms);
    const { rmsProperties: rmsArticles, error: articlesMapErrorMsg } =
        useMapDexArticleToRmsProperty(dexArticles);

    const rmsVehicle = rmsVehicles[0];
    const rmsFirearm = rmsFirearms[0];
    const rmsArticle = rmsArticles[0];

    const isRedirectFromDex = rmsVehicle || rmsFirearm || rmsArticle;

    const errorMsg = vehiclesMapErrorMsg || firearmsMapErrorMsg || articlesMapErrorMsg;

    useEffect(() => {
        if (isRedirectFromDex && !canEditItemProfile) {
            return;
        }

        if (rmsVehicle) {
            dispatch(
                openItemSidePanel({
                    itemProfileId: entityId,
                    isVehicle: true,
                    operation: itemSidePanelOperationEnum.EDIT,
                    ownerType: EntityTypeEnum.ITEM_PROFILE.name,
                    isFormHidden: false,
                    itemTypeAttrId: globalAttributes.itemType.vehicle,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge(
                            {},
                            formModel,
                            convertToFormModelFromRmsVehicle(rmsVehicle)
                        ),
                })
            );
        } else if (rmsFirearm) {
            dispatch(
                openItemSidePanel({
                    itemProfileId: entityId,
                    operation: itemSidePanelOperationEnum.EDIT,
                    ownerType: EntityTypeEnum.ITEM_PROFILE.name,
                    itemTypeAttrId: globalAttributes.itemType.firearm,
                    alwaysHideItemTypeNavigation: true,
                    alwaysHideDexItemsQuerySearch: true,
                    alwaysHideItemsInlineSearch: true,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge(
                            {},
                            formModel,
                            generateSidePanelFormDataAsObjFromRmsFirearm(rmsFirearm)
                        ),
                })
            );
        } else if (rmsArticle) {
            dispatch(
                openItemSidePanel({
                    itemProfileId: entityId,
                    operation: itemSidePanelOperationEnum.EDIT,
                    ownerType: EntityTypeEnum.ITEM_PROFILE.name,
                    itemTypeAttrId: globalAttributes.itemType.item,
                    alwaysHideItemTypeNavigation: true,
                    alwaysHideDexItemsQuerySearch: true,
                    setFormValues: (formModel: Record<string, unknown>) =>
                        merge(
                            {},
                            formModel,
                            generateSidePanelFormDataAsObjFromRmsOtherItem(rmsArticle)
                        ),
                })
            );
        }
    }, [rmsVehicle, rmsFirearm, rmsArticle, dispatch, entityId, canEditItemProfile, isRedirectFromDex]);

    useEffect(() => {
        if (isRedirectFromDex && !canEditItemProfile) {
            toast({
                status: 'error',
                description: headerStrings.notAllowedToEditMessage,
            });
        }
        //   useToast returns a new instance of the toast each time. Remove toast from the dependencies.
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRedirectFromDex, canEditItemProfile]);

    useEffect(() => {
        if (errorMsg) {
            toast({
                status: 'error',
                description: errorMsg,
            });
        }
        //   useToast returns a new instance of the toast each time. Remove toast from the dependencies.
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMsg]);
};
