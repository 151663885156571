import keyMirror from 'keymirror';

/**
 * Must match server enum {@link
 *   cobalt-rms/search/search-common/src/main/java/mark43/search/model/ElasticCobaltSortKey.java},
 *   except for the default `RELEVANCE`.
 * @type {Object}
 */
const sortKeyEnum = {
    RELEVANCE: undefined,
    ...keyMirror({
        ATTACHMENT_CREATED_DATE_UTC: null,
        REPORT_UPDATED_DATE_UTC: null,
        REPORT_EVENT_START_UTC: null,
        REPORT_REPORT_CREATION_DATE_UTC: null,
        REPORT_REPORTING_EVENT_NUMBER: null,
        CASE_UPDATED_DATE_UTC: null,
        CASE_CREATED_DATE_UTC: null,
        CASE_ASSIGNED_DATE_UTC: null,
        CASE_DUE_DATE_UTC: null,
        CASE_PRIORITY: null,
        COURT_ORDER_COURT_CASE_DATE_UTC: null,
        COURT_ORDER_MODIFIED_DATE_UTC: null,
        PERSON_FIRST_NAME: null,
        PERSON_LAST_NAME: null,
        SUBJECT_PERSON_LAST_NAME: null,
        SUBJECT_PERSON_FIRST_NAME: null,
        PERSON_DATE_OF_BIRTH: null,
        PERSON_UPDATED_DATE_UTC: null,
        CAD_TICKET_START_DATE_UTC: null,
        ORGANIZATION_NAME: null,
        ORGANIZATION_UPDATED_DATE_UTC: null,
        PROPERTY_UPDATED_DATE_UTC: null,
        VEHICLE_UPDATED_DATE_UTC: null,
        COBALT_CAD_TICKET_START_DATE_UTC: null,
        COBALT_CAD_TICKET_PRIORITY: null,
        EVIDENCE_ITEM_DATE_ACQUIRED: null, // evidence
        EVIDENCE_ITEM_REPORTING_EVENT_NUMBER: null, // evidence
        EVIDENCE_ITEM_CHAIN_EVENT_DATE: null, // evidence
        EVIDENCE_ITEM_DISPOSITION_STATUS: null, // evidence
        EVIDENCE_ITEM_EVENT_TYPE: null, // evidence
        EVIDENCE_ITEM_STAFF_REMARKS: null, // evidence
        EVIDENCE_ITEM_DISPLAY_VALUE: null, // evidence
        ELASTICSEARCH_SCORE: null,
        TASK_CREATED_DATE_UTC: null,
        TASK_LABEL_NAME: null,
        TASK_REPORTING_EVENT_NUMBER: null,
        TASK_STATUS: null,
        TASK_STATUS_UPDATED_DATE_UTC: null,
        TASK_SUBJECT_TYPE: null,
        UNIT_STATUS_START_DATE_UTC: null,
        UNIT_STATUS_AGENCY_EVENT_NUMBER: null,
        UNIT_STATUS_AGENCY_EVENT_GLOBAL_PRIORITY: null,
        UNIT_STATUS_AGENCY_TYPE: null,
        USER_ACTION_CREATED_DATE_UTC: null,
        USER_FULL_NAME: null,
        USER_UPDATED_DATE_UTC: null,
        USER_ACTIVE: null,
        WARRANT_DATE_ISSUED_UTC: null,
        WARRANT_STATUS: null,
    }),
};

export default sortKeyEnum;
// type is any value of sortKeyEnum object
export type SortKey = (typeof sortKeyEnum)[keyof typeof sortKeyEnum];
