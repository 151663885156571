import { RedirectFunction, RouterState } from 'react-router';
import { find, get } from 'lodash';
import createSearchModule from '~/client-common/redux/modules/search/core/utils/createSearchModule';
import { RmsAction } from '../../../../../core/typings/redux';

/**
 * Execute a saved search based on a query param, ?savedSearchId=12345. Use this in a route's
 *   onEnter hook.
 */
export function executeSavedSearchFromQueryParam({
    searchModule,
    nextState,
    replace,
}: {
    searchModule: ReturnType<typeof createSearchModule>;
    nextState: RouterState;
    replace: RedirectFunction;
}): RmsAction<Promise<boolean>> {
    const savedSearchId = parseInt(get(nextState, 'location.query.savedSearchId'));
    const showNew = get(nextState, 'location.query.showNew') === 'true';

    return (dispatch, getState) => {
        if (!searchModule || !savedSearchId) {
            return Promise.resolve(false);
        }

        const state = getState();
        const savedSearches = searchModule.selectors.savedSearchesSelector(state);

        if (!find(savedSearches, { id: savedSearchId })) {
            // if the saved search doesn't already exist in state, load it - this can happen when
            // the user reloads the app with ?savedSearchId=54321 in the url, or opens it in a new
            // tab
            return dispatch(searchModule.actionCreators.loadSavedSearches())
                .then(() =>
                    dispatch(
                        searchModule.actionCreators.executeSavedSearch(
                            savedSearchId,
                            false,
                            showNew
                        )
                    )
                )
                .then(() => true)
                .catch(() => {
                    // if the saved search could not be executed, clear savedSearchId from the url
                    replace(get(nextState, 'location.pathname'));
                    return false;
                });
        }

        return dispatch(searchModule.actionCreators.executeSavedSearch(savedSearchId, false)).then(
            () => true
        );
    };
}
