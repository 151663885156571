import { createSelector } from 'reselect';
import { CadTicket, CadTicketUnitAndMember } from '@mark43/rms-api';

// helpers
import {
    allSingleAttributeValuesMapper,
    buildViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { dateTimeRangeFormatter } from '../../../../dates/utils/dateHelpers';
import { formatFullName } from '../../../mini-users/utils/miniUsersHelpers';

// selectors
import { formatUserByIdSelector } from '../../../../../../legacy-redux/selectors/userSelectors';
import { cadTicketsWhereSelector } from '../data';
import { formatMiniUserByIdSelector } from '../../../mini-users/state/data';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { currentDepartmentDateFormatterSelector } from '../../../current-user/state/ui';

export const primaryResponderFormatter = (
    unit: CadTicketUnitAndMember,
    formatUserById: ReturnType<typeof formatUserByIdSelector>
) => {
    if (unit.memberId) {
        return formatUserById(unit.memberId);
    }
    return formatFullName({ first: unit.memberName, badge: unit.memberIdentifier });
};

const responderMembersFormatter = (
    units: CadTicketUnitAndMember[],
    formatUserById: ReturnType<typeof formatUserByIdSelector>
) => {
    if (units) {
        return units
            .filter((unit) => unit.isPrimaryOnEvent)
            .map((unit) => primaryResponderFormatter(unit, formatUserById))
            .join(', ');
    }

    return '';
};

const responderUnitsFormatter = (units: CadTicketUnitAndMember[]) => {
    if (units) {
        // second filter for uniqueness: Removes duplicates by checking if the index of the current value is the same
        // as the first occurrence index in the array (self.indexOf(value)).
        return units
            .filter((unit) => unit.isPrimaryOnEvent)
            .map((unit) => unit.unitCallSign)
            .sort()
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(', ');
    }

    return '';
};

type CadTicketViewProps = {
    eventTypeAttrId: string;
    secondaryEventTypeAttrId: string;
    eventDate: string;
    fullAddress: string;
    callForService: string;
    responderUnitsCommaSeparated: string;
    responderMembersCommaSeparated: string;
    callerPhoneNumber: string;
};

const getCadTicketsViewModelWhere = (
    formatUserById: ReturnType<typeof formatUserByIdSelector>,
    formatAttributeById: ReturnType<typeof formatAttributeByIdSelector>,
    cadTicketsWhere: ReturnType<typeof cadTicketsWhereSelector>,
    dateTimeFormatter: ReturnType<typeof currentDepartmentDateFormatterSelector>
) => {
    return (reportingEventNumber?: string, cadAgencyEventNumber?: string | null) => {
        const cadTickets = cadAgencyEventNumber
            ? cadTicketsWhere(
                  (cadTicket) => cadTicket.cadAgencyEventNumber === cadAgencyEventNumber
              )
            : cadTicketsWhere(
                  (cadTicket) =>
                      reportingEventNumber &&
                      cadTicket.reportingEventNumbers.includes(reportingEventNumber)
              );

        const viewModelBuilder = buildViewModel<CadTicket, CadTicketViewProps>({
            mappers: [
                allSingleAttributeValuesMapper,
                ({ cadAgencyEventNumber }) => ({ cadAgencyEventNumber }),
                ({ eventStartDateUtc, eventClosedDateUtc }) => ({
                    eventDate: dateTimeRangeFormatter(
                        eventStartDateUtc,
                        eventClosedDateUtc,
                        dateTimeFormatter
                    ),
                }),
                ({ fullAddress }) => ({ fullAddress }),
                ({ secondaryEventTypeAttrId, secondaryEventType }) => ({
                    callForService:
                        formatAttributeById(secondaryEventTypeAttrId) || secondaryEventType,
                }),
                ({ unitsAndMembers }) => ({
                    // these are the primary units and primary officers
                    responderUnitsCommaSeparated: responderUnitsFormatter(unitsAndMembers),
                    responderMembersCommaSeparated: responderMembersFormatter(
                        unitsAndMembers,
                        formatUserById
                    ),
                }),
                ({ callerPhoneNumber }) => ({ callerPhoneNumber }),
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return cadTickets.map(viewModelBuilder);
    };
};

export const cadTicketsViewModelWhereSelector = createSelector(
    formatMiniUserByIdSelector,
    formatAttributeByIdSelector,
    cadTicketsWhereSelector,
    currentDepartmentDateFormatterSelector,
    getCadTicketsViewModelWhere
);
