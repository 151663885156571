import { createSelector, createStructuredSelector } from 'reselect';
import identity from 'lodash/identity';
import orderBy from 'lodash/orderBy';
import noop from 'lodash/noop';
import {
    BriefingSearchSqlQuery,
    ElasticSearchTypeEnumType,
    EntityTypeEnum,
    LinkTypesEnum,
    RefContextEnum,
    RmsSavedSearchesView,
    SqlSearchQueryBriefingSearchSqlQuery,
    SqlSort,
} from '@mark43/rms-api';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { Briefing, briefingsByIdSelector } from '~/client-common/core/domain/briefings/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import { savedSearchErrorMessages } from '~/client-common/configs/advancedSearchConfig';
import { getSavedSearchById } from '~/client-common/helpers/advancedSearchHelpers';

import { RootState } from '../../../../legacy-redux/reducers/rootReducer';
import { RmsAction, RmsDispatch } from '../../../../core/typings/redux';
import { scrollToTop } from '../../../core/components/ScrollableUnderSubheader';
import createSearchModule from '../../../search/core/utils/createSearchModule';
import { canEditTaskSelector } from '../../../tasks/core/state/ui';
import { initializeAttachmentsSidePanelForm } from '../../../attachments/core/state/ui/attachmentsSidePanel';
import getRmsSavedSearchResource from '../../../search/core/resources/rmsSavedSearchResource';
import storeRmsSavedSearchesView from '../../../search/core/utils/storeRmsSavedSearchesView';

import { BriefingRoutingModalType } from '../../types';
import { queryParamDefaults } from '../../configuration';
import { getDuplicateBriefingTitle } from '../../utils';
import briefingResource from '../../resources/briefingResource';
import { convertToFormModel } from '../forms/routingOptionsForm';
import { formatElasticQueryToFilters } from '../forms/formatElasticQueryToFilters';
import { initialFilters } from '../forms/briefingsDashboardFilters';
import { withBriefingsView } from '../data';

export const briefingsDashboardSearch = createSearchModule({
    elasticSearchType: 'BRIEFING',
    baseUiSelector: (state: RootState) => state.ui.briefings.dashboard,
    defaultTableState: {
        from: queryParamDefaults.FROM,
        size: queryParamDefaults.SIZE,
        activeColumnKeys: {
            date: 'postedDateUtc',
        },
    },
    resourceMethod: (
        searchSqlQuery: BriefingSearchSqlQuery,
        from: number,
        size: number,
        sortKey: SqlSort['sortKey'],
        sortType: SqlSort['sortType'],
        dispatch: RmsDispatch,
        _hideLoadingBar: boolean,
        elasticSearchType?: ElasticSearchTypeEnumType
    ) => {
        const sorts = sortKey && sortType ? [{ sortKey, sortType }] : [];
        const briefingSqlQuery: SqlSearchQueryBriefingSearchSqlQuery = {
            query: searchSqlQuery,
            from,
            size,
            sorts,
        };

        return briefingResource
            .search(briefingSqlQuery, elasticSearchType)
            .then((briefingsSearchView) => {
                const { briefings, totalCount, query } = briefingsSearchView;
                dispatch(withBriefingsView(briefingsSearchView));

                return {
                    items: briefings,
                    query: {
                        query: query?.query,
                        from,
                        size,
                        sorts,
                    },
                    totalCount,
                };
            });
    },
    createResultsViewModelsSelector: identity,
    resultsContainerClassName: 'briefing-results-container',
    scrollToElement: scrollToTop,
    elasticQueryToFilterGroups: formatElasticQueryToFilters,
    transformElasticQueryBeforeSearchSelector: () => identity,
    buildLoadSavedSearchesActionCreator: () => identity,
    buildLoadAndExecuteLatestAutoSavedSearch,
    buildExecuteSavedSearchActionCreator,
    selectorToBind: createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
        dateTimeFormatter: currentDepartmentDateFormatterSelector,
        allRoleFormatsByRoleId: allRoleFormatsByRoleIdSelector,
    }),
});

export default briefingsDashboardSearch.reducers.uiReducer;

function buildLoadAndExecuteLatestAutoSavedSearch(
    actionCreators: ReturnType<typeof createSearchModule>['actionCreators'],
    elasticSearchType: ElasticSearchTypeEnumType
) {
    return () => (dispatch: RmsDispatch) => {
        return getRmsSavedSearchResource()
            .getAutoSavedRmsSavedSearchesViewForElasticSearchType(elasticSearchType)
            .then((rmsSavedSearchesView: RmsSavedSearchesView) => {
                const { savedSearches } = rmsSavedSearchesView;

                if (savedSearches.length > 0) {
                    const savedSearch = orderBy(savedSearches, 'id', 'desc')[0];
                    const { query } = savedSearch;
                    const parsedQuery = JSON.parse(query);
                    const { query: savedSearchQuery } = parsedQuery;

                    const convertedSavedSearch = {
                        ...savedSearch,
                        query: JSON.stringify({
                            query: savedSearchQuery,
                        }),
                    };

                    dispatch(
                        storeRmsSavedSearchesView({
                            rmsSavedSearchesView,
                            action: actionCreators.loadLatestAutoSavedSearchSuccess([
                                convertedSavedSearch,
                            ]),
                        })
                    );
                    return dispatch(actionCreators.executeSavedSearch(savedSearch.id));
                }
            })
            .catch(noop);
    };
}

function buildExecuteSavedSearchActionCreator(
    actionCreators: ReturnType<typeof createSearchModule>['actionCreators'],
    selectors: ReturnType<typeof createSearchModule>['selectors']
) {
    return (savedSearchId?: number, scroll = true) => {
        return (dispatch: RmsDispatch, getState: () => RootState) => {
            const state = getState();
            const savedSearch = getSavedSearchById(
                selectors.savedSearchesSelector(state),
                savedSearchId
            );
            const parsedQuery: SqlSearchQueryBriefingSearchSqlQuery = JSON.parse(savedSearch.query);
            const {
                query,
                from = queryParamDefaults.FROM,
                size = queryParamDefaults.SIZE,
            } = parsedQuery;

            const transformedSavedSearch = {
                ...savedSearch,
                query: JSON.stringify({
                    query,
                    from,
                    size,
                }),
            };

            dispatch(actionCreators.executeSavedSearchStart());

            return briefingResource
                .executeSavedSearch(transformedSavedSearch, { from, size })
                .then((briefingsSearchView) => {
                    const { briefings, totalCount } = briefingsSearchView;
                    dispatch(withBriefingsView(briefingsSearchView));

                    dispatch(
                        actionCreators.searchSuccess(
                            {
                                items: briefings,
                                query: {
                                    from,
                                    size,
                                    query: {
                                        ...query,
                                    },
                                    elasticQuery: {
                                        ...initialFilters,
                                        ...query,
                                    },
                                },
                                totalCount,
                            },
                            scroll
                        )
                    );

                    dispatch(actionCreators.executeSavedSearchSuccess(savedSearch));

                    return {
                        elasticQuery: query,
                        briefingsSearchView,
                    };
                })
                .catch(() => {
                    dispatch(
                        actionCreators.executeSavedSearchFailure(
                            savedSearchErrorMessages.onExecuteSavedSearchErrorMessage
                        )
                    );
                });
        };
    };
}

type TQuery = Partial<
    SqlSort & {
        from: number;
        size: number;
        formData: BriefingSearchSqlQuery | null;
    }
>;
export const searchBriefingDashboard = (
    query: TQuery = {},
    options: { autoSave?: boolean; reset?: boolean } = {}
): RmsAction<Promise<void>> => async (dispatch, getState) => {
    const { reset = false, ...restOptions } = options;
    const currentQuery = briefingsDashboardSearch.selectors.currentQuerySelector(getState());
    const sort: SqlSort | undefined = currentQuery.sorts?.[0];

    if (reset) {
        dispatch(briefingsDashboardSearch.actionCreators.resetState());
    }

    const queryWithSort = {
        ...(sort ?? {}),
        ...query,
    };

    dispatch(
        briefingsDashboardSearch.actionCreators.search(queryWithSort, {
            cacheBust: true,
            showLoadingModal: false,
            ...restOptions,
        })
    );
};

const prefillBriefingRoutingForm = (briefingId: number, duplicate: boolean): RmsAction<void> => (
    dispatch,
    getState,
    { formsRegistry }
) => {
    const briefing = briefingsByIdSelector(getState())(briefingId);
    if (briefing) {
        const duplicateBriefing: Briefing = {
            ...briefing,
            expiryDateUtc: undefined,
            title: getDuplicateBriefingTitle(briefing.title),
        };
        const formModel = convertToFormModel(duplicate ? duplicateBriefing : briefing);
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_BRIEFING_ROUTING_MODAL.name,
            undefined,
            (form) => {
                form.set('', formModel);
            }
        );
    }
};

export const openBriefingRoutingModal = (
    type: BriefingRoutingModalType,
    briefingId?: number
): RmsAction<void> => (dispatch, getState, { overlayStore }) => {
    const prefillTypes = [BriefingRoutingModalType.EDIT, BriefingRoutingModalType.DUPLICATE];
    if (briefingId && prefillTypes.includes(type)) {
        dispatch(
            prefillBriefingRoutingForm(briefingId, type === BriefingRoutingModalType.DUPLICATE)
        );
    }
    overlayStore.open(overlayIdEnum.BRIEFING_ROUTING_MODAL, {
        modalType: type,
        briefingId,
    });
};

export const openBriefingAttachmentsSidePanel = (briefingId: number): RmsAction<void> => (
    dispatch,
    getState,
    { overlayStore }
) => {
    const attachmentEntityLinkDetails = {
        entityType: EntityTypeEnum.BRIEFING.name,
        entityId: briefingId,
        attachmentLinkType: LinkTypesEnum.ATTACHMENT_IN_BRIEFING,
    };
    dispatch(initializeAttachmentsSidePanelForm(attachmentEntityLinkDetails));
    overlayStore.open(overlayIdEnum.BRIEFING_ATTACHMENTS_SIDE_PANEL, attachmentEntityLinkDetails);
};

// SELECTORS
export const currentUserCanCreateBriefingTasksSelector = createSelector(
    applicationSettingsSelector,
    canEditTaskSelector,
    (applicationSettings, canEditTasks) =>
        applicationSettings.RMS_TASK_AND_REQUEST_TRACKING_ENABLED && canEditTasks
);
