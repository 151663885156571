import * as yup from 'yup';
import {
    Form,
    createField,
    createFormConfiguration,
    formEvents,
    InferFormDataShape,
} from 'markformythree';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../core/validation/yupMftValidation';
import { M43Asset } from '../../../diagram/types';

export const GLOBAL_DEPARTMENT_ID = 1;
export const formName = formClientEnum.DIAGRAM_ASSET_ADMIN_FORM;

const validationStrings = componentStrings.validation;

const validationSchema = yup.object().shape({
    name: yup.string().required(validationStrings.requiredError),
    fileId: yup.string().required(validationStrings.requiredError),
    imageUrl: yup.string().required(validationStrings.requiredError),
});

export const formConfiguration = createFormConfiguration({
    id: createField<number>({}),
    name: createField<string>({}),
    fileId: createField<number>({}),
    imageUrl: createField<string>({}),
    isGlobal: createField<boolean>({}),
    isDefault: createField<boolean>({}),
});

export type DiagramAssetAdminFormConfiguration = typeof formConfiguration;
type DiagramAssetAdminFormModel = InferFormDataShape<DiagramAssetAdminFormConfiguration>;

export const onValidate: Form['props']['onValidate'] = ({ formState }) =>
    validationSchema
        .validate(formState.model, { abortEarly: false })
        .then((validationResult) =>
            convertYupSuccessShapeToMFTSuccessShape(validationResult, formState.ui.$form)
        )
        .catch((validationErrors) =>
            convertYupErrorShapeToMFTErrorShape(validationErrors, formState.ui.$form)
        );

export const validationEvents = [
    {
        eventType: formEvents.FORM_SUBMIT,
    },
    {
        eventType: formEvents.INPUT_BLUR,
    },
];

export const convertToFormModel = (asset: Partial<M43Asset>): DiagramAssetAdminFormModel => {
    return {
        id: asset.id,
        name: asset.name,
        fileId: asset.file?.id,
        imageUrl: asset.file?.fileAppServerPath,
        isGlobal: asset.departmentId === GLOBAL_DEPARTMENT_ID,
        isDefault: asset.isDefault,
    };
};

export const convertFromFormModel = (
    formModel: DiagramAssetAdminFormModel,
    diagramCategoryId: number
) => {
    const { id, fileId, name, isGlobal, isDefault } = formModel;
    if (!fileId || !name) {
        return undefined;
    }
    return {
        id,
        fileId,
        name,
        diagramCategoryId,
        isGlobal: !!isGlobal,
        isDefault: !!isDefault,
    };
};
