import _, { compact, get } from 'lodash';
import { createSelector } from 'reselect';

import {
    formatStrings,
    getProfileUrlForUser,
    getFullName,
    isUserActive,
    mapOtherUserToOption,
    mapUserToOption,
    otherUserId,
} from '~/client-common/helpers/userHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { userSearchResultsSelector } from '~/client-common/core/domain/user-search-results/state/data';

const userDataSelector = (state) => state.data.user;

export const userProfileSelector = createSelector(userDataSelector, ({ profile }) => profile);

export const userSsoLogoutUrlSelector = createSelector(
    userDataSelector,
    (userData) => userData.ssoLogoutUrl
);

export const userInitialsSelector = createSelector(userProfileSelector, (userProfile) =>
    formatStrings.getUserInitials(userProfile)
);

export const userProfileUrlSelector = createSelector(userProfileSelector, (userProfile) =>
    getProfileUrlForUser(userProfile)
);

export const userDepartmentIdSelector = createSelector(
    userProfileSelector,
    (userProfile) => userProfile.departmentId
);

/**
 * Format the display string for a user(s), including their badge number.
 *
 * Must match {@link printing/src/csv/selectors/userSelectors.js}.
 * @param  {number|number[]} id
 * @return {string}
 */
export const formatUserByIdSelector = createSelector(
    userSearchResultsSelector,
    formatMiniUserByIdSelector,
    applicationSettingsSelector,
    (userSearchResults, formatMiniUserById, applicationSettings) => {
        const isEvidenceFiltersEnabled = !!applicationSettings.EVIDENCE_FILTER_SORT_UPDATES;
        const formatUserById = (id) =>
            get(userSearchResults, id)
                ? getFullName(userSearchResults[id], true, isEvidenceFiltersEnabled)
                : formatMiniUserById(id);

        return (id) =>
            !_.isArray(id)
                ? formatUserById(id)
                : joinTruthyValues(_(id).map(formatUserById).sortBy().value());
    }
);

/**
 * User typeahead options. Besides grabbing all cached user search results from
 *   state, this is a function that takes in additional user id(s) because
 *   typeaheads can initialize with user ids independent of any user searches
 *   being made (say from saved search), and those user ids need to be formatted
 *   into options using mini user state instead.
 * @param  {number|number[]}    [additionalIds]
 * @param  {Object}             [options]
 *         {boolean}            includeOther    Include hardcoded "OTHER" user.
 *         {string}             effectiveDate   Moment date string
 * @return {Object[]}
 */

export const userOptionsSelector = createSelector(
    userSearchResultsSelector,
    formatUserByIdSelector,
    (userSearchResults, formatUserById) =>
        (
            additionalIds = [],
            { includeOther, effectiveDate, excludeUsersWithInactiveDutyStatus = true } = {}
        ) => {
            // convert to array
            additionalIds = compact([].concat(additionalIds));

            // RMS-5515 -- For `additionalIds` that are not contained in
            // `userSearchResults` state, we hardcode these users as active.
            // The "OTHER" user is always hardcoded as active.
            return _(userSearchResults)
                .pickBy((userSearchResult) =>
                    effectiveDate && excludeUsersWithInactiveDutyStatus
                        ? isUserActive(userSearchResult, effectiveDate)
                        : true
                )
                .map('id')
                .union(additionalIds)
                .filter((id) => id !== otherUserId)
                .map((id) => mapUserToOption(id, userSearchResults, formatUserById))
                .sortBy(['status', 'display']) // sort by active users than alphabetical
                .concat(includeOther ? mapOtherUserToOption() : null)
                .compact()
                .value();
        }
);
