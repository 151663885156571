import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { size } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatReportTitleForReportIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { reportsSelector } from '~/client-common/core/domain/reports/state/data';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { updateUploads } from '../../../attachments/core/state/ui/inlineAttachmentsUploader';
import { isProcessingUploadingAttachmentsSelector } from '../../../attachments/core/state/ui/sharedAttachmentsSelectors';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import Page from '../../../core/components/Page';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import Subheader from '../../../core/components/Subheader';
import reportSealingForm from '../state/forms/reportSealingForm';
import {
    submitReportSealingForm,
    cleanupReportSealing,
    reportSealingReportLoadingStateSelector,
    reportSealingIsSubmittingSelector,
    chargesForArrestReportIdSelector,
} from '../state/ui';
import { InlineBanner } from '../../../core/components/InlineBanner';
import ReportSealingForm from './ReportSealingForm';
import ReportPartialSealingForm from './ReportPartialSealingForm';
import SealingActionBar from './SealingActionBar';

class SealingDashboard extends React.Component {
    componentWillUnmount() {
        this.props.resetState();
    }

    render() {
        const {
            routeParams,
            onFormSubmit,
            reportLoadingState,
            formatReportTitleForReportId,
            chargesForArrestReportId,
            reportSealingIsSubmitting,
            formModelByPath,
            reports,
            isProcessingUploadingAttachments,
        } = this.props;
        const { reportId } = routeParams;
        const backUrl = `/reports/${reportId}`;
        const { isLoading, loadingError, reportLoaded } = reportLoadingState;
        const title = reportLoaded ? formatReportTitleForReportId(parseInt(reportId)) : '';
        const arrestCharges = reportLoaded ? chargesForArrestReportId(parseInt(reportId)) : [];
        const hasChargesToSeal = !!arrestCharges.length;

        const hasPersonIdToSeal = !!formModelByPath('personIdToSeal');
        const isStatuteSealing = formModelByPath('sealingType');
        const personIdsToStatuteSeal = formModelByPath('statuteSealingPersonIdsToSeal') ?? [];
        const hasChargeIdsToSeal = !!size(formModelByPath('chargeIdsToSeal'));

        const isSealButtonDisabled =
            isProcessingUploadingAttachments ||
            reportSealingIsSubmitting ||
            (!hasPersonIdToSeal && !hasChargeIdsToSeal && !isStatuteSealing) ||
            (isStatuteSealing && personIdsToStatuteSeal.length === 0);

        return (
            <Page>
                <Subheader
                    title={componentStrings.recordPrivacy.sealing.SealingDashboard.title(title)}
                    backButtonTo={backUrl}
                />
                <ScrollableUnderSubheader forceNoClassName={true}>
                    {isLoading && <SimpleLoading />}
                    {loadingError && <InlineBanner status="error">{loadingError}</InlineBanner>}
                    {reportLoaded && (
                        <FeatureFlagged
                            flag="RMS_PARTIAL_NARRATIVE_SEALING_ENABLED"
                            fallback={<ReportSealingForm report={reports[reportId]} />}
                        >
                            <ReportPartialSealingForm report={reports[reportId]} />
                        </FeatureFlagged>
                    )}
                </ScrollableUnderSubheader>
                {reportLoaded && (
                    <SealingActionBar
                        personToSealSelected={hasPersonIdToSeal}
                        chargeToSealSelected={hasChargeIdsToSeal}
                        hasChargesToSeal={hasChargesToSeal}
                        sealButtonDisabled={isSealButtonDisabled}
                        onSealClick={onFormSubmit}
                        backUrl={backUrl}
                    />
                )}
            </Page>
        );
    }
}

export default connect(
    createStructuredSelector({
        isProcessingUploadingAttachments: isProcessingUploadingAttachmentsSelector,
        formIsValid: reportSealingForm.selectors.formIsValidSelector,
        formModelByPath: reportSealingForm.selectors.formModelByPathSelector,
        reportLoadingState: reportSealingReportLoadingStateSelector,
        formatReportTitleForReportId: formatReportTitleForReportIdSelector,
        chargesForArrestReportId: chargesForArrestReportIdSelector,
        reportSealingIsSubmitting: reportSealingIsSubmittingSelector,
        reports: reportsSelector,
    }),
    (dispatch, props) => ({
        onFormSubmit: () => dispatch(submitReportSealingForm({ router: props.router })),
        resetState: () => {
            dispatch(reportSealingForm.actionCreators.reset());
            dispatch(cleanupReportSealing());
            // reset attachment uploader
            dispatch(updateUploads([]));
        },
    })
)(SealingDashboard);
