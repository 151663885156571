import { RoleTypeEnum } from '@mark43/rms-api';

import React from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFRoleSelect } from '../../../core/forms/components/selects/RoleSelect';
import Row from '../../../core/components/Row';

import taskDetailsForm from '../state/forms/taskDetailsForm';

const strings = componentStrings.cases.taskList.TaskDetailsForm;

const TaskDetailsForm = (): JSX.Element => {
    return (
        <ReactReduxForm {...taskDetailsForm}>
            <Row>
                <RRFText label={strings.description} width={240} path="description" />
            </Row>
            <Row>
                <RRFRoleSelect
                    label={strings.assignee}
                    width={240}
                    path="assigneeRoleId"
                    roleTypes={[RoleTypeEnum.USER.name]}
                />
            </Row>
            <Row>
                <RRFDatePicker label={strings.dueDate} path="dueDateUtc" width={240} />
            </Row>
            <Row>
                <RRFAttributeSelect
                    label={strings.status}
                    attributeType="TASK_STATUS"
                    width={240}
                    path="statusAttrId"
                />
            </Row>
        </ReactReduxForm>
    );
};

export default TaskDetailsForm;
