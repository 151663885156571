import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { max } from 'lodash';
import {
    ElasticCourtOrder,
    SearchQueryObject,
    ElasticCobaltSortTypeEnumType,
    ElasticCobaltSortKeyEnumType,
} from '@mark43/rms-api';

import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import * as fields from '~/client-common/core/enums/universal/fields';

import { RmsDispatch } from '../../../../core/typings/redux';
import AdvancedSearchResultsTableHeader from '../../core/components/AdvancedSearchResultsTableHeader';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import ModifiedDateCell from '../../../../legacy-redux/components/core/tables/elasticCourtOrdersTable/ModifiedDateCell';
import ArrestNumberCell from '../../../../legacy-redux/components/core/tables/elasticCourtOrdersTable/ArrestNumberCell';
import InvolvedPersonCell from '../../../../legacy-redux/components/core/tables/elasticCourtOrdersTable/InvolvedPersonCell';
import StateIdNumberCell from '../../../../legacy-redux/components/core/tables/elasticCourtOrdersTable/StateIdNumberCell';
import AttachmentCell from '../../../../legacy-redux/components/core/tables/elasticCourtOrdersTable/AttachmentCell';
import CourtCaseNumberCell from '../../../../legacy-redux/components/core/tables/elasticCourtOrdersTable/CourtCaseNumberCell';
import CourtCodeCell from '../../../../legacy-redux/components/core/tables/elasticCourtOrdersTable/CourtCodeCell';
import CourtOrderDateCell from '../../../../legacy-redux/components/core/tables/elasticCourtOrdersTable/CourtOrderDateCell';
import ReportEventNumberCell from '../../../../legacy-redux/components/core/tables/elasticCourtOrdersTable/ReportEventNumberCell';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';
import advancedSearchCourtOrders from '../state/ui';
import StatuteCodeCell from '../../../../legacy-redux/components/core/tables/elasticCourtOrdersTable/StatuteCodeCell';

const strings = componentStrings.search.AdvancedSearchCourtOrdersResults;
// 107 is the height of the table header
// todo refactor to genericize
const ClippableSearchTable = clippable({ bottomBuffer: 207 })(Table);

type SortOptionType = {
    sortType: ElasticCobaltSortTypeEnumType;
};

type ActiveOptionType = {
    display: string;
    sortOptions: SortOptionType[];
    value: ElasticCobaltSortKeyEnumType;
};

type ActiveColumnKeysType = {
    date: 'courtOrderDate' | 'modifiedDate';
    number: 'reportEventNumber' | 'arrestNumber' | 'courtCaseNumber' | 'statuteCode';
};

const columnKeyToValueMap = {
    courtOrderDate: sortKeyEnum.COURT_ORDER_COURT_CASE_DATE_UTC,
    modifiedDate: sortKeyEnum.COURT_ORDER_MODIFIED_DATE_UTC,
};

const AdvancedSearchCourtOrdersResults: React.FC = () => {
    const query: SearchQueryObject = useSelector(
        advancedSearchCourtOrders.selectors.currentQuerySelector
    );
    const results: ElasticCourtOrder[] = useSelector(
        advancedSearchCourtOrders.selectors.currentResultsSelector
    );
    const totalResults = useSelector(advancedSearchCourtOrders.selectors.totalCountSelector);
    const tableLoading = useSelector(advancedSearchCourtOrders.selectors.tableLoadingSelector);
    const searchFilters = useSelector(advancedSearchCourtOrders.selectors.filtersSelector);
    const selectedRows = useSelector(advancedSearchCourtOrders.selectors.selectedRowsSelector);
    const highlightedRows = useSelector(
        advancedSearchCourtOrders.selectors.highlightedRowsSelector
    );
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const activeColumnKeys: ActiveColumnKeysType = useSelector(
        advancedSearchCourtOrders.selectors.activeColumnKeysSelector
    );
    const { date: activeDateColumnKey, number: activeNumberColumnKey } = activeColumnKeys;

    const dispatch: RmsDispatch = useDispatch();
    function handleRowSelect(newSelectedRows: number[]) {
        dispatch(advancedSearchCourtOrders.actionCreators.selectRows(newSelectedRows));
    }

    function handlePaginationClick(nextPage: number, size: number) {
        dispatch(
            advancedSearchCourtOrders.actionCreators.search({
                from: nextPage * size - size,
                size,
            })
        );
    }
    function handleSizeChange(size: string) {
        dispatch(advancedSearchCourtOrders.actionCreators.search({ from: 0, size }));
    }

    function handleSortBySortTypeClick(
        sortType: ElasticCobaltSortTypeEnumType,
        activeOption: ActiveOptionType
    ) {
        dispatch(
            advancedSearchCourtOrders.actionCreators.search({
                sortKey: activeOption.value,
                sortType,
            })
        );
    }

    function handleDateOptionClick({
        columnKey,
        value,
        sortOptions,
    }: {
        columnKey: string;
        value: ElasticCobaltSortKeyEnumType;
        sortOptions: SortOptionType[];
    }) {
        dispatch(advancedSearchCourtOrders.actionCreators.setActiveColumnKey('date', columnKey));
        dispatch(
            advancedSearchCourtOrders.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    }

    function handleNumberOptionClick({ value }: { value: string }) {
        dispatch(advancedSearchCourtOrders.actionCreators.setActiveColumnKey('number', value));
    }

    const { from = 0, size = 25, sortKey: currentSortKey, sortType: currentSortType } = query;

    const currentPage = max([1, fromSizeToPage(from + 1, size)]);

    return (
        <div>
            <ClippableSearchTable
                data={results}
                sortKey={currentSortKey}
                sortType={currentSortType}
                noRowsText={strings.noResults}
                disableBody={tableLoading}
                selectableRows={true}
                selectedRows={selectedRows}
                highlightedRows={highlightedRows}
                onSelectRow={handleRowSelect}
                containerClassName="search-results-table"
                rowComponent={ElasticResultRow}
                disableRowSelectWhen={(row: ElasticCourtOrder) =>
                    row && !canRead(row.permissionSet)
                }
                tableCheckboxMargin="10px"
            >
                <TableHeader>
                    <AdvancedSearchResultsTableHeader
                        from={from}
                        to={from + results?.length}
                        searchSize={size}
                        onSizeChange={handleSizeChange}
                        totalResults={totalResults}
                        currentPage={currentPage}
                        searchFilters={searchFilters}
                        searchModule={advancedSearchCourtOrders}
                        isSearchSavable={false}
                        onPaginationClick={(lastPage: number, nextPage: number) =>
                            handlePaginationClick(nextPage, size)
                        }
                    />
                    <TableColumns>
                        <OptionsTableColumn
                            display={({ display }: { display: string }) => display}
                            columnKey={activeDateColumnKey}
                            activeValue={columnKeyToValueMap[activeDateColumnKey]}
                            activeSortType={
                                currentSortKey === columnKeyToValueMap[activeDateColumnKey]
                                    ? currentSortType
                                    : undefined
                            }
                            onOptionClick={handleDateOptionClick}
                            onSortTypeClick={handleSortBySortTypeClick}
                            width={190}
                            popoutWidth={310}
                        >
                            <OptionsTableColumnOption
                                display={strings.courtOrderDate.label}
                                columnKey="courtOrderDate"
                                value={sortKeyEnum.COURT_ORDER_COURT_CASE_DATE_UTC}
                                sortOptions={[
                                    {
                                        display: strings.courtOrderDate.ascendingSort,
                                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                    },
                                    {
                                        display: strings.courtOrderDate.descendingSort,
                                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                    },
                                ]}
                            />
                            <OptionsTableColumnOption
                                display={strings.modifiedDate.label}
                                columnKey="modifiedDate"
                                value={sortKeyEnum.COURT_ORDER_MODIFIED_DATE_UTC}
                                sortOptions={[
                                    {
                                        display: strings.modifiedDate.ascendingSort,
                                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                    },
                                    {
                                        display: strings.modifiedDate.descendingSort,
                                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                    },
                                ]}
                            />
                        </OptionsTableColumn>
                        <OptionsTableColumn
                            display={({ display }: { display: string }) => display}
                            columnKey={activeNumberColumnKey}
                            activeValue={activeNumberColumnKey}
                            onOptionClick={handleNumberOptionClick}
                            width={148}
                            popoutWidth={310}
                        >
                            <OptionsTableColumnOption
                                display={strings.reportEventNumber.label}
                                value="reportEventNumber"
                            />
                            <OptionsTableColumnOption
                                display={strings.arrestNumber.label}
                                value="arrestNumber"
                            />
                            <OptionsTableColumnOption
                                display={strings.courtCaseNumber.label}
                                value="courtCaseNumber"
                            />
                            <OptionsTableColumnOption
                                display={strings.statuteCode.label}
                                value="statuteCode"
                            />
                        </OptionsTableColumn>
                        <TableColumn
                            display={formatFieldByName(fields.COURT_ORDER_COURT_CODE_ATTR_ID)}
                            columnKey="courtCode"
                            width={145}
                        />
                        <TableColumn
                            display={strings.involvedPerson.label}
                            columnKey="involvedPerson"
                            width={152}
                        />
                        <TableColumn
                            display={formatFieldByName(fields.COURT_ORDER_STATE_ID_NUMBER)}
                            columnKey="stateIdNumber"
                            width={140}
                        />
                        <TableColumn
                            display={strings.attachment.label}
                            columnKey="attachment"
                            width={148}
                        />
                    </TableColumns>
                </TableHeader>
                <TableBody>
                    <ModifiedDateCell columnKey="modifiedDate" />
                    <ReportEventNumberCell columnKey="reportEventNumber" />
                    <ArrestNumberCell columnKey="arrestNumber" />
                    <StatuteCodeCell columnKey="statuteCode" />
                    <CourtCaseNumberCell columnKey="courtCaseNumber" />
                    <CourtOrderDateCell columnKey="courtOrderDate" />
                    <CourtCodeCell columnKey="courtCode" />
                    <InvolvedPersonCell columnKey="involvedPerson" />
                    <StateIdNumberCell columnKey="stateIdNumber" />
                    <AttachmentCell columnKey="attachment" />
                </TableBody>
            </ClippableSearchTable>
        </div>
    );
};

export default AdvancedSearchCourtOrdersResults;
