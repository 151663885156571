import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, IconButton, Text, Flex, CreatableSelect, SelectChangeEvent } from 'arc';
import styled from 'styled-components';
import { parse, NodeType } from 'node-html-parser';
import type { Node } from 'node-html-parser';
import { PersonProfile } from '@mark43/rms-api';

import strings from '~/client-common/core/strings/componentStrings';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { NarrativeContainer } from '../../core/components/RecordPrivacyContainers';
import { REPORT_SEALING_PARTIAL_NARRATIVE_UPDATE_SEALS } from '../state/ui';

const narrativeStrings = strings.recordPrivacy.sealing.NarrativeSection;

const PreviewBox = styled.div`
    height: 400px;
    overflow: scroll;
    padding: 5px;
    border: 1px solid #888;
`;

const EditorBox = styled(NarrativeContainer)`
    height: 400px;
`;

const PopupBox = styled.div`
    position: fixed;
    padding: var(--arc-space-1) var(--arc-space-3);
    background-color: var(--arc-colors-background-primary);
    border: var(--arc-borders-sm) var(--arc-colors-control-border-default);
    border-radius: var(--arc-radii-md);
    box-shadow: var(--arc-shadows-lg);
`;

enum SealType {
    Default,
    Highlight,
    Seal,
    Preview,
    HighlightSeal,
    HighlightSelect,
    HighlightSealSelect,
}
interface TextMarker {
    text: string;
    start: number;
    end: number;
}
interface SwapMarker extends TextMarker {
    type: SealType;
}

interface PartialSealingNarrativeEditorProps {
    narrative?: string;
    involvedPersons: PersonProfile[];
}

const PartialSealingNarrativeEditor = (props: PartialSealingNarrativeEditorProps) => {
    const dispatch = useDispatch();
    const [textInput, setTextInput] = useState<string>('');
    const [preview, setPreview] = useState<boolean>(false);
    const [displayedNarrative, setDisplayedNarrative] = useState<string>(props.narrative || '');
    const [matchCount, setMatchCount] = useState<number>(0);
    const [selectedNum, setSelectedNum] = useState<number>(0);
    const [seals, setSeals] = useState<{ [word: string]: TextMarker[] }>({});
    const [highlights, setHighlights] = useState<TextMarker[]>([]);
    const [selectOptions, setSelectOptions] = useState<{ label: string; value: string }[]>([]);
    const [popupData, setPopupData] = useState<{
        x: number;
        y: number;
        start: number;
        type: string;
    } | null>(null);

    function sanitizeInput(input: string): string {
        const element = document.createElement('div');
        element.innerText = input;
        return element.innerHTML;
    }

    function updateSelectOptions(newValue: string): void {
        // prevent searching for "sealed" word
        if (newValue.match(/sealed/i)) {
            setSelectedNum(0);
            return;
        }
        // update select options to include new sealed word
        const newSelectOptions = [...selectOptions];
        let alreadyIncluded = false;
        newSelectOptions.forEach(({ value }) => {
            if (value === newValue) {
                alreadyIncluded = true;
            }
        });
        if (!alreadyIncluded) {
            newSelectOptions.push({ label: newValue, value: newValue });
            setSelectOptions(newSelectOptions);
        }
        setSelectedNum(0);
    }

    function handleSelectChange(e: SelectChangeEvent<{ label: string; value: string }>): void {
        const value: string = e.target.value;
        if (value === '') {
            setTextInput('');
        } else {
            // use sanitized input
            const sanitized = sanitizeInput(value);
            setTextInput(sanitized);
            updateSelectOptions(sanitized);
        }
    }

    function togglePreview(): void {
        setPreview((prev) => !prev);
    }

    function selectNextOrPrevious(next: boolean): void {
        // update displayed value for selected number
        setSelectedNum((prev) => {
            const newValue = next ? prev + 1 : prev - 1;
            if (newValue < 0) {
                return 0;
            }
            if (newValue > matchCount) {
                return matchCount;
            }
            return newValue;
        });
    }

    function sealWord(index: number, startIndex?: number): void {
        if (!textInput) {
            return;
        }
        const hl =
            index >= 0 ? highlights[index] : highlights.find(({ start }) => start === startIndex);
        if (!hl) {
            return;
        }
        const newSeals = { ...seals };
        if (newSeals[textInput]) {
            const alreadySealed = newSeals[textInput].find(({ start }) => start === hl.start);
            if (!!alreadySealed) {
                return;
            } else {
                newSeals[textInput].push(hl);
            }
        } else {
            newSeals[textInput] = [hl];
        }
        setSeals(newSeals);
    }

    function sealAll(): void {
        if (!textInput) {
            return;
        }
        const newSeals = { ...seals };
        newSeals[textInput] = [...highlights];
        setSeals(newSeals);
    }

    function unsealWord(index: number, start?: number): void {
        if (!textInput) {
            return;
        }
        const startIndex = start || highlights[index].start;
        const newSeals = { ...seals };
        if (newSeals[textInput]) {
            newSeals[textInput] = newSeals[textInput].filter(({ start }) => start !== startIndex);
        }
        setSeals(newSeals);
    }

    function isUnsealButtonDisabled(): boolean {
        let isDisabled = true;
        if (selectedNum - 1 < 0 || selectedNum - 1 > matchCount) {
            return true;
        }
        if (!textInput) {
            return isDisabled;
        }
        if (seals[textInput]) {
            const selectedStartIndex = highlights[selectedNum - 1]?.start || 0;
            const currentPosition = seals[textInput]?.find(
                ({ start }) => start === selectedStartIndex
            );
            if (currentPosition) {
                isDisabled = false;
            }
        }
        return isDisabled;
    }

    // create markers to designate what text to swap out
    function createSwapMarkers(
        base: string,
        searchTerm: string,
        sealType: SealType,
        selectedStart: number,
        rootIndex: number,
        sealStartPositions?: number[]
    ): SwapMarker[] {
        const regex = new RegExp(`\\b${searchTerm}\\b`, 'gi');
        const matches = [...base.matchAll(regex)];
        const markers: SwapMarker[] = [];

        matches.forEach((match) => {
            const text = match[0];
            const start = rootIndex + (match?.index || 0);
            const end = start + text.length;
            if (sealStartPositions && !sealStartPositions.includes(start)) {
                return;
            }
            let type = sealType;
            if (selectedStart === start) {
                switch (sealType) {
                    case SealType.Highlight:
                        type = SealType.HighlightSelect;
                        break;
                    case SealType.HighlightSeal:
                        type = SealType.HighlightSealSelect;
                        break;
                    default:
                        break;
                }
            }
            markers.push({ text, start, end, type });
        });

        return markers;
    }

    // replace marked text with appropriate styling
    function replaceText(strippedBase: string, markers: SwapMarker[]): string {
        const sortedMarkers = markers.sort((a, b) => (a.start > b.start ? -1 : 1));
        sortedMarkers.forEach((marker) => {
            let replacement = marker.text;
            switch (marker.type) {
                case SealType.Highlight:
                    replacement = `<span
                            style="background-color:#ffde64;"
                            class="highlighted-word"
                            data-start="${marker.start}"
                        >${marker.text}</span>`;
                    break;
                case SealType.Seal:
                    replacement = `<span style="text-decoration:line-through;">${marker.text}</span>`;
                    break;
                case SealType.Preview:
                    replacement = `[SEALED]`;
                    break;
                case SealType.HighlightSeal:
                    replacement = `<span
                            style="background-color:#ffde64;text-decoration:line-through;"
                            class="sealed-word"
                            data-start="${marker.start}"
                        >${marker.text}</span>`;
                    break;
                case SealType.HighlightSelect:
                    replacement = `<span
                            style="background-color:#ffde64;border-bottom:2px solid #ff0000;"
                            class="highlighted-word"
                            data-start="${marker.start}"
                            id="selected-word"
                        >${marker.text}</span>`;
                    break;
                case SealType.HighlightSealSelect:
                    replacement = `<span
                            style="background-color:#ffde64;border-bottom:2px solid #ff0000;text-decoration:line-through;"
                            class="sealed-word"
                            data-start="${marker.start}"
                            id="selected-word"
                        >${marker.text}</span>`;
                    break;
                default:
                    break;
            }
            strippedBase =
                strippedBase.slice(0, marker.start) + replacement + strippedBase.slice(marker.end);
        });
        return strippedBase;
    }

    // remove duplicates
    function removeSealedWordsFromHighlights(
        sealedWords: SwapMarker[],
        highlights: SwapMarker[]
    ): SwapMarker[] {
        // highlight is valid if: start is not within sealed word range && end is not within sealed word range
        return highlights.filter((highlight) => {
            const { start, end } = highlight;
            const isWithinSealedWord = sealedWords.some(({ start: s, end: e }) => {
                if (start >= s && start <= e) {
                    return true;
                }
                if (end >= s && end <= e) {
                    return true;
                }
                return false;
            });
            return !isWithinSealedWord;
        });
    }

    // handle dynamic seal/unseal pop-up events
    function clickHandler(e: PointerEvent): void {
        if (e.target && e.target instanceof HTMLElement) {
            const target = e.target;
            const start = target.getAttribute('data-start') || '-1';
            if (target.classList.contains('highlighted-word')) {
                setPopupData({ x: e.clientX, y: e.clientY, start: Number(start), type: 'seal' });
                return;
            } else if (target.classList.contains('sealed-word')) {
                setPopupData({ x: e.clientX, y: e.clientY, start: Number(start), type: 'unseal' });
                return;
            }
        }
        setPopupData(null);
    }

    // recursively extract text from dom model
    function extractTextFromDom(node: Node, output: TextMarker[] = []) {
        if (node.nodeType === NodeType.TEXT_NODE) {
            output.push({ text: node.rawText, start: node.range[0], end: node.range[1] });
        }
        if (node.childNodes) {
            node.childNodes.forEach((child) => {
                extractTextFromDom(child, output);
            });
        }
    }

    // initialize click listener for dynamic pop-up
    useEffect(() => {
        document.addEventListener('click', clickHandler as EventListener);
        return () => {
            document.removeEventListener('click', clickHandler as EventListener);
        };
    }, []);

    // fill select options with person names
    useEffect(() => {
        const newOptions: { label: string; value: string }[] = [];
        props.involvedPersons.forEach((person) => {
            if (!person.firstName && !person.lastName) {
                return;
            }
            // build name string with information available
            const nameStr = formatFullName(person);
            newOptions.push({ label: nameStr, value: nameStr });
        });
        setSelectOptions(newOptions);
    }, [props.involvedPersons]);

    // re-create narrative string when props change
    useEffect(() => {
        // strip dom of all html tags
        const dom: Node = parse(props.narrative || '');
        const narrativeArr: TextMarker[] = [];
        extractTextFromDom(dom, narrativeArr);
        // vars for swap markers
        let modified = props.narrative || '';
        let sealedMarkers: SwapMarker[] = [];
        let highlightMarkers: SwapMarker[] = [];
        const selectedStartIndex = highlights[selectedNum - 1]?.start || 0;
        // find all sealed words
        Object.entries(seals).forEach(([word, markers]) => {
            let sealType = SealType.Seal;
            if (preview) {
                sealType = SealType.Preview;
            } else if (word === textInput) {
                sealType = SealType.HighlightSeal;
            }
            const positionStarts = markers.map(({ start }) => start);
            narrativeArr.forEach((narrativePiece) => {
                const { text, start } = narrativePiece;
                const newMarkers = createSwapMarkers(
                    text,
                    word,
                    sealType,
                    selectedStartIndex,
                    start,
                    positionStarts
                );
                sealedMarkers = sealedMarkers.concat(newMarkers);
            });
        });
        // find all highlighted words
        if (textInput) {
            const sealType = preview ? SealType.Default : SealType.Highlight;
            narrativeArr.forEach((narrativePiece) => {
                const { text, start } = narrativePiece;
                const newMarkers = createSwapMarkers(
                    text,
                    textInput,
                    sealType,
                    selectedStartIndex,
                    start
                );
                highlightMarkers = highlightMarkers.concat(newMarkers);
            });
            // remove duplicates between sealed words and highlighted words
            highlightMarkers = removeSealedWordsFromHighlights(sealedMarkers, highlightMarkers);
        }
        // replace marked words
        const mergedMarkers = [...sealedMarkers, ...highlightMarkers];
        modified = replaceText(modified, mergedMarkers);
        // extract highlighted words from full markers list
        const validHighlights = mergedMarkers.reverse().filter(({ type }) => {
            if (
                type === SealType.Highlight ||
                type === SealType.HighlightSelect ||
                type === SealType.HighlightSeal ||
                type === SealType.HighlightSealSelect
            ) {
                return true;
            }
            return false;
        });
        setMatchCount(validHighlights.length);
        setHighlights(validHighlights);
        // build seals into array for store
        const sealArray: TextMarker[] = [];
        Object.values(seals).forEach((markers) => {
            sealArray.push(...markers);
        });
        dispatch({
            type: REPORT_SEALING_PARTIAL_NARRATIVE_UPDATE_SEALS,
            payload: sealArray,
        });
        // update displayed narrative
        setDisplayedNarrative(modified);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textInput, seals, selectedNum, preview, props.narrative]);

    // scroll action needs to happen AFTER re-render
    useEffect(() => {
        // scroll ui
        const element = document.getElementById('selected-word');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [displayedNarrative]);

    return (
        <div>
            <CreatableSelect
                value={{ label: textInput, value: textInput }}
                onChange={handleSelectChange}
                createOptionPosition="first"
                formatCreateLabel={(inputValue) => `Search for \"${inputValue}\"`}
                placeholder={narrativeStrings.fieldPlaceholder}
                options={selectOptions}
                isClearable
            />
            {textInput !== '' && (
                <Flex gap={1} marginTop={3}>
                    <div style={{ flexGrow: 1 }} />
                    <Text variant="bodyMd" lineHeight="inherit" style={{ margin: 'auto' }}>
                        {selectedNum}/{matchCount}
                    </Text>
                    <IconButton
                        aria-label="Previous"
                        icon="ArrowUp"
                        variant="ghost"
                        onClick={() => selectNextOrPrevious(false)}
                    />
                    <IconButton
                        aria-label="Next"
                        icon="ArrowDown"
                        variant="ghost"
                        onClick={() => selectNextOrPrevious(true)}
                    />
                </Flex>
            )}
            <Flex gap={1} marginTop={3} marginBottom={1}>
                <Button variant="ghost" trailingVisual="Visibility" onClick={togglePreview}>
                    {narrativeStrings.preview}
                </Button>
                <div style={{ flexGrow: 1 }} />
                <Button
                    variant="ghost"
                    isDisabled={isUnsealButtonDisabled()}
                    onClick={() => unsealWord(selectedNum - 1)}
                >
                    {narrativeStrings.unseal}
                </Button>
                <Button onClick={() => sealAll()}>{narrativeStrings.sealAll}</Button>
                <Button onClick={() => sealWord(selectedNum - 1)}>{narrativeStrings.seal}</Button>
            </Flex>
            {preview ? (
                <PreviewBox
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: displayedNarrative }}
                />
            ) : (
                <EditorBox
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: displayedNarrative }}
                />
            )}
            {popupData && (
                <PopupBox style={{ top: popupData.y, left: popupData.x }}>
                    {popupData.type === 'seal' ? (
                        <Button variant="ghost" onClick={() => sealWord(-1, popupData.start)}>
                            {narrativeStrings.seal}
                        </Button>
                    ) : (
                        <Button variant="ghost" onClick={() => unsealWord(-1, popupData.start)}>
                            {narrativeStrings.unseal}
                        </Button>
                    )}
                </PopupBox>
            )}
        </div>
    );
};

export default PartialSealingNarrativeEditor;
