import { useDispatch, useSelector } from 'react-redux';
import max from 'lodash/max';
import { BriefingSimpleView, SqlSearchQueryBriefingSearchSqlQuery } from '@mark43/rms-api';
import { SelectChangeEvent } from '@arc/select';
import { DataTableProps } from '@arc/data-table';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import { briefingsDashboardSearch, searchBriefingDashboard } from '../state/ui';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { RmsDispatch } from '../../../core/typings/redux';
import { queryParamDefaults } from '../configuration';

const { currentQuerySelector, totalCountSelector } = briefingsDashboardSearch.selectors;

export const useBriefingsPagination = (): DataTableProps<BriefingSimpleView>['paginationProps'] => {
    const dispatch = useDispatch<RmsDispatch>();
    const { from = queryParamDefaults.FROM, size = queryParamDefaults.SIZE } = useSelector<
        RootState,
        SqlSearchQueryBriefingSearchSqlQuery
    >(currentQuerySelector);
    const totalSize = useSelector<RootState, number>(totalCountSelector);

    const currentPage = max([1, fromSizeToPage(from + 1, size)]) ?? 1;
    const handlePageChange = (page: number) => {
        dispatch(searchBriefingDashboard({ from: page * size - size, size }));
    };

    const handleSizeChange = (newSize: number) => {
        dispatch(searchBriefingDashboard({ from: 0, size: newSize }));
    };

    const handleNextPage = () => {
        handlePageChange(currentPage + 1);
    };

    const handlePrevPage = () => {
        handlePageChange(currentPage - 1);
    };

    const handlePageIndexChange = (e: SelectChangeEvent) => {
        handlePageChange(Number(e.target.value) + 1);
    };

    const handlePageSizeChange = (e: SelectChangeEvent) => {
        handleSizeChange(Number(e.target.value));
    };

    return {
        pageIndex: currentPage - 1,
        pageSize: size,
        totalSize,
        onPageIndexChange: totalSize >= size ? handlePageIndexChange : undefined,
        onPageSizeChange: handlePageSizeChange,
        onNextPage: handleNextPage,
        onPrevPage: handlePrevPage,
    };
};
