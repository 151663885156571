import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { includes, noop, size } from 'lodash';
import {
    EntityTypeEnum,
    OperationTypeEnum,
    EntityPermissionView,
    ReportStatusView,
} from '@mark43/rms-api';

import { DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { canManage } from '~/client-common/core/domain/entity-permissions/state/ui';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { reportDefinitionRestrictViewReportOwnersSelector } from '~/client-common/core/domain/report-definitions/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { RmsDispatch } from '../../../../core/typings/redux';
import HelpText from '../../../../legacy-redux/components/core/HelpText';
import EntityPermissionsMessage from '../../../../legacy-redux/components/security/EntityPermissionsMessage';
import {
    currentReportSelector,
    reportStatusViewSelector,
    canEditReportCardStatusSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import RecordsHeaderPermissionsButton from '../../../records/core/components/header/RecordsHeaderPermissionsButton';
import { openReportChangeOwnerModal } from '../../../reports/core/state/ui/core';
import { refreshReportApprovalStatusView } from '../../../reports/core/state/ui/submissions';
import { SecondarySectionHeader } from '../../components/typography';
import Modal from '../../overlays/components/Modal';
import {
    useOverlayResource,
    useOverlayResourceOnOpen,
} from '../../overlays/hooks/useOverlayResource';
import { useSetPermissionsForm } from '../hooks/useLoadPermissionsForOverlay';
import entityPermissionsResource from '../resources/entityPermissionsResource';
import {
    getFormNameForEntityType,
    saveEntityPermissionsForm,
    updatePermissions,
} from '../state/ui';
import { abilitiesEnum } from '../../abilities';
import { currentUserHasAbilitySelector } from '../../current-user/state/ui';
import { useIsReportOwnerRestricted } from '../../hooks/useIsReportOwnerRestricted';
import { RestrictVisibilityWithDash } from '../../components/RestrictVisibilityWithDash';
import RecordPermissionsForm from './RecordPermissionsForm';

interface ReportPermissionsModalProps {
    reportId: number;
    reportShortTitle: string;
}
interface LoadPermissionsProps {
    reportId: number;
    reportStatusView: ReportStatusView;
}

const formName = getFormNameForEntityType(EntityTypeEnum.REPORT.name);
const overlayId = overlayIdEnum.REPORT_PERMISSIONS_OVERLAY;
const strings = componentStrings.core.permissions.reportPermissionsModal;

const Section = styled.div`
    margin-bottom: 30px;
`;

// instead of useLoadPermissionsForOverlay to trigger load _once_.
// we want to trigger whenever reportStatusView changes, because modal includes
// link to ReportChangeOwnerModal which can change permissions.
// Using separate component because cannot use multiple useOverlayResource in same component
const LoadPermissions: React.FC<LoadPermissionsProps> = (props) => {
    const { reportId, reportStatusView } = props;

    const dispatch = useDispatch<RmsDispatch>();
    const resource = useCallback(() => {
        return entityPermissionsResource.getPermissions(
            EntityTypeEnum.REPORT.name,
            reportId,
            false
        );
    }, [reportId]);

    const setPermissionsForm = useSetPermissionsForm();
    const onSuccess = useCallback(
        (permissions: EntityPermissionView[]) => {
            dispatch(updatePermissions(EntityTypeEnum.REPORT.name, reportId, permissions));
            setPermissionsForm(formName, permissions);
        },
        [dispatch, reportId, setPermissionsForm]
    );

    const overlayResource = useOverlayResource<EntityPermissionView[]>({
        id: overlayId,
        onSuccess,
        resource,
    });

    useEffect(() => {
        overlayResource();
    }, [overlayResource, reportStatusView]);

    return null;
};

const Content: React.FC<ReportPermissionsModalProps> = (props) => {
    const { reportId } = props;
    const reportOwnerDisplayValue = useFields(DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL)[
        DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL
    ];

    const dispatch = useDispatch<RmsDispatch>();
    const reportApprovalStatusResource = useCallback(
        () => dispatch(refreshReportApprovalStatusView(reportId)),
        [dispatch, reportId]
    );
    useOverlayResourceOnOpen({ id: overlayId, resource: reportApprovalStatusResource });

    const canEditReportCardStatus = useSelector(canEditReportCardStatusSelector);
    const currentReport = useSelector(currentReportSelector);
    const reportStatusView = useSelector(reportStatusViewSelector);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const reportDefinitionRestrictViewReportOwners = useSelector(
        reportDefinitionRestrictViewReportOwnersSelector
    );

    const checkIsReportOwnerRestricted = useIsReportOwnerRestricted();

    const handleEditClick = useCallback(() => {
        dispatch(openReportChangeOwnerModal({ callback: noop }));
    }, [dispatch]);

    if (!reportStatusView || !currentReport) {
        return null;
    }

    const { activeSubmission, canReassign, report } = reportStatusView;
    const currentUserHasChangeReportOwnerPermissions =
        canReassign && canManage(report.permissionSet);

    // `currentReport` being here is a bit of a hack
    // as is, our "change report owner" modal requires that there be a "current report" in redux state
    // to work
    // given this, we only show the "edit report owner" button when there is a current report (basically
    // this is when a user is actually on a report page)
    // this is unlikely to grate much on UX because it is unlikely that a user is hoping to change the owner
    // of a report that is related to a case they are currently viewing
    const allowEditReportOwner = currentReport && currentUserHasChangeReportOwnerPermissions;
    const editable = includes(report.permissionSet, OperationTypeEnum.MANAGE.name);
    const isDepartmentRoleEditable = !!(
        currentUserHasAbility(abilitiesEnum.ADMIN.DELETE_DEPARTMENT_PERMISSION) &&
        applicationSettings.RMS_REMOVE_DEPARTMENT_PERMISSIONS_ENABLED
    );
    const errorMessage = canEditReportCardStatus ? canEditReportCardStatus.errorMessage : undefined;

    const restrictViewReportOwners = reportDefinitionRestrictViewReportOwners(
        currentReport.reportDefinitionId
    );
    const isReportOwnerRestricted = checkIsReportOwnerRestricted(
        restrictViewReportOwners,
        currentReport.permissionSet
    );

    return (
        <>
            <LoadPermissions reportId={reportId} reportStatusView={reportStatusView} />
            {!isUndefinedOrNull(errorMessage) && size(errorMessage) > 0 ? (
                <EntityPermissionsMessage infoMessage={errorMessage} />
            ) : null}

            <SecondarySectionHeader
                onEditClick={allowEditReportOwner ? handleEditClick : undefined}
            >
                {strings.reportOwner(reportOwnerDisplayValue)}
                <HelpText
                    content={strings.reportOwnerHelpText(reportOwnerDisplayValue)}
                    collisionBoundary={document.querySelector('.mark43-react-modal')}
                />
            </SecondarySectionHeader>
            <Section>
                <FeatureFlagged
                    flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                    fallback={
                        activeSubmission.ownerId && (
                            <ConnectedFormattedUser
                                userId={activeSubmission.ownerId}
                                format={FORMATS.FULL_NAME}
                            />
                        )
                    }
                >
                    <RestrictVisibilityWithDash restricted={isReportOwnerRestricted}>
                        {activeSubmission.ownerId && (
                            <ConnectedFormattedUser
                                userId={activeSubmission.ownerId}
                                format={FORMATS.FULL_NAME}
                            />
                        )}
                    </RestrictVisibilityWithDash>
                </FeatureFlagged>
            </Section>
            <RecordPermissionsForm
                formName={formName}
                disabledRoleIds={[]}
                editable={editable}
                isDepartmentRoleEditable={isDepartmentRoleEditable}
                hideUserSettings={isReportOwnerRestricted}
            />
        </>
    );
};

const ReportPermissionsModal: React.FC<ReportPermissionsModalProps> = (props) => {
    const { reportId, reportShortTitle } = props;

    const dispatch = useDispatch<RmsDispatch>();
    const handleSave = useCallback(() => {
        return dispatch(saveEntityPermissionsForm(EntityTypeEnum.REPORT.name, reportId));
    }, [dispatch, reportId]);

    return (
        <Modal
            buttonElement={<RecordsHeaderPermissionsButton overlayId={overlayId} />}
            id={overlayId}
            okText={strings.saveText}
            onSave={handleSave}
            title={reportShortTitle}
        >
            <Content {...props} />
        </Modal>
    );
};

export default ReportPermissionsModal;
