import { lifecycleOptions } from 'markformythree';
import React from 'react';
import { RefContextEnum } from '@mark43/rms-api';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import {
    eFileProfileRemoveDeactivateFormConfiguration,
    initialState,
} from '../../state/forms/eFileProfileRemoveDeactivateForm';

export const EFileProfileRemoveDeactivateForm = () => (
    <ArbiterForm
        context={RefContextEnum.FORM_E_FILE_REMOVE_DEACTIVATE_ENTITY.name}
        configuration={eFileProfileRemoveDeactivateFormConfiguration}
        initialState={initialState}
        name={RefContextEnum.FORM_E_FILE_REMOVE_DEACTIVATE_ENTITY.name}
        lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
        render={() => <ArbiterMFTTextArea path="rationale" width={550} rows={6} />}
    />
);
