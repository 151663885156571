import type { FormValue } from 'dragon-react';

import { useDragonCurrentFormContext } from '../context/dragon-current-form';
import { useDragonFormValuesContext } from '../context/dragon-form-values';

/**
 * This hook provides access to the static form value of the form within the current context.
 * Static in this case refers to the fact that the value is not updating while the form is changing.
 * The value is to be used as initial value when initializing a form.
 */
export function useCurrentFormInitialValue(): FormValue {
    const { configuredFormId, instanceId } = useDragonCurrentFormContext();
    const formValuesMap = useDragonFormValuesContext();
    const formValueKey = `${configuredFormId}~${instanceId}`;
    const formValue = formValuesMap[formValueKey];
    if (!formValue) {
        throw new Error(
            `Unexpectedly did not find a value for key "${formValueKey}" in form values for form "${configuredFormId}~${instanceId}"`
        );
    }

    return formValue;
}

export const useCurrentFormInitialValueByInstanceId = (instanceId: string | number): FormValue => {
    const { configuredFormId } = useDragonCurrentFormContext();
    const formValuesMap = useDragonFormValuesContext();
    const formValueKey = `${configuredFormId}~${instanceId}`;
    const formValue = formValuesMap[formValueKey];
    if (!formValue) {
        throw new Error(
            `Unexpectedly did not find a value for key "${formValueKey}" in form values for form "${configuredFormId}~${instanceId}"`
        );
    }

    return formValue;
};
