import { ReactNode } from 'react';
import _, { get, identity, map, noop } from 'lodash';
import keyMirror from 'keymirror';
import {
    AttributeTypeEnum,
    ChangeView as BaseChangeView,
    EntityTypeEnum,
    HistoryEventTypeEnum,
    HistoryValueTypeEnum,
    RmsHistoryEvent,
    UnitsEnum,
} from '@mark43/rms-api';
import { booleanToYesNo } from '../../../../helpers/stringHelpers';
import componentStrings from '../../../strings/componentStrings';
import { formatAttributeByIdSelector, getAttributeByIdSelector } from '../../attributes/state/data';
import { DateTimeFormatter } from '../../../dates/utils/dateHelpers';
import { formatHeight, formatWeight } from '../../person-profiles/utils/personProfilesHelpers';

import diffWords from './diffWords';

const strings = componentStrings.core.historyEvents;

// These are of type Object in the BE and handled by the serrializer
type ChangeView = BaseChangeView & {
    newValue?: number | string;
    oldValue?: number | string;
};

const iconTypes = keyMirror({
    CHECK: null,
    GENERATED: null,
    STATUS_CHANGE: null,
    VEHICLE: null,
    EDITED: null,
    CREATED: null,
});

type UserById = ((id: number | undefined) => string | undefined) | typeof noop;

export type AugmentedRmsHistoryEvent = RmsHistoryEvent & {
    entityId?: number;
};

const getPrimaryName = (history: AugmentedRmsHistoryEvent, userById: UserById = noop) => {
    if (history.primaryType === 'USER') {
        return userById(history.primaryId);
    }

    return history.primaryName;
};

const getSecondaryName = (history: AugmentedRmsHistoryEvent, userById: UserById = noop) => {
    if (history.secondaryType === 'USER') {
        return userById(history.secondaryId);
    }

    return history.secondaryName;
};

type ProcessingArgs = {
    attributeById?: ReturnType<typeof getAttributeByIdSelector> | typeof noop;
    formatAttributeById?: ReturnType<typeof formatAttributeByIdSelector> | typeof noop;
    userById?: UserById;
    displayDate?: DateTimeFormatter['formatDate'] | typeof noop;
    displayDateTime?: DateTimeFormatter['formatDateTime'] | typeof noop;
    displayInches?: typeof formatHeight | typeof noop;
    displayPounds?: typeof formatWeight | typeof noop;
};

type ProcessedHistoryEvent = AugmentedRmsHistoryEvent & {
    action?: string;
    subject?: string | void;
    showChanges?: boolean;
    iconType?: keyof typeof iconTypes;
    subjectSplit?: string;
    subject2?: string | void;
};

type DiffSet = {
    added?: boolean;
    removed?: boolean;
    narrativeValue?: string;
    value: string | boolean;
    count?: number;
};

export type DiffedProcessedChangeSet = ProcessedChangeSet & {
    diffSet?: DiffSet[];
};

export type HistoryEventViewModel = ProcessedHistoryEvent & {
    user?: string | ReactNode;
    formattedDate?: string;
    changes: DiffedProcessedChangeSet[];
};

export const processHistoryEvent = (
    history: AugmentedRmsHistoryEvent,
    processingArgs: ProcessingArgs
): ProcessedHistoryEvent => {
    switch (history.historyEventType) {
        case HistoryEventTypeEnum.FIELDS_CHANGED.name:
            return processFieldsChanged(history, processingArgs);
        case HistoryEventTypeEnum.ENTITY_CREATION.name:
            return processEntityCreation(history, processingArgs);
        case HistoryEventTypeEnum.ENTITY_DELETE.name:
            return processEntityDelete(history, processingArgs);
        case HistoryEventTypeEnum.LINK_CREATION.name:
            return processLinkCreation(history, processingArgs);
        case HistoryEventTypeEnum.LINK_DELETE.name:
            return processLinkDelete(history, processingArgs);
        case HistoryEventTypeEnum.ATTRIBUTE_LINK_CREATION.name:
            return processAttributeLinkCreation(history, processingArgs);
        case HistoryEventTypeEnum.ATTRIBUTE_LINK_DELETION.name:
            return processAttributeLinkDelete(history, processingArgs);
        case HistoryEventTypeEnum.LABEL_CREATION.name:
            return processAttributeLinkCreation(history, processingArgs);
        case HistoryEventTypeEnum.LABEL_DELETION.name:
            return processAttributeLinkDelete(history, processingArgs);
        case HistoryEventTypeEnum.OWNER_CHANGED.name:
            return processFieldsChanged(history, processingArgs);
        case HistoryEventTypeEnum.SEALING_ACTION.name:
            return processSealingAction(history, processingArgs);
        case HistoryEventTypeEnum.VACATING_ACTION.name:
            return processVacatingAction(history, processingArgs);
        case HistoryEventTypeEnum.ASSOCIATED_RECORD_CHANGED.name:
            return processFieldsChanged(history, processingArgs);
        case HistoryEventTypeEnum.ASSOCIATED_RECORD_CREATED.name:
            return processEntityCreation(history, processingArgs);
        case HistoryEventTypeEnum.ASSOCIATED_RECORD_DELETED.name:
            return processEntityDelete(history, processingArgs);
        case HistoryEventTypeEnum.ENTITY_PERMISSION_CHANGED.name:
            return processFieldsChanged(history, processingArgs);
        case HistoryEventTypeEnum.ENTITY_PERMISSION_CREATED.name:
            return processEntityCreation(history, processingArgs);
        case HistoryEventTypeEnum.ENTITY_PERMISSION_DELETED.name:
            return processEntityDelete(history, processingArgs);
        default:
            return history;
    }
};

const processFieldsChanged = (
    oldHistory: AugmentedRmsHistoryEvent,
    { userById = noop }: ProcessingArgs
): ProcessedHistoryEvent => {
    const history: ProcessedHistoryEvent = {
        ...oldHistory,
        action: 'changed',
        subject: getPrimaryName(oldHistory, userById),
        showChanges: true,
        iconType: iconTypes.EDITED,
    };

    if (history.secondaryName) {
        history.action = `${history.action} the link between`;
        history.subjectSplit = 'and';
        history.subject2 = getSecondaryName(oldHistory, userById);
    }
    return history;
};

const processSealingAction = (
    oldHistory: AugmentedRmsHistoryEvent,
    { userById = noop }: ProcessingArgs
): ProcessedHistoryEvent => {
    const history = {
        ...oldHistory,
        action: 'has performed a sealing action per court order mandate',
        subject: getPrimaryName(oldHistory, userById),
        showChanges: false,
        // TODO-WAVE-1329: Add a new icon type
        iconType: iconTypes.EDITED,
    };

    return history;
};

const processVacatingAction = (
    oldHistory: AugmentedRmsHistoryEvent,
    { userById = noop }: ProcessingArgs
): ProcessedHistoryEvent => {
    const history = {
        ...oldHistory,
        subject: getPrimaryName(oldHistory, userById),
        showChanges: false,
        iconType: iconTypes.EDITED,
    };

    return history;
};

// processReportStatus

const processEntityCreation = (
    oldHistory: AugmentedRmsHistoryEvent,
    { userById = noop }: ProcessingArgs
): ProcessedHistoryEvent => {
    const history: ProcessedHistoryEvent = {
        ...oldHistory,
        action: 'added',
        subject: getPrimaryName(oldHistory, userById),
        iconType: iconTypes.GENERATED,
        // We need to show the whole additional info here, it's too much work
        // for readers to cross-reference with the info list in the event summary
        showChanges: oldHistory.primaryType === EntityTypeEnum.ADDITIONAL_INFO.name,
    };

    if (history.directionalPrefix) {
        history.subject = history.directionalPrefix;
        history.subjectSplit = 'to';
        history.subject2 = getPrimaryName(oldHistory, userById);
    }

    return history;
};

const processEntityDelete = (
    oldHistory: AugmentedRmsHistoryEvent,
    { userById = noop }: ProcessingArgs
): ProcessedHistoryEvent => {
    const history: ProcessedHistoryEvent = {
        ...oldHistory,
        action: 'removed',
        subject: getPrimaryName(oldHistory, userById),
        iconType: iconTypes.EDITED,
        showChanges: oldHistory.changeSet && oldHistory.changeSet.length > 0,
    };

    if (history.directionalPrefix) {
        history.subject = history.directionalPrefix;
        history.subjectSplit = 'from';
        history.subject2 = getPrimaryName(oldHistory, userById);
    }

    return history;
};

const processLinkCreation = (
    oldHistory: AugmentedRmsHistoryEvent,
    { userById = noop }: ProcessingArgs
): ProcessedHistoryEvent => {
    const history = {
        ...oldHistory,
        action: 'added',
        subject: getPrimaryName(oldHistory, userById),
        subject2: getSecondaryName(oldHistory, userById),
        subjectSplit: 'to',
        ending: 'as a(n)',
        iconType: iconTypes.STATUS_CHANGE,
    };

    return history;
};

const processLinkDelete = (
    oldHistory: AugmentedRmsHistoryEvent,
    { userById = noop }: ProcessingArgs
): ProcessedHistoryEvent => {
    const history = {
        ...oldHistory,
        action: 'removed the link between',
        subject: getPrimaryName(oldHistory, userById),
        subject2: getSecondaryName(oldHistory, userById),
        subjectSplit: 'and',
        ending: 'as a(n)',
        iconType: iconTypes.EDITED,
        showChanges: oldHistory.changeSet && oldHistory.changeSet.length > 0,
    };

    return history;
};

const processAttributeLinkCreation = (
    oldHistory: AugmentedRmsHistoryEvent,
    { userById = noop }: ProcessingArgs
): ProcessedHistoryEvent => {
    const history = {
        ...oldHistory,
        action: `added attribute${
            oldHistory.changeSet && oldHistory.changeSet.length ? 's' : ''
        } to`,
        subject: getPrimaryName(oldHistory, userById),
        showChanges: true,
        iconType: iconTypes.CREATED,
    };
    return history;
};

const processAttributeLinkDelete = (
    oldHistory: AugmentedRmsHistoryEvent,
    { userById = noop }: ProcessingArgs
): ProcessedHistoryEvent => {
    const history = {
        ...oldHistory,
        action: `removed attribute${
            oldHistory.changeSet && oldHistory.changeSet.length ? 's' : ''
        } from`,
        subject: getPrimaryName(oldHistory, userById),
        showChanges: true,
        iconType: iconTypes.EDITED,
    };
    return history;
};

const converterForChangeSet = (
    changeSet: ChangeView,
    {
        formatAttributeById = noop,
        userById = noop,
        displayDate = noop,
        displayDateTime = noop,
        displayPounds = noop,
        displayInches = noop,
    }: ProcessingArgs
) => {
    switch (changeSet.historyValueType) {
        case HistoryValueTypeEnum.ATTRIBUTE.name:
            return formatAttributeById;
        case HistoryValueTypeEnum.USER.name:
            return userById;
        case HistoryValueTypeEnum.DATE.name:
            return displayDate;
        case HistoryValueTypeEnum.DATETIME.name:
            return displayDateTime;
        case HistoryValueTypeEnum.LINK_TYPE.name:
            return noop;
        case HistoryValueTypeEnum.NUMERIC.name:
            if (changeSet.unit === UnitsEnum.POUNDS.name) {
                return displayPounds;
            } else if (changeSet.unit === UnitsEnum.INCHES.name) {
                return displayInches;
            }
            return identity;
        default:
            return identity;
    }
};

type ProcessedChangeSet = ChangeView & {
    oldDisplay?: string | boolean | void;
    newDisplay?: string | boolean | void;
};

export const processChangeSet = (
    changeSet: ChangeView,
    {
        attributeById = noop,
        formatAttributeById = noop,
        userById = noop,
        displayDate = noop,
        displayDateTime = noop,
        displayPounds = noop,
        displayInches = noop,
    }: ProcessingArgs,
    parentHistoryObject: ProcessedHistoryEvent
): DiffedProcessedChangeSet => {
    const converter = converterForChangeSet(changeSet, {
        formatAttributeById,
        userById,
        displayDate,
        displayDateTime,
        displayPounds,
        displayInches,
    });

    const newChangeSet: ProcessedChangeSet = {
        ...changeSet,
        // @ts-expect-error client-common to client RND-7529
        oldDisplay: converter(changeSet.oldValue),
        // @ts-expect-error client-common to client RND-7529
        newDisplay: converter(changeSet.newValue),
    };

    if (
        parentHistoryObject &&
        changeSet.historyValueType === HistoryValueTypeEnum.ATTRIBUTE.name &&
        (parentHistoryObject.historyEventType ===
            HistoryEventTypeEnum.ATTRIBUTE_LINK_CREATION.name ||
            parentHistoryObject.historyEventType ===
                HistoryEventTypeEnum.ATTRIBUTE_LINK_DELETION.name ||
            parentHistoryObject.historyEventType === HistoryEventTypeEnum.LABEL_CREATION.name ||
            parentHistoryObject.historyEventType === HistoryEventTypeEnum.LABEL_DELETION.name)
    ) {
        if (changeSet.oldValue) {
            const attr = attributeById(changeSet.oldValue);
            if (attr) {
                newChangeSet.fieldName = get(AttributeTypeEnum, `${attr.type}.displayName`);
                newChangeSet.oldDisplay = get(attr, 'val');
            }
        }
        if (changeSet.newValue) {
            const attr = attributeById(changeSet.newValue);
            if (attr) {
                newChangeSet.fieldName = get(AttributeTypeEnum, `${attr.type}.displayName`);
                newChangeSet.newDisplay = get(attr, 'val');
            }
        }
    }
    return diffForChangeSet(newChangeSet);
};

const diffForChangeSet = (changeSet: ProcessedChangeSet): DiffedProcessedChangeSet => {
    switch (changeSet.historyValueType) {
        case HistoryValueTypeEnum.STRING.name:
            return stringDiff(changeSet);
        case HistoryValueTypeEnum.NARRATIVE.name:
            return textDiff(changeSet);
        case HistoryValueTypeEnum.BOOLEAN.name:
            return booleanDiff(changeSet);
        default:
            return defaultDiff(changeSet);
    }
};

const stringDiff = (changeSet: ProcessedChangeSet): DiffedProcessedChangeSet => {
    const oldDisplay = (get(changeSet, 'oldDisplay') || '').toString();
    const newDisplay = (get(changeSet, 'newDisplay') || '').toString();
    const newChangeSet = textDiff({
        ...changeSet,
        oldDisplay,
        newDisplay,
    });
    return {
        ...newChangeSet,
        oldDisplay,
        newDisplay,
    };
};

const booleanDiff = (changeSet: ProcessedChangeSet): DiffedProcessedChangeSet => {
    const oldDisplay = booleanToYesNo(get(changeSet, 'oldDisplay') as boolean) || '';
    const newDisplay = booleanToYesNo(get(changeSet, 'newDisplay') as boolean) || '';
    const diffSet = diffWords(oldDisplay, newDisplay);
    return {
        ...changeSet,
        diffSet,
        oldDisplay,
        newDisplay,
    };
};

const textDiff = (changeSet: ProcessedChangeSet): DiffedProcessedChangeSet => {
    const diffSet: DiffSet[] = diffWords(
        changeSet.oldDisplay as string,
        changeSet.newDisplay as string
    );
    if (diffSet.length === 1 && !diffSet[0].added && !diffSet[0].removed) {
        // This means only formatting changes were made, since the plain texts match
        // and was neither added nor removed.
        diffSet[0].value = strings.formattingChanges;
        diffSet[0].narrativeValue = strings.narrativeFormattingChanges;
    } else if (!diffSet.length) {
        diffSet.push({
            added: false,
            removed: false,
            value: strings.formattingChanges,
            narrativeValue: strings.narrativeFormattingChanges,
        });
    }
    return {
        ...changeSet,
        diffSet,
        oldDisplay: '',
        newDisplay: '',
    };
};

const defaultDiff = (changeSet: ProcessedChangeSet): DiffedProcessedChangeSet => {
    const diffSet: DiffSet[] = [];
    if (changeSet.oldDisplay) {
        diffSet.push({
            value: changeSet.oldDisplay,
            removed: true,
            count: 1,
        });
    }
    if (changeSet.newDisplay) {
        diffSet.push({
            value: changeSet.newDisplay,
            added: true,
            count: 1,
        });
    }
    return {
        ...changeSet,
        diffSet,
    };
};

export const augmentHistoryEvents = (
    historyEvents: RmsHistoryEvent[],
    entityId: number
): AugmentedRmsHistoryEvent[] => {
    return map(historyEvents, (historyEvent) => {
        return {
            ...historyEvent,
            entityId,
        };
    });
};

export const convertHistoryArrayToHistoryEventViewModels = (
    historyEvents: RmsHistoryEvent[],
    processingArgs: {
        formatUserById: (userId: number) => string;
        dateTimeFormatter: DateTimeFormatter;
        attributeById?: ReturnType<typeof getAttributeByIdSelector>;
        formatAttributeById?: ReturnType<typeof formatAttributeByIdSelector>;
    }
) => {
    const { attributeById, formatAttributeById, formatUserById, dateTimeFormatter } =
        processingArgs;
    const args = {
        attributeById,
        formatAttributeById,
        userById: formatUserById,
        displayDate: dateTimeFormatter.formatDate,
        displayDateTime: dateTimeFormatter.formatDateTime,
        displayInches: formatHeight,
        displayPounds: formatWeight,
    };

    return _(historyEvents)
        .orderBy(['timestampUtc', 'id'], ['desc', 'desc'])
        .map((history) => {
            const processedEvent = processHistoryEvent(history, args);
            return {
                ...processedEvent,
                user: formatUserById(processedEvent.changedBy),
                changes: processedEvent.changeSet.map((changeSet) =>
                    // @ts-expect-error see ChangeView in historyEventHelpers
                    processChangeSet(changeSet, args, processedEvent)
                ),
                formattedDate: dateTimeFormatter.formatDateTime(history.timestampUtc),
            };
        })
        .value();
};
