import {
    EntityTypeEnum,
    RenConfigurationEnum,
    ReportHistoryVisibilityLevelEnum,
} from '@mark43/rms-api';
import _, { get, map, slice, find } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { FormsProvider } from 'markformythree';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    reportDefinitionByIdSelector,
    reportDefinitionByReportIdSelector,
} from '~/client-common/core/domain/report-definitions/state/data';
import { reportShortTitleViewModelByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatReportTitleForReportIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { reportDefinitionHasArrestCard } from '~/client-common/helpers/reportDefinitionsHelpers';
import { canManage } from '~/client-common/core/domain/entity-permissions/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import RecordsHeaderLinkReportMenu from '../../../../records/core/components/header/RecordsHeaderLinkReportMenu';
import RecordsHeaderCurrentViewersToggle from '../../../../records/core/components/header/RecordsHeaderCurrentViewersToggle';
import RecordsHeader from '../../../../records/core/components/header/RecordsHeader';
import { OnlyWithAbility, abilitiesEnum } from '../../../../core/abilities';
import { Tooltip } from '../../../../core/components/tooltip';
import { ButtonGroup } from '../../../../core/components/Button';
import { custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector } from '../../../../core/report-definitions/state/ui';
import {
    reportOwnerIdSelector,
    reportSubmissionAuthorsSelector,
    currentReportSelector,
    linkedCaseTitleViewModelsSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';

import {
    currentUserDepartmentIdSelector,
    currentUserHasAbilitySelector,
} from '../../../../core/current-user/state/ui';
import { doesVacatedChargeExistForReportIdSelector } from '../../../../record-privacy/vacating/state/ui';
import {
    isInvolvedPersonSealedForReportIdSelector,
    doesSealedChargeExistForReportIdSelector,
} from '../../../../record-privacy/sealing/state/ui';
import formsRegistry from '../../../../../core/formsRegistry';
import testIds from '../../../../../core/testIds';
import routesConfig from '../../../../../routing/routesConfig';
import Subheader from '../../../../core/components/Subheader';
import { routeNameSelector } from '../../../../../routing/routerModule';
import { RestrictVisibilityWithDash } from '../../../../core/components/RestrictVisibilityWithDash';
import ReportHeaderBanners from './ReportHeaderBanners';
import ReportHeaderHamburgerMenu from './ReportHeaderHamburgerMenu';
import ReportHeaderBookingButton from './ReportHeaderBookingButton';
import ReportHeaderHistoryButton from './ReportHeaderHistoryButton';
import ReportHeaderPermissionsButton from './ReportHeaderPermissionsButton';
import ReportHeaderExportsDropdown from './ReportHeaderExportsDropdown';

const strings = componentStrings.reports.core.ReportHeader;

const MoreAuthors = styled.span`
    padding: 5px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-sm);
    cursor: default;
`;

const mapStateToProps = createStructuredSelector({
    featureFlags: applicationSettingsSelector,
    reportSubmissionAuthors: reportSubmissionAuthorsSelector,
    reportOwnerId: reportOwnerIdSelector,
    formatMiniUserById: formatMiniUserByIdSelector,
    currentReport: currentReportSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    reportDefinitionByReportId: reportDefinitionByReportIdSelector,
    custodialPropertySummaryReportDefinition:
        custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector,
    formatReportTitleForReportId: formatReportTitleForReportIdSelector,
    isInvolvedPersonSealedForReportId: isInvolvedPersonSealedForReportIdSelector,
    doesVacatedChargeExistForReportId: doesVacatedChargeExistForReportIdSelector,
    doesSealedChargeExistForReportId: doesSealedChargeExistForReportIdSelector,
    formatFieldByName: formatFieldByNameSelector,
    linkedCaseTitleViewModels: linkedCaseTitleViewModelsSelector,
    reportShortTitleViewModelByReportId: reportShortTitleViewModelByReportIdSelector,
    reportDefinitionById: reportDefinitionByIdSelector,
    routeName: routeNameSelector,
});

class ReportHeader extends React.Component {
    render() {
        const {
            featureFlags,
            reportSubmissionAuthors,
            reportOwnerId,
            formatMiniUserById,
            currentReport,
            currentUserDepartmentId,
            currentUserHasAbility,
            reportDefinitionByReportId,
            reportDefinitionById,
            // legacy KO side panel callbacks
            hideAllButtons = false,
            custodialPropertySummaryReportDefinition,
            formatReportTitleForReportId,
            isInvolvedPersonSealedForReportId,
            doesVacatedChargeExistForReportId,
            doesSealedChargeExistForReportId,
            formatFieldByName,
            linkedCaseTitleViewModels,
            reportShortTitleViewModelByReportId,
            routeName,
            router,
        } = this.props;

        // if we have no current report we bail, as we really have nothing to display
        if (!currentReport) {
            return null;
        }

        const bannerComponent = <ReportHeaderBanners router={router} />;
        const currentReportId = currentReport.id;
        const recordsWithoutRenEnabled = featureFlags.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;

        const reportDefinition = reportDefinitionByReportId(currentReportId);
        const renConfiguration = get(reportDefinition, 'renConfiguration');
        const restrictViewReportOwners = get(reportDefinition, 'restrictViewReportOwners');
        const reportDefinitionHasNoRen = renConfiguration === RenConfigurationEnum.NONE.name;

        const authors = _(reportSubmissionAuthors)
            .uniqBy('officerId')
            .map(({ officerId }) => formatMiniUserById(officerId, { firstNameAsInitial: true }))
            .value();
        const additionalAuthors = authors.length > 1 ? slice(authors, 1) : null;
        const authorsDisplay =
            authors.length > 1 ? (
                <span>
                    {authors[0]}
                    {additionalAuthors && (
                        <Tooltip
                            side="bottom"
                            align="start"
                            content={map(additionalAuthors, (author) => (
                                <div key={author}>{author}</div>
                            ))}
                        >
                            <MoreAuthors>+{additionalAuthors.length}</MoreAuthors>
                        </Tooltip>
                    )}
                </span>
            ) : null;

        const isCPSReport =
            currentReport.reportDefinitionId ===
            get(custodialPropertySummaryReportDefinition, 'id');

        const isReportOwnerRestricted =
            restrictViewReportOwners &&
            !(
                canManage(currentReport.permissionSet) &&
                currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_REPORT_OWNER)
            );

        let subtitleParts = isCPSReport
            ? []
            : [
                  {
                      name: formatFieldByName(DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL),
                      value: (
                          <FeatureFlagged
                              flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                              fallback={formatMiniUserById(reportOwnerId, {
                                  firstNameAsInitial: true,
                              })}
                          >
                              <RestrictVisibilityWithDash restricted={isReportOwnerRestricted}>
                                  {formatMiniUserById(reportOwnerId, {
                                      firstNameAsInitial: true,
                                  })}
                              </RestrictVisibilityWithDash>
                          </FeatureFlagged>
                      ),
                  },
                  {
                      name: additionalAuthors ? strings.authors : strings.author,
                      value: (
                          <FeatureFlagged
                              flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                              fallback={authorsDisplay}
                          >
                              <RestrictVisibilityWithDash restricted={isReportOwnerRestricted}>
                                  {authorsDisplay}
                              </RestrictVisibilityWithDash>
                          </FeatureFlagged>
                      ),
                  },
              ];

        const reportingEventNumber = currentReport.reportingEventNumber;
        if (recordsWithoutRenEnabled) {
            const linkedCase = find(linkedCaseTitleViewModels, {
                reportingEventNumber,
            });
            let caseLink;
            if (linkedCase) {
                const linkedCaseId = linkedCase.caseId;
                const { canRead } = getViewModelProperties(linkedCase);
                caseLink = canRead ? `/cases/${linkedCaseId}` : null;
            }
            const renDisplay = {
                name: formatFieldByName(REPORT_REPORTING_EVENT_NUMBER),
                value: reportingEventNumber,
                link: caseLink,
                testId: testIds.REPORT_REN_DISPLAY_VALUE,
                canCopyToClipboard: !!navigator.clipboard,
            };
            subtitleParts = [renDisplay, ...subtitleParts];
        }

        const isCrossAgency = currentReport.departmentId !== currentUserDepartmentId;
        const isArrestReport = reportDefinitionHasArrestCard(reportDefinition);
        const reportContainsVacatedCharge =
            isArrestReport && doesVacatedChargeExistForReportId(currentReportId);
        const reportContainsSealedPerson = isInvolvedPersonSealedForReportId(currentReportId);
        const reportContainsSealedCharge =
            isArrestReport && doesSealedChargeExistForReportId(currentReportId);
        const isReportSealed = currentReport.isSealed;
        const isReportMarkedForPrivacy =
            reportContainsVacatedCharge ||
            reportContainsSealedCharge ||
            reportContainsSealedPerson ||
            isReportSealed;

        const canExecuteCourtOrders = currentUserHasAbility(
            abilitiesEnum.REPORTING.EXECUTE_COURT_ORDERS
        );

        const canOpenExports = !isCrossAgency && !isReportSealed && !isReportOwnerRestricted;
        const canOpenPermissions = !isCrossAgency && !isReportSealed;
        const reportDefinitionHistoryVisibilityLevel = get(
            reportDefinitionById(currentReport.reportDefinitionId),
            'historyVisibilityLevel'
        );
        const reportDefinitionCanShowHistory =
            reportDefinitionHistoryVisibilityLevel &&
            reportDefinitionHistoryVisibilityLevel !== ReportHistoryVisibilityLevelEnum.NEVER.name;

        const canOpenReportHistory =
            ((isCPSReport &&
                currentUserHasAbility(abilitiesEnum.EVIDENCE.VIEW_CUSTODIAL_REPORT_HISTORY)) ||
                (!isCPSReport && currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_HISTORY))) &&
            reportDefinitionCanShowHistory &&
            !reportContainsSealedPerson &&
            !reportContainsSealedCharge &&
            !isReportSealed &&
            !isReportOwnerRestricted;

        const canOpenBooking = !isCrossAgency && isArrestReport && !isReportMarkedForPrivacy;

        const canCreateLinkedReport =
            !isCrossAgency && !isReportSealed && !reportDefinitionHasNoRen;

        const canOpenHamburgerMenu =
            !isCrossAgency &&
            (!isArrestReport ||
                !(isReportSealed || reportContainsSealedPerson || reportContainsSealedCharge) ||
                canExecuteCourtOrders);

        const reportShortTitle = reportShortTitleViewModelByReportId(currentReportId);
        const title = recordsWithoutRenEnabled
            ? get(reportShortTitle, 'shortTitle')
            : formatReportTitleForReportId(currentReportId, {
                  includeRen: !recordsWithoutRenEnabled,
              });
        const backToLinkForRouteName = {
            [routesConfig.REPORT_DETENTION_DISPOSITION.name]: `/reports/${currentReportId}`,
            [routesConfig.REPORT_EXPORTS.name]: `/reports/${currentReportId}`,
            [routesConfig.REPORT_EXPORT_REDACT.name]: `/reports/${currentReportId}/exports`,
            [routesConfig.REPORT_HISTORY.name]: `reports/${currentReportId}`,
        };

        const goBackTo = backToLinkForRouteName[routeName];

        return goBackTo ? (
            <Subheader backButtonTo={goBackTo} title={title} bannerComponent={bannerComponent} />
        ) : (
            <Subheader
                bannerComponent={bannerComponent}
                title={title}
                content={
                    <RecordsHeader
                        departmentId={currentReport.departmentId}
                        subtitleParts={subtitleParts}
                    />
                }
            >
                <FormsProvider registry={formsRegistry}>
                    {!hideAllButtons && (
                        <ButtonGroup spacing="var(--arc-space-1)">
                            {canOpenExports && <ReportHeaderExportsDropdown />}
                            {canOpenPermissions && (
                                <ReportHeaderPermissionsButton
                                    currentReportId={currentReportId}
                                    reportShortTitle={title}
                                />
                            )}
                            {canOpenReportHistory && (
                                <ReportHeaderHistoryButton reportId={currentReportId} />
                            )}
                            {canOpenBooking && (
                                <OnlyWithAbility has={abilitiesEnum.REPORTING.EDIT_BOOKING}>
                                    <ReportHeaderBookingButton />
                                </OnlyWithAbility>
                            )}
                            <RecordsHeaderCurrentViewersToggle
                                entityId={currentReportId}
                                entityType={EntityTypeEnum.REPORT.name}
                            />
                            {canCreateLinkedReport && (
                                <OnlyWithAbility has={abilitiesEnum.REPORTING.CREATE_REPORTS}>
                                    <RecordsHeaderLinkReportMenu
                                        reportingEventNumber={currentReport.reportingEventNumber}
                                    />
                                </OnlyWithAbility>
                            )}
                            {canOpenHamburgerMenu && (
                                <ReportHeaderHamburgerMenu
                                    currentReport={currentReport}
                                    title={title}
                                />
                            )}
                        </ButtonGroup>
                    )}
                </FormsProvider>
            </Subheader>
        );
    }
}

/**
 * Header for a report that appears below the page header (as in this is the
 *   page subheader). This shows the report title at the left and action button
 *   menu at the right. This is a child of #mark43-subheader-container.
 */
export default connect(mapStateToProps)(ReportHeader);
