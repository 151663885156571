import React from 'react';
import { useSelector } from 'react-redux';
import { PropertyStatus } from '@mark43/rms-api';

import { formatAttributeByIdSelector } from '../../attributes/state/data';
import { formatFieldByNameSelector } from '../../../fields/state/config';
import * as fields from '../../../enums/universal/fields';

type FormattedPropertyStatusMarijuanaTypeQuantityProps = {
    propertyStatus: PropertyStatus;
    className?: string;
};

export const FormattedPropertyStatusMarijuanaTypeQuantity = ({
    propertyStatus,
    className,
}: FormattedPropertyStatusMarijuanaTypeQuantityProps): JSX.Element | null => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const formatFieldByName = useSelector(formatFieldByNameSelector);

    if (!propertyStatus) {
        return null;
    }

    const { marijuanaTypeAttrId, marijuanaQuantity } = propertyStatus;

    const formattedMarijuanaTypeQuantity =
        marijuanaTypeAttrId && marijuanaQuantity
            ? `${formatFieldByName(
                  fields.PROPERTY_STATUS_MARIJUANA_TYPE_ATTR_ID
              )} - ${formatAttributeById(marijuanaTypeAttrId)} - ${marijuanaQuantity}`
            : '';

    if (!formattedMarijuanaTypeQuantity) {
        return null;
    }

    return <div className={className}>{formattedMarijuanaTypeQuantity}</div>;
};
