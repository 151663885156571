import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import classNames from 'classnames';
import { ElasticCase } from '@mark43/rms-api';
import { max, map, at } from 'lodash';

import { useCaseFieldName, useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import {
    ActiveColumnKeysSelectorType,
    CurrentQuerySelectorType,
    CurrentResultsViewModelsSelectorType,
} from '~/client-common/redux/modules/search/core/utils/types';

import OffenseLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/OffenseLocationCell';
import PrimaryLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/PrimaryLocationCell';
import BatchBanners from '../../batch-operations/components/BatchBanners';
import BulkManageCasesSidePanel from '../../core/components/BulkManageCasesSidePanel';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';

import ResultsHeader from '../../core/components/ResultsHeader';
import CasesResultsFooter from '../../core/components/CasesResultsFooter';
import CasesActionBar from '../../core/components/CasesActionBar';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import { ElasticResultRowWithTargetProfileDetails } from '../../core/components/ElasticResultRowWithTargetProfileDetails';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import Button, { ButtonProps, buttonTypes } from '../../../../legacy-redux/components/core/Button';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import YourInvolvementCell from '../../core/components/YourInvolvementCell';
import AssignedUnitCell from '../../core/components/AssignedUnitCell';
import CaseNumberNameCell from '../../core/components/CaseNumberNameCell';
import CaseTypeCell from '../../core/components/CaseTypeCell';
import CaseApprovalStatusCell from '../../core/components/CaseApprovalStatusCell';
import CaseStatusCell from '../../all-cases/components/CaseStatusCell';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import { abilitiesEnum } from '../../../core/abilities';
import { openCreateManageCaseModalFromDashboard } from '../../core/state/ui';
import { useDashboardScroll } from '../../../search/core/hooks/useDashboardScroll';
import { myCasesSearch } from '../state/ui';
import { iconTypes } from '../../../core/components/Icon';
import { Tooltip } from '../../../core/components/tooltip';
import CreateManageCaseModal from '../../core/components/CreateManageCaseModal';
import { exportsPageLoad } from '../../../../legacy-redux/actions/exportsActions';
import testIds from '../../../../core/testIds';
import MyCasesSearchForm from './MyCasesSearchForm';
import { HandleOptionClickParams } from 'src/scripts/legacy-redux/components/core/tables/OptionsTableColumnTypes';

const AssignedDateCell = dateCellFactory('assignedDateUtc');
const UpdatedDateCell = dateCellFactory('updatedDateUtc');
const CreatedDateCell = dateCellFactory('createdDateUtc');
const DueDateCell = dateCellFactory('dueDateUtc');

const strings = componentStrings.cases.myCases.MyCases;

type ActionButtonProps = ButtonProps & {
    disabled?: boolean;
    disabledTooltipText: string;
};

type OptionsTableDisplayParams = { display: string };

function ActionButton({ disabled, disabledTooltipText, ...props }: ActionButtonProps) {
    const button = <Button className={buttonTypes.SECONDARY} disabled={disabled} {...props} />;
    return disabled && disabledTooltipText ? (
        <Tooltip side="top" hasButtonOffset content={disabledTooltipText}>
            <div>{button}</div>
        </Tooltip>
    ) : (
        button
    );
}

const MyCases = ({ router }: WithRouterProps): JSX.Element => {
    // table ui state
    const { from, size, sortKey: currentSortKey, sortType: currentSortType } = useSelector(
        myCasesSearch.selectors.currentQuerySelector
    ) as CurrentQuerySelectorType;

    const results = useSelector(
        myCasesSearch.selectors.currentResultsViewModelsSelector
    ) as CurrentResultsViewModelsSelectorType<ElasticCase>;

    const highlightedRows = useSelector(
        myCasesSearch.selectors.highlightedRowsSelector
    ) as number[];

    const totalResults = useSelector(myCasesSearch.selectors.totalCountSelector) as number;

    const tableLoading = useSelector(myCasesSearch.selectors.tableLoadingSelector) as boolean;

    const activeColumnKeys = useSelector(
        myCasesSearch.selectors.activeColumnKeysSelector
    ) as ActiveColumnKeysSelectorType;

    // state needed for the assign bar
    const selectedRows = useSelector(myCasesSearch.selectors.selectedRowsSelector) as number[];

    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const applicationSettings = useSelector(applicationSettingsSelector);

    const allResultsSelected = useSelector(
        myCasesSearch.selectors.allResultsSelectedSelector
    ) as boolean;

    const dispatch = useDispatch();

    const {
        singularCaseFieldName: caseDisplayName,
        pluralCaseFieldName: casesDisplayName,
    } = useCaseFieldName();
    const offenseDisplayName = useOffenseFieldName();
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: myCasesSearch,
    });
    const currentPage = max([1, fromSizeToPage(from + 1, size)]);

    const bulkManageCasesLimit = (applicationSettings.RMS_BULK_MANAGE_CASES_LIMIT as number) ?? 0;

    const handleBulkRowSelect = (newSelectedRows: number[]) => {
        dispatch(myCasesSearch.actionCreators.selectRows(newSelectedRows));
    };

    const handleRowClick = (elasticCase: ElasticCase, rowIndex: number) => {
        dispatch(
            myCasesSearch.actionCreators.openSearchResult(
                elasticCase,
                rowIndex,
                router,
                getCurrentScrollPosition()
            )
        );
    };

    const handleDateOptionClick = ({ columnKey, value, sortOptions }: HandleOptionClickParams) => {
        dispatch(myCasesSearch.actionCreators.setActiveColumnKey('date', columnKey));
        dispatch(
            myCasesSearch.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    };

    const handleDateSortTypeClick = (sortType: keyof typeof sortTypeEnum) => {
        dispatch(myCasesSearch.actionCreators.search({ sortType }));
    };

    const handleCaseInfoOptionClick = ({ value }: { value: string }) => {
        dispatch(myCasesSearch.actionCreators.setActiveColumnKey('caseInfo', value));
    };

    const handlePersonnelOptionClick = ({ value }: { value: string }) => {
        dispatch(myCasesSearch.actionCreators.setActiveColumnKey('personnel', value));
    };

    const handleLocationOptionClick = ({ value }: { value: string }) => {
        dispatch(myCasesSearch.actionCreators.setActiveColumnKey('location', value));
    };

    const handlePaginationClick = (_: number, nextPage: number) => {
        dispatch(
            myCasesSearch.actionCreators.search(
                {
                    from: nextPage * size - size,
                    size,
                },
                { cacheBust: true }
            )
        );
    };

    const handleSizeChange = (size: number) => {
        dispatch(
            myCasesSearch.actionCreators.search({
                from: 0,
                size,
            })
        );
    };

    const handleManageCaseClick = () => {
        const elasticCase = results[selectedRows[0]];
        dispatch(openCreateManageCaseModalFromDashboard(elasticCase.id));
    };

    const handleCaseLinkClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch(myCasesSearch.actionCreators.setScrollPosition(getCurrentScrollPosition()));
    };

    const handleExportClick = () => {
        const cases = at(results, selectedRows);
        const caseIds = cases.map((elasticCase) => elasticCase.id);
        if (caseIds.length === 1) {
            dispatch(exportsPageLoad());
            router.push(`/cases/${caseIds[0]}/exports`);
        } else {
            dispatch(myCasesSearch.actionCreators.loadSearchExportPrintables(caseIds)).then(() =>
                router.push('/cases/mine/exports')
            );
        }
    };

    const manageCaseButton =
        selectedRows.length > 1 ? (
            <BulkManageCasesSidePanel
                caseIds={results.length ? map(selectedRows, (r) => results[r].id) : []}
                renderButton={(open: () => void) => (
                    <ActionButton
                        testId={testIds.ACTION_BAR_BULK_MANAGE_CASE_BUTTON}
                        className={buttonTypes.SECONDARY_BOLD}
                        onClick={open}
                        disabled={selectedRows.length > bulkManageCasesLimit}
                        disabledTooltipText={strings.maxBulkCaseManageReached(caseDisplayName)}
                    >
                        {strings.actionButtons.bulkManageCases(casesDisplayName)}
                    </ActionButton>
                )}
            />
        ) : (
            <Button
                testId={testIds.ACTION_BAR_MANAGE_CASE_BUTTON}
                className={buttonTypes.SECONDARY_BOLD}
                onClick={handleManageCaseClick}
            >
                {strings.actionButtons.manageCase(caseDisplayName)}
            </Button>
        );

    const configuredApplicationSettingBulkExportCaseLimit = Number(
        applicationSettings.RMS_BULK_EXPORT_CASE_LIMIT as number
    );
    const bulkExportCaseLimit = !isNaN(configuredApplicationSettingBulkExportCaseLimit)
        ? configuredApplicationSettingBulkExportCaseLimit
        : 0;

    const casesActionBarButtons = (
        <>
            {manageCaseButton}
            <FeatureFlagged flag="RMS_BULK_EXPORT_CASE_ENABLED">
                <ActionButton
                    iconLeft={iconTypes.EXPORT}
                    className={buttonTypes.SECONDARY}
                    onClick={handleExportClick}
                    disabled={bulkExportCaseLimit > 1 && selectedRows.length > bulkExportCaseLimit}
                    disabledTooltipText={strings.maxBulkCaseExportReached(caseDisplayName)}
                >
                    {strings.actionButtons.export}
                </ActionButton>
            </FeatureFlagged>
        </>
    );
    return (
        <>
            <ScrollableUnderSubheader>
                <div className={myCasesSearch.resultsContainerClassName}>
                    <BatchBanners />
                    <ResultsHeader
                        from={from}
                        to={from + results.length}
                        totalResults={totalResults}
                        onSizeChange={handleSizeChange}
                        SearchForm={MyCasesSearchForm}
                        searchModule={myCasesSearch}
                        isSearchSavable={true}
                        inlineWithTabs={false}
                    />
                    <Table
                        data={results}
                        onRowClick={handleRowClick}
                        sortKey={currentSortKey}
                        sortType={currentSortType}
                        noRowsText={strings.noResults(casesDisplayName)}
                        disableBody={tableLoading}
                        selectableRows={currentUserHasAbility(abilitiesEnum.CASES.EDIT_GENERAL)}
                        selectableRow={false}
                        selectedRows={selectedRows}
                        highlightedRows={highlightedRows}
                        onSelectRow={handleBulkRowSelect}
                        containerClassName={classNames('search-results-table')}
                        rowComponent={ElasticResultRowWithTargetProfileDetails}
                        tableCheckboxMargin="10px"
                    >
                        <TableHeader>
                            <TableColumns>
                                <OptionsTableColumn
                                    display={({ display }: OptionsTableDisplayParams) => display}
                                    columnKey={activeColumnKeys.date}
                                    activeValue={currentSortKey}
                                    activeSortType={currentSortType}
                                    onOptionClick={handleDateOptionClick}
                                    onSortTypeClick={handleDateSortTypeClick}
                                    width={169}
                                    popoutWidth={160}
                                >
                                    <OptionsTableColumnOption
                                        display={strings.columns.assignedDateUtc.label}
                                        value={sortKeyEnum.CASE_ASSIGNED_DATE_UTC}
                                        columnKey="assignedDateUtc"
                                        sortOptions={[
                                            {
                                                display:
                                                    strings.columns.assignedDateUtc.descendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                            },
                                            {
                                                display:
                                                    strings.columns.assignedDateUtc.ascendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                            },
                                        ]}
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.updatedDateUtc.label}
                                        value={sortKeyEnum.CASE_UPDATED_DATE_UTC}
                                        columnKey="updatedDateUtc"
                                        sortOptions={[
                                            {
                                                display:
                                                    strings.columns.updatedDateUtc.descendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                            },
                                            {
                                                display:
                                                    strings.columns.updatedDateUtc.ascendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                            },
                                        ]}
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.createdDateUtc.label}
                                        value={sortKeyEnum.CASE_CREATED_DATE_UTC}
                                        columnKey="createdDateUtc"
                                        sortOptions={[
                                            {
                                                display:
                                                    strings.columns.createdDateUtc.descendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                            },
                                            {
                                                display:
                                                    strings.columns.createdDateUtc.ascendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                            },
                                        ]}
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.dueDateUtc.label}
                                        value={sortKeyEnum.CASE_DUE_DATE_UTC}
                                        columnKey="dueDateUtc"
                                        sortOptions={[
                                            {
                                                display: strings.columns.dueDateUtc.descendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                            },
                                            {
                                                display: strings.columns.dueDateUtc.ascendingSort,
                                                sortType:
                                                    sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                            },
                                        ]}
                                    />
                                </OptionsTableColumn>
                                <OptionsTableColumn
                                    display={({ display }: OptionsTableDisplayParams) => display}
                                    columnKey={activeColumnKeys.caseInfo}
                                    activeValue={activeColumnKeys.caseInfo}
                                    onOptionClick={handleCaseInfoOptionClick}
                                    width={205}
                                    popoutWidth={205}
                                >
                                    <OptionsTableColumnOption
                                        display={strings.columns.caseNumberName.label(
                                            caseDisplayName
                                        )}
                                        value="caseNumberName"
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.caseType.label(caseDisplayName)}
                                        value="caseType"
                                    />
                                </OptionsTableColumn>
                                <OptionsTableColumn
                                    display={({ display }: OptionsTableDisplayParams) => display}
                                    columnKey={activeColumnKeys.personnel}
                                    activeValue={activeColumnKeys.personnel}
                                    onOptionClick={handlePersonnelOptionClick}
                                    width={204}
                                    popoutWidth={204}
                                >
                                    <OptionsTableColumnOption
                                        display={strings.columns.yourInvolvement.label}
                                        value="yourInvolvement"
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.assignedUnit.label}
                                        value="assignedUnit"
                                    />
                                </OptionsTableColumn>
                                <OptionsTableColumn
                                    display={({ display }: OptionsTableDisplayParams) => display}
                                    columnKey={activeColumnKeys.location}
                                    activeValue={activeColumnKeys.location}
                                    onOptionClick={handleLocationOptionClick}
                                    width={132}
                                    popoutWidth={170}
                                >
                                    <OptionsTableColumnOption
                                        display={strings.columns.primaryLocation.label}
                                        value="primaryLocation"
                                    />
                                    <OptionsTableColumnOption
                                        display={strings.columns.offenseLocation.label(
                                            offenseDisplayName
                                        )}
                                        value="offenseLocation"
                                    />
                                </OptionsTableColumn>
                                <TableColumn
                                    display={strings.columns.caseStatus.label(caseDisplayName)}
                                    columnKey="caseStatus"
                                    width={140}
                                />
                                <TableColumn
                                    display={strings.columns.caseApprovalStatus.label}
                                    columnKey="caseApprovalStatus"
                                    width={70}
                                />
                            </TableColumns>
                        </TableHeader>
                        <TableBody>
                            <AssignedDateCell columnKey="assignedDateUtc" />
                            <UpdatedDateCell columnKey="updatedDateUtc" />
                            <CreatedDateCell columnKey="createdDateUtc" />
                            <DueDateCell columnKey="dueDateUtc" />
                            <CaseNumberNameCell
                                columnKey="caseNumberName"
                                handleCaseLinkClick={handleCaseLinkClick}
                            />
                            <CaseTypeCell columnKey="caseType" />
                            <YourInvolvementCell columnKey="yourInvolvement" />
                            <AssignedUnitCell columnKey="assignedUnit" />
                            <PrimaryLocationCell columnKey="primaryLocation" />
                            <OffenseLocationCell columnKey="offenseLocation" />
                            <CaseApprovalStatusCell columnKey="caseApprovalStatus" />
                            <CaseStatusCell columnKey="caseStatus" />
                        </TableBody>
                    </Table>
                    {totalResults > 0 && (
                        <CasesResultsFooter
                            totalResults={totalResults}
                            searchSize={size}
                            currentPage={currentPage}
                            handlePaginationClick={handlePaginationClick}
                        />
                    )}

                    <CreateManageCaseModal
                        title={componentStrings.cases.core.CaseHeader.manageCase(caseDisplayName)}
                        okText={strings.actionButtons.save}
                        isBulkCreate={false}
                        includeLocalIdInTitle={true}
                    />
                </div>
            </ScrollableUnderSubheader>
            <CasesActionBar
                totalResultCount={totalResults}
                selectedResultCount={selectedRows.length}
                buttons={casesActionBarButtons}
                allResultsSelected={allResultsSelected}
                visible={selectedRows.length > 0}
            />
        </>
    );
};

export default withRouter(MyCases);
