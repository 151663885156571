import React, { useCallback, useEffect } from 'react';
import { MapView } from '../constructors';
import { CenterT } from '../types';
import SimpleZoomControls from '../components/SimpleZoomControls';
import { handleMapViewGotoCatch } from '../helpers/mapHelpers';

export type SimpleZoomControlsPositionT = 'middle' | 'top';
export type SimpleZoomControlsButtonStyleT = 'arc';
interface SimpleZoomControlsProps {
    mapView: MapView | undefined;
    centerForUpdate?: CenterT;
    displayOnlyMode: boolean;
    zoomForUpdate?: number;
    zoom: number;
    zoomControlPosition?: SimpleZoomControlsPositionT;
    zoomButtonStyle?: SimpleZoomControlsButtonStyleT;
}

export const useSimpleZoomControls: React.FC<SimpleZoomControlsProps> = ({
    mapView,
    centerForUpdate,
    displayOnlyMode,
    zoomForUpdate,
    zoomControlPosition = 'top',
    zoomButtonStyle,
}) => {
    useEffect(() => {
        if (mapView && centerForUpdate) {
            mapView
                .goTo(
                    {
                        target: [centerForUpdate.lng, centerForUpdate.lat],
                        zoom: zoomForUpdate,
                    },
                    { animate: false }
                )
                .catch(handleMapViewGotoCatch);
        }
    }, [centerForUpdate, zoomForUpdate, mapView]);

    const zoomIn = useCallback(() => {
        if (mapView) {
            mapView
                .goTo({
                    target: mapView.center,
                    zoom: mapView.zoom + 1,
                })
                .catch(handleMapViewGotoCatch);
        }
    }, [mapView]);

    const zoomOut = useCallback(() => {
        if (mapView) {
            mapView
                .goTo({
                    target: mapView.center,
                    zoom: mapView.zoom - 1,
                })
                .catch(handleMapViewGotoCatch);
        }
    }, [mapView]);

    return displayOnlyMode ? null : (
        <SimpleZoomControls
            zoomButtonStyle={zoomButtonStyle}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            zoomControlPosition={zoomControlPosition}
        />
    );
};
