import {
    DefaultFilterOptionValueT,
    FilterList,
    FilterMenuRenderProps,
    FilterT,
    VisibleFilter,
} from '@arc/filter-list';
import { Flex } from '@arc/layout';
import { Text as ArcText } from '@arc/typography';
import { DateRangeQuery, SavedSearch, SearchQueryObject } from '@mark43/evidence-api';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import useFields from '~/client-common/core/fields/hooks/useFields';
import fieldStrings from '~/client-common/configs/fieldStrings';
import { Field } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';

import {
    DISPLAY_CHAIN_OF_CUSTODY_ACQUIRED_DATE_UTC,
    DISPLAY_EVIDENCE_LABEL_ITEM_ID,
    DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_DISPOSITION_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_IN_POLICE_CUSTODY_STATUSES_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_LATEST_STATUS_UPDATE_DATE_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_PERSONNEL_NAME_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_REASONS_FOR_POLICE_CUSTODY_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_SERIAL_NUMBER_LABEL,
    DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL,
    DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL,
    DISPLAY_ONLY_PROPERTY_TYPE_LABEL,
    LOCATION_ENTITY_LINK_AGENCY_ID,
    REPORT_REPORTING_EVENT_NUMBER,
    DISPLAY_ONLY_EVIDENCE_SEARCH_IS_OVERDUE_LABEL,
} from '~/client-common/core/enums/universal/fields';

import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';

import { CreateFilterHelper } from '../../../../core/filters/utils';
import { renCreationDateRangeLabelSelector, evidenceDashboardSearch } from '../../state/ui';

import {
    AgencyFilter,
    AttributeSelectFilter,
    DateRangeFilter,
    IdentifierTypesFilter,
    IdentifierTypesValue,
    InputFilter,
    InvolvedPersonsFilter,
    involvedPersonsFilterName,
    InvolvedPersonsValueT,
    ItemdIdValueT,
    ItemIdNItemsFilter,
    RenItemValueT,
    RenNItemsFilter,
} from '../../../../core/filters/components';
import { ChainOfCustodyFilter } from '../../../../core/filters/components/ChainOfCustodyFilter';
import { SearchName } from '../../../../search/core/components/SearchName';

import SearchCapabilityIcon, {
    SEARCH_CAPABILITY_TYPE_ENUM,
} from '../../../../search/core/components/SearchCapabilityIcon';
import {
    FacilityStorageLocationsFilter,
    FacilityStorageLocationsValue,
} from '../../../dashboard-filters/components/FacilityStorageLocationsFilter';
import { PropertyCategoryFilter } from '../../../dashboard-filters/components/PropertyCategoryFilter';
import { PropertyTypeFilter } from '../../../dashboard-filters/components/PropertyTypeFilter';

import { ManageEvidenceDashboardSearch } from './ManageEvidenceDashboardSearch';
import {
    convertManageDashboardArcFiltersToSearchFormData,
    dispositionStatusOptions,
    MANAGE_EVIDENCE_FILTER_IDS,
    convertManageEvidenceElasticQueryToArcFilters,
} from './manageEvidenceFilterHelpers';
import { ManageEvidenceSavedSearch } from './ManageEvidenceSavedSearch';

export type FilterOptionsValueT =
    | DefaultFilterOptionValueT
    | DateRangeQuery
    | FacilityStorageLocationsValue
    | RenItemValueT
    | ItemdIdValueT
    | IdentifierTypesValue
    | InvolvedPersonsValueT
    | boolean;

export type FilterGroups = { key: string; keys?: string[]; label: string; display: string }[];

const strings = componentStrings.evidence.dashboard.EvidenceDashboardSearchResults;
const SearchHeader = styled(ArcText)`
    white-space: break-spaces;
`;
const createFilterList = (
    fields: Record<Field, string>,
    renCreationDateRangeLabel: string
): FilterT<FilterOptionsValueT>[] => {
    const filterCreator = new CreateFilterHelper();
    const filtersList: FilterT<FilterOptionsValueT>[] = filterCreator
        .add<FacilityStorageLocationsValue>({
            id: MANAGE_EVIDENCE_FILTER_IDS.STORAGE_LOCATIONS_ID,
            name: fields.DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL,
            render: (renderProps) => <FacilityStorageLocationsFilter {...renderProps} />,
            isAlwaysVisible: true,
            dynamicValues: true,
        })
        .add({
            id: MANAGE_EVIDENCE_FILTER_IDS.DISPOSITION_STATUS_FILTER_ID,
            name: fields.DISPLAY_ONLY_EVIDENCE_SEARCH_DISPOSITION_STATUSES_LABEL,
            options: dispositionStatusOptions,
            selectionType: 'multiple',
            isAlwaysVisible: true,
        })
        .add({
            id: MANAGE_EVIDENCE_FILTER_IDS.PROPERTY_STATUS_FILTER_ID,
            name: fields.DISPLAY_ONLY_EVIDENCE_SEARCH_IN_POLICE_CUSTODY_STATUSES_LABEL,
            render: (renderProps: FilterMenuRenderProps) => {
                return (
                    <AttributeSelectFilter
                        attributeType="PROPERTY_LOSS"
                        includeExpired
                        {...renderProps}
                    />
                );
            },
            selectionType: 'multiple',
        })
        .add({
            id: MANAGE_EVIDENCE_FILTER_IDS.REASON_FOR_POLICE_CUSTODY_FILTER_ID,
            name: fields.DISPLAY_ONLY_EVIDENCE_SEARCH_REASONS_FOR_POLICE_CUSTODY_LABEL,
            render: (renderProps: FilterMenuRenderProps) => {
                return (
                    <AttributeSelectFilter
                        attributeType="REASON_FOR_POLICE_CUSTODY_OF_PROPERTY"
                        includeExpired
                        {...renderProps}
                    />
                );
            },
            selectionType: 'multiple',
        })
        .add({
            id: MANAGE_EVIDENCE_FILTER_IDS.PROPERTY_CATEGORY_FILTER_ID,
            name: fields.DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL,
            render: (renderProps: FilterMenuRenderProps) => {
                return <PropertyCategoryFilter grouped {...renderProps} />;
            },
            selectionType: 'multiple',
        })
        .add({
            id: MANAGE_EVIDENCE_FILTER_IDS.AGENCY_FILTER_ID,
            name: fields.LOCATION_ENTITY_LINK_AGENCY_ID,
            render: (renderProps: FilterMenuRenderProps) => {
                return <AgencyFilter {...renderProps} />;
            },
            selectionType: 'multiple',
        })
        .add<DateRangeQuery>({
            id: MANAGE_EVIDENCE_FILTER_IDS.DATE_ACQUIRED_ID,
            name: fields.DISPLAY_CHAIN_OF_CUSTODY_ACQUIRED_DATE_UTC,
            render: (renderProps: FilterMenuRenderProps<DateRangeQuery>) => {
                return (
                    <DateRangeFilter
                        {...renderProps}
                        withinLastPeriodOptions={['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D']}
                        toDatePeriodOptions={['P1M', 'P1Y']}
                    />
                );
            },
            dynamicValues: true,
            isAlwaysVisible: true,
        })
        .add({
            id: MANAGE_EVIDENCE_FILTER_IDS.CHAIN_OF_CUSTODY_ID,
            name: fields.DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL,
            render: (renderProps: FilterMenuRenderProps) => {
                return <ChainOfCustodyFilter {...renderProps} />;
            },
            selectionType: 'multiple',
            isAlwaysVisible: true,
        })
        .add<DateRangeQuery>({
            id: MANAGE_EVIDENCE_FILTER_IDS.DATE_LAST_UPDATED_ID,
            name: fields.DISPLAY_ONLY_EVIDENCE_SEARCH_LATEST_STATUS_UPDATE_DATE_LABEL,
            render: (renderProps: FilterMenuRenderProps<DateRangeQuery>) => {
                return (
                    <DateRangeFilter
                        {...renderProps}
                        withinLastPeriodOptions={['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D']}
                        toDatePeriodOptions={['P1M', 'P1Y']}
                    />
                );
            },
            dynamicValues: true,
            isAlwaysVisible: true,
        })
        .add({
            id: MANAGE_EVIDENCE_FILTER_IDS.PROPERTY_TYPE_FILTER_ID,
            name: fields.DISPLAY_ONLY_PROPERTY_TYPE_LABEL,
            render: (renderProps: FilterMenuRenderProps) => {
                return <PropertyTypeFilter {...renderProps} />;
            },
            selectionType: 'multiple',
        })
        .add<DateRangeQuery>({
            id: MANAGE_EVIDENCE_FILTER_IDS.DATE_REN_CREATION_ID,
            name: renCreationDateRangeLabel,
            render: (renderProps: FilterMenuRenderProps<DateRangeQuery>) => {
                return (
                    <DateRangeFilter
                        {...renderProps}
                        withinLastPeriodOptions={['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D']}
                        toDatePeriodOptions={['P1M', 'P1Y']}
                    />
                );
            },
            dynamicValues: true,
        })
        .add({
            id: MANAGE_EVIDENCE_FILTER_IDS.SERIAL_NUMBER_ID,
            name: fields.DISPLAY_ONLY_EVIDENCE_SEARCH_SERIAL_NUMBER_LABEL,
            render: (renderProps: FilterMenuRenderProps) => {
                return (
                    <InputFilter
                        {...renderProps}
                        debounced
                        filterId={MANAGE_EVIDENCE_FILTER_IDS.SERIAL_NUMBER_ID}
                        rightElement={
                            <SearchCapabilityIcon
                                variant={SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD.name}
                            />
                        }
                    />
                );
            },
            dynamicValues: true,
        })
        .add<RenItemValueT>({
            id: MANAGE_EVIDENCE_FILTER_IDS.REN_NUMBER_ID,
            name: fields.REPORT_REPORTING_EVENT_NUMBER,
            render: (renderProps: FilterMenuRenderProps<RenItemValueT>) => {
                return <RenNItemsFilter {...renderProps} />;
            },
        })
        .add<ItemdIdValueT>({
            id: MANAGE_EVIDENCE_FILTER_IDS.ITEM_ID_ID,
            name: fields.DISPLAY_EVIDENCE_LABEL_ITEM_ID,
            render: (renderProps: FilterMenuRenderProps<ItemdIdValueT>) => {
                return <ItemIdNItemsFilter {...renderProps} />;
            },
        })
        .add<IdentifierTypesValue>({
            id: MANAGE_EVIDENCE_FILTER_IDS.IDENTIFIER_TYPES_ID,
            name: fieldStrings.attributeTypes.ITEM_IDENTIFIER_TYPE,
            render: (renderProps: FilterMenuRenderProps<IdentifierTypesValue>) => {
                return <IdentifierTypesFilter {...renderProps} />;
            },
        })
        .add<InvolvedPersonsValueT>({
            id: MANAGE_EVIDENCE_FILTER_IDS.PERSONNEL_IDS_ID,
            name: involvedPersonsFilterName,
            render: (renderProps: FilterMenuRenderProps<InvolvedPersonsValueT>) => {
                return <InvolvedPersonsFilter {...renderProps} />;
            },
        })
        .add<boolean>({
            id: MANAGE_EVIDENCE_FILTER_IDS.IS_OVERDUE_ID,
            name: fields.DISPLAY_ONLY_EVIDENCE_SEARCH_IS_OVERDUE_LABEL,
            selectionType: 'boolean',
            options: [{ value: true, label: '', id: 1 }],
        })
        .getFilters();

    return filtersList;
};

interface ManageEvidenceFilterContextProps {
    query: string;
    setApplyingSavedSearch: (value: boolean) => void;
}

const ManageEvidenceFilterContext = React.createContext<
    ManageEvidenceFilterContextProps | undefined
>(undefined);

export const useManageEvidenceFilterContext = () => {
    const context = React.useContext(ManageEvidenceFilterContext);
    if (!context) {
        throw new Error(
            'useManageEvidenceFilterContext must be used within ManageEvidenceFilterContextProvider'
        );
    }
    return context;
};

export const ManageEvidenceDashboardFilters = () => {
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const renCreationDateRangeLabel = useSelector(renCreationDateRangeLabelSelector);
    const currentSavedSearch: SavedSearch = useSelector(
        evidenceDashboardSearch.selectors.currentSavedSearchSelector
    );

    const filterGroups: FilterGroups = useSelector(
        evidenceDashboardSearch.selectors.filtersSelector
    );
    const currentQuery: SearchQueryObject = useSelector(
        evidenceDashboardSearch.selectors.currentQuerySelector
    );
    const [applyingSavedSearch, setApplyingSavedSearch] = useState(true);
    const [initialAppliedFilters, setInitialAppliedFilters] = useState<
        VisibleFilter<FilterOptionsValueT>[]
    >();
    const [query, setQuery] = useState<string>('');
    const [localAppliedFilters, setLocalAppliedFilters] = useState<
        VisibleFilter<FilterOptionsValueT>[]
    >([]);

    const fields = useFields([
        DISPLAY_ONLY_EVIDENCE_SEARCH_IN_POLICE_CUSTODY_STATUSES_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_DISPOSITION_STATUSES_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_REASONS_FOR_POLICE_CUSTODY_LABEL,
        DISPLAY_ONLY_PROPERTY_TYPE_LABEL,
        DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL,
        LOCATION_ENTITY_LINK_AGENCY_ID,
        DISPLAY_CHAIN_OF_CUSTODY_ACQUIRED_DATE_UTC,
        DISPLAY_ONLY_EVIDENCE_SEARCH_LATEST_STATUS_UPDATE_DATE_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_SERIAL_NUMBER_LABEL,
        REPORT_REPORTING_EVENT_NUMBER,
        DISPLAY_EVIDENCE_LABEL_ITEM_ID,
        DISPLAY_ONLY_EVIDENCE_SEARCH_PERSONNEL_NAME_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_CHAIN_OF_CUSTODY_STATUSES_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_STORAGE_LOCATIONS_LABEL,
        DISPLAY_ONLY_EVIDENCE_SEARCH_IS_OVERDUE_LABEL,
    ]);

    const dispatch = useDispatch();

    const onApplyFilters = useCallback(
        (formData) => {
            dispatch(
                evidenceDashboardSearch.actionCreators.search(
                    {
                        formData,
                        from: 0,
                    },
                    { cacheBust: true, showLoadingModal: false }
                )
            );
        },
        [dispatch]
    );

    const handleDebouncedChangeSlow = useMemo(() => debounce((data) => onApplyFilters(data), 700), [
        onApplyFilters,
    ]);
    const handleDebouncedChangeFast = useMemo(() => debounce((data) => onApplyFilters(data), 100), [
        onApplyFilters,
    ]);

    React.useEffect(() => {
        if (applyingSavedSearch && currentQuery.elasticQuery) {
            const newAppliedFilters = convertManageEvidenceElasticQueryToArcFilters(
                currentQuery.elasticQuery,
                filterGroups
            );
            setInitialAppliedFilters(newAppliedFilters);
            setLocalAppliedFilters(newAppliedFilters);
            // @ts-expect-error elasticQuery is typed as unknown
            setQuery(currentQuery?.elasticQuery?.query);
            setApplyingSavedSearch(false);
        }
    }, [applyingSavedSearch, currentQuery.elasticQuery, filterGroups]);

    if (!currentUserDepartmentId) {
        return null;
    }

    const handleFiltersChange = (newFilters: VisibleFilter<FilterOptionsValueT>[]) => {
        setLocalAppliedFilters(newFilters);
        const filterFormData = convertManageDashboardArcFiltersToSearchFormData(
            newFilters,
            currentUserDepartmentId
        );
        const formData = { ...{ query }, ...filterFormData };

        if (isEmpty(newFilters)) {
            handleDebouncedChangeFast(formData);
        } else {
            handleDebouncedChangeSlow(formData);
        }
    };

    const handleQueryChange = (query: string) => {
        setQuery(query);
        const filterFormData = convertManageDashboardArcFiltersToSearchFormData(
            localAppliedFilters,
            currentUserDepartmentId
        );
        const formData = { ...{ query }, ...filterFormData };
        if (!query) {
            handleDebouncedChangeFast(formData);
        } else {
            handleDebouncedChangeSlow(formData);
        }
    };

    const filterList = createFilterList(fields, renCreationDateRangeLabel);

    const resetSearch = () => {
        handleDebouncedChangeFast({});
        dispatch(evidenceDashboardSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(evidenceDashboardSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
    };

    return (
        <ManageEvidenceFilterContext.Provider
            value={{
                query,
                setApplyingSavedSearch,
            }}
        >
            <Flex flexDirection="column" p={3}>
                <Flex justifyContent="flex-end" flexWrap="wrap" align-items="center">
                    <Flex flexGrow={1} mt={1} flexWrap="wrap">
                        <SearchHeader variant="headingSm">
                            {strings.header(!!currentSavedSearch)}
                        </SearchHeader>
                        {!!currentSavedSearch && (
                            <SearchName
                                currentSavedSearch={currentSavedSearch}
                                isAdvancedSearch={false}
                            />
                        )}
                    </Flex>
                    <ManageEvidenceSavedSearch />
                </Flex>
                <Flex gap={6} justifyContent="space-between">
                    <Flex flexWrap="wrap" gap={2}>
                        <ManageEvidenceDashboardSearch onQueryChange={handleQueryChange} />
                        <FilterList<FilterOptionsValueT>
                            render={(content) => (
                                <>
                                    {content.filters}
                                    {content.moreFiltersMenu}
                                    {content.resetFiltersButton}
                                </>
                            )}
                            onResetFilters={() => {
                                setQuery('');
                                resetSearch();
                            }}
                            filters={filterList}
                            onFiltersChange={(newFilters) => {
                                if (!applyingSavedSearch) {
                                    handleFiltersChange(newFilters);
                                }
                            }}
                            appliedFilters={initialAppliedFilters}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </ManageEvidenceFilterContext.Provider>
    );
};
