import { LinkTypesEnum } from '@mark43/rms-api';
import { LinkTypesEnumType } from '@mark43/aml-api';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { InvolvedProfileLinkType } from '../types';
import eFileProfilesDefendantsSearchForm from '../core/state/forms/eFileProfilesDefendantsSearchForm';
import eFileProfilesVictimWitnessSearchForm from '../core/state/forms/eFileProfilesVictimWitnessSearchForm';

export const getSearchFormName = (linkType: LinkTypesEnumType) => {
    switch (linkType) {
        case LinkTypesEnum.DEFENDANT_IN_EFILE:
            return formClientEnum.E_FILES_SEARCH_PROFILES_DEFENDANTS_FORM;
        case LinkTypesEnum.INVOLVED_PROFILE_IN_EFILE:
            return formClientEnum.E_FILES_SEARCH_PROFILES_VICTIM_WITNESS_FORM;
        default:
            return '';
    }
};

export const getSearchFormConfig = (linkType: InvolvedProfileLinkType) => {
    switch (linkType) {
        case LinkTypesEnum.DEFENDANT_IN_EFILE:
            return eFileProfilesDefendantsSearchForm;
        case LinkTypesEnum.INVOLVED_PROFILE_IN_EFILE:
            return eFileProfilesVictimWitnessSearchForm;
        default:
            return null;
    }
};
