import React from 'react';
import { FilterMenuRenderProps } from 'arc';
import { createField } from 'markformythree';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { MFTText } from '../../forms/components/Text';
import { NItemsFormFilter } from '.';

const strings = componentStrings.evidence.dashboard.filterForm;

const fieldsConfig = { reportingEventNumber: createField<string>({}) };
export type RenItemValueT = {
    reportingEventNumber: string;
};

export const RenNItemsFilter: React.FC<FilterMenuRenderProps<RenItemValueT>> = ({
    appliedOptions,
    ...props
}) => {
    const fields = useFields([REPORT_REPORTING_EVENT_NUMBER]);

    return (
        <NItemsFormFilter<RenItemValueT>
            fields={fieldsConfig}
            appliedOptions={appliedOptions}
            name={fields.REPORT_REPORTING_EVENT_NUMBER}
            render={() => (
                <MFTText path="reportingEventNumber" label={fields.REPORT_REPORTING_EVENT_NUMBER} />
            )}
            addText={strings.addRen(fields.REPORT_REPORTING_EVENT_NUMBER)}
            {...props}
        />
    );
};
