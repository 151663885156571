import React, { useContext, useEffect, useState } from 'react';
import { Divider, Drawer, DrawerBody, SideNav, SideNavItem, Text, Box, useToast } from 'arc';
import styled from 'styled-components';
import { GoodDataDashboard, JsonApiOriginTypeEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import insightsResource from '../../dashboard/resources/insightsResource';
import { abilitiesEnum, OnlyWithAbility } from '../../../core/abilities';
import { InsightsContext } from './InsightsContext';

const strings = componentStrings.insights.NavigationDrawer;

const StyledDrawer = styled(Drawer)`
    background-color: var(--arc-colors-surface-canvas) !important;

    & .chakra-modal__close-btn {
        display: none;
    }
`;

const StyledDrawerBody = styled(DrawerBody)`
    padding: var(--arc-space-3);
`;

const StyledSideNavItem = styled(SideNavItem)`
    font-weight: var(--arc-fontWeights-semibold);

    & .chakra-icon {
        color: var(--arc-colors-text-primary);
    }
`;

const StyledSideNavItemButton = styled(StyledSideNavItem)`
    font-weight: var(--arc-fontWeights-bold);
    border: 1px solid var(--arc-colors-border-default);
`;

const NavigationDrawer: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
    isOpen,
    onClose,
}) => {
    const { setGoodDataDashboardId, setGoodDataNewDashboardPath } =
        useContext(InsightsContext) || {};
    const [dashboards, setDashboards] = useState<GoodDataDashboard[]>([]);
    const toast = useToast();

    useEffect(
        () => {
            insightsResource
                .getAllDashboards()
                .then((dashboards) => {
                    const sortedDashboards = dashboards.sort((a, b) =>
                        (a.title || 'Untitled').localeCompare(b.title || 'Untitled')
                    );
                    setDashboards(sortedDashboards);
                })
                .catch(() => {
                    toast({
                        status: 'error',
                        description: strings.failedToLoadDashboardserror,
                    });
                });
        } /* toast dependency cause unnecessary re-renders */,
        /* eslint-disable react-hooks/exhaustive-deps */
        []
    );

    const publicDashboards = dashboards.filter(
        (dashboard) => dashboard.originType === JsonApiOriginTypeEnum.PARENT.name
    );
    const personalDashboards = dashboards.filter(
        (dashboard) => dashboard.originType === JsonApiOriginTypeEnum.NATIVE.name
    );

    return (
        <StyledDrawer
            placement="right"
            size="md"
            variant="inline"
            isOpen={isOpen}
            onClose={onClose}
            onEsc={onClose}
        >
            <StyledDrawerBody>
                <SideNav>
                    <OnlyWithAbility has={abilitiesEnum.INSIGHTS.CREATOR}>
                        <StyledSideNavItemButton
                            icon="Add"
                            label={strings.addNewDashboard}
                            onClick={() => setGoodDataNewDashboardPath()}
                        />

                        <Divider />
                    </OnlyWithAbility>
                    {publicDashboards.length > 0 && (
                        <>
                            {publicDashboards.map((dashboard) => (
                                <StyledSideNavItem
                                    key={dashboard.id}
                                    icon="AnalyticsDashboard"
                                    label={dashboard.title || 'Untitled'}
                                    onClick={() => setGoodDataDashboardId(dashboard.id)}
                                />
                            ))}
                        </>
                    )}
                    {personalDashboards.length > 0 && (
                        <>
                            <Divider />
                            <Box w="100%" textAlign="left">
                                <Text fontSize="sm" fontWeight="normal">
                                    Personal Dashboards
                                </Text>
                            </Box>
                            {personalDashboards.map((dashboard) => (
                                <StyledSideNavItem
                                    key={dashboard.id}
                                    icon="AnalyticsDashboard"
                                    label={dashboard.title || 'Untitled'}
                                    onClick={() => setGoodDataDashboardId(dashboard.id)}
                                />
                            ))}
                        </>
                    )}
                </SideNav>
            </StyledDrawerBody>
        </StyledDrawer>
    );
};

export default NavigationDrawer;
