import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import _, { filter, isEmpty, map, pick, reduce, some, trim, head, get } from 'lodash';

import { createFormConfiguration, createNItems, _Form, createFieldset } from 'markformythree';
import { filterFormData } from '~/client-common/helpers/formHelpers';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';

import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const EVENT_INFO_CARD_EVENT_START_UTC_PATH = 'eventStartUtc';
export const EVENT_INFO_CARD_EVENT_END_UTC_PATH = 'eventEndUtc';

export const eventInfoFormConfiguration = createFormConfiguration({
    id: {},
    reportId: {},
    // Careful with `eventStartUtc` -- because of cross-card validations for
    // Event Start Date, depending on the report, other forms subscribe to
    // this field, and are expecting this field to live at this path.
    [EVENT_INFO_CARD_EVENT_START_UTC_PATH]: {
        fieldName: fields.EVENT_DETAIL_EVENT_START_UTC,
    },
    [EVENT_INFO_CARD_EVENT_END_UTC_PATH]: {
        fieldName: fields.EVENT_DETAIL_EVENT_END_UTC,
    },
    respondingOfficerId: {
        fieldName: fields.EVENT_DETAIL_RESPONDING_OFFICER_ID,
    },
    agencyId: {
        fieldName: fields.REPORT_AGENCY_ID,
    },
    personnelUnitAttrId: {
        fieldName: fields.EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID,
    },
    assistingPersonnel: createNItems({
        fieldName: fields.DISPLAY_ONLY_EVENT_DETAIL_ASSISTING_PERSONNEL_N_ITEMS_WRAPPER,
        fields: {
            reportId: {},
            officerId: {
                fieldName: fields.ASSISTING_OFFICER_OFFICER_ID,
            },
            assistTypeAttrIds: {
                fieldName: fields.ASSISTING_OFFICER_ASSIST_TYPE_ATTR_ID,
            },
            description: {
                fieldName: fields.ASSISTING_OFFICER_DESCRIPTION,
            },
        },
    }),
    weatherAttrIds: {
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEATHER_ATTRIBUTE_ID,
    },
    weatherDescription: {
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEATHER_DESCRIPTION,
    },
    isStreetCrime: {
        fieldName: fields.EVENT_DETAIL_IS_STREET_CRIME,
    },
    anyNonOfficerFiredWeapon: {
        fieldName: fields.EVENT_DETAIL_ANY_NON_OFFICER_FIRED_WEAPON,
    },
    wasAnyoneStruckByBullet: {
        fieldName: fields.EVENT_DETAIL_WAS_ANYONE_STRUCK_BY_BULLET,
    },
    paramedicsAtScene: {
        fieldName: fields.EVENT_DETAIL_PARAMEDICS_AT_SCENE,
    },
    paramedicsUnit: {
        fieldName: fields.EVENT_DETAIL_PARAMEDICS_UNIT,
    },
    paramedics: createNItems({
        fieldName: fields.DISPLAY_ONLY_EVENT_DETAIL_PARAMEDICS_N_ITEMS_WRAPPER,
        fields: {
            name: {
                fieldName: fields.EVENT_DETAIL_SERIALIZED_PARAMEDICS,
            },
            idNumber: {
                fieldName: fields.EVENT_DETAIL_SERIALIZED_PARAMEDICS,
            },
        },
    }),
    isSchoolRelated: {
        fieldName: fields.EVENT_DETAIL_INVOLVES_SCHOOL_INCIDENT,
    },
    isArrestOrCustodialRelatedDeath: {
        fieldName: fields.EVENT_DETAIL_ARREST_OR_CUSTODIAL_RELATED_DEATH,
    },
    eventStatisticsAttrIds: {
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EVENT_STATISTICS_ATTRIBUTE_ID,
    },
    eventStatisticsDescription: {
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EVENT_STATISTICS_DESCRIPTION,
    },
    crimeDescription: {
        fieldName: fields.EVENT_DETAIL_CRIME_DESCRIPTION,
    },
    reportTakenLocation: createFieldset({
        fields: {
            locationId: {
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_EVENT_LINK_TYPE,
            },
            positionAttrId: {
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_EVENT_POSITION_ATTR_ID,
            },
            description: {
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_EVENT_DESCRIPTION,
            },
        },
    }),
    notifications: createNItems({
        fieldName: fields.DISPLAY_ONLY_EVENT_DETAIL_NOTIFICATION_N_ITEMS_WRAPPER,
        fields: {
            id: {},
            reportId: {},
            notificationTypeAttrId: {
                fieldName: fields.REPORT_NOTIFICATION_NOTIFICATION_TYPE_ATTR_ID,
            },
            notificationDateUtc: {
                fieldName: fields.REPORT_NOTIFICATION_NOTIFICATION_DATE_UTC,
            },
            details: {
                fieldName: fields.REPORT_NOTIFICATION_DETAILS,
            },
        },
    }),
    reportingParty: {
        fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_REPORTING_PARTY_IN_REPORT_LINK_TYPE,
    },
});

export function createEventInfoForm(options = {}) {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: RefContextEnum.FORM_EVENT_INFO.name,
        initialState,
        onValidate: createArbiterMFTValidationHandler(
            arbiter,
            RefContextEnum.FORM_EVENT_INFO.name,
            formatFieldByName
        ),
        validationEvents: mftArbiterValidationEvents,
        configuration: eventInfoFormConfiguration,
    });
}

export function convertToFormModel(
    eventDetail,
    weatherAttrIds,
    weatherDescription,
    eventStatisticsAttrIds,
    eventStatisticsDescription,
    report,
    reportNotifications,
    assistingPersonnel,
    paramedics,
    locationEntityLinks,
    reportingParties
) {
    const reportTakenLocation = head(locationEntityLinks);
    return {
        ...pick(
            eventDetail,
            'eventStartUtc',
            'eventEndUtc',
            'respondingOfficerId',
            'isStreetCrime',
            'anyNonOfficerFiredWeapon',
            'wasAnyoneStruckByBullet',
            'paramedicsAtScene',
            'paramedicsUnit',
            'personnelUnitAttrId',
            'isSchoolRelated',
            'isArrestOrCustodialRelatedDeath',
            'crimeDescription'
        ),
        agencyId: report.agencyId,
        weatherAttrIds,
        weatherDescription,
        eventStatisticsAttrIds,
        eventStatisticsDescription,
        assistingPersonnel: _(assistingPersonnel)
            .groupBy('officerId')
            .mapValues((assistingOfficerList, officerId) => {
                return reduce(
                    assistingOfficerList,
                    (acc, assistingOfficer) => ({
                        ...acc,
                        assistTypeAttrIds: [
                            ...acc.assistTypeAttrIds,
                            assistingOfficer.assistTypeAttrId,
                        ],
                        description: acc.description
                            ? acc.description
                            : assistingOfficer.description,
                    }),
                    { officerId, assistTypeAttrIds: [], description: '' }
                );
            })
            .values()
            .value(),
        paramedics,
        notifications: map(reportNotifications, (reportNotification) =>
            pick(
                reportNotification,
                'id',
                'notificationTypeAttrId',
                'notificationDateUtc',
                'details'
            )
        ),
        reportTakenLocation,
        reportingParty: reportingParties,
    };
}

export function convertFromFormModel(formModel, currentReport, attributes, attributeIsOther) {
    const reportId = currentReport.id;
    // Must handle manually clearing out hidden fields for `paramedics`, because
    // the paramedics fields are wrapped in custom field wrapper
    // `DISPLAY_ONLY_EVENT_DETAIL_PARAMEDICS_N_ITEMS_WRAPPER`.
    const paramedicsAtScene = formModel.paramedicsAtScene;
    const filteredParamedicsUnit =
        paramedicsAtScene === true && !isEmpty(trim(formModel.paramedicsUnit))
            ? formModel.paramedicsUnit
            : undefined;
    const filteredParamedics =
        paramedicsAtScene === true ? filterFormData(formModel.paramedics) : undefined;

    const eventDetail = {
        reportId: formModel.reportId || reportId,
        eventStartUtc: formModel[EVENT_INFO_CARD_EVENT_START_UTC_PATH],
        eventEndUtc: formModel.eventEndUtc,
        respondingOfficerId: formModel.respondingOfficerId,
        paramedicsAtScene,
        paramedicsUnit: filteredParamedicsUnit,
        paramedics: filteredParamedics,
        anyNonOfficerFiredWeapon: formModel.anyNonOfficerFiredWeapon,
        wasAnyoneStruckByBullet: formModel.wasAnyoneStruckByBullet,
        isStreetCrime: formModel.isStreetCrime,
        personnelUnitAttrId: formModel.personnelUnitAttrId,
        isSchoolRelated: formModel.isSchoolRelated,
        isArrestOrCustodialRelatedDeath: formModel.isArrestOrCustodialRelatedDeath,
        crimeDescription: formModel.crimeDescription,
    };
    const filteredEventDetail = filterFormData(eventDetail);

    const weatherReportAttributes = assignDescriptionToReportAttributes(
        map(formModel.weatherAttrIds, (weatherAttrId) => {
            return {
                reportId,
                attributeType: AttributeTypeEnum.WEATHER.name,
                attributeId: weatherAttrId,
            };
        }),
        attributes,
        formModel.weatherDescription
    );

    const eventStatisticsReportAttributes = assignDescriptionToReportAttributes(
        map(formModel.eventStatisticsAttrIds, (eventStatisticsAttrId) => {
            return {
                reportId,
                attributeType: AttributeTypeEnum.EVENT_STATISTICS.name,
                attributeId: eventStatisticsAttrId,
            };
        }),
        attributes,
        formModel.eventStatisticsDescription
    );

    const reportNotifications = map(
        filter(formModel.notifications, some),
        (reportNotification) => ({
            reportId,
            ...filterFormData(reportNotification),
        })
    );

    const assistingPersonnel = _(formModel.assistingPersonnel)
        .map((assistingOfficer) => {
            return map(assistingOfficer.assistTypeAttrIds, (assistTypeAttrId) => {
                return {
                    reportId,
                    officerId: assistingOfficer.officerId,
                    assistTypeAttrId,
                    description: attributeIsOther(assistTypeAttrId)
                        ? assistingOfficer.description
                        : '',
                };
            });
        })
        .flatten()
        .values()
        .value();

    const reportTakenLocation = formModel.reportTakenLocation;
    const locationEntityLinks = filter(
        [reportTakenLocation],
        (locationEntityLink) => !!get(locationEntityLink, 'locationId')
    );

    return {
        eventDetail: filteredEventDetail,
        weatherReportAttributes,
        eventStatisticsReportAttributes,
        agencyId: formModel.agencyId,
        reportNotifications,
        assistingPersonnel,
        locationEntityLinks,
    };
}

export function getEventDatesFromEventForm(reportId) {
    return (dispatch, getState) => {
        const eventDetailByReportId = eventDetailByReportIdSelector(getState());
        const eventStartUtc = reportId
            ? get(eventDetailByReportId(reportId), 'eventStartUtc')
            : undefined;
        const eventEndUtc = reportId
            ? get(eventDetailByReportId(reportId), 'eventEndUtc')
            : undefined;

        return {
            eventStartUtc,
            eventEndUtc,
        };
    };
}
