import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { intersectionBy, reduce } from 'lodash';

import { Offense } from '@mark43/rms-api';

import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_OFFENSE } from '~/client-common/core/enums/universal/fields';
import { useResource } from '~/client-common/core/hooks/useResource';
import getReportsResource from '~/client-common/core/domain/reports/resources/reportResource';

import { nibrsOffenseCodeIdOptionsSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/ui';
import { offenseReportIdForCurrentReportRENSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import { AttributeOption } from '../../../core/forms/helpers/selectHelpers';

const useOffenseCodesFromOffenseReportToFilterOptions = () => {
    // @ts-expect-error client-common to client RND-7529
    const nibrsOffenseCodeIdOptions: AttributeOption[] = useSelector(
        nibrsOffenseCodeIdOptionsSelector
    );

    const [offenseCodeOptions, setOffenseCodeOptions] = useState<AttributeOption[]>([]);

    const offenseReportIdForCurrentReportREN = useSelector(
        offenseReportIdForCurrentReportRENSelector
    );

    const { [DISPLAY_ONLY_OFFENSE]: offenseFieldName } = useFields(DISPLAY_ONLY_OFFENSE);

    const getOffensesForOffenseReportId = useCallback(() => {
        if (!!offenseReportIdForCurrentReportREN) {
            return getReportsResource().getOffensesForReport(offenseReportIdForCurrentReportREN);
        }
        return Promise.reject(new Error(`No linked ${offenseFieldName} Report was found`));
    }, [offenseFieldName, offenseReportIdForCurrentReportREN]);

    const onSuccess = useCallback(
        (offensesForOffenseReport: Offense[]) => {
            const nibrsOffenseCodesInOffense = reduce(
                offensesForOffenseReport,
                (result: { value: number }[], offense) => {
                    if (!!offense.nibrsOffenseCodeId) {
                        result.push({ value: offense.nibrsOffenseCodeId });
                    }
                    return result;
                },
                []
            );

            const options = intersectionBy(
                nibrsOffenseCodeIdOptions,
                nibrsOffenseCodesInOffense,
                'value'
            );
            const shouldFilterNibrsOffenseCodeIdOptions = !!options.length;
            if (shouldFilterNibrsOffenseCodeIdOptions) {
                setOffenseCodeOptions(options);
            } else {
                setOffenseCodeOptions(nibrsOffenseCodeIdOptions);
            }
            return;
        },
        [nibrsOffenseCodeIdOptions, setOffenseCodeOptions]
    );

    const onError = useCallback(() => {
        setOffenseCodeOptions(nibrsOffenseCodeIdOptions);
    }, [nibrsOffenseCodeIdOptions]);

    useResource(getOffensesForOffenseReportId, onSuccess, onError);

    return offenseCodeOptions;
};

export default useOffenseCodesFromOffenseReportToFilterOptions;
