import { get } from 'lodash';
import { Offense, Arrest, EntityTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { MFTFieldConfigurationDiscriminator, MFTFormConfigurationDescriminator } from "markformythree";
import { offensesByReportIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { linkedReportShortTitleViewModelsForReportSelector } from '~/client-common/core/domain/report-short-titles/state/ui';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { currentReportSelector } from '../../../legacy-redux/selectors/reportSelectors';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { logError } from '../../../core/logging';
import formsRegistry from "../../../core/formsRegistry";
import { FieldDescriptorType } from './helpers/types';

type Relationship = {
    description?: string;
    displayLinkOptionIds?: string[];
    nameEntityType?: string;
    nameId?: number;
    otherNameEntityType?: string;
    otherNameId?: number;
    relationshipLinkTypes?: object[];
    hasRelationships: boolean;
};

type SupportedEntityTypeMap = {
    [EntityTypeEnum.OFFENSE.name]: Offense;
    [EntityTypeEnum.ARREST.name]: Arrest;
    [EntityTypeEnum.PERSON_PROFILE.name]: Relationship;
};

type RelationshipFormConfiguration = {
    relationships?: Relationship[] & MFTFieldConfigurationDiscriminator;
} & MFTFormConfigurationDescriminator

type SupportedEntity = SupportedEntityTypeMap[SupportedEntityType];
type SupportedEntityType = keyof SupportedEntityTypeMap;

type GetDataFromLinkedEntitiesProps = {
    /**
     * The type of linked entity we want to grab data from
     */
    entityType: SupportedEntityType;
    /**
     * The property from the linked entity that we want to evaluate
     */
    propertyName: string;
    /**
     * The value we expect our entity to have at the given `propertyName`
     * It is not expected that this will ever be undefined or an empty string
     */
    expectedValue: string;
    /**
     * If multiple instances of the linked entity are defined,
     * determine if they should all match our `expectedValue`,
     * or if its sufficient for just one to match
     */
    quantifier: 'ALL' | 'ANY';
};

function getValueFromEntity(entity: SupportedEntity, propertyName: string) {
    return get(entity, `customProperties.${propertyName}`) || get(entity, propertyName);
}

function getRelationships(): Relationship[] {
    const model = formsRegistry.get<RelationshipFormConfiguration>(RefContextEnum.FORM_RELATIONSHIPS.name)?.get();
    const relationships = model?.relationships ?? [];

    return relationships.map((r) => {
        const hasRelationships = !!r.relationshipLinkTypes?.length;
        return { ...r, hasRelationships };
    });
}

/**
 * This RFG allows us to generically grab data from a linked entity (within the REN, but outside the form)
 *
 * Example:
 *      - entityType: `OFFENSE`
 *      - propertyName: `isDomesticViolence`
 *      - expectedValue: `true`
 *      - expectedValueType: `BOOLEAN`
 *      - quantifier: `ALL`
 *
 * When this rule runs, if:
 *      All offenses related to the current report will have `isDomesticViolence === true`
 *
 * Then the RFG will yield `true` because the wanted conditions are satisfied
 *
 * We should apply this RFG to the field in our form that should trigger this RFG
 * (even though we aren't using the field's value at all)
 *
 * For instance, if we want this to run when `PERSON_PROFILE_FIRST_NAME` changes,
 * we would apply this RFG to that field
 */
export const getDataFromLinkedEntitiesMatchesValue = (getState: () => RootState) => (
    _fieldDescriptor: FieldDescriptorType,
    args: GetDataFromLinkedEntitiesProps
) => {
    const state = getState();

    const currentReport = currentReportSelector(state);


    if (isUndefinedOrNull(currentReport)) {
        // If there is no report (which should never be the case),
        // allow the rule to pass
        return true;
    }

    /**
     * To get our linked reports we will copy the logic that powers the current ReportSidebar:
     * https://github.com/mark43/rms/blob/02c5a3bbd03d3053af65b663dc135be8ca7f2792/client/src/scripts/legacy-redux/components/reports/ReportSidebar.js#L133-L141
     *
     * Once we have our linked reports, we can iterate through each one and manually query for our linked entities
     */
    const linkedReportIds = linkedReportShortTitleViewModelsForReportSelector(state)(
        currentReport
    ).map((shortTitle) => shortTitle.reportId);

    const offenses = linkedReportIds.flatMap((reportId) =>
        offensesByReportIdSelector(state)(reportId)
    );

    const relationships = getRelationships();

    const allData: Record<SupportedEntityType, SupportedEntity[]> = {
        [EntityTypeEnum.OFFENSE.name]: offenses,
        [EntityTypeEnum.PERSON_PROFILE.name]: relationships,
        // There's no use-case for this right now -- when we need it, we should add this in
        [EntityTypeEnum.ARREST.name]: [],
    };

    const getEntityContainsWantedData = (entity: SupportedEntity) => {
        // For each entity, we should also check `customProperties`
        const entityValue = getValueFromEntity(entity, args.propertyName);

        if (isUndefinedOrNull(entityValue)) {
            return args.expectedValue === '';
        } else {
            // Currently, comparisons will be limited by this set of primitives
            switch (typeof entityValue) {
                case 'boolean': {
                    return entityValue === (args.expectedValue.toLowerCase() === 'true');
                }
                case 'string': {
                    return entityValue === args.expectedValue;
                }
                case 'number': {
                    return entityValue === parseFloat(args.expectedValue);
                }
                default: {
                    // It's not expected that we get into this branch.
                    // If we do, allow the rule to pass, and also log an error to sentry
                    // to further investigate why
                    logError(
                        'An invalid data-type was returned from the "getDataFromLinkedEntitiesMatchesValue" RFG',
                        {
                            extras: {
                                dataType: typeof entityValue,
                            },
                        }
                    );
                    return true;
                }
            }
        }
    };

    return args.quantifier === 'ALL'
        ? allData[args.entityType].every(getEntityContainsWantedData)
        : allData[args.entityType].some(getEntityContainsWantedData);
};
