import React, { useCallback, useState, useRef } from 'react';
import { Flex } from '@arc/layout';
import { Button, useToast } from 'arc';
import styled from 'styled-components';
import { IOverlayBaseRenderProps } from 'overlay-manager/lib/OverlayBase';
import invariant from 'invariant';
import { DiagramCategoryEnum, DiagramTypeEnum } from '@mark43/rms-api';
import { CenterT, MapRefShapeT } from '~/client-common/core/maps/types';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { objectValuesAreEmpty } from '~/client-common/helpers/objectHelpers';
import overlayIdEnum, {
    OverlayIdEnumType,
} from '~/client-common/core/enums/universal/overlayIdEnum';
import { useScreenBreakpoint } from '../../../../../../core/utils/useScreenBreakpoint';
import { ADD_MAP_STAGE_HEIGHT } from '../config';

import EsriSimpleMapWrapper from '../../../../../../core/maps/components/EsriSimpleMapWrapper';
import { OverlayBaseHelper } from '../../../../../../core/components/OverlayBaseHelper';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { logError } from '../../../../../../../core/logging';
import errorToMessage from '../../../../../../core/errors/utils/errorToMessage';
import { useOverlayStore } from '../../../../../../core/overlays/hooks/useOverlayStore';
import { JPG_FORMAT, MAP_MODAL_TOAST_ID, STANDARD_ZOOM } from '../constants';
import { DiagramModal } from './DiagramModal';

const ActionsContainer = styled.div`
    display: flex;
    justify-content: end;
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    overflow: auto;
`;

const crashDiagramModalStrings = componentStrings.dragon.crashDiagram.CrashDiagramModal;
const AddMapBackgroundModalStrings = componentStrings.dragon.crashDiagram.AddMapBackgroundModal;
const esriStrings = componentStrings.core.maps.Esri;

interface AddMapBackgroundModalPropsT {
    center: CenterT;
    crashDiagramOverlayId: OverlayIdEnumType;
}

interface AddMapBackgroundModalRenderProps {
    overlayBase: IOverlayBaseRenderProps<AddMapBackgroundModalPropsT>;
}

export const AddMapBackgroundModal: React.FC = () => {
    return (
        <OverlayBaseHelper id={overlayIdEnum.ADD_MAP_BACKGROUND_MODAL}>
            {(renderProps: AddMapBackgroundModalRenderProps) => {
                const { center, crashDiagramOverlayId } =
                    renderProps.overlayBase.overlayState.customProperties;
                return (
                    <AddMapBackgroundModalContent
                        center={center}
                        crashDiagramOverlayId={crashDiagramOverlayId}
                    />
                );
            }}
        </OverlayBaseHelper>
    );
};

const AddMapBackgroundModalContent: React.FC<AddMapBackgroundModalPropsT> = ({
    center,
    crashDiagramOverlayId,
}) => {
    const overlayStore = useOverlayStore();
    const { isMobile } = useScreenBreakpoint();
    const toast = useToast();
    const [isMapLoading, setIsMapLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { addExternalAsset } = useCrashDiagram();
    const buttonSize = isMobile ? 'sm' : 'md';
    const isCenterUndefined = objectValuesAreEmpty(center);
    const mapRef = useRef<MapRefShapeT>(null);
    const isDisabled = isCenterUndefined || isMapLoading || isError || !mapRef.current;

    const onMapLoadingError = useCallback(() => {
        if (!toast.isActive(MAP_MODAL_TOAST_ID)) {
            toast({
                id: MAP_MODAL_TOAST_ID,
                description: esriStrings.loadingError,
                status: 'error',
            });
        }
    }, [toast]);

    const handleOpenCrashDiagramModal = () => {
        overlayStore.close(overlayIdEnum.ADD_MAP_BACKGROUND_MODAL);
        overlayStore.open(crashDiagramOverlayId);
    };

    const handleUseMapAsBackgroundButtonClicked = async () => {
        try {
            invariant(
                mapRef.current,
                'Unable to take a screenshot of the map view because mapRef.current is undefined'
            );

            const dataUrl = await mapRef.current.onTakeScreenshot(JPG_FORMAT);
            if (dataUrl) {
                addExternalAsset(
                    DiagramTypeEnum.CRASH.name,
                    DiagramCategoryEnum.CRASH_BACKGROUND.name,
                    dataUrl
                );
                handleOpenCrashDiagramModal();
            }
        } catch (error) {
            logError(errorToMessage(error), { error });
            throw error;
        }
    };

    return (
        <DiagramModal
            overlayId={overlayIdEnum.ADD_MAP_BACKGROUND_MODAL}
            okText={crashDiagramModalStrings.okText}
            title={crashDiagramModalStrings.title}
        >
            <ActionsContainer>
                <Flex gap={10}>
                    <Button
                        variant="outline"
                        size={buttonSize}
                        onClick={handleOpenCrashDiagramModal}
                    >
                        {AddMapBackgroundModalStrings.skipButtonText}
                    </Button>
                    <Button
                        disabled={isDisabled}
                        leadingVisual="Check"
                        variant="solid"
                        size={buttonSize}
                        onClick={handleUseMapAsBackgroundButtonClicked}
                    >
                        {AddMapBackgroundModalStrings.addMapAsBackgroundButtonText}
                    </Button>
                </Flex>
            </ActionsContainer>
            <EsriSimpleMapWrapper
                mapRef={mapRef}
                styles={{
                    position: 'relative',
                    width: '100%',
                    height: ADD_MAP_STAGE_HEIGHT,
                    overflow: 'hidden',
                }}
                defaultCenter={center}
                enableMove
                enableZoom
                mapMode="streets-navigation-vector"
                zoom={STANDARD_ZOOM}
                zoomForUpdate={STANDARD_ZOOM}
                zoomButtonStyle="arc"
                onLoadingStatusChange={(loading) => setIsMapLoading(loading)}
                onErrorStatusChange={(error) => setIsError(error)}
                onMapLoadingError={onMapLoadingError}
            />
        </DiagramModal>
    );
};
