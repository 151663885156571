import {
    EntityTypeEnumType,
    LocationBundle,
    LocationEntityLink,
    LocationView,
    LinkTypesEnum,
} from '@mark43/rms-api';
import { filter, forEach, map, omit, pick } from 'lodash';
import Promise from 'bluebird';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import {
    NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
    replaceLocationEntityLinksWhere,
    replaceLocationLinksForEntity,
    locationEntityLinksSelector,
} from '../../../location-entity-links/state/data';
import getLocationsResource from '../../resources/locationsResource';
import { convertLocationBundlesToLocationViews } from '../../utils/locationHelpers';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'locationsTODO';

// This file is nominally of LocationViews, but may contain other location shapes
const locationsModule = createNormalizedModule<LocationView>({
    type: NEXUS_STATE_PROP,
});

// ACTION TYPES
const LOAD_LOCATIONS_LINKED_TO_ENTITY_START = 'locations/LOAD_LOCATIONS_LINKED_TO_ENTITY_START';
const LOAD_LOCATIONS_LINKED_TO_ENTITY_SUCCESS = 'locations/LOAD_LOCATIONS_LINKED_TO_ENTITY_SUCCESS';
const LOAD_LOCATIONS_LINKED_TO_ENTITY_FAILURE = 'locations/LOAD_LOCATIONS_LINKED_TO_ENTITY_FAILURE';
const SAVE_LOCATION_START = 'locations/SAVE_LOCATION_START';
const SAVE_LOCATION_SUCCESS = 'locations/SAVE_LOCATION_SUCCESS';
const SAVE_LOCATION_FAILURE = 'locations/SAVE_LOCATION_FAILURE';

// ACTIONS
const storeLocations = locationsModule.actionCreators.storeEntities;

function loadLocationsLinkedToEntityStart() {
    return {
        type: LOAD_LOCATIONS_LINKED_TO_ENTITY_START,
    };
}
function loadLocationsLinkedToEntitySuccess() {
    return {
        type: LOAD_LOCATIONS_LINKED_TO_ENTITY_SUCCESS,
    };
}
function loadLocationsLinkedToEntityFailure(errorMessage: string) {
    return {
        type: LOAD_LOCATIONS_LINKED_TO_ENTITY_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

/**
 * Load all locations linked to the given entity. Each location includes its
 *   `entityLinks` array.
 */
export function loadLocationsLinkedToEntity(
    entityType: EntityTypeEnumType,
    entityId: number
): ClientCommonAction<Promise<void>> {
    const locationsResource = getLocationsResource();

    // @ts-expect-error Our installed version of Bluebird types `Promise.method` as `method(fn: Function): Function`
    // which results in a return type that is too broad. The solution is to upgrade Bluebird in TNP-532.
    return Promise.method((dispatch, getState) => {
        dispatch(loadLocationsLinkedToEntityStart());

        const state = getState();
        const locationEntityLinks = filter(locationEntityLinksSelector(state), {
            entityType,
            entityId,
        });
        // if the entity's linked locations are cached in state, return them
        // without making an API request; if the entity has no linked locations,
        // an API request is made each time this action creator is dispatched
        // (can improve this later)
        if (locationEntityLinks.length > 0) {
            const locationIds = map(locationEntityLinks, 'locationId');
            const locations = pick(locationsSelector(state), locationIds);
            dispatch(loadLocationsLinkedToEntitySuccess());
            return locations;
        }

        return locationsResource
            .getLocationsLinkedToEntity([{ type: entityType, id: entityId }])
            .then((locationBundles: LocationBundle[]) => {
                // within each LocationBundle, convert the Location model to a
                // LocationView model
                const locations = convertLocationBundlesToLocationViews(locationBundles);
                dispatch(loadLocationsLinkedToEntitySuccess());
                // store the location entities in data state
                dispatch(storeLocations(locations));
                // store the location entity links in data state
                forEach(locationBundles, ({ location, entityLinks }) => {
                    dispatch(
                        replaceLocationEntityLinksWhere(
                            {
                                locationId: location.id,
                            },
                            entityLinks
                        )
                    );
                });
                return locations;
            })
            .catch((err: Error) => {
                dispatch(loadLocationsLinkedToEntityFailure(err.message));
                throw err;
            });
    });
}

function saveLocationStart() {
    return {
        type: SAVE_LOCATION_START,
    };
}
function saveLocationSuccess() {
    return {
        type: SAVE_LOCATION_SUCCESS,
    };
}
function saveLocationFailure(errorMessage: string) {
    return {
        type: SAVE_LOCATION_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

/**
 * Create or update a location.
 */
export function saveLocation({
    location,
    omitLocationEntityLinks,
}: {
    location: LocationView;
    omitLocationEntityLinks?: boolean;
}): ClientCommonAction<Promise<void>> {
    const locationsResource = getLocationsResource();

    return function (dispatch, getState, { nexus }) {
        dispatch(saveLocationStart());

        const { entityLinks = [] } = location;
        const { entityId, entityType } = entityLinks[0] || {};
        const { linkType }: { linkType: number } = entityLinks[0] || {};

        // right now, replacing LocationEntityLinks does not work on the BE. as such, we need to delete any links
        // before saving down the new ones. once this is fixed, this deletion will no longer be necessary
        // see https://mark43.atlassian.net/browse/RMS-3637
        let resetEntityLinksPromise: Promise<LocationEntityLink[] | void> = Promise.resolve();
        if (
            !omitLocationEntityLinks &&
            entityLinks.length > 0 &&
            (linkType === LinkTypesEnum.CASE_NOTE_LOCATION ||
                linkType === LinkTypesEnum.DEPARTMENT_PROFILE_ADDRESS ||
                linkType === LinkTypesEnum.PROPERTY_RECOVERED_LOCATION ||
                linkType === LinkTypesEnum.EVIDENCE_FACILITY_LOCATION)
        ) {
            resetEntityLinksPromise = dispatch(
                replaceLocationLinksForEntity(entityType, entityId, [])
            );
        }

        if (omitLocationEntityLinks) {
            // @ts-expect-error what shape is this supposed to be?
            location = omit(location, 'entityLinks');
        }

        const upsertLocation = locationsResource.upsertLocation(location);

        return Promise.all([resetEntityLinksPromise, upsertLocation])
            .then(([, location]) => {
                // store the location in data state, excluding its entity links
                // because they're not part of the model

                // @ts-expect-error this line should not be present because it's no longer storing locationViews
                dispatch(storeLocations([omit(location, 'entityLinks')]));

                let successAction = saveLocationSuccess();
                // store the location entity links in data state
                if (!omitLocationEntityLinks) {
                    successAction = nexus.withEntityItems(
                        {
                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: location.entityLinks,
                        },
                        successAction
                    );
                }

                dispatch(successAction);

                return location;
            })
            .catch((err: Error) => {
                dispatch(saveLocationFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS

/**
 * Locations normalized by id. These correspond to LocationView models on the
 *   server, not Location models.
 */
export const locationsSelector = locationsModule.selectors.entitiesSelector;

export const locationByIdSelector = locationsModule.selectors.entityByIdSelector;

// REDUCER
export default locationsModule.reducerConfig;
