import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { get, isFunction } from 'lodash';
import { Report, ReportDefinition } from '@mark43/rms-api';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import withFields from '~/client-common/core/fields/components/withFields';
import { DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT } from '~/client-common/core/enums/universal/fields';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import { Field } from '~/client-common/core/fields/state/config';
import { reportDefinitionRestrictViewReportOwnersSelector } from '~/client-common/core/domain/report-definitions/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import { RmsDispatch } from '../../../../../core/typings/redux';
import Modal from '../../../../../legacy-redux/components/core/Modal';
import { submitReturnReportToDraftModal } from '../../state/ui';
import { createModalSelector } from '../../../../core/box/state/ui';
import {
    reportOwnerIdSelector,
    approvalStatusSelector,
    currentReportSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { currentUserIdSelector } from '../../../../core/current-user/state/ui';
import { RestrictVisibilityWithDash } from '../../../../core/components/RestrictVisibilityWithDash';
import { custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector } from '../../../../core/report-definitions/state/ui';
import { useIsReportOwnerRestricted } from '../../../../core/hooks/useIsReportOwnerRestricted';

const strings = componentStrings.reports.core.ReturnReportToDraftModal;

const context = {
    name: boxEnum.RETURN_REPORT_TO_DRAFT_MODAL,
};

function getModalTitle(clientApprovalStatus: keyof typeof approvalStatusClientEnum) {
    switch (clientApprovalStatus) {
        case approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW:
            return strings.title;
        case approvalStatusClientEnum.PENDING_SECONDARY_REVIEW:
            return strings.pendingSupervisorTitle;
        default:
            return strings.title;
    }
}

function getModalContent(
    clientApprovalStatus: keyof typeof approvalStatusClientEnum,
    draftDisplayFieldName: string
) {
    switch (clientApprovalStatus) {
        case approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW:
            return strings.defaultNote(draftDisplayFieldName);
        case approvalStatusClientEnum.PENDING_SECONDARY_REVIEW:
            return strings.pendingSupervisorNote(draftDisplayFieldName);
        default:
            return strings.defaultNote(draftDisplayFieldName);
    }
}

interface ReturnReportToDraftModalPropsT {
    reportOwnerId: number | undefined;
    currentReport: Report | undefined;
    custodialPropertySummaryReportDefinition: ReportDefinition | undefined;
    currentUserId: number | undefined;
    callback: ReturnType<typeof createModalSelector>;
    clientApprovalStatus: keyof typeof approvalStatusClientEnum;
    fieldDisplayNames: Record<Field, string>;
    submitReturnReportToDraftModal: () => Promise<void>;
    reportDefinitionRestrictViewReportOwners: ReturnType<
        typeof reportDefinitionRestrictViewReportOwnersSelector
    >;
}

function ReturnReportToDraftModal({
    currentReport,
    custodialPropertySummaryReportDefinition,
    currentUserId,
    reportOwnerId,
    submitReturnReportToDraftModal,
    callback,
    fieldDisplayNames,
    clientApprovalStatus,
    reportDefinitionRestrictViewReportOwners,
}: ReturnReportToDraftModalPropsT) {
    const checkIsReportOwnerRestricted = useIsReportOwnerRestricted();

    if (!currentReport) {
        return null;
    }

    const title = getModalTitle(clientApprovalStatus);

    const content = getModalContent(
        clientApprovalStatus,
        fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT
    );

    const isCPSReport =
        currentReport.reportDefinitionId === get(custodialPropertySummaryReportDefinition, 'id');

    const restrictViewReportOwners = reportDefinitionRestrictViewReportOwners(
        currentReport.reportDefinitionId
    );

    return (
        <Modal
            title={title}
            context={context}
            onSave={() => {
                submitReturnReportToDraftModal().then(() => {
                    if (isFunction(callback)) {
                        callback();
                    }
                });
            }}
        >
            {content}
            {currentUserId !== reportOwnerId && !isCPSReport && (
                <div>
                    {'You will replace '}
                    <FeatureFlagged
                        flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                        fallback={
                            <ConnectedFormattedUser
                                userId={reportOwnerId}
                                format={FORMATS.FULL_NAME}
                            >
                                {(user) => <b>{user}</b>}
                            </ConnectedFormattedUser>
                        }
                    >
                        <RestrictVisibilityWithDash
                            restricted={checkIsReportOwnerRestricted(
                                restrictViewReportOwners,
                                currentReport.permissionSet
                            )}
                        >
                            <ConnectedFormattedUser
                                userId={reportOwnerId}
                                format={FORMATS.FULL_NAME}
                            >
                                {(user) => <b>{user}</b>}
                            </ConnectedFormattedUser>
                        </RestrictVisibilityWithDash>
                    </FeatureFlagged>

                    {' as the owner of this report.'}
                </div>
            )}
        </Modal>
    );
}

const mapStateToProps = createStructuredSelector({
    reportOwnerId: reportOwnerIdSelector,
    currentReport: currentReportSelector,
    custodialPropertySummaryReportDefinition:
        custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector,
    currentUserId: currentUserIdSelector,
    callback: createModalSelector(context, 'callback'),
    clientApprovalStatus: approvalStatusSelector,
    reportDefinitionRestrictViewReportOwners: reportDefinitionRestrictViewReportOwnersSelector,
});

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    submitReturnReportToDraftModal: () => dispatch(submitReturnReportToDraftModal()),
});

export default compose<ReturnReportToDraftModalPropsT, ReturnReportToDraftModalPropsT>(
    withFields([DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT]),
    connect(mapStateToProps, mapDispatchToProps)
)(ReturnReportToDraftModal);
