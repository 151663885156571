import { useEffect, useContext } from 'react';
import { RefContextEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { Flex } from 'arc';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FederatedSearchExternalWriteDataContext } from 'mark43-federated-search';

import { FieldState, Observer, lifecycleOptions } from 'markformythree';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useItemTypeNames } from '~/client-common/core/fields/hooks/useItemTypeNames';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import { attachmentsWhereSelector } from '~/client-common/core/domain/attachments/state/data';

import { SidePanelSection } from '../../../../../legacy-redux/components/core/SidePanel';
import InlineAttachmentsUploader from '../../../../attachments/core/components/InlineAttachmentsUploader';
import _NoticeBar from '../../../../core/components/NoticeBar';
import Divider from '../../../../core/components/Divider';
import _ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { MFTButtonRadio } from '../../../../core/forms/components/ButtonRadio';
import _Button from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../../legacy-redux/components/core/Icon';
import { loadEntityProfile } from '../../../../entity-profiles/core/state/data';
import { initialState } from '../../state/forms/hydratedItemForm';
import { formConfiguration } from '../../state/forms/hydratedItemFormConfiguration';
import testIds from '../../../../../core/testIds';
import {
    updateUploadingFiles,
    clearUploads,
    clearDeletedAttachments,
} from '../../../../attachments/core/state/ui/inlineAttachmentsUploader';
import {
    itemSidePanelMasterItemIdSelector,
    alwaysHideItemTypeNavigationSelector,
} from '../../state/ui/itemSidePanel';
import FirearmFieldset from './fieldsets/FirearmFieldset';
import DrugsFieldset from './fieldsets/DrugsFieldset';
import OtherItemFieldset from './fieldsets/OtherItemFieldset';
import VehicleFieldset from './fieldsets/VehicleFieldset';
import { AdditionalDetailsFieldset } from './fieldsets/AdditionalDetailsFieldset';
import { VehicleRegistrationFieldset } from './fieldsets/VehicleRegistrationFieldset';
import { VehicleDescriptionFieldset } from './fieldsets/VehicleDescriptionFieldset';
import SharedItemDetailsFieldset from './fieldsets/SharedItemDetailsFieldset';
import PropertyStatusesFieldset from './fieldsets/PropertyStatusesFieldset';
import AssociatedNamesFieldset from './fieldsets/AssociatedNamesFieldset';
import ImpoundInfoFieldset from './fieldsets/ImpoundInfoFieldset';
import CustodyStatusFieldset from './fieldsets/CustodyStatusFieldset';
import RecoveryInfoFieldset from './fieldsets/RecoveryInfoFieldset';
import { ItemLocationFieldset } from './fieldsets/ItemLocationFieldset';

import _VehicleInlineSearch from './VehicleInlineSearch';

const strings = componentStrings.reports.core.HydratedItemForm;

const Button = styled(_Button)`
    box-sizing: border-box;
    margin: 0;
    width: 100%;
`;

const StyledMFTButtonRadio = styled(MFTButtonRadio)`
    display: flex;
    gap: var(--arc-space-2);
    margin-bottom: var(--arc-space-2);
`;

const ItemTypeButtonRadio = ({ form, operation }) => {
    const itemTypeNames = useItemTypeNames();
    const propertyItemTypeOptions = [
        {
            value: globalAttributes.itemType.firearm,
            display: itemTypeNames[globalAttributes.itemType.firearm],
        },
        {
            value: globalAttributes.itemType.drugs,
            display: itemTypeNames[globalAttributes.itemType.drugs],
        },
        {
            value: globalAttributes.itemType.item,
            display: itemTypeNames[globalAttributes.itemType.item],
        },
    ];
    return (
        <StyledMFTButtonRadio
            path="itemTypeAttrId"
            options={propertyItemTypeOptions}
            deselectable={false}
            testId={testIds.ITEM_TYPE_BUTTONS}
        >
            {(buttonProps) => (
                <Button
                    onClick={buttonProps.onClick}
                    className={buttonProps.className}
                    testId={buttonProps.testId}
                    disabled={!!(operation === 'EDIT' && form.get('itemTypeAttrId'))}
                    iconLeft={<Icon type={iconTypes.ADD} />}
                    testDisplay={buttonProps.children}
                >
                    {buttonProps.children}
                </Button>
            )}
        </StyledMFTButtonRadio>
    );
};

const VehicleInlineSearch = styled(_VehicleInlineSearch)`
    display: flex;
    flex-direction: column;
`;

const ArbiterForm = styled(_ArbiterForm)`
    padding-bottom: 160px;
`;

const NoticeBar = styled(_NoticeBar)`
    background: ${(props) => props.theme.colors.lightBlue};
    border-color: ${(props) => props.theme.colors.brightBlue};
`;

function HydratedItemForm({
    helpTextCollisionBoundary,
    isInPoliceCustodyDisabled,
    isStorageLocationIdDisabled,
    isVehicle,
    formIsHidden,
    operation,
    locationSummaryView,
    reportingEventNumber,
    ownerType,
    ownerId,
    itemProfileId,
    query,
    openDexFormElem,
    dexData,
    alwaysHideItemsInlineSearch,
}) {
    const hideForm = isVehicle && formIsHidden;

    const dispatch = useDispatch();
    const masterItemProfileId = useSelector(itemSidePanelMasterItemIdSelector);
    const attachmentsWhere = useSelector(attachmentsWhereSelector);
    const { resetResultForConsumerApp } = useContext(FederatedSearchExternalWriteDataContext) || {};
    const alwaysHideItemTypeNavigation = useSelector(alwaysHideItemTypeNavigationSelector);

    useEffect(() => {
        if (!masterItemProfileId) {
            return;
        }
        const attachmentsAreLoaded =
            attachmentsWhere({
                entityId: masterItemProfileId,
                entityType: EntityTypeEnum.ITEM_PROFILE.name,
            }).length > 0;

        if (!attachmentsAreLoaded) {
            const entityTypeToLoad = isVehicle ? 'vehicles' : 'property';
            dispatch(loadEntityProfile(entityTypeToLoad, masterItemProfileId));
        }
    }, [dispatch, masterItemProfileId, attachmentsWhere, isVehicle]);

    useEffect(
        () => () => {
            dispatch(updateUploadingFiles());
            dispatch(clearUploads());
            dispatch(clearDeletedAttachments());
            resetResultForConsumerApp?.();
        },
        [dispatch, resetResultForConsumerApp]
    );

    const isEditMode = operation === itemSidePanelOperationEnum.EDIT;
    const showVehicleInlineSearch = isVehicle && !isEditMode && !alwaysHideItemsInlineSearch;

    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={RefContextEnum.FORM_HYDRATED_ITEM.name}
            context={RefContextEnum.FORM_HYDRATED_ITEM.name}
            configuration={formConfiguration}
            initialState={initialState}
            render={(form) => {
                const noticeBar =
                    operation === itemSidePanelOperationEnum.DUPLICATE ? (
                        <NoticeBar>{strings.itemDuplicationRequiresUniqueIdentifiers}</NoticeBar>
                    ) : null;

                const isImpoundDisabled =
                    isInPoliceCustodyDisabled && isEditMode && form.get('isImpounded');
                const isExhibitNumberDisabled =
                    isEditMode && !!form.get('custodyStatus.exhibitNumber');

                return (
                    <div>
                        <Flex direction="column">
                            {noticeBar}
                            {!isVehicle && !alwaysHideItemTypeNavigation && (
                                <ItemTypeButtonRadio form={form} operation={operation} />
                            )}
                            <FeatureFlagged
                                flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                                fallback={
                                    <>
                                        {showVehicleInlineSearch && (
                                            <VehicleInlineSearch
                                                helpTextCollisionBoundary={
                                                    helpTextCollisionBoundary
                                                }
                                                query={query}
                                                dexData={dexData}
                                            />
                                        )}
                                    </>
                                }
                            >
                                {showVehicleInlineSearch && hideForm && (
                                    <VehicleInlineSearch
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        query={query}
                                        dexData={dexData}
                                    />
                                )}
                            </FeatureFlagged>
                        </Flex>
                        {hideForm && openDexFormElem}
                        {!hideForm && (
                            <div>
                                <FieldState
                                    path="itemTypeAttrId"
                                    render={({ model }) => {
                                        // TODO: Remove this FieldState since the fields within
                                        // these fieldsets should all show/hide correctly based
                                        // on Rules.
                                        switch (model) {
                                            case globalAttributes.itemType.firearm:
                                                return (
                                                    <FirearmFieldset
                                                        editMode={isEditMode}
                                                        form={form}
                                                        helpTextCollisionBoundary={
                                                            helpTextCollisionBoundary
                                                        }
                                                    />
                                                );
                                            case globalAttributes.itemType.drugs:
                                                return (
                                                    <DrugsFieldset
                                                        form={form}
                                                        helpTextCollisionBoundary={
                                                            helpTextCollisionBoundary
                                                        }
                                                    />
                                                );
                                            case globalAttributes.itemType.item:
                                                return (
                                                    <OtherItemFieldset
                                                        form={form}
                                                        helpTextCollisionBoundary={
                                                            helpTextCollisionBoundary
                                                        }
                                                    />
                                                );
                                            case globalAttributes.itemType.vehicle: {
                                                const vehicleFieldSets = (
                                                    <>
                                                        <VehicleRegistrationFieldset
                                                            helpTextCollisionBoundary={
                                                                helpTextCollisionBoundary
                                                            }
                                                        />
                                                        <VehicleDescriptionFieldset
                                                            form={form}
                                                            helpTextCollisionBoundary={
                                                                helpTextCollisionBoundary
                                                            }
                                                        />
                                                        <AdditionalDetailsFieldset
                                                            helpTextCollisionBoundary={
                                                                helpTextCollisionBoundary
                                                            }
                                                        />
                                                    </>
                                                );

                                                return (
                                                    <FeatureFlagged
                                                        flag="RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED"
                                                        fallback={
                                                            <FeatureFlagged
                                                                flag="RMS_VEHICLE_CAUTIONS_ENABLED"
                                                                fallback={
                                                                    <VehicleFieldset
                                                                        form={form}
                                                                        helpTextCollisionBoundary={
                                                                            helpTextCollisionBoundary
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                {vehicleFieldSets}
                                                            </FeatureFlagged>
                                                        }
                                                    >
                                                        {vehicleFieldSets}
                                                    </FeatureFlagged>
                                                );
                                            }
                                            default:
                                                return <div />;
                                        }
                                    }}
                                />
                                <Divider />
                                <SharedItemDetailsFieldset
                                    form={form}
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                />
                            </div>
                        )}
                        {!hideForm && (
                            <div>
                                {/* nameItemLinks are passed here beacuse the downstream components
                                    wont update themselves automatically
                                */}
                                <Observer
                                    subscriptions={{
                                        nameItemLinks: 'nameItemLinks',
                                    }}
                                    render={({ nameItemLinks }) => (
                                        <AssociatedNamesFieldset
                                            ownerType={ownerType}
                                            ownerId={ownerId}
                                            form={form}
                                            nameItemLinks={nameItemLinks}
                                            reportingEventNumber={reportingEventNumber}
                                        />
                                    )}
                                />
                                <PropertyStatusesFieldset
                                    form={form}
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    isVehicle={isVehicle}
                                    filterOutSeized={!reportingEventNumber}
                                />
                                <ImpoundInfoFieldset
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    isImpoundDisabled={isImpoundDisabled}
                                />
                                <CustodyStatusFieldset
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    isExhibitNumberDisabled={isExhibitNumberDisabled}
                                    isInPoliceCustodyDisabled={
                                        isInPoliceCustodyDisabled || !reportingEventNumber
                                    }
                                    isStorageLocationIdDisabled={isStorageLocationIdDisabled}
                                />
                                <RecoveryInfoFieldset
                                    form={form}
                                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                                    locationSummaryView={locationSummaryView}
                                />
                                <FeatureFlagged flag={'RMS_ANPR_IMPROVEMENTS_ENABLED'}>
                                    <ItemLocationFieldset
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        itemProfileId={itemProfileId}
                                        itemType={
                                            isVehicle
                                                ? globalAttributes.itemType.vehicle
                                                : undefined
                                        }
                                        form={form}
                                    />
                                </FeatureFlagged>
                            </div>
                        )}
                        {!hideForm && (
                            <SidePanelSection title={strings.additionalAttachments}>
                                <InlineAttachmentsUploader
                                    buttonText={strings.attachments}
                                    entityId={masterItemProfileId}
                                    entityType={EntityTypeEnum.ITEM_PROFILE.name}
                                    linkType={LinkTypesEnum.ITEM_PROFILE_ATTACHMENT}
                                />
                            </SidePanelSection>
                        )}
                    </div>
                );
            }}
        />
    );
}

/**
 * Form for creating/editing a single item (property or vehicle) and all of its hydrated data.
 */
export default HydratedItemForm;
