import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, toInteger } from 'lodash';
import { ElasticSearchTypeEnum } from '@mark43/rms-api';

import {
    scrollableNodeNameEnum,
    ScrollableNodesContext,
} from '../../../core/context/ScrollableNodesContext';

function getScrollableNodeNameForElasticSearchType(elasticSearchType) {
    switch (elasticSearchType) {
        case ElasticSearchTypeEnum.CASE.name:
        case ElasticSearchTypeEnum.CASE_ASSIGNED_TO_ME.name:
        case ElasticSearchTypeEnum.REPORT_NOT_ASSIGNED_TO_CASE.name:
        case ElasticSearchTypeEnum.REPORTS_DASHBOARD.name:
            return scrollableNodeNameEnum.SCROLLABLE_UNDER_SUBHEADER;
        case ElasticSearchTypeEnum.ATTACHMENTS.name:
        case ElasticSearchTypeEnum.CAD_TICKET.name:
        case ElasticSearchTypeEnum.ORGANIZATION.name:
        case ElasticSearchTypeEnum.PERSON.name:
        case ElasticSearchTypeEnum.PROPERTY.name:
        case ElasticSearchTypeEnum.REPORT.name:
        case ElasticSearchTypeEnum.VEHICLE.name:
        default:
            return scrollableNodeNameEnum.APP_CONTENT;
    }
}

/**
 *
 * @param {any} param0
 * @returns
 */
export function useDashboardScroll({ isScrollToElement = undefined, router, searchModule }) {
    const scrollableNodeName = getScrollableNodeNameForElasticSearchType(
        searchModule.elasticSearchType
    );
    const refs = useContext(ScrollableNodesContext);
    const ref = refs[scrollableNodeName];

    const dispatch = useDispatch();
    const scrollPosition = useSelector(searchModule.selectors.scrollPositionSelector);
    const triggerScroll = useSelector(searchModule.selectors.triggerScrollSelector);

    const getCurrentScrollPosition = useCallback(() => {
        if (isNil(ref.current)) {
            return;
        }

        // may give us decimal value, but can only be set to integers
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollTop
        return toInteger(ref.current.scrollTop);
    }, [ref]);

    const scrollToTop = useCallback(() => {
        if (isScrollToElement) {
            // Advanced Search uses scroll to element
            searchModule.scrollToElement();
        } else {
            // Reports/Cases Dashboards can scroll to top
            ref.current.scrollTop = 0;
        }
    }, [isScrollToElement, ref, searchModule]);

    useEffect(() => {
        if (isNil(ref.current) || !triggerScroll) {
            return;
        }

        // react-router - BOTH back AND forward browser navigation will trigger 'POP' action
        const routerPopAction = router.location.action === 'POP';
        const restoreScrollOnBack = scrollPosition && routerPopAction;

        if (restoreScrollOnBack) {
            ref.current.scrollTop = scrollPosition;
        } else {
            scrollToTop();
        }

        dispatch(searchModule.actionCreators.setTriggerScroll(false));
    }, [dispatch, ref, router, scrollPosition, scrollToTop, searchModule, triggerScroll]);

    return [getCurrentScrollPosition];
}
