import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FederatedSearchClient, QueryableEntityType } from 'mark43-federated-search';
import { withRouter, InjectedRouter } from 'react-router';
import { ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import { queryParamDefaults } from '~/client-common/configs/advancedSearchConfig';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import OverlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import Subheader from '../../core/components/Subheader';
import { RmsDispatch } from '../../../core/typings/redux';
import { currentUserProfileSelector } from '../../core/current-user/state/ui';
import redirectToErrorPage from '../../core/utils/redirectToErrorPage';
import { resetNotificationCount, getFederatedSearchNotificationsCount } from '../state/ui';
import UnderSubheader from '../../core/components/UnderSubheader';
import { openBox } from '../../../legacy-redux/actions/boxActions';
import { openCreateReportModal } from '../../reports/core/state/ui/createReportModal';
import { PersonSidePanel } from '../../core/persons/components/PersonSidePanel';
import { useOverlayStore } from '../../core/overlays/hooks/useOverlayStore';
import { SCREENS } from '../../core/persons/state/data';
import elasticSearchResource from '../../../legacy-redux/resources/elasticSearchResource';

const personOverlayId = OverlayIdEnum.PERSON_OVERLAY_NAME_SUMMARY_VIEW_WRAPPER;

export const FederatedSearch = withRouter(({ router }: { router: InjectedRouter }) => {
    const dispatch = useDispatch<RmsDispatch>();
    const userProfile = useSelector(currentUserProfileSelector);
    const notificationsCount = useSelector(getFederatedSearchNotificationsCount);

    useEffect(() => {
        if (notificationsCount && userProfile) {
            dispatch(resetNotificationCount());
        }
    }, [dispatch, notificationsCount, userProfile]);

    useEffect(() => {
        if (!userProfile) {
            dispatch(redirectToErrorPage());
        }
    }, [dispatch, userProfile]);

    if (!userProfile) {
        return null;
    }

    const overlayStore = useOverlayStore();

    const applicationSettings = useSelector(applicationSettingsSelector);
    const reportRecorsdWithoutRenEnabled =
        applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;

    const addToReport = useCallback(
        ({ reportId }) => {
            if (reportId) {
                router.push(`reports/${reportId}`);
            } else {
                if (reportRecorsdWithoutRenEnabled) {
                    dispatch(openCreateReportModal());
                } else {
                    dispatch(openBox({ name: boxEnum.CREATE_REPORT_MODAL }));
                }
            }
        },
        [dispatch, reportRecorsdWithoutRenEnabled, router]
    );
    const addToProfile = useCallback(
        ({ profileId, result, resultEntityType }) => {
            if (resultEntityType === QueryableEntityType.PERSON) {
                const dexPerson = result?.PERSON?.[0];

                if (profileId) {
                    router.push(`/profiles/persons/${profileId}`);
                } else {
                    overlayStore.open(personOverlayId, {
                        entityId: profileId,
                        entityType: EntityTypeEnum.PERSON_PROFILE.name,
                        ownerId: profileId,
                        ownerType: EntityTypeEnum.PERSON_PROFILE.name,
                        isEditingMasterProfile: !!profileId,
                        redirectAfterSave: true,
                        dexPerson,
                        screenStack: [
                            {
                                screen: profileId ? SCREENS.PROFILE_EDIT : SCREENS.PROFILE_ADD_NEW,
                                screenState: {
                                    currentScreen: profileId
                                        ? SCREENS.PROFILE_EDIT
                                        : SCREENS.PROFILE_ADD_NEW,
                                    selectedId: profileId,
                                    idToIdentify: profileId,
                                    dexPerson,
                                },
                            },
                        ],
                    });
                }
            } else if (
                (resultEntityType === QueryableEntityType.VEHICLE ||
                    resultEntityType === QueryableEntityType.BOAT) &&
                profileId
            ) {
                router.push(`/profiles/vehicles/${profileId}`);
            } else if (
                (resultEntityType === QueryableEntityType.FIREARM ||
                    resultEntityType === QueryableEntityType.ARTICLE) &&
                profileId
            ) {
                router.push(`/profiles/property/${profileId}`);
            }
        },
        [overlayStore, router]
    );

    const searchReports = async (searchString: string) => {
        const response = await elasticSearchResource.searchAll({
            size: queryParamDefaults.SIZE,
            query: searchString,
            searchTypes: [ElasticSearchTypeEnum.REPORT.name],
        });
        return response.reports;
    };

    const searchProfiles = async (
        searchString: string,
        entityType: QueryableEntityType
    ) => {
        if (entityType === QueryableEntityType.PERSON) {
            const response = await elasticSearchResource.searchAll({
                size: queryParamDefaults.SIZE,
                query: searchString,
                searchTypes: [ElasticSearchTypeEnum.PERSON.name],
            });
            return response.persons;
        } else if (
            entityType === QueryableEntityType.VEHICLE ||
            entityType === QueryableEntityType.BOAT
        ) {
            const response = await elasticSearchResource.searchAll({
                size: queryParamDefaults.SIZE,
                query: searchString,
                searchTypes: [ElasticSearchTypeEnum.VEHICLE.name],
            });
            return response.vehicles;
        } else if (
            entityType === QueryableEntityType.FIREARM ||
            entityType === QueryableEntityType.ARTICLE
        ) {
            const response = await elasticSearchResource.searchAll({
                size: queryParamDefaults.SIZE,
                query: searchString,
                searchTypes: [ElasticSearchTypeEnum.PROPERTY.name],
            });
            return response.property;
        } else {
            return null;
        }
    };

    return (
        <>
            <Subheader fullscreen title={componentStrings.core.navigation.dex} />
            <UnderSubheader fullscreen={true} style={{ maxWidth: '100%', margin: 0 }}>
                <FederatedSearchClient
                    buttonsConfiguration={{
                        report: {
                            addToReport,
                            searchReports,
                        },
                        profile: {
                            addToProfile,
                            searchProfiles,
                            disableNewProfileCreationForEntities: [
                                QueryableEntityType.VEHICLE,
                                QueryableEntityType.BOAT,
                                QueryableEntityType.FIREARM,
                                QueryableEntityType.ARTICLE,
                            ],
                        },
                    }}
                />
            </UnderSubheader>

            <PersonSidePanel
                overlayId={personOverlayId}
                ownerType={EntityTypeEnum.PERSON_PROFILE.name}
            />
        </>
    );
});
