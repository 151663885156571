import { EntityPermissionView } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

const entityPermissionsModule = createLinkModule<EntityPermissionView>({
    type: 'entityPermissions',
    keys: ['entityType', 'entityId', 'roleId'],
});

export const NEXUS_STATE_PROP = 'entityPermissions';

// ACTIONS
export const storeEntityPermissions = entityPermissionsModule.actionCreators.storeLinks;

// SELECTORS
export const entityPermissionsSelector = entityPermissionsModule.selectors.linksSelector;

// REDUCER
export default entityPermissionsModule.reducerConfig;
