import { Key } from 'react';
import { DefaultFilterOptionValueT, FilterOptionT, VisibleFilter } from 'arc';
import { DateRangeQuery } from '@mark43/rms-api';
import invariant from 'invariant';
import { PickByValue } from 'utility-types';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import {
    convertDateRangeFieldValuesToOptionValue,
    formatCustomRange,
    getDateRangeOptions,
} from '~/client-common/core/dates/utils/dateRangeHelpers';
import DateRangeTypeEnum from '~/client-common/core/enums/client/dateRangeTypeEnum';
import { formatFieldValueRRF } from '~/client-common/helpers/formHelpers';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { isDateRangeQuery } from '../../utils/type-guards';

export * from './CreateFilterHelper';

type EnumType = Record<string, { name: string; value: number }>;
export const enumToFilterOptions = <TObj extends EnumType>(obj: TObj) => {
    return Object.values(obj).reduce<FilterOptionT[]>((acc, current) => {
        acc.push({
            id: current.value,
            value: current.name,
            label: current.name,
        });
        return acc;
    }, []);
};

export const mapVisibleFiltersToQuery = <T = DefaultFilterOptionValueT>(
    filters: VisibleFilter<T>[]
) => {
    // it can't be inferred directly from type because of the Key type from FilterOptionT
    const getOptionsIds = <TReturn extends unknown[]>(options: FilterOptionT<T>[]) =>
        options.map((item) => item.id) as TReturn;

    return {
        // important to use correct ids for appliedOptions
        getMultiSelectOptions: <TInitial extends unknown[]>(id: Key, initial: TInitial) => {
            const filter = filters.find((item) => item.id === id);

            return filter ? getOptionsIds<TInitial>(filter.appliedOptions) : initial;
        },

        // text filter should always place value as first item of appliedOptions
        getTextOption: <TInitial extends string>(id: Key, initial?: TInitial) => {
            const filter = filters.find((item) => item.id === id);
            if (!filter) {
                return initial ?? '';
            }
            if (!filter.appliedOptions || !filter.appliedOptions.length) {
                return initial ?? '';
            }

            if (typeof filter.appliedOptions[0].value !== 'string') {
                return initial ?? '';
            }

            return filter.appliedOptions[0].value;
        },

        // Use to build an array of the multiple values provided. Consumer must provide the right key for the values to map to.
        getNItemsOptions: <TInitial extends []>(id: Key, initial?: TInitial) => {
            const filter = filters.find((item) => item.id === id);
            if (!filter?.appliedOptions?.length) {
                return initial ?? [];
            }

            return filter.appliedOptions.map((item) => item.value);
        },

        // filter pass value as DateRangeQuery object for the first item in appliedOptions
        getDateRangeOption: (id: Key, initial: DateRangeQuery) => {
            const filter = filters.find((item) => item.id === id);
            if (!filter) {
                return initial;
            }
            if (!filter.appliedOptions || !filter.appliedOptions.length) {
                return initial;
            }

            return filter.appliedOptions[0].value as DateRangeQuery;
        },
    };
};

function assertDateRangeQuery(value: unknown): asserts value is DateRangeQuery {
    invariant(isDateRangeQuery(value), 'Filter value must be a DateRangeQuery');
}

function assertKeyArray(value: unknown): asserts value is Key[] {
    invariant(Array.isArray(value), 'Filter value is not an array');
    for (let i = 0; i < value.length; i++) {
        invariant(
            typeof value[i] === 'string' || typeof value[i] === 'number',
            'value item type is not Key type'
        );
    }
}

type SearchSqlQuery = Record<never, never>;
export const mapQueryToVisibleFilters = <TSearchSqlQuery extends SearchSqlQuery>(
    elasticQuery: TSearchSqlQuery,
    boundSelectors: {
        dateTimeFormatter: ReturnType<typeof currentDepartmentDateFormatterSelector>;
        formatFieldValue: typeof formatFieldValueRRF;
    }
) => {
    const { dateTimeFormatter, formatFieldValue } = boundSelectors;
    const defaultDateRangeOptions = getDateRangeOptions({
        withinLastPeriodOptions: ['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D'],
        toDatePeriodOptions: ['P1M', 'P1Y'],
        includeCustomFilterOption: true,
    });

    return {
        getEnumSelectFilter: <T extends keyof PickByValue<TSearchSqlQuery, (string | number)[]>>(
            id: T,
            enumType: EnumType
        ) => {
            const query: unknown = elasticQuery[id];
            assertKeyArray(query);

            return {
                id,
                appliedOptions: enumToFilterOptions(enumType).filter((option) =>
                    query.includes(option.id)
                ),
            };
        },
        getMultiSelectFilter: <T extends keyof PickByValue<TSearchSqlQuery, (string | number)[]>>(
            id: T,
            type: keyof typeof fieldTypeClientEnum
        ) => {
            const query: unknown = elasticQuery[id];
            assertKeyArray(query);

            return {
                id,
                appliedOptions: query.map((id) => ({
                    id,
                    value: String(id),
                    label: formatFieldValue(id, { type }),
                })),
            };
        },
        getCustomMultiSelectFilter: <
            T extends keyof PickByValue<TSearchSqlQuery, (string | number)[]>,
            TOption = string
        >(
            id: T,
            formatItem: (item: Key, index: number) => FilterOptionT<TOption>
        ) => {
            const query: unknown = elasticQuery[id];
            assertKeyArray(query);
            return {
                id,
                appliedOptions: query.map(formatItem),
            };
        },
        getTextFilter: <T extends keyof PickByValue<TSearchSqlQuery, string | number | undefined>>(
            id: T
        ) => {
            return {
                id,
                appliedOptions: [
                    {
                        id,
                        value: String(elasticQuery[id]),
                        label: String(elasticQuery[id]),
                    },
                ],
            };
        },
        getDateRangeFilter: <T extends keyof PickByValue<TSearchSqlQuery, DateRangeQuery>>(
            id: T,
            dateRangeOptions?: ReturnType<typeof getDateRangeOptions>
        ) => {
            const query: unknown = elasticQuery[id];
            assertDateRangeQuery(query);

            const { startDateUtc, endDateUtc } = query;
            const checker = convertDateRangeFieldValuesToOptionValue(query);

            return {
                id,
                appliedOptions: (dateRangeOptions ?? defaultDateRangeOptions)
                    .filter((item) => item.value === checker)
                    .map((item) => ({
                        id: item.value,
                        value: query,
                        label:
                            item.value === DateRangeTypeEnum.CUSTOM_RANGE
                                ? formatCustomRange(
                                      startDateUtc,
                                      endDateUtc,
                                      false,
                                      dateTimeFormatter
                                  )
                                : item.display,
                    })),
            };
        },
    };
};
