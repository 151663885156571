import React from 'react';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { OverlayIdEnumType } from '~/client-common/core/enums/universal/overlayIdEnum';
import Modal, { ModalProps } from '../../../../../../core/overlays/components/Modal';
import { useScreenBreakpoint } from '../../../../../../core/utils/useScreenBreakpoint';
import {
    MODAL_CONTENT_WIDTH,
    MODAL_WIDTH_LARGE,
    MODAL_WIDTH_MEDIUM,
    MODAL_WIDTH_MOBILE,
    STAGE_WIDTH_MEDIUM,
    STAGE_WIDTH_MOBILE,
} from '../config';

export const ModalContentWrapper = styled.div`
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    border-radius: var(--arc-radii-md);
    position: relative;
`;

const crashDiagramModalStrings = componentStrings.dragon.crashDiagram.CrashDiagramModal;

type DiagramModalProps<T extends Record<string, unknown> = Record<string, unknown>> =
    React.PropsWithChildren<{
        overlayId: OverlayIdEnumType;
        okText?: string;
        title?: string;
    }> &
        Omit<ModalProps<T>, 'id' | 'children'>;

export const DiagramModal = <T extends Record<string, unknown> = Record<string, unknown>>(
    props: DiagramModalProps<T>
) => {
    const { overlayId, children, ...modalProps } = props;
    const { isMobile, isLargeScreen } = useScreenBreakpoint();

    return (
        <Modal
            contentStyle={{
                minWidth: isMobile
                    ? STAGE_WIDTH_MOBILE
                    : isLargeScreen
                      ? MODAL_CONTENT_WIDTH
                      : STAGE_WIDTH_MEDIUM,
            }}
            id={overlayId}
            cancelText={crashDiagramModalStrings.cancelText}
            closeOnSave={false}
            modalStyle={{
                minWidth: isMobile
                    ? MODAL_WIDTH_MOBILE
                    : isLargeScreen
                      ? MODAL_WIDTH_LARGE
                      : MODAL_WIDTH_MEDIUM,
                maxHeight: '100%',
            }}
            saveDisabled={true}
            {...modalProps}
        >
            <ModalContentWrapper>{children}</ModalContentWrapper>
        </Modal>
    );
};
