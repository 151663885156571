import { EFile, EntityPermissionView, EntityTypeEnum, OperationTypeEnum } from '@mark43/rms-api';
import { useDispatch, useSelector } from 'react-redux';
import { userSearchResultsByIdSelector } from '~/client-common/core/domain/user-search-results/state/data';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { useFormGetter } from '../../core/forms/hooks/useFormGetter';
import { useOverlayStore } from '../../core/overlays/hooks/useOverlayStore';
import {
    entityPermissionsByIdSelector,
    loadPermissionsForModal,
} from '../../core/permissions/state/ui';
import entityPermissionsResource, {
    EntityPermissionForUpdate,
} from '../../core/permissions/resources/entityPermissionsResource';
import { isOwnerPermission } from '../helpers/permissions';

export const useEFileUpdatePermissions = () => {
    const dispatch = useDispatch();
    const { getForm } = useFormGetter();
    const overlayStore = useOverlayStore();

    const entityPermissionsById = useSelector(entityPermissionsByIdSelector);
    const userSearchResultsById = useSelector(userSearchResultsByIdSelector);

    return async (eFile: EFile, prevEFile: EFile) => {
        const permissions = entityPermissionsById(EntityTypeEnum.E_FILE.name, eFile.id);
        const existingPermissions: EntityPermissionView[] = permissions.filter(
            (permission) => !isOwnerPermission(permission, prevEFile.ownerUserId)
        );

        const newOwnerUser = userSearchResultsById(eFile.ownerUserId);
        const permissionsForUpdate: (EntityPermissionView | EntityPermissionForUpdate)[] = [
            ...existingPermissions,
        ];

        if (newOwnerUser) {
            const doesNewOwnerUserHavePermission = existingPermissions.find((permission) =>
                isOwnerPermission(permission, newOwnerUser.userRoleId)
            );
            const newOwnerPermission: EntityPermissionForUpdate = {
                entityId: eFile.id,
                entityType: EntityTypeEnum.E_FILE.name,
                operationType: OperationTypeEnum.MANAGE.name,
                roleId: newOwnerUser.userRoleId,
            };

            if (!doesNewOwnerUserHavePermission) {
                permissionsForUpdate.push(newOwnerPermission);
            }
        }

        await entityPermissionsResource.updatePermissions(
            EntityTypeEnum.E_FILE.name,
            eFile.id,
            permissionsForUpdate
        );

        const eFilePermissionsForm = getForm(formClientEnum.E_FILE_PERMISSIONS_FORM);
        const eFilePermissionsOverlayState = overlayStore.getStateForId(
            overlayIdEnum.E_FILE_PERMISSIONS_OVERLAY
        );

        if (
            eFilePermissionsForm &&
            eFilePermissionsOverlayState &&
            eFilePermissionsOverlayState.isOpen
        ) {
            dispatch(
                loadPermissionsForModal(
                    overlayIdEnum.E_FILE_PERMISSIONS_OVERLAY,
                    EntityTypeEnum.E_FILE.name,
                    eFile.id
                )
            );
        }
    };
};
