import { logError } from '../../core/logging';
import errorToMessage from '../../modules/core/errors/utils/errorToMessage';

export const safeJsonParse = <T>(str: string) => {
    try {
        const jsonValue: T = JSON.parse(str);

        return jsonValue;
    } catch (error) {
        logError(errorToMessage(error, `Failed to parse JSON string. Unknown error`), {
            string: str,
        });
        return undefined;
    }
};
