import React from 'react';
import { map, difference, sortBy, get } from 'lodash';
import styled from 'styled-components';
import { FormControl } from 'arc';
import { Radio, RadioGroup } from '@arc/radio';
import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum, RefContextEnum } from '@mark43/rms-api';

import strings from '~/client-common/core/strings/componentStrings';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import Row from '../../../core/components/Row';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFTextArea } from '../../../core/forms/components/TextArea';
import { RRFCourtCodeAttributeSelect } from '../../../core/forms/components/selects/CourtCodeSelect';
import { FIELD_WIDTH_50, FIELD_WIDTH_100 } from '../../core/configuration';
import { RRFInlineAttachmentsUploader } from '../../../attachments/core/components/InlineAttachmentsUploader';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { StyledRRFCheckbox as _StyledRRFCheckbox } from '../../core/components/Form';
import Select from '../../../core/forms/components/selects/Select';
import SealingChargesDropdown from './SealingChargesDropdown';

const StyledRRFCheckbox = styled(_StyledRRFCheckbox)`
    margin-bottom: 13px;
`;

const SealingFormContainer = styled.div`
  margin-top: var(--arc-space-4);
`;

class ReportPartialSealingFormFields extends React.Component {
    handleFileUpload = (files) => {
        const courtOrderFileIds = this.props.formModelByPath('courtOrderFileIds') || [];
        const fileIds = map(files, 'file.id');
        const updatedCourtOrderFileIds = [...courtOrderFileIds, ...fileIds];
        this.props.changePath('courtOrderFileIds', sortBy(updatedCourtOrderFileIds));
    };

    handleFileDelete = (fileId) => {
        const courtOrderFileIds = this.props.formModelByPath('courtOrderFileIds');
        const updatedCourtOrderFileIds = difference(courtOrderFileIds, [fileId]);
        this.props.changePath('courtOrderFileIds', updatedCourtOrderFileIds);
    };

    handleAttachmentDelete = (attachment) => {
        const courtOrderFileIds = this.props.formModelByPath('courtOrderFileIds');
        const updatedCourtOrderFileIds = difference(courtOrderFileIds, [
            get(attachment, 'file.id'),
        ]);
        this.props.changePath('courtOrderFileIds', updatedCourtOrderFileIds);
    };

    getSelectedRadioValue(isStatuteSealing) {
        if(typeof isStatuteSealing !== 'boolean') {return '';}
        return isStatuteSealing ? 'statutory-seal' : 'court-order-seal';
    }

    render() {
        const {
            showPersonRelatedFields = true,
            currentContext,
            showArrestIdField,
            showStateIdField,
            onSealingTypeChange = () => {},
            isStatuteSealing,
            involvedPersons,
            statuteSealingPersonIdsToSeal,
            personIdToSeal,
            onSealUnsealClick,
            reportId,
            isEditing = false,
        } = this.props;

        const isRadioSelected = typeof isStatuteSealing === 'boolean';
        const datesRow = (
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <RRFDatePicker
                    path="courtOrder.courtCaseDateUtc"
                    dateWidth="100%"
                    width={FIELD_WIDTH_50}
                />
                <RRFDatePicker
                    path="courtOrder.dateToPurgeUtc"
                    dateWidth="100%"
                    width={FIELD_WIDTH_50}
                />
            </Row>
        );

        return (
            <SealingFormContainer>
                {currentContext !==
                    RefContextEnum.FORM_CHARGE_VACATING.name && (
                    <Row style={{ paddingBottom: 'var(--arc-space-4)' }}>
                        <FormControl label="Select Court Action Type" size="md">
                            <RadioGroup
                                defaultValue={'court-order-seal'}
                                value={this.getSelectedRadioValue(isStatuteSealing)}
                                name="court-action-types"
                                onChange={(value) => {
                                    onSealingTypeChange.call(
                                        this,
                                        value === 'statutory-seal'
                                    );
                                }}
                            >
                                {strings.recordPrivacy.sealing.CourtActionTypes.map(
                                    (radioItem, index) => (
                                        <Radio
                                            key={`${radioItem.value}-${index}`}
                                            value={radioItem.value}
                                            isChecked={
                                                isStatuteSealing ===
                                                radioItem.checked
                                            }
                                        >
                                            {radioItem.label}
                                        </Radio>
                                    )
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Row>
                )}
                {isRadioSelected && <>
                    {showPersonRelatedFields && (
                        <Row>
                            <Select
                                options={involvedPersons.map((person) => ({
                                    value: person.id,
                                    label: formatFullName(person),
                                }))}
                                onChange={(personId) => {
                                    onSealUnsealClick(personId, involvedPersons);
                                }}
                                isRequired={true}
                                value={
                                    isStatuteSealing
                                        ? statuteSealingPersonIdsToSeal
                                        : personIdToSeal
                                }
                                label={
                                    strings.recordPrivacy.sealing
                                        .SealingPersonsDropdown.sectionTitle
                                }
                                disabled={isEditing}
                                multiple={isStatuteSealing}
                                clearable
                            />
                        </Row>
                    )}
                    {!isStatuteSealing && (
                        <StyledRRFCheckbox
                            path="courtOrder.isJuvenile"
                            disabled={this.props.disableIsJuvenile}
                        />
                    )}
                    <Row>
                        <SealingChargesDropdown reportId={reportId} />
                    </Row>
                    {isStatuteSealing ? (
                        <>
                            <Row>
                                <RRFAttributeSelect
                                    attributeType={
                                        AttributeTypeEnum.SEALING_STATUTE_CODE.name
                                    }
                                    path="courtOrder.statuteCodeAttrId"
                                    label={
                                        strings.recordPrivacy.courtOrders
                                            .CourtOrderEditForm.courtOrderAttrId
                                    }
                                    shouldShowFieldNames={true}
                                    width={FIELD_WIDTH_100}
                                />
                            </Row>
                            {datesRow}
                        </>
                    ) : (
                        <>
                            <Row>
                                <RRFText
                                    path="courtOrder.courtCaseNumber"
                                    width={FIELD_WIDTH_100}
                                />
                            </Row>
                            <Row>
                                <RRFText
                                    path="courtOrder.courtCaseStatute"
                                    width={FIELD_WIDTH_100}
                                />
                            </Row>
                            <Row>
                                <RRFCourtCodeAttributeSelect
                                    attributeType={
                                        AttributeTypeEnum.COURT_CODE.name
                                    }
                                    path="courtOrder.courtCodeAttrId"
                                    width={FIELD_WIDTH_100}
                                    currentContext={currentContext}
                                />
                            </Row>
                            {datesRow}
                            <Row>
                                {showArrestIdField && (
                                    <RRFText
                                        disabled
                                        path="arrestIdNumber"
                                        width={FIELD_WIDTH_100}
                                    />
                                )}
                                {showStateIdField && (
                                    <RRFText
                                        disabled
                                        path="courtOrder.stateIdNumber"
                                        width={FIELD_WIDTH_100}
                                    />
                                )}
                            </Row>
                        </>
                    )}
                    <Row>
                        <RRFTextArea
                            path="courtOrder.remarks"
                            width={FIELD_WIDTH_100}
                        />
                    </Row>
                    <Row>
                        <RRFInlineAttachmentsUploader
                            path={'courtOrderFileIds'}
                            entityId={this.props.courtOrderId}
                            entityType={EntityTypeEnum.COURT_ORDER.name}
                            linkType={LinkTypesEnum.COURT_ORDER_ATTACHMENT}
                            onFileUploadFinish={this.handleFileUpload}
                            onDeleteUpload={this.handleFileDelete}
                            onAttachmentDelete={this.handleAttachmentDelete}
                        />
                    </Row>
                </>}
            </SealingFormContainer>
        );
    }
}

export default ReportPartialSealingFormFields;
