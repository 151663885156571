import React from 'react';
import PropTypes from 'prop-types';
import componentStrings from '~/client-common/core/strings/componentStrings';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';

import {
    REPORT_REPORTING_EVENT_NUMBER,
    DISPLAY_ONLY_CUSTODY_LABEL,
} from '~/client-common/core/enums/universal/fields';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';

const strings = componentStrings.evidence.dashboard.EvidenceDashboardSearchResults;

const EvidenceDashboardSearchResultsTableHeader = ({
    formatFieldByName,
    handleSortKeyClick,
    handleSortTypeClick,
    querySortKey,
    querySortType,
}) => (
    <TableColumns>
        <OptionsTableColumn
            display={(title) => title?.display}
            columnKey="reportingEventNumber"
            activeValue={querySortKey}
            activeSortType={querySortType}
            onOptionClick={handleSortKeyClick}
            onSortTypeClick={handleSortTypeClick}
            width={220}
            popoutWidth={194}
        >
            <OptionsTableColumnOption
                display={strings.columns.relevance}
                value={sortKeyEnum.ELASTICSEARCH_SCORE}
            />
            <OptionsTableColumnOption
                display={strings.columns.dateAcquired.label}
                value={sortKeyEnum.EVIDENCE_ITEM_DATE_ACQUIRED}
                sortOptions={[
                    {
                        display: strings.columns.dateAcquired.descendingSort,
                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                    },
                    {
                        display: strings.columns.dateAcquired.ascendingSort,
                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                    },
                ]}
            />
            <OptionsTableColumnOption
                display={strings.columns.chainEventDate.label}
                value={sortKeyEnum.EVIDENCE_ITEM_CHAIN_EVENT_DATE}
                sortOptions={[
                    {
                        display: strings.columns.chainEventDate.descendingSort,
                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                    },
                    {
                        display: strings.columns.chainEventDate.ascendingSort,
                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                    },
                ]}
            />
            <OptionsTableColumnOption
                display={strings.columns.reportingEventNumber.label(
                    formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
                )}
                value={sortKeyEnum.EVIDENCE_ITEM_REPORTING_EVENT_NUMBER}
                sortOptions={[
                    {
                        display: strings.columns.reportingEventNumber.descendingSort,
                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                    },
                    {
                        display: strings.columns.reportingEventNumber.ascendingSort,
                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                    },
                ]}
            />
        </OptionsTableColumn>
        <TableColumn width={300} columnKey="identifiers" display={strings.columns.identifiers} />
        <TableColumn
            width={400}
            columnKey="location"
            display={strings.columns.location(formatFieldByName(DISPLAY_ONLY_CUSTODY_LABEL))}
        />
    </TableColumns>
);

EvidenceDashboardSearchResultsTableHeader.propTypes = {
    formatFieldByName: PropTypes.func.isRequired,
    handleSortKeyClick: PropTypes.func.isRequired,
    handleSortTypeClick: PropTypes.func.isRequired,
    querySortKey: PropTypes.string.isRequired,
    querySortType: PropTypes.string.isRequired,
};

EvidenceDashboardSearchResultsTableHeader.defaultProps = {
    formatFieldByName: () => {},
    handleSortKeyClick: () => {},
    handleSortTypeClick: () => {},
    querySortKey: '',
    querySortType: '',
};

export default EvidenceDashboardSearchResultsTableHeader;
