import React from 'react';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { isColumnKeyPropObject } from '../../../../legacy-redux/components/core/tables/tableHelpers';
import { TableCellComponentProps } from '../../../../legacy-redux/components/core/tables/tableTypes';
import { ElasticCaseViewModel } from '../../types';

type CaseStatusCellProps = TableCellComponentProps<ElasticCaseViewModel>;

const CaseStatusCell = (props: CaseStatusCellProps): JSX.Element | null => {
    if (isColumnKeyPropObject(props)) {
        return null;
    }
    const { currentStatusAttrId } = getViewModelProperties(props);
    return <div>{currentStatusAttrId}</div>;
};

export default CaseStatusCell;
