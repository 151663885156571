import React from 'react';

import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import { TableCellComponentProps } from '../tableTypes';
import { CaseTaskViewModel } from '../../../../../modules/cases/task-list/state/ui/types';
import { isColumnKeyPropObject } from '../tableHelpers';

type TaskStatusCellProps = TableCellComponentProps<CaseTaskViewModel>;

function TaskStatusCell(props: TaskStatusCellProps): JSX.Element | null {
    if (isColumnKeyPropObject(props)) {
        return null;
    }
    const { statusAttrId } = props;
    return <ConnectedFormattedAttribute attributeId={statusAttrId} />;
}

export default TaskStatusCell;
