import React from 'react';
import { map } from 'lodash';
import { PropertyStatus } from '@mark43/rms-api';
import ConnectedFormattedAttribute from '../../attributes/components/ConnectedFormattedAttribute';

type PropertyStatusTitlesProps = {
    propertyStatuses: PropertyStatus[];
    className?: string;
};

/**
 * Use this component to display property status titles given a list of property statuses.
 */
export default function PropertyStatusTitles({
    propertyStatuses,
    className,
}: PropertyStatusTitlesProps): JSX.Element {
    return (
        <div className={className}>
            {map(propertyStatuses, ({ id, propertyStatusAttrId }) => (
                <ConnectedFormattedAttribute key={id} attributeId={propertyStatusAttrId} />
            ))}
        </div>
    );
}
