import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReportStatusView } from '@mark43/rms-api';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import reportApprovalLevelClientEnum from '~/client-common/core/enums/client/reportApprovalLevelClientEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    approvalStatusSelector,
    disableReportSubmissionButtonsSelector,
    reportStatusViewSelector,
    currentReportApprovalLevelSelector,
    reportLastExportedDateSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { currentReportSealingSelector } from '../../../../record-privacy/sealing/state/ui';
import { openReportRejectionModal } from '../../state/ui';
import { actuallyRejectReport } from '../../state/ui/submissions';
import { NibrsErrorState } from '../../../../core/generated-segments/constants';
import { useNibrsErrors } from '../../../../core/generated-segments/hooks';

import { RmsDispatch } from '../../../../../core/typings/redux';
import DraftCard from './DraftCard';
import SubmittedCard from './SubmittedCard';
import RejectedCard from './RejectedCard';
import CompletedCard from './CompletedCard';
import PendingSecondaryReviewCard from './PendingSecondaryReviewCard';

interface ReportStatusCommentsCardPropsT {
    approvalStatus: keyof typeof approvalStatusClientEnum | undefined;
    className?: string;
    currentReportApprovalLevel: keyof typeof reportApprovalLevelClientEnum | undefined;
    disableReportSubmissionButtons: boolean;
    isCustodialReport: boolean;
    rejectReport: (callback: () => void) => void;
    reportLastExportedDate: string | undefined;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
    reportStatusView: ReportStatusView | false;
    ucrRejectReport: () => void;
}

const ReportStatusCommentsCard = ({
    isCustodialReport,
    approvalStatus,
    reportStatusView,
    className,
    rejectReport,
    ucrRejectReport,
    reportSealingInfo,
    disableReportSubmissionButtons,
    currentReportApprovalLevel,
    reportLastExportedDate,
}: ReportStatusCommentsCardPropsT) => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isRestrictReportCompletionEnabled = !!applicationSettings.RMS_RESTRICT_REPORT_COMPLETION_ENABLED;
    const nibrsErrorsData = useNibrsErrors();
    const shouldDisableApprovalButton = isRestrictReportCompletionEnabled &&
        (nibrsErrorsData.nibrsErrorState !== NibrsErrorState.NoErrors &&
            nibrsErrorsData.nibrsErrorState !== NibrsErrorState.NotReportable);

    if (!reportStatusView || !approvalStatus) {
        return <div />;
    }

    switch (approvalStatus) {
        case approvalStatusClientEnum.DRAFT:
            return (
                <DraftCard
                    isCustodialReport={isCustodialReport}
                    disabled={disableReportSubmissionButtons}
                    className={className}
                    reportStatusView={reportStatusView}
                    reportSealingInfo={reportSealingInfo}
                    reportApprovalLevel={currentReportApprovalLevel}
                    nibrsErrorsData={nibrsErrorsData}
                />
            );

        case approvalStatusClientEnum.SUBMITTED:
        case approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW:
            return (
                <SubmittedCard
                    reportStatusView={reportStatusView}
                    rejectReport={rejectReport}
                    disabled={disableReportSubmissionButtons}
                    reportSealingInfo={reportSealingInfo}
                    shouldDisableApprovalButton={shouldDisableApprovalButton}
                    nibrsErrorsData={nibrsErrorsData}
                    currentReportApprovalLevel={currentReportApprovalLevel}
                />
            );
        case approvalStatusClientEnum.REJECTED:
            return (
                <RejectedCard 
                    reportSealingInfo={reportSealingInfo}
                    nibrsErrorsData={nibrsErrorsData}
                />
            );
        case approvalStatusClientEnum.PENDING_SECONDARY_REVIEW:
            return (
                <PendingSecondaryReviewCard
                    className={className}
                    reportStatusView={reportStatusView}
                    ucrRejectReport={ucrRejectReport}
                    rejectReport={rejectReport}
                    disabled={disableReportSubmissionButtons}
                    reportSealingInfo={reportSealingInfo}
                    shouldDisableApprovalButton={shouldDisableApprovalButton}
                    nibrsErrorsData={nibrsErrorsData}
                    currentReportApprovalLevel={currentReportApprovalLevel}
                />
            );
        case approvalStatusClientEnum.COMPLETED:
            return (
                <CompletedCard
                    reportApprovalLevel={currentReportApprovalLevel}
                    disabled={disableReportSubmissionButtons}
                    className={className}
                    reportStatusView={reportStatusView}
                    reportSealingInfo={reportSealingInfo}
                    ucrRejectReport={ucrRejectReport}
                    rejectReport={rejectReport}
                    reportLastExportedDate={reportLastExportedDate}
                    nibrsErrorsData={nibrsErrorsData}
                />
            );

        default:
            return <div />;
    }
};

const mapStateToProps = createStructuredSelector({
    reportSealingInfo: currentReportSealingSelector,
    approvalStatus: approvalStatusSelector,
    reportStatusView: reportStatusViewSelector,
    disableReportSubmissionButtons: disableReportSubmissionButtonsSelector,
    currentReportApprovalLevel: currentReportApprovalLevelSelector,
    reportLastExportedDate: reportLastExportedDateSelector,
});

const mapDispatchToProps = (dispatch: RmsDispatch) => ({
    rejectReport: (callback: () => void) => {
        dispatch(
            openReportRejectionModal({
                callback: (reasonForRejection: string) =>
                    dispatch(actuallyRejectReport(false, reasonForRejection, callback)),
            })
        );
    },
    ucrRejectReport: () => {
        dispatch(
            openReportRejectionModal({
                callback: (reasonForRejection: string) =>
                    dispatch(actuallyRejectReport(true, reasonForRejection)),
            })
        );
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportStatusCommentsCard);
