import { get, groupBy, map, reject, parseInt, find, isNaN } from 'lodash';
import {
    RefContextEnum,
    EntityTypeEnumType,
    IdFormatConfiguration,
    DepartmentSequenceKey,
    IdFormatEntityTypeLink,
    EntityTypeEnum,
} from '@mark43/rms-api';
import { IOverlayStore } from 'overlay-manager/lib/store';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import getIdFormatConfigurationResource from '~/client-common/core/domain/id-format-configurations/resources/idFormatConfigurationResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    idGeneratorFormatsByEntityTypeSelector,
    idGeneratorFormatsSelector,
    NEXUS_STATE_PROP as ID_GENERATOR_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/id-generator/state/data';
import { NEXUS_STATE_PROP as ID_FORMAT_CONFIGURATION_NEXUS_STATE_PROP } from '~/client-common/core/domain/id-format-configurations/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { MFTFormsRegistry } from '../../../../../core/formsRegistry';
import idGeneratorResource from '../../resources/idGeneratorResource';
import { RmsAction } from '../../../../../core/typings/redux';
import { IdFormatConfigurationFormDataShape } from '../forms/idFormatConfigurationForm';

const strings = componentStrings.support.sequenceIncreaseModal;

export const LOAD_ID_FORMATS_START = 'support-id-generator/LOAD_ID_FORMATS_START';
export const LOAD_ID_FORMATS_SUCCESS = 'support-id-generator/LOAD_ID_FORMATS_SUCCESS';
export const LOAD_ID_FORMATS_FAILURE = 'support-id-generator/LOAD_ID_FORMATS_FAILURE';

export const SAVE_ID_FORMAT_START = 'support-id-generator/SAVE_ID_FORMAT_START';
export const SAVE_ID_FORMAT_SUCCESS = 'support-id-generator/SAVE_ID_FORMAT_SUCCESS';
export const SAVE_ID_FORMAT_FAILURE = 'support-id-generator/SAVE_ID_FORMAT_FAILURE';

export const SAVE_SEQUENCE_INCREASE_START = 'support-id-generator/SAVE_SEQUENCE_INCREASE_START';
export const SAVE_SEQUENCE_INCREASE_SUCCESS = 'support-id-generator/SAVE_SEQUENCE_INCREASE_SUCCESS';
export const SAVE_SEQUENCE_INCREASE_FAILURE = 'support-id-generator/SAVE_SEQUENCE_INCREASE_FAILURE';

type IdGeneratorFormShape = {
    sequenceIncrement?: number;
    id?: number;
    entityType?: EntityTypeEnumType;
    extraKey?: string;
};

export type SequenceIncreaseModalCustomProperties = {
    currentSequence?: number;
    nextSequence?: number;
    saveDisabled: boolean;
    increaseSequenceBy?: number | string;
    isSaving?: boolean;
};

type Error = {
    message: string;
};

type ValidContextNames = keyof Pick<
    typeof RefContextEnum,
    'FORM_ID_FORMAT_CONFIGURATION' | 'FORM_WARRANT_ID_FORMAT_CONFIGURATION'
>;

export function loadIdFormatsStart() {
    return {
        type: LOAD_ID_FORMATS_START,
    } as const;
}

export function loadIdFormatsSuccess() {
    return {
        type: LOAD_ID_FORMATS_SUCCESS,
    } as const;
}

export function loadIdFormatsFailure(error: Error) {
    return {
        type: LOAD_ID_FORMATS_FAILURE,
        payload: error.message,
    } as const;
}

export function saveIdFormatStart() {
    return {
        type: SAVE_ID_FORMAT_START,
    } as const;
}

export function saveIdFormatSuccess() {
    return {
        type: SAVE_ID_FORMAT_SUCCESS,
    } as const;
}

export function saveIdFormatFailure(error: Error) {
    return {
        type: SAVE_ID_FORMAT_FAILURE,
        payload: error.message,
    } as const;
}

export function saveSequenceIncreaseStart() {
    return {
        type: SAVE_SEQUENCE_INCREASE_START,
    } as const;
}

export function saveSequenceIncreaseSuccess() {
    return {
        type: SAVE_SEQUENCE_INCREASE_SUCCESS,
    } as const;
}

export function saveSequenceIncreaseFailure(error: Error) {
    return {
        type: SAVE_SEQUENCE_INCREASE_FAILURE,
        payload: error.message,
    } as const;
}

export function loadIdFormats(): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        dispatch(loadIdFormatsStart());
        return Promise.all([
            idGeneratorResource.getIdFormats() as Promise<IdFormatEntityTypeLink[]>,
            idGeneratorResource.getSequenceKeys() as Promise<DepartmentSequenceKey[]>,
        ])
            .then((results) => {
                const [idFormats, sequenceKeys] = results;
                const sequencesByEntityType = groupBy(sequenceKeys, 'entityType');
                const enhancedIdFormats = map(idFormats, (idFormat) => ({
                    ...idFormat,
                    sequences: sequencesByEntityType[idFormat.entityType],
                }));
                dispatch(
                    nexus.withEntityItems(
                        {
                            [ID_GENERATOR_NEXUS_STATE_PROP]: enhancedIdFormats,
                        },
                        loadIdFormatsSuccess()
                    )
                );
            })
            .catch((error: Error) => {
                dispatch(loadIdFormatsFailure(error));
            });
    };
}

export function loadIdFormatConfigurations(): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        dispatch(loadIdFormatsStart());
        return getIdFormatConfigurationResource()
            .getIdFormatConfigurations()
            .then((results: IdFormatConfiguration[]) => {
                const uniqueFormats = reject(results, { entityType: EntityTypeEnum.REPORT.name });
                dispatch(
                    nexus.withEntityItems(
                        {
                            [ID_FORMAT_CONFIGURATION_NEXUS_STATE_PROP]: uniqueFormats,
                        },
                        loadIdFormatsSuccess()
                    )
                );
            })
            .catch((error: Error) => dispatch(loadIdFormatsFailure(error)));
    };
}

export function saveIdFormat(idFormat: IdFormatEntityTypeLink): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        if (!idFormat.idFormatType) {
            return;
        }
        dispatch(saveIdFormatStart());
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        return (idGeneratorResource as any)
            .upsertIdFormatLink(idFormat)
            .then((data: IdFormatEntityTypeLink[]) => {
                const idGeneratorFormatsByEntityType = idGeneratorFormatsByEntityTypeSelector(
                    getState()
                );
                const enhancedIdFormats = map(data, (idFormat) => ({
                    ...idFormat,
                    sequences: get(
                        idGeneratorFormatsByEntityType(idFormat.entityType),
                        'sequences'
                    ),
                }));
                dispatch(
                    nexus.withEntityItems(
                        {
                            [ID_GENERATOR_NEXUS_STATE_PROP]: enhancedIdFormats,
                        },
                        loadIdFormatsSuccess()
                    )
                );
                dispatch(saveIdFormatSuccess());
            })
            .catch((error: Error) => dispatch(saveIdFormatFailure(error)));
    };
}

export function saveIdFormatConfiguration(
    contextName: ValidContextNames
): RmsAction<Promise<IdFormatConfiguration | undefined>> {
    return (dispatch, getState, { formsRegistry, nexus }) => {
        dispatch(saveIdFormatStart());
        const form = formsRegistry.get(contextName);
        const state = getState();
        const { selectedAgencyId } = state.ui.supportIdGenerator;
        const applicationSettings = applicationSettingsSelector(state);

        return Promise.resolve(form)
            .then((form) => {
                if (!form) {
                    throw new Error(`Form ${contextName} was not found`);
                }
                const formModel = form.getState().model;
                if (!validateIdFormatConfigurationFormConfigNumber(formModel)) {
                    const sequenceMaxLength: number = formModel.sequenceMaxLength as number;
                    const sequenceMaxLengthErrorMessage = `Start/End range must be between 0 and ${Math.pow(
                        10,
                        sequenceMaxLength
                    )}`;
                    throw new Error(sequenceMaxLengthErrorMessage);
                }
                return form.submit();
            })
            .then((result) => {
                const idFormatConfigurationResource = getIdFormatConfigurationResource();
                const formModel = result.form.get();

                // There is branching logic here primarily to permission
                // warrants differently from all other id generation sequences
                const resourceMethod =
                    contextName === RefContextEnum.FORM_WARRANT_ID_FORMAT_CONFIGURATION.name
                        ? idFormatConfigurationResource.upsertWarrantIdFormatConfiguration
                        : idFormatConfigurationResource.upsertIdFormatConfiguration;

                // @ts-expect-error - Entity type exists on type.
                const entityType = formModel.entityType;
                const persistWithAgencyId =
                    (applicationSettings.CAD_USE_AGENCY_DEPT_CONFIG_FOR_REN &&
                        entityType === EntityTypeEnum.REPORTING_EVENT_NUMBER.name) ||
                    entityType === EntityTypeEnum.ARREST.name ||
                    entityType === EntityTypeEnum.OFFENSE.name;

                if (persistWithAgencyId) {
                    return resourceMethod({
                        ...formModel,
                        agencyId: selectedAgencyId,
                    });
                } else {
                    return resourceMethod(formModel);
                }
            })
            .then((result: IdFormatConfiguration) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [ID_FORMAT_CONFIGURATION_NEXUS_STATE_PROP]: [result],
                        },
                        loadIdFormatsSuccess()
                    )
                );
                dispatch(saveIdFormatSuccess());
                return result;
            })
            .catch((error) => {
                dispatch(saveIdFormatFailure(error));
            });
    };
}

export function saveSequenceIncrease(idFormat: IdGeneratorFormShape): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        if (!idFormat.sequenceIncrement) {
            return;
        }
        const sequenceToSave = {
            extraKey: idFormat.extraKey,
            entityType: idFormat.entityType,
        };
        dispatch(saveSequenceIncreaseStart());
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        return (idGeneratorResource as any)
            .increaseSequence(sequenceToSave, idFormat.sequenceIncrement)
            .then((data: DepartmentSequenceKey[]) => {
                dispatch(saveSequenceIncreaseSuccess());
                const sequencesByEntityType = groupBy(data, 'entityType');
                const idFormats = idGeneratorFormatsSelector(getState());
                const enhancedIdFormats = map(idFormats, (idFormat) => ({
                    ...idFormat,
                    sequences: sequencesByEntityType[idFormat.entityType],
                }));
                dispatch(
                    nexus.withEntityItems(
                        {
                            [ID_GENERATOR_NEXUS_STATE_PROP]: enhancedIdFormats,
                        },
                        loadIdFormatsSuccess()
                    )
                );
            })
            .catch((error: Error) => dispatch(saveSequenceIncreaseFailure(error)));
    };
}

export function openSequenceIncreaseModal({
    showDatePrefix,
    entityType,
    extraKey,
}: {
    showDatePrefix: boolean;
    entityType: EntityTypeEnumType;
    extraKey?: number;
}): RmsAction<Promise<void>> {
    return (
        dispatch,
        getState,
        { overlayStore }: { overlayStore: IOverlayStore<SequenceIncreaseModalCustomProperties> }
    ) => {
        overlayStore.open(overlayIdEnum.SEQUENCE_INCREASE_MODAL, {
            currentSequence: 0,
            nextSequence: 0,
            saveDisabled: true,
        });
        if (!showDatePrefix) {
            const sequenceKey = {
                entityType,
                extraKey: extraKey ? extraKey.toString() : '',
            };
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return (idGeneratorResource as any)
                .getSequenceByDepartmentSequenceKey(sequenceKey)
                .then((currentSequence: DepartmentSequenceKey['sequence']) => {
                    setSequenceCustomProperties({
                        overlayStore,
                        currentSequence,
                        increaseSequenceBy: 0,
                        saveDisabled: true,
                    });
                })
                .catch(() =>
                    overlayStore.setError(
                        overlayIdEnum.SEQUENCE_INCREASE_MODAL,
                        strings.sequenceValueReadFailure
                    )
                );
        }
    };
}

export function loadCurrentSequenceValue({
    formatDateString,
    entityType,
    extraKey,
}: {
    formatDateString: string;
    entityType: EntityTypeEnumType;
    extraKey?: number;
}): RmsAction<Promise<void>> {
    return (dispatch, getState, { formsRegistry, overlayStore }) => {
        const { datePrefix, increaseSequenceBy, sequenceKey } = getSequenceKeyAndFormData(
            formsRegistry,
            entityType,
            extraKey
        );
        if (!hasValidDatePrefixOrSuffix(formatDateString, true, datePrefix)) {
            // We only want to fire this request at valid date prefix values, since it's used onChange
            setSequenceCustomProperties({
                overlayStore,
                currentSequence: 0,
                increaseSequenceBy: 0,
                saveDisabled: true,
            });
            return;
        }
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        return (idGeneratorResource as any)
            .getSequenceByDepartmentSequenceKey(sequenceKey)
            .then((currentSequence: DepartmentSequenceKey['sequence']) => {
                setSequenceCustomProperties({
                    overlayStore,
                    currentSequence,
                    increaseSequenceBy,
                    saveDisabled: !increaseSequenceBy,
                });
            })
            .catch(() =>
                overlayStore.setError(
                    overlayIdEnum.SEQUENCE_INCREASE_MODAL,
                    strings.sequenceValueReadFailure
                )
            );
    };
}

export function loadNextSequenceValue(): RmsAction<void> {
    return (dispatch, getState, { formsRegistry, overlayStore }) => {
        const { increaseSequenceBy } =
            formsRegistry.get(formClientEnum.FORM_SEQUENCE_INCREASE_MODAL)?.getState().model || {};
        const { currentSequence } = overlayStore.getStateForId(
            overlayIdEnum.SEQUENCE_INCREASE_MODAL
        ).customProperties;
        setSequenceCustomProperties({
            overlayStore,
            currentSequence,
            increaseSequenceBy,
            saveDisabled: !increaseSequenceBy,
        });
    };
}

export function saveSequenceIncreaseModal(
    entityType: EntityTypeEnumType,
    extraKey?: number
): RmsAction<Promise<void>> {
    return (dispatch, getState, { formsRegistry, overlayStore }) => {
        const { sequenceKey, increaseSequenceBy } = getSequenceKeyAndFormData(
            formsRegistry,
            entityType,
            extraKey
        );
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        return (idGeneratorResource as any)
            .increaseSequence(sequenceKey, increaseSequenceBy)
            .then((departmentSequenceKeys: DepartmentSequenceKey[]) => {
                const departmentSequenceKey = find(departmentSequenceKeys, sequenceKey);
                if (!isUndefinedOrNull(departmentSequenceKey)) {
                    setSequenceCustomProperties({
                        overlayStore,
                        currentSequence: get(departmentSequenceKey, 'sequence'),
                        increaseSequenceBy,
                        saveDisabled: false,
                    });
                }
            });
    };
}

export function hasValidDatePrefixOrSuffix(
    formatDateString: string,
    showDatePrefixOrSuffix: boolean,
    datePrefixOrSuffix?: string
) {
    return (
        !showDatePrefixOrSuffix ||
        (formatDateString &&
            datePrefixOrSuffix &&
            formatDateString.length === datePrefixOrSuffix.length &&
            !isNaN(parseInt(datePrefixOrSuffix)))
    );
}

function setSequenceCustomProperties({
    overlayStore,
    currentSequence,
    increaseSequenceBy,
    saveDisabled,
}: SequenceIncreaseModalCustomProperties & {
    overlayStore: IOverlayStore<SequenceIncreaseModalCustomProperties>;
}) {
    const sequence = isUndefinedOrNull(currentSequence) ? 0 : currentSequence;
    const increment = increaseSequenceBy ? parseInt(`${increaseSequenceBy}`) : 0;
    overlayStore.setCustomProperties(overlayIdEnum.SEQUENCE_INCREASE_MODAL, {
        currentSequence: sequence,
        nextSequence: sequence + increment,
        isSaving: false,
        saveDisabled,
    });
    overlayStore.setError(overlayIdEnum.SEQUENCE_INCREASE_MODAL, undefined);
}

function getSequenceKeyAndFormData(
    formsRegistry: MFTFormsRegistry,
    entityType: EntityTypeEnumType,
    extraKey?: number
) {
    const form = formsRegistry.get(formClientEnum.FORM_SEQUENCE_INCREASE_MODAL);

    const formData = form?.getState().model || {};

    const datePrefix = formData.datePrefix ? formData.datePrefix : '';
    const extraKeyNotNull = extraKey || '';
    const dateExtraKeySeparator = datePrefix && extraKey ? '+' : '';
    const sequenceKey = {
        entityType,
        extraKey: `${datePrefix}${dateExtraKeySeparator}${extraKeyNotNull}`,
    };
    return {
        ...formData,
        sequenceKey,
        datePrefix,
    };
}

const validateIdFormatConfigurationFormConfigNumber = (
    formModel: IdFormatConfigurationFormDataShape
): boolean => {
    return (
        (!formModel.startNumber && !formModel.endNumber) ||
        (isConfigNumberMatchSequenceMaxLength(formModel.startNumber, formModel.sequenceMaxLength) &&
            isConfigNumberMatchSequenceMaxLength(formModel.endNumber, formModel.sequenceMaxLength))
    );
};

const isConfigNumberMatchSequenceMaxLength = (
    configNumber?: number,
    sequenceMaxLength?: number
) => {
    return (
        !!configNumber &&
        !!sequenceMaxLength &&
        +configNumber > 0 &&
        +configNumber < Math.pow(10, +sequenceMaxLength)
    );
};
