import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cssVar, Text, Box } from 'arc';
import {
    FederatedSearchExternalReadDataContext,
    QueryableEntityType,
    FederatedSearchArticle,
} from 'mark43-federated-search';
import { EntityTypeEnum, ItemProfile } from '@mark43/rms-api';

import { merge } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import {
    generateSidePanelFormDataFromRmsOtherItem,
    openItemSidePanel,
} from '../../state/ui/itemSidePanel';
import { PropertyPillSmall } from './PropertyPillSmall';
import { useMapDexArticleToRmsProperty } from './hooks';

const strings = componentStrings.core.ArticleQuickAdd.dex;

export const AddArticleFromDex = () => {
    const { resultForConsumerApp = {} } = useContext(FederatedSearchExternalReadDataContext) || {};
    const currentReport = useSelector(currentReportSelector);
    const dispatch = useDispatch();

    const dexArticles: FederatedSearchArticle[] = useMemo(
        () => resultForConsumerApp[QueryableEntityType.ARTICLE] || [],
        [resultForConsumerApp]
    );

    const { rmsProperties, loading, error } = useMapDexArticleToRmsProperty(dexArticles);

    const createOnPillClickHandler = (rmsProperty: Partial<ItemProfile>) => () => {
        if (!currentReport) {
            return;
        }
        dispatch(
            openItemSidePanel({
                itemTypeAttrId: globalAttributes.itemType.item,
                ownerId: currentReport.id,
                ownerType: EntityTypeEnum.REPORT.name,
                operation: itemSidePanelOperationEnum.CREATE,
                reportingEventNumber: currentReport?.reportingEventNumber,
                isFormHidden: true,
                setFormValues: (formModel: Record<string, unknown>) =>
                    merge({}, formModel, generateSidePanelFormDataFromRmsOtherItem(rmsProperty)),
            })
        );
    };

    return (
        !loading && (
            <div>
                <Text fontSize="small" color="tertiary" sx={{ mb: cssVar('arc.space.2') }}>
                    {strings.addItem}
                </Text>
                {error && (
                    <Text variant="headingXs" color="negative">
                        {strings.genericLoadError}
                    </Text>
                )}
                {rmsProperties.length > 0 &&
                    rmsProperties.map((rmsProperty) => (
                        <Box
                            display="grid"
                            gridTemplateColumns="1fr 1fr"
                            gap={cssVar('arc.space.2')}
                            key={rmsProperty.id}
                        >
                            <PropertyPillSmall
                                property={rmsProperty}
                                onClick={createOnPillClickHandler(rmsProperty)}
                            />
                        </Box>
                    ))}
            </div>
        )
    );
};
