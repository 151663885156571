import { EFileNameSearchRequest, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sqlSortKeyEnum from '~/client-common/core/enums/universal/sqlSortKeyEnum';
import { EFileSearchRequest, EntityToIconT } from './types';

export const TOOLTIP_DELAY_MS = 500;

export const INVOLVED_PROFILES_PAGE_SIZE = 50;
export const ATTACHMENTS_PAGE_SIZE = 50;
export const ITEMS_PAGE_SIZE = 50;

export const INVOLVED_PROFILES_DEFAULT_SORT = {
    sortKey: sqlSortKeyEnum.E_FILE_NAME_LINK_NAME,
    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
};

export const INITIAL_DEFENDANTS_SEARCH_QUERY: EFileSearchRequest<EFileNameSearchRequest> = {
    from: 0,
    size: INVOLVED_PROFILES_PAGE_SIZE,
    query: {
        linkTypes: [LinkTypesEnum.DEFENDANT_IN_EFILE],
    },
    sorts: [INVOLVED_PROFILES_DEFAULT_SORT],
};

export const EFILE_LINK_TYPE_TO_NAME_MAP = {
    [LinkTypesEnum.DEFENDANT_IN_EFILE]: 'DEFENDANT_IN_EFILE',
    [LinkTypesEnum.INVOLVED_PROFILE_IN_EFILE]: 'INVOLVED_PROFILE_IN_EFILE',
    [LinkTypesEnum.ATTACHMENT_IN_EFILE]: 'ATTACHMENT_IN_EFILE',
    [LinkTypesEnum.ITEM_IN_EFILE]: 'ITEM_IN_EFILE',
};

export const ENTITY_TO_ICON_MAP: EntityToIconT = {
    [EntityTypeEnum.PERSON_PROFILE.name]: 'Person',
    [EntityTypeEnum.ORGANIZATION_PROFILE.name]: 'Organization',
} as const;
