import { EntityTypeEnum, OperationTypeEnum, LinkTypesEnum, CaseTaskView } from '@mark43/rms-api';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, size } from 'lodash';
import styled from 'styled-components';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import OnlyWithEntityPermission from '~/client-common/core/domain/entity-permissions/components/OnlyWithEntityPermission';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ViewModel } from '~/client-common/helpers/viewModelHelpers';
import { TaskViewModelProps } from '~/client-common/core/domain/tasks/state/ui/types';
import { currentCaseSelector } from '../../core/state/ui';
import {
    sortedCurrentCaseTaskViewModelsSelector,
    taskListSortSelector,
    openCaseTaskAttachmentSidePanel,
    openTaskDetailsSidePanel,
    removeTaskFromCase as removeTaskFromCaseAction,
    setTaskListSort as setTaskListSortAction,
} from '../state/ui';
import { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import TableHeaderButton from '../../../../legacy-redux/components/core/tables/TableHeaderButton';
import AttachmentsSidePanel from '../../../attachments/core/components/AttachmentsSidePanel';
import { initializeAttachmentsSidePanelForm } from '../../../attachments/core/state/ui/attachmentsSidePanel';
import CaseTasks from '../../../tasks/cases/components/CaseTasks';
import TaskListIcon from './TaskListIcon';
import CaseTaskListRow from './CaseTaskListRow';
import TaskListAddButton from './TaskListAddButton';

const strings = componentStrings.cases.taskList.CaseTaskList;

const HeaderTaskListAddButton = styled(TaskListAddButton)`
    margin: 0 0 5px 0;
`;

type TaskListHeaderCellProps = {
    textAlign?: string;
    width?: number;
};

const TaskListHeaderCell = styled.td<TaskListHeaderCellProps>`
    padding-bottom: 5px;
    text-align: ${(props) => props.textAlign || 'left'};

    &:last-child {
        padding-right: 10px;
    }
`;

const CaseTaskListHeader = styled.tr`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

type BaseCaseTaskListProps = {
    className?: string;
};

function BaseCaseTaskList({ className }: BaseCaseTaskListProps): JSX.Element {
    const taskViewModels = useSelector(sortedCurrentCaseTaskViewModelsSelector) as ViewModel<
        CaseTaskView,
        TaskViewModelProps
    >[]; // needed while the selector file is still in JS
    const currentCase = useSelector(currentCaseSelector);
    const sort = useSelector(taskListSortSelector);

    const dispatch = useDispatch();

    const openNewTaskDetailsSidePanel = useCallback(() => {
        dispatch(openTaskDetailsSidePanel());
    }, [dispatch]);

    const openEditTaskDetailsSidePanel = useCallback(
        (taskId: number) => {
            dispatch(openTaskDetailsSidePanel(taskId));
        },
        [dispatch]
    );

    const removeTaskFromCase = useCallback(
        (taskId: number) => dispatch(removeTaskFromCaseAction(taskId)),
        [dispatch]
    );

    const setTaskListSort = useCallback(
        (sortKey: string) => dispatch(setTaskListSortAction(sortKey)),
        [dispatch]
    );

    const openAttachmentsSidePanel = useCallback(
        (entityId: number) => {
            return () => {
                const attachmentEntityLinkDetails = {
                    attachmentLinkType: LinkTypesEnum.TASK_ATTACHMENT,
                    entityId,
                    entityType: EntityTypeEnum.TASK.name,
                };

                dispatch(initializeAttachmentsSidePanelForm(attachmentEntityLinkDetails));
                dispatch(openCaseTaskAttachmentSidePanel(attachmentEntityLinkDetails));
            };
        },
        [dispatch]
    );

    const { permissionSet } = currentCase || {};
    const taskRows = map(taskViewModels, (taskViewModel, i) => (
        <CaseTaskListRow
            key={taskViewModel.id}
            taskViewModel={taskViewModel}
            casePermissionSet={permissionSet}
            lastRow={i + 1 === size(taskViewModels)}
            openTaskDetailsSidePanel={openEditTaskDetailsSidePanel}
            removeTaskFromCase={removeTaskFromCase}
            openAttachmentsSidePanel={openAttachmentsSidePanel(taskViewModel.id)}
        />
    ));

    return (
        <table className={className}>
            <thead>
                <CaseTaskListHeader>
                    <TaskListHeaderCell width={155}>
                        <TableHeaderButton
                            activeSortKey={sort.sortKey}
                            activeSortType={sort.sortType}
                            sortKey="description"
                            onClick={setTaskListSort}
                        >
                            {strings.headers.task}
                        </TableHeaderButton>
                    </TaskListHeaderCell>
                    <TaskListHeaderCell width={228}>
                        <TableHeaderButton
                            activeSortKey={sort.sortKey}
                            activeSortType={sort.sortType}
                            sortKey="assignee"
                            onClick={setTaskListSort}
                        >
                            {strings.headers.assignee}
                        </TableHeaderButton>
                    </TaskListHeaderCell>
                    <TaskListHeaderCell width={125}>
                        <TableHeaderButton
                            activeSortKey={sort.sortKey}
                            activeSortType={sort.sortType}
                            sortKey="dueDateUtc"
                            onClick={setTaskListSort}
                        >
                            {strings.headers.dueDate}
                        </TableHeaderButton>
                    </TaskListHeaderCell>
                    <TaskListHeaderCell width={146}>
                        <TableHeaderButton
                            activeSortKey={sort.sortKey}
                            activeSortType={sort.sortType}
                            sortKey="statusAttrId"
                            onClick={setTaskListSort}
                        >
                            {strings.headers.status}
                        </TableHeaderButton>
                    </TaskListHeaderCell>
                    <TaskListHeaderCell textAlign="right">
                        <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_TASKS}>
                            <OnlyWithEntityPermission
                                permissionSet={permissionSet}
                                has={OperationTypeEnum.WRITE.name}
                            >
                                <HeaderTaskListAddButton onClick={openNewTaskDetailsSidePanel}>
                                    <TaskListIcon type={iconTypes.ADD} />
                                    {strings.headers.createTask}
                                </HeaderTaskListAddButton>
                            </OnlyWithEntityPermission>
                        </OnlyWithAbility>
                    </TaskListHeaderCell>
                </CaseTaskListHeader>
            </thead>
            <tbody>{taskRows}</tbody>
            <AttachmentsSidePanel overlayId={overlayIdEnum.CASE_TASK_ATTACHMENTS_SIDE_PANEL} />
        </table>
    );
}

const CaseTaskList = styled(BaseCaseTaskList)`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-md);

    &,
    * {
        box-sizing: border-box;
    }
`;

const FeatureFlaggedCaseTaskList = (): JSX.Element => (
    <FeatureFlagged flag="RMS_TASK_AND_REQUEST_TRACKING_ENABLED" fallback={<CaseTaskList />}>
        <CaseTasks />
    </FeatureFlagged>
);

export default FeatureFlaggedCaseTaskList;
