import { ProductModuleEnum } from '@mark43/rms-api';
import React, { FC } from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';

import testIds from '../../../../../core/testIds';
import Pill from '../../../../core/components/Pill';
import { BodyMediumText } from '../../../../core/components/typography/Typography';
import { abilitiesEnum, OnlyWithAbility } from '../../../../core/abilities';

const strings = componentStrings.reports.core.ItemSidePanel;

const SearchFromDexPill = styled(Pill)`
    justify-content: space-between;
`;

type OpenDexFormPillProps = {
    onClick: () => void;
    className?: string;
};

export const OpenDexFormPill: FC<OpenDexFormPillProps> = ({ onClick, className }) => {
    return (
        <ProductModuled productModule={ProductModuleEnum.RMS_DEX.name}>
            <OnlyWithAbility has={abilitiesEnum.RMS_DEX.RMS_DEX_MAKE_INQUIRIES}>
                <OnlyWithAbility has={abilitiesEnum.RMS_DEX.RMS_DEX_VIEW_RESULTS}>
                    <SearchFromDexPill
                        justifyContent="normal"
                        hoverable={true}
                        showChevron={true}
                        onClick={onClick}
                        testId={testIds.SEARCH_FORM_DEX_BUTTON}
                        className={className}
                    >
                        <BodyMediumText fontWeight="semibold">
                            {strings.addFromDexQuery}
                        </BodyMediumText>
                    </SearchFromDexPill>
                </OnlyWithAbility>
            </OnlyWithAbility>
        </ProductModuled>
    );
};
