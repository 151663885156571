import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../core/testIds';
import Icon, { iconTypes } from './Icon';

type SealedStampProps = {
    className?: string;
};

const sealedString = componentStrings.recordPrivacy.sealing.InvolvedPersonRow.sealedTag;

const Border = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 115px;
    height: 30px;
    border: 2px solid ${(props) => props.theme.colors.red};
    box-sizing: border-box;
`;

const SealedText = styled.div`
    position: relative;
    display: inline-block;
    margin-left: 6px;
    line-height: 14px;
    font-size: var(--arc-fontSizes-md);
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.red};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;
const SealedStamp: React.FC<SealedStampProps> = (props) => {
    const { className, children } = props;
    return (
        <Border className={className} data-test-id={testIds.SEALED_STAMP}>
            <Icon color="red" type={iconTypes.PERMISSIONS} size={16} />
            <SealedText>{children || sealedString}</SealedText>
        </Border>
    );
};

export default SealedStamp;
