import React, { useCallback } from 'react';
import { Button, useToast, cssVar } from 'arc';
import { Mark43File, LinkTypesEnum, EntityTypeEnum } from '@mark43/rms-api';

import invariant from 'invariant';
import {
    simpleControl,
    SimpleControlInjectedProps,
    Observer,
    MFTFormConfiguration,
} from 'markformythree';
import styled from 'styled-components';
import { FieldUiOptions } from 'dragon-react';
import { useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { sortedLocationBundlesForLocationEntityLinksWhereSelector } from '~/client-common/core/domain/locations/state/ui';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { RMSDragonConfigurationExtensions } from '../../../../../rms-types';
import { getCrashDiagramModalOverlayId } from '../helpers';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { CRASH_DIAGRAM_ASSET_KEY, useAssetCache } from '../context/AssetCacheContext';
import { useOverlayStore } from '../../../../../../core/overlays/hooks/useOverlayStore';
import {
    DragonCoreEntityDataLoaderContext,
    useDragonReportCoreEntityDataLoaderContext,
} from '../../../../../context/dragon-report-core-entity-data-loaders';
import { Wrapper } from './shared';
import { CrashDiagramModal } from './CrashDiagramModal';
import { CrashDiagramImage } from './CrashDiagramImage';
import { AddMapBackgroundModal } from './AddMapBackgroundModal';

type BaseCrashDiagramCustomComponentPropsT = Pick<
    FieldUiOptions<RMSDragonConfigurationExtensions>,
    'fullyQualifiedPath'
> &
    SimpleControlInjectedProps<number>;

type DragonCrashDiagramCustomComponentPropsT = {
    identifier: string;
} & BaseCrashDiagramCustomComponentPropsT;

const StyledButton = styled(Button)`
    margin: ${cssVar('arc.space.2')} 0;
    width: 100%;
    height: 60px;
`;

const crashDiagramStrings = componentStrings.dragon.crashDiagram.CrashDiagramFormField;

const CrashDiagramInput: React.FC<DragonCrashDiagramCustomComponentPropsT> = ({
    identifier,
    onChange,
    fullyQualifiedPath,
}) => {
    const { reportId } = useDragonReportCoreEntityDataLoaderContext(
        DragonCoreEntityDataLoaderContext
    );
    const toast = useToast();
    const { setCrashDiagramFromSvg, addMapBackgroundModalEnabled } = useCrashDiagram();
    const { getAsset, removeAsset } = useAssetCache();
    const overlayStore = useOverlayStore();
    const crashDiagramOverlayId = getCrashDiagramModalOverlayId(identifier);
    const sortedLocationBundlesForLocationEntityLinksWhere = useSelector(
        sortedLocationBundlesForLocationEntityLinksWhereSelector
    );
    const trafficCrashLocationBundle = sortedLocationBundlesForLocationEntityLinksWhere({
        entityType: EntityTypeEnum.REPORT.name,
        entityId: reportId,
        linkType: LinkTypesEnum.LOCATION_OF_TRAFFIC_CRASH,
    })[0];
    const crashLocation = trafficCrashLocationBundle?.location;

    const openCrashDiagramModal = useCallback(() => {
        overlayStore.open(crashDiagramOverlayId);
    }, [crashDiagramOverlayId, overlayStore]);

    const openAddMapBackgroundModal = useCallback(() => {
        if (!addMapBackgroundModalEnabled) {
            return;
        }

        invariant(crashLocation, 'The crash location is unexpectedly undefined');
        overlayStore.open(overlayIdEnum.ADD_MAP_BACKGROUND_MODAL, {
            center: { lat: crashLocation.latitude, lng: crashLocation.longitude },
            crashDiagramOverlayId,
        });
    }, [addMapBackgroundModalEnabled, crashLocation, overlayStore, crashDiagramOverlayId]);

    const setFormValueAndInvalidateAssetCache = useCallback(
        (fileId?: number) => {
            // Invalidate the cache for the Crash Diagram Image
            removeAsset({ key: CRASH_DIAGRAM_ASSET_KEY, type: 'SVG' });
            removeAsset({ key: CRASH_DIAGRAM_ASSET_KEY, type: 'SVG_DATA_URL' });

            // Set the value in the MFT Form model for this form field.
            onChange(fileId);
        },
        [onChange, removeAsset]
    );

    const onSave = useCallback(
        (file: Mark43File) => {
            toast({
                status: 'default',
                description: crashDiagramStrings.successToastMessage,
                duration: 2500,
            });
            setFormValueAndInvalidateAssetCache(file.id);
        },
        [setFormValueAndInvalidateAssetCache, toast]
    );

    const onEdit = useCallback(() => {
        const crashDiagramSvgString = getAsset({ key: CRASH_DIAGRAM_ASSET_KEY, type: 'SVG' });
        invariant(
            !!crashDiagramSvgString,
            'Failed to get crash diagram svg contents from asset cache'
        );
        setCrashDiagramFromSvg(crashDiagramSvgString);
        openCrashDiagramModal();
    }, [setCrashDiagramFromSvg, openCrashDiagramModal, getAsset]);

    const onDelete = useCallback(() => {
        setFormValueAndInvalidateAssetCache(undefined);
    }, [setFormValueAndInvalidateAssetCache]);

    return (
        <Wrapper>
            <Observer<{ fileId?: number }, MFTFormConfiguration>
                subscriptions={{
                    fileId: fullyQualifiedPath,
                }}
                render={({ fileId }) => {
                    return !fileId ? (
                        <StyledButton
                            leadingVisual="Signature"
                            variant="outline"
                            onClick={
                                !!crashLocation && addMapBackgroundModalEnabled
                                    ? openAddMapBackgroundModal
                                    : openCrashDiagramModal
                            }
                        >
                            {crashDiagramStrings.captureButtonText}
                        </StyledButton>
                    ) : (
                        <CrashDiagramImage
                            fileId={fileId}
                            mode="EDIT"
                            onEdit={onEdit}
                            onDelete={onDelete}
                        />
                    );
                }}
            />
            <CrashDiagramModal
                overlayId={crashDiagramOverlayId}
                identifier={identifier}
                onSave={onSave}
                openAddMapToBackground={openAddMapBackgroundModal}
            />
            <AddMapBackgroundModal />
        </Wrapper>
    );
};
export const CrashDiagramFormField = simpleControl(CrashDiagramInput);
