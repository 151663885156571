import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    MenuListItemsProps,
    WidgetType,
    widgetTypes,
    widgetActions,
    CrashDiagramPosition,
} from '../types';
import { isFlipAction, isArrangeAction } from '../helpers';

const strings = componentStrings.dragon.crashDiagram.CrashDiagramModal.widgetTextMenu;

const menuListItems: MenuListItemsProps[] = [
    {
        action: widgetActions.BRING_ITEM_TO_FRONT,
        leadingVisual: 'BringToFront',
        displayName: strings.brintToFrontButtonText,
    },
    {
        action: widgetActions.SEND_ITEM_TO_BACK,
        leadingVisual: 'SendToBack',
        displayName: strings.sendToBackButtonText,
    },
    {
        action: widgetActions.FLIP_ITEM_HORIZONTAL,
        leadingVisual: 'FlipHorizontal',
        displayName: strings.flipHorizontallyButtonText,
    },
    {
        action: widgetActions.FLIP_ITEM_VERTICAL,
        leadingVisual: 'FlipVertical',
        displayName: strings.flipVerticallyButtonText,
    },
];

export const flipMenuListItems = menuListItems.filter((item) => isFlipAction(item.action));
export const arrangeMenuListItems = menuListItems.filter((item) => isArrangeAction(item.action));

export const DEFAULT_WIDGET_POSITION: Required<Omit<CrashDiagramPosition, 'x' | 'y'>> = {
    offsetX: 50,
    offsetY: 50,
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
    skewX: 0,
    skewY: 0,
    width: 100,
    height: 100,
};

export const STAGE_HEIGHT = 490;
export const ADD_MAP_STAGE_HEIGHT = 575;
export const STAGE_WIDTH_LARGE = 980;
export const MODAL_CONTENT_WIDTH = STAGE_WIDTH_LARGE + 18;
export const MODAL_WIDTH_LARGE = MODAL_CONTENT_WIDTH + 16;

export const STAGE_WIDTH_MEDIUM = 840;
export const MODAL_WIDTH_MEDIUM = STAGE_WIDTH_MEDIUM + 10;

export const STAGE_WIDTH_MOBILE = 90;
export const MODAL_WIDTH_MOBILE = STAGE_WIDTH_MOBILE + 10;

export const STAGE_CENTER = [STAGE_WIDTH_LARGE / 2, STAGE_HEIGHT / 2];

export const FONT_SIZE = 18;

export const widgetTypeToDefaultValue: Record<WidgetType, string> = {
    [widgetTypes.TEXT]: 'Add Label',
    [widgetTypes.IMAGE]: '',
    [widgetTypes.BACKGROUND_IMAGE]: '',
};
