import { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';
import { FeatureLayerApplyEditsT, FeatureLayerT, GraphicT, MapViewT } from '../types';
import { handleMapViewGotoCatch } from '../helpers/mapHelpers';

type LayerControlProps<T> = {
    layer: FeatureLayerT | undefined;
    currItems: T[];
    createGraphics: (items: T[]) => GraphicT[];
    replaceExisting?: boolean;
    zoomToGraphics?: boolean;
    mapView?: MapViewT;
};
/**
 * Static Feature Layer control, this hook manages 'static' feature layers, meaning graphics in layers will not be updated during the layer/map life circle.
 * when replaceExisting = true, layer can be updated by removing then recrating all graphics. (only update layer this way if there are not many graphics. )
 *
 * IF THE GRAPHICS IN LAYER ARE DYNAMICALLY CHANGED AND THERE ARE MANY GRAPHICS IN THE LAYER,
 * USE useLayerControl INSTEAD.
 */
export const useStaticLayerControl = <T>({
    layer,
    currItems,
    createGraphics,
    replaceExisting,
    zoomToGraphics,
    mapView,
}: LayerControlProps<T>) => {
    const itemsRef = useRef<GraphicT[]>();

    useEffect(() => {
        if (layer) {
            const itemsToBeAdded: T[] = [];

            // add all currItems
            itemsToBeAdded.push(...currItems);

            // build graphic of icons
            const itemGraphicsToBeAdded = createGraphics(itemsToBeAdded);

            // compile edits into correct format
            const edits: FeatureLayerApplyEditsT = {};
            if (itemGraphicsToBeAdded.length) {
                edits.addFeatures = itemGraphicsToBeAdded;
            }

            if (replaceExisting && !!itemsRef.current?.length) {
                edits.deleteFeatures = itemsRef.current;
            }

            // apply edits to the unit layer if there are any
            if (!isEmpty(edits)) {
                layer?.applyEdits(edits).then(() => {
                    itemsRef.current = itemGraphicsToBeAdded;

                    // We can zoom to the graphics by passing them into the `goTo` function. This
                    // can be very useful in the case of the setup wizard.
                    if (mapView && zoomToGraphics) {
                        mapView.goTo(itemGraphicsToBeAdded).catch(handleMapViewGotoCatch);
                    }
                });
            }
        }
    }, [currItems, replaceExisting, layer, mapView, zoomToGraphics, createGraphics]);
};
