import * as Sentry from '@sentry/browser';
import { MapView, WebMap } from '../constructors';

export class EsriUtils {
    mapView?: MapView;
    webMap?: WebMap;

    constructor() {
        // empty constructor
        /* use attach function to attach necessary esri Object.
         *  e.g. EsriUtil
         *         .attach(mapView)  // attach esri mapView
         *         .enableMove()     // call util/helper on mapView
         *         .enableZoom()     // chaining
         */
    }

    static attach(instance: MapView | WebMap) {
        const utils = new EsriUtils();
        if (instance instanceof MapView) {
            utils.mapView = instance;
        } else if (instance instanceof WebMap) {
            utils.webMap = instance;
        } else {
            throw new Error('Invalid instance provided. Must be MapView or WebMap');
        }
        return utils;
    }

    catchWebMapLoadError(loadErrorCallback?: (error: Error) => void) {
        if (!this.webMap) {
            throw new Error('WebMap is not attached');
        }
        this.webMap.load().catch((error: Error) => {
            loadErrorCallback?.(error);
            Sentry.captureException(new Error('esri map: webmap load error'), {
                extra: {
                    error: JSON.stringify(error),
                },
            });
        });
        return this;
    }

    enableMove(enabled = true) {
        if (!this.mapView) {
            throw new Error('MapView is not attached');
        }
        if (enabled) {
            return this;
        }
        this.mapView
            // @ts-expect-error MapView has following event listenter
            .on('drag', (event: React.MouseEvent) => {
                event.stopPropagation();
            });
        this.mapView
            // @ts-expect-error MapView has following event listenter
            .on('drag', ['Shift'], (event: React.MouseEvent) => {
                event.stopPropagation();
            });
        this.mapView
            // @ts-expect-error MapView has following event listenter
            .on('drag', ['Shift', 'Control'], (event: React.MouseEvent) => {
                event.stopPropagation();
            });
        this.mapView
            // @ts-expect-error MapView has following event listenter
            .on('key-down', (event: React.KeyboardEvent) => {
                const prohibitedKeys = [
                    'A',
                    'a', // case sensitive
                    'D',
                    'd',
                    'ArrowUp',
                    'ArrowDown',
                    'ArrowLeft',
                    'ArrowRight',
                ];
                const keyPressed = event.key;
                if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                    event.stopPropagation();
                }
            });
        return this;
    }

    enableZoom(enabled = true) {
        if (!this.mapView) {
            throw new Error('MapView is not attached');
        }
        if (enabled) {
            return this;
        }
        // zoom in
        this.mapView
            // @ts-expect-error MapView has following event listenter
            .on('double-click', (event: React.MouseEvent) => {
                event.stopPropagation();
            });
        // zoom out
        this.mapView
            // @ts-expect-error MapView has following event listenter
            .on('double-click', ['Control'], (event: React.MouseEvent) => {
                event.stopPropagation();
            });
        this.mapView
            // @ts-expect-error MapView has following event listenter
            .on('key-down', (event: React.KeyboardEvent) => {
                const prohibitedKeys = ['+', '-', 'Shift', '_', '='];
                const keyPressed = event.key;
                if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                    event.stopPropagation();
                }
            });
        this.mapView
            // @ts-expect-error MapView has following event listenter
            .on('mouse-wheel', (event: React.MouseEvent) => {
                event.stopPropagation();
            });

        return this;
    }
}
