import { DefaultFilterOptionValueT, FilterOptionT, VisibleFilter } from '@arc/filter-list';
import { DispositionStatusEnum, ElasticEvidenceItemQuery } from '@mark43/evidence-api';
import { chain, identity, isEmpty, pickBy } from 'lodash';
import {
    formatCustomRange,
    formatToDatePeriod,
    formatWithinLastPeriod,
} from '~/client-common/core/dates/utils/dateRangeHelpers';
import { formatDispositionStatus } from '~/client-common/core/domain/item-evidence-states/utils/formatDisposition';
import { mapVisibleFiltersToQuery } from '../../../../core/filters/utils';
import { FacilityStorageLocationsValue } from '../../../dashboard-filters/components/FacilityStorageLocationsFilter';
import { convertEvidenceDashboardSearchElasticQueryToFormModel } from '../../state/forms/evidenceDashboardSearchForm';
import { FilterOptionsValueT, FilterGroups } from './ManageEvidenceDashboardFilters';

export const MANAGE_EVIDENCE_FILTER_IDS = {
    STORAGE_LOCATIONS_ID: 1,
    DISPOSITION_STATUS_FILTER_ID: 2,
    PROPERTY_STATUS_FILTER_ID: 3,
    REASON_FOR_POLICE_CUSTODY_FILTER_ID: 4,
    PROPERTY_CATEGORY_FILTER_ID: 5,
    AGENCY_FILTER_ID: 6,
    DATE_ACQUIRED_ID: 7,
    CHAIN_OF_CUSTODY_ID: 8,
    DATE_LAST_UPDATED_ID: 9,
    PROPERTY_TYPE_FILTER_ID: 10,
    DATE_REN_CREATION_ID: 11,
    SERIAL_NUMBER_ID: 12,
    REN_NUMBER_ID: 13,
    ITEM_ID_ID: 14,
    PERSONNEL_IDS_ID: 15,
    IDENTIFIER_TYPES_ID: 16,
    IS_OVERDUE_ID: 17,
} as const;

// Disposition Status Options
export const dispositionStatusOptions = chain(DispositionStatusEnum)
    .map((dispositionStatus) => ({
        id: dispositionStatus.name,
        label: formatDispositionStatus(dispositionStatus.name) as string,
        value: dispositionStatus.name,
    }))
    .sortBy('label')
    .value();

export const convertManageDashboardArcFiltersToSearchFormData = <T = DefaultFilterOptionValueT>(
    filters: VisibleFilter<T>[],
    departmentId: number
) => {
    const mapper = mapVisibleFiltersToQuery(filters);

    // Returns facilityIds, storageLocationIds, excludeTemporaryStorageLocations and excludeExpiredStorageLocations to query.
    const getFacilityStorageLocationsQuery = () => {
        const filter: VisibleFilter<FacilityStorageLocationsValue> | undefined = filters.find(
            (item) => item.id === MANAGE_EVIDENCE_FILTER_IDS.STORAGE_LOCATIONS_ID
        );
        const formData: FacilityStorageLocationsValue | undefined = filter?.appliedOptions[0].value;
        return formData;
    };

    const isOverdue = filters.find((item) => item.id === MANAGE_EVIDENCE_FILTER_IDS.IS_OVERDUE_ID)
        ?.appliedOptions[0].value;

    const formData = {
        departmentIds: [departmentId],
        storageLocationIds: getFacilityStorageLocationsQuery()?.storageLocationId ?? [],
        facilityIds: getFacilityStorageLocationsQuery()?.facilityId ?? [],
        excludeTemporaryStorageLocations:
            getFacilityStorageLocationsQuery()?.excludeTemporaryStorageLocations ?? false,
        excludeExpiredStorageLocations:
            getFacilityStorageLocationsQuery()?.excludeExpiredStorageLocations ?? false,
        chainEventTypeIds: mapper.getMultiSelectOptions(
            MANAGE_EVIDENCE_FILTER_IDS.CHAIN_OF_CUSTODY_ID,
            []
        ),
        propertyStatusAttrIds: mapper.getMultiSelectOptions(
            MANAGE_EVIDENCE_FILTER_IDS.PROPERTY_STATUS_FILTER_ID,
            []
        ),
        dispositionStatuses: mapper.getMultiSelectOptions(
            MANAGE_EVIDENCE_FILTER_IDS.DISPOSITION_STATUS_FILTER_ID,
            []
        ),
        reasonForPoliceCustodyAttrIds: mapper.getMultiSelectOptions(
            MANAGE_EVIDENCE_FILTER_IDS.REASON_FOR_POLICE_CUSTODY_FILTER_ID,
            []
        ),
        itemTypeAttrIds: mapper.getMultiSelectOptions(
            MANAGE_EVIDENCE_FILTER_IDS.PROPERTY_TYPE_FILTER_ID,
            []
        ),
        itemCategoryAttrIds: mapper.getMultiSelectOptions(
            MANAGE_EVIDENCE_FILTER_IDS.PROPERTY_CATEGORY_FILTER_ID,
            []
        ),
        agencyIds: mapper.getMultiSelectOptions(MANAGE_EVIDENCE_FILTER_IDS.AGENCY_FILTER_ID, []),
        acquiredDateRangeQuery: mapper.getDateRangeOption(
            MANAGE_EVIDENCE_FILTER_IDS.DATE_ACQUIRED_ID,
            {}
        ),
        chainEventDateRangeQuery: mapper.getDateRangeOption(
            MANAGE_EVIDENCE_FILTER_IDS.DATE_LAST_UPDATED_ID,
            {}
        ),
        renCreationDateRangeQuery: mapper.getDateRangeOption(
            MANAGE_EVIDENCE_FILTER_IDS.DATE_REN_CREATION_ID,
            {}
        ),
        serialNumber: mapper.getTextOption(MANAGE_EVIDENCE_FILTER_IDS.SERIAL_NUMBER_ID),
        reportingEventNumbers: mapper.getNItemsOptions(
            MANAGE_EVIDENCE_FILTER_IDS.REN_NUMBER_ID,
            []
        ),
        itemIds: mapper.getNItemsOptions(MANAGE_EVIDENCE_FILTER_IDS.ITEM_ID_ID, []),
        personnelIds: mapper.getNItemsOptions(MANAGE_EVIDENCE_FILTER_IDS.PERSONNEL_IDS_ID, []),
        identifierTypeAttrIds: mapper.getNItemsOptions(
            MANAGE_EVIDENCE_FILTER_IDS.IDENTIFIER_TYPES_ID,
            []
        ),
        isOverdue,
    };

    return formData;
};

// Functions to convert different types of form data values to filters.
const convertDateRangeToAppliedOptions = (value: Record<string, string>) => {
    if (!value) {
        return [];
    }
    if ('withinLastPeriod' in value) {
        return [
            {
                id: `WITHIN_LAST_PERIOD_${value.withinLastPeriod}`,
                label: formatWithinLastPeriod(value.withinLastPeriod),
                value,
            },
        ];
    } else if ('toDatePeriod' in value) {
        return [
            {
                id: `TO_DATE_PERIOD_${value.toDatePeriod}`,
                label: formatToDatePeriod(value.toDatePeriod),
                value,
            },
        ];
    } else if ('endDateUtc' in value && 'startDateUtc' in value) {
        return [
            {
                id: `CUSTOM_RANGE`,
                label: formatCustomRange(value.startDateUtc, value.endDateUtc),
                value,
            },
        ];
    }
    return [];
};
const convertMultiselectToAppliedOptions = (data: string[], label?: string) => {
    if (!data) {
        return [];
    }

    return (
        data.map((item) => {
            return { id: item, label: label ?? '', value: String(item) };
        }) ?? []
    );
};

const convertTextToAppliedOptions = (value: string, id: number) => {
    if (!value) {
        return [];
    }
    return [{ id, label: value, value }];
};

const convertNItemsToAppliedOptions = (
    value: Record<string, string | number | (number | string)[]>[]
) => {
    if (!value) {
        return [];
    }

    return value.map((item, index) => {
        return { id: index, label: value.length, value: item };
    });
};

export const convertLocationsFormDataToFilterOption = (value: FacilityStorageLocationsValue) => {
    // Remove falsey values from form data.
    const filteredFormData: FacilityStorageLocationsValue = pickBy(value, identity);

    if (isEmpty(filteredFormData)) {
        return;
    } else {
        const locationsCount =
            (filteredFormData?.facilityId?.length ?? 0) +
            (filteredFormData?.storageLocationId?.length ?? 0);

        const updatedFilter: FilterOptionT<FacilityStorageLocationsValue> = {
            id: 'facilityStorageLocations',
            label: locationsCount > 0 ? locationsCount : '',
            value: filteredFormData,
        };
        return updatedFilter;
    }
};

/** The query from a saved search doesn't include the labels for items.
 * This uses the existing filterGroups that get created and parses the first label out.
 * We only need the first label, because if there are multiple values we display a counter.*/
const getFilterDisplayLabelByKey = (filterGroups: FilterGroups, key: string) => {
    const labelString = filterGroups.find((item) => item.key === key)?.display;
    // If it has multiple items, just render an empty string.
    return !labelString?.includes(',') ? labelString : '';
};

export const convertManageEvidenceElasticQueryToArcFilters = (
    elasticQuery: ElasticEvidenceItemQuery | unknown,
    filterGroups: FilterGroups
) => {
    const formData = convertEvidenceDashboardSearchElasticQueryToFormModel(
        elasticQuery as Record<string, unknown>
    );

    const storageLocationsAppliedOptions = convertLocationsFormDataToFilterOption({
        facilityId: formData.facilityIds,
        storageLocationId: formData.storageLocationIds,
        excludeExpiredStorageLocations: formData.excludeExpiredStorageLocations,
        excludeTemporaryStorageLocations: formData.excludeTemporaryStorageLocations,
    });

    const convertedAppliedFilters: VisibleFilter<FilterOptionsValueT>[] = [
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.STORAGE_LOCATIONS_ID,
            appliedOptions: storageLocationsAppliedOptions ? [storageLocationsAppliedOptions] : [],
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.DISPOSITION_STATUS_FILTER_ID,
            appliedOptions: convertMultiselectToAppliedOptions(
                formData.dispositionStatuses,
                getFilterDisplayLabelByKey(filterGroups, 'dispositionStatuses')
            ),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.PROPERTY_STATUS_FILTER_ID,
            appliedOptions: convertMultiselectToAppliedOptions(
                formData.propertyStatusAttrIds,
                getFilterDisplayLabelByKey(filterGroups, 'propertyStatusAttrIds')
            ),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.REASON_FOR_POLICE_CUSTODY_FILTER_ID,
            appliedOptions: convertMultiselectToAppliedOptions(
                formData.reasonForPoliceCustodyAttrIds,
                getFilterDisplayLabelByKey(filterGroups, 'reasonForPoliceCustodyAttrIds')
            ),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.PROPERTY_CATEGORY_FILTER_ID,
            appliedOptions: convertMultiselectToAppliedOptions(
                formData.itemCategoryAttrIds,
                getFilterDisplayLabelByKey(filterGroups, 'itemCategoryAttrIds')
            ),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.AGENCY_FILTER_ID,
            appliedOptions: convertMultiselectToAppliedOptions(
                formData.agencyIds,
                getFilterDisplayLabelByKey(filterGroups, 'agencyIds')
            ),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.DATE_ACQUIRED_ID,
            appliedOptions: convertDateRangeToAppliedOptions(formData.acquiredDateRangeQuery),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.CHAIN_OF_CUSTODY_ID,
            appliedOptions: convertMultiselectToAppliedOptions(
                formData.chainEventTypeIds,
                getFilterDisplayLabelByKey(filterGroups, 'chainEventTypeIds')
            ),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.DATE_LAST_UPDATED_ID,
            appliedOptions: convertDateRangeToAppliedOptions(formData.chainEventDateRangeQuery),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.PROPERTY_TYPE_FILTER_ID,
            appliedOptions: convertMultiselectToAppliedOptions(
                formData.itemTypeAttrIds,
                getFilterDisplayLabelByKey(filterGroups, 'itemTypeAttrIds')
            ),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.DATE_REN_CREATION_ID,
            appliedOptions: convertDateRangeToAppliedOptions(formData.renCreationDateRangeQuery),
        },
        // TEXT
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.SERIAL_NUMBER_ID,
            appliedOptions: convertTextToAppliedOptions(
                formData.serialNumber,
                MANAGE_EVIDENCE_FILTER_IDS.SERIAL_NUMBER_ID
            ),
        },
        // NITEMS
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.REN_NUMBER_ID,
            appliedOptions: convertNItemsToAppliedOptions(formData.reportingEventNumbers),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.ITEM_ID_ID,
            appliedOptions: convertNItemsToAppliedOptions(formData.itemIds),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.PERSONNEL_IDS_ID,
            appliedOptions: convertNItemsToAppliedOptions(formData.personnelIds),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.IDENTIFIER_TYPES_ID,
            appliedOptions: convertNItemsToAppliedOptions(formData.identifierTypeAttrIds),
        },
        {
            id: MANAGE_EVIDENCE_FILTER_IDS.IS_OVERDUE_ID,
            appliedOptions: formData.isOverdue
                ? [{ id: 1, label: '', value: formData.isOverdue }]
                : [],
        },
    ];

    return convertedAppliedFilters.filter((filter) => !isEmpty(filter.appliedOptions));
};
