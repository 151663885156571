import { isNil, toNumber } from 'lodash';

export * from './locationHelpers_untyped';

// we are converting to a precision of 5 decimal points
// given the information available on https://en.wikipedia.org/wiki/Decimal_degrees
// 5 decimal points give us an accuracy of ~ 1m, which should be absolutely fine to determine
// if a position has changed
const DECIMAL_FACTOR = 1000000;
const TOLERANCE_THRESHOLD = 15;

function toComparableInteger(numStr: string): number {
    return Math.floor(parseFloat(numStr) * DECIMAL_FACTOR);
}

type PossibleLatLngValue = number | string | null;

type PossibleLatLngLocation = {
    lat?: PossibleLatLngValue;
    lng?: PossibleLatLngValue;
    latitude?: PossibleLatLngValue;
    longitude?: PossibleLatLngValue;
};
export function areLatLngsEqual(
    location1?: PossibleLatLngLocation,
    location2?: PossibleLatLngLocation
) {
    if (!location1 || !location2) {
        return !location1 && !location2;
    }
    const lat1 = location1.lat ?? location1.latitude;
    const lng1 = location1.lng ?? location1.longitude;
    const lat2 = location2.lat ?? location2.latitude;
    const lng2 = location2.lng ?? location2.longitude;
    return (
        Math.abs(toComparableInteger(`${lat1}`) - toComparableInteger(`${lat2}`)) <=
            TOLERANCE_THRESHOLD &&
        Math.abs(toComparableInteger(`${lng1}`) - toComparableInteger(`${lng2}`)) <=
            TOLERANCE_THRESHOLD
    );
}

export function isValidLatitude(latitude?: PossibleLatLngValue) {
    if (isNil(latitude)) {
        return false;
    }
    const strLat = `${latitude}`;
    return strLat.length > 0 && Math.abs(toNumber(strLat)) <= 90;
}

export function isValidLongitude(longitude?: PossibleLatLngValue) {
    if (isNil(longitude)) {
        return false;
    }
    const strLng = `${longitude}`;
    return strLng.length > 0 && Math.abs(toNumber(strLng)) <= 180;
}

export function isValidLatLng(latitude?: PossibleLatLngValue, longitude?: PossibleLatLngValue) {
    return isValidLatitude(latitude) && isValidLongitude(longitude);
}
