import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ElasticReport } from '@mark43/rms-api';

import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { getViewModelProperties, ViewModel } from '~/client-common/helpers/viewModelHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import testIds from '../../../../../core/testIds';
import { currentUserDepartmentIdSelector } from '../../../../../modules/core/current-user/state/ui';
import Link from '../../../../../modules/core/components/links/Link';
import { ReportOwnerRestricted } from '../../../../../modules/core/components/ReportOwnerRestricted';
import ElasticCell from '../ElasticCell';
import dateCellFactory from './dateCellFactory';

const AgencyNameContainer = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

const EventStartUtcCell = dateCellFactory('eventStartUtc');

type LinkedRenOwnerDateCellProps = {
    elasticReportViewModel: ViewModel<ElasticReport, { currentSubmissionOwnerId?: string }>;
    currentUserDepartmentId: ReturnType<typeof currentUserDepartmentIdSelector>;
    departmentNameFromConsortiumLinksByDepartmentId: ReturnType<
        typeof departmentNameFromConsortiumLinksByDepartmentIdSelector
    >;
};

function LinkedRenOwnerDateCell({
    elasticReportViewModel,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}: LinkedRenOwnerDateCellProps) {
    const viewModelProps = getViewModelProperties(elasticReportViewModel);
    const departmentDisplayTag =
        elasticReportViewModel.departmentId !== currentUserDepartmentId
            ? ` (${departmentNameFromConsortiumLinksByDepartmentId(
                  elasticReportViewModel.departmentId
              )})`
            : undefined;

    const { restrictViewReportOwners } = elasticReportViewModel.reportDefinition;

    return (
        <div className="elastic-report-info-with-date">
            <ElasticCell row={elasticReportViewModel}>
                {(isClickable: boolean) => (
                    <>
                        <div className="elastic-report-ren" data-test-id={testIds.CASE_LINKED_REN}>
                            <Link
                                disabled={!isClickable}
                                disabledColor="darkGrey"
                                hoverOnUnderline={true}
                                to={`reports/${elasticReportViewModel.id}`}
                                onClick={(event: MouseEvent<HTMLAnchorElement>) =>
                                    event.stopPropagation()
                                }
                            >
                                {elasticReportViewModel.reportingEventNumber}
                            </Link>
                        </div>
                        <div className="elastic-report-owner">
                            <FeatureFlagged
                                flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                                fallback={viewModelProps.currentSubmissionOwnerId}
                            >
                                <ReportOwnerRestricted
                                    restrictViewReportOwners={restrictViewReportOwners}
                                    permissionSet={elasticReportViewModel.permissionSet}
                                >
                                    {viewModelProps.currentSubmissionOwnerId}
                                </ReportOwnerRestricted>
                            </FeatureFlagged>
                        </div>
                        {departmentDisplayTag && (
                            <AgencyNameContainer>{departmentDisplayTag}</AgencyNameContainer>
                        )}
                        <EventStartUtcCell {...elasticReportViewModel} />
                    </>
                )}
            </ElasticCell>
        </div>
    );
}

export default compose<LinkedRenOwnerDateCellProps, { columnKey: 'renOwnerDate' }>(
    mapProps((ownerProps) => ({ elasticReportViewModel: ownerProps })),
    connect(
        createStructuredSelector({
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            departmentNameFromConsortiumLinksByDepartmentId:
                departmentNameFromConsortiumLinksByDepartmentIdSelector,
        })
    )
)(LinkedRenOwnerDateCell);
