import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { Fieldset as MFTFieldset } from 'markformythree';
import { Box, cssVar } from 'arc';
import { useSelector } from 'react-redux';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import FormGroup from '../../../../../core/forms/components/FormGroup';
import FormRow from '../../../../../core/forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTCheckbox } from '../../../../../core/forms/components/checkboxes/Checkbox';
import InlineAttachmentsUploader from '../../../../../attachments/core/components/InlineAttachmentsUploader';
import { InlineAttachmentsEntityProfileUploader } from '../../../../../core/names/components/InlineAttachmentsEntityProfileUploader';
import { itemSidePanelMasterItemIdSelector } from '../../../state/ui';
import withItemCategoryFilterOptions from './withItemCategoryFilterOptions';

function DrugsFieldset({ helpTextCollisionBoundary, itemCategoryFilterOptions }) {
    const masterItemProfileId = useSelector(itemSidePanelMasterItemIdSelector);

    return (
        <SidePanelSection>
            <MFTFieldset path="drugs">
                <FormGroup>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: cssVar('arc.space.6'),
                            mt: cssVar('arc.space.2'),
                        }}
                    >
                        <InlineAttachmentsUploader
                            entityId={masterItemProfileId}
                            entityType={EntityTypeEnum.ITEM_PROFILE.name}
                            linkType={LinkTypesEnum.ITEM_PROFILE_PHOTO}
                        >
                            {(inlineAttachmentsRenderProps) => (
                                <InlineAttachmentsEntityProfileUploader
                                    {...inlineAttachmentsRenderProps}
                                />
                            )}
                        </InlineAttachmentsUploader>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <ArbiterMFTAttributeSelect
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="itemCategoryAttrId"
                                attributeType={AttributeTypeEnum.ITEM_CATEGORY.name}
                                filterOptions={itemCategoryFilterOptions}
                            />
                            {/* Two ArbiterMFTCheckboxes are never expected to be visible at same time */}
                            {/* isPrecursorChemical for Texas LEA customers */}
                            <ArbiterMFTCheckbox
                                path="isPrecursorChemical"
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                            />
                            {/* isCounterfeit for Louisana NIBRS customers */}
                            <ArbiterMFTCheckbox
                                path="isCounterfeit"
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                            />
                        </Box>
                    </Box>
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="description"
                    />
                    <ArbiterMFTBooleanSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="wasContainerOpen"
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="containerAmountRemaining"
                        length="md"
                    />
                    <FormRow>
                        <ArbiterMFTText
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="itemMake"
                            length="md"
                        />
                        <ArbiterMFTAttributeSelect
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="primaryColorAttrId"
                            attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                            length="md"
                        />
                    </FormRow>
                </FormGroup>
            </MFTFieldset>
        </SidePanelSection>
    );
}

/**
 * Fieldset in the item entry v2 form that appears only when the item type is drugs. It includes
 *   fields from the ItemProfile model.
 */
export default withItemCategoryFilterOptions(globalAttributes.itemType.drugs)(DrugsFieldset);
