import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { first, map } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { locationEntityLinksViewModelsWhereSelector } from '~/client-common/core/domain/location-entity-links/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatAliases } from '~/client-common/core/domain/elastic-involved-locations/state/ui';
import { getDisplaySource } from '~/client-common/core/domain/locations/utils/locationSourceHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import VerifiedTag from '../../../../../modules/core/components/tags/VerifiedTag';
import { FieldName } from '../../../../../modules/core/components/FieldName';
import { useDateTimeFormatter } from '../../../../../modules/core/current-user/hooks/dateTimeFormats';
import { useSubPremiseFormatters } from '../../../../../modules/core/locations/hooks/useSubPremiseFormatters';

const LocationCellLineItem = styled.div`
    margin-top: 10px;
`;

const AliasesWrapper = styled.div`
    display: flex;
    align-items: baseline;
`;

const AliasesContent = styled.div`
    margin-right: 5px;
    font-weight: 600;
`;

const AdditionalDatesTitle = styled.div`
    margin-top: 10px;
    font-weight: 600;
`;

const AdditionalDateSet = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
`;

const strings = componentStrings.core.LocationCell;

/*
 * This should probably be replaced with `StyledLocationCell`
 *
 * NOTE: Do not destructure and spread here -- spreading `elasticLocation`
 * will cause it to lose its `Symbol` properties
 *
 */
export default function LocationCell(elasticLocation) {
    const { isVerified, subdivisionAttrIds, categoryAttrId, propertyTypeAttrId } =
        getViewModelProperties(elasticLocation);
    const locationEntityLinksViewModelsWhere = useSelector(
        locationEntityLinksViewModelsWhereSelector
    );
    const isSubPremiseSupportEnabled = useSelector(
        applicationSettingsSelector
    ).SUBPREMISE_SUPPORT_ENABLED;
    const { buildElasticLocationLines } = useSubPremiseFormatters();
    const dateTimeFormatter = useDateTimeFormatter();
    const locationEntityLink = first(
        locationEntityLinksViewModelsWhere({
            locationId: elasticLocation.id,
            entityType: elasticLocation.ownerType,
            entityId: elasticLocation.ownerId,
            linkType: elasticLocation.type,
        })
    );
    const { positionAttrId } = getViewModelProperties(locationEntityLink);
    const { startDateUtc, enteredDateUtc, endDateUtc, additionalDates = [] } = elasticLocation.locationEntityLink || {};
    const aliases = elasticLocation.aliases ? formatAliases(elasticLocation.aliases) : '';
    const renderVerifiedTag = () =>
        isVerified && (
            <VerifiedTag
                className="elastic-location-verified-tag"
                content={
                    isSubPremiseSupportEnabled
                        ? getDisplaySource(elasticLocation.resolverSource)
                        : undefined
                }
            />
        );

    const renderLocationLines = () => (
        <div className="location-lines">
            {map(
                buildElasticLocationLines(elasticLocation, {
                    showAliases: !isSubPremiseSupportEnabled,
                }),
                (line, index) => (
                    <div key={index}>{line}</div>
                )
            )}
        </div>
    );

    return (
        <div className="elastic-location-info">
            <FeatureFlagged
                flag="SUBPREMISE_SUPPORT_ENABLED"
                fallback={
                    <>
                        {renderLocationLines()}
                        {renderVerifiedTag()}
                    </>
                }
            >
                <>
                    <AliasesWrapper>
                        <AliasesContent>{aliases}</AliasesContent>
                        {renderVerifiedTag()}
                    </AliasesWrapper>
                    {renderLocationLines()}
                </>
            </FeatureFlagged>

            {subdivisionAttrIds && subdivisionAttrIds.length ? (
                <div className="subdivisions-line">
                    <span className="subdivisions-info">{subdivisionAttrIds}</span>
                </div>
            ) : undefined}
            {categoryAttrId && <div className="category-line">{categoryAttrId}</div>}

            {propertyTypeAttrId && <div className="property-type-line">{propertyTypeAttrId}</div>}
            {positionAttrId && (
                <LocationCellLineItem>
                    <FieldName fieldName={fields.LOCATION_ENTITY_LINK_POSITION_ATTR_ID} />:{' '}
                    {positionAttrId}
                </LocationCellLineItem>
            )}
            {startDateUtc && (
                <LocationCellLineItem>
                    <FieldName fieldName={fields.LOCATION_ENTITY_LINK_START_DATE_UTC} />:{' '}
                    {dateTimeFormatter.formatDate(startDateUtc)}
                </LocationCellLineItem>
            )}
            {endDateUtc && (
                <LocationCellLineItem>
                    <FieldName
                        fieldName={fields.LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_END_DATE_UTC}
                    />
                    : {dateTimeFormatter.formatDate(endDateUtc)}
                </LocationCellLineItem>
            )}
            {enteredDateUtc && (
                <LocationCellLineItem>
                    <FieldName fieldName={fields.LOCATION_ENTITY_LINK_ENTERED_DATE_UTC} />:{' '}
                    {dateTimeFormatter.formatDate(enteredDateUtc)}
                </LocationCellLineItem>
            )}
            {additionalDates.length > 0 && (
                <>
                    <AdditionalDatesTitle>
                        {strings.additionalDates}
                    </AdditionalDatesTitle>
                    {additionalDates.map(
                        (
                            {
                                startDateUtc: additionalDatesStartDateUtc,
                                endDateUtc: additionalDatesEndDateUtc,
                                description: additionalDatesDescription,
                            },
                            index
                        ) => (
                            <AdditionalDateSet key={index}>
                                {additionalDatesStartDateUtc && (
                                    <LocationCellLineItem>
                                        <FieldName fieldName={fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_HOME_ADDRESS_START_DATE_UTC} />:{' '}
                                        {dateTimeFormatter.formatDate(additionalDatesStartDateUtc)}
                                    </LocationCellLineItem>
                                )}
                                {additionalDatesEndDateUtc && (
                                    <LocationCellLineItem>
                                        <FieldName fieldName={fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_HOME_ADDRESS_END_DATE_UTC} />:{' '}
                                        {dateTimeFormatter.formatDate(additionalDatesEndDateUtc)}
                                    </LocationCellLineItem>
                                )}
                                {additionalDatesDescription && (
                                    <LocationCellLineItem>
                                        <FieldName fieldName={fields.LOCATION_ENTITY_LINK_ADDITIONAL_DATE_HOME_ADDRESS_DESCRIPTION} />:{' '}
                                        {additionalDatesDescription}
                                    </LocationCellLineItem>
                                )}
                            </AdditionalDateSet>
                        )
                    )}
                </>
            )}
        </div>
    );
}
