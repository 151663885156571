import { AttributeTypeEnumType, AttributeTypeEnum } from '@mark43/rms-api';
import { FederatedSearchPerson, PersonFields } from 'mark43-federated-search';
import { isNil, keys, omitBy, pick } from 'lodash';
import {
    createPersonProfileFormConfiguration,
    PersonProfileFormDataShape,
} from './createPersonProfileFormConfiguration';
import { TaskStatusOption } from 'src/scripts/modules/tasks/core/types';

const convertDexPersonToPersonProfile = (
    dexPerson: FederatedSearchPerson,
    getAttributesByType: ({ type }: { type: AttributeTypeEnumType }) => TaskStatusOption[]
) => {
    const getAttributeId = (value: string | undefined, formPath: AttributeTypeEnumType) =>
        getAttributeIdByDisplayValue(value, formPath, getAttributesByType);

    const attributeProperties = {
        sexAttrId: getAttributeId(dexPerson.sex, AttributeTypeEnum.SEX.name),
        raceAttrId: getAttributeId(dexPerson.race, AttributeTypeEnum.RACE.name),
        ethnicityAttrId: getAttributeId(dexPerson.ethnicityCode, AttributeTypeEnum.ETHNICITY.name),
        eyeColorAttrId: getAttributeId(dexPerson.eyeColor, AttributeTypeEnum.EYE_COLOR.name),
        skinToneAttrId: getAttributeId(dexPerson.skinTone, AttributeTypeEnum.SKIN_TONE.name),
        hairColorAttrId: getAttributeId(dexPerson.hairColor, AttributeTypeEnum.HAIR_COLOR.name),
        birthStateAttrId: getAttributeId(dexPerson.birthPlaceCode, AttributeTypeEnum.STATE.name),
        dlStateAttrId: getAttributeId(dexPerson.driversLicenseState, AttributeTypeEnum.STATE.name),
    };

    const personProfile: Partial<PersonProfileFormDataShape> = {
        ...pick(dexPerson, [
            PersonFields.firstName,
            PersonFields.middleName,
            PersonFields.suffix,
            PersonFields.dateOfBirth,
        ]),
        ssn: dexPerson.socialSecurityNumber ? +dexPerson.socialSecurityNumber : undefined,
        lastName: dexPerson.lastName || dexPerson.name,
        dlNumber: dexPerson.driversLicenseNumber,
        placeOfBirth: dexPerson.birthPlaceCode,
        heightFeet: dexPerson.height ? +dexPerson.height : undefined,
        weight: dexPerson.weight ? +dexPerson.weight : undefined,
        ...attributeProperties,
        emergencyContacts: dexPerson.personEmergencyContacts || [],
        phoneNumbers: dexPerson.namePhoneNumbers || [],
        emails: dexPerson.nameEmails || [],
        monikers: dexPerson.nameMonikers || [],
    };

    if (dexPerson.nameIdentifiers && dexPerson.nameIdentifiers.length > 0) {
        personProfile.identifiers = dexPerson.nameIdentifiers.map((nameIdentifier) => ({
            identifier: nameIdentifier.identifier,
            nameIdentifierTypeAttrId: getAttributeId(
                nameIdentifier.nameIdentifierType,
                AttributeTypeEnum.NAME_IDENTIFIER_TYPE.name
            ),
            nameIdentifierTypeOther: nameIdentifier.nameIdentifierTypeOther,
        }));
    }

    if (dexPerson.scarMarkTattooCode) {
        personProfile.identifyingMarks = [
            {
                identifyingMarkTypeAttrId: getAttributeId(
                    dexPerson.scarMarkTattooCode,
                    AttributeTypeEnum.IDENTIFYING_MARK_TYPE.name
                ),
                description: dexPerson.scarMarkTattooCode,
            },
        ];
    }

    if (dexPerson.personInjuries && dexPerson.personInjuries.length > 0) {
        personProfile.injuries = dexPerson.personInjuries.map((injury) => ({
            description: injury.description,
            injuryTypeAttrId: getAttributeId(
                injury.injuryType,
                AttributeTypeEnum.INJURY_CATEGORY.name
            ),
            bodyPartAttrId: getAttributeId(injury.bodyPart, AttributeTypeEnum.BODY_PART.name),
            weaponsUsedAttrId: getAttributeId(
                injury.weaponsUsed,
                AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name
            ),
            wasWeaponSeized: injury.wasWeaponSeized,
        }));
    }

    if (dexPerson.personProbations && dexPerson.personProbations.length > 0) {
        personProfile.personProbations = dexPerson.personProbations.map((probation) => ({
            probationTypeAttrId: getAttributeId(
                probation.probationType,
                AttributeTypeEnum.PROBATION_TYPE.name
            ),
            miscDescription: probation.miscDescription,
        }));
    }

    return personProfile;
};

export const getPersonProfileFromDexPerson = (
    dexPerson: FederatedSearchPerson,
    getAttributesByType: ({ type }: { type: AttributeTypeEnumType }) => TaskStatusOption[]
) => {
    const personProfile = convertDexPersonToPersonProfile(dexPerson, getAttributesByType);

    const formConfiguration = createPersonProfileFormConfiguration();
    return pick(personProfile, keys(formConfiguration));
};

const getAttributeIdByDisplayValue = (
    value: string | undefined,
    formPath: AttributeTypeEnumType,
    getAttributesByType: ({ type }: { type: AttributeTypeEnumType }) => TaskStatusOption[]
) => {
    const attributesByType = getAttributesByType({ type: formPath });

    const currentAttribute = attributesByType.find((attribute) => attribute.display === value);
    return currentAttribute?.value;
};

export const mergeDexFormModelWithPersonFormModel = (
    dexFormModel: PersonProfileFormDataShape,
    personFormModel: PersonProfileFormDataShape
) => {
    return {
        ...personFormModel,
        ...omitBy(dexFormModel, isNil),
        personProbations: [
            ...(dexFormModel.personProbations || []),
            ...(personFormModel.personProbations || []),
        ],
        emergencyContacts: [
            ...(dexFormModel.emergencyContacts || []),
            ...(personFormModel.emergencyContacts || []),
        ],
        phoneNumbers: [
            ...(dexFormModel.phoneNumbers || []),
            ...(personFormModel.phoneNumbers || []),
        ],
        emails: [...(dexFormModel.emails || []), ...(personFormModel.emails || [])],
        monikers: [...(dexFormModel.monikers || []), ...(personFormModel.monikers || [])],
        employmentHistories: [
            ...(dexFormModel.employmentHistories || []),
            ...(personFormModel.employmentHistories || []),
        ],
        identifiers: [...(dexFormModel.identifiers || []), ...(personFormModel.identifiers || [])],
        identifyingMarks: [
            ...(dexFormModel.identifyingMarks || []),
            ...(personFormModel.identifyingMarks || []),
        ],
        injuries: [...(dexFormModel.injuries || []), ...(personFormModel.injuries || [])],
    };
};

export const attributesToPrefetch = [
    AttributeTypeEnum.STATE.name,
    AttributeTypeEnum.NAME_IDENTIFIER_TYPE.name,
    AttributeTypeEnum.SKIN_TONE.name,
    AttributeTypeEnum.EYE_COLOR.name,
    AttributeTypeEnum.HAIR_COLOR.name,
    AttributeTypeEnum.IDENTIFYING_MARK_TYPE.name,
    AttributeTypeEnum.BODY_PART.name,
    AttributeTypeEnum.PROBATION_TYPE.name,
    AttributeTypeEnum.VICTIM_DISABILITY_TYPE.name,
    AttributeTypeEnum.INJURY_CATEGORY.name,
    AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name,
    AttributeTypeEnum.SEX.name,
];
