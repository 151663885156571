import React from 'react';
import { compose } from 'recompose';
import { ElasticReport } from '@mark43/rms-api';

import { getViewModelProperties, ViewModel } from '~/client-common/helpers/viewModelHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { ReportOwnerRestricted } from '../../../../../modules/core/components/ReportOwnerRestricted';

type ElasticReportViewModel = ViewModel<ElasticReport, { currentSubmissionOwnerId?: string }>;

function ReportOwnerCell(elasticReportViewModel: ElasticReportViewModel) {
    const viewModel = getViewModelProperties(elasticReportViewModel);
    const { restrictViewReportOwners } = elasticReportViewModel.reportDefinition;

    return (
        <div>
            <FeatureFlagged
                flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                fallback={<div>{viewModel.currentSubmissionOwnerId}</div>}
            >
                <ReportOwnerRestricted
                    restrictViewReportOwners={restrictViewReportOwners}
                    permissionSet={elasticReportViewModel.permissionSet}
                >
                    {viewModel.currentSubmissionOwnerId}
                </ReportOwnerRestricted>
            </FeatureFlagged>
        </div>
    );
}

export default compose<ElasticReportViewModel, { columnKey: 'reportOwner' }>()(ReportOwnerCell);
