import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { compose, mapProps } from 'recompose';
import { useSelector } from 'react-redux';
import { ElasticReport } from '@mark43/rms-api';

import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { getViewModelProperties, ViewModel } from '~/client-common/helpers/viewModelHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import Link from '../../../../../modules/core/components/links/Link';
import { currentUserDepartmentIdSelector } from '../../../../../modules/core/current-user/state/ui';
import { ReportOwnerRestricted } from '../../../../../modules/core/components/ReportOwnerRestricted';

import ElasticCell from '../ElasticCell';

const AgencyNameContainer = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

type InfoCellProps = {
    elasticReportViewModel: ViewModel<ElasticReport, { currentSubmissionOwnerId?: string }>;
};

function InfoCell({ elasticReportViewModel }: InfoCellProps) {
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const departmentNameFromConsortiumLinksByDepartmentId = useSelector(
        departmentNameFromConsortiumLinksByDepartmentIdSelector
    );

    const viewModelProps = getViewModelProperties(elasticReportViewModel);
    const isExternalAgency = elasticReportViewModel.departmentId !== currentUserDepartmentId;
    const departmentDisplayTag = isExternalAgency
        ? ` (${departmentNameFromConsortiumLinksByDepartmentId(
              elasticReportViewModel.departmentId
          )})`
        : undefined;
    const ren = elasticReportViewModel.reportingEventNumber;
    const { restrictViewReportOwners } = elasticReportViewModel.reportDefinition;

    return (
        <ElasticCell row={elasticReportViewModel}>
            {(isClickable: boolean) => (
                <div className="elastic-report-info">
                    <Link
                        disabled={!isClickable}
                        hoverOnUnderline={true}
                        disabledColor="darkGrey"
                        onClick={(event: MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
                        to={`reports/${elasticReportViewModel.id}`}
                    >
                        {ren &&
                            (elasticReportViewModel.isLegacy ? (
                                <div className="legacy-search-result">{`${ren}*`}</div>
                            ) : (
                                <div>{ren}</div>
                            ))}
                    </Link>
                    {departmentDisplayTag && (
                        <AgencyNameContainer>{departmentDisplayTag}</AgencyNameContainer>
                    )}
                    <div>
                        <FeatureFlagged
                            flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                            fallback={viewModelProps.currentSubmissionOwnerId}
                        >
                            <ReportOwnerRestricted
                                restrictViewReportOwners={restrictViewReportOwners}
                                permissionSet={elasticReportViewModel.permissionSet}
                            >
                                {viewModelProps.currentSubmissionOwnerId}
                            </ReportOwnerRestricted>
                        </FeatureFlagged>
                    </div>
                </div>
            )}
        </ElasticCell>
    );
}

export default compose<InfoCellProps, { columnKey: 'info' }>(
    mapProps((ownerProps) => ({ elasticReportViewModel: ownerProps }))
)(InfoCell);
