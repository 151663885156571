import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FilterMenuList, FilterMenuRenderProps, FilterSearchInput } from '@arc/filter-list';

import { agencyProfileOptionsWhereSelector } from '~/client-common/core/domain/agency-profiles/state/ui';
import { currentUserDepartmentIdSelector } from '../../current-user/state/ui';

const StyledFilterMenuList = styled(FilterMenuList)`
    max-width: 25rem;
`;

export const AgencyFilter: React.FC<FilterMenuRenderProps> = ({
    appliedOptions,
    setAppliedFilters,
    selectionType,
    ...props
}) => {
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const [query, setQuery] = React.useState('');

    const agencyWhereProfiles = useSelector(agencyProfileOptionsWhereSelector);
    const agencyArrayList = agencyWhereProfiles({
        departmentId: currentUserDepartmentId,
    });
    const agencyFilterOptions = agencyArrayList.map((item) => {
        return { label: item.display, value: String(item.value), id: Number(item.value) };
    });

    return (
        <>
            <FilterSearchInput onChange={(e) => setQuery(e.target.value)} />
            <StyledFilterMenuList
                query={query}
                options={agencyFilterOptions}
                appliedOptions={appliedOptions}
                onSelect={setAppliedFilters}
                selectionType={selectionType}
                {...props}
            />
        </>
    );
};
