import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import noop from 'lodash/noop';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import Row from '../../../core/components/Row';
import { InlineBanner } from '../../../core/components/InlineBanner';
import TaskTable, { columnKeys } from '../../../tasks/core/components/TaskTable';
import { useGetTasksForLinkedEntity } from '../../../tasks/core/hooks/useGetTasksForLinkedEntity';
import { openTaskSidePanelForEntity } from '../../../tasks/core/state/ui';
import { RmsDispatch } from '../../../../core/typings/redux';
import testIds from '../../../../core/testIds';
import { refreshTasksDashboard, tasksDashboardSearch } from '../../../tasks/dashboard/state/ui';

import TaskDashboardSearchResults from '../../../tasks/dashboard/components/TaskDashboardSearchResults';
import { TaskDashboardSearchResultsParents } from '../../../tasks/core/config';
import { ProfileRouteParams, getEntityDataFromRouteParams } from '../helpers/routeHelpers';

const strings = componentStrings.entityProfiles.EntityProfileTasks;

type EntityProfileTasksProps = WithRouterProps<ProfileRouteParams>;

const taskEnhancementsColumnConfigurations = [
    {
        width: 133,
        key: columnKeys.title,
    },
    {
        width: 140,
        key: columnKeys.dueDateUtc,
    },
    {
        width: 109,
        key: columnKeys.assigneeRoleId,
    },
    {
        width: 109,
        key: columnKeys.typeAttrId,
    },
    {
        width: 109,
        key: columnKeys.priorityAttrId,
    },
];

const hiddenColumnKeys = [columnKeys.source];

const NewTaskButton = styled(Button)`
    float: right;
    margin-right: 0;
    margin-top: 4px;
`;

const EntityProfileTasks: React.FC<EntityProfileTasksProps> = ({ params }) => {
    const { entityId, entityType } = getEntityDataFromRouteParams(params);

    const dispatch = useDispatch<RmsDispatch>();
    useEffect(() => {
        dispatch(tasksDashboardSearch.actionCreators.resetState());
        dispatch(
            tasksDashboardSearch.actionCreators.search({ formData: { entityId, entityType } })
        );
    }, [dispatch, entityId, entityType]);

    const { errorMessage, tasks } = useGetTasksForLinkedEntity(entityId, entityType);

    // TODO: compute entityTitle for display
    const openTaskSidePanel = useCallback(() => {
        // This will open the TaskSidePanel component embedded in TaskTable.
        dispatch(openTaskSidePanelForEntity([{ entityId, entityType }]));
    }, [dispatch, entityId, entityType]);

    const refreshEntityTasks = useCallback(() => {
        dispatch(refreshTasksDashboard());
    }, [dispatch]);

    if (errorMessage) {
        return <InlineBanner status="error" children={errorMessage} />;
    }

    // TODO: after saving side panel, task table data is stale
    // pass in onSave/onDelete props to TaskTable to update tasks state or refresh tasks by calling resource
    return (
        <>
            <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_TASKS}>
                <Row>
                    <NewTaskButton
                        className={buttonTypes.PRIMARY}
                        iconLeft={<Icon fontSize="24px" type={iconTypes.ADD} />}
                        testId={testIds.NEW_TASK_BUTTON}
                        onClick={openTaskSidePanel}
                    >
                        {strings.newTask}
                    </NewTaskButton>
                </Row>
            </OnlyWithAbility>

            <FeatureFlagged
                flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                fallback={() => (
                    <TaskTable
                        hiddenColumnKeys={hiddenColumnKeys}
                        tasks={tasks}
                        columnConfigurations={undefined}
                        onSave={noop}
                        onDeleteTask={noop}
                    />
                )}
            >
                <TaskDashboardSearchResults
                    columnConfigurations={taskEnhancementsColumnConfigurations}
                    hiddenColumnKeys={hiddenColumnKeys}
                    onSave={refreshEntityTasks}
                    parentComponent={TaskDashboardSearchResultsParents.ENTITY_PROFILE_TASKS}
                />
            </FeatureFlagged>
        </>
    );
};

export default withRouter(EntityProfileTasks);
