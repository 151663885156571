import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ListItem, UnorderedList, Box } from 'arc';
import { map } from 'lodash';

import {
    BARCODE_VALUE,
    DISPLAY_EVIDENCE_LABEL_ITEM_ID,
    ITEM_PROFILE_DESCRIPTION,
    ITEM_PROFILE_ITEM_TYPE_ITEM_SERIAL_NUMBER,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import testIds from '../../../../../core/testIds';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import Text from '../../../../core/forms/components/Text';
import { ConditionalTooltip } from '../../../../core/components/tooltip';
import { useManageEvidenceFilterContext } from './ManageEvidenceDashboardFilters';

const strings = componentStrings.evidence.dashboard.searchForm;

const TooltipContent = () => {
    const {
        BARCODE_VALUE: barcodeIdDisplay,
        DISPLAY_EVIDENCE_LABEL_ITEM_ID: itemIdDisplay,
        ITEM_PROFILE_DESCRIPTION: itemDesciptionDisplay,
        ITEM_PROFILE_ITEM_TYPE_ITEM_SERIAL_NUMBER: serialIdDisplay,
        REPORT_REPORTING_EVENT_NUMBER: renDisplay,
    } = useFields([
        BARCODE_VALUE,
        DISPLAY_EVIDENCE_LABEL_ITEM_ID,
        ITEM_PROFILE_DESCRIPTION,
        ITEM_PROFILE_ITEM_TYPE_ITEM_SERIAL_NUMBER,
        REPORT_REPORTING_EVENT_NUMBER,
    ]);

    const searchables = [
        strings.searchOptionPopover.itemIdentifiers(
            renDisplay,
            barcodeIdDisplay,
            serialIdDisplay,
            itemIdDisplay
        ),
        strings.searchOptionPopover.propertyOwners,
        strings.searchOptionPopover.itemNotes,
        strings.searchOptionPopover.itemDescription(itemDesciptionDisplay),
    ];

    return (
        <Box p={2}>
            <p>{strings.searchBy}</p>
            <UnorderedList>
                {map(searchables, (text) => (
                    <ListItem>{text}</ListItem>
                ))}
            </UnorderedList>
        </Box>
    );
};

export const ManageEvidenceDashboardSearch = ({
    onQueryChange,
}: {
    onQueryChange?: (query: string) => void;
}) => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);

    const fieldDisplayNames = useFields([REPORT_REPORTING_EVENT_NUMBER]);

    const { query } = useManageEvidenceFilterContext();
    const [showTooltip, setShowTooltip] = React.useState(false);

    const isEvidenceFilterSortUpdatesEnabled = !!applicationSettings.EVIDENCE_FILTER_SORT_UPDATES;

    const placeholder = useMemo(() => {
        return isEvidenceFilterSortUpdatesEnabled
            ? strings.placeholders.basicPlaceholder
            : strings.placeholders.quickSearchQuery(
                  fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER
              );
    }, [fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER, isEvidenceFilterSortUpdatesEnabled]);

    if (!currentUserDepartmentId) {
        return null;
    }

    return (
        <>
            <ConditionalTooltip
                condition={isEvidenceFilterSortUpdatesEnabled}
                content={<TooltipContent />}
                isOpen={showTooltip}
                align="start"
                delayDuration={300}
                maxWidth="unset"
            >
                <span>
                    <Text
                        leftIcon="Search"
                        textInputOnly
                        placeholder={placeholder}
                        isClearable={isEvidenceFilterSortUpdatesEnabled}
                        value={query}
                        onChange={(e) => {
                            if (e) {
                                setShowTooltip(false);
                                if (onQueryChange) {
                                    onQueryChange(e.toString());
                                }
                            } else {
                                setShowTooltip(true);
                                if (onQueryChange) {
                                    onQueryChange('');
                                }
                            }
                        }}
                        testId={testIds.EVIDENCE_DASHBOARD_SEARCH_TEXT_INPUT}
                        onFocus={() => setShowTooltip(true)}
                        onBlur={() => setShowTooltip(false)}
                    />
                </span>
            </ConditionalTooltip>
        </>
    );
};
