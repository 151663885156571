import React from 'react';
import { FilterMenuRenderProps } from '@arc/filter-list';
import { createField } from 'markformythree';
import {
    DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_ITEM_ID_LABEL,
    DISPLAY_EVIDENCE_LABEL_ITEM_ID,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { MFTText } from '../../forms/components/Text';
import { NItemsFormFilter } from '.';

const fieldsConfig = { itemId: createField<string>({}) };
export type ItemdIdValueT = {
    itemId: string;
};

export const ItemIdNItemsFilter: React.FC<FilterMenuRenderProps<ItemdIdValueT>> = ({
    appliedOptions,
    ...props
}) => {
    const fields = useFields([
        DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_ITEM_ID_LABEL,
        DISPLAY_EVIDENCE_LABEL_ITEM_ID,
    ]);

    return (
        <NItemsFormFilter<ItemdIdValueT>
            fields={fieldsConfig}
            appliedOptions={appliedOptions}
            name={fields.DISPLAY_EVIDENCE_LABEL_ITEM_ID}
            render={() => <MFTText path="itemId" label={fields.DISPLAY_EVIDENCE_LABEL_ITEM_ID} />}
            addText={fields.DISPLAY_ONLY_EVIDENCE_SEARCH_ADD_ITEM_ID_LABEL}
            {...props}
        />
    );
};
