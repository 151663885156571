import {
    createField,
    createFormConfiguration,
    lifecycleOptions,
    MFTFormConfiguration,
} from 'markformythree';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

const eFileProfilesDefendantsSearchFormConfig: MFTFormConfiguration = createFormConfiguration({
    firstName: createField<string>({}),
    lastName: createField<string>({}),
    organizationName: createField<string>({}),
    sentToCPS: createField<string>({}),
    decision: createField<string>({}),
});

export type EFileProfilesDefendantsSearchFormConfig = typeof eFileProfilesDefendantsSearchFormConfig;

const eFileProfilesDefendantsSearchForm = {
    name: formClientEnum.E_FILES_SEARCH_PROFILES_DEFENDANTS_FORM,
    configuration: eFileProfilesDefendantsSearchFormConfig,
    lifecycle: lifecycleOptions.REGISTER_AND_RETAIN,
};

export default eFileProfilesDefendantsSearchForm;
