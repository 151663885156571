import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IconNameT, cssVar } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../core/testIds';

import { IconButton } from './IconButton';
import { Tooltip } from './tooltip';

const strings = componentStrings.core.ButtonCopyToClipboard;

const CopyToClipboardContainer = styled.div`
    margin: -3px 3px;
`;

const StyledIconButton = styled(IconButton)<{ $color: string }>`
    color: ${({ $color }) => $color};
    &:active {
        box-shadow: none;
    }

    &:focus {
        box-shadow: none;
    }
`;

type ButtonCopyToClipboardPropsType = {
    message?: string;
    dataToCopy: string;
    disabled?: boolean;
};

type ComponentStateType = {
    isOpen?: boolean;
    icon: IconNameT;
    iconColor: string;
    tooltipMessage: string;
};

/**
 * Use this component to copy text to clipboard
 * @param [options.message] The tooltip message
 * @param options.dataToCopy The data to copy to clipboard
 */

const ButtonCopyToClipboard = ({
    message,
    dataToCopy,
    disabled,
}: ButtonCopyToClipboardPropsType) => {
    const initialState: ComponentStateType = useMemo(
        () => ({
            isOpen: undefined,
            icon: 'Duplicate',
            iconColor: cssVar('arc.colors.brand.default'),
            tooltipMessage: message ? `${strings.copy} ${message}` : strings.copy,
        }),
        [message]
    );

    const [state, setState] = useState<ComponentStateType>(initialState);

    const copyToClipboardHandler = useCallback(async () => {
        try {
            // this is needed because it will close without it
            setState({ ...state, isOpen: true });
            await navigator.clipboard.writeText(dataToCopy);
            setState({
                iconColor: cssVar('arc.colors.positive.default'),
                isOpen: true,
                tooltipMessage: strings.copied,
                icon: 'SupervisorApproved',
            });
            setTimeout(() => {
                setState(initialState);
            }, 3000);
        } catch (err) {
            throw err;
        }
    }, [dataToCopy, initialState, state]);

    return (
        <CopyToClipboardContainer data-test-id={testIds.COPY_TO_CLIPBOARD_CONTAINER}>
            <Tooltip
                side="top"
                avoidCollisions={false}
                hasArrow
                isOpen={state.isOpen}
                content={state.tooltipMessage}
            >
                <StyledIconButton
                    disabled={disabled}
                    $color={state.iconColor}
                    aria-label="copy to clipboard"
                    size="sm"
                    icon={state.icon}
                    variant="ghost"
                    testId={testIds.COPY_TO_CLIPBOARD}
                    onClick={copyToClipboardHandler}
                />
            </Tooltip>
        </CopyToClipboardContainer>
    );
};

export default ButtonCopyToClipboard;
