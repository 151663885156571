import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum, RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { compact, get, map, size } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { mapProps, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';

import {
    agencyProfileByIdSelector,
    agencyProfilesWhereSelector,
} from '~/client-common/core/domain/agency-profiles/state/data';
import {
    assistingOfficerViewModelsWhereSelector,
    formatAssistingOfficers,
} from '~/client-common/core/domain/assisting-officers/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import { formatAttributeLinkViewModels } from '~/client-common/core/domain/attributes/state/ui';
import { eventDetailViewModelByReportIdSelector } from '~/client-common/core/domain/event-details/state/ui';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { reportAttributeViewModelsWhereSelector } from '~/client-common/core/domain/report-attributes/state/ui';
import { reportNotificationViewModelsWhereSelector } from '~/client-common/core/domain/report-notifications/state/ui';
import { reportViewModelByIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { legacyEntityDetailsWhereSelector } from '~/client-common/core/domain/legacy-entity-details/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportDefinitionByReportIdSelector } from '~/client-common/core/domain/report-definitions/state/data';

import Row from '../../../../core/components/Row';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import LegacyEntityDetails from '../../../../records/core/components/LegacyEntityDetails';
import { OnlyWithAbility, abilitiesEnum } from '../../../../core/abilities';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import testIds from '../../../../../core/testIds';
import { LocationSummaryViewWrapper } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapper';
import { ExternalReportStatusSummary } from '../../../../core/external-report-statuses/components/ExternalReportStatusSummary';
import { OffenseCaseStatusesSummary } from '../../../../core/offense-case-statuses/components/OffenseCaseStatusSummary';
import { RestrictVisibilityWithDash } from '../../../../core/components/RestrictVisibilityWithDash';
import { useIsReportOwnerRestricted } from '../../../../core/hooks/useIsReportOwnerRestricted';
import UcrClassificationSummary from './UcrClassificationSummary';
import ReportUCRSummary from './ReportUCRSummary';
import ReportCaseStatusSummary from './ReportCaseStatusSummary';
import CadTicketSummary from './CadTicketSummary';

const strings = componentStrings.reports.core.EventInfoCard;

const mapStateToProps = createStructuredSelector({
    reportViewModelById: reportViewModelByIdSelector,
    reportNotificationViewModelsWhere: reportNotificationViewModelsWhereSelector,
    reportAttributeViewModelsWhere: reportAttributeViewModelsWhereSelector,
    eventDetailViewModelByReportId: eventDetailViewModelByReportIdSelector,
    assistingOfficerViewModelsWhere: assistingOfficerViewModelsWhereSelector,
    agencyProfileById: agencyProfileByIdSelector,
    nameReportLinksWhere: nameReportLinksWhereSelector,
    agencyProfilesWhere: agencyProfilesWhereSelector,
    legacyEntityDetailsWhere: legacyEntityDetailsWhereSelector,
    reportDefinitionByReportId: reportDefinitionByReportIdSelector,
});

const mapPropsOnChange = ({
    reportId,
    reportViewModelById,
    reportNotificationViewModelsWhere,
    reportAttributeViewModelsWhere,
    eventDetailViewModelByReportId,
    assistingOfficerViewModelsWhere,
    agencyProfileById,
    nameReportLinksWhere,
    agencyProfilesWhere,
    legacyEntityDetailsWhere,
    reportDefinitionByReportId,
}) => {
    const reportViewModel = reportViewModelById(reportId);
    const reportDefinition = reportDefinitionByReportId(reportId);
    const reportNotificationViewModels = reportNotificationViewModelsWhere({ reportId });
    const weatherAttributeViewModels = reportAttributeViewModelsWhere({
        reportId,
        attributeType: AttributeTypeEnum.WEATHER.name,
    });
    const eventStatisticsAttributeViewModels = reportAttributeViewModelsWhere({
        reportId,
        attributeType: AttributeTypeEnum.EVENT_STATISTICS.name,
    });
    const eventDetailViewModel = eventDetailViewModelByReportId(reportId);
    const assistingOfficerViewModels = assistingOfficerViewModelsWhere({ reportId });
    const reportingAgency = agencyProfileById(reportViewModel.agencyId);

    const suspectInReportLinks = nameReportLinksWhere({
        reportId,
        linkType: LinkTypesEnum.SUSPECT_IN_REPORT,
    });
    const witnessInReportLinks = nameReportLinksWhere({
        reportId,
        linkType: LinkTypesEnum.WITNESS_IN_REPORT,
    });
    const victimInReportLinks = nameReportLinksWhere({
        reportId,
        linkType: LinkTypesEnum.VICTIM_IN_REPORT,
    });
    const otherNameInReportLinks = nameReportLinksWhere({
        reportId,
        linkType: LinkTypesEnum.OTHER_NAME_IN_REPORT,
    });
    const allAgencyProfilesForDepartment = agencyProfilesWhere({
        departmentId: reportViewModel.departmentId,
    });

    const legacyEntityDetails = legacyEntityDetailsWhere({
        entityType: EntityTypeEnum.REPORT.name,
        entityId: reportId,
    });

    return {
        reportViewModel,
        reportNotificationViewModels,
        weatherAttributeViewModels,
        eventStatisticsAttributeViewModels,
        eventDetailViewModel,
        assistingOfficerViewModels,
        reportingAgency,
        hasSuspectInReport: suspectInReportLinks.length > 0,
        hasWitnessInReport: witnessInReportLinks.length > 0,
        hasVictimInReport: victimInReportLinks.length > 0,
        hasOtherNameInReport: otherNameInReportLinks.length > 0,
        showReportingAgency: size(allAgencyProfilesForDepartment) > 1,
        legacyEntityDetails,
        restrictViewReportOwners: !!reportDefinition.restrictViewReportOwners,
    };
};

const EventInfoCardSummary = compose(
    connect(mapStateToProps),
    withPropsOnChange(
        [
            'reportId',
            'reportViewModelById',
            'reportNotificationViewModelsWhere',
            'reportAttributeViewModelsWhere',
            'eventDetailViewModelByReportId',
            'assistingOfficerViewModelsWhere',
            'agencyProfileById',
            'nameReportLinksWhere',
            'agencyProfilesWhere',
            'legacyEntityDetailsWhere',
        ],
        mapPropsOnChange
    ),
    mapProps((props) => ({
        ...props,
        formattedWeatherAttributes: formatAttributeLinkViewModels(props.weatherAttributeViewModels),
        formattedEventStatisticsAttributes: formatAttributeLinkViewModels(
            props.eventStatisticsAttributeViewModels
        ),
        formattedAssistingOfficers: formatAssistingOfficers(props.assistingOfficerViewModels),
        formattedResponders: map(
            get(props.eventDetailViewModel, 'paramedics'),
            (paramedic) => `${paramedic.name}${paramedic.idNumber ? ` #${paramedic.idNumber}` : ''}`
        ),
    }))
)(function _EventInfoCardSummary({
    reportViewModel,
    eventDetailViewModel,
    reportNotificationViewModels,
    formattedAssistingOfficers,
    formattedWeatherAttributes,
    formattedEventStatisticsAttributes,
    formattedResponders,
    reportingAgency = {},
    hasSuspectInReport,
    hasWitnessInReport,
    hasVictimInReport,
    hasOtherNameInReport,
    showReportingAgency,
    legacyEntityDetails,
    reportId,
    currentReportREN,
    reportingEventId,
    restrictViewReportOwners,
}) {
    const eventDetailViewModelProperties = getViewModelProperties(eventDetailViewModel);
    const legacyLinkTypeConfigs = [
        {
            linkType: LinkTypesEnum.SUSPECT_IN_REPORT,
            title: strings.suspectSectionTitle,
            show: { people: true, organizations: false },
            showSection: hasSuspectInReport,
        },
        {
            linkType: LinkTypesEnum.WITNESS_IN_REPORT,
            title: strings.witnessSectionTitle,
            show: { people: true, organizations: false },
            showSection: hasWitnessInReport,
        },
        {
            linkType: LinkTypesEnum.VICTIM_IN_REPORT,
            title: strings.victimSectionTitle,
            show: { people: true, organizations: true },
            showSection: hasVictimInReport,
        },
        {
            linkType: LinkTypesEnum.OTHER_NAME_IN_REPORT,
            title: strings.otherInvolvedNameSectionTitle,
            show: { people: true, organizations: true },
            showSection: hasOtherNameInReport,
        },
    ];
    const legacyNameSummaryViews = map(legacyLinkTypeConfigs, (config) => {
        return (
            config.showSection && (
                <CardSection title={config.title}>
                    <NameSummaryViewWrapper
                        renForRecents={currentReportREN}
                        summaryMode={true}
                        linkType={config.linkType}
                        show={config.show}
                        reportId={reportId}
                        contextType={EntityTypeEnum.REPORT.name}
                        contextId={reportId}
                        parentEntityType={EntityTypeEnum.REPORT.name}
                        parentId={reportId}
                    />
                </CardSection>
            )
        );
    });

    const checkIsReportOwnerRestricted = useIsReportOwnerRestricted();

    return (
        <RMSArbiterProvider context={RefContextEnum.FORM_EVENT_INFO.name}>
            {() => (
                <>
                    <SummaryList labelWidth={160} contentWidth={380} testId={testIds.CARD_SUMMARY}>
                        <CardSection>
                            <FeatureFlagged
                                flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                                fallback={<CadTicketSummary reportId={reportId} />}
                            />
                            <SummaryRowDate
                                fieldName={fields.REPORT_CREATED_DATE_UTC}
                                date={reportViewModel.createdDateUtc}
                            />
                            {showReportingAgency && (
                                <SummaryRow fieldName={fields.REPORT_AGENCY_ID}>
                                    {reportingAgency.agencyName}
                                </SummaryRow>
                            )}
                            <SummaryRow fieldName={fields.DISPLAY_EVENT_DETAIL_EVENT_START_END}>
                                {eventDetailViewModelProperties.eventTime}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.EVENT_DETAIL_RESPONDING_OFFICER_ID}>
                                <FeatureFlagged
                                    flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                                    fallback={eventDetailViewModelProperties.respondingOfficerId}
                                >
                                    <RestrictVisibilityWithDash
                                        restricted={checkIsReportOwnerRestricted(
                                            restrictViewReportOwners,
                                            reportViewModel.permissionSet
                                        )}
                                    >
                                        {eventDetailViewModelProperties.respondingOfficerId}
                                    </RestrictVisibilityWithDash>
                                </FeatureFlagged>
                            </SummaryRow>
                            <SummaryRow fieldName={fields.EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID}>
                                {eventDetailViewModelProperties.personnelUnitAttrId}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.DISPLAY_EVENT_DETAIL_REPORTING_MEMBERS}>
                                {formattedAssistingOfficers}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.EVENT_DETAIL_CRIME_DESCRIPTION}>
                                {get(eventDetailViewModel, 'crimeDescription')}
                            </SummaryRow>
                            <SummaryRow
                                fieldName={
                                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEATHER_ATTRIBUTE_ID
                                }
                            >
                                {formattedWeatherAttributes}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.EVENT_DETAIL_IS_STREET_CRIME}>
                                {eventDetailViewModelProperties.isStreetCrime}
                            </SummaryRow>
                            <SummaryRow
                                fieldName={fields.EVENT_DETAIL_ANY_NON_OFFICER_FIRED_WEAPON}
                            >
                                {eventDetailViewModelProperties.anyNonOfficerFiredWeapon}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.EVENT_DETAIL_WAS_ANYONE_STRUCK_BY_BULLET}>
                                {get(eventDetailViewModelProperties, 'wasAnyoneStruckByBullet')}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.EVENT_DETAIL_PARAMEDICS_AT_SCENE}>
                                {eventDetailViewModelProperties.paramedicsAtScene}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.EVENT_DETAIL_PARAMEDICS_UNIT}>
                                {get(eventDetailViewModel, 'paramedicsUnit')}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.EVENT_DETAIL_SERIALIZED_PARAMEDICS}>
                                {map(formattedResponders, (responder) => (
                                    <div key={responder}>{responder}</div>
                                ))}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.EVENT_DETAIL_INVOLVES_SCHOOL_INCIDENT}>
                                {eventDetailViewModelProperties.isSchoolRelated}
                            </SummaryRow>
                            <SummaryRow
                                fieldName={fields.EVENT_DETAIL_ARREST_OR_CUSTODIAL_RELATED_DEATH}
                            >
                                {eventDetailViewModelProperties.isArrestOrCustodialRelatedDeath}
                            </SummaryRow>
                            <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_UCR}>
                                <ReportUCRSummary reportId={reportId} />
                            </OnlyWithAbility>
                            <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_UCR}>
                                <UcrClassificationSummary reportId={reportId} />
                            </OnlyWithAbility>
                            <ReportCaseStatusSummary reportId={reportId} />
                            <FeatureFlagged flag="RMS_CLEARANCE_IMPROVEMENTS_ENABLED">
                                <OnlyWithAbility
                                    has={abilitiesEnum.REPORTING.VIEW_EXTERNAL_REPORT_STATUS}
                                >
                                    <Row>
                                        <ExternalReportStatusSummary
                                            reportingEventId={reportingEventId}
                                        />
                                    </Row>
                                </OnlyWithAbility>
                            </FeatureFlagged>
                            <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_CASE_STATUS}>
                                <OffenseCaseStatusesSummary reportId={reportId} />
                            </OnlyWithAbility>
                            {size(formattedEventStatisticsAttributes) > 0 && (
                                <SummaryRow
                                    fieldName={
                                        fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EVENT_STATISTICS_ATTRIBUTE_ID
                                    }
                                >
                                    {formattedEventStatisticsAttributes}
                                </SummaryRow>
                            )}
                        </CardSection>
                        <VisibilityObserver
                            path="reportTakenLocation.locationId"
                            formName={RefContextEnum.FORM_EVENT_INFO.name}
                            render={({ hidden }) =>
                                !hidden ? (
                                    <CardSection
                                        fieldName={
                                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_EVENT_LINK_TYPE
                                        }
                                        testId={testIds.REPORT_TAKEN_LOCATION_SECTION}
                                    >
                                        <LocationSummaryViewWrapper
                                            entityType={EntityTypeEnum.REPORT.name}
                                            entityId={reportId}
                                            linkType={LinkTypesEnum.LOCATION_OF_EVENT}
                                            summaryMode={true}
                                        />
                                    </CardSection>
                                ) : null
                            }
                        />
                        <VisibilityObserver
                            path="notifications"
                            formName={RefContextEnum.FORM_EVENT_INFO.name}
                            render={({ hidden }) =>
                                !hidden ? (
                                    <CardSection
                                        fieldName={
                                            fields.DISPLAY_ONLY_EVENT_DETAIL_NOTIFICATION_N_ITEMS_WRAPPER
                                        }
                                        title={strings.notificationsSectionTitle}
                                        testId={testIds.EVENT_CARD_SUMMARY_NOTIFICATIONS_SECTION}
                                    >
                                        {map(
                                            reportNotificationViewModels,
                                            (reportNotificationViewModel) => (
                                                <FormattedDate
                                                    key={reportNotificationViewModel.id}
                                                    date={
                                                        reportNotificationViewModel.notificationDateUtc
                                                    }
                                                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                                                >
                                                    {(formattedDate) => (
                                                        <SummaryRow
                                                            fieldName={
                                                                fields.REPORT_NOTIFICATION_NOTIFICATION_TYPE_ATTR_ID
                                                            }
                                                        >
                                                            {compact([
                                                                getViewModelProperties(
                                                                    reportNotificationViewModel
                                                                ).notificationTypeAttrId,
                                                                formattedDate,
                                                                reportNotificationViewModel.details
                                                                    ? `"${reportNotificationViewModel.details}"`
                                                                    : '',
                                                            ]).join('; ')}
                                                        </SummaryRow>
                                                    )}
                                                </FormattedDate>
                                            )
                                        )}
                                    </CardSection>
                                ) : null
                            }
                        />
                        <VisibilityObserver
                            path="reportingParty"
                            formName={RefContextEnum.FORM_EVENT_INFO.name}
                            render={({ hidden }) =>
                                !hidden ? (
                                    <CardSection
                                        testId={testIds.EVENT_CARD_SUMMARY_REPORTING_PARTY_SECTION}
                                        fieldName={
                                            fields.NAME_REPORT_LINK_LINK_TYPE_REPORTING_PARTY_IN_REPORT_LINK_TYPE
                                        }
                                    >
                                        <NameSummaryViewWrapper
                                            renForRecents={currentReportREN}
                                            summaryMode={true}
                                            reportId={reportId}
                                            contextType={EntityTypeEnum.REPORT.name}
                                            contextId={reportId}
                                            parentEntityType={EntityTypeEnum.REPORT.name}
                                            parentId={reportId}
                                            linkType={LinkTypesEnum.REPORTING_PARTY_IN_REPORT}
                                            show={{
                                                people: true,
                                                organizations: true,
                                                societies: true,
                                            }}
                                        />
                                    </CardSection>
                                ) : null
                            }
                        />
                        {legacyNameSummaryViews}
                    </SummaryList>
                    <LegacyEntityDetails legacyEntityDetails={legacyEntityDetails} />
                </>
            )}
        </RMSArbiterProvider>
    );
});

export default EventInfoCardSummary;
