import { EntityTypeEnum, ProductModuleEnum, OperationTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { itemHasChainEventsSelector } from '~/client-common/core/domain/chain-events/state/data';
import OnlyWithEntityPermission from '~/client-common/core/domain/entity-permissions/components/OnlyWithEntityPermission';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { FederatedSearchSidePanel } from '../../../../federated-search/components/FederatedSearchSidePanel';
import { useOverlayStore } from '../../../../core/overlays/hooks/useOverlayStore';
import { OnlyWithAbility, abilitiesEnum } from '../../../../core/abilities';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import GenericPermissionsModal from '../../../../core/permissions/components/GenericPermissionsModal';
import {
    currentEntityProfileTypeSelector,
    currentEntityProfileIdSelector,
    currentEntityProfileUserOperationTypesSelector,
    currentEntityProfileHasVisiblePermissionsSelector,
    currentEntityProfileIsEditableSelector,
    currentEntityProfileDepartmentIdSelector,
} from '../../state/ui';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import EvidenceItemMenu from '../../../../evidence/core/components/EvidenceItemMenu';
import RecordsHeaderButton from '../../../../records/core/components/header/RecordsHeaderButton';
import RecordsHeaderPermissionsButton from '../../../../records/core/components/header/RecordsHeaderPermissionsButton';
import RecordsHeaderExportsButton from '../../../../records/core/components/header/RecordsHeaderExportsButton';
import RecordsHeaderCurrentViewersToggle from '../../../../records/core/components/header/RecordsHeaderCurrentViewersToggle';
import routesConfig from '../../../../../routing/routesConfig';
import testIds from '../../../../../core/testIds';
import { useEntityProfileDexConfiguration } from './useEntityProfileDexConfiguration';

const strings = componentStrings.entityProfiles.EntityProfileHeaderMenu;

const HeaderMenu = styled.div`
    float: right;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    gap: var(--arc-space-1);
    flex-wrap: wrap;
`;

const Container = styled.span`
    display: inline-flex;
`;

const HeaderInlineWrapper = styled.div`
    display: inline-block;
`;

function EntityProfileHeaderMenu({ editProfileSidePanel, entityName, isHidden, itemType }) {
    const overlayId = overlayIdEnum.ENTITY_PROFILE_PERMISSIONS_OVERLAY;

    const itemHasChainEvents = useSelector(itemHasChainEventsSelector);
    const entityType = useSelector(currentEntityProfileTypeSelector);
    const entityId = useSelector(currentEntityProfileIdSelector);
    const userOperationTypes = useSelector(currentEntityProfileUserOperationTypesSelector);
    const visiblePermissions = useSelector(currentEntityProfileHasVisiblePermissionsSelector);
    const isEditable = useSelector(currentEntityProfileIsEditableSelector);
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const currentEntityProfileDepartmentId = useSelector(currentEntityProfileDepartmentIdSelector);
    const isExternalProfile = currentEntityProfileDepartmentId !== currentUserDepartmentId;

    const entityHasChainEvents = itemHasChainEvents(entityId);

    const {
        showSearchFromDex,
        federatedSearchButtonConfiguration,
    } = useEntityProfileDexConfiguration({
        entityId,
        itemType,
        entityType,
        isExternalProfile,
        isEditable,
        editProfileSidePanel,
    });

    const overlayStore = useOverlayStore();

    return (
        <HeaderMenu>
            {showSearchFromDex && (
                <ProductModuled productModule={ProductModuleEnum.RMS_DEX.name}>
                    <OnlyWithAbility
                        hasOneOf={[
                            abilitiesEnum.RMS_DEX.RMS_DEX_MAKE_INQUIRIES,
                            abilitiesEnum.RMS_DEX.RMS_DEX_VIEW_RESULTS,
                        ]}
                    >
                        <FederatedSearchSidePanel
                            buttonsConfiguration={federatedSearchButtonConfiguration}
                            renderButton={() => (
                                <Button
                                    className={buttonTypes.ICON}
                                    onClick={() => {
                                        overlayStore.open(overlayIdEnum.DEX_SIDE_PANEL);
                                    }}
                                >
                                    <Icon size={18} type={iconTypes.DATA_EXHANGE_INQUIRIES} />
                                </Button>
                            )}
                        />
                    </OnlyWithAbility>
                </ProductModuled>
            )}
            {!isExternalProfile &&
                visiblePermissions &&
                entityType === EntityTypeEnum.PERSON_PROFILE.name && (
                    <RecordsHeaderExportsButton
                        exportRouteName={routesConfig.ENTITY_PROFILE_EXPORTS.name}
                        path={`/profiles/persons/${entityId}`}
                    />
                )}
            {!isExternalProfile && visiblePermissions && (
                <Container>
                    {isHidden ? (
                        <RecordsHeaderPermissionsButton />
                    ) : (
                        <GenericPermissionsModal
                            buttonElement={<RecordsHeaderPermissionsButton overlayId={overlayId} />}
                            entityId={entityId}
                            entityType={entityType}
                            overlayId={overlayId}
                            permissionSet={userOperationTypes}
                            title={strings.permissionModalTitle(entityName)}
                        />
                    )}
                </Container>
            )}

            <RecordsHeaderCurrentViewersToggle entityType={entityType} entityId={entityId} />
            {entityHasChainEvents &&
                !isExternalProfile &&
                visiblePermissions &&
                entityType === EntityTypeEnum.ITEM_PROFILE.name && (
                    <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
                        <OnlyWithAbility has={abilitiesEnum.EVIDENCE.VIEW_GENERAL}>
                            <EvidenceItemMenu masterItemId={entityId} />
                        </OnlyWithAbility>
                    </ProductModuled>
                )}
            {!isExternalProfile && isEditable && editProfileSidePanel && (
                <OnlyWithEntityPermission
                    permissionSet={userOperationTypes}
                    has={OperationTypeEnum.WRITE.name}
                >
                    <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_GENERAL}>
                        <HeaderInlineWrapper>
                            {editProfileSidePanel(({ open, setCloseFocusRefs }) => (
                                <RecordsHeaderButton overlay={strings.edit}>
                                    <OpenSidePanelWithQueryParamEffect
                                        open={open}
                                        enabled={isEditable}
                                    />
                                    <Button
                                        className={buttonTypes.ICON}
                                        iconLeft={
                                            <Icon
                                                size={18}
                                                type={iconTypes.EDIT}
                                                color="cobaltBlue"
                                            />
                                        }
                                        onClick={open}
                                        ref={setCloseFocusRefs}
                                        testId={testIds.EDIT_BUTTON}
                                    />
                                </RecordsHeaderButton>
                            ))}
                        </HeaderInlineWrapper>
                    </OnlyWithAbility>
                </OnlyWithEntityPermission>
            )}
        </HeaderMenu>
    );
}

const SHOW_SIDE_PANEL_QUERY_PARAM = 'showSidePanel';

/**
 * When the `showSidePanel` query parameter is present,
 * show the edit side panel
 *
 * This is primarily for integration with the JMS so that we can seamlessly
 * link out to the edit side panel
 *
 * Currently, this specifically only works for contexted profiles with a BOOKING parent entity type
 * as well as master profiles
 */
const OpenSidePanelWithQueryParamEffect = withRouter(({ open, router, enabled }) => {
    const showSidePanel = SHOW_SIDE_PANEL_QUERY_PARAM in router.location.query;
    React.useEffect(() => {
        // If the `showSidePanel` query parmeter exists, and this effect is `enabled`
        // then automatically open the side panel
        if (enabled && showSidePanel && open) {
            open();
        }
    }, [open, showSidePanel, enabled]);
    return null;
});

export default EntityProfileHeaderMenu;
