import React from 'react';
import { useSelector } from 'react-redux';
import { identity } from 'lodash';
import styled from 'styled-components';
import { ReportStatusHistory } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import {
    reportDefinitionHasArrestCard,
    reportDefinitionHasOffenseCard,
} from '~/client-common/helpers/reportDefinitionsHelpers';
import { reportDefinitionByReportIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import {
    currentReportCardUITitleByTypeSelector,
    currentReportIdSelector,
    latestReportStatusHistoryForCurrentReport,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import _Card, { CardSection, CardProps } from '../../../../../legacy-redux/components/core/Card';
import testIds from '../../../../../core/testIds';
import { currentUserIdSelector } from '../../../../core/current-user/state/ui';
import { OnlyWithAbility, abilitiesEnum } from '../../../../core/abilities';
import _ApprovalStatusIcon from '../../../../records/core/components/ApprovalStatusIcon';
import { currentReportSealingSelector } from '../../../../record-privacy/sealing/state/ui';

import _ReportCommentsAndHistory from './ReportCommentsAndHistory';
import NibrsAdminSection, { NibrsErrors } from './NibrsAdminSection';

const strings = componentStrings.reports.core.ReportStatusCommentsCard.core;

const Card = styled(_Card)`
    font-size: var(--arc-fontSizes-sm);

    &,
    * {
        box-sizing: border-box;
    }
`;

const ApprovalStatusCardSection = styled(CardSection)`
    padding: 10px 20px 10px 20px;
    float: none;
    display: flex;
    gap: var(--arc-space-4);
`;

const ApprovalStatusIcon = styled(_ApprovalStatusIcon)`
    display: inline-flex;
    width: 35px;
    vertical-align: top;
`;

const ApprovalStatusCardContent = styled.div`
    display: inline-block;
    width: 100%;
    vertical-align: top;
`;

const ApprovalStatusCardTitle = styled.div`
    margin-top: 10px;
    margin-bottom: 12px;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const ReportCommentsAndHistory = styled(_ReportCommentsAndHistory)`
    margin-top: 10px;
`;

interface CardWithApprovalStatusIconProps extends CardProps {
    approvalStatus: keyof typeof approvalStatusClientEnum;
    reportSealingInfo?: Partial<ReturnType<typeof currentReportSealingSelector>>;
    latestHistory?: ReportStatusHistory;
    latestHistoryText?: (createdBy: string, formattedDate: string | null) => string;
    showHistory?: boolean;
    shouldDisableApprovalButton?: boolean;
    nibrsErrorsData?: NibrsErrors;
    isSingleReview?: boolean;
}

/**
 * An approval status card with an approval status icon at the top left of it.
 *   The reason this is a separate component is that the "draft" state does not
 *   have an approval status icon.
 */
function CardWithApprovalStatusIcon({
    approvalStatus,
    title,
    className,
    children,
    reportSealingInfo = {},
    latestHistoryText = identity,
    customSummaryModeColor,
    customSummaryModeBorderColor,
    testId,
    showHistory = true,
    shouldDisableApprovalButton = false,
    nibrsErrorsData,
    isSingleReview,
}: CardWithApprovalStatusIconProps) {
    const latestHistory = useSelector(latestReportStatusHistoryForCurrentReport);
    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);
    const currentUserId = useSelector(currentUserIdSelector);
    const currentReportId = useSelector(currentReportIdSelector);
    const reportDefinitionByReportId = useSelector(reportDefinitionByReportIdSelector);
    const currentReportCardUITitleByType = useSelector(currentReportCardUITitleByTypeSelector);

    if (!currentReportId) {
        return null;
    }

    const cardTitle = currentReportCardUITitleByType(reportCardEnum.REPORT_STATUS_COMMENTS.id);
    const currentReportDefinition = reportDefinitionByReportId(currentReportId);
    const isArrestOrOffense =
        reportDefinitionHasArrestCard(currentReportDefinition) ||
        reportDefinitionHasOffenseCard(currentReportDefinition);
    return (
        <Card
            renderContent={(summaryMode) => {
                if (summaryMode) {
                    return (
                        <>
                            <ApprovalStatusCardSection>
                                <ApprovalStatusIcon
                                    approvalStatus={approvalStatus}
                                    size={34}
                                    {...reportSealingInfo}
                                />
                                <ApprovalStatusCardContent className="mark43-react-approval-status-card-content">
                                    <ApprovalStatusCardTitle
                                        data-test-id={testIds.APPROVAL_STATUS_CARD_TITLE}
                                    >
                                        {title}
                                    </ApprovalStatusCardTitle>

                                    {showHistory && latestHistory && (
                                        <p>
                                            <FormattedDate
                                                date={latestHistory.createdDateUtc}
                                                format={FormattedDate.FORMATS.DATE_TIME_IN_SENTENCE}
                                            >
                                                {(formattedDate) => (
                                                    <>
                                                        {latestHistoryText(
                                                            currentUserId ===
                                                                latestHistory.createdBy
                                                                ? strings.you
                                                                : formatMiniUserById(
                                                                      latestHistory.createdBy
                                                                  ),
                                                            formattedDate
                                                        )}
                                                    </>
                                                )}
                                            </FormattedDate>
                                            .
                                        </p>
                                    )}
                                    {children}
                                </ApprovalStatusCardContent>
                            </ApprovalStatusCardSection>
                            {isArrestOrOffense && (
                                <FeatureFlagged flag="RMS_NIBRS_REPORT_SEGMENT_GENERATION_ENABLED">
                                    <OnlyWithAbility
                                        has={abilitiesEnum.REPORTING.NIBRS_SEGMENT_GENERATION}
                                    >
                                        <NibrsAdminSection 
                                            shouldDisableApprovalButton={shouldDisableApprovalButton} 
                                            nibrsErrorsData={nibrsErrorsData}
                                            isSingleReview={isSingleReview}
                                        />
                                    </OnlyWithAbility>
                                </FeatureFlagged>
                            )}
                            {showHistory && (
                                <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_REPORT_COMMENTS}>
                                    <ReportCommentsAndHistory />
                                </OnlyWithAbility>
                            )}
                        </>
                    );
                }
                return undefined;
            }}
            title={cardTitle}
            hideHeader={!showHistory}
            hideFooter={true}
            className={className}
            anchor="report-status-comments-card"
            customSummaryModeColor={customSummaryModeColor}
            customSummaryModeBorderColor={customSummaryModeBorderColor}
            testId={testId}
        />
    );
}

export default CardWithApprovalStatusIcon;
