import {
    GetDataTableConfigForUserByTableId,
    UpsertDataTableConfig as _UpsertDataTableConfig,
} from '@mark43/rms-api/dist/resources/DatatableconfigResource';
import { req } from '../../../lib/ajax';
import { createResource } from '../../../lib/resources/Resource';
import { DataTableEnumValues, DataTableConfigUpsertData } from './DataTableConfigProvider';

/** The type UpsertDataTableConfig in the api expects a full DataTableConfig for the data.
 * But it actually only needs `configJson` and `dataTableId` and optionally savedSearchId.
 * The other required fields get generated. */
type UpsertDataTableConfig = Pick<_UpsertDataTableConfig, 'method' | 'path' | 'returns'> & {
    data: DataTableConfigUpsertData;
};
export default createResource({
    name: 'DataTable Config Resource',
    methods: {
        // Returns all the configs for a specific DataTable from the DataTableEnum
        getByDataTableId(id: DataTableEnumValues) {
            return req<GetDataTableConfigForUserByTableId>({
                method: 'GET',
                params: {
                    data_table_id: id,
                },
                url: `datatable/config`,
            });
        },
        upsertDataTableConfig(data: DataTableConfigUpsertData) {
            return req<UpsertDataTableConfig>({
                data,
                method: 'PUT',
                url: `datatable/config`,
            });
        },
    },
});
