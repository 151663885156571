import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { FilterMenuRenderProps, FilterMenuList, FilterSearchInput } from 'arc';
import { SearchResultElasticStorageLocation } from '@mark43/evidence-api';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatStorageLocationNames } from '~/client-common/core/domain/storage-locations/utils/storageLocationHelpers';
import { useFetchStorageLocations } from '../../../core/hooks/useFetchStorageLocations';
import { logError } from '../../../../core/logging';
import errorToMessage from '../../../core/errors/utils/errorToMessage';

const strings = componentStrings.evidence.core.StorageLocationFilter;
const DEBOUNCE_DELAY = 600;

const StyledFilterMenuList = styled(FilterMenuList)`
    max-width: 25rem;
`;

export const StorageLocationFilter: React.FC<FilterMenuRenderProps> = ({
    appliedOptions,
    setAppliedFilters,
}) => {
    const [storageLocations, setStorageLocations] = useState<
        SearchResultElasticStorageLocation['items']
    >([]);
    const { searchStorageLocations } = useFetchStorageLocations();

    const fetchStorageLocations = React.useCallback(
        (query: string) => {
            const locations = searchStorageLocations({
                query,
            });
            return locations;
        },
        [searchStorageLocations]
    );

    const handleFetchSuccess = React.useCallback((result) => {
        if (result.items) {
            setStorageLocations(result.items);
        }
    }, []);

    const handleFetchError = React.useCallback((error) => {
        logError(errorToMessage(error, strings.error));
    }, []);

    const { loading, callResource: callFetchStorageLocations } = useResourceDeferred(
        fetchStorageLocations,
        handleFetchSuccess,
        handleFetchError
    );
    useEffect(() => {
        callFetchStorageLocations('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const debouncedSearch = debounce((query) => {
        callFetchStorageLocations(query);
    }, DEBOUNCE_DELAY);

    const debouncedFetchStorageLocations = React.useCallback(
        (query) => {
            debouncedSearch.cancel();
            debouncedSearch(query);
        },
        [debouncedSearch]
    );

    const handleQueryChange = (query: string) => {
        if (!query) {
            callFetchStorageLocations('');
        } else {
            debouncedFetchStorageLocations(query);
        }
    };
    return (
        <>
            <FilterSearchInput
                onChange={(e) => handleQueryChange(e.target.value)}
                isLoading={loading.isLoading}
                isClearable
            />
            <StyledFilterMenuList
                options={storageLocations.map((item) => {
                    return {
                        id: item.id,
                        value: String(item.id),
                        label: `${formatStorageLocationNames(item.parentLocationNames)}${
                            item.barcodeValue ? ` (${item.barcodeValue})` : ''
                        }`,
                    };
                })}
                onSelect={setAppliedFilters}
                appliedOptions={appliedOptions}
                selectionType="multiple"
            />
        </>
    );
};
