import React from 'react';
import styled from 'styled-components';

import { EntityTypeEnum } from '@mark43/rms-api';

import { Editor as _Editor } from '../../../core/editor/components/TinyEditor';
import { InlineBanner } from '../../../core/components/InlineBanner';
import CaseNoteLocation from './CaseNoteLocation';
import CaseNoteAttachments from './CaseNoteAttachments';

const Editor = styled(_Editor)`
    width: 100%;
    height: auto;
    margin-top: 5px;
    flex-grow: 2;
    overflow: auto;
`;

const Container = styled.div`
    font-size: var(--arc-fontSizes-sm);
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const Title = styled.div`
    padding-top: 18px;
    padding-left: 18px;
    font-weight: 700;
`;

const ReadonlyCaseNote = ({ caseNote }) => {
    const [errorMessage, setErrorMessage] = React.useState();

    return (
        <Container>
            <Title>{caseNote.title}</Title>
            <CaseNoteLocation caseNoteId={caseNote.id} />

            {errorMessage ? <InlineBanner status="error">{errorMessage}</InlineBanner> : undefined}
            <Editor
                value={caseNote.content}
                entityType={EntityTypeEnum.CASE_NOTE.name}
                getSummaryMode={() => true}
                isFullHeight
                isScrollable
                tablesEnabled
                setError={setErrorMessage}
            />
            <CaseNoteAttachments caseNoteId={caseNote.id} />
        </Container>
    );
};

export default ReadonlyCaseNote;
