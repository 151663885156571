import { parseSync, stringify, IStringifyOptions, INode } from 'svgson';
import { partition } from 'lodash';
import { logWarning } from '../../../../../../../core/logging';
import { CrashDiagramState } from '../context/reducer/diagram';
import { createWidget, createDiagramAssetsMapInitialState } from '../helpers';
import { STAGE_HEIGHT, STAGE_WIDTH_LARGE } from '../config';
import { Widget, CrashDiagramPosition, widgetTypes, WidgetType } from '../types';
import { InvalidSvgFormatError, serializerErrorMessages } from './errors';

const SVG_IMAGE_TAG_NAME = 'image';
const SVG_TEXT_TAG_NAME = 'text';
const DATA_WIDGET_TYPE_ATTRIBUTE_NAME = 'data-widget-type';
const CRASH_DIAGRAM_SVG_END_TAG = '</g></svg>';
const SVG_REG_EXP = /<svg\s[^>]*(?:\/>|>.*?<\/svg>)/;

/*
 Note; `transformNode` in parseSync's args does not recursively go through all children nodes. `stringify` does.
    parseSync: https://github.com/elrumordelaluz/svgson/blob/master/src/svgson.js#L9
    stringify: https://github.com/elrumordelaluz/svgson/blob/master/src/stringify.js#L13

 Because of this reason, I decided to wrap this into a function so that it is encapsulated.
*/
const parseCrashDiagramSvg = (crashDiagramSvgString: string, options: IStringifyOptions) => {
    return stringify(parseSync(crashDiagramSvgString), options);
};

const isSVGString = (svgString: string) => {
    return SVG_REG_EXP.test(svgString);
};

const isWidgetType = (widgetType: string): widgetType is WidgetType => {
    return Object.keys(widgetTypes).includes(widgetType);
};

function isCrashDiagramPositionProperty(
    x: CrashDiagramPosition,
    k: PropertyKey
): k is keyof CrashDiagramPosition {
    return k in x;
}

const castToNumber = (value: string | number) => {
    if (typeof value === 'number') {
        return value;
    }
    const castedNumber = Number(value);
    return !isNaN(castedNumber) ? castedNumber : undefined;
};

const getWidgetPositionFromWidgetSvgTagAttributes = (widgetProperties: INode['attributes']) => {
    if (!widgetProperties) {
        return undefined;
    }

    const width = castToNumber(widgetProperties['data-width']);
    const height = castToNumber(widgetProperties['data-height']);
    const offsetX = castToNumber(widgetProperties['data-offsetX']);
    const offsetY = castToNumber(widgetProperties['data-offsetY']);
    const rotation = castToNumber(widgetProperties['data-rotation']);
    const scaleX = castToNumber(widgetProperties['data-scaleX']);
    const scaleY = castToNumber(widgetProperties['data-scaleY']);
    const skewX = castToNumber(widgetProperties['data-skewX']);
    const skewY = castToNumber(widgetProperties['data-skewY']);
    const xPosition = Number(widgetProperties['data-x']);
    const yPosition = Number(widgetProperties['data-y']);

    return {
        width,
        height,
        offsetX,
        offsetY,
        rotation,
        scaleX,
        scaleY,
        skewX,
        skewY,
        x: xPosition,
        y: yPosition,
    };
};

export const convertDiagramSvgStringToCrashDiagramState = (
    svgString: string
): CrashDiagramState => {
    if (!isSVGString(svgString)) {
        logWarning(serializerErrorMessages.INVALID_FORMAT, { svgString });
        throw new InvalidSvgFormatError();
    }

    const extractedWidgets: Widget[] = [];
    try {
        parseCrashDiagramSvg(svgString, {
            transformNode(node) {
                const widgetType = node.attributes['data-widget-type'];

                let value;
                switch (node.name) {
                    case SVG_IMAGE_TAG_NAME:
                        value = node.attributes['xlink:href'];
                        break;
                    case SVG_TEXT_TAG_NAME:
                        value = node.children[0]?.value;
                        break;
                    default:
                        break;
                }
                const widgetPosition = getWidgetPositionFromWidgetSvgTagAttributes(node.attributes);

                const opacity = castToNumber(node.attributes['opacity']);

                if (!value || !widgetPosition || !isWidgetType(widgetType) || !opacity) {
                    return node;
                }

                extractedWidgets.push(
                    createWidget({
                        position:
                            widgetType === widgetTypes.BACKGROUND_IMAGE
                                ? {
                                      height: widgetPosition?.height,
                                      width: widgetPosition?.width,
                                      rotation: 0,
                                      offsetX: 0,
                                      offsetY: 0,
                                      x: 0,
                                      y: 0,
                                  }
                                : widgetPosition,
                        type: widgetType,
                        value,
                        opacity,
                    })
                );
                return node;
            },
        });
    } catch {
        logWarning(serializerErrorMessages.INVALID_FORMAT, { svgString });
        throw new InvalidSvgFormatError();
    }

    const [widgets, backgroundImages] = partition(
        extractedWidgets,
        (widget) => widget.type !== widgetTypes.BACKGROUND_IMAGE
    );

    const backgroundImageWidget = backgroundImages[0];

    return {
        widgets,
        backgroundImage: backgroundImageWidget?.value,
        assets: createDiagramAssetsMapInitialState(),
    };
};

const getDefaultBackgroundImageTag = (backgroundImage: string) => {
    return `<image width=${STAGE_WIDTH_LARGE} height=${STAGE_HEIGHT} ${DATA_WIDGET_TYPE_ATTRIBUTE_NAME}="${widgetTypes.BACKGROUND_IMAGE}" preserveAspectRatio="none" transform="matrix(1 0 0 1 0 0)" xlink:href="${backgroundImage}" opacity="1"/>`;
};

const removeDuplicateBackgroundImageTags = (svgString: string, matches: string[]) => {
    let modifiedSvgString = svgString;
    for (let i = 1; i < matches.length; i++) {
        modifiedSvgString = modifiedSvgString.replace(`<g>${matches[i]}</g>`, '');
    }
    return modifiedSvgString;
};

const addDefaultBackgroundImageTag = (svgString: string, backgroundImageDataUrl: string) => {
    return svgString.replace(
        CRASH_DIAGRAM_SVG_END_TAG,
        `<g>${getDefaultBackgroundImageTag(backgroundImageDataUrl)}</g>${CRASH_DIAGRAM_SVG_END_TAG}`
    );
};

const addBackgroundImageAttributesToElement = (svgString: string, backgroundImage: string) => {
    return svgString.replace(
        `xlink:href="${backgroundImage}"`,
        `xlink:href="${backgroundImage}" ${DATA_WIDGET_TYPE_ATTRIBUTE_NAME}="${widgetTypes.BACKGROUND_IMAGE}" opacity="1"`
    );
};

export const getImageTagRegularExpression = () =>
    new RegExp(`<image\\b[^>]*\\bxlink:href="([^"]+)"[^>]*\\/?>`, 'g');

export const addDataWidgetTypeAttributeToBackgroundImageTag = (
    svgString: string,
    backgroundImage: string
) => {
    const backgroundImageTagRegExp = getImageTagRegularExpression();
    const allBackgroundImageTags = svgString.match(backgroundImageTagRegExp);

    const matches =
        allBackgroundImageTags?.filter((tag) => tag.includes(`xlink:href="${backgroundImage}"`)) ||
        [];

    let modifiedSvgString = svgString;
    if (matches.length === 0) {
        modifiedSvgString = addDefaultBackgroundImageTag(modifiedSvgString, backgroundImage);
    } else if (matches.length > 1) {
        modifiedSvgString = removeDuplicateBackgroundImageTags(modifiedSvgString, matches);
    }

    if (modifiedSvgString.indexOf(DATA_WIDGET_TYPE_ATTRIBUTE_NAME) === -1) {
        modifiedSvgString = addBackgroundImageAttributesToElement(
            modifiedSvgString,
            backgroundImage
        );
    }

    return modifiedSvgString;
};

const constructWidgetPositionAttributes = (widget: Widget) => {
    const widgetPosition = widget.position;
    const widgetPositionKeys = Object.keys(widgetPosition);
    const result: Record<string, string> = {
        'data-widget-type': widget.type,
    };

    for (let i = 0; i < widgetPositionKeys.length; i++) {
        const key = widgetPositionKeys[i];
        if (isCrashDiagramPositionProperty(widget.position, key) && !!widget.position[key]) {
            result[`data-${key}`] = String(widget.position[key]);
        }
    }
    return result;
};

export const addAdditionalAttributesToSVGWidgetTags = (svgString: string, widgets: Widget[]) => {
    const widgetProcessedMap: Record<string, boolean> = {};
    return parseCrashDiagramSvg(svgString, {
        transformNode(node) {
            let value: string | undefined;

            switch (node.name) {
                case SVG_IMAGE_TAG_NAME:
                    value = node.attributes['xlink:href'];
                    break;
                case SVG_TEXT_TAG_NAME:
                    value = node.children[0]?.value;
                    break;
                default:
                    break;
            }

            if (!value) {
                return node;
            }

            const widget = widgets.find(
                (widget) => !widgetProcessedMap[widget.id] && widget.value === value
            );

            if (!widget) {
                return node;
            }

            widgetProcessedMap[widget.id] = true;
            const widgetAttributes = constructWidgetPositionAttributes(widget);
            return {
                ...node,
                attributes: {
                    ...node.attributes,
                    ...widgetAttributes,
                    opacity: widget.opacity.toString(),
                },
            };
        },
        selfClose: true,
    });
};
