import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { getContext } from 'recompose';
import { simpleControl } from 'markformythree';
import { Select as ArcSelect, SelectProps as ArcSelectProps, SelectChangeEvent } from '@arc/select';
import { arbiterMFTInput } from '../../../arbiter';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { InputStyles, computeInputStyle } from '../../helpers/inputStyles';
import {
    LegacySelectOption,
    SelectOption,
    sortOptions,
    convertLegacySelectOptionsToNewOptions,
    areLegacyOptions,
    GroupedOptions,
    SelectOptions,
    SelectOptionOrLegacySelectOptionAsSelectOption,
    LegacySelectOptions,
    areSelectOptions,
    processChangeValue,
    GroupedOption,
} from '../../helpers/selectHelpers';
import FormElement, { FormElementProps } from '../FormElement';

const { connectRRFInput } = reactReduxFormHelpers;

// Note: was having trouble getting this to work (passing generic to styled component)
// This won't work if you try to use this as a selector in a styled component:
// e.g. styled.div`
//      ${StyledArcSelect} {
//      }
// `
const StyledArcSelect = styled(ArcSelect)`
    display: block;
    width: 100%;

    .dark & {
        button {
            font-size: inherit;
            line-height: 0;
        }
    }
` as typeof ArcSelect;

type TargetValue<
    Option extends SelectOption | LegacySelectOption | GroupedOption<SelectOption>,
    Multi extends boolean = false,
> = SelectChangeEvent<
    SelectOptionOrLegacySelectOptionAsSelectOption<Option>,
    Multi
>['target']['value'];

// For the time, we need to support both signatures for options,
// as we transition away from the legacy structure
export type SelectProps<
    Option extends SelectOption | LegacySelectOption | GroupedOption<SelectOption>,
    Multi extends boolean = false,
> = {
    style?: React.CSSProperties;
    isAsync?: boolean;
    onChange?: (value: TargetValue<Option, Multi>) => void;
    onQueryChange?: (query: string) => void;
    onClick?: () => void;
    onFocus?: (value?: TargetValue<Option, Multi>) => void;
    onBlur?: (value?: TargetValue<Option, Multi>) => void;
    // DEPRECATED (not used)
    // setRef?: PropTypes.func,
    //
    // DEPRECATED (not used)
    // optionsRef: PropTypes.func,
    options?: [Option] extends [LegacySelectOption]
        ? LegacySelectOptions<Option>
        : [Option] extends [SelectOption]
          ? SelectOptions<Option>
          : [Option] extends [GroupedOption<SelectOption>]
            ? GroupedOptions<SelectOption>
            : never;
    label?: React.ReactNode;
    // TODO: DEPRECATE_WHEN_DONE_WITH_TRANSITION : Deprecate after everything is moved to the new select
    // However, for the time being, we need to support it because some options have
    // the `group` property even if they are not meant to be grouped (i.e. attributes in which
    // only one type of attribute is fetched or displayed)
    grouped?: boolean;
    showSelectedGroup?: boolean;
    // If this is true the options array must contain an additional
    // option for each category, with the parameter
    // isGrouping=true, and the same grouping as their category
    // TODO: DEPRECATE_WHEN_DONE_WITH_TRANSITION: We don't support this in the new select
    groupsAreSelectable?: boolean;
    //
    // when `clearable` is set to `false`, the `✕` icon is hidden, but the
    // selected option can still be unset by pressing backspace
    clearable?: boolean;
    disabled?: boolean;
    loading?: boolean;
    multiple?: Multi;
    // TODO: DEPRECATE_WHEN_DONE_WITH_TRANSITION: This is only used for the DateRange picker
    // but we are going to change this up, so we don't need to support this option
    fullHeightOptions?: boolean;
    //
    // When the user types a query, if the options that start with the query
    // should be sorted to the top
    // will only take affect if grouped=false
    // DEPRECATED (The only place this is false is in
    // `VehicleModelSelect` when `grouped` is true.
    // But if `grouped` is true, it does a relevance sort anyways)
    // sortOnRelevance: PropTypes.bool,
    initialShowOptions?: boolean;
    // Only usage of this is in the NarrativeGuide select
    autoFocus?: boolean;
    // Provided by `FieldSet`
    // If there is a value preset, the fieldset will change color
    onInputFocus?: ((value: boolean) => void) | null;
    // Provided by `FieldSet`
    // If there is a value preset, the fieldset will change color
    onInputBlur?: ((value: boolean) => void) | null;
    // Provided by `FieldSet` and used in `NItems` from `client-common`
    // Determines if we should add new NItem if the previous NItem has been filled out
    onInputChange?: ((value: TargetValue<Option, Multi>) => void) | null;
    touched?: boolean;
    value?: TargetValue<Option, Multi>;
    error?: string;
    helpText?: string;
    forceShowError?: boolean;
    maxMenuHeight?: number;
    maxOptionsToShow?: number;
    isRequired?: boolean;
    sortAttributes?: <Option extends SelectOption>(
        options: SelectOptions<Option> | GroupedOptions<Option> | undefined,
        query?: string
    ) => SelectOptions<Option>;
    placeholder?: string;
    testId?: string;
    menuPortalTarget?: ArcSelectProps['menuPortalTarget'];
};

export type SelectAndFormElementProps<
    Option extends LegacySelectOption | SelectOption | GroupedOption<SelectOption>,
    Multi extends boolean = false,
> = SelectProps<Option, Multi> & FormElementProps;

function Select<
    Option extends LegacySelectOption | SelectOption | GroupedOption<SelectOption>,
    Multi extends boolean = false,
>(props: SelectAndFormElementProps<Option, Multi>) {
    const {
        touched,
        multiple,
        onChange,
        value,
        options,
        loading = false,
        grouped = false,
        showSelectedGroup = true,
        onClick,
        onQueryChange,
        disabled = false,
        clearable = true,
        error,
        onInputFocus,
        onFocus,
        onBlur,
        onInputBlur,
        onInputChange,
        initialShowOptions = false,
        forceShowError,
        autoFocus,
        maxMenuHeight = 200,
        maxOptionsToShow = 1000,
        isRequired,
        sortAttributes,
        placeholder,
        testId,
        menuPortalTarget,
        style,
    } = props;
    const handleFocus = () => {
        if (onFocus) {
            onFocus(value); // call custom handler
        }
        if (onInputFocus) {
            onInputFocus(!!value); // call context handler
        }
    };
    const handleBlur = () => {
        if (onBlur) {
            onBlur(value); // call custom handler
        }
        if (onInputBlur) {
            onInputBlur(!!value); // call context handler
        }
    };
    const handleChange: ArcSelectProps<
        SelectOptionOrLegacySelectOptionAsSelectOption<Option>,
        Multi
    >['onChange'] = (e) => {
        const { selectedOptionsData } = e.detail;

        const transformedValue = processChangeValue<
            SelectOptionOrLegacySelectOptionAsSelectOption<Option>,
            Multi
        >({
            selectedOptionsData,
            selectedValuesData: e.target.value,
            multiple,
        });

        // call custom handler
        if (onChange) {
            onChange(transformedValue);
        }

        if (onInputChange) {
            // call context handler
            onInputChange(transformedValue);
        }
    };
    const handleInputChange: ArcSelectProps<
        SelectOptionOrLegacySelectOptionAsSelectOption<Option>,
        Multi
    >['onInputChange'] = (_e, value) => {
        if (onQueryChange) {
            onQueryChange(value);
        }
    };

    // For some reason I need to specifically check
    // if `areSelectedOptions` and `areLegacyOptions`
    // even though there are only 2 possible types
    //
    // Not sure why this is the case
    const convertedOptions = areLegacyOptions(options)
        ? convertLegacySelectOptionsToNewOptions(options, grouped)
        : areSelectOptions(options)
          ? options
          : // We should never get here, unless
            // options is undefined
            (options as never);

    const inputStyle = computeInputStyle({
        isRequired,
        isEmpty:
            (!value && value !== 0 && value !== false) || (Array.isArray(value) && !value.length),
        isTouched: touched,
        hasError: !!error,
        forceShowError,
    });

    return (
        <FormElement {...props} inputStyle={inputStyle} isRequired={isRequired} touched={touched}>
            <StyledArcSelect
                autoFocus={autoFocus}
                isDefaultOpen={initialShowOptions}
                maxMenuHeight={maxMenuHeight}
                isMultiple={multiple}
                onChange={handleChange}
                // our inputs should always be controlled
                value={value ?? null}
                // @ts-expect-error The type of `convertedOptions` is: `GroupedOptions<LegacySelectOptionAsSelectOption<LegacySelectOption>> | SelectOptions<LegacySelectOptionAsSelectOption<LegacySelectOption>>`
                // The type error is: GroupType<LegacySelectOptionAsSelectOption<LegacySelectOption>>' is not assignable to type 'SelectOptionOrLegacySelectOptionAsSelectOption<Option>
                options={convertedOptions}
                isLoading={loading}
                showSelectedGroup={showSelectedGroup}
                onFocus={handleFocus}
                onBlur={handleBlur}
                // @ts-expect-error The type 'readonly (SelectOptionOrLegacySelectOptionAsSelectOption<Option> | GroupBase<SelectOptionOrLegacySelectOptionAsSelectOption<Option>>)[]' is 'readonly' and cannot be assigned to the mutable type 'GroupedOptions<SelectOption>'
                // This is based on a conflict between:
                // 1. The prop expects either the legacy or non-legacy type, due to the type of options
                // 2. The `sortOptions` function is typed as if it accepts only the non-legacy type
                sortOptions={sortAttributes || sortOptions}
                onMenuOpen={onClick}
                onInputChange={handleInputChange}
                isDisabled={disabled}
                isClearable={clearable}
                maxOptionsToShow={maxOptionsToShow}
                isInvalid={inputStyle === InputStyles.ERROR}
                containerZIndex={(menuIsOpen, defaultZIndex) => (menuIsOpen ? 100 : defaultZIndex)}
                placeholder={placeholder}
                // We need to hardcode this to `bottom` for now because the `auto` mode is buggy
                menuPlacement="bottom"
                data-test-id={testId}
                menuPortalTarget={menuPortalTarget}
                style={style}
            />
        </FormElement>
    );
}

const SelectWithContext = getContext({
    onInputChange: PropTypes.func,
    onInputFocus: PropTypes.func,
    onInputBlur: PropTypes.func,
})(Select);

export default SelectWithContext as unknown as typeof Select;

// Augment props interface with `path: string`
type SelectWithSimpleControl = <
    Option extends SelectOption | LegacySelectOption | GroupedOption<SelectOption>,
    Multi extends boolean,
>(
    props: SelectProps<Option, Multi> & React.ComponentProps<ReturnType<typeof simpleControl>>
) => JSX.Element;

// @ts-expect-error client-common to client RND-7529
export const RRFSelect = connectRRFInput(SelectWithContext) as SelectWithSimpleControl;

export const MFTSelect = simpleControl(SelectWithContext) as SelectWithSimpleControl;

export const ArbiterMFTSelect = arbiterMFTInput(SelectWithContext) as SelectWithSimpleControl;
