import { isObject, compact, every } from 'lodash';
import { getInstanceIdFromFormReferenceValue } from 'dragon-react';

import { useCurrentFormValueForPath } from './use-current-form-value-for-path';

function isRecord(value: unknown): value is Record<string, unknown> {
    return isObject(value) && !Array.isArray(value);
}

function isRecordArray(value: unknown): value is Record<string, unknown>[] {
    return Array.isArray(value) && every(value, (item) => isObject(item));
}
/**
 * Given a fully qualified path (e.g. `a.b.c[0].d`) for a form reference, returns an array of instance ids stored
 * in the current form's static value. If no value exists `undefined` is returned.
 */
export function useReferencedFormInstanceIds(path: string): (string | number)[] | undefined {
    const formReferenceData = useCurrentFormValueForPath(path);
    if (isRecord(formReferenceData)) {
        const instanceId = getInstanceIdFromFormReferenceValue(formReferenceData);
        if (!instanceId) {
            return undefined;
        }
        return [instanceId];
    } else if (isRecordArray(formReferenceData)) {
        return compact(
            formReferenceData.map((item) => getInstanceIdFromFormReferenceValue(item))
        ).sort();
    }

    return undefined;
}
