import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    collectFormValuePaths,
    collectFormValues,
    createMFTDoEffectValue,
    FormReferenceUiOptions,
    PromiseRunner,
    ruleRunner,
    FormRuleView,
    processRuleEffectDescriptors,
    ConcreteConfiguredRuleEffect,
    isRuleRunnerFailureResult,
} from 'dragon-react';
import {
    Form,
    MFTFormConfigurationDescriminator,
    lifecycleOptions,
    formEvents,
} from 'markformythree';
import { AccessorTypeEnum } from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import {
    DragonConfigDataContextValue,
    useDragonConfigDataContext,
} from '../../context/dragon-config-data';
import formsRegistry from '../../../../core/formsRegistry';
import { logWarning } from '../../../../core/logging';
import { useCurrentFormInitialValueByInstanceId } from '../../hooks/use-current-form-initial-value';
import { RMSDragonConfigurationExtensions } from '../../rms-types';
import { MFTFormContextProvider } from '../../context/mft-form';
import { DragonCard, DragonMFTFormConstructorProps } from './dragon-card';
import { reconcileDoValues } from './utils/reconcile-do-values';
import { reconcileFormErrors } from './utils/reconcile-form-errors';
import { getDataAttributes } from './utils/get-data-attributes';

type RuleExecutor = (formRuleView: FormRuleView) => Promise<
    | {
          doEffectValue: ReturnType<typeof createMFTDoEffectValue>;
          concreteRuleEffectDescriptors: ConcreteConfiguredRuleEffect[];
      }
    | undefined
>;

function ruleExecutorFactory(
    context: DragonConfigDataContextValue & { instanceId?: string | number }
): RuleExecutor {
    return async (formRuleView) => {
        const uiConfigurationIdsAccessedInConditions = formRuleView.uiConfigurationAccessors[
            AccessorTypeEnum.RULE_CONDITION.name
        ].map((descriptor) => descriptor.uiConfigurationId);
        // TODO we will have to collect paths for _each_ id and scope combination and then merge them together
        const eligibleFormValuePaths = collectFormValuePaths(
            {
                ids: uiConfigurationIdsAccessedInConditions,
                // TODO make scope work
            },
            context.uiConfigurationPathMap
        );

        // Dragon internally is unaware of the concept of form "instances". All it cares about it receiving
        // some form of state container that it can query values from. It is up to us to provide
        // the correct form for the current instance id.
        const formGetter = (formName: string) => formsRegistry.get(formName, context.instanceId);
        const scopedUiConfigurationIdToValueMap = collectFormValues(
            formGetter,
            eligibleFormValuePaths,
            context.uiConfigurationMetadataMap
        );
        const ruleResult = await ruleRunner({
            configuredRuleConditionView: formRuleView.rootConfiguredRuleCondition,
            scopedUiConfigurationIdToValueMap,
            configuredEntityTypeInstanceMap: context.configuredEntityTypeDescriptors,
        });

        if (isRuleRunnerFailureResult(ruleResult)) {
            logWarning(ruleResult.evaluationError.message, {
                configuredRuleId: formRuleView.configuredRuleId,
                rootConfiguredRuleCondition: formRuleView.rootConfiguredRuleCondition,
            });
            return undefined;
        }

        const concreteRuleEffectDescriptors = processRuleEffectDescriptors(
            ruleResult.collectedEffects,
            (options) => collectFormValuePaths(options, context.uiConfigurationPathMap),
            (uiConfigurationPathShapeMap) =>
                collectFormValues(
                    formGetter,
                    uiConfigurationPathShapeMap,
                    context.uiConfigurationMetadataMap
                )
        );

        return {
            concreteRuleEffectDescriptors,
            doEffectValue: createMFTDoEffectValue(
                concreteRuleEffectDescriptors,
                context.uiConfigurationMetadataMap
            ),
        };
    };
}

export function MFTFormWrapper({
    configuration,
    form,
    formName,
    props,
    renderSummary,
    renderChildren,
    instanceId,
}: FormReferenceUiOptions<RMSDragonConfigurationExtensions> & {
    instanceId: number | string;
}): JSX.Element {
    const context = useDragonConfigDataContext();
    const initialState = useCurrentFormInitialValueByInstanceId(instanceId);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const { formConfigurationId } = configuration;
    const cacheKeyPrefixForForm = `${formConfigurationId}~${instanceId ?? '-1'}~`;
    // Unmounting/cleanup of forms happens within an effect in `DragonCard`
    const formProps: DragonMFTFormConstructorProps = React.useMemo(() => {
        return {
            name: props.name.replace('form~', ''),
            configuration: props.configuration,
            initialState: initialState.values as DragonMFTFormConstructorProps['initialState'],
            validationEvents: [
                formEvents.INPUT_CHANGE,
                formEvents.INPUT_BLUR,
                formEvents.FORM_SUBMIT,
                formEvents.N_ITEM_ADDED,
                formEvents.N_ITEM_REMOVED,
            ].map((formEvent) => ({
                eventType: formEvent,
            })),
            onValidate: async ({
                path,
                eventType,
            }: {
                path?: string;
                eventType: (typeof formEvents)[keyof typeof formEvents];
            }) => {
                let relatedConfiguredRuleIds: number[] | undefined;
                let ruleExecutionCacheKey: string;
                const isFormSubmit = eventType === 'FORM_SUBMIT';
                if (
                    path &&
                    // This is a hack to get rules to run when items are added and removed from nitems. Right now
                    // we just run _all_ rules of the form. This is not ideal but we need to get this done for UAT.
                    // A real solution requires computation of a subtree of rules for a given path.
                    eventType !== 'N_ITEM_ADDED' &&
                    eventType !== 'N_ITEM_REMOVED'
                ) {
                    const pathSegments = path.split('.');
                    // The id we want to search for must come from MFT because it needs to tells us which field changed
                    const changedFieldId = pathSegments[pathSegments.length - 1];
                    ruleExecutionCacheKey = `${cacheKeyPrefixForForm}${changedFieldId}`;
                    // based on the id we can look up all form rule views
                    relatedConfiguredRuleIds =
                        context.uiConfigurationMetadataMap[changedFieldId]?.configuration
                            .relatedConfiguredRuleIds;
                } else {
                    // any validation event without a path will just validate the whole form
                    ruleExecutionCacheKey = `${cacheKeyPrefixForForm}${eventType}`;
                    PromiseRunner.getSingletonInstance().cancelExecutionsWithPrefix(
                        cacheKeyPrefixForForm
                    );
                    const ruleIdsForForm = context.formConfiguredRuleIdsMap[formConfigurationId];
                    relatedConfiguredRuleIds = ruleIdsForForm ? [...ruleIdsForForm] : [];
                }

                // if there are no rules for this configuration we abort
                if (!relatedConfiguredRuleIds?.length) {
                    return {
                        success: true,
                        formErrors: [],
                    };
                }

                const formRuleViews: FormRuleView[] = relatedConfiguredRuleIds.map(
                    // TODO this assertion is required because the types we receive from the backend don't satisfy
                    // the discriminated union for downstream effects that dragon-react requires
                    // We could vet this at runtime with type guards or something along those lines, or find a
                    // different approach
                    (configuredRuleConditionId) =>
                        context.formRuleMap[configuredRuleConditionId] as FormRuleView
                );

                // create execution promises for each of our rules
                const ruleExecutionPromises = formRuleViews.map(
                    ruleExecutorFactory({ ...context, instanceId })
                );

                // associate the sum of all rule executions to the changed field id and wait for the result
                const runnerResult = await PromiseRunner.getSingletonInstance().execute(
                    Promise.all(ruleExecutionPromises),
                    ruleExecutionCacheKey
                );

                if (runnerResult.status === 'CANCELLED') {
                    return undefined;
                }

                const { success, doValue } = reconcileDoValues(
                    (formName: string) => formsRegistry.get(formName, instanceId),
                    runnerResult.value,
                    { touchAllFields: isFormSubmit, clearErrorsForHiddenFields: isFormSubmit }
                );

                const formErrors = reconcileFormErrors(
                    doValue,
                    context.uiConfigurationMetadataMap,
                    !!applicationSettings.RMS_REPORT_WRITING_ERROR_ENHANCEMENTS_ENABLED
                );

                // TODO compare against invocation id to ensure that we only forward the latest successful invocation
                return {
                    formErrors,
                    success,
                    do: doValue,
                };
            },
        };
    }, [
        props.name,
        props.configuration,
        initialState,
        cacheKeyPrefixForForm,
        context,
        formConfigurationId,
        instanceId,
        applicationSettings,
    ]);

    // just specifying `MFTFormConfigurationDescriminator` allows us to have this form as generic as possible without causing type errors
    const children = (
        <Form<MFTFormConfigurationDescriminator>
            {...formProps}
            index={instanceId}
            render={(form) => {
                return (
                    <MFTFormContextProvider value={form}>{renderChildren()}</MFTFormContextProvider>
                );
            }}
            key={props.key}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
        />
    );
    switch (form.component) {
        case 'CARD':
            return (
                <DragonCard
                    key={`dragon-card~${formName}`}
                    formConfiguration={form}
                    formReference={configuration}
                    instanceId={instanceId}
                    renderSummary={renderSummary}
                    formProps={formProps}
                    testId={getDataAttributes({ label: form.displayName })['testId']}
                >
                    {children}
                </DragonCard>
            );
        case 'ROOT':
            return (
                <div key={`${form.component}~${formName}`} className="report">
                    {children}
                </div>
            );
        default:
            throw new Error(`Unsupported component type "${form.component}"`);
    }
}
