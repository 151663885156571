import { EntityTypeEnum, LinkTypesEnum, RefContextEnum } from '@mark43/rms-api';
import React, { useContext } from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import { connect, useSelector, useDispatch } from 'react-redux';
import { isFunction } from 'lodash';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Observer } from 'markformythree';
import { FederatedSearchExternalWriteDataContext } from 'mark43-federated-search';
import { cssVar } from 'arc';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import getImportEventResource from '~/client-common/core/domain/import-events/resources/importEventResource';
import { formatReportDefinition } from '~/client-common/helpers/reportDefinitionsHelpers';
import { custodialPropertySummaryReportDefinitionSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { hydratedItemByIdSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { FederatedSearchSidePanel } from  "../../../../federated-search/components/FederatedSearchSidePanel"
import { useOverlayStore } from '../../../../core/overlays/hooks/useOverlayStore';
import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import _SidePanel from '../../../../../legacy-redux/components/core/SidePanel';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import {
    getHydratedItemForm,
    convertFromFormModelToLocationEntityLinks,
} from '../../state/forms/hydratedItemForm';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import { clearfix } from '../../../../core/styles/mixins';
import {
    closeItemSidePanel,
    submitHydratedItemForm,
    itemSidePanelIsInPoliceCustodyDisabledSelector,
    itemSidePanelIsStorageLocationIdDisabledSelector,
    itemSidePanelReportingEventNumberSelector,
    itemSidePanelIsVehicleSelector,
    itemSidePanelItemProfileIdSelector,
    itemSidePanelOperationSelector,
    itemSidePanelOwnerIdSelector,
    itemSidePanelOwnerTypeSelector,
    itemSidePanelIsFormHiddenSelector,
    itemSidePanelQuerySelector,
    setFormWithSearchResult,
    setFormWithCadVehicleSuggestion,
    itemSidePanelDexDataSelector,
    itemSidePanelAlwaysHideDexItemsQuerySearchSelector,
    itemSidePanelAlwaysHideItemsInlineSearchSelector,
} from '../../state/ui/itemSidePanel';
import testIds from '../../../../../core/testIds';
import { withQuickAddCallback } from '../../../../core/components/withQuickAddCallback';
import { ArbiterMFTFormElementLabel } from '../../../../core/forms/components/FormElementLabel';
import { saveBoxFailure } from '../../../../../legacy-redux/actions/boxActions';
import { storeEvidenceHydratedItemsLite } from '../../../../evidence/core/utils/evidenceItemsHelpers';
import { pollForCustodialPropertySummaryReport } from '../../state/data/submission';
import { useGetFederatedSearchVehicleButtonsConfiguration } from './hooks';
import HydratedItemForm from './HydratedItemForm';
import { OverrideItemProfileModal } from './OverrideItemProfileModal';
import { OpenDexFormPill } from './OpenDexFormPill';

const strings = componentStrings.reports.core.ItemSidePanel;

const SidePanel = styled(_SidePanel)`
    .react-side-panel-content {
        ${clearfix}
    }
`;

const WrapperWithLabel = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledOpenDexFormPill = styled(OpenDexFormPill)`
    margin-bottom: ${cssVar('arc.space.3')};
`;

const locationDescriptionPath = () => 'description';
const locationPositionAttrIdPath = () => 'positionAttrId';

const ItemSidePanel = ({
    onSave,
    nameQuickAddCallback,
    context,
    onCancel,
    saveText,
    title,
    itemProfileId,
    isInPoliceCustodyDisabled,
    isStorageLocationIdDisabled,
    isVehicle,
    operation,
    reportingEventNumber,
    ownerId,
    ownerType,
    isFormHidden,
    onCreateVehicleClick,
    dexData,
}) => {
    const dispatch = useDispatch();
    const overlayStore = useOverlayStore();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const custodialPropertySummaryReportDefinition = useSelector(
        custodialPropertySummaryReportDefinitionSelector
    );
    const { resetResultForConsumerApp } = useContext(FederatedSearchExternalWriteDataContext) || {};
    const custodialPropertySummaryReportDefinitionName = formatReportDefinition(
        custodialPropertySummaryReportDefinition
    );

    const onLocationSaveSuccess = (location, modelEntityLink) => {
        // If the `locationEntityLink` is empty,
        // then we need to populate it properly
        const form = getHydratedItemForm();
        form.set('recoveryInfo.locationEntityLink', {
            ...form.get('recoveryInfo.locationEntityLink'),
            ...modelEntityLink,
        });
    };

    const onLocationRemoveSuccess = () => {
        const form = getHydratedItemForm();
        form.resetModel('recoveryInfo.locationEntityLink');
    };

    const onSuccessForSaveProgress = React.useCallback(
        (evidenceHydratedItems) => {
            dispatch(storeEvidenceHydratedItemsLite(evidenceHydratedItems));
            dispatch(pollForCustodialPropertySummaryReport(ownerId));
        },
        [ownerId, dispatch]
    );

    const handleSidePanelCancel = () => {
        onCancel();
        if (resetResultForConsumerApp) {
            resetResultForConsumerApp();
        }
    }

    const runImportEventOnAllItems = React.useCallback(() => {
        return getImportEventResource()
            .importItemsToReport({
                reportId: ownerId,
                hideLoadingBar: true,
            })
            .catch((err) => {
                dispatch(
                    saveBoxFailure(
                        context,
                        `${strings.importEventErrorMessage(
                            custodialPropertySummaryReportDefinitionName
                        )} ${err.message}`
                    )
                );
                throw err;
            });
    }, [context, ownerId, custodialPropertySummaryReportDefinitionName, dispatch]);

    const { callResource: onSaveVehicleProgress } = useResourceDeferred(
        runImportEventOnAllItems,
        onSuccessForSaveProgress
    );

    const onSaveHandler = () => {
        onSave().then(() => {
            if (isFunction(nameQuickAddCallback)) {
                nameQuickAddCallback();
            }
            if (resetResultForConsumerApp) {
                resetResultForConsumerApp();
            }
            if (isVehicle) {
                if (ownerType === EntityTypeEnum.ITEM_PROFILE.name) {
                    return Promise.resolve();
                }
                onSaveVehicleProgress();
            }
        });
    };

    const helpTextContainerClassName = 'item-side-panel';

    const showCreateVehicle =
        isVehicle && isFormHidden && applicationSettings.RMS_CAD_DATA_ENTITY_PREFILL_ENABLED;

    const locationSummaryView = (
        <VisibilityObserver
            path="recoveryInfo.locationEntityLink.locationId"
            formName={RefContextEnum.FORM_HYDRATED_ITEM.name}
            render={({ hidden }) =>
                !hidden && (
                    <Observer
                        formName={RefContextEnum.FORM_HYDRATED_ITEM.name}
                        subscriptions={{
                            recoveredLocationLocationId:
                                'recoveryInfo.locationEntityLink.locationId',
                        }}
                        render={() => {
                            let additionalLocationEntityLinks = [];
                            // Only show additional links if we
                            // haven't saved our item yet
                            if (!itemProfileId) {
                                additionalLocationEntityLinks =
                                    convertFromFormModelToLocationEntityLinks(
                                        getHydratedItemForm().getState().model
                                    );
                            }
                            return (
                                <WrapperWithLabel
                                    data-test-field-name-section={
                                        fields.LOCATION_ENTITY_LINK_LINK_TYPE_PROPERTY_RECOVERED_LOCATION_LOCATION_ID
                                    }
                                >
                                    <ArbiterMFTFormElementLabel path="locationId" />
                                    <LocationSummaryViewWrapperWithFormFields
                                        summaryMode={false}
                                        entityType={EntityTypeEnum.ITEM_PROFILE.name}
                                        entityId={itemProfileId}
                                        linkType={LinkTypesEnum.PROPERTY_RECOVERED_LOCATION}
                                        onLocationRemove={onLocationRemoveSuccess}
                                        onLocationAdd={onLocationSaveSuccess}
                                        locationDescriptionPath={locationDescriptionPath}
                                        locationPositionAttrIdPath={locationPositionAttrIdPath}
                                        additionalLocationEntityLinks={
                                            additionalLocationEntityLinks
                                        }
                                    />
                                </WrapperWithLabel>
                            );
                        }}
                    />
                )
            }
        />
    );

    const alwaysHideDexItemsQuerySearch = useSelector(itemSidePanelAlwaysHideDexItemsQuerySearchSelector);
    const alwaysHideItemsInlineSearch = useSelector(itemSidePanelAlwaysHideItemsInlineSearchSelector);

    const {
        federatedSearchButtonsConfiguration,
        handleOverrideProfile,
        handleCancelOverrideProfile,
    } = useGetFederatedSearchVehicleButtonsConfiguration();

    const shouldRenderOpenDexFormElem =
        isVehicle &&
        operation === itemSidePanelOperationEnum.CREATE &&
        !alwaysHideDexItemsQuerySearch;

    return (
        <SidePanel
            cancelText={strings.cancelButton}
            className={helpTextContainerClassName}
            context={context}
            onCancel={handleSidePanelCancel}
            onSave={onSaveHandler}
            saveText={showCreateVehicle ? '' : saveText}
            title={title}
            testId={testIds.ITEM_SIDE_PANEL}
            actionText={showCreateVehicle ? strings.createNewProfile : undefined}
            action={onCreateVehicleClick}
        >
            <OverrideItemProfileModal
                handleOverrideProfile={handleOverrideProfile}
                handleCancelOverrideProfile={handleCancelOverrideProfile}
                entity="vehicle"
            />
            <HydratedItemForm
                helpTextCollisionBoundary={document.querySelector('.mark43-react-side-panel')}
                isInPoliceCustodyDisabled={isInPoliceCustodyDisabled}
                isStorageLocationIdDisabled={isStorageLocationIdDisabled}
                isVehicle={isVehicle}
                locationSummaryView={locationSummaryView}
                operation={operation}
                reportingEventNumber={reportingEventNumber}
                ownerId={ownerId}
                ownerType={ownerType}
                formIsHidden={isFormHidden}
                itemProfileId={itemProfileId}
                dexData={dexData}
                alwaysHideItemsInlineSearch={alwaysHideItemsInlineSearch}
                openDexFormElem={
                    shouldRenderOpenDexFormElem && (
                        <FederatedSearchSidePanel
                            buttonsConfiguration={federatedSearchButtonsConfiguration}
                            renderButton={() => (
                                <StyledOpenDexFormPill
                                    onClick={() => overlayStore.open(overlayIdEnum.DEX_SIDE_PANEL)}
                                />
                            )}
                        />
                    )
                }
            />
        </SidePanel>
    );
};

const mapStateToProps = createStructuredSelector({
    isInPoliceCustodyDisabled: itemSidePanelIsInPoliceCustodyDisabledSelector,
    isStorageLocationIdDisabled: itemSidePanelIsStorageLocationIdDisabledSelector,
    isVehicle: itemSidePanelIsVehicleSelector,
    itemProfileId: itemSidePanelItemProfileIdSelector,
    operation: itemSidePanelOperationSelector,
    reportingEventNumber: itemSidePanelReportingEventNumberSelector,
    ownerId: itemSidePanelOwnerIdSelector,
    ownerType: itemSidePanelOwnerTypeSelector,
    isFormHidden: itemSidePanelIsFormHiddenSelector,
    query: itemSidePanelQuerySelector,
    itemViewSelector: hydratedItemByIdSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    dexData: itemSidePanelDexDataSelector,
});

const mapDispatchToProps = {
    onCancel: closeItemSidePanel,
    onSave: submitHydratedItemForm,
    setFormWithSearchResult,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withProps(({ isVehicle, operation }) => {
        let saveText;
        let title;

        if (operation === itemSidePanelOperationEnum.CREATE) {
            saveText = strings.saveChangesButton;
            title = isVehicle ? strings.createVehicleTitle : strings.createPropertyTitle;
        } else if (operation === itemSidePanelOperationEnum.EDIT) {
            saveText = strings.saveChangesButton;
            title = isVehicle ? strings.editVehicleTitle : strings.editPropertyTitle;
        } else {
            saveText = isVehicle ? strings.createVehicleButton : strings.createPropertyButton;
            title = isVehicle ? strings.duplicateVehicleTitle : strings.duplicatePropertyTitle;
        }

        return {
            context: { name: boxEnum.ITEM_SIDE_PANEL },
            saveText,
            title,
        };
    }),
    withHandlers({
        onCreateVehicleClick({
            storeItemSidePanelIsFormHidden,
            itemViewSelector,
            itemProfileId,
            currentUserDepartmentId,
        }) {
            return () => {
                storeItemSidePanelIsFormHidden(false);
                if (itemProfileId) {
                    const hydratedItem = itemViewSelector(itemProfileId);
                    const itemInCurrentUserDepartment =
                        currentUserDepartmentId === hydratedItem.vehicles[0].departmentId;
                    setFormWithCadVehicleSuggestion({ hydratedItem, itemInCurrentUserDepartment });
                }
            };
        },
    }),
    withQuickAddCallback
)(ItemSidePanel);
