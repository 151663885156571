import styled from 'styled-components';
import { IconButton as _IconButton, Menu, MenuContent, MenuItem, MenuTrigger } from 'arc';
import { LinkTypesEnum, LinkTypesEnumType, RefContextEnum } from '@mark43/rms-api';
import React, {forwardRef, useCallback, useMemo} from 'react';
import { InjectedRouter } from 'react-router';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { useEFileContext, useEFileDisplayName } from '../../../hooks';
import { eFileResource } from '../../../resources';
import { EFILE_LINK_TYPE_TO_NAME_MAP } from '../../../constants';
import testIds from '../../../../../core/testIds';
import { useOverlayStore } from '../../../../core/overlays/hooks/useOverlayStore';
import { useFormGetter } from '../../../../core/forms/hooks/useFormGetter';
import { convertFromFormModel } from '../../state/forms/eFileProfileRemoveDeactivateForm';

const strings = componentStrings.eFiles.core.iceCreamMenu;

const IconButton = styled(_IconButton)`
    border: none;
`;

type IceCreamMenuT = {
    isDeactivated: boolean;
    eFileId: number;
    contextedProfileId: number;
    linkType: LinkTypesEnumType;
    eFileLinkId: number;
    router?: InjectedRouter;
};

export enum ActionType {
    REMOVE = 'Remove',
    DEACTIVATE = 'Deactivate',
}

export const IceCreamMenu = forwardRef(
    (
        {
            isDeactivated,
            eFileId,
            contextedProfileId,
            linkType,
            eFileLinkId,
            router,
        }: IceCreamMenuT,
        /** This ref is to prevent the menu option clicks from propagating into the parent's
         *   onClick handler. One option is to use the e.stopPropagation() but that doesn't
         *   work for the menu options so a ref is used.
         */
        ref: React.Ref<HTMLDivElement> | undefined
    ) => {
        const { eFileDisplayName } = useEFileDisplayName();
        const {
            getters,
            handlers: {
                updateInvolvedNamesDeactivatedState,
                updateInvolvedProfileDeactivatedState,
                removeInvolvedNamesFromState,
                refreshInvolvedProfiles,
            },
        } = useEFileContext();
        const { getForm } = useFormGetter();
        const overlayStore = useOverlayStore();
        const eFileViewModel = getters.efile.getEFileViewModel();
        const isDisabled = !eFileViewModel?.canEdit;

        const getDashboard = useMemo(() => {
            switch (linkType) {
                case LinkTypesEnum.DEFENDANT_IN_EFILE:
                    return `e-file/${eFileId}/defendants`;
                case LinkTypesEnum.ITEM_IN_EFILE:
                case LinkTypesEnum.ATTACHMENT_IN_EFILE:
                    return `e-file/${eFileId}/material`;
                default:
                    return `e-file/${eFileId}/summary`;
            }
        }, [eFileId, linkType]);

        const { callResource: onDeactivate } = useResourceDeferred(
            eFileResource.deactivateInvolvedNames,
            useCallback(
                (success) => {
                    if (success) {
                        updateInvolvedNamesDeactivatedState(eFileLinkId, !isDeactivated);
                        updateInvolvedProfileDeactivatedState(eFileLinkId, !isDeactivated);
                    }
                },
                [
                    eFileLinkId,
                    isDeactivated,
                    updateInvolvedNamesDeactivatedState,
                    updateInvolvedProfileDeactivatedState,
                ]
            )
        );

        const { callResource: onRemove } = useResourceDeferred(
            eFileResource.removeInvolvedEntities,
            useCallback(
                (success) => {
                    if (success) {
                        removeInvolvedNamesFromState(eFileLinkId);
                    }
                },
                [eFileLinkId, removeInvolvedNamesFromState]
            )
        );

        const onRemoveCallback = useCallback(async () => {
            const form = getForm(RefContextEnum.FORM_E_FILE_REMOVE_DEACTIVATE_ENTITY.name);
            if (!form) {
                return;
            }
            const success = await form.submit();
            await onRemove({
                // @ts-expect-error mark43-resource type generation of this parameter seems to be incorrect.
                linkType: EFILE_LINK_TYPE_TO_NAME_MAP[linkType],
                contextedIdsWithRationale: [
                    {
                        entityId: contextedProfileId,
                        rationale: convertFromFormModel(form.getState().model).rationale,
                    },
                ],
            });
            if (success) {
                refreshInvolvedProfiles();
                form.resetModel();
                if (!!router) {
                    router.push(getDashboard);
                }
            }
        }, [contextedProfileId, getDashboard, getForm, linkType, onRemove, refreshInvolvedProfiles, router]);

        const deactivateReactivateCallback = useCallback(async(form) => {
            if (!form) {
                return;
            }
            return await onDeactivate({
                eFileId,
                // @ts-expect-error mark43-resource type generation of this parameter seems to be incorrect.
                linkType: EFILE_LINK_TYPE_TO_NAME_MAP[linkType],
                deactivate: !isDeactivated,
                contextedIdsWithRationale: [{
                    entityId: contextedProfileId,
                    rationale: !isDeactivated ?
                        convertFromFormModel(form.getState().model).rationale :
                        '',
                }],
            });
        }, [contextedProfileId, eFileId, isDeactivated, linkType, onDeactivate]);

        const onDeactivateCallback = useCallback(async () => {
            const form = getForm(RefContextEnum.FORM_E_FILE_REMOVE_DEACTIVATE_ENTITY.name);
            if (!form) {
                return;
            }
            const success = await form.submit();
            await deactivateReactivateCallback(form);
            if (success) {
                form.resetModel();
            }
        }, [deactivateReactivateCallback, getForm]);

        const onRemoveDeactivateClick = (actionType: ActionType) => {
            const isRemoveAction = actionType === ActionType.REMOVE;
            overlayStore.open(overlayIdEnum.E_FILE_PROFILE_REMOVE_DEACTIVATE_MODAL);
            overlayStore.setCustomProperties(overlayIdEnum.E_FILE_PROFILE_REMOVE_DEACTIVATE_MODAL, {
                onSave: isRemoveAction ? onRemoveCallback : onDeactivateCallback,
                title: isRemoveAction ? ActionType.REMOVE : ActionType.DEACTIVATE,
            });
        }

        const stopClickPropagation = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
        }

        return (
            <Menu>
                <MenuTrigger isDisabled={isDisabled} asChild>
                    <IconButton
                        icon="MoreActions"
                        aria-label="iceCreamMenuButton"
                        data-test-id={testIds.E_FILE_PROFILE_ROW_MORE_MENU}
                        onClick={stopClickPropagation}
                    />
                </MenuTrigger>
                <MenuContent align="end" ref={ref}>
                    <MenuItem
                        data-test-id={testIds.E_FILE_PROFILE_ROW_REMOVE}
                        onSelect={() => onRemoveDeactivateClick(ActionType.REMOVE)}
                        onClick={stopClickPropagation}
                    >
                        {strings.remove(eFileDisplayName)}
                    </MenuItem>
                    <MenuItem
                        data-test-id={testIds.E_FILE_PROFILE_DEACTIVATE}
                        onSelect={!isDeactivated ? () => onRemoveDeactivateClick(ActionType.DEACTIVATE) : deactivateReactivateCallback}
                        onClick={stopClickPropagation}
                    >
                        {!isDeactivated
                            ? strings.deactivate(eFileDisplayName)
                            : strings.reactivate(eFileDisplayName)}
                    </MenuItem>
                </MenuContent>
            </Menu>
        );
    }
);
