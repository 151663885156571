import { HistoryEventCategoryEnum } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { applicationSettingsSelector } from '~/client-common/core/domain/application-settings/state/data';
import {
    DISPLAY_ONLY_CASE,
    DISPLAY_ONLY_E_FILE,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    ALL_EVENTS_OPTION as ALL_EVENTS_OPTION_VALUE,
    HistoryType,
    VehicleHistoryOptionsType,
} from '../config';

const strings = componentStrings.reports.core.History;

type FilterOption = {
    display: string;
    value: string;
    toggle?: string;
};

export const useHistoryFilterOptions = (historyType: HistoryType) => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const filterOptions = useFilterOptionsMap();

    return filterOptions[historyType]
        .filter(({ toggle }) => !toggle || applicationSettings[toggle])
        .map(({ display, value }) => ({ display, value }));
};

const ALL_EVENTS_OPTION = {
    display: strings.categoryTypes.allEvents,
    value: ALL_EVENTS_OPTION_VALUE,
};

const useBaseOptions = (historyType: Exclude<HistoryType, HistoryType.VEHICLE>) => {
    const fields = useFields([DISPLAY_ONLY_CASE, DISPLAY_ONLY_E_FILE]);

    const historyTypeToDisplayName = {
        [HistoryType.DEFAULT]: 'Report',
        [HistoryType.E_FILE]: fields[DISPLAY_ONLY_E_FILE],
        [HistoryType.CASE]: fields[DISPLAY_ONLY_CASE],
    };

    return [
        ALL_EVENTS_OPTION,
        {
            display: strings.categoryTypes.entityContent(historyTypeToDisplayName[historyType]),
            value: HistoryEventCategoryEnum.CONTENT.name,
        },
        {
            display: strings.categoryTypes.workflow,
            value: HistoryEventCategoryEnum.WORKFLOW.name,
        },
    ];
};

const useFilterOptionsMap = (): Record<HistoryType, FilterOption[]> => ({
    [HistoryType.DEFAULT]: useBaseOptions(HistoryType.DEFAULT),
    [HistoryType.E_FILE]: useBaseOptions(HistoryType.E_FILE),
    [HistoryType.CASE]: [
        ...useBaseOptions(HistoryType.CASE),
        {
            display: strings.categoryTypes.folderChanges,
            value: HistoryEventCategoryEnum.FOLDERS.name,
            toggle: 'RMS_CASE_FOLDERING_ENABLED',
        },
    ],
    [HistoryType.VEHICLE]: [
        ALL_EVENTS_OPTION,
        {
            display: strings.categoryTypes.anpr,
            value: VehicleHistoryOptionsType.ANPR,
            toggle: 'RMS_ANPR_IMPROVEMENTS_ENABLED',
        },
        {
            display: strings.categoryTypes.registrationChange,
            value: VehicleHistoryOptionsType.REGISTRATION_CHANGE,
        },
        {
            display: strings.categoryTypes.reportActivity,
            value: VehicleHistoryOptionsType.REPORT_ACTIVITY,
        },
        {
            display: strings.categoryTypes.otherActivity,
            value: VehicleHistoryOptionsType.OTHER_ACTIVITY,
        },
    ],
});
