import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import { compose, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';

// components
import { createStructuredSelector } from 'reselect';
import { FormattedDate } from '~/client-common/core/dates/components';

import { convertAttachmentViewModelsForLightboxGallery } from '~/client-common/core/domain/attachments/state/ui';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { openImageGalleryLightbox } from '../../actions/imageGalleryLightboxActions';
import testIds from '../../../core/testIds';
import Icon, { iconTypes } from './Icon';

const FILE_ICON_WIDTH = 24;
const FILE_ICON_HEIGHT = 30;

const FileMetadata = styled.div`
    margin-left: ${FILE_ICON_WIDTH}px;
    font-size: var(--arc-fontSizes-xs);
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

const FileLink = styled.a`
    max-width: ${(props) => props.maxWidth}px;
    margin-top: 5px;
    color: ${(props) =>
        props.disabled ? props.theme.colors.mediumGrey : props.theme.colors.cobaltBlue};
    display: inline-block;
    vertical-align: top;
    word-wrap: break-word;
    cursor: pointer;
`;

const mapStateToProps = createStructuredSelector({
    dateTimeFormatter: currentDepartmentDateFormatterSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openImageGalleryLightbox: (fileGridAttachments, index) =>
        dispatch(openImageGalleryLightbox(fileGridAttachments, index)),
});

const _FileDownloadLink = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onClick: ({ openImageGalleryLightbox, fileGridAttachments, index, dateTimeFormatter }) => (
            e
        ) => {
            e.stopPropagation();
            openImageGalleryLightbox(
                convertAttachmentViewModelsForLightboxGallery(
                    fileGridAttachments,
                    dateTimeFormatter
                ),
                index
            );
        },
    })
)(function _FileDownloadLink({
    className,
    displayName,
    path,
    createdBy,
    createdDateUtc,
    description,
    width,
    allowLightbox,
    isFileExport,
    disabled = false,
    onClick,
}) {
    const linkWidth = width - FILE_ICON_WIDTH;
    return (
        <div className={className} key={path} style={{ width }}>
            <Icon className="file-icon" type={isFileExport ? iconTypes.EXPORT : iconTypes.FILE} />
            {allowLightbox || disabled ? (
                <FileLink
                    as="div"
                    maxWidth={linkWidth}
                    onClick={!disabled && onClick}
                    disabled={disabled}
                >
                    {displayName}
                </FileLink>
            ) : (
                <FileLink
                    maxWidth={linkWidth}
                    href={path}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                    data-test-id={testIds.DOWNLOADABLES_FILENAME_LINK}
                >
                    {displayName}
                </FileLink>
            )}
            {description && <FileMetadata>{description}</FileMetadata>}
            <FileMetadata>
                <FormattedDate
                    date={createdDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                />
                {createdDateUtc && createdBy ? ' ' : ''}
                {createdBy}
            </FileMetadata>
        </div>
    );
});

export const FileDownloadLink = styled(_FileDownloadLink)`
    font-size: var(--arc-fontSizes-sm);

    i.file-icon {
        display: inline-block;
        color: ${(props) =>
            props.disabled ? props.theme.colors.mediumGrey : props.theme.colors.cobaltBlue};
        width: ${FILE_ICON_WIDTH}px;
        height: ${FILE_ICON_HEIGHT}px;
        vertical-align: top;

        &::before {
            font-size: ${({ isFileExport }) => (isFileExport ? 23 : 30)}px;
        }
    }
`;

const FileGridFileDownloadLink = styled(FileDownloadLink)`
    margin-bottom: 3px;
    display: inline-block;
    vertical-align: top;
`;

/**
 *
 * @param {any} param0
 */
function _FileGrid({
    fileLinks,
    numOfColumns = 2,
    className = '',
    width,
    disabled = false,
    isFileExport = false,
    allowLightbox = false,
}) {
    const links = map(fileLinks, (link, index) => (
        <FileGridFileDownloadLink
            {...link}
            allowLightbox={allowLightbox}
            fileGridAttachments={fileLinks}
            disabled={disabled}
            index={index}
            key={link.id}
            isFileExport={isFileExport}
            width={width ? width / numOfColumns : `${100 / numOfColumns}%`}
        />
    ));

    return <div className={className}>{links}</div>;
}

export const FileGrid = pure(styled(_FileGrid)`
    ${(props) => (props.width ? `width: ${props.width}px;` : '')} padding: 2px 0;
`);
