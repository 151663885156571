import { useContext } from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    FederatedSearchExternalReadDataContext,
    QueryableEntityType,
} from 'mark43-federated-search';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import withCard from '../../utils/withCard';
import vehicleCard from '../../state/ui/vehicleCard';
import { openManageItemsSidePanel, limitToOneSelector } from '../../state/ui';
import testIds from '../../../../../core/testIds';
import { registerCard } from '../../utils/cardsRegistry';
import ItemCard from './ItemCard';
import { AddItemFromDex } from './AddItemFromDex';

const mapStateToProps = createStructuredSelector({
    limitToOne: limitToOneSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onAdd: ({ limitToOne, ownerType, ownerId, reportingEventNumber, index }) => {
        return dispatch(
            openManageItemsSidePanel({
                isVehicle: true,
                limitToOne,
                ownerType,
                ownerId,
                reportingEventNumber,
                index,
            })
        );
    },
});

/**
 * Vehicle Card in Item Entry v2.
 * @param  {number} props.ownerType The ownerType of all the vehicles in this card. This is always
 *   'REPORT' as of Jan 2018.
 * @param  {number} props.ownerId The ownerId of all the vehicles in this card.
 * @param  {string} props.reportingEventNumber
 */
export default compose(
    withCard(vehicleCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }),
    withHandlers({
        onAdd: ({ limitToOne, ownerType, ownerId, reportingEventNumber, onAdd, index }) => () =>
            onAdd({ limitToOne, ownerType, ownerId, reportingEventNumber, index }),
        onEdit: ({ editCallback, onEdit, index }) => () => editCallback(() => onEdit({ index })),
        registerCard: ({ onSave, index }) => () =>
            registerCard({
                cardModule: vehicleCard,
                onSave: () => onSave(null, { index }),
                index,
            }),
    }),
    withProps(() => {
        const { resultForConsumerApp = {} } =
            useContext(FederatedSearchExternalReadDataContext) || {};

        const dexVehicles = resultForConsumerApp[QueryableEntityType.VEHICLE] || [];
        const dexBoats = resultForConsumerApp[QueryableEntityType.BOAT] || [];

        const containsVehicleItems = dexVehicles.length + dexBoats.length > 0;

        return {
            isVehicle: true,
            cardId: reportCardEnum.VEHICLE.id,
            testId: testIds.VEHICLE_CARD,
            quickSearchSection: containsVehicleItems ? <AddItemFromDex /> : undefined,
        };
    })
)(ItemCard);
