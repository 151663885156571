import {
    ElasticSearchResource,
    ElasticSearchTypeEnumType,
    ElasticReportQuery,
    ElasticCaseQuery,
    ElasticSearchsavedSearchesResource,
    SavedSearch,
    SavedSearchView,
    ElasticSearchTypeEnum,
    EntityTypeEnumType,
    ElasticCobaltSortKeyEnumType,
    ElasticCobaltSortTypeEnumType,
    ElasticSort,
} from '@mark43/rms-api';
import { ElasticsearchResource } from '@mark43/evidence-api';
import { DeepPartial } from 'utility-types';
import {
    queryParamDefaults,
    allQueryParamDefaults,
} from '~/client-common/configs/advancedSearchConfig';
import { ValueOf } from '~/client-common/types';
import { req } from '../../lib/ajax';
import { createResource } from '../../lib/resources/Resource';

const RMS_API_URL = '/rms/api';
const EVIDENCE_API_URL = '/evidence/api';

type ElasticEntityResourceByPath<P> =
    P extends typeof elasticEntityPath.REPORTS ? ElasticSearchResource.SearchReports1 :
    P extends typeof elasticEntityPath.CAD_TICKETS ? ElasticSearchResource.SearchCadTickets1 :
    P extends typeof elasticEntityPath.PERSONS ? ElasticSearchResource.SearchPersons :
    P extends typeof elasticEntityPath.LOCATIONS ? ElasticSearchResource.SearchLocations :
    P extends typeof elasticEntityPath.ORGANIZATIONS ? ElasticSearchResource.SearchOrgs :
    P extends typeof elasticEntityPath.VEHICLES ? ElasticSearchResource.SearchVehicles :
    P extends typeof elasticEntityPath.PROPERTY ? ElasticSearchResource.SearchProperty :
    P extends typeof elasticEntityPath.FILES ? ElasticSearchResource.SearchFiles :
    P extends typeof elasticEntityPath.WARRANTS ? ElasticSearchResource.SearchWarrants :
    P extends typeof elasticEntityPath.EVIDENCE_ITEMS ? ElasticsearchResource.SearchEvidenceItems :
    P extends typeof elasticEntityPath.STORAGE_LOCATIONS ? ElasticsearchResource.SearchStorageLocations :
    P extends typeof elasticEntityPath.CASES ? ElasticSearchResource.SearchCases :
    P extends typeof elasticEntityPath.USERS ? ElasticSearchResource.SearchUsers :
    P extends typeof elasticEntityPath.USERS_WITH_MFA_CONFIG ? ElasticSearchResource.SearchUsersWithMfaConfig :
    P extends typeof elasticEntityPath.COURT_ORDERS ? ElasticSearchResource.SearchCourtOrders : never;

function elasticSearchFactory<
    T extends ElasticEntityResourceByPath<P>,
    P extends ValueOf<typeof elasticEntityPath>,
>(path: P, defaults = queryParamDefaults, baseUrl = RMS_API_URL) {
    // TODO: Transform params into a single object
    return (
        elasticQuery: DeepPartial<NonNullable<T['data']>['elasticQuery']>,
        from: number = defaults.FROM,
        size: number = defaults.SIZE,
        sortKey?: ElasticCobaltSortKeyEnumType,
        sortType?: ElasticCobaltSortTypeEnumType,
        dispatch?: unknown,
        hideLoadingBar?: boolean,
        elasticSearchType?: ElasticSearchTypeEnumType,
        requestFeatureTag?: string,
        sorts?: ElasticSort[]
    ) => {
        // @ts-expect-error TODO: figure out how to fix TS errors - RND-23227
        return req<T>({
            baseUrl,
            method: 'POST',
            url: `elastic_search/${path}`,
            hideLoadingBar,
            params: {
                auto_save_type: elasticSearchType,
                request_feature_tag: requestFeatureTag,
            },
            data: {
                elasticQuery,
                from,
                size,
                sortKey,
                sortType,
                sorts,
            },
        });
    };
}

const elasticEntityPath = {
    REPORTS: 'reports',
    CAD_TICKETS: 'cad_tickets',
    PERSONS: 'persons',
    LOCATIONS: 'locations',
    ORGANIZATIONS: 'organizations',
    VEHICLES: 'vehicles',
    PROPERTY: 'property',
    FILES: 'files',
    WARRANTS: 'warrants',
    EVIDENCE_ITEMS: 'evidence_items',
    STORAGE_LOCATIONS: 'storage_locations',
    CASES: 'cases',
    USERS: 'users',
    USERS_WITH_MFA_CONFIG: 'users-with-mfa-config',
    COURT_ORDERS: 'court-orders',
} as const;

const searchTypeToQueryParam = {
    [ElasticSearchTypeEnum.REPORT.name]: 'include_reports',
    [ElasticSearchTypeEnum.PERSON.name]: 'include_persons',
    [ElasticSearchTypeEnum.CAD_TICKET.name]: 'include_cad_tickets',
    [ElasticSearchTypeEnum.ORGANIZATION.name]: 'include_organizations',
    [ElasticSearchTypeEnum.VEHICLE.name]: 'include_vehicles',
    [ElasticSearchTypeEnum.PROPERTY.name]: 'include_property',
    [ElasticSearchTypeEnum.WARRANT.name]: 'include_warrants',
    [ElasticSearchTypeEnum.CASE.name]: 'include_cases',
    [ElasticSearchTypeEnum.COURT_ORDERS.name]: 'include_court_orders',
} as const;

type QueryParams = Partial<Record<ValueOf<typeof searchTypeToQueryParam>, boolean>>;

type ElasticSearchTypeEnumTypeExtended =
    | ElasticSearchTypeEnumType
    | Extract<EntityTypeEnumType, 'BOOKING'>;

export default createResource({
    name: 'Elastic Search Resource',
    methods: {
        searchReports: elasticSearchFactory(elasticEntityPath.REPORTS),
        searchCadTickets: elasticSearchFactory(elasticEntityPath.CAD_TICKETS),
        searchPersons: elasticSearchFactory(elasticEntityPath.PERSONS),
        searchLocations: elasticSearchFactory(elasticEntityPath.LOCATIONS),
        searchOrganizations: elasticSearchFactory(elasticEntityPath.ORGANIZATIONS),
        searchVehicles: elasticSearchFactory(elasticEntityPath.VEHICLES),
        searchProperty: elasticSearchFactory(elasticEntityPath.PROPERTY),
        searchAttachments: elasticSearchFactory(elasticEntityPath.FILES),
        searchWarrants: elasticSearchFactory(elasticEntityPath.WARRANTS),
        searchEvidenceItems: elasticSearchFactory(
            elasticEntityPath.EVIDENCE_ITEMS,
            undefined,
            EVIDENCE_API_URL
        ),
        searchStorageLocations: elasticSearchFactory(
            elasticEntityPath.STORAGE_LOCATIONS,
            undefined,
            EVIDENCE_API_URL
        ),
        searchCases: elasticSearchFactory(elasticEntityPath.CASES),
        searchUsers: elasticSearchFactory(elasticEntityPath.USERS),
        searchUsersWithMfaConfig: elasticSearchFactory(elasticEntityPath.USERS_WITH_MFA_CONFIG),
        searchCourtOrders: elasticSearchFactory(elasticEntityPath.COURT_ORDERS),
        searchAll({
            query = '',
            from = allQueryParamDefaults.FROM,
            size = allQueryParamDefaults.SIZE,
            searchTypes = [],
            excludeExternalAgencyResults,
        }: {
            query: string;
            from?: number;
            size?: number;
            searchTypes?: ElasticSearchTypeEnumTypeExtended[];
            excludeExternalAgencyResults?: boolean;
        }) {
            const searchTypesQueryObject: QueryParams = searchTypes.reduce<QueryParams>(
                (acc, searchType) => {
                    if (searchType in searchTypeToQueryParam) {
                        return {
                            ...acc,
                            [searchTypeToQueryParam[
                                searchType as keyof typeof searchTypeToQueryParam
                            ]]: true,
                        };
                    }

                    return acc;
                },
                {}
            );

            return req<ElasticSearchResource.QuickSearch>({
                method: 'GET',
                url: 'elastic_search/all',
                params: {
                    q: query,
                    from,
                    size,
                    exclude_external_results: excludeExternalAgencyResults,
                    ...searchTypesQueryObject,
                },
            });
        },
        quickSearchCases({
            query = '',
            from = allQueryParamDefaults.FROM,
            size = allQueryParamDefaults.SIZE,
            excludeExternalResults,
        }: {
            query: string;
            from?: number;
            size?: number;
            searchTypes?: ElasticSearchTypeEnumType[];
            excludeExternalResults?: boolean;
        }) {
            return req<ElasticSearchResource.QuickSearchCases>({
                method: 'GET',
                url: 'elastic_search/cases/quick_search',
                params: {
                    q: query,
                    from,
                    size,
                    exclude_external_results: excludeExternalResults,
                },
            });
        },
        // Saved Search endpoints
        copySavedSearch(savedSearchId: number) {
            return req<ElasticSearchsavedSearchesResource.Copy3>({
                method: 'POST',
                url: `elastic_search/saved_searches/copy/${savedSearchId}/`,
            });
        },
        executeSavedSearch(savedSearch: SavedSearch, showNew = false) {
            return req<ElasticSearchsavedSearchesResource.ExecuteSavedSearch1>({
                method: 'PUT',
                url: 'elastic_search/saved_searches/execute',
                data: savedSearch,
                params: {
                    from: queryParamDefaults.FROM,
                    size: queryParamDefaults.SIZE,
                    showNew,
                },
            });
        },
        saveSearch(newSavedSearch: SavedSearchView) {
            return req<ElasticSearchsavedSearchesResource.SaveSearches>({
                method: 'POST',
                url: 'elastic_search/saved_searches',
                data: [newSavedSearch],
            });
        },
        updateSearch(updatedSavedSearch: SavedSearchView) {
            return req<ElasticSearchsavedSearchesResource.UpdateSavedSearches>({
                method: 'POST',
                url: 'elastic_search/saved_searches/update',
                data: [updatedSavedSearch],
            });
        },
        deleteSavedSearch(savedSearchId: number) {
            return req<ElasticSearchsavedSearchesResource.DeleteSavedSearch>({
                method: 'DELETE',
                url: `elastic_search/saved_searches/${savedSearchId}/`,
            });
        },
        searchReportsAggregate(elasticQuery: ElasticReportQuery, field: string) {
            return req<ElasticSearchResource.SearchReportsAggregate>({
                method: 'POST',
                url: 'elastic_search/reports/aggregate',
                data: {
                    elasticQuery,
                    aggregateField: field,
                },
            });
        },
        searchCasesByCaseStatusAggregate(elasticQuery: ElasticCaseQuery) {
            return req<ElasticSearchResource.SearchCasesByCaseStatusAggregate>({
                method: 'POST',
                url: 'elastic_search/case_status/aggregate',
                // @ts-expect-error resource type requires `aggregateField` property here
                data: {
                    elasticQuery,
                },
            });
        },
    },
});
